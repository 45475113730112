import { chakra, forwardRef, ImageProps } from '@chakra-ui/react';
import success from '../../assets/svgs/success.svg';

export const SuccessIcon = forwardRef<ImageProps, 'img'>((props, ref) => {
  const { height, width } = props;
  return (
    <chakra.img
      src={success}
      height={height}
      width={width}
      ref={ref}
      {...props}
    />
  );
});
