import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Flex,
  Image,
} from '@chakra-ui/react';
import dayjs from 'dayjs';

import AccountGroupIcon from 'mdi-react/AccountGroupIcon';
import ClockOutlineIcon from 'mdi-react/ClockOutlineIcon';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import styled from 'styled-components';
import { ReactComponent as DeactiveMicroLocationIcon } from '../../../assets/svgs/DeactiveMicroLocationIcon.svg';
import { ReactComponent as PhotoImage } from '../../../assets/svgs/Image.svg';

import {
  getRepeatOrNotRepeat,
  formatTime,
  defaultAvatar,
} from '../../../helpers/misc';

export const weekDaysData = [
  { value: 0, label: 'Sunday' },
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
];

const PreviewShift = (props) => {
  const {
    selectedProperties,
    openPreviewModal,
    onClose,
    locations,
    submitScheduleRef,
    repetative,
    shiftEndDate,
    shift,
    microlocations,
    loading,
    job,
    janitors,
    edit,
  } = props;

  return (
    <Modal
      onClose={() => {
        onClose();
      }}
      isOpen={openPreviewModal}
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent minW="850px">
        <ModalHeader fontSize="30px">Shift Preview</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody overflowY="auto">
          <ShiftInfo
            microlocations={microlocations}
            job={job}
            shift={shift}
            selectedProperties={selectedProperties}
            repetative={repetative}
            shiftEndDate={shiftEndDate}
            janitors={janitors}
            locations={locations}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            background="transparent"
            marginRight="10px"
            isDisabled={loading}
            onClick={() => {
              //
              onClose();
            }}
            color="primary.500"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              submitScheduleRef.current.click();
            }}
            colorScheme="blue"
            isLoading={loading}
            background="#006DB8 0% 0%"
            boxShadow=" 0px 3px 6px #006DB85C"
            borderRadius="5px"
          >
            {edit ? 'Update' : 'Create Shift'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const ShiftInfo = ({
  job,
  shift,
  selectedProperties,
  repetative,
  shiftEndDate,
  janitors,
  locations,
  microlocations,
  fromShiftDetail,
  markerLogs,
}) => {
  const providers = fromShiftDetail ? janitors : job?.providerUids;
  const jobImages = markerLogs?.map((m) => m.images).flat() ?? [];

  function isOngoing() {
    // TODO: confirm this
    // if (job.createdDate < dayjs(job?.endDate).format('MMM D, YYYY'))
    //   return '(Ongoing)';
    if (job.createdDate < dayjs().startOf('day')) return '(Completed)';
    return '';
  }

  const getDateOfShiftEnd = () => {
    return dayjs(shift?.endsOn).format('MMM D, YYYY');
  };

  return (
    <>
      <Flex paddingY="md" gap="md">
        <ClockOutlineIcon color="#8A91A6" />
        <Flex flexDirection="column">
          <HeaderText>Shift</HeaderText>
          {fromShiftDetail ? (
            <BoldText>
              {getDateOfShiftEnd()}, {formatTime(shift?.startTime)} to{' '}
              {formatTime(shift?.endTime)} {isOngoing()}
            </BoldText>
          ) : (
            <BoldText>
              {getDateOfShiftEnd()}, {formatTime(shift?.startTime)} -{' '}
              {formatTime(shift?.endTime)}
            </BoldText>
          )}
          {shift?.name && <PlaneText>{shift.name}</PlaneText>}
          <PlaneText>
            <Text>
              {repetative &&
                `${getRepeatOrNotRepeat(
                  shift?.weekdays?.map(
                    (w) => weekDaysData?.find((wd) => wd.value === w)?.label
                  ),
                  repetative
                )} ${
                  shiftEndDate
                    ? `until ${dayjs(shiftEndDate).format('MMM D, YYYY')}`
                    : ''
                }`}
            </Text>
          </PlaneText>
        </Flex>
      </Flex>
      <Divider />
      <Flex paddingY="md" gap="md">
        <AccountGroupIcon color="#8A91A6" />
        <Flex paddingLeft="5px" flexDirection="column">
          <HeaderText>Janitor</HeaderText>
          {providers?.map((jan) => {
            const janitor = fromShiftDetail
              ? jan.name
              : janitors.find((j) => j.value === jan)?.label;
            const janitorKey = fromShiftDetail ? jan.uid : jan;
            return <BoldText key={janitorKey}>{janitor}</BoldText>;
          })}
        </Flex>
      </Flex>
      {selectedProperties?.map((loc, i) => {
        return (
          <ListLocation
            key={loc.value}
            locations={locations}
            microlocations={microlocations}
            loc={loc}
            count={i + 1}
            selectedMicrolocations={
              selectedProperties?.find((l) => l.value === loc.value)
                ?.selectedTasks ?? []
            }
          />
        );
      })}
      {!fromShiftDetail || !jobImages.length ? null : (
        <>
          <Flex paddingY="md" gap="md">
            <PhotoImage />
            <HeaderText>Images attached</HeaderText>
          </Flex>
          <Flex gap="8px" overflow="auto">
            {jobImages?.map((image) => {
              return (
                <Image
                  key={image}
                  width="80px"
                  height="80px"
                  borderRadius="6px"
                  src={image}
                  fallbackSrc={defaultAvatar}
                />
              );
            })}
          </Flex>
        </>
      )}
    </>
  );
};

const ListLocation = ({
  loc,
  count,
  selectedMicrolocations,
  locations,
  microlocations,
}) => {
  const location = locations?.find((l) => l.id === (loc.value ?? loc.id)) ?? {};
  return (
    <>
      <Divider />
      <Flex paddingY="md" gap="md">
        <MapMarkerIcon color="#8A91A6" />
        <Flex paddingLeft="5px" direction="column">
          <HeaderText>Location {count}</HeaderText>

          <BoldText>{loc.label}</BoldText>
          <PlaneText>{location.address}</PlaneText>
        </Flex>
      </Flex>
      <Divider />
      <br />

      <Flex>
        {selectedMicrolocations?.length ? (
          <>
            <DeactiveMicroLocationIcon style={{ marginRight: 'md' }} />
            <Table paddingLeft="5px" variant="simple">
              <Thead
                style={{
                  background: '#F1F1F1 0% 0% no-repeat padding-box',
                }}
              >
                <Tr>
                  <Th>Micro-Location</Th>
                  <Th>Task-List</Th>
                </Tr>
              </Thead>
              <Tbody>
                {selectedMicrolocations?.map((ml, i) => {
                  const microlocationKey = Object.keys(ml)[0];
                  const tasks = ml[microlocationKey];

                  const taskByCategory = tasks.reduce((acc, curr) => {
                    acc[curr.category] = acc[curr.category] ?? [];
                    acc[curr.category] = [...acc[curr.category], curr];
                    return acc;
                  }, {});

                  return (
                    <Tr key={microlocationKey}>
                      <Td
                        style={{
                          font: 'normal normal 600 14px/21px Montserrat',
                        }}
                        color="#17244F"
                      >
                        {microlocations[microlocationKey]?.name ?? '-'}
                      </Td>
                      <Td>
                        <Flex direction="column">
                          {Object.keys(taskByCategory)?.map(
                            (category, index) => {
                              const taskLength =
                                Object.keys(taskByCategory)?.length;
                              const taskOfCategory = taskByCategory[category];
                              return (
                                <>
                                  <Text
                                    style={{
                                      font: 'normal normal 600 14px/21px Montserrat',
                                    }}
                                    color="#17244F"
                                  >
                                    {category}
                                  </Text>

                                  <Text
                                    // textDecoration="underline"
                                    color="#006DB8"
                                    cursor="pointer"
                                    onClick={() => {
                                      //
                                    }}
                                  >
                                    {taskOfCategory.length} Tasks
                                  </Text>
                                  {index === taskLength - 1 ? null : (
                                    <Divider />
                                  )}
                                </>
                              );
                            }
                          )}
                        </Flex>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </>
        ) : null}
      </Flex>
    </>
  );
};

export default PreviewShift;
export const HeaderText = styled(Text)`
  color: #8a91a6;
  font: normal normal normal 16px/19px Montserrat;
`;
export const BoldText = styled(Text)`
  color:#17244F
  font: normal normal normal 16px/21px Montserrat;
  font-weight: bold;

`;
export const PlaneText = styled(Text)`
  font: normal normal normal 16px/18px Montserrat;
  letter-spacing: 0.22px;
`;
