import styled from 'styled-components';
import { Card, Typography, Paper } from '@material-ui/core';

import color from '../../../utils/color';

export const MapContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  position: relative;
  min-height: 400px;
  max-height: 500px;
  flex-direction: row;
`;

export const StyledCard = styled(Card)`
  && {
    border-radius: 16px;
    box-shadow: 0 0 10px ${color.boxShadowColor};
    width: ${(props) => props.width};
  }
`;

export const MapStyledCard = styled(StyledCard)`
  display: flex;
  flex-direction: row;
  padding: 1em;
  margin-left: 2em;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  text-align: center;
  justify-content: space-between;
`;

export const DetailsContainer = styled.div`
  width: 100%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const VerticalScroll = styled.div`
  height: 92%;
  overflow-y: scroll;
`;

export const Message = styled(Typography)`
  && {
    display: flex;
    flex: 1;
    padding: 5px;
    font-size: 1em;
  }
`;

export const DateView = styled(Typography)`
  && {
    margin: 4px;
    font-size: 10px;
    text-align: end;
    font-weight: thin;
  }
`;

export const Stat = styled(Typography).attrs((props) => ({
  variant: props.variant || 'h4',
}))`
  && {
    color: #54bce8;
  }
`;

export const TypeTitle = styled(Typography).attrs(() => ({ variant: 'body1' }))`
  && {
    font-weight: bold;
    margin-top: 16px;
    line-height: ${(props) => (props.lineheight ? props.lineheight : 0.5)};
  }
`;

export const LoadContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Log = styled.a`
  display: flex;
  padding: 8px;
  flex-direction: column;
  background-color: #f2f8fb;
  cursor: ${(props) => props.cursor && 'pointer'};
`;

export const PaperCard = styled(Paper)`
  && {
    width: 0;
    display: flex;
    height: 230px;
    flex-direction: row;
    border-radius: 16px;
    box-shadow: 0 0 10px ${color.boxShadowColor};
    margin-right: ${(props) => (props.margin ? props.margin : 0)};
    flex: auto;
  }
`;

export const IconDiv = styled.div`
  color: white;
  padding: 0 16px;
  display: flex;
  flex: 1 1 70%;
  align-items: center;
  justify-content: center;
  background-color: #c6e5f5;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
`;

export const JobStat = styled(Typography)`
  && {
    color: ${(props) => props.fontcolor};
    font-weight: bold;
    margin-right: 10px;
  }
`;
