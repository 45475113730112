import React from 'react';

import { Switch, Route } from 'react-router';

import ServiceRequests from './ServiceRequests';
import WorkOrderDetails from './WorkOrderDetails';

const WorkOrderRoutes = () => {
  return (
    <Switch>
      <Route
        path="/customer/serviceRequests/marker/:markerKey/order/:orderId"
        component={WorkOrderDetails}
        exact
      />
      <Route
        path="/customer/serviceRequests"
        component={ServiceRequests}
        exact
      />
    </Switch>
  );
};

export default WorkOrderRoutes;
