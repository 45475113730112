import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useHistory } from 'react-router';
import { Card, Divider } from '@material-ui/core';
import ClockFastIcon from 'mdi-react/ClockFastIcon';
import AccountMultipleIcon from 'mdi-react/AccountMultipleIcon';

import Span from '../../Common/Span';
import { Col } from '../../../styles/snowm_styled';
import ListTitleItem from '../../Generics/list_title_item';
import { getRouteByKey } from '../../../../controllers/snowm_firebase';

const RouteItem = ({ route }) => {
  const [routeDetails, setRouteDetails] = useState();
  const history = useHistory();

  useEffect(() => {
    if (route.routeKey) {
      getRouteByKey(route.routeKey).then((res) => setRouteDetails(res));
    }
  }, [route]);

  function handleRouteCard(e) {
    e.stopPropagation();
    history.push(`/customer/routes/${route.jobKey}/${route.key}`);
  }

  return (
    <StyledCard onClick={handleRouteCard}>
      <Col>
        <Span size="16px">{routeDetails?.name}</Span>
        <Divider />
        <ListTitleItem
          Icon={AccountMultipleIcon}
          content={route.servicePointsKeys?.length ?? 0}
          title="Total Makers"
        />
        <ListTitleItem
          Icon={AccountMultipleIcon}
          content={route?.servedPointsKeys?.length ?? 0}
          title="Completed Markers"
        />
        <ListTitleItem
          Icon={ClockFastIcon}
          content={`${route.distance} meters`}
          title="Distance"
        />
      </Col>
    </StyledCard>
  );
};

export default RouteItem;

const StyledCard = styled(Card)`
  && {
    padding: 8px;
  }
`;
