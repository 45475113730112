interface VerticalLineProps {
  height: string | number;
  margin: string | number;
}

export const VerticalLine = (params: VerticalLineProps) => {
  const { height, margin } = params;
  return (
    <div
      style={{
        width: 0,
        height,
        border: '1px solid #BCBCBC',
        margin,
      }}
    />
  );
};
