import { useEffect, useState } from 'react';

import {
  Box,
  Flex,
  Icon,
  Text,
  Image,
  Button,
  Divider,
} from '@chakra-ui/react';

import Loader from '../../Generics/snowm_loader';
import dummyImage from '../../../assets/images/dummyImage.png';
import { MicroLocationInterface } from '../microLocations/microLocation';
import { ReactComponent as MarkerI } from '../../../assets/svgs/Markeri.svg';
import { getMicrolocationsByLocationKey } from '../../../controllers/snowm_firebase';

interface PropertyMarkersProps {
  locationId: string;
  onMicroLocationClick: (microLocation: MicroLocationInterface) => void;
}

const PropertyMarkers = ({
  locationId: propertyId,
  onMicroLocationClick,
}: PropertyMarkersProps) => {
  const [microLocations, setMicroLocations] =
    useState<MicroLocationInterface[]>();

  useEffect(() => {
    let isSubscribe = true;
    getMicrolocationsByLocationKey(propertyId).then((res) => {
      if (isSubscribe) setMicroLocations(res);
    });

    return () => {
      isSubscribe = false;
    };
  }, [propertyId]);

  if (!microLocations) {
    return <Loader />;
  }

  if (microLocations.length === 0) {
    return (
      <Flex justifyContent="center" height="100%">
        <Text>No micro locations for this location.</Text>
      </Flex>
    );
  }

  return (
    <Box>
      {microLocations.map((microLocation, i) => (
        <MicroLocationBox
          index={i}
          key={microLocation.key}
          microLocation={microLocation}
          microLocations={microLocations}
          onMicroLocationClick={onMicroLocationClick}
        />
      ))}
      {/* <Heading as="h6" size="sm">
        Micro-Location
      </Heading>
      {markers.map((marker) => {
        const { imageUrl, name, battery } = marker;
        return (
          <MicroLocationBox
            imageUrl={imageUrl}
            name={name}
            battery={battery}
            key={marker.key}
          />
        );
      })} */}
    </Box>
  );
};

export default PropertyMarkers;

interface MicroLocationBoxProps {
  microLocation: MicroLocationInterface;
  onMicroLocationClick: (microLocation: MicroLocationInterface) => void;
  index: number;
  microLocations: MicroLocationInterface[];
}

const MicroLocationBox = ({
  index,
  microLocation,
  microLocations,
  onMicroLocationClick,
}: MicroLocationBoxProps) => {
  const { name, imageUrl, battery, key } = microLocation;

  return (
    <>
      <Button
        key={key}
        padding={0}
        gridGap="sm"
        variant="ghost"
        alignItems="center"
        maxHeight="max-content"
        _hover={{ background: 'none' }}
        onClick={() => onMicroLocationClick(microLocation)}
      >
        <Image
          boxSize="50"
          objectFit="cover"
          borderRadius="sm"
          src={!imageUrl ? dummyImage : imageUrl}
        />
        <Box>
          <Text>{name}</Text>
          <Flex gridGap="sm">
            {typeof battery === 'number' && (
              <>
                <Icon height="20px" width="20px" as={MarkerI} />
                <Flex gap="xxsm">
                  <Text>{battery}</Text>
                  <Text>%</Text>
                </Flex>
              </>
            )}
          </Flex>
        </Box>
      </Button>
      {index < microLocations.length - 1 && <Divider margin="16px auto" />}
    </>
  );
};
