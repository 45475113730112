import dayjs from 'dayjs';
import moment from 'moment';
import humanizeDuration from 'humanize-duration';

import localeData from 'dayjs/plugin/localeData';

import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

dayjs.extend(localeData);

export function getRelativeDate(date) {
  return date && moment(date).fromNow();
}

export function getCurrentDate() {
  return moment().format('YYYY-MM-DD h:mm A');
}

export function getDateInDashFormat(date) {
  return date ? moment(date).format('YYYY-MM-DD h:mm A') : '-';
}

export function getTimeInEpoch(date?: dayjs.Dayjs) {
  if (date) {
    return dayjs(date).valueOf();
  }
  return dayjs().valueOf();
}

export function getFormattedDate(date) {
  return moment(date).format('MMM Do, YYYY hh:mm A');
}

export function getDayMonthYear(date) {
  return moment(date).format('MM/DD/YYYY');
}

export function getDayMonthYearTime(date) {
  return date ? moment(date).format('YYYY/MM/DD hh:mm A') : '-';
}

export function getLocaleDate(date) {
  return moment(date).format('lll');
}

export function getDateForJob(date) {
  return moment(date).format('YYYY/MM/DD hh:mm A');
}

export function getYearMonthDay(date) {
  return moment(date).format('YYYY/MM/DD');
}

export function getCurrentTimeInEpoch() {
  return dayjs().valueOf();
}

export function getStartTimeStampOfTheDay(date?: Date | number | string) {
  if (date) {
    return dayjs(date).startOf('day').valueOf();
  }
  return dayjs().startOf('day').valueOf();
}

export function getTimestampOfAWeekBefore(date) {
  return moment(date).subtract(7, 'd').valueOf();
}

export const getMomentObjectFromTimestamp = (date) => {
  return moment(date);
};

export const getHourMinutes = (date) => {
  return moment(date).format('HH:mm A');
};

export const getHoursFromMill = (millSec) => {
  return humanizeDuration(millSec, {
    units: ['h', 'm', 's'],
    round: true,
    language: 'shortEn',
    languages: {
      shortEn: {
        y: () => 'y',
        mo: () => 'mo',
        w: () => 'w',
        d: () => 'd',
        h: () => 'h',
        m: () => 'm',
        s: () => 's',
        ms: () => 'ms',
      },
    },
  });
};

export const getTimeInStartOfMinute = (date) => {
  const startOfMinute = dayjs(date).startOf('minute');
  return getTimeInEpoch(startOfMinute);
};

export const getMonthDayYear = (date: number) => {
  return dayjs(date).format('MMM DD,YYYY');
};

export function getTime(time) {
  if (time._d) {
    return moment(time).format('HH:mm');
  }
  return time;
}

export const getYearMonthDayFormat = (date: number) => {
  return moment(date).format('YYYY-MM-DD');
};

export const getAllDaysOfAWeek = () => {
  return dayjs.weekdays();
};

export const getComparedDate = (timestamp: number) => {
  return dayjs(timestamp).fromNow();
};
