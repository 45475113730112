/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: service_points.js
 *
 *  Description: Contex API for Service Points
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 8-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import { createContext, useState } from 'react';
import {
  getRealTimeBeaconMs,
  getRealTimeServicePoints,
  getRealTimeUnAssignedServicePoints,
  getServicePointById,
} from '../controllers/snowm_firebase';

export const ServicePointsContext = createContext();

export const ServicePointsProvider = ({ children }) => {
  const [markers, setMarkers] = useState(null);
  const [unAssignedMarkers, setUnAssignedMarkers] = useState(null);

  const [spLoading, setSpLoading] = useState(null);

  function _updateBeaconMs(beaconMsData) {
    getRealTimeServicePoints((microlocations) =>
      _updateServicePoints(microlocations, beaconMsData)
    );
  }

  function fetchBeaconMs() {
    return getRealTimeBeaconMs(_updateBeaconMs);
  }

  function _updateServicePoints(servicePointsData, device) {
    const microlocationsWithDeviceDetails = Object.values(
      servicePointsData
    ).reduce((prev, curr) => {
      prev[curr.key] = {
        ...curr,
        deviceDetail: Object.values(device).find(
          (d) => d.uuid === curr.beaconId
        ),
      };
      return prev;
    }, {});

    setMarkers(microlocationsWithDeviceDetails);
    setUnAssignedMarkers(servicePointsData);
    setSpLoading(false);
  }
  function _updateUnAssignedServicePoints(servicePointsData) {
    setUnAssignedMarkers(servicePointsData);
    setSpLoading(false);
  }

  async function fetchServicePoints() {
    setSpLoading(true);
    fetchBeaconMs();
    // TODO: if user is customer then use getRealTimeServicePointsOfCustomer
  }

  function fetchUnAssignedServicePoints() {
    setSpLoading(true);
    getRealTimeUnAssignedServicePoints(_updateUnAssignedServicePoints);
  }

  const getMarkerByKey = async (markerKey) => {
    let marker = markers?.[markerKey];
    if (marker) {
      return marker;
    }

    marker = await getServicePointById(markerKey);
    setMarkers({ ...markers, [markerKey]: marker });

    return marker;
  };
  return (
    <ServicePointsContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        markers,
        unAssignedMarkers,
        fetchServicePoints,
        fetchUnAssignedServicePoints,
        spLoading,
        getMarkerByKey,
      }}
    >
      {children}
    </ServicePointsContext.Provider>
  );
};
