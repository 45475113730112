import React from 'react';
import NoFoundResult from './NoResultFound';
import ZeroStateComponent from './ZeroStateComponent';

interface EmptyComponentPropsInterface {
  isSearch: boolean;
  subTitle: string;
  title: string;
  header: string;
  zeroStateImage: string;
}

const EmptyComponent = (props: EmptyComponentPropsInterface) => {
  const { isSearch, subTitle, header, title, zeroStateImage } = props;

  if (isSearch) {
    return <NoFoundResult />;
  }
  return (
    <ZeroStateComponent
      header={header}
      title={title}
      subTitle={subTitle}
      image={zeroStateImage}
    />
  );
};

export default EmptyComponent;
