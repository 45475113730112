import React from 'react';

import Home from './snowm_home';
import { ServicePContext } from '../../../contexts/service_provider';

const HomeConsumer = (normalProps) => (
  <ServicePContext.Consumer>
    {({
      fetchProviders,
      providers,
      providersLoading,
      locations,
      logs,
      getLogs,
      jobs,
      fetchJobs,
      services,
      fetchServices,
    }) => (
      <Home
        fetchProviders={fetchProviders}
        providers={providers}
        providersLoading={providersLoading}
        points={locations}
        logs={logs}
        getLogs={getLogs}
        jobs={jobs}
        fetchJobs={fetchJobs}
        services={services}
        fetchServices={fetchServices}
        {...normalProps}
      />
    )}
  </ServicePContext.Consumer>
);

export default HomeConsumer;
