import {
  Box,
  Flex,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Card } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  getScheduleOfLocation,
  getShiftById,
} from '../../../controllers/snowm_firebase';
import { getJobStatus } from '../../../enums/jobStatus';
import { getDateForJob } from '../../../helpers/date';
import {
  CrewsOfJob,
  flteredJobsHeaders,
  MarkerOfAJob,
} from '../../../reusableComponent/shiftSchedule/JobsTable';
import { Row } from '../../../styles/snowm_styled';
import Span from '../../Common/Span';
import Loader from '../../Generics/snowm_loader';
import { renderHeader } from './LocationShifts';

interface LocationSchedulesProps {
  propertyId: string;
  location: any;
}

const LocationSchedules = (props: LocationSchedulesProps) => {
  const { propertyId, location } = props;

  const [schedules, setSchedules] = useState<any>();

  useEffect(() => {
    let isSubscribe = true;
    if (!schedules) {
      getScheduleOfLocation(location?.id).then((res) => {
        if (isSubscribe) setSchedules(res);
      });
    }

    return () => {
      isSubscribe = false;
    };
  }, [propertyId]);

  if (!schedules) {
    return <Loader />;
  }
  if (schedules.length === 0) {
    return (
      <Flex justifyContent="center" height="100%">
        <Span weight="bold">No schedules for this location.</Span>
      </Flex>
    );
  }

  return (
    <Box overflowX="scroll">
      <Table size="sm" overflowX="scroll">
        <Thead>{renderHeader(flteredJobsHeaders(false))}</Thead>
        <Tbody>
          {schedules?.map((property) => (
            <STableRow key={property.id} job={property} />
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

const STableRow = (props) => {
  const { job } = props;
  const isPeriodic = false;

  const getJob = () => {
    if (isPeriodic) {
      return job?.job;
    }
    return job;
  };
  const [shift, setShift] = useState<any>(null);
  const [actualJob, setActualJob] = useState(getJob());

  useEffect(() => {
    const updatedJob = getJob();
    setActualJob(updatedJob);
  }, [job, isPeriodic]);

  useEffect(() => {
    let isCancel = false;

    if ((actualJob?.shift || actualJob?.shiftKey) && !isPeriodic) {
      if (actualJob?.shift) {
        setShift(actualJob.shift);
      } else {
        getShiftById(actualJob?.shiftKey).then((res) => {
          if (!isCancel) {
            setShift(res);
          }
        });
      }
    }
    return () => {
      isCancel = true;
    };
  }, [actualJob]);

  const renderTimeStampForJob = () => {
    const startDate = actualJob?.startedDate
      ? getDateForJob(actualJob.startedDate)
      : '-';
    const endedDate = actualJob?.endDate
      ? getDateForJob(actualJob.endDate)
      : '-';
    return (
      <>
        <Flex justifyContent="space-between">
          <Span>{startDate}</Span>
        </Flex>
        <Flex justifyContent="space-between">
          <Span>{endedDate}</Span>
        </Flex>
      </>
    );
  };

  return (
    <Tr>
      <Td>
        <Flex gridGap="md">
          <Stack>
            <Text>{job?.shift?.name ?? '---'}</Text>
          </Stack>
        </Flex>
      </Td>
      <Td>{getJobStatus(actualJob, shift)}</Td>
      <CrewsOfJob job={actualJob} />
      <Td>{renderTimeStampForJob()}</Td>
      <MarkerOfAJob job={actualJob} />
    </Tr>
  );
};

export default LocationSchedules;

const StyledCard = styled(Card)`
  padding: 16px;
`;

const NoMarkerContainer = styled(Row)`
  height: 100%;
`;

const Image = styled.img`
  object-fit: contain;
`;
