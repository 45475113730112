import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import CloseIcon from 'mdi-react/CloseIcon';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as DeleteIcon } from '../assets/svgs/GreyDeleteIcon.svg';
import { ReactComponent as EditIcon } from '../assets/svgs/GreyEditIcon.svg';
import { Toast } from '../Components/Common/Toast';
import DeleteDialog from './DeleteDialog';

interface DetailsDrawerProps {
  open: boolean;
  title: string;
  children?: React.ReactNode;
  showActionButtons?: boolean;
  action?: React.ReactElement;
  handleBackClick?: () => void;
  onClose: () => void;
  showBackButton?: boolean;
  actionLoading?: boolean;
  details: any; // TODO: maybe type
  detailTabs: any; // TODO:type this
  subtitle: string;
  image?: string;
  setOpenAddDialog?: any;
  onEdit?: any;
  onDelete?: any;
  setOpenDeleteDialog?: any;
  hideEditDeleteButton?: boolean;
  width?: string;
  height?: string;
  // eslint-disable-next-line no-undef
  body?: (location: any) => JSX.Element;
  deleteSubtitle?: string;
}

export const DetailsDrawer = (props: DetailsDrawerProps) => {
  const {
    open,
    title,
    showActionButtons = false,
    action,
    onClose,
    handleBackClick,
    showBackButton = false,
    actionLoading = false,
    details = {},
    detailTabs,
    subtitle,
    image,
    children,
    setOpenAddDialog,
    onEdit,
    onDelete,
    setOpenDeleteDialog,
    hideEditDeleteButton,
    width,
    height,
    body,
    deleteSubtitle,
  } = props;

  const { name, imageUrl } = details;

  const [tabValue, setTabValue] = useState(0);
  const [agreed, setAgreed] = useState(false);

  const deleteConfirmation = useDisclosure();

  const toast = useToast();

  function handleTabChange(val) {
    setTabValue(val);
  }

  const handleEdit = () => {
    onClose();
    onEdit(details);
  };

  const toggleAgreeCheckbox = () => {
    setAgreed(!agreed);
  };

  const closeDeleteDialog = () => {
    deleteConfirmation.onClose();
    setAgreed(false);
  };

  const handleDelete = () => {
    if (!agreed) return;
    setOpenDeleteDialog(true);
    onDelete(details);
    onClose();
    closeDeleteDialog();
    renderJanitorToast();
  };
  function renderJanitorToast() {
    return toast({
      duration: 3000,
      isClosable: true,
      render: function CustomFile() {
        return (
          <Toast
            title="Success"
            status="success"
            description={renderJanitorName()}
          />
        );
      },
    });
  }
  function renderJanitorName() {
    return (
      <span>
        Janitor&ensp;
        <span style={{ fontWeight: 'bold', fontSize: 18 }}>{name}&ensp;</span>
        deleted successfully
      </span>
    );
  }

  return (
    <>
      <DeleteDialog
        open={deleteConfirmation.isOpen}
        agreed={agreed}
        toggleAgreeCheckbox={toggleAgreeCheckbox}
        closeDeleteDialog={closeDeleteDialog}
        handleDeleteLocation={handleDelete}
        entity="location"
        locationName={name}
        image={imageUrl}
        body={body}
        title="Delete 1 location"
        deleteSubtitle={deleteSubtitle}
      />
      <Flex
        p="12"
        gridGap="xl"
        height="100%"
        overflow="auto"
        bg="background.500"
        flexDirection="column"
      >
        <Drawer isOpen={open} placement="right" onClose={onClose} size="lg">
          <DrawerOverlay />
          <DrawerContent paddingTop="25px">
            <Flex justify="space-between">
              <Flex gridGap="md" paddingLeft="60px" paddingTop="15px">
                {imageUrl && (
                  <Image
                    src={imageUrl}
                    objectFit="cover"
                    height={width ?? '75px'}
                    width="75px"
                  />
                )}
                <Stack>
                  <Text
                    textAlign="left"
                    letterSpacing="0px"
                    color="#17244F"
                    style={{ font: 'normal normal 600 25px/40px Montserrat' }}
                  >
                    {title}
                  </Text>
                  <Title>{subtitle}</Title>
                </Stack>
              </Flex>
              <Box padding="10px" textAlign="right">
                {!hideEditDeleteButton ? (
                  <>
                    <Button
                      width="24px"
                      height="auto"
                      color="#8B91A7"
                      backgroundColor="transparent"
                      _hover={{ backgroundColor: 'transparent' }}
                      onClick={handleEdit}
                      leftIcon={<EditIcon />}
                    />
                    <Button
                      width="24px"
                      height="auto"
                      color="#8B91A7"
                      backgroundColor="transparent"
                      _hover={{ backgroundColor: 'transparent' }}
                      onClick={() => {
                        deleteConfirmation.onOpen();
                      }}
                      leftIcon={<DeleteIcon />}
                    />
                  </>
                ) : null}
                <Button
                  width="24px"
                  height="auto"
                  backgroundColor="transparent"
                  color="#8B91A7"
                  _hover={{ backgroundColor: 'transparent' }}
                  onClick={onClose}
                  leftIcon={<CloseIcon />}
                />
              </Box>

              {/* {!hideEditDeleteButton ? (
                    <>
                      <Icon
                        as={EditIcon}
                        onClick={handleEdit}
                        cursor="pointer"
                      />
                      <Icon
                        as={DeleteIcon}
                        cursor="pointer"
                        onClick={() => {
                          deleteConfirmation.onOpen();
                        }}
                      />
                    </>
                  ) : null}
                  <Icon
                    as={IoMdClose}
                    onClick={onClose}
                    cursor="pointer"
                    color="#8A91A6"
                  /> */}
            </Flex>
            <DrawerBody padding="0px">
              <Box width="100%" padding=" 0px 15px 0px 37px">
                <Divider paddingTop="10px" borderBottomWidth="3px" />
                <Tabs
                  onChange={handleTabChange}
                  isFitted
                  paddingTop="10px"
                  colorScheme="blue"
                  _selected={{ color: 'black' }}
                  boxShadow="none"
                >
                  <TabList
                    boxShadow="none"
                    textAlign="left"
                    letterSpacing="0px"
                    justifyContent="space-around"
                    fontWeight="600"
                    color="#AEB0B5"
                    _focus={{
                      outline: 'none',
                      borderBottom: '3px solid #006DB8',
                      color: '#006DB8',
                    }}
                  >
                    {Object.keys(detailTabs).map((eachTab: any) => {
                      return (
                        <Tab
                          key={eachTab}
                          fontWeight="600"
                          _selected={{
                            borderBottom: '3px solid #006DB8',
                            color: '#006DB8',
                          }}
                          _focus={{
                            outline: 'none',
                            background: 'none',

                            borderBottom: '3px solid #006DB8',
                          }}
                        >
                          {eachTab === 'Issue_Reported'
                            ? 'Issue Reported'
                            : eachTab}
                        </Tab>
                      );
                    })}
                  </TabList>

                  <TabPanels>
                    {Object.keys(detailTabs).map((r, i) => {
                      return <TabPanel key={r}>{detailTabs[r]}</TabPanel>;
                    })}
                  </TabPanels>
                </Tabs>
              </Box>
              {children}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Flex>
    </>
  );
};

const Header = styled(Text)`
  text-align: left;
  font: normal normal 600 30px/40px Montserrat;
  letter-spacing: 0px;
  color: #17244f;
`;

const Title = styled(Text)`
  text-align: left;
  font: normal normal normal 18px/24px Montserrat;
  letter-spacing: 0.29px;
  color: #8a91a6;
  opacity: 1;
`;
