/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: job_item.jsx
 *
 *  Description: UI for each job item
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 12-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { Typography } from '@material-ui/core';

import Card from '../../Common/custom_card';
import { getDateForJob } from '../../../helpers/date';
import { Row, Title } from '../../../styles/snowm_styled';
import ListTitleItem from '../../Generics/list_title_item';
import { getProviderByUid } from '../../../../controllers/snowm_firebase';

export const routesIncludingServiceTypes = ['indoor', 'outdoor'];

const JobItem = (props) => {
  const { job, isPeriodic } = props;

  const [crews, setCrews] = useState([]);
  const [jobDetail, setJobDetail] = useState();

  const getCrews = async (crewIds) => {
    const crewPromise = crewIds?.map((uid) => {
      const response = getProviderByUid(uid);
      return response;
    });

    const result = await Promise.all(crewPromise);

    return result;
  };

  useEffect(() => {
    if (isPeriodic) {
      setJobDetail({ ...job?.job, serviceType: job?.serviceType });
    } else {
      setJobDetail({ ...job });
    }
  }, [job]);

  useEffect(() => {
    let isSubscribe = true;
    if (jobDetail?.serviceKey) {
      getCrews(jobDetail?.providerUids).then((crewsDetails) => {
        if (isSubscribe) setCrews(crewsDetails);
      });
    }
    return () => {
      isSubscribe = false;
    };
  }, [jobDetail]);

  return (
    <Card>
      <Title>{job?.name}</Title>

      {!isPeriodic && (
        <Row justify="center" marginbottom="0">
          <SpanLeft>status: </SpanLeft>
          <SpanRight color="primary">{job?.status}</SpanRight>
        </Row>
      )}

      <DetailsContainer>
        <ListTitleItem
          title="Created At"
          content={getDateForJob(job.createdDate)}
        />

        {job?.status !== 'assigned' && (
          <ListTitleItem
            title="Started At"
            content={getDateForJob(job?.startedDate)}
          />
        )}

        <ListTitleItem
          title="Crew"
          content={crews.map((prov) => prov.name).join() || 'Deleted'}
        />
      </DetailsContainer>
    </Card>
  );
};

export default JobItem;

const SpanRight = styled(Typography)`
  && {
    text-transform: uppercase;
  }
`;

const SpanLeft = styled(Typography)`
  && {
    margin-right: 10px;
    text-transform: uppercase;
    font-weight: 100;
  }
`;

const DetailsContainer = styled.div`
  width: 100%;
`;
