import { useEffect, useState } from 'react';
import { Button, Stack, useToast } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { updatePassword } from '../../../controllers/snowm_firebase';
import SDialog from '../../../reusableComponent/SDialog';
import { SInput } from '../../Public/SInput';

interface PassChangeProps {
  userKey: string;
  username: string;
  handleCancel: () => void;
}

const PassChange = (props: PassChangeProps) => {
  const toast = useToast();

  const { userKey, handleCancel, username } = props;

  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
  });
  const [passwords, setPasswords] = useState({
    oldPassword: '',
    newPassword: '',
  });
  const [error, setError] = useState({
    oldPassword: '',
    newPassword: '',
  });

  const { register, handleSubmit, formState } = useForm<any>({
    defaultValues: passwords,
  });

  useEffect(() => {
    setError({
      oldPassword: '',
      newPassword: '',
    });
  }, [passwords.newPassword, passwords.oldPassword]);

  async function changePassword() {
    setLoading(true);

    try {
      await updatePassword(passwords.newPassword, passwords.oldPassword, true);
    } catch (err) {
      toast({
        duration: 3000,
        status: 'error',
        description:
          err?.message ?? 'Error occured while changing the password.',
      });
    }
    setLoading(false);
  }

  function handleInputChange(field: string, value: string) {
    setPasswords({ ...passwords, [field]: value });
  }

  function validateOldPassword() {
    const oldPass = passwords?.oldPassword;
    if (oldPass?.length > 0) {
      return true;
    }
    return 'Password must not be empty.';
  }

  function validateNewPassword() {
    if (passwords.newPassword.length < 8) {
      return 'Password must be at least 8 characters.';
    }
    if (passwords.newPassword === passwords.oldPassword) {
      return 'New password must be different from old password.';
    }
    if (passwords.newPassword.includes(' ')) {
      return 'New password must not contain spaces.';
    }
    return true;
  }

  const onClickNewPasswordIcon = () => {
    setShowPassword({
      ...showPassword,
      newPassword: !showPassword.newPassword,
    });
  };
  const onClickOldPasswordIcon = () => {
    setShowPassword({
      ...showPassword,
      oldPassword: !showPassword.oldPassword,
    });
  };
  return (
    <SDialog
      open
      size="lg"
      height="auto"
      loading={loading}
      positiveLabel="Change"
      title="Change Password"
      positiveActionDisabled={false}
      handleNegativeAction={handleCancel}
      handlePositiveAction={() => changePassword()}
      body="Enter your old password and new password"
    >
      <form onSubmit={handleSubmit(changePassword)}>
        <Stack mt="4" spacing="4">
          <SInput
            isRequired
            type={showPassword.oldPassword ? 'text' : 'password'}
            name="oldPassword"
            placeholder="Old Password * "
            value={passwords.oldPassword}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            showPassword={showPassword.oldPassword}
            showPasswordIcon
            onClickIcon={onClickOldPasswordIcon}
          />
          <SInput
            isRequired
            type={showPassword.newPassword ? 'text' : 'password'}
            name="newPassword"
            placeholder="New Password * "
            value={passwords.newPassword}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            showPassword={showPassword.newPassword}
            showPasswordIcon
            onClickIcon={onClickNewPasswordIcon}
          />
        </Stack>
        <Button type="submit" display="none" />
      </form>
    </SDialog>
  );
};

export default PassChange;
