import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import Loader from '../Components/Generics/snowm_loader';
import { AuthContext } from '../contexts/auth_context';
import { getRedirectPath } from '../helpers/misc';

// alternative = (string) route to be redirected to if the authentication go as expected
// shouldFail = (bool) to represent if failed authentication is expected

function renderProps(authenticated, shouldFail, altPath, Component, props) {
  if (authenticated === null) {
    return <Loader />;
  }

  if (
    (authenticated.canLogIn && !shouldFail) ||
    (!authenticated.canLogIn && shouldFail)
  ) {
    return <Component {...props} />;
  }

  return <Redirect to={altPath} />;
}

const AuthenticatedRoute = (props) => {
  return (
    <AuthContext.Consumer>
      {({ authenticated }) => {
        return <AuthRoute authenticated={authenticated} {...props} />;
      }}
    </AuthContext.Consumer>
  );
};

const AuthRoute = ({
  altPath,
  shouldFail,
  authenticated,
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        renderProps(authenticated, shouldFail, altPath, Component, props)
      }
    />
  );
};
AuthenticatedRoute.propTypes = {
  shouldFail: PropTypes.bool.isRequired,
  altPath: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
};

export const PrivateRoute = (props) => (
  <AuthenticatedRoute altPath="/choose-company" shouldFail={false} {...props} />
);
export const LoginRoute = (props) => {
  return (
    <AuthenticatedRoute altPath={getRedirectPath()} shouldFail {...props} />
  );
};
