import axios from 'axios';
import { get } from 'lodash';

export async function getAddressFromLongLat(lat, long) {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`;
  const locationData = await axios.get(url);
  return get(
    locationData,
    'data.results[0].formatted_address',
    'Unnamed place'
  );
}

export async function getLatLongFor(address) {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`;
  const locationData = await axios.get(url);
  return {
    latlng: get(locationData, 'data.results[0].geometry.location', {}),
    address: get(
      locationData,
      'data.results[0].formatted_address',
      'Unnamed place'
    ),
  };
}

export function decodeHash(encoded = '') {
  // array that holds the points

  const points = [];
  let index;
  let lat;
  let lng;
  const len = encoded ? encoded.length : 0;
  index = 0;
  lat = 0;
  lng = 0;
  while (index < len) {
    let b;
    let shift;
    let result;
    shift = 0;
    result = 0;
    do {
      // eslint-disable-next-line
      b = encoded.charAt(index++).charCodeAt(0) - 63; // and substract it by 63
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    // eslint-disable-next-line
    const dlat = (result & 1) != 0 ? ~(result >> 1) : result >> 1;
    lat += dlat;
    shift = 0;
    result = 0;
    do {
      // eslint-disable-next-line
      b = encoded.charAt(index++).charCodeAt(0) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    // eslint-disable-next-line
    const dlng = (result & 1) != 0 ? ~(result >> 1) : result >> 1;
    lng += dlng;

    points.push({ kx: lat / 1e5, ky: lng / 1e5 });
  }
  return points;
}

function py2Round(value) {
  return Math.floor(Math.abs(value) + 0.5) * (value >= 0 ? 1 : -1);
}

function encodeWithFactor(current, previous, factor) {
  current = py2Round(current * factor);
  previous = py2Round(previous * factor);
  let coordinate = current - previous;
  coordinate <<= 1;
  if (current - previous < 0) {
    coordinate = ~coordinate;
  }
  let output = '';
  while (coordinate >= 0x20) {
    output += String.fromCharCode((0x20 | (coordinate & 0x1f)) + 63);
    coordinate >>= 5;
  }
  output += String.fromCharCode(coordinate + 63);
  return output;
}

export function encodeOffsetToPolyline(coordinates, precision = 5) {
  if (coordinates.length === 0) {
    return '';
  }
  // eslint-disable-next-line
  const factor = Math.pow(10, precision);
  let output =
    encodeWithFactor(coordinates[0].kx, 0.0, factor) +
    encodeWithFactor(coordinates[0].ky, 0.0, factor);
  // eslint-disable-next-line
  for (let i = 1; i < coordinates.length; i++) {
    const a = coordinates[i];
    const b = coordinates[i - 1];
    output += encodeWithFactor(a.kx, b.kx, factor);
    output += encodeWithFactor(a.ky, b.ky, factor);
  }

  return output;
}
