import { Flex, Text } from '@chakra-ui/layout';
import { FormControl, HStack, PinInput, PinInputField } from '@chakra-ui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { CustomButton } from '../../../reusableComponent/CustomButton';
import color from '../../../utils/color';
import Span from '../../Common/Span';
import { LowerCaseButton } from '../../Common/styled';
import { Progress } from '../../Generics/snowm_loader';

interface OTPInterface {
  handleSendOTPButton: (_: any) => void;
  handleCodeChange: (_: any) => void;
  sendOtpCodeAgain: () => void;
  isSendingOTPAgain: boolean;
  fetching: boolean;
  otpSuccessResult: any;
}

export const VerifyCode = (props: OTPInterface) => {
  const {
    handleSendOTPButton,
    handleCodeChange,
    sendOtpCodeAgain,
    isSendingOTPAgain,
    fetching,
    otpSuccessResult,
  } = props;

  const { register } = useForm<any>({
    defaultValues: {},
  });

  return (
    <form
      onSubmit={handleSendOTPButton}
      style={{
        width: '100%',
      }}
    >
      <Flex gridGap="4" direction="column">
        <FormControl>
          <HStack
            name="verificationCode"
            isRequired
            register={register}
            justify="space-around"
          >
            <PinInput
              placeholder=""
              onChange={handleCodeChange}
              type="number"
              size="lg"
              autoFocus
            >
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </HStack>
        </FormControl>

        <CustomButton
          colorScheme="primary"
          type="submit"
          bgGradient="linear-gradient(to right, #006eb9, #00afee)"
          isLoading={fetching}
        >
          <Text color="white">Verify Code</Text>
        </CustomButton>
        {otpSuccessResult && (
          <LowerCaseButton
            onClick={sendOtpCodeAgain}
            style={{ flexBasis: '35%', backgroundColor: color.white }}
            disabled={fetching}
          >
            {isSendingOTPAgain ? (
              <Progress size={20} progressColor={color.white} />
            ) : (
              <Span color="primary">Resend Code</Span>
            )}
          </LowerCaseButton>
        )}
      </Flex>
    </form>
  );
};
