import * as React from 'react';

import styled from 'styled-components';
import { Divider } from '@material-ui/core';

import Span from '../../Common/Span';
import color from '../../../utils/color';
import { CustomSelect } from '../../../reusableComponent/shiftSchedule/custom_select';
import { LowerCaseButton } from '../../Common/styled';
import PrimaryButton from '../../Common/primary_button';
import { Col, Row } from '../../../styles/snowm_styled';
import { ServicePointsContext } from '../../../contexts/service_points';

import {
  getCrewsForJobs,
  storeConfigurableJob,
} from '../../../controllers/snowm_firebase';

// eslint-disable-next-line import/prefer-default-export
export const JobConfiguration = ({ handleCancel, isOpen }) => {
  const { fetchServicePoints, markers } =
    React.useContext(ServicePointsContext);
  const [crews, setCrews] = React.useState(null);
  const [selectedCrew, setSelectedCrew] = React.useState(null);
  const [isConfiguring, setIsConfiguring] = React.useState(false);
  const [markersOptions, setMarkersOptions] = React.useState(null);
  const [selectedMarkers, setSelectedMarkers] = React.useState(null);

  React.useEffect(() => {
    if (isOpen) {
      setSelectedCrew(null);
      setSelectedMarkers(null);
    }
  }, [isOpen]);

  React.useEffect(() => {
    getCrewsForJobs().then((crewsMembers) => {
      setCrews(crewsMembers);
    });
  }, []);

  React.useEffect(() => {
    const getMarkerLabel = (marker) => {
      if (!marker.latitude || !marker.longitude) {
        return 'Not configured';
      }
      return 'Configured';
    };

    if (!markers) {
      fetchServicePoints();
    } else {
      const options = Object.values(markers).reduce((acc, marker) => {
        return [
          ...acc,
          {
            label: `${marker.name} (${getMarkerLabel(marker)})`,
            value: marker.key,
          },
        ];
      }, []);

      setMarkersOptions(options);
    }
  }, [markers]);

  const handleCrewChange = (crewSelected) => {
    setSelectedCrew(crewSelected);
  };

  const handleMarkerChange = (markersSelected) => {
    setSelectedMarkers(markersSelected);
  };

  const checkIfAnyFieldIsEmpty = () => {
    return !selectedCrew || !selectedMarkers || selectedMarkers.length <= 0;
  };

  const handleConfigureButton = async () => {
    setIsConfiguring(true);
    const requestBody = {
      status: 'assigned',
      crewId: selectedCrew?.value,
      markers: selectedMarkers?.map((marker) => marker.value),
    };

    await storeConfigurableJob(requestBody);

    handleCancel();
    setIsConfiguring(false);
  };

  return (
    <StyledCol gap="8px">
      <Row justify="center">
        <Span weight="bold" size="16px">
          Configure Marker
        </Span>
      </Row>
      <Divider />
      <Col>
        <Span weight="bold">Select Crew:</Span>
        <CustomSelect
          options={crews}
          onChange={handleCrewChange}
          value={selectedCrew}
        />
      </Col>

      <Divider />

      <Col>
        <Span weight="bold">Select Markers:</Span>
        <CustomSelect
          options={markersOptions}
          isMulti
          onChange={handleMarkerChange}
          value={selectedMarkers}
        />
      </Col>

      <Row justify="flex-end">
        <LowerCaseButton onClick={() => handleCancel(true)} color="primary">
          <Span>Cancel</Span>
        </LowerCaseButton>
        {checkIfAnyFieldIsEmpty() ? (
          <LowerCaseButton variant="contained" disabled color="primary">
            <Span>Configure</Span>
          </LowerCaseButton>
        ) : (
          <PrimaryButton
            onClick={handleConfigureButton}
            loading={isConfiguring}
          >
            <Span color={color.white}>Configure</Span>
          </PrimaryButton>
        )}
      </Row>
    </StyledCol>
  );
};

const StyledCol = styled(Col)`
  padding: 16px;
`;
