import React, { useState } from 'react';
import { Flex, Text } from '@chakra-ui/layout';
import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { checkIfEmailExists } from '../../../controllers/snowm_firebase';
import { lowerCase } from '../../../helpers/string';
import ADialog from '../../Common/styled';
import ForgotPassword from '../ResetPassword';

interface EmailInterface {
  setIsEmailVerified: (isVerified: boolean) => void;
  handleEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleLogin: (_: LoginMethodType) => void;
}

export interface NewUserCheck {
  newUser: boolean | null;
}

// eslint-disable-next-line no-shadow
export enum LoginMethod {
  Email = 'email',
  PhoneNumber = 'phoneNumber',
}

export type LoginMethodType = LoginMethod.Email | LoginMethod.PhoneNumber;

export const Email = (props: EmailInterface) => {
  const toast = useToast();

  const { setIsEmailVerified, handleEmailChange, handleLogin } = props;
  const [email, setEmail] = useState('');
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);
  const [open, setOpen] = useState(false);

  const { register, handleSubmit, errors } = useForm<any>({
    defaultValues: {},
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target ?? {};
    const lowerCasedEmail = lowerCase(value);
    setEmail(lowerCasedEmail);
    handleEmailChange(event);
  };

  const checkEmail = async () => {
    setIsCheckingEmail(true);
    const auth = await checkIfEmailExists(email);
    if (!auth.length) {
      toast({
        duration: 3000,
        status: 'error',
        description: 'User not allowed to login to the marker admin web.',
      });
      setIsCheckingEmail(false);
    } else {
      setIsEmailVerified(true);
    }
  };
  function handleForgetPassword(event) {
    event.preventDefault();
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(checkEmail)}
        style={{
          width: '100%',
        }}
      >
        <Flex flexDirection="column" width="100%" gridGap="1.5rem">
          <FormControl>
            <Input
              isRequired
              type="email"
              name="email"
              register={register}
              placeholder="Email *"
              onChange={(e) => handleInputChange(e)}
              height="51px"
              padding="16px"
            />
            {errors.name && <FormErrorMessage>Invalid Value</FormErrorMessage>}
          </FormControl>
          <Flex
            justifyContent="center"
            marginBottom="0"
            alignItems="center"
            color="primary.400"
            fontWeight="bold"
            cursor="pointer"
            onClick={handleForgetPassword}
          >
            Forgot Password
          </Flex>
          <NextButton text="Next" loading={isCheckingEmail} />
          <div style={{ textAlign: 'center' }}>or</div>
          <LoginToggleButton
            text=" Log In Using Phone Number"
            shouldDisableButton={isCheckingEmail}
            handleClick={() => handleLogin(LoginMethod.PhoneNumber)}
          />
        </Flex>
      </form>
      <ADialog
        onClose={handleClose}
        open={open}
        // width="15%"
      >
        <ForgotPassword handleDialog={handleClose} {...props} open={open} />
      </ADialog>
    </>
  );
};

interface NextButtonProps {
  text: string;
  loading: boolean;
}

export const NextButton = (props: NextButtonProps) => {
  const { text, loading } = props;
  return (
    <Button
      type="submit"
      colorScheme="primary"
      boxShadow="0px 3px 6px #006db85c"
      background={`transparent linear-gradient(
      90deg, #006EB9 0%, #00AFEE 100%) 0% 0% no-repeat padding-box`}
      line-height="2.5"
      color="#fff"
      borderColor="primary.400"
      isLoading={loading}
      _hover={{
        border: '1px solid primary.400',
        borderColor: 'primary.400',
      }}
      height="51px"
      padding="16px"
    >
      <Text color="white">{text}</Text>
    </Button>
  );
};

interface LoginToggleButtonProps {
  text: string;
  shouldDisableButton: boolean;

  handleClick: () => void;
}

export const LoginToggleButton = (props: LoginToggleButtonProps) => {
  const { text, handleClick, shouldDisableButton } = props;
  return (
    <Button
      colorScheme="white"
      onClick={handleClick}
      border="1px solid transparent"
      line-height="2.5"
      borderColor="primary.400"
      color="primary.400"
      boxShadow="0px 3px 6px primary.400"
      disabled={shouldDisableButton}
      height="51px"
      padding="16px"
      _hover={{ background: 'primary.400', color: 'white' }}
    >
      {text}
    </Button>
  );
};
