import {
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  Flex,
  Grid,
  GridItem,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FormControl } from '@material-ui/core';
import { ResponsiveRadialBar } from '@nivo/radial-bar';
import { cubicBezier, Gauge } from 'gauge-chart-js';
import AccountClockIcon from 'mdi-react/AccountClockIcon';
import BatteryAlertIcon from 'mdi-react/BatteryAlertIcon';
import BackArrowIcon from 'mdi-react/ChevronRightIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import MapIcon from 'mdi-react/MapIcon';
import MotionPlayIcon from 'mdi-react/MotionPlayIcon';
import PlayIcon from 'mdi-react/PlayIcon';
import TimerIcon from 'mdi-react/TimerIcon';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import styled from 'styled-components';
import { LocalizationContext } from '../../../contexts/localization_context';
import { ServiceTypesContext } from '../../../contexts/service_types';
import {
  getLatestJobs,
  getPropertiesFromFirestore,
  getRealtimeCompanyDetails,
  getRealTimeLocationOfAllProviders,
  getRealTimeServicePointsWithBattery,
} from '../../../controllers/snowm_firebase';
import {
  EntityCount,
  SeeMoreInfo,
} from '../../../reusableComponent/EntityCount';
import { RowEntityCount } from '../../../reusableComponent/RowEntityCount';
import { SCard } from '../../../reusableComponent/SCard';
import { Col, Row } from '../../../styles/snowm_styled';
import Typography from '../../Common/Span';
import ADialog from '../../Common/styled';
import Loader from '../../Generics/snowm_loader';
import Map from '../Maps/snowm_maps';
import { LoadContainer, Log, TitleContainer } from './styled';
import '../../../App.css';

// require('chart.js');
// require('chartjs-chart-radial-gauge');

const Home = ({ history, fetchProviders, logs = [], getLogs, theme }) => {
  const [markers, setMarkers] = useState();
  const [latLng, setLatLng] = useState({});
  const [properties, setProperties] = useState();
  const [activities, setActivities] = useState();
  const [company, setCompany] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [logType, setLogType] = useState('All');
  const [filteredLogs, setFilteredLogs] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [devices, setDevices] = useState(null);
  const [locations, setLocations] = useState(null);
  const [latestJobs, setLatestJobs] = useState(null);
  const [logSortType, setLogSortType] = useState({
    value: 'all',
    label: 'All',
  });
  const [selectedLocation, setSelectedLocation] = useState([
    {
      value: 'all',
      label: 'All',
    },
  ]);
  const { strings } = useContext(LocalizationContext);

  const serviceFeatures = useContext(ServiceTypesContext);

  const subscribedServices = serviceFeatures?.subscribedServices;

  const {
    home: { LIVE_FEED },
    sidemenu: { MICROLOCATIONS, TASKS, LOCATIONS },
  } = strings;

  const logsRef = useRef();

  const btnRef = useRef();
  const mapRef = useRef();
  const mapDrawer = useDisclosure();
  const animatedComponents = makeAnimated();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const logTypes = [
    'All',
    'Service Request',
    'Incident Reported',
    'Job Information',
    'Job Exception (Late or no show)',
    'General Information',
  ];

  const locationOptions = locations?.map((loc) => ({
    label: loc.name,
    value: loc.id,
  }));

  const logsSortOptions = logTypes?.map((log) => ({
    label: log,
    value: log,
  }));

  function getLogTypeColor(typeLog) {
    switch (typeLog) {
      case 'Service Request':
        return 'blue';
      // return color.snomwBlue;
      case 'Incident Reported':
        // return color.red;
        return 'red';
      case 'Job Information':
        // return color.amber;
        return 'amber';
      case 'Job Exception (Late or no show)':
        // return color.red;
        return 'red';
      default:
        // return color.snomwBlue;
        return 'blue';
    }
  }

  const easeIn = cubicBezier(0, 0, 0.2, 1);

  useEffect(() => {
    if (mapRef.current) {
      const gauge = new Gauge({
        container: document.getElementById('hh'),
        color: '#0f0',
        easing: easeIn,
      });

      gauge.setValue(10);
    }
  }, [mapRef.current]);

  useEffect(() => {
    if (subscribedServices?.properties) {
      setProperties(company?.properties);
    }

    if (subscribedServices?.activities || subscribedServices?.activitiesOnJob) {
      setActivities(company?.activities);
    }

    setMarkers(company?.servicePoints);
  }, [company, subscribedServices]);

  useEffect(() => {
    fetchProviders();
    getLogs();
    getRealtimeCompanyDetails(setCompany);
  }, []);

  useEffect(() => {
    if (logType === 'All') {
      setFilteredLogs(logs);
    } else {
      const logsByLogType = logs?.filter((log) => log.topic === logType);
      setFilteredLogs(logsByLogType);
    }
  }, [logType, logs]);

  useEffect(() => {
    function updateServicePoints(res) {
      setDevices(res);
    }
    return getRealTimeServicePointsWithBattery(updateServicePoints);
  }, []);

  useEffect(() => {
    function updateJobs(res) {
      setLatestJobs(res);
    }
    return getLatestJobs(updateJobs);
  }, []);

  useEffect(() => {
    let isSubscribe = true;

    function onChange(loc) {
      const latlongOfCrew = Object.entries(loc ?? {}).reduce(
        (accumulator, data) => {
          if (data[1].latitude) {
            return [...accumulator, { uid: data[0], ...data[1] }];
          }

          return accumulator;
        },
        []
      );

      if (isSubscribe) {
        setLatLng([...(latlongOfCrew ?? [])]);
      }
    }

    getRealTimeLocationOfAllProviders(onChange);
    return () => {
      isSubscribe = false;
    };
  }, []);
  useEffect(() => {
    let isSubscribe = true;
    function _updateProperties(response) {
      if (isSubscribe) {
        setLocations(response);
      }
    }

    getPropertiesFromFirestore(_updateProperties);

    return () => {
      isSubscribe = false;
    };
  }, []);

  function filterWithLocation(entities) {
    if (selectedLocation[(selectedLocation?.length ?? 0) - 1]?.value === 'all')
      return entities;
    return entities?.filter((e) =>
      e.propertyKeys?.includes(selectedLocation?.value)
    );
  }

  function getLogsList() {
    if (filterWithLocation(filteredLogs).length === 0) {
      return (
        <LoadContainer>
          <Typography> No logs ...</Typography>
        </LoadContainer>
      );
    }

    function handleImageSelected(imageUrl) {
      setOpenDialog(true);
      setSelectedImage(imageUrl);
    }

    function handleLogClick(l) {
      if (l.route) {
        history.push(l.route);
      }
    }

    return filterWithLocation(filteredLogs).map((l) => (
      <Log
        className="check"
        key={l.key}
        onClick={() => handleLogClick(l)}
        cursor={!!l.route}
      >
        <Row>
          <Text fontWeight="bold" fontSize="13px" marginRight="8px">
            {l.title}
          </Text>
          <Badge colorScheme={getLogTypeColor()} variant="subtle" size="xs">
            <span style={{ fontSize: 10 }}>
              {l.topic ? l.topic : 'General Information'}
            </span>
          </Badge>
        </Row>
        <Row align="center">
          {l.imageUrl && (
            <StyledImageContainer
              type="button"
              onClick={() => handleImageSelected(l.imageUrl)}
            >
              <Image
                src={l.imageUrl}
                alt="Report"
                height={50}
                width={50}
                borderRadius="50%"
              />
            </StyledImageContainer>
          )}
          <Typography size="13px">{l.message}</Typography>
        </Row>
        <hr style={{ color: 'black' }} />
        {/* <Date size="12px" color="primary">
            {moment(l.date).format('MMMM DD, YYYY, h:mm A')}
          </Date> */}

        <div ref={logsRef} />
      </Log>
    ));
  }

  function handleLogTypeChange(event) {
    setLogType(event.target.value);
  }

  function getLogsContainer(t) {
    return (
      <Box className="logss" style={{ height: '100%' }}>
        <TitleContainer>
          <Text fontSize="1em" fontWeight="bold" marginLeft="10px">
            {LIVE_FEED}
          </Text>
          <FormControl
            style={{
              width: 200,
            }}
          >
            <Flex alignItems="center">
              <Text whiteSpace="nowrap" marginRight="7px" fontSize="12px">
                Sort By :
              </Text>
              {/* TODO: here use react select  */}

              <ReactSelect
                options={logsSortOptions}
                styles={{
                  menu: (provided, state) => ({
                    ...provided,
                    width: 150,
                    height: 25,
                    fontSize: 12,
                  }),
                  control: () => ({
                    width: 150,
                    height: 25,
                    display: 'flex',
                    border: 'solid 1px #ECEEF5',
                    borderRadius: 5,
                    fontSize: 12,
                    textAlign: 'left',
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    color: 'black',
                    display: 'flex',
                    textAlign: 'left',
                    padding: 4,
                    fontSize: 12,
                    background: state.isSelected ? '#F8F8F8' : 'white',
                    '&:hover': {
                      background: '#F8F8F8',
                    },
                  }),
                }}
                components={animatedComponents}
                value={logSortType}
                onChange={(selectedValue) => {
                  setLogSortType(selectedValue);
                  setLogType(selectedValue.value);
                }}
              />
              {/* <Select
                style={{ overflow: 'hidden' }}
                value={logType}
                onChange={handleLogTypeChange}
              >
                {logTypes.map((typeOfLog) => (
                  <MenuItem key={typeOfLog} value={typeOfLog}>
                    <Typography fon={color.black}>{typeOfLog}</Typography>
                  </MenuItem>
                ))}
              </Select> */}
            </Flex>
          </FormControl>
        </TitleContainer>
        <Box
          className="content"
          overflowY="scroll"
          margin="8px"
          height="90%"
          // style={{
          //   height: '340px',
          //   overflowY: 'scroll',
          //   margin: 8,
          //   borderRadius: 8,
          // }}
        >
          {getLogsList(logs, t)}
        </Box>
      </Box>
    );
  }

  function renderProperties() {
    if (properties) {
      return (
        <EntityCount
          count={properties ?? 0}
          text={LOCATIONS}
          onPress={() => history.push('/admin/locations')}
        />
      );
    }
    return null;
  }

  function renderMarkers() {
    if (markers) {
      return (
        <EntityCount
          count={markers ?? 0}
          text={MICROLOCATIONS}
          onPress={() => history.push('/admin/micro-locations')}
        />
      );
    }
    return null;
  }

  function renderActivitiesDetail() {
    if (activities) {
      return (
        <EntityCount
          count={activities ?? 0}
          text={TASKS}
          onPress={() => history.push('/admin/tasks')}
        />
      );
    }
    return null;
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  if (!company || !subscribedServices || !devices || !latestJobs) {
    return <Loader />;
  }

  function renderUserMap() {
    return (
      <div className="dashboardMap">
        <Map
          dashboard
          locations={latLng}
          center={[45.347767, -75.90983]}
          zoom={9}
        />
      </div>
    );
  }
  function renderLocation() {
    return (
      <div className="dashboardMap">
        <Map
          dashboard
          locations={latLng}
          center={[45.347767, -75.90983]}
          zoom={9}
        />
      </div>
    );
  }
  const detailTabs = {
    Location: renderLocation(),
    // User: renderUserMap(),
  };

  // function MultiValueLabel(props) {
  //   // const { innerProps, innerRef, data } = props;
  //   return (
  //     <components.Option {...props}>
  //       <Box border="1px solid #959595" borderRadius=" 4px">
  //         <Text fontSize="12px" color="#A1B3C8">
  //           test
  //         </Text>
  //       </Box>
  //     </components.Option>
  //   );
  // }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: '12px',
      fontWeight: 'normal',
    }),
    menu: () => ({
      bottom: 90,
      width: 300,
      borderRadius: 4,
      backgroundColor: 'white',
      boxShadow: '0px 1px 4px gray',
    }),
    control: () => ({
      width: 300,
      display: 'flex',
      borderRadius: 4,
      backgroundColor: 'white',
      border: 'solid 1px #E2E8F0',
    }),
    dropdownButton: (provided) => ({
      ...provided,
      width: 300,
      borderRadius: 4,
    }),
  };
  const getSearchFilter = () => {
    Object(detailTabs).map((i) => {
      return (
        <>
          <Text marginRight="10px">{i}</Text>
          <ReactMultiSelectCheckboxes
            options={locationOptions}
            menuPlacement="auto"
            styles={customStyles}
            onChange={(selectedValue) => {
              console.log('value', selectedValue);
            }}
            value={selectedLocation}
          />
        </>
      );
    });
  };
  return (
    <>
      <Flex height="100%" width="100%">
        <StyledCotainer width="100%">
          <Flex alignItems="center" marginBottom="20px">
            <Text marginRight="10px">Location</Text>
            <ReactMultiSelectCheckboxes
              options={locationOptions}
              menuPlacement="auto"
              styles={customStyles}
              onChange={(selectedValue) => {
                setSelectedLocation(selectedValue);
              }}
              value={selectedLocation}
            />
            {/* 
            <ReactSelect
              options={[
                {
                  value: 'all',
                  label: 'All',
                },
                ...locationOptions,
              ]}
              isMulti
              clearable={false}
              isClearable={false}
              styles={{
                menu: (provided, state) => ({
                  ...provided,
                  width: 300,
                  height: 40,
                  background: 'white',
                }),
                control: () => ({
                  width: 300,
                  height: 45,
                  display: 'flex',
                  border: 'solid 1px #ECEEF5',
                  borderRadius: 5,
                  background: 'white',
                  alignItems: 'center',
                }),
                option: (provided, state) => ({
                  ...provided,
                  color: state.isSelected ? '#F8F8F8' : 'black',
                  padding: 4,
                  fontSize: 14,
                  background: state.isSelected ? '#F8F8F8' : 'white',
                }),
                multiValueLabel: (base) => ({
                  ...base,
                  backgroundColor: 'white',
                  color: 'black',
                  border: '1px solid #959595',
                  borderRadius: ' 4px',
                }),
              }}
              isSearchable
              value={selectedLocation}
              onChange={(selectedValue) => {
                setSelectedLocation(selectedValue);
              }}
              components={{ Option }}
            /> */}
          </Flex>

          <Grid
            height="100%"
            templateColumns="repeat( auto-fit, minmax(150px, 1fr) )"
            className="grid1"
            // templateColumns="repeat(12, 1fr)"
            gap={2}
          >
            <GridItem overflow="hidden" height="100%" colSpan={3}>
              <SCard overflow="hidden" height="50%">
                {getLogsContainer(theme)}
              </SCard>
              <Flex height="50%" marginTop="25px">
                <Box flexGrow={1}>
                  <StatisticsCard
                    latestJobs={filterWithLocation(latestJobs)}
                    mapRef={mapRef}
                  />
                </Box>
                <Box
                  background="transparent linear-gradient(144deg, #FF4A44 0%, #B31217 100%) 0% 0% no-repeat padding-box"
                  borderRadius="12px"
                  color="white"
                  padding="12px"
                  marginLeft="6px"
                  height="fit-content"
                  // width="187px"
                  // marginLeft="auto"
                >
                  <Flex direction="column" height="100%">
                    <Text fontWeight="bold" fontSize="40px" color="white">
                      {Object.values(devices ?? {}).length ?? 0}
                    </Text>
                    <>
                      <Flex direction="column" marginTop="20px">
                        <Icon
                          height="30px"
                          width="fit-content"
                          as={BatteryAlertIcon}
                        />
                        <span style={{ fontWeight: 'bold', marginTop: 10 }}>
                          Low Battery Sensors
                        </span>
                        <span style={{ fontSize: 12 }}>between 0% - 20%</span>
                      </Flex>
                      <SeeMoreInfo justify="flex-start" color="white" />
                    </>
                  </Flex>
                </Box>
              </Flex>
              {/* <GridItem paddingTop="15px" colSpan={1}> */}

              {/* </GridItem> */}
            </GridItem>

            <GridItem colSpan={2}>
              <ShiftCard
                company={company}
                allShifts={filterWithLocation(latestJobs)}
              />
            </GridItem>
            {activities || properties || markers ? (
              <GridItem colSpan={2}>
                <SCard height="fit-content">
                  <Flex alignItems="center" justifyContent="space-between">
                    <Text
                      fontSize="1em"
                      fontWeight="bold"
                      marginBottom="0.5rem"
                    >
                      Business Information
                    </Text>
                  </Flex>
                  <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                    {renderActivitiesDetail()}
                    {renderProperties()}
                    {renderMarkers()}
                  </Grid>
                </SCard>
              </GridItem>
            ) : (
              <div />
            )}

            {/* <GridItem>
              <Flex height="300px">
              </Flex>
            </GridItem> */}
          </Grid>
        </StyledCotainer>
        <Flex
          background="#FFFFFF 0% 0% no-repeat padding-box"
          boxShadow="0px 12px 24px #ECEEF5"
          onClick={mapDrawer.onOpen}
          cursor="pointer"
          direction="column"
          justifyContent="center"
          height="100vh"
          position="relative"
        >
          {/* <Box
            backgroundColor="white"
            style={{
              position: 'absolute',
              right: '22px',
              top: '224px',
              height: '380px',
              width: '15px',
              borderRadius: '193% 10% 30% 185% / 65% 70% 51% 51%',
              // transform: 'matrix(-1, 0, 0, -1, 0, 0)',
            }}
          /> */}
          <Flex className="second-map" direction="column">
            <BackArrowIcon />
            <Text
              style={{
                writingMode: 'vertical-rl',
                transform: 'rotate(-180deg)',
              }}
            >
              {' '}
              Map View{' '}
            </Text>
            <MapIcon />
          </Flex>

          {/* TODO: here add icon */}
        </Flex>
        {/* map view */}

        {/* <div className="curve-mask">
          <div className="curve-down">
            <div className="para standard-section" id="compare">
              <div className="curve-content">
                <div className="row">
                  <div className="medium-12 columns">
                    <h1>Responsive Curved Divs with Foundation 5</h1>
                    <h2>H2 Font</h2>
                    <h3>H3 Font</h3>
                    <h4>H4 Font</h4>
                    <h5>Disclaimer</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/*         
        <div className="curve-mask">
          <div className="curve-up">
            <div className="plain standard-section">
              <div className="curve-content">
                <div className="row">
                  <div className="medium-12 columns">
                    <h1>Curve Up</h1>
                    <h2>H2 Font</h2>
                    <h3>H3 Font</h3>
                    <h4>H4 Font</h4>
                    <h5>Disclaimer</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="curve-mask">
          <div className="curve-up">
            <div className="plain standard-section">
              <div className="curve-content">
                <p> side ko ho</p>
              </div>
            </div>
          </div>
        </div> */}
      </Flex>
      {/* This is map drawer */}
      <Drawer
        isOpen={mapDrawer.isOpen}
        placement="right"
        onClose={() => {
          mapDrawer.onClose();
          setTabIndex(0);
        }}
        finalFocusRef={btnRef}
        size="xl"
      >
        <DrawerContent>
          <Flex>
            <Flex
              background="var(--unnamed-chaolor-ffffff) 0% 0% no-repeat padding-box"
              // boxShadow="0px 12px 24px #ECEEF5"
              onClick={mapDrawer.onClose}
              cursor="pointer"
              justifyContent="center"
              direction="column"
              pl="5px"
            >
              {/* <Box
                backgroundColor="white"
                style={{
                  position: 'absolute',
                  left: '-14px',
                  top: '225px',
                  height: '380px',
                  width: '15px',
                  borderRadius: '193% 10% 30% 185% / 65% 70% 51% 51%',
                  // transform: 'matrix(-1, 0, 0, -1, 0, 0)',
                }}
              /> */}
              <Flex className="map" direction="column">
                <BackArrowIcon />
                <Text
                  style={{
                    writingMode: 'vertical-rl',
                    transform: 'rotate(-180deg)',
                  }}
                >
                  {' '}
                  Map View{' '}
                </Text>
                <MapIcon />
              </Flex>

              {/* TODO: here add icon */}
            </Flex>
            <DrawerBody padding="0px">
              <Tabs
                onChange={(tab) => setTabIndex(tab)}
                colorScheme="blue"
                size="sm"
                variant="unstyled"
              >
                <Flex
                  pt="sm"
                  alignItems="flex-start"
                  justifyContent="space-between"
                  pb="sm"
                >
                  <TabList marginLeft="md">
                    {Object.keys(detailTabs).map((r) => {
                      return (
                        <Tab
                          font
                          _selected={{
                            borderBottom: '2px solid #006DB8',
                            color: '#006DB8',
                            fontWeight: '600',
                          }}
                          key={r}
                          _focus={{
                            outline: 'none',
                            background: 'none',
                            borderBottom: '2px solid #006DB8',
                            fontWeight: 'semibold',
                          }}
                          _active={{ color: 'blue.200' }}
                          color="primary.600"
                        >
                          {r}
                        </Tab>
                      );
                    })}
                  </TabList>
                  <Flex className="center" marginBottom="20px">
                    {/* {getSearchFilter()} */}
                    {tabIndex === 0 ? (
                      <Flex alignItems="center" className="check12">
                        {/* <Text marginRight="10px">Location</Text> */}
                        {/* <ReactMultiSelectCheckboxes
                          options={locationOptions}
                          menuPlacement="auto"
                          styles={customStyles}
                          onChange={(selectedValue) => {
                            console.log('value', selectedValue);
                          }}
                          value={selectedLocation}
                        /> */}
                      </Flex>
                    ) : (
                      <Flex alignItems="center">
                        <Text marginRight="10px">User</Text>
                        {/* <ReactMultiSelectCheckboxes
                          options={locationOptions}
                          menuPlacement="auto"
                          styles={customStyles}
                          onChange={(selectedValue) => {
                            console.log('value', selectedValue);
                          }}
                          value={selectedLocation}
                        /> */}
                      </Flex>
                    )}
                  </Flex>
                </Flex>

                <TabPanels>
                  {Object.keys(detailTabs).map((r) => {
                    return (
                      <TabPanel
                        paddingTop="0px"
                        paddingBottom="0px"
                        paddingLeft="18px"
                        key={r}
                      >
                        {detailTabs[r]}
                      </TabPanel>
                    );
                  })}
                </TabPanels>
              </Tabs>
            </DrawerBody>
          </Flex>
        </DrawerContent>
      </Drawer>
      <ADialog open={openDialog}>
        <ImageModalContainer gap="4px">
          <Row justify="space-between" marginbottom="0">
            <Typography weight="bold" size="16px">
              Reported Site
            </Typography>
            <CloseIcon onClick={handleCloseDialog} cursor="pointer" />
          </Row>
          <Divider />
          <Image
            height={250}
            src={selectedImage}
            alt="Reporting site"
            borderRadius="8px"
          />
        </ImageModalContainer>
      </ADialog>
    </>
  );
};

const ShiftCard = ({ company, allShifts }) => {
  const { strings } = useContext(LocalizationContext);
  const [toggleShift, setToggleShift] = useState({
    started: true,
    notStarted: true,
  });
  const [shifts, setShifts] = useState();
  const [tab, setTab] = useState('today');

  const todayTimestamp = moment().startOf('day').valueOf();
  const now = moment().valueOf();
  const thirtyMin = 30 * 60 * 1000;
  const thirtyMinFromNow = now + 30 * 60 * 1000;

  function filterShifts(filterType) {
    if (filterType === 'today') {
      const todaysShifts = allShifts.filter(
        (shift) => todayTimestamp <= shift.createdDate
      );
      setShifts(todaysShifts);
      return;
    }

    const lastWeek = allShifts.filter(
      (shift) => shift.createdDate <= todayTimestamp
    );
    setShifts(lastWeek);
  }

  useEffect(() => {
    filterShifts(tab);
  }, [allShifts]);

  const {
    status: { ASSIGNED, STARTED },
    sidemenu: { SHIFTS },
  } = strings;

  const handleTabChange = (val) => {
    if (val === 0) {
      setTab('today');
      filterShifts('today');
    } else {
      filterShifts('week');
      setTab('week');
    }
  };

  if (!shifts) return null;

  function checkIfStatedOnTime(shift) {
    if (shift.startDate < shift.createdDate) {
      return true;
    }
    if (
      shift.startDate > shift.createdDate &&
      shift.startDate - shift.createdDate <= thirtyMin
    ) {
      return true;
    }
    return false;
  }

  function checkIfStatedLate(shift) {
    if (
      shift.startDate > shift.createdDate &&
      shift.startDate - shift.createdDate > thirtyMin
    )
      return true;
    return false;
  }

  const counts = {
    assigned: 0,
    started: 0,
    notStarted: 0,
    missed: 0,
    startedOnTime: 0,
    startedLate: 0,
    notStartedOnTime: 0,
  };

  shifts.forEach((s) => {
    if (s.status === 'assigned') {
      counts.assigned += 1;
    }

    if (s.status === 'started') {
      counts.started += 1;
      if (checkIfStatedOnTime(s)) {
        // startedo n time
        // if startTime and createdTime diff is less than 30 mins
        // and or startTime is before the createdTime
        counts.startedOnTime += 1;
      }
      if (checkIfStatedLate(s)) {
        // started late
        // if startTime and createdTime diff is more than 30 mins
        // and or startTime is not before the createdTime
        counts.startedLate += 1;
      }
    }
    if (s.status === 'not_started') {
      counts.missed += 1;
    }
    if (s.status === 'assigned' && s.createdDate > thirtyMinFromNow) {
      counts.notStarted += 1;
    }
  });

  return (
    <SCard height="auto">
      <Grid templateColumns="repeat(1, 1fr)" gap={2}>
        <Flex alignItems="center" justifyContent="space-between">
          <Text fontSize="1em" fontWeight="bold">
            {SHIFTS}
          </Text>
          <TodayWeekTab handleTabChange={handleTabChange} />
        </Flex>

        <RowEntityCount
          count={counts?.assigned ?? 0}
          text={`${ASSIGNED}:`}
          icon={<TimerIcon />}
        />

        <RowEntityCount
          count={counts?.started ?? 0}
          text={`${STARTED}:`}
          icon={<PlayIcon />}
          onPress={() => {
            setToggleShift({
              ...toggleShift,
              started: !toggleShift.started ?? true,
            });
          }}
        >
          <hr
            style={{
              margin: '5px',
              display: toggleShift.notStarted ? 'block' : 'none',
            }}
          />

          <DetailShiftCard
            show={toggleShift.started}
            displayData={{
              // TODO: here calculate and display count of shifts
              'On-Time': `${counts.startedOnTime} shifts started on time`,
              Late: ` ${counts.startedLate}  shift started late`,
            }}
          />
        </RowEntityCount>

        <RowEntityCount
          count={counts?.notStarted ?? 0}
          text="Not Started:"
          icon={<MotionPlayIcon />}
          onPress={() => {
            setToggleShift({
              ...toggleShift,
              notStarted: !toggleShift.notStarted ?? true,
            });
          }}
        >
          <hr
            style={{
              margin: '5px',
              display: toggleShift.notStarted ? 'block' : 'none',
            }}
          />

          <DetailShiftCard
            show={toggleShift.notStarted}
            displayData={{
              // TODO: here calculate and display count of shifts
              'Not Started On-Time': `${counts.notStarted} shifts not started on time`,
              'Running Late': '0 shift is running late',
            }}
          />
        </RowEntityCount>

        <RowEntityCount
          count={counts?.missed ?? 0}
          text="Missed Shifts"
          color="white"
          icon={<AccountClockIcon />}
          background="transparent linear-gradient(107deg, #FF4A44 0%, #B31217 100%) 0% 0% no-repeat padding-box"
        />

        {/* <SeeMoreInfo onPress={() => history.push('/admin/jobs')} /> */}
      </Grid>
    </SCard>
  );
};

function getColor(percentage) {
  if (percentage < 90) {
    return '#F1963A';
  }
  return '#5EC778';
}
const MyResponsiveRadialBar = ({ data, percentage }) => {
  return (
    <ResponsiveRadialBar
      data={data}
      valueFormat=">-.2f"
      padding={0.5}
      innerRadius={0.6}
      enableRadialGrid={false}
      enableCircularGrid={false}
      isInteractive={false}
      startAngle={-140}
      endAngle={140}
      margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
      colors={[getColor(percentage), 'gray']}
      style={{ position: 'relative' }}
      positive="relative"
      circularAxisOuter={false}
      radialAxisStart={false}

      // radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
      // circularAxisOuter={{ tickSize: 0, tickPadding: 0, tickRotation: 0 }}
    />
  );
};

const DetailShiftCard = ({ show, displayData }) => {
  if (!show) {
    return null;
  }

  return Object.keys(displayData).map((d) => (
    <Flex direction="column" marginBottom="6px" key={d}>
      <Text fontSize="12px" marginBottom="4px">
        {d}
      </Text>
      <Flex
        background={
          d === 'Running Late'
            ? ' transparent linear-gradient(96deg, #FF4A44 0%, #B31217 100%) 0% 0% no-repeat padding-box'
            : '#E6F1F7 0% 0% no-repeat padding-box'
        }
        justifyContent="space-between"
        alignItems="center"
        borderRadius={4}
        padding="4px"
      >
        <Text color={d === 'Running Late' ? 'white' : null} fontSize="12px">
          {displayData[d]}{' '}
        </Text>
        <BackArrowIcon color="#1C2852" size={18} />
      </Flex>
    </Flex>
  ));
};

const TodayWeekTab = ({ handleTabChange }) => (
  <Tabs colorScheme="blue" onChange={handleTabChange} size="sm">
    <TabList>
      <Tab
        color="grey.200"
        fontSize="mdsm"
        _selected={{
          borderBottom: '1px solid #006DB8',
          color: '#006DB8',
        }}
        _focus={{
          outline: 'none',
          background: 'none',
          borderBottom: '1px solid #006DB8',
        }}
        _active={{ color: 'blue.200', fontWeight: 'bold' }}
      >
        Today
      </Tab>
      <Tab
        fontSize="mdsm"
        color="grey.200"
        _selected={{
          borderBottom: '1px solid #006DB8',
          color: '#006DB8',
        }}
        _focus={{
          outline: 'none',
          background: 'none',
          borderBottom: '1px solid #006DB8',
        }}
        _active={{ color: 'blue.200', fontWeight: 'bold' }}
      >
        Week
      </Tab>
    </TabList>
  </Tabs>
);

const StyledCotainer = styled(Col)`
  padding: 15px;
`;

const StyledImageContainer = styled.button`
  border: none;
  cursor: pointer;
`;

const Image = styled.img`
  border-radius: ${(props) => props.borderRadius};
`;

const ImageModalContainer = styled(Col)`
  padding: 8px;
`;

export default Home;

export const StatisticsCard = ({ latestJobs = [], mapRef }) => {
  const [shifts, setShifts] = useState();
  const [tab, setTab] = useState('today');
  const todayTimestamp = moment().startOf('day').valueOf();

  function filterShifts(filterType) {
    if (filterType === 'today') {
      const todaysShifts = latestJobs.filter(
        (shift) => todayTimestamp <= shift.createdDate
      );
      setShifts(todaysShifts);
      return;
    }

    const lastWeek = latestJobs.filter(
      (shift) => shift.createdDate <= todayTimestamp
    );
    setShifts(lastWeek);
  }

  useEffect(() => {
    filterShifts(tab);
  }, [latestJobs]);

  function handleTabChange(val) {
    if (val === 0) {
      setTab('today');
      filterShifts('today');
    } else {
      filterShifts('week');
      setTab('week');
    }
  }

  let start = 0;
  let end = 0;
  let attendance = 0;

  if (!shifts) return null;

  const shiftsCount = !shifts.length || shifts.length === 0 ? 1 : shifts.length;

  shifts.forEach((job) => {
    //  startDate is withing 30 minuts of created date
    // TODO: confirm this

    if (!job) return;
    if (job.startDate ?? 0 - job.createdDate ?? 0 <= 30000) start += 1;
    if (job.endDate ?? 0 - (job.sbift?.endDate ?? 0) <= 30000) end += 1;
    // 2. endDate is withing 30 minutes of shiftEND DATE
    // 3. schedules with status != assigned / total schedules
    if (job.status !== 'assigned') attendance += 1;
  });

  const startRate = (start / shiftsCount) * 100;
  const endRate = (end / shiftsCount) * 100;
  const attendanceRate = (attendance / shiftsCount) * 100;

  return (
    <SCard padding="10px">
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontSize="1em" fontWeight="bold" marginBottom="0.5rem">
          Statistics
        </Text>
        <TodayWeekTab handleTabChange={handleTabChange} />
      </Flex>

      <Flex justifyContent="space-between" flexWrap="wrap" marginTop="20px">
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <MyResponsiveRadialBar
            style={{ flex: 2, position: 'relative' }}
            data={[
              {
                data: [
                  {
                    x: '1',
                    y: Math.round(startRate),
                  },
                  {
                    x: '',
                    y: Math.round(100 - startRate),
                  },
                ],
              },
            ]}
            percentage={startRate}
          />
          <Flex
            flex={1}
            position="absolute"
            direction="column"
            // border="1px solid black"
            top="30px"
            alignItems="center"
            // height="100px"
          >
            <Text fontSize="12px" fontWeight="bold" color="#17244F">
              {Math.round(startRate)}%
            </Text>
            <Text fontSize="10px">{`( ${start} of ${shiftsCount} )`}</Text>
          </Flex>
          <Flex
            marginTop="16px"
            direction="column"
            marginLeft="6px"
            alignItems="center"
            justifyContent="center"
          >
            <Text fontSize="12px">Shift</Text>
            <Text fontSize="12px">start punctuality</Text>
          </Flex>
        </div>
        <Box background="#ECEEF5" width="1px" className="hey" />
        <div
          style={{
            height: '150px',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            // borderLeft: '1px solid #ECEEF5',
          }}
        >
          <MyResponsiveRadialBar
            data={[
              {
                // id: 'end punctuality',
                data: [
                  {
                    x: '1',
                    y: Math.round(endRate),
                  },
                  {
                    x: '',
                    y: Math.round(100 - endRate),
                  },
                ],
              },
            ]}
            percentage={endRate}
          />
          <Flex
            position="absolute"
            direction="column"
            alignItems="center"
            top="30px"
          >
            <Text fontSize="12px" fontWeight="bold">
              {Math.round(endRate)}%
            </Text>
            <Text fontSize="10px"> ( {` ${end} of ${shiftsCount}`} )</Text>
          </Flex>
          <Flex
            direction="column"
            alignItems="center"
            marginTop="16px"
            justifyContent="center"
          >
            <Text fontSize="12px">Shift</Text>
            <Text fontSize="12px">end punctuality</Text>
          </Flex>
        </div>
        <Box background="#ECEEF5" width="1px" className="hey" />

        <div
          style={{
            height: '150px',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <MyResponsiveRadialBar
            data={[
              {
                // id: 'attendance rate',
                data: [
                  {
                    x: '1',
                    y: Math.round(attendanceRate),
                  },
                  {
                    x: '',
                    y: Math.round(100 - attendanceRate),
                  },
                ],
              },
            ]}
            percentage={attendanceRate}
          />
          <Flex
            position="absolute"
            alignItems="center"
            direction="column"
            top="30px"
          >
            <Text fontSize="12px" fontWeight="bold">
              {Math.round(attendanceRate)}%
            </Text>
            <Text fontSize="10px">{` (${attendance} of ${shiftsCount})`}</Text>
          </Flex>

          <Flex
            direction="column"
            alignItems="center"
            marginTop="16px"
            justifyContent="center"
          >
            <Text fontSize="12px">Shift</Text>
            <Text fontSize="12px">attendance rate</Text>
          </Flex>
        </div>
        {/* <div>
          <Flex
            direction="column"
            borderRight="1px solid grey"
            alignItems="center"
          >
             <Flex
              position="absolute"
              direction="column"
              border="1px solid black"
              alignItems="center"
              width="85%"
              height="100px"
            >
              <Text fontSize="12px" fontWeight="bold">
                {Math.round(startRate)}%
              </Text>
              <Text fontSize="10px">{`${start} of ${shiftsCount}`}</Text>
            </Flex>
            <Flex
              marginTop="16px"
              direction="column"
              marginLeft="6px"
              alignItems="center"
              justifyContent="center"
            >
              <Text fontSize="12px">Shift</Text>
              <Text fontSize="12px">start punctuality</Text>
            </Flex>
          </Flex>
        </div> */}
        {/* <div>
          <Flex direction="column" borderRight="1px solid grey">
            <Flex
              direction="column"
              border="1px solid black"
              width="85%"
              height="100px"
              marginLeft="6px"
              alignItems="center"
            >
              <Text fontSize="12px" fontWeight="bold">
                {Math.round(endRate)}%
              </Text>
              <Text fontSize="10px">{`${end} of ${shiftsCount}`}</Text>
            </Flex>
            <Flex
              direction="column"
              alignItems="center"
              marginTop="16px"
              justifyContent="center"
            >
              <Text fontSize="12px">Shift</Text>
              <Text fontSize="12px">end punctuality</Text>
            </Flex>
          </Flex>
        </div> */}

        {/* <div>
          <Flex direction="column">
            <Flex
              direction="column"
              border="1px solid black"
              width="85%"
              height="100px"
              marginLeft="6px"
              alignItems="center"
            >
              <Text fontSize="12px" fontWeight="bold">
                {Math.round(attendanceRate)}%
              </Text>
              <Text fontSize="10px">{`${attendance} of ${shiftsCount}`}</Text>
            </Flex>
            <Flex
              direction="column"
              alignItems="center"
              marginTop="16px"
              justifyContent="center"
            >
              <Text fontSize="12px">Shift</Text>
              <Text fontSize="12px">attendance rate</Text>
            </Flex>
          </Flex>
        </div> */}
      </Flex>
    </SCard>
  );
};
