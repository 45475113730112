import { useEffect, useState } from 'react';

const Polyline = ({ markers, map, maps }) => {
  const [polyline, setPolyline] = useState(
    new maps.Polyline({
      path: markers,
      geodesic: false,
      strokeColor: 'orange',
      strokeOpacity: 0.9,
      strokeWeight: 3,
    })
  );

  useEffect(() => {
    polyline.setMap(null);
    setPolyline(
      new maps.Polyline({
        path: markers,
        geodesic: false,
        strokeColor: 'orange',
        strokeOpacity: 0.9,
        strokeWeight: 3,
      })
    );
  }, [markers]);

  polyline.setMap(map);
  return null;
};

export default Polyline;
