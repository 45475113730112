import { Icon, Stack, Text, Flex } from '@chakra-ui/react';

import { RowCard } from './RowCard';
import bgImage from '../assets/svgs/bgImage.svg';

interface DescriptionTitleProps {
  title: string;
  subTitle: string;
  icon: string | any;
  onClick: () => void;
}

export const DescriptionTitle = (props: DescriptionTitleProps) => {
  const { title, subTitle, icon, onClick } = props;

  return (
    <RowCard onClick={onClick}>
      <Flex
        boxSize="88px"
        bgImage={bgImage}
        borderRadius="sm"
        alignItems="center"
        justifyContent="center"
      >
        <Icon w="48px" h="48px" as={icon} color="#006DB8" />
      </Flex>
      <Stack flex="1">
        <Text fontSize="lg" fontWeight="semibold">
          {title}
        </Text>
        <Text fontSize="md" marginTop="sm">
          {subTitle}
        </Text>
      </Stack>
    </RowCard>
  );
};
