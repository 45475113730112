import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import Janitors from './snowm_janitors';

const JanitorsRoutes = () => (
  <Switch>
    <Route path="/admin/janitors" exact component={Janitors} />
    <Redirect to="/admin/janitors" />
  </Switch>
);

export default JanitorsRoutes;
