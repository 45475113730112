import React, { useEffect, useState, useContext } from 'react';

import styled from 'styled-components';
import { Redirect } from 'react-router';
import { Grid, CircularProgress } from '@material-ui/core';
import { addSubLocation } from '../../../controllers/navigine';
import { RoutesContext } from '../../../contexts/service_routes';

import RouteItem from './route_item';
import Header from '../../Common/header';
import AddNewRoute from './add_new_route';
import AlertBox from '../../Common/alert_box';
import AddButton from '../../Common/add_button';
import PrimayButton from '../../Common/primary_button';
import { ServiceTypesContext } from '../../../contexts/service_types';
import { getFilteredItems as getFilteredRoutes } from '../../../helpers/misc';
import {
  createServiceRoute,
  deleteServiceRoute,
  getCompanyDetail,
} from '../../../controllers/snowm_firebase';
import { Row } from '../../../styles/snowm_styled';
import Loader from '../../Generics/snowm_loader';

const RouteConsumer = (normalProps) => (
  <RoutesContext.Consumer>
    {({ fetchRoutes, routes, routesLoading }) => (
      <Routes
        fetchRoutes={fetchRoutes}
        routes={routes}
        routesLoading={routesLoading}
        {...normalProps}
      />
    )}
  </RoutesContext.Consumer>
);

const Routes = ({ routes, fetchRoutes, ...props }) => {
  const [open, setOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState({});
  const [filteredRoutes, setFilteredRoutes] = useState(routes);
  const [isSearch, setIsSearch] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [selectedServiceKey, setSelectedServiceKey] = useState('all');
  const [loading, setLoading] = useState(false);
  const [routesAfterSearch, setRouteAfterSearch] = useState([]);

  const serviceContext = useContext(ServiceTypesContext);

  const subscribedServices = serviceContext?.subscribedServices;

  useEffect(() => {
    fetchRoutes();
  }, []);

  useEffect(() => {
    let routesAfterFilter = [];
    if (routesAfterSearch.length > 0) {
      routesAfterFilter = getFilteredRoutes(
        routesAfterSearch,
        selectedServiceKey
      );
    } else {
      routesAfterFilter = getFilteredRoutes(routes, selectedServiceKey);
    }
    setFilteredRoutes(routesAfterFilter);
  }, [selectedServiceKey, routes]);

  function handleDeleteDialogClose() {
    setOpenDeleteDialog(false);
  }

  function onAddButtonPress() {
    setOpen(true);
  }

  function onRoutePress(route) {
    props.history.push(`/admin/routes/details/${route.key}`);
  }

  function onDeletePress(route) {
    setOpenDeleteDialog(true);

    setSelectedRoute(route);
  }
  function deleteRoute() {
    deleteServiceRoute(selectedRoute.key);
    handleDeleteDialogClose();
  }

  function closeDialog() {
    setOpen(false);
  }

  async function changeRoute(
    selectedService,
    routeInfo,
    image,
    imageHeight,
    imageWidth
  ) {
    const infoOfRoute = routeInfo;
    const indoor = selectedService.type === 'indoor';

    infoOfRoute.serviceKey = selectedService.key;
    let subLocationDetail;
    if (indoor) {
      const companyDetail = await getCompanyDetail();
      subLocationDetail = await addSubLocation(
        companyDetail.locationId,
        routeInfo.name,
        image.result,
        imageHeight,
        imageWidth,
        selectedService.latitude.toString(),
        selectedService.longitude.toString()
      );

      infoOfRoute.navigineLocationId = subLocationDetail.sublocation.id;
    }

    closeDialog();
    setLoading(false);

    createServiceRoute(routeInfo).then((key) => {
      infoOfRoute.key = key;
    });
  }

  const handleInputSearch = async (items) => {
    setIsSearching(true);
    setRouteAfterSearch(items);
    const routesAfterFilter = getFilteredRoutes(items, selectedServiceKey);
    setFilteredRoutes(routesAfterFilter);
    setIsSearching(false);
    setIsSearch(true);
  };

  const handleShowAllButton = () => {
    setIsSearch(false);
    setFilteredRoutes(routes);
    setRouteAfterSearch([]);
    setSelectedServiceKey('all');
  };

  const handelServiceChange = (event) => {
    setSelectedServiceKey(event.target.value);
  };

  if (!subscribedServices) {
    return <Loader />;
  }

  if (!subscribedServices.indoor && !subscribedServices.outdoor) {
    return <Redirect to="/admin" />;
  }

  return (
    <div>
      <Header
        heading="Search Route"
        subheading="Search by Route Name"
        placeholder="Routes Name"
        paddingbottom="1em"
        handleSearch={handleInputSearch}
        isSearching={isSearching}
        selectedServiceKey={selectedServiceKey}
        handleServiceChange={handelServiceChange}
        indexName="routes"
      />

      {isSearch && (
        <Row justify="center">
          <PrimayButton onClick={() => handleShowAllButton()}>
            Show All Routes
          </PrimayButton>
        </Row>
      )}
      <StyledGrid container spacing={3}>
        {Object.values(filteredRoutes).map((r) => (
          <RouteItem
            key={r.key}
            route={r}
            onPress={() => onRoutePress(r)}
            onDeletePress={() => onDeletePress(r)}
          />
        ))}
        <AddButton onPress={onAddButtonPress} title="Add New Route" />
      </StyledGrid>
      <AddNewRoute
        open={open}
        onCloseDialog={closeDialog}
        onChangeRoute={changeRoute}
        loading={loading}
        setLoading={setLoading}
      />
      <AlertBox
        title="Delete Service Route"
        subtitle="Are you sure you want to delete this route ?"
        handlePositiveAction={deleteRoute}
        handleNegativeAction={handleDeleteDialogClose}
        open={openDeleteDialog}
      />
    </div>
  );
};

const StyledGrid = styled(Grid)`
  padding: 20px;
`;

export default RouteConsumer;
