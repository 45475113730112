import React from 'react';

import { Route, Switch } from 'react-router';

import JobDetail from './JobDetail';
import LogDetail from './LogDetail';

const ServiceLogsRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/customer/servicelogs/details/:jobId/:markerLogKey"
        component={JobDetail}
      />
      <Route path="/customer/servicelogs" component={LogDetail} />
    </Switch>
  );
};

export default ServiceLogsRoutes;
