import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import MenuDownIcon from 'mdi-react/MenuDownIcon';
import Select, { components } from 'react-select';

interface CustomSelectProps {
  value?: any;
  name?: string;
  title?: string;
  options: any[];
  border?: number;
  variant?: string;
  isMulti?: boolean;
  required?: boolean;
  defaultValue?: any;
  autoFocus?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  isClearable?: boolean;
  onChange: (newValue: any) => void;
}

export const CustomSelect = React.forwardRef(
  (props: CustomSelectProps, ref: any) => {
    const [value, setValue] = useState('');
    const { required, isDisabled, onChange, border, ...rest } = props;

    const enableRequired = !isDisabled && !rest.value;

    useEffect(() => {
      setValue(props.value);
    }, [props]);

    const handleOnChange = (selectedOption) => {
      onChange(selectedOption);
      setValue(selectedOption);
    };

    const DropdownIndicator = (prop) => {
      return (
        <components.DropdownIndicator {...prop}>
          <MenuDownIcon />
        </components.DropdownIndicator>
      );
    };

    return (
      <>
        <Select
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            menu: (provided) => ({
              ...provided,
              zIndex: 22222,
            }),
            placeholder: (base) => ({
              ...base,
              opacity: 0.8,
            }),

            control: (base) => ({
              ...base,
              border: border ?? 0,
              marginTop: '4px',
              boxShadow: 'none',
            }),
          }}
          ref={ref}
          isDisabled={isDisabled}
          onChange={handleOnChange}
          components={{ DropdownIndicator }}
          {...rest}
        />
        {enableRequired && (
          <StyledHiddenInput
            tabIndex={-1}
            autoComplete="off"
            value={value ?? ''}
            required={required}
            onFocus={() => ref?.current?.focus()}
            onChange={() => null}
          />
        )}
      </>
    );
  }
);

CustomSelect.displayName = 'customSelect';
CustomSelect.defaultProps = {
  required: false,
  isDisabled: false,
  border: 0,
  value: null,
  autoFocus: false,
  defaultValue: null,
  isMulti: false,
  isClearable: false,
  name: '',
  title: '',
  variant: '',
  placeholder: '',
};

const StyledHiddenInput = styled.input`
  opacity: 0;
  height: 0;
  position: absolute;
  border-color: ${(value) => (value ? '' : 'red')};
`;
