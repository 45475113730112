import { Flex, Box } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface CardProps {
  children: ReactNode | undefined;
  onClick: () => void;
  height?: number | string | undefined;
  padding?: number | string | undefined;
  width?: number | string | undefined;
  background?: string | undefined;
}

export const SCard = (props: CardProps) => {
  const { children, onClick, height, background, padding, width } = props;
  const bg = background ?? '#FFFFFF';
  return (
    <Box
      {...props}
      height={height ?? 'auto'}
      borderRadius="16px"
      padding={padding ?? '10px'}
      boxShadow="0px 12px 24px #ECEEF5"
      cursor="pointer"
      onClick={onClick}
      bg={bg}
      width={width ?? 'auto'}
    >
      {children}
    </Box>
  );
};
