import React from 'react';

import styled from 'styled-components';
import { Divider } from '@material-ui/core';

import Span from '../../Common/Span';
import { getFormattedDate } from '../../../helpers/date';
import ListTitleItem from '../../Generics/list_title_item';

const AssetInformation = ({ details }) => {
  if (!details?.customData) {
    return null;
  }
  const { customData } = details;

  function renderRepairingCost() {
    if (!customData?.totalRepairCost) {
      return null;
    }
    return (
      <ListTitleItem
        title="Till Date"
        content={`$ ${customData?.totalRepairCost}`}
      />
    );
  }

  function renderValidUntil() {
    if (!customData?.validUntil) {
      return null;
    }
    return (
      <ListTitleItem
        title="Valid Until"
        content={getFormattedDate(customData?.validUntil)}
      />
    );
  }

  return (
    <Container>
      <Span size="20px" weight="bold">
        Asset Detail
      </Span>
      <Divider />
      <ListTitleItem title="SN" content={customData?.sn} />
      <ListTitleItem title="Model" content={customData?.model} />
      <ListTitleItem title="Price" content={customData?.price} />
      <ListTitleItem
        title="Purchase Date"
        content={getFormattedDate(customData?.purchase_date)}
      />
      <ListTitleItem
        title="Warranty valid"
        content={customData?.warranty_valid ? 'Yes' : 'No'}
      />
      {renderValidUntil()}
      {renderRepairingCost()}
    </Container>
  );
};

const Container = styled.div`
  padding: 16px;
`;

export default AssetInformation;
