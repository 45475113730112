import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Td,
  Text,
  Tr,
  Wrap,
} from '@chakra-ui/react';
import { useEffect, useState, useContext } from 'react';
import { LocalizationContext } from '../../../contexts/localization_context';
import ActionsDrawer from '../../../reusableComponent/ActionsDrawer';
import { MainTable } from '../../../reusableComponent/MainTable';

const headers = [{ name: 'Task name' }];

const CollapseData = (props) => {
  const [taskListCount, setTaskListCount] = useState(0);
  const {
    tasks,
    task: selectedTask,
    setOpenTaskListActionDrawer,
    openTaskListActionDrawer,
    setSelectedTaskLists,
    selectedTaskLists,
    handleCategoryEdit,
    openDeleteModal,
    handleMultipleTaskDeletion,
    setSelectedEntities,
    handleAddTask,
    renderTaskListToDelete,
  } = props;
  const filteredTask = tasks.filter((task) => task.category === selectedTask);
  const selectedTaskCount = Object.values(selectedTaskLists);
  const taskToEditDelete = selectedTaskCount?.[0];
  const selectAllTaskList = (e) => {
    setSelectedEntities([]);
    setOpenTaskListActionDrawer(true);
    if (e.target.checked) {
      const checkedTask = filteredTask?.reduce((taskLists, taskList) => {
        if (taskList.id) {
          taskLists[taskList.id] = taskList;
        }
        return taskLists;
      }, {});
      setSelectedTaskLists({ ...checkedTask });
    } else {
      setSelectedTaskLists({});
    }
  };
  useEffect(() => {
    setTaskListCount(selectedTaskCount.length);
  }, [selectedTaskLists]);

  const onSelectedTaskList = (task) => {
    setSelectedEntities([]);
    setOpenTaskListActionDrawer(true);

    const { id } = task;
    if (id) {
      if (selectedTaskLists[id]) {
        const { [id]: value, ...remainingTask } = selectedTaskLists;
        setSelectedTaskLists({ ...remainingTask });
      } else {
        setSelectedTaskLists({
          ...selectedTaskLists,
          [id]: { ...task },
        });
      }
    }
  };
  const showButton = () => {
    return (
      <Wrap>
        <Button
          size="sm"
          colorScheme="blue"
          border="2px solid #006DB8"
          leftIcon={<EditIcon />}
          variant="outline"
          // isLoading={deleting}
          onClick={(e) => {
            handleCategoryEdit(e, taskToEditDelete);
          }}
        >
          Edit
        </Button>
      </Wrap>
    );
  };
  const showDelete = () => {
    return (
      <Wrap>
        <Button
          size="sm"
          colorScheme="red"
          border="2px solid #006DB8"
          variant="outline"
          leftIcon={<DeleteIcon />}
          // isLoading={deleting}
          onClick={(e) => {
            openDeleteModal(taskToEditDelete);
          }}
        >
          Delete
        </Button>
      </Wrap>
    );
  };
  const {
    strings: {
      taskList: {
        task: { TASK, TASKS },
      },
    },
  } = useContext(LocalizationContext);
  return (
    <>
      <MainTable
        onSelectedEntities={(e) => selectAllTaskList(e)}
        checked={filteredTask?.length === taskListCount}
        headers={headers}
      >
        {filteredTask.map((task, i) => {
          const { name, id } = task;
          return (
            <Tr
              key={id}
              height="51px"
              backgroundColor="#F1F1F1"
              justifyContent="space-between"
            >
              <Td>
                <Flex gridGap="md">
                  <Checkbox
                    isChecked={!!selectedTaskLists[task.id ?? '']}
                    borderRadius="4px"
                    onChange={() => onSelectedTaskList(task)}
                    borderColor="primary.500"
                    bg="white"
                  />
                  <Text> {name} </Text>{' '}
                </Flex>
              </Td>
            </Tr>
          );
        })}
      </MainTable>
      <Box width="100%" height="51px" backgroundColor="white">
        <Button
          size="sm"
          isDisabled={false}
          leftIcon={<AddIcon />}
          alignItems="center"
          paddingTop="23px"
          paddingLeft="15px"
          justifyContent="start"
          color="#006DB8"
          style={{ background: 'transparent' }}
          isLoading={false}
          onClick={() => handleAddTask(selectedTask)}
        >
          Add Task
        </Button>
      </Box>
      <ActionsDrawer
        setSelectedEntities={setSelectedTaskLists}
        openActionDrawer={openTaskListActionDrawer}
        selectedEntities={Object.values(selectedTaskLists).map((v) => ({
          key: v.id,
          name: v.name,
        }))}
        showButtons={showButton}
        body={() => renderTaskListToDelete()}
        showDeleteButton
        handleDeleteEntities={(i) => handleMultipleTaskDeletion(i.key)}
        entity={Object.values(selectedTaskLists).length < 2 ? TASK : TASKS}
        showCustomDelete={showDelete}
      />
    </>
  );
};

export default CollapseData;
