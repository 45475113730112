import styled from 'styled-components';
import React, { useContext } from 'react';
import { Card, Typography } from '@material-ui/core';

import { LocalizationContext } from '../../../../contexts/localization_context';

const ServicePointDetailItem = ({ servicePointDetail, onClick, highlight }) => {
  const { strings } = useContext(LocalizationContext);

  const { NAME } = strings.inputLabel ?? '';

  return (
    <SPCard selected={highlight} onClick={onClick}>
      <div>
        <div>
          <Typography variant="subtitle1">{`${NAME}: ${servicePointDetail.name}`}</Typography>
        </div>
        <div>
          <Typography variant="caption">{`Customer Name: ${servicePointDetail.name}`}</Typography>
        </div>
      </div>
    </SPCard>
  );
};

const SPCard = styled(Card)`
  && {
    margin: 8px;
    padding: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;

    ${(props) =>
      props.selected &&
      `
    background-color: grey;
    color: #fff;`}
  }
`;

export default ServicePointDetailItem;
