import { useContext, useEffect, useState } from 'react';

import { Box, CircularProgress, Flex, Text } from '@chakra-ui/react';

import { formatTime } from '../../../helpers/misc';
import {
  weekDays,
  weeklyDays,
} from '../shiftTemplates/ AddShiftTemplateFields';
import { LocalizationContext } from '../../../contexts/localization_context';

interface SelectShiftTemplateProps {
  shifts: any;
  selectedJobType: string;
  selectedShift: any;
  handleShiftChange: (shift) => void;
  shiftData: any;
  handleDataChange: (value: any, type: string) => void;
  handleWeekDays: (value: any[]) => void;
  weekDay: OptionType[];
  selectedWeekDays: number[];
  setCustomShiftTemplate: (value: any) => void;
  setSelectedShift: (value: any) => void;
  isEditShift: boolean;
  shiftKey: string | null;
}

type OptionType = { label: string; value: number };

export const ShiftSelect = ({
  shifts,
  selectedJobType,
  selectedShift,
  handleShiftChange,
  shiftData,
  handleDataChange,
  handleWeekDays,
  setCustomShiftTemplate,
  setSelectedShift,
  isEditShift,
  weekDay,
  selectedWeekDays: weekDaysThatAreSelected,
  shiftKey,
}: SelectShiftTemplateProps) => {
  const { strings } = useContext(LocalizationContext);
  const [selectedWeekDays, setSelectedWeekDays] = useState<OptionType[]>([]);

  useEffect(() => {
    if (selectedShift?.value) {
      const selectedWeekDaysFromDb = new Set(selectedShift.value.weekdays);
      const selectedDays = weekDay?.reduce((allDays: OptionType[], day) => {
        if (selectedWeekDaysFromDb.has(day.value)) {
          allDays.push(day);
        }
        return allDays;
      }, []);
      setSelectedWeekDays(selectedDays);
    }
  }, [selectedShift?.value]);

  const [selectedKey, setSelectedKey] = useState<string>();

  useEffect(() => {
    if (selectedShift || shiftKey) {
      setSelectedKey(selectedShift ? selectedShift?.value?.id : shiftKey);
    }
  }, []);
  const shiftChange = (shift: any) => {
    handleShiftChange(shift);
    setSelectedKey(shift.value.id);
  };
  // TODO: auto focus on the selected shift in case of edit
  return (
    <>
      {!shifts && (
        <Flex justify="center">
          <CircularProgress />
        </Flex>
      )}
      <Box
        border="1px solid #323A45"
        opacity="0.4"
        marginBottom="15px"
        mt="10px"
        overflowY="scroll"
      />

      <Box overflowY="auto" padding="20px" className="check123">
        {shifts?.length > 0 &&
          shifts?.map((shift: any) => {
            return (
              <SelectShiftTemplates
                key={shift.value.id}
                shift={shift}
                label={shift.label}
                handleShiftChange={handleShiftChange}
                shiftChange={shiftChange}
                selectedKey={selectedKey}
                setSelectedKey={setSelectedKey}
              />
            );
          })}
      </Box>
    </>
  );
};
export const SelectShiftTemplates = ({
  shift,
  key,
  handleShiftChange,
  shiftChange,
  selectedKey,
}: any) => {
  const label = shift?.label;
  const { id, startTime, endTime, weekdays, repetative } = shift.value;

  return (
    <Flex
      onClick={() => shiftChange(shift)}
      key={id}
      flexDirection="column"
      cursor="pointer"
      flexWrap="wrap"
      borderRadius="8px"
      padding="20px"
      marginTop="10px"
      border={id === selectedKey ? '1px solid #006db8' : 'none'}
      style={{
        background: `${
          id === selectedKey
            ? '#F2F7FF 0% 0% no-repeat padding-box'
            : ' #F8F8F8 0% 0% no-repeat padding-box'
        }`,
      }}
    >
      {/* <Flex flexWrap="wrap" flexDirection="column"> */}
      <Text
        color={id === selectedKey ? '#006DB8' : ' ##17244F'}
        fontWeight="semibold"
        fontSize="md"
      >
        {label}
      </Text>

      <Text color={id === selectedKey ? '#006DB8' : ' ##17244F'}>
        {` ${getRepeatOrNotRepeat(null, repetative)}  ${getRepeatOrNotRepeat(
          (weekdays ?? []).map(
            (w) => weeklyDays.find((wd) => wd.id === w)?.name
          )
        )} ${formatTime(startTime)} to ${formatTime(endTime)}`}
      </Text>
      {/* </Flex> */}
    </Flex>
  );
};
const getRepeatOrNotRepeat = (selectedWeekDay: any, repetative?: boolean) => {
  if (selectedWeekDay) {
    if (
      selectedWeekDay.length === 5 &&
      !selectedWeekDay.includes('Saturday') &&
      !selectedWeekDay.includes('Sunday')
    ) {
      return 'Weekly on weekdays';
    }
    if (selectedWeekDay.length === weekDays.length) {
      return 'Every day';
    }
    if (!selectedWeekDay.length) {
      return '';
    }
    if (selectedWeekDay.length > 1) {
      const lastElem = selectedWeekDay.pop();
      return `Every ${selectedWeekDay.join(', ')} and ${lastElem}`;
    }
    return `Every ${selectedWeekDay.join(', ')}`;
  }

  if (repetative) return 'Repeat';
  return 'Non Repeat';
};
