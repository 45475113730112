import { Flex } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface RowCardProps {
  children: ReactNode | undefined;
  onClick: () => void;
  height?: number | string | undefined;
}

export const RowCard = (props: RowCardProps) => {
  const { children, onClick, height } = props;
  return (
    <Flex
      bg="#FFFFFF"
      minHeight={height ?? '152px'}
      borderRadius="lg"
      boxShadow="0px 12px 24px #ECEEF5"
      alignItems="center"
      justifyContent="flex-start"
      cursor="pointer"
      onClick={onClick}
      gridGap="lg"
      padding="llg"
    >
      {children}
    </Flex>
  );
};
