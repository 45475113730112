/* eslint-disable react/jsx-wrap-multilines */

import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import MomentUtils from '@date-io/moment';
import { FormControlLabel } from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import cronstrue from 'cronstrue';
import moment from 'moment';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { KeyboardBackspace } from '@material-ui/icons';
import { TriangleDownIcon } from '@chakra-ui/icons';
import { BsCalendar4 } from 'react-icons/bs';

import { SingleDatePicker } from 'react-dates';
import { FaPlusCircle } from 'react-icons/fa';
import styled from 'styled-components';
import CloseIcon from 'mdi-react/CloseIcon';
import ActivitiesSelection from '../../Components/Common/ActivitiesSelection';
import Span from '../../Components/Common/Span';
import ADialog from '../../Components/Common/styled';
import CollapseMicrolocation from '../../Components/Private/schedule/CollapseMicrolocation';
import { CustomShiftForm } from '../../Components/Private/schedule/CustomShiftForm';
import PreviewShift, {
  weekDaysData,
} from '../../Components/Private/schedule/PreviewShift';
import { ShiftSelect } from '../../Components/Private/schedule/ShiftSelect';
import TaskListSelect from '../../Components/Private/schedule/TaskListSelect';
import { JobConfiguration } from '../../Components/Private/settings/JobConfiguration';
import { AuthContext } from '../../contexts/auth_context';
import { LocalizationContext } from '../../contexts/localization_context';
import { ServicePointsContext } from '../../contexts/service_points';
import { ServiceTypesContext } from '../../contexts/service_types';
import { generateCronExpression } from '../../controllers/cron';
import { ReactComponent as AcceptedSvg } from '../../assets/svgs/Accepted.svg';
import {
  createEditShiftJob,
  editSchedule,
  getCompanyDetail,
  getCrewsForJobs,
  getMarkersForServiceKey,
  getMicrolocationsByLocationKey,
  getPropertiesOfCompany,
  getRealTimeServices,
  getRoutesForService,
  getRoutesFromDatabase,
  getServicePointById,
  getShiftsOfACompany,
  getWorkOrderFromRoutes,
  getActivitiesOfACompany,
} from '../../controllers/snowm_firebase';
import {
  getStartTimeStampOfTheDay,
  getTime,
  getTimeInEpoch,
} from '../../helpers/date';
import { Col, Row } from '../../styles/snowm_styled';
import { CustomInput } from '../CustomInput';
import { CustomSelect } from './custom_select';
import {
  defaultPeriodicTimeSchedule,
  PeriodicJobTimeSelection,
} from './PeriodicJobTimeSelection';
import './style.css';
import StyledTextField from './styled';
import { getRepeatOrNotRepeat } from '../../helpers/misc';
import { RepeatModal } from '../../Components/Private/shiftTemplates/ AddShiftTemplateFields';

const JobModal = ({
  open,
  edit,
  onClose,
  individualJob,
  orderSelected,
  onJobCreation,
  serviceOfOrder,
  markerSelected,
  selectedJobType,
  title,
}) => {
  const defaultJob = {
    key: '',
    name: '',
    endDate: null,
    allRoutes: [],
    orderKeys: {},
    companyKey: '',
    serviceKey: '',
    allMarkers: [],
    noTouch: false,
    shiftKey: null,
    activities: {},
    serviceType: '',
    providerUids: [],
    servedRoutes: [],
    createdDate: null,
    startedDate: null,
    servedMarkers: [],
    dateToEndJob: null,
    status: 'assigned',
    propertyKeys: null,
    detectionTime: null,
    expectedEndTime: null,
    expectedStartTime: null,
    invoicingEnabled: false,
    enabledGeofencing: false,
    minimumServingDistance: 80,
    allowImageFromGallery: true,
    temperatureMonitoring: false,
  };

  const defaultPeriodicJob = React.useMemo(
    () => ({
      name: '',
      cronExp: '',
      createdDate: null,
      job: {},
      days: [],
      hours: [],
      minutes: [],
      key: '',
      companyKey: '',
      serviceType: '',
    }),
    []
  );

  const editScheduleJobTypes = React.useMemo(
    () => [
      {
        label: 'This shift',
        value: 'onlyThis',
      },
      {
        label: 'All shifts',
        value: 'thisAndFollowing',
      },
    ],
    []
  );

  const markerRef = useRef(null);

  const serviceContext = useContext(ServiceTypesContext);

  const { markers: allMarkers, fetchServicePoints } =
    useContext(ServicePointsContext);
  const subscribedServices = serviceContext?.subscribedServices;

  const { strings } = useContext(LocalizationContext);

  const { SHIFT_JOBS } = strings.jobType;

  const [job, setJob] = useState(
    individualJob?.id ? individualJob : defaultJob
  );

  const { company } = useContext(AuthContext);

  const [isPeriodic, setIsPeriodic] = useState(true);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [crew, setCrew] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [selectedService, setSelectedService] = useState();
  const [serviceOption, setServiceOption] = useState('');
  const [names, setNames] = useState([]);
  const [crewsNames, setCrewsNames] = useState([]);
  const [date, setDate] = useState(null);
  const [focus, setFocus] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [propertyDetail, setPropertyDetail] = useState(null);
  const [locations, setLocations] = useState(null);
  const [microlocationsByLocation, setMicrolocationsByLocation] = useState({});
  const [selectedProperties, setSelectedProperties] = useState(null);
  const [selectedMicrolocations, setSelectedMicrolocations] = useState(null);
  const [openMicrolocationSelectModal, setOpenMicrolocationSelectModal] =
    useState(null);

  const [selectedProperty, setSelectedProperty] = useState();
  const [selectedLocation, setSelectedLocation] = useState();
  const [selectedCrew, setSelectedCrew] = useState(null);
  const [isConfigureJobOpen, setIsConfigureJobOpen] = useState(false);
  const [tasksUnderMicrolocation, setTasksUnderMicrolocation] = useState();

  const [notes, setNotes] = useState(null);
  const [shifts, setShifts] = useState(null);
  const [cronExp, setCronExp] = useState('');
  const [noteInJob, setNoteInJob] = useState(false);
  const [noTouchJob, setNoTouchJob] = useState(true);
  const [selectedShift, setSelectedShift] = useState(null);
  const [customShiftTemplate, setCustomShiftTemplate] = useState({
    endsOn: new Date(),
  });
  const [selectedMarkers, setSelectedMarkers] = useState();
  const [companyDetail, setCompanyDetail] = useState(null);
  const [selectedLastMarker, setSelectedLastMarker] = useState();
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [openActivitiesModal, setOpenActivitiesModal] = useState(false);
  const [isTimeSelectionOpen, setIsTimeSelectionOpen] = useState(false);
  const [periodicJobData, setPeriodicJobData] = useState(defaultPeriodicJob);
  const [periodicData, setPeriodicData] = useState(defaultPeriodicTimeSchedule);
  const [isPropertyChanged, setIsPropertyChanged] = useState(false);
  const [selectedName, setSelectedName] = useState('');
  const [step, setStep] = useState('shift');
  const [isTasksChanged, setIsTasksChanged] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [repetative, setRepetative] = useState(false);
  const [openDialogForRepeatShift, setOpenDialogForRepeatShift] =
    useState(false);

  const [shiftEndDate, setShiftEndDate] = useState(moment().valueOf());
  const [shiftStartDate, setShiftStartDate] = useState(moment().valueOf());

  const [categoryTasks, setCategoryTasks] = useState();
  const [arrayOfTasks, setArrayOfTasks] = useState([]);

  const [scheduleEditType, setScheduleEditType] = useState('onlyThis');
  const submitScheduleRef = useRef(null);

  const isJobAutoComplete = (finishJobAtEnd) => {
    if (!finishJobAtEnd) return '';

    return '(Job auto complete on shift end)';
  };
  const customLabel = (shift) => {
    const { name, startTime, endTime, finishJobAtEnd } = shift;
    const shiftName = name;
    const shiftDuration = `${startTime} - ${endTime}`;
    const jobAutoCompleteMessage = isJobAutoComplete(finishJobAtEnd);
    const shiftLabel = `${shiftName} ( ${shiftDuration} ) ${jobAutoCompleteMessage}`;

    return shiftLabel;
  };

  useEffect(() => {
    if (!allMarkers) {
      fetchServicePoints();
    }
  }, []);

  const getLocationDetailsOfJob = async (selectedJob) => {
    const locationData = selectedJob?.propertyKeys.map((i) => {
      const location = locations?.[i] ?? {};
      const microLocationsOfLocation = Object.values(allMarkers).filter((m) => {
        return m.propertyKey === i;
      });
      const microLocationsOfLocationIds = microLocationsOfLocation.map(
        (m) => m.key
      );
      const selectedTasks = Object.keys(selectedJob.activities).reduce(
        (prev, curr) => {
          if (microLocationsOfLocationIds.includes(curr)) {
            prev.push({ [curr]: selectedJob.activities?.[curr] });
          }
          return prev;
        },
        []
      );
      return {
        ...location,
        label: location.name,
        value: location.id,
        selectedTasks,
      };
    });
    setSelectedProperties(locationData);
  };

  useEffect(() => {
    if (selectedShift?.value) {
      setCustomShiftTemplate({
        ...selectedShift.value,
      });

      setPeriodicData({
        ...periodicData,
        weekDays: selectedShift?.value.weekdays,
        minutes: selectedShift.value.exceptionMinutes,
      });
      setRepetative(selectedShift?.value.repetative);
    }
  }, [selectedShift]);

  useEffect(() => {
    function _updateShifts(res) {
      const shiftOptions = res?.map((shift) => {
        return { label: customLabel(shift), value: shift };
      });
      setShifts(shiftOptions);
    }
    if (selectedService?.value?.serviceFeatures?.shiftManagement) {
      getShiftsOfACompany(_updateShifts);
    } else {
      setJob({ ...job, shiftKey: null });
    }
  }, [selectedService]);

  useEffect(() => {
    if (noteInJob) {
      setOpenActivitiesModal(true);
      setNotes({ [orderSelected.markerKey]: orderSelected.requestNote });
    } else {
      setOpenActivitiesModal(false);
      setNotes(null);
      setJob({ ...job, notes: null, activities: null });
    }
  }, [noteInJob]);
  useEffect(() => {
    let propertyKeys = [];
    let orderKeys = {};
    let updatedAllMarkers = [];
    let serviceKey = '';
    let serviceType = '';
    let expectedServiceTime = '';
    const updatedNotes = {};

    const service = serviceOfOrder?.value;
    if (serviceOfOrder) {
      setSelectedService(serviceOfOrder);
      serviceKey = service.key;
      serviceType = service.type;
    }

    if (markerSelected?.propertyKey) {
      propertyKeys = [markerSelected?.propertyKey];

      serviceKey = markerSelected?.serviceKey;
    }

    if (orderSelected) {
      orderKeys = {
        [orderSelected.markerKey]: orderSelected.id,
      };

      if (
        orderSelected?.preferredTime &&
        orderSelected?.preferredTime?.length > 0
      ) {
        expectedServiceTime = orderSelected.preferredTime;
        updatedNotes[orderSelected.markerKey] = orderSelected.requestNote;
      }
      updatedAllMarkers = [orderSelected.markerKey];
    }

    setJob({
      ...job,
      orderKeys,
      allMarkers: updatedAllMarkers,
      propertyKeys,
      serviceKey,
      serviceType,
      expectedServiceTime,
      notes: updatedNotes,
    });
  }, [markerSelected, orderSelected, serviceOfOrder]);

  useEffect(() => {
    getCompanyDetail().then((res) => {
      setCompanyDetail(res);
    });
  }, []);
  useEffect(() => {
    if (selectedService?.value?.type === 'markerbased') {
      setSelectedName('markers');
    } else {
      setSelectedName('routes');
    }
  }, [selectedService]);

  const generateNumbers = (lastNum, diff) => {
    const numbers = [];
    for (let i = 0; i <= lastNum; i += diff) {
      numbers.push({ value: i, label: i });
    }
    return numbers;
  };

  const hoursData = generateNumbers(23, 1);
  const minutesData = generateNumbers(55, 5);

  function sortNumber(a, b) {
    return a - b;
  }

  function getDataFromReducer(jobField, setOfAllMarkers) {
    const actualJobField = job?.[jobField] ?? job?.job?.[jobField] ?? {};
    const newData = Object.keys(actualJobField).reduce((acc, curr) => {
      if (!setOfAllMarkers.has(curr)) {
        return acc;
      }
      return { ...acc, [curr]: actualJobField?.[curr] ?? null };
    }, {});
    return newData;
  }
  function handleClose(res) {
    setStep('shift');
    setRepetative(false);
    setPeriodicData(defaultPeriodicTimeSchedule);
    setCronExp('');
    if (onJobCreation && res) {
      onJobCreation(res);
    } else {
      onClose(res);
    }
  }

  const getSelectedDataFromArray = (oldData, newData) => {
    if (!newData?.length) {
      return oldData ?? [];
    }
    return newData ?? [];
  };

  const getJobForPeriodic = (data, jobMainDetails) => {
    return {
      key: jobMainDetails?.key || data.key,
      parentKey: jobMainDetails?.parentKey || data.parentKey,
      enabledGeofencing:
        data?.enabledGeofencing ?? jobMainDetails?.enabledGeofencing ?? false,
      closedBy: jobMainDetails?.closedBy ?? null,
      name: null,
      expectedServiceTime: jobMainDetails?.expectedServiceTime ?? null,
      futureAssignmentData: jobMainDetails?.futureAssignmentData ?? null,
      notes: data.notes ?? jobMainDetails?.notes ?? null,
      noTouch: data.noTouch ?? jobMainDetails?.noTouch ?? null,
      crewAdminNote: jobMainDetails?.crewAdminNote ?? null,
      endDate: jobMainDetails?.endDate ?? null,
      reassignCrew: jobMainDetails?.reassignCrew ?? null,
      shiftKey:
        data.shiftKey ??
        jobMainDetails?.shiftKey ??
        selectedShift?.value?.id ??
        null,
      allRoutes: getSelectedDataFromArray(
        jobMainDetails?.allRoutes,
        data.allRoutes
      ),
      serviceKey: data?.serviceKey || jobMainDetails?.serviceKey,
      companyKey: data?.companyKey || jobMainDetails?.companyKey,
      allMarkers: getSelectedDataFromArray(
        jobMainDetails?.allMarkers,
        data.allMarkers
      ),
      propertyKeys: getSelectedDataFromArray(
        jobMainDetails?.propertyKeys,
        data.propertyKeys
      ),
      invoicingEnabled:
        data?.invoicingEnabled ?? jobMainDetails?.invoicingEnabled ?? null,
      createdDate: (data?.createdDate || jobMainDetails?.createdDate) ?? null,
      startedDate: jobMainDetails?.startedDate ?? null,
      providerUids: getSelectedDataFromArray(
        jobMainDetails?.providerUids,
        data?.providerUids
      ),
      dateToEndJob: jobMainDetails?.dataToEndJob ?? null,
      servedRoutes: jobMainDetails?.servedRoutes ?? [],
      detectionTime: jobMainDetails?.detectionTime ?? null,
      servedMarkers: jobMainDetails?.servedMarkers ?? [],
      expectedEndTime: jobMainDetails?.expectedEndTime ?? null,
      status: data?.status ?? jobMainDetails?.status ?? null,
      expectedStartTime: jobMainDetails?.expectedStartTime ?? null,
      serviceType: data.serviceType ?? jobMainDetails?.serviceType,
      temperatureMonitoring: jobMainDetails?.temperatureMonitoring ?? null,
      allowImageFromGallery: jobMainDetails?.allowImageFromGallery ?? null,
      activities: data.activities ?? jobMainDetails?.activities ?? [],
      orderKeys: data.orderKeys ?? null,
      minimumServingDistance:
        data?.minimumServingDistance ??
        jobMainDetails?.minimumServingDistance ??
        0,
      shift: getShiftForSchedule(),
    };
  };
  const isPeriodicTimeEmpty = (periodicSchedule) => {
    if (periodicSchedule) {
      return (
        !periodicSchedule?.hours?.length || !periodicSchedule?.minutes?.length
      );
    }
    return !periodicData?.hours?.length || !periodicData?.minutes?.length;
  };

  const getShiftByShiftKey = (shiftKey) => {
    if (!shiftKey && !selectedShift) return {};
    if (selectedShift) {
      const shiftSelected = shifts?.find(
        (shift) => shift.value.id === (shiftKey ?? selectedShift.value.id)
      );
      const { timezone, startTime, endTime, name, weekdays } =
        shiftSelected?.value ?? {};
      return { timezone, startTime, endTime, name, weekdays };
    }

    const {
      startTime: momentedStartTime,
      endTime: momentedEndTime,
      name,
      weekdays,
    } = customShiftTemplate;

    const startTime = getTime(momentedStartTime);
    const endTime = getTime(momentedEndTime);

    return {
      startTime,
      endTime,
      timezone: company.timezone,
      name: name ?? '',
      weekdays: weekdays ?? [],
    };
  };

  const getShiftForSchedule = () => {
    if (!customShiftTemplate.startTime || !customShiftTemplate.endTime)
      return {};
    const {
      startTime: momentedStartTime,
      endTime: momentedEndTime,
      name,
      exceptionMinutes,
      weekdays,
    } = customShiftTemplate;

    const startTime = getTime(momentedStartTime);
    const endTime = getTime(momentedEndTime);
    return {
      startTime,
      endTime,
      timezone: selectedShift?.timezone ?? company.timezone,
      name: name ?? '',
      weekdays: weekdays ?? [],
      exceptionMinutes: periodicData?.minutes ?? exceptionMinutes ?? null,
    };
  };
  async function addEditJob(e) {
    // const isEmpty = isPeriodicTimeEmpty();

    // if (isEmpty && isPeriodic) {
    //   return;
    // }

    let jobRes;
    setLoading(true);
    const data = { ...job };
    const setOfAllMarkers = new Set(
      job?.allMarkers?.length ? job?.allMarkers : job?.job?.allMarkers ?? []
    );
    const newActivities = getDataFromReducer('activities', setOfAllMarkers);

    const newOrderKeys = getDataFromReducer('orderKeys', setOfAllMarkers);

    const newNotes = getDataFromReducer('notes', setOfAllMarkers);
    data.activities = newActivities;
    data.orderKeys = newOrderKeys;
    data.notes = newNotes;

    const jobMainDetails = data?.job;
    try {
      let periodicJob = {};

      if (!edit) {
        periodicJob = {
          job: {
            ...getJobForPeriodic(data, jobMainDetails),
            allMarkers: [
              ...new Set(
                selectedProperties
                  ?.map((p) => p.selectedTasks?.map((s) => Object.keys(s)))
                  .flat(2) ?? []
              ),
            ].filter((m) => m),
            propertyKeys: selectedProperties?.map((p) => p.value),
            activities: selectedProperties?.reduce((prev, curr) => {
              const { selectedTasks } = curr;
              const reducedTasks = selectedTasks?.reduce((acc, crr) => {
                acc = { ...acc, ...crr };
                return acc;
              }, {});
              prev = { ...prev, ...reducedTasks };
              return prev;
            }, {}),
          },
          companyKey: job.companyKey,
          shift: getShiftForSchedule(),
          startDate: getTimeInEpoch(shiftStartDate),
          endDate: getTimeInEpoch(customShiftTemplate?.endsOn),
        };

        jobRes = await createEditShiftJob(periodicJob);
      } else {
        const jobReqBody = {
          ...getJobForPeriodic(data, jobMainDetails),
          allMarkers: [
            ...new Set(
              selectedProperties
                ?.map((p) => p.selectedTasks?.map((s) => Object.keys(s)))
                .flat(2) ?? []
            ),
          ].filter((m) => m),
          activities: selectedProperties?.reduce((prev, curr) => {
            const { selectedTasks } = curr;
            const reducedTasks = selectedTasks?.reduce((acc, crr) => {
              acc = { ...acc, ...crr };
              return acc;
            }, {});
            prev = { ...prev, ...reducedTasks };
            return prev;
          }, {}),
        };
        jobRes = await editSchedule(jobReqBody, scheduleEditType);
      }
      onEdiScheduleTypeSelection();
      onPreviewModalClose();
      handleClose(jobRes);
      setJob(defaultJob);
      setNotes(null);
      setPeriodicData(defaultPeriodicTimeSchedule);
      setCronExp('');
      setScheduleEditType('onlyThis');
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }

  const {
    isOpen,
    onOpen,
    onClose: onEdiScheduleTypeSelection,
  } = useDisclosure();

  const addOrEditSchedule = async (e) => {
    e.preventDefault();
    if (!edit) {
      try {
        await addEditJob();
      } catch (error) {
        console.error(error);
      }
    } else {
      onOpen();
      setOpenPreviewModal(false);
    }
  };

  useEffect(() => {
    if (!isPeriodic && edit) {
      setCustomShiftTemplate(individualJob.shift);
    }
  }, [individualJob, edit, isPeriodic]);

  useEffect(() => {
    setSelectedProperties(null);
    if (!open) {
      setSelectedCrew();
      setJob(defaultJob);
      setNoteInJob(false);
      setSelectedProperty();
      setSelectedShift(null);
      setSelectedMarkers(null);
      setSelectedService(null);
    }
  }, [open]);

  function handleFormInputChange(event) {
    setJob({
      ...job,
      [event.target.name]: event.target.value,
    });
    setPeriodicJobData({
      ...periodicJobData,
      [event.target.name]: event.target.value,
    });
  }

  function getLabelValue(d, isMarkers) {
    const data = d ?? [];
    if (!isMarkers) {
      return data?.map((detail) => {
        const value = detail.key ?? detail.masterUid ?? detail.uid;
        return { value, label: detail.name };
      });
    }

    const result = data?.map((detail) => {
      return {
        value: detail.key,
        label: detail.name,
      };
    });

    return result;
  }

  const getJobKey = () => {
    if (edit) {
      return job.key ?? '';
    }
    return '';
  };

  const getCreatedDate = () => {
    if (edit) {
      return job.createdDate ?? '';
    }
    return null;
  };

  const getUidsOfSelectedCrews = (crewsSelected) => {
    let crewsUids = [];
    if (Array.isArray(crewsSelected)) {
      crewsUids = crewsSelected?.map((prov) => prov.value);
    } else {
      crewsUids = crewsSelected && [crewsSelected?.value];
    }

    return crewsUids;
  };

  async function handleServiceChange(service) {
    const serv = service.value;
    const { serviceFeatures } = serv;
    setSelectedService(service);
    setSelectedMarkers(null);
    setNames();
    setSelectedProperties(null);

    let updatedJob = {
      ...defaultJob,
      invoicingEnabled: serviceFeatures?.invoicing ?? false,
      serviceKey: serv.key,
      serviceType: serv.type,
      key: edit ? job.key : '',
      providerUids: getUidsOfSelectedCrews(selectedCrew),
      createdDate: getCreatedDate(),
    };
    if (!isPeriodic && !edit) {
      updatedJob = { ...updatedJob };
    } else if (isPeriodic) {
      updatedJob = {
        job: { ...updatedJob },
        name: job.name,
        companyKey: job.companyKey,
        key: getJobKey(),
        createdDate: getCreatedDate(),
      };
    }
    if (edit) {
      updatedJob = { ...updatedJob, name: job.name };
    }
    setJob({
      ...updatedJob,
      name: job.name,
    });
    if (isPeriodic) {
      setPeriodicJobData({
        ...defaultPeriodicJob,
        serviceKey: serv.key,
      });
    }
  }

  useEffect(() => {
    let isSubscribe = true;
    const updateServices = (response) => {
      const serviceOptions = Object.values(response).map((service) => {
        return { value: service, label: service.name };
      });

      if (isSubscribe) setServices(serviceOptions);
    };

    getRealTimeServices(updateServices);

    return () => {
      isSubscribe = false;
    };
  }, []);

  useEffect(() => {
    const serviceSelected = selectedService?.value;
    if (!edit) {
      setNoTouchJob(serviceSelected?.serviceFeatures?.noTouch ?? false);
    }
    setMarkers([]);
    setSelectedProperty();
    if (serviceSelected?.serviceFeatures?.properties) {
      getPropertiesOfCompany().then((response) => {
        const properties = response?.reduce((acc, property) => {
          const doesLocationHaveMicrolocation = Object.values(
            allMarkers ?? {}
          ).some((m) => m.propertyKey === property.id);
          if (!property.archive && doesLocationHaveMicrolocation) {
            return [...acc, { label: property.name, value: property.id }];
          }
          return acc;
        }, []);
        setLocations(response);
        setPropertyDetail(properties);
      });
    } else if (
      serviceSelected?.type === 'markerbased' &&
      serviceSelected?.key
    ) {
      getMarkersForServiceKey(serviceSelected?.key).then(async (response) => {
        const selectableMarkers = getLabelValue(response, true);
        setMarkers(selectableMarkers);
      });
    } else if (serviceSelected) {
      getRoutesForService(serviceSelected).then(async (response) => {
        const serviceRoutes = getLabelValue(response);
        setRoutes(serviceRoutes);
      });
    }

    setPropertyDetail(null);
    setSelectedProperties(null);
  }, [selectedService, individualJob]);

  useEffect(() => {
    const serviceFeatures = selectedService?.value?.serviceFeatures;
    if (!edit) {
      setJob({
        ...job,
        noTouch: serviceFeatures?.noTouch ?? false,
      });
    }
  }, [selectedService]);

  useEffect(() => {
    if (services?.length === 1 && open) {
      handleServiceChange(services[0]);
    }
  }, [services, open]);

  const getRoutesOfAJob = async (routesKeys) => {
    const response = routesKeys?.map((routeKey) => {
      const r = getRoutesFromDatabase(routeKey);
      return r;
    });

    const arrayOfNames = await Promise.all(response ?? []);
    return arrayOfNames;
  };

  const getCrewsOfAJob = (providerUids) => {
    const setOfProviderUids = new Set(providerUids);
    const selectedCrews = crew?.reduce((acc, crewDetails) => {
      if (!setOfProviderUids?.has(crewDetails.value)) {
        return acc;
      }
      return [...acc, crewDetails];
    }, []);

    return selectedCrews;
  };

  async function getMarkersOfAJob(markerKeys = []) {
    const response = markerKeys?.map((key) => {
      const marker = getServicePointById(key);
      return marker;
    });

    const arrayOfNames = await Promise.all(response);
    return arrayOfNames;
  }

  useEffect(() => {
    if (isPeriodic) {
      const isEmpty = isPeriodicTimeEmpty();
      if (isEmpty) {
        return;
      }
      const cronExpression = generateCronExpression(
        periodicData.minutes,
        periodicData.hours,
        periodicData.weekDays
      );
      const expression = cronstrue.toString(cronExpression);
      setCronExp(expression);
    }
  }, [periodicData]);

  useEffect(() => {
    let isSubscribe = true;
    const getServiceOfAJob = () => {
      let serviceKey = '';
      if (isPeriodic) serviceKey = individualJob?.job?.serviceKey;
      else serviceKey = individualJob?.serviceKey;

      const service = services?.filter(
        (serviceOpt) => serviceKey === serviceOpt?.value?.key
      );

      if (service?.length > 0) {
        setServiceOption(service[0]);
        setSelectedService(service[0]);
      }
    };

    setJob(() => {
      return individualJob;
    });

    if (individualJob?.serviceType === 'markerbased') {
      getMarkersOfAJob(individualJob?.allMarkers).then((markersDetails) => {
        const markersNames = markersDetails?.map((marker) => marker.name);
        if (isSubscribe) {
          const labels = getLabelValue(markersDetails);
          setSelectedMarkers(labels);
          setNames(markersNames);
        }
      });
    } else if (individualJob?.serviceType === 'outdoor') {
      getRoutesOfAJob(individualJob?.allRoutes).then((routesNames) => {
        if (isSubscribe) setNames(routesNames);
      });
    }

    const filteredSelectedCrews = getCrewsOfAJob(individualJob?.providerUids);
    const { shift } = individualJob ?? {};
    getLocationDetailsOfJob(individualJob);
    setSelectedCrew(filteredSelectedCrews);
    setRepetative(individualJob?.repetative);
    if (shift) {
      setPeriodicData({
        ...periodicData,
        weekDays: shift.weekdays,
      });
      setCustomShiftTemplate({
        ...shift,
        startDate: individualJob.createdDate,
        endDate: individualJob.createdDate,
        endsOn: individualJob.createdDate,
      });
    }

    getServiceOfAJob();

    return () => {
      isSubscribe = false;
    };
  }, [edit, individualJob, isPeriodic]);

  useEffect(() => {
    const service = selectedService?.value;
    const selectedServiceKey = service?.key;
    const serviceKey =
      individualJob?.job?.serviceKey ?? individualJob?.serviceKey;

    if (edit && serviceKey === selectedServiceKey) {
      const propertyKeys =
        individualJob?.job?.propertyKeys ?? individualJob?.propertyKeys;
      const setOfPropertyKeys = new Set(propertyKeys ?? []);

      const propertiesThatsSelected = propertyDetail?.filter((property) => {
        return setOfPropertyKeys.has(property.value);
      });

      setSelectedProperties(propertiesThatsSelected);
      setSelectedProperty(propertyKeys);
    }
  }, [edit, propertyDetail]);

  useEffect(() => {
    if (edit) {
      setNoTouchJob(
        individualJob?.job?.noTouch ?? individualJob?.noTouch ?? false
      );
    }
  }, [edit]);

  useEffect(() => {
    let isSubscribe = true;
    if (selectedService && selectedService?.value?.type !== 'markerbased') {
      getRoutesForService(selectedService.value).then((response) => {
        const serviceRoutes = Object.values(response).map((route) => {
          return { value: route.key, label: route.name };
        });
        if (isSubscribe) setRoutes(serviceRoutes);
      });
    }

    return () => {
      isSubscribe = false;
    };
  }, [serviceOption, selectedService]);

  useEffect(() => {
    let isSubscribe = true;
    getCrewsForJobs().then((crewMembers) => {
      if (isSubscribe) setCrew(crewMembers);
    });

    return () => {
      isSubscribe = false;
    };
  }, []);

  useEffect(() => {
    const getAllMarkersOfSelectedProperties = async (
      propertiesThatAreSelected,
      service
    ) => {
      const markersPromises = propertiesThatAreSelected?.map((propertyKey) => {
        return getMicrolocationsByLocationKey(
          propertyKey,
          service.type,
          service.key
        );
      });

      const res = await Promise.all(markersPromises ?? []);

      const markersRes = res?.flat();
      const markersListForDropDown = getLabelValue(markersRes, true);
      const reducedByLocation = markersRes.reduce((prev, curr) => {
        prev[curr.propertyKey] = prev[curr.propertyKey]
          ? [...prev[curr.propertyKey], ...getLabelValue([curr], true)]
          : getLabelValue([curr], true);
        return prev;
      }, {});
      setMicrolocationsByLocation({
        ...microlocationsByLocation,
        ...reducedByLocation,
      });
      return markersListForDropDown;
    };
    if (selectedProperty?.length && selectedService) {
      const service = selectedService?.value;

      getAllMarkersOfSelectedProperties(selectedProperty, service).then(
        (res) => {
          setMarkers(res);
        }
      );
    } else if (
      !selectedProperty &&
      selectedService?.value.serviceFeatures.properties
    ) {
      setSelectedMarkers(null);
      setMarkers([]);
      setJob({ ...job, allMarkers: [] });
    } else if (!selectedProperty?.length) {
      setMarkers([]);
    }
  }, [selectedProperty]);

  useEffect(() => {
    let isSubscribe = true;
    const { serviceFeatures } = selectedService?.value ?? {};

    function _updateActivities(response = []) {
      const data = response.reduce((acc, curr) => {
        acc[curr.category] = acc[curr.category] ?? [];
        acc[curr.category] = [...acc[curr.category], curr];
        return acc;
      }, {});

      if (isSubscribe) {
        setArrayOfTasks(response);
        setCategoryTasks(data);
      }
    }

    if (serviceFeatures?.activities || serviceFeatures?.activitiesOnJob) {
      getActivitiesOfACompany(_updateActivities);
    } else {
      setCategoryTasks({});
    }

    return () => {
      isSubscribe = false;
    };
  }, [selectedService?.value.serviceFeatures]);

  const handleDateChange = (d) => {
    setDate(d);
    setJob({ ...job, dateToEndJob: parseInt(moment(d._d).format('x'), 10) });
  };

  async function setRoutesOrMarkers(data, selectedOptions, propertyKeys) {
    setIsPropertyChanged(false);
    const { serviceFeatures } = selectedService.value;
    let removedMarker = '';
    if (selectedService.value.type === 'markerbased') {
      const markersSelected = selectedOptions?.length ? selectedOptions : null;
      setSelectedMarkers(markersSelected);
      if (
        data?.length &&
        (!allMarkers?.[data[data.length - 1]]?.longitude ||
          !allMarkers?.[data[data.length - 1]]?.latitude) &&
        noTouchJob
      ) {
        setIsConfigureJobOpen(true);
      } else if (
        data.length > (job?.allMarkers?.length ?? 0) &&
        (serviceFeatures?.activitiesOnJob || serviceFeatures?.enableJobNotes)
      ) {
        setSelectedLastMarker(data[data.length - 1]);
        setOpenActivitiesModal(true);
      } else if (data.length < job?.allMarkers?.length) {
        const markerSet = new Set(data);
        const removedMarkers = job?.allMarkers?.filter(
          (markerKey) => !markerSet?.has(markerKey)
        );

        removedMarker = removedMarkers[0] ?? '';
      }

      let updatedJobData = {
        allMarkers: data,
      };
      if (removedMarker) {
        updatedJobData = {
          ...updatedJobData,
          activities: { ...job?.activities, [removedMarker]: [] },
          notes: { ...job?.notes, [removedMarker]: null },
        };
      }

      if (propertyKeys) {
        setJob({ ...job, ...updatedJobData, propertyKeys });
      } else {
        setJob({ ...job, ...updatedJobData });
      }
    } else {
      const orderKeys = await getWorkOrderFromRoutes(data);
      setJob({ ...job, allRoutes: data ?? [], orderKeys });
    }
  }

  async function handlePropertyChange(data) {
    const serv = selectedService?.value;
    const { serviceFeatures } = serv ?? {};
    setIsPropertyChanged(!isPropertyChanged);
    const propertyKeys =
      data?.map((propertyLabVal) => propertyLabVal?.value) ?? null;

    if (propertyKeys?.length < selectedProperty?.length) {
      const setOfPropertyKeys = new Set(propertyKeys ?? []);

      const newMarkersPromises = selectedMarkers?.map((sm) => {
        return getServicePointById(sm.value);
      });

      const newMarkersValues = await Promise.all(newMarkersPromises ?? []);

      const markersPropertiesKeyValue = newMarkersValues?.reduce((a, c) => {
        return { ...a, [c.key]: c.propertyKey };
      }, {});

      const newMarkersSelected = selectedMarkers?.filter((sm) => {
        return setOfPropertyKeys.has(markersPropertiesKeyValue[sm.value]);
      });

      const markerKeys = newMarkersSelected?.map((m) => m.value) ?? [];
      await setRoutesOrMarkers(markerKeys, newMarkersSelected, propertyKeys);
    } else {
      setJob({ ...job, propertyKeys });
    }
    setSelectedProperty(propertyKeys);

    if (selectedProperties) {
      const keys = selectedProperties.map((i) => i.value);
      const checkPropertySelected = keys.includes(data[0].value);
      const properties = selectedProperties.filter(
        (i) => i.value !== data[0]?.value
      );
      setSelectedProperties(
        checkPropertySelected ? properties : [...data, ...(properties ?? [])]
      );
    } else {
      setSelectedProperties([...data, ...(selectedProperties ?? [])]);
    }
  }
  function checkPropertyEnabled() {
    return (
      selectedService?.value?.serviceFeatures?.properties && selectedProperty
    );
  }

  function checkIfPropertySelected() {
    // TODO: here check if one location is selected for microlocation add
    return (
      !selectedService?.value?.serviceFeatures?.properties &&
      job?.providerUids?.length > 0 &&
      selectedLocation
    );
  }

  useEffect(() => {
    if (!noTouchJob) setJob({ ...job, temperatureMonitoring: false });
  }, [noTouchJob]);

  function handleNoTouchChange(event) {
    event.stopPropagation();
    setJob({ ...job, noTouch: event.target?.checked });
    setNoTouchJob(event.target?.checked);
  }

  function handleNoteConfigChange(event) {
    event.stopPropagation();
    setNoteInJob(!noteInJob);
  }

  function handleImgConfigChange(event) {
    event.preventDefault();
    setJob({ ...job, allowImageFromGallery: event.target?.checked });
  }

  const ImageConfiguration = () => {
    if (selectedService?.value?.serviceFeatures?.imageConfiguration) {
      return (
        <Flex justify="space-between">
          <Text>Allow image from gallery</Text>
          <Switch
            isDisabled={edit && job?.status !== 'assigned'}
            isChecked={job?.allowImageFromGallery}
            onChange={handleImgConfigChange}
          />
        </Flex>
      );
    }
    return null;
  };

  function handleTempConfigChange(event) {
    event.preventDefault();
    setJob({ ...job, temperatureMonitoring: event.target.checked });
  }

  function isNoTouchEnabled() {
    return selectedService?.value?.serviceFeatures?.noTouch;
  }

  function isTemperatureMonitoringEnabled() {
    return selectedService?.value?.serviceFeatures?.temperatureMonitoring;
  }

  const TemperatureConfiguration = () => {
    if (isTemperatureMonitoringEnabled() && isNoTouchEnabled() && noTouchJob) {
      return (
        <Flex justify="space-between">
          <Text>Monitor Temperature</Text>

          <Switch
            isDisabled={edit && job?.status !== 'assigned'}
            isChecked={job?.temperatureMonitoring}
            onChange={handleTempConfigChange}
          />
        </Flex>
      );
    }
    return null;
  };

  function handleShiftChange(shiftSelected) {
    setSelectedShift(shiftSelected);
    const shiftKey = shiftSelected?.value?.id ?? null;
    setJob({ ...job, shiftKey });
  }
  function handleActivities() {
    setOpenActivitiesModal(false);
    let activities;
    if (!orderSelected) {
      activities = {
        [selectedLastMarker]: selectedActivities ?? [],
      };
    } else {
      activities = {
        [orderSelected.markerKey]: selectedActivities ?? [],
      };
    }
    setJob({
      ...job,
      activities: { ...(job?.activities ?? {}), ...activities },
      notes: notes ?? null,
    });
  }

  function handleTaskSelection(microlocationKey, tasks, locationKey) {
    setOpenActivitiesModal(false);
    const activities = {
      [microlocationKey]: tasks ?? [],
    };

    const updatedTasks = {
      ...(tasksUnderMicrolocation ?? {}),
      [locationKey]: tasksUnderMicrolocation?.[locationKey]?.some((m) =>
        Object.keys(m).includes(microlocationKey)
      )
        ? [
            ...(tasksUnderMicrolocation?.[locationKey]?.filter(
              (m) => !Object.keys(m).includes(microlocationKey)
            ) ?? []),
            activities,
          ]
        : [...(tasksUnderMicrolocation?.[locationKey] ?? []), activities],
    };

    setTasksUnderMicrolocation(updatedTasks);
    setJob({
      ...job,
      activities: { ...(job?.activities ?? {}), ...activities },
      notes: notes ?? null,
    });
  }

  function saveTasks(updatedTasks) {
    const tasks = updatedTasks ?? tasksUnderMicrolocation;

    if (!updatedTasks && !isTasksChanged && !selectedMicrolocations?.length) {
      handleLocationModalClose();
      return;
    }
    function getSelectedTasks(locKey) {
      if (
        Object.keys(tasksUnderMicrolocation)?.some(
          (t) => isTasksChanged[locKey]
        )
      ) {
        if (
          !tasks[locKey]?.some((t) =>
            Object.keys(t).some((tt) => isTasksChanged[tt])
          )
        ) {
          return [
            ...(selectedProperties.find((sp) => sp.value === locKey)
              .selectedTasks ?? []),
            ...tasks[locKey],
          ];
        }
      }
      return [...tasks[locKey]];
    }

    const updatedProperties = selectedProperties?.map((loc) => {
      const locationKey = loc.value;
      if (tasks?.[locationKey]) {
        return {
          ...loc,
          selectedTasks: getSelectedTasks(locationKey),
        };
      }
      return loc;
    });
    setSelectedProperties(updatedProperties);
    handleLocationModalClose();
  }

  function handleActivityClose() {
    const activities = {
      [selectedLastMarker]: [],
    };
    setJob({
      ...job,
      activities: { ...(job?.activities ?? {}), ...activities },
    });
    setOpenActivitiesModal(false);
  }

  function handleActivitiesNotes(e) {
    e.stopPropagation();
    if (!orderSelected) {
      setNotes({
        ...notes,
        [selectedLastMarker]: e.target.value,
      });
    } else {
      setNotes({
        [orderSelected?.markerKey]: e.target.value,
      });
    }
  }

  function handleCalendarDate(d, key) {
    const expectedTime = moment(d._d).valueOf();
    setJob({ ...job, [key]: expectedTime });
  }

  const selectJobStartAndEndTime = () => {
    if (!companyDetail) {
      return (
        <Row>
          <CircularProgress size={20} />
        </Row>
      );
    }

    function getMinimumDate() {
      if (!job.expectedStartTime) {
        return undefined;
      }
      return new Date(job.expectedStartTime);
    }

    if (!companyDetail?.markerFeatures?.calendarView) {
      return null;
    }
    return (
      <Col gap="8px">
        <Divider />
        <Span weight="bold">Select Time</Span>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Col gap="16px">
            <DateTimePicker
              size="small"
              label="Start Time"
              inputVariant="outlined"
              value={job.expectedStartTime}
              onChange={(d) => handleCalendarDate(d, 'expectedStartTime')}
              minutesStep={15}
              disabled={job?.status !== 'assigned'}
            />

            <DateTimePicker
              size="small"
              label="End Time"
              inputVariant="outlined"
              value={job.expectedEndTime}
              onChange={(d) => handleCalendarDate(d, 'expectedEndTime')}
              minDate={getMinimumDate()}
              minutesStep={15}
              minDateMessage="End date should not be more than expected start time"
              disabled={job?.status !== 'assigned'}
            />
          </Col>
        </MuiPickersUtilsProvider>
        <Divider />
      </Col>
    );
  };

  function areExpectedTimesEmpty() {
    return !job?.expectedStartTime || !job?.expectedEndTime;
  }

  function isEndTimeLessThanStartTime() {
    return job?.expectedEndTime < job?.expectedStartTime;
  }

  function checkForDisablity() {
    return (
      companyDetail?.markerFeatures?.calendarView &&
      (areExpectedTimesEmpty() || isEndTimeLessThanStartTime())
    );
  }

  function checkIfActivitiesEnabled() {
    const { serviceFeatures } = selectedService?.value ?? {};
    return serviceFeatures?.enableJobNotes || serviceFeatures?.activitiesOnJob;
  }

  const handleCrewChange = (crews) => {
    const crewsSelected = crews;
    const crewsUids = getUidsOfSelectedCrews(crewsSelected);
    setSelectedCrew(crewsSelected);
    setJob({ ...job, providerUids: crewsUids ?? [] });
  };

  const getSelectedMarkers = () => {
    if (!selectedMarkers?.length) return null;
    return selectedMarkers;
  };

  const handleConfigureJobCancel = (isCancel) => {
    if (!isCancel) {
      handleClose();
      setNotes(null);
      setJob(defaultJob);
    } else {
      const newMarkers = [...selectedMarkers];
      newMarkers.splice(newMarkers.length - 1, 1);
      setSelectedMarkers(newMarkers);
    }
    setIsConfigureJobOpen(false);
  };

  const handleScheduleClick = () => {
    setOpenDialogForRepeatShift(true);
  };

  const handleSelection = (periodicSchedule) => {
    setPeriodicData(periodicSchedule);
    const isEmpty = isPeriodicTimeEmpty(periodicSchedule);
    if (isEmpty) {
      return;
    }
    setIsTimeSelectionOpen(false);
    const cronExpression = generateCronExpression(
      periodicSchedule.minutes,
      periodicSchedule.hours,
      periodicSchedule.weekDays
    );
    const expression = cronstrue.toString(cronExpression);
    setCronExp(expression);
  };

  const handleCancel = () => {
    setIsTimeSelectionOpen(false);
    if (!edit && isPeriodicTimeEmpty()) {
      setPeriodicData(defaultPeriodicTimeSchedule);
      setCronExp('');
    }
  };

  const getHeightOfTheScreenWindow = () => {
    return window.innerHeight;
  };

  const getWidthOfTheScreen = () => {
    return window.innerWidth;
  };

  const handleServiceDistanceChange = (event) => {
    const valueInNumber = parseInt(event.target.value, 10);
    if (selectedJobType === SHIFT_JOBS) {
      setJob({
        ...job,
        job: { ...job.job, minimumServingDistance: valueInNumber },
      });
    } else {
      setJob({ ...job, minimumServingDistance: valueInNumber });
    }
  };

  const handleGeoFencingEnablingChange = (event) => {
    setJob({
      ...job,
      enabledGeofencing: event.target.checked,
    });
  };

  function handleChange(data) {
    let allSelected = [];
    if (data) {
      if (Array.isArray(data)) {
        allSelected = data.map((prov) => prov.value);
      } else {
        allSelected = [data.value];
      }
    }

    setRoutesOrMarkers(allSelected, data);
  }
  const handleEndDateChange = (selectedShiftEndDate) => {
    setShiftEndDate(selectedShiftEndDate);
  };
  const handleStartDateChange = (startDate) => {
    setShiftStartDate(startDate);
  };

  const handleWeekDays = (selWeekDays) => {
    const selectedDays = selWeekDays.reduce((days, day) => {
      days.push(day.value);
      return days;
    }, []);
    setCustomShiftTemplate({
      ...customShiftTemplate,
      weekdays: selectedDays,
    });
  };
  function handleDataChange(data, type) {
    if (type === 'startDate') handleStartDateChange(moment(data).valueOf());
    else if (type === 'endsOn') handleEndDateChange(moment(data).valueOf());
    setCustomShiftTemplate({ ...customShiftTemplate, [type]: data });
  }
  function getTitle() {
    if (title) {
      return title;
    }
    if (edit) {
      return 'Edit Schedule';
    }
    return 'Add Schedule';
  }

  // TODO: set default name to null for now
  function renderShiftLocationSelection() {
    if (step === 'location') {
      return (
        <Flex flexDirection="column" height="100%">
          <Heading as="h4" padding="20px">
            Select Location
          </Heading>
          <Box
            height="0px"
            border="1px solid #323A45"
            opacity="0.4"
            marginBottom="15px"
            mt="10px"
          />
          <Box overflow="auto">
            {selectedService?.value?.serviceFeatures?.properties &&
              !markerSelected?.propertyKey && (
                <>
                  {propertyDetail ? (
                    propertyDetail.map((i) => {
                      const id = i?.value;
                      const label = i?.label;
                      const location = locations.find((l) => l.id === i.value);
                      return (
                        <>
                          <Flex
                            key={id}
                            flexDirection="column"
                            cursor="pointer"
                            flexWrap="wrap"
                            borderRadius="8px"
                            padding="10px"
                            marginTop="15px"
                            border={
                              id === selectedProperties?.id
                                ? '1px solid #006db8'
                                : 'none'
                            }
                          >
                            <Checkbox
                              onChange={() => handlePropertyChange([i])}
                              borderColor="primary.500"
                              isDisabled={edit}
                              isChecked={
                                selectedProperties?.some(
                                  (s) => s.value === id
                                ) ?? false
                              }
                            >
                              <Flex gridGap="md">
                                <Flex
                                  cursor="pointer"
                                  gridGap="sm"
                                  alignItems="center"
                                >
                                  <Avatar name={i?.name} src={i?.imageUrl} />
                                  <Flex flexDirection="column">
                                    <Text fontWeight="bold">{label}</Text>
                                    <Text>{location?.address}</Text>
                                  </Flex>
                                </Flex>
                              </Flex>
                            </Checkbox>
                          </Flex>

                          <Divider />
                        </>
                      );
                    })
                  ) : (
                    <Flex justify="center">
                      <CircularProgress isIndeterminate color="primary.300" />
                    </Flex>
                  )}
                </>
              )}
          </Box>
        </Flex>
      );
    }
    return (
      <Flex flexDirection="column" height="100%" paddingBottom="10px">
        <Heading as="h4" padding="20px">
          Select Template
        </Heading>
        <ShiftSelect
          selectedShift={selectedShift}
          shifts={shifts}
          selectedJobType={selectedJobType}
          handleShiftChange={handleShiftChange}
          handleWeekDays={handleWeekDays}
          shiftData={customShiftTemplate}
          setCustomShiftTemplate={setCustomShiftTemplate}
          setSelectedShift={setSelectedShift}
          shiftKey={individualJob?.shiftKey ?? null}
          handleDataChange={handleDataChange}
          isEditShift={edit}
        />
      </Flex>
    );
  }

  const formHeaderContent = () => {
    return (
      <>
        <Flex>
          {/* call */}
          <Flex>
            <Flex
              style={{
                background: `${
                  step === 'shift'
                    ? '#006DB8 0% 0% no-repeat padding-box'
                    : '#F1F1F1 0% 0% no-repeat padding-box'
                }`,
                borderRadius: '5px',
                width: '46px',
                color: `${step === 'shift' ? 'white' : 'black'}`,
                justifyContent: 'center',
                height: '46px',
              }}
            >
              {step === 'shift' ? (
                <Text
                  textAlign="center"
                  fontWeight={600}
                  fontSize="18px"
                  marginTop="10px"
                  color={step === 'shift' ? 'white' : 'black'}
                >
                  1
                </Text>
              ) : (
                <Icon
                  height="24px"
                  width="24px"
                  textAlign="center"
                  marginTop="10px"
                >
                  <AcceptedSvg />
                </Icon>
              )}
            </Flex>
            <Text padding="5px" marginTop="10px">
              Select Shift Timing & Janitor
            </Text>
          </Flex>
          <Flex marginLeft="15px">
            <Flex
              style={{
                background: `${
                  step === 'location'
                    ? '#006DB8 0% 0% no-repeat padding-box'
                    : '#F1F1F1 0% 0% no-repeat padding-box'
                }`,
                borderRadius: '5px',
                width: '46px',
                color: `${step === 'location' ? 'white' : 'black'}`,
                height: '46px',
                justifyContent: 'center',
              }}
            >
              <Text
                textAlign="center"
                fontWeight={600}
                fontSize="18px"
                marginTop="10px"
                color={step === 'location' ? 'white' : 'black'}
              >
                2
              </Text>
            </Flex>
            <Text padding="5px" marginTop="10px">
              Assign Locations
            </Text>
          </Flex>
        </Flex>
        <Box
          border="1px solid #323A45"
          opacity="0.4"
          marginBottom="32px"
          mt="16px"
        />
        <Text> Steps {step === 'shift' ? 1 : 2}/2</Text>
      </>
    );
  };

  const onHandleRepeatDays = (day) => {
    let daysInWeek = [...(periodicData.weekDays ?? [])];
    if (daysInWeek.includes(day)) {
      daysInWeek = daysInWeek.filter((d) => d !== day);
    } else {
      daysInWeek.push(day);
    }
    setPeriodicData({ ...periodicData, weekDays: daysInWeek });
    setCustomShiftTemplate({ ...customShiftTemplate, weekdays: daysInWeek });
  };

  const onCloseRepeatShift = () => {
    setOpenDialogForRepeatShift(false);
  };

  function renderShiftLocationForm() {
    if (step === 'shift') {
      return (
        <>
          <div>
            {isPeriodic ? (
              <>
                {formHeaderContent()}
                <br />
                <CustomShiftForm
                  selectedShift={selectedShift}
                  shifts={shifts}
                  selectedJobType={selectedJobType}
                  handleShiftChange={handleShiftChange}
                  handleWeekDays={handleWeekDays}
                  shiftData={customShiftTemplate}
                  setCustomShiftTemplate={setCustomShiftTemplate}
                  setSelectedShift={setSelectedShift}
                  handleDataChange={handleDataChange}
                  isEditShift={edit}
                  setPeriodicData={setPeriodicData}
                  periodicData={periodicData}
                  job={job}
                  setJob={setJob}
                  handleStartDateChange={handleStartDateChange}
                  handleEndDateChange={handleEndDateChange}
                  shiftStartDate={shiftStartDate}
                  shiftEndDate={shiftEndDate}
                />

                <Flex
                  gridGap="xsm"
                  marginTop="16px"
                  cursor="pointer"
                  onClick={handleScheduleClick}
                >
                  <Checkbox
                    isChecked={repetative}
                    onClick={handleScheduleClick}
                    bg="white"
                    borderRadius="4px"
                    borderColor="primary.500"
                  />
                  <Text>Repeat Shift</Text>
                </Flex>

                <Text
                  onClick={handleScheduleClick}
                  textDecoration="underline"
                  fontSize="14px"
                  letterSpacing="0.22px"
                  color="primary.900"
                  marginTop="8px"
                >
                  {repetative && periodicData?.weekDays?.length
                    ? `${getRepeatOrNotRepeat(
                        periodicData.weekDays?.map(
                          (w) =>
                            weekDaysData.find((wd) => wd.value === w)?.label
                        ),
                        repetative
                      )} ${
                        customShiftTemplate?.endsOn
                          ? `until ${dayjs(customShiftTemplate?.endsOn).format(
                              'MMM D, YYYY'
                            )}`
                          : ''
                      }`
                    : null}
                </Text>
              </>
            ) : (
              <div />
            )}
            {Object.keys(selectedService ?? {}).length ? (
              <Box>
                <Text
                  textAlign="left"
                  fontWeight={600}
                  fontSize="22px"
                  marginTop="24px"
                  marginBottom="8px"
                >
                  Janitor
                </Text>
                <CustomInput
                  type="children"
                  value={selectedCrew ?? null}
                  placeholder="Select janitors"
                >
                  <CustomSelect
                    value={selectedCrew ?? null}
                    isMulti={
                      selectedService?.value?.serviceFeatures?.multipleCrew
                    }
                    options={crew}
                    onChange={handleCrewChange}
                    // required={!job?.providerUids || job?.providerUids?.length < 1}
                    placeholder={
                      edit
                        ? crewsNames?.map((name) => name).join()
                        : 'Select janitors'
                    }
                  />
                </CustomInput>
              </Box>
            ) : null}
          </div>
          {/* <RepeatModal
            shiftData={customShiftTemplate}
            openDialogForRepeatShift={openDialogForRepeatShift}
            selectedWeekDays={periodicData.weekDays}
            onClose={onClose}
            onHandleRepeatDays={onHandleRepeatDays}
            handleDataChange={handleDataChange}
          /> */}

          <Modal
            blockScrollOnMount={false}
            isOpen={openDialogForRepeatShift}
            onClose={onCloseRepeatShift}
            size="lg"
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Repeat Shift</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Flex gridGap="llg" alignItems="center">
                  <Flex direction="column" gridGap="xl">
                    <Text>Repeat on</Text>
                    <Text>Ends on</Text>
                  </Flex>

                  <Flex direction="column" gridGap="lg">
                    <HStack spacing="xxsm">
                      {weekDaysData.map((weekday) => {
                        return (
                          <Button
                            key={weekday.value}
                            colorScheme={
                              periodicData.weekDays?.includes(weekday.value)
                                ? 'blue'
                                : 'gray'
                            }
                            onClick={() => onHandleRepeatDays(weekday.value)}
                          >
                            {weekday.label.charAt(0)}
                          </Button>
                        );
                      })}
                    </HStack>

                    <Box
                      border="1px solid gray"
                      borderRadius="2px"
                      width="max-content"
                    >
                      <Flex alignItems="center" gridGap="md" ml="6">
                        <Icon as={BsCalendar4} />
                        <DatePicker
                          selected={
                            customShiftTemplate?.endsOn
                              ? new Date(customShiftTemplate?.endsOn)
                              : new Date()
                          }
                          onChange={(dates) => {
                            handleDataChange(dayjs(dates).valueOf(), 'endsOn');
                          }}
                          dateFormat="MM d, yyyy"
                        />
                      </Flex>
                    </Box>
                  </Flex>
                </Flex>
              </ModalBody>

              <ModalFooter>
                <Button
                  variant="ghost"
                  onClick={() => setOpenDialogForRepeatShift(false)}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme="blue"
                  mr={3}
                  onClick={() => {
                    setOpenDialogForRepeatShift(false);
                    if (!periodicData?.weekDays?.length) {
                      setRepetative(false);
                    } else {
                      setRepetative(true);
                    }
                    setCustomShiftTemplate({
                      ...customShiftTemplate,
                      weekdays: periodicData?.weekDays,
                    });
                  }}
                >
                  Save
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      );
    }

    function handleLocationClick(location) {
      setSelectedLocation(location);
      setOpenMicrolocationSelectModal(true);
    }
    return (
      <>
        {formHeaderContent()}
        <Heading fontSize="xl">Assign Location</Heading>
        <Heading paddingTop="15px" color="#323A45" fontSize="larger">
          Locations
        </Heading>

        {selectedProperties?.length ? (
          selectedProperties.map((loc) => {
            return (
              <SelectedLocationComponent
                key={loc.value}
                tasksUnderMicrolocation={tasksUnderMicrolocation}
                loc={loc}
                handleLocationClick={handleLocationClick}
                saveTasks={saveTasks}
                setTasksUnderMicrolocation={setTasksUnderMicrolocation}
                locations={locations}
                setOpenMicrolocationSelectModal={
                  setOpenMicrolocationSelectModal
                }
                allMarkers={allMarkers}
                arrayOfTasks={arrayOfTasks}
                setArrayOfTasks={setArrayOfTasks}
                categoryTasks={categoryTasks}
                setCategoryTasks={setCategoryTasks}
                selectedProperties={selectedProperties}
                setSelectedProperties={setSelectedProperties}
              />
            );
          })
        ) : (
          <Text>Select Locations from the right panel </Text>
        )}
        {/* TODO: after location selected, choose microlocation and then add task to it */}
      </>
    );
  }

  function handleLocationModalClose() {
    setSelectedLocation(undefined);
    setSelectedMicrolocations(undefined);
    setOpenMicrolocationSelectModal(false);
    setIsTasksChanged(false);
  }
  function onPreviewModalClose() {
    setOpenPreviewModal(false);
  }

  function checkIfShiftFormFilled() {
    if (
      !job?.providerUids?.length ||
      !customShiftTemplate?.startTime ||
      !customShiftTemplate?.endTime
    ) {
      return true;
    }
    return false;
  }

  function checkIfPreviewDisabled() {
    if (!selectedProperties?.length) {
      return true;
    }
    if (
      selectedProperties?.length &&
      selectedProperties.some((p) => !p.selectedTasks?.length)
    ) {
      return true;
    }
    return false;
  }

  return (
    <>
      <SelectedLocationActionModal
        isOpen={openMicrolocationSelectModal}
        onClose={() => {
          handleLocationModalClose();
        }}
        selectedLocation={selectedLocation}
        microlocations={markers}
        setSelectedActivities={setSelectedActivities}
        selectedService={selectedService}
        setSelectedMicrolocations={setSelectedMicrolocations}
        selectedMicrolocations={selectedMicrolocations}
        selectedActivities={selectedActivities}
        microlocationsByLocation={microlocationsByLocation}
        handleTaskSelection={handleTaskSelection}
        setOpenMicrolocationSelectModal={setOpenMicrolocationSelectModal}
        saveTasks={saveTasks}
        tasksUnderMicrolocation={tasksUnderMicrolocation}
        selectedProperties={selectedProperties}
        setIsTasksChanged={setIsTasksChanged}
        isTasksChanged={isTasksChanged}
        setCategoryTasks={setCategoryTasks}
        categoryTasks={categoryTasks}
        arrayOfTasks={arrayOfTasks}
        setArrayOfTasks={setArrayOfTasks}
      />
      {/* TODO; here add microlocation and task modal for selected/clicked location */}
      <Drawer size="full" isOpen={open} placement="right" onClose={handleClose}>
        <DrawerOverlay>
          <DrawerContent
            className="drawerContent"
            background="#FBF9FB"
            padding="50px"
          >
            <DrawerHeader mb="20px">
              <Heading
                fontSize="xl"
                color=" #162350"
                letterSpacing={0}
                fontWeight="bold"
              >
                {getTitle()}
              </Heading>
            </DrawerHeader>

            <DrawerBody overflow="hidden">
              <form onSubmit={addOrEditSchedule} style={{ height: '100%' }}>
                <Stack gridGap="md">
                  <PreviewShift
                    selectedProperties={selectedProperties}
                    shift={getShiftForSchedule()}
                    onClose={onPreviewModalClose}
                    openPreviewModal={openPreviewModal}
                    submitScheduleRef={submitScheduleRef}
                    locations={locations}
                    shiftEndDate={shiftEndDate}
                    repetative={repetative}
                    job={job}
                    microlocations={allMarkers}
                    loading={loading}
                    janitors={crew}
                    edit={edit}
                  />
                  <Box>
                    {services.length !== 1 && !serviceOfOrder && (
                      <CustomSelect
                        title="Services"
                        options={services}
                        onChange={handleServiceChange}
                        // required={!edit}
                        value={selectedService ?? null}
                        placeholder="Select"
                      />
                    )}
                  </Box>
                </Stack>
                <Flex
                  className="flex1"
                  justifyContent="space-between"
                  height="100%"
                  width="100%"
                >
                  <Box
                    className="box1"
                    background="white"
                    height="100%"
                    overflow="auto"
                    padding="20px"
                    boxShadow=" 0px 12px 24px #ECEEF5"
                    flex={3}
                  >
                    {renderShiftLocationForm()}
                    <Flex
                      flexDirection="row-reverse"
                      justifyContent="space-between"
                    >
                      <Box>
                        <Button
                          onClick={() => {
                            setStep('shift');
                            setCustomShiftTemplate({});
                            setPeriodicData(defaultPeriodicTimeSchedule);
                            onClose();
                          }}
                          variant="outline"
                          color="primary.500"
                          m="3"
                        >
                          Close
                        </Button>
                        {step === 'shift' ? (
                          <Button
                            onClick={() => setStep('location')}
                            disabled={checkIfShiftFormFilled()}
                            colorScheme="blue"
                            background="#006DB8 0% 0%"
                            boxShadow=" 0px 3px 6px #006DB85C"
                            borderRadius="5px"
                            m="3"
                          >
                            Next Step
                          </Button>
                        ) : null}

                        {step === 'location' ? (
                          <>
                            <Button
                              onClick={() => {
                                // TODO: some required fields are removed - recheck that
                                setOpenPreviewModal(true);
                              }}
                              disabled={checkIfPreviewDisabled()}
                              colorScheme="blue"
                              background="#006DB8 0% 0%"
                              boxShadow=" 0px 3px 6px #006DB85C"
                              borderRadius="5px"
                              m="3"
                            >
                              Preview
                            </Button>
                            <Button
                              externalRef={submitScheduleRef}
                              ref={submitScheduleRef}
                              type="submit"
                              m="3"
                              height="0"
                              width="0"
                            />
                          </>
                        ) : null}
                      </Box>
                      {step !== 'shift' ? (
                        <Button
                          onClick={() => {
                            setStep('shift');
                          }}
                          variant="outline"
                          color="primary.500"
                          m="3"
                        >
                          Back
                        </Button>
                      ) : null}
                    </Flex>
                  </Box>
                  {/* TODO: here is selection container - toggle select of shifts and locations */}
                  <Box
                    className="box2"
                    // p="25px"
                    marginLeft="20px"
                    flex={2}
                    background="white"
                    boxShadow="0px 12px 24px #ECEEF5"
                  >
                    {renderShiftLocationSelection()}
                  </Box>
                </Flex>
                {selectJobStartAndEndTime()}
                {selectedService?.value &&
                  selectedService?.value?.type === 'attendance' && (
                    <SingleDatePicker
                      date={date}
                      onDateChange={(d) => handleDateChange(d)}
                      focused={focus}
                      onFocusChange={(f) => setFocus(f.focused)}
                      // required
                    />
                  )}
                {selectedService?.value?.serviceFeatures?.noTouch && (
                  <Flex justify="space-between">
                    <Text>Configure Activities</Text>
                    <Switch
                      isDisabled={edit && !isPeriodic}
                      isChecked={noTouchJob}
                      onChange={handleNoTouchChange}
                    />
                  </Flex>
                )}
                {Object.keys(selectedService ?? {}).length ? (
                  <>
                    {/* {(checkPropertyEnabled() ||
                            checkIfPropertySelected()) &&
                            markers.length > 0 &&
                            !serviceOfOrder && (
                              <>
                                {selectedService.value?.type ===
                                'markerbased' ? (
                                  <CustomInput
                                    type="children"
                                    placeholder="Select micro-location"
                                    value={getSelectedMarkers()}
                                  >
                                    <CustomSelect
                                      placeholder="Select MicroLocation"
                                      onChange={(data) => handleChange(data)}
                                      selectedService={selectedService}
                                      options={markers}
                                      edit={edit}
                                      markersSelected
                                      value={getSelectedMarkers()}
                                      ref={markerRef}
                                      isMulti
                                      openMenuOnFocus={
                                        !selectedService?.value?.serviceFeatures
                                          .multipleProperties
                                      }
                                    />
                                  </CustomInput>
                                ) : (
                                  <CustomInput>
                                    <List
                                      selectedService={selectedService}
                                      edit={edit}
                                      names={names}
                                      options={routes}
                                      setRoutesOrMarkers={setRoutesOrMarkers}
                                    />
                                  </CustomInput>
                                )}
                              </>
                            )} */}

                    {checkIfActivitiesEnabled() && markerSelected && (
                      <Flex justify="space-between">
                        <Text>Configure Activities</Text>
                        <Switch
                          isChecked={noteInJob}
                          onChange={handleNoteConfigChange}
                        />
                      </Flex>
                    )}

                    {/* {selectedService?.value?.serviceFeatures
                            ?.shiftManagement &&
                            selectedJobType === SHIFT_JOBS && (
                              <SelectShiftTemplate
                                selectedShift={selectedShift}
                                shifts={shifts}
                                selectedJobType={selectedJobType}
                                handleShiftChange={handleShiftChange}
                                handleWeekDays={handleWeekDays}
                                shiftData={customShiftTemplate}
                                setCustomShiftTemplate={setCustomShiftTemplate}
                                setSelectedShift={setSelectedShift}
                                handleDataChange={handleDataChange}
                                isEditShift={edit}
                              />
                            )} */}

                    {/* {isPeriodic && (
                            <SInput
                              type="children"
                              value={shiftEndDate}
                              placeholder="Select End Date"
                            >
                              <DatePicker
                                required
                                variant="inline"
                                selected={shiftEndDate}
                                onChange={handleEndDateChange}
                                placeholderText="Select End Date"
                              />
                            </SInput>
                          )} */}

                    <TemperatureConfiguration />
                    <ImageConfiguration />

                    {/* {selectedService?.value?.serviceFeatures
                            ?.properties &&
                            selectedService?.value?.serviceFeatures
                              ?.jobLevelGeofence && (
                              <Flex justify="space-between">
                                <Text>Enable Job Geofencing</Text>
                                <Switch
                                  onChange={handleGeoFencingEnablingChange}
                                  isChecked={
                                    job?.enabledGeofencing ??
                                    job?.job?.enabledGeofencing
                                  }
                                />
                              </Flex>
                            )} */}
                    {(job?.job?.enabledGeofencing ||
                      job?.enabledGeofencing) && (
                      <Box>
                        <CustomInput
                          placeholder="Allow Start schedule At"
                          InputProps={{
                            min: '50',
                          }}
                          rightIcon="m"
                          type="number"
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          value={
                            job?.job?.minimumServingDistance ??
                            job?.minimumServingDistance
                          }
                          onChange={handleServiceDistanceChange}
                        />
                        <Text color="grey" size="12px">
                          Minimum distance the crew should be to present near
                          the property to start the job. We recommend to have 80
                          meters however if the property is larger in size,
                          consider increasing it to a higher value.
                        </Text>
                      </Box>
                    )}
                  </>
                ) : (
                  <div />
                )}
                {/* TODO: this is required in shift preview modal after all input fields are filled */}
                {/* <Flex justify="flex-end" gridGap="md">
                        <Button
                          onClick={onClose}
                          variant="outline"
                          color="primary.500"
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          disabled={loading}
                          colorScheme="blue"
                          background="#006DB8 0% 0%"
                          boxShadow=" 0px 3px 6px #006DB85C"
                          borderRadius="5px"
                        >
                          {edit ? 'Edit' : 'Add'}
                        </Button>
                      </Flex> */}
                <ADialog
                  $minHeight="auto"
                  $maxHeight="75%"
                  justifycontent="space-evenly"
                  padding="24px"
                  // open={openActivitiesModal}
                  open={false}
                  onClose={handleActivityClose}
                  scroll="body"
                  overflow="auto"
                >
                  <Col>
                    <ActivitiesSelection
                      handleSelectedActivities={(activities) => {
                        setSelectedActivities(activities);
                      }}
                      serviceFeatures={selectedService?.value?.serviceFeatures}
                      atJob
                    />

                    {selectedService?.value?.serviceFeatures
                      ?.enableJobNotes && (
                      <StyledTextField
                        placeholder="Notes"
                        variant="outlined"
                        color="primary"
                        onChange={handleActivitiesNotes}
                        margintop="12px"
                        defaultValue={
                          orderSelected && orderSelected?.requestNote
                        }
                      />
                    )}
                  </Col>
                </ADialog>
                <ADialog open={isConfigureJobOpen} maxWidth="500px">
                  <JobConfiguration
                    handleCancel={handleConfigureJobCancel}
                    isOpen={isConfigureJobOpen}
                  />
                </ADialog>
                {isPeriodic && (
                  <ADialog open={isTimeSelectionOpen} $minWidth="500px">
                    <PeriodicJobTimeSelection
                      edit={edit}
                      hoursData={hoursData}
                      minutesData={minutesData}
                      periodicSchedule={periodicData}
                      weekDaysData={weekDaysData}
                      handleSelection={handleSelection}
                      handleCancel={handleCancel}
                    />
                  </ADialog>
                )}
              </form>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>

      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Edit recurring shift
            <ModalCloseButton color="text.500" top="25px" />
            <Box
              maxWidth="481px"
              height="0px"
              border="1px solid #323A45"
              opacity="0.4"
              marginTop="18px"
            />
          </ModalHeader>

          <ModalBody>
            <RadioGroup onChange={setScheduleEditType} value={scheduleEditType}>
              <Flex flexDirection="column" gridGap="md">
                {editScheduleJobTypes?.map((type) => (
                  <Radio value={type.value} key={type.value}>
                    <Text fontWeight="bold">{type.label}</Text>
                  </Radio>
                ))}
              </Flex>
            </RadioGroup>
          </ModalBody>
          <ModalFooter>
            <Flex gridGap="sm">
              <Button
                onClick={onEdiScheduleTypeSelection}
                border="none"
                variant="outline"
                marginRight="10px"
                color="primary.500"
              >
                Cancel
              </Button>
              <Button
                onClick={() => addEditJob()}
                colorScheme="blue"
                isLoading={loading}
                background="#006DB8 0% 0%"
                boxShadow=" 0px 3px 6px #006DB85C"
                borderRadius="5px"
              >
                Save
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const SelectedLocationActionModal = ({
  onClose,
  isOpen,
  selectedLocation,
  microlocations,
  setSelectedActivities,
  setSelectedMicrolocations,
  selectedMicrolocations,
  selectedService,
  selectedActivities,
  microlocationsByLocation,
  handleTaskSelection,
  setOpenMicrolocationSelectModal,
  saveTasks,
  tasksUnderMicrolocation,
  selectedProperties,
  setIsTasksChanged,
  isTasksChanged,
  setCategoryTasks,
  categoryTasks,
  arrayOfTasks,
  setArrayOfTasks,
}) => {
  function removeSelectedMicrolocation(key) {
    const filtered = selectedMicrolocations.filter((ml) => ml.value !== key);
    setSelectedMicrolocations(filtered);
  }
  const microLocationsOfLocation =
    microlocationsByLocation[selectedLocation?.id];

  useEffect(() => {
    if (selectedProperties?.length) {
      const thisLocation = selectedProperties.find(
        (sp) => sp.value === selectedLocation?.id
      );
      if (thisLocation?.selectedTasks?.length) {
        const filtered = microLocationsOfLocation?.filter((ml) => {
          const arrays = thisLocation.selectedTasks?.map((l) => Object.keys(l));
          return arrays.flat().includes(ml.value);
        });
        setSelectedMicrolocations(filtered);
      }
    }
  }, [isOpen, selectedProperties, selectedLocation]);

  function getTaskListsFromMicrolocations(microlocationKey) {
    if (tasksUnderMicrolocation) {
      // const taskLists = tasksUnderMicrolocation[selectedLocation.id]?.filter(
      //   (ml) => {
      //     const arrays = ml[microlocationKey];
      //     return arrays.flat().includes(ml.value);
      //   }
      // );
      const taskLists = tasksUnderMicrolocation[selectedLocation.id]?.find(
        (sl) => {
          return Object.keys(sl).includes(microlocationKey);
        }
      );

      const defaultOption = [];
      taskLists?.[microlocationKey]?.forEach((t) => {
        if (!defaultOption.some((o) => Object.values(o).includes(t.category))) {
          defaultOption.push({ label: t.category, value: t.category });
        }
      });
      return defaultOption;
    }
    return undefined;
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent minHeight="459px" minWidth="80%">
        <ModalHeader
          style={{ font: 'normal normal bold 30px/40px Montserrat' }}
        >
          Assign Micro-Location and Task-List
        </ModalHeader>
        <ModalCloseButton />
        <Divider paddingBottom="10px" />
        <ModalBody paddingTop="10px">
          {!microLocationsOfLocation ? (
            <Flex justify="center">
              <CircularProgress isIndeterminate color="primary.300" />
            </Flex>
          ) : (
            <Flex minH="460px">
              <Box>
                <Text
                  style={{
                    font: 'normal normal 600 14px/18px Montserrat',
                    color: '#8A91A6',
                  }}
                >
                  MicroLocations ({microLocationsOfLocation?.length})
                </Text>
                <br />
                <Flex direction="column">
                  {microLocationsOfLocation?.map((ml) => {
                    return (
                      <>
                        <Flex
                          minWidth="260px"
                          justifyContent="space-between"
                          key={ml.value}
                          padding="15px"
                        >
                          <Text
                            color="#17244F"
                            style={{
                              font: 'normal normal normal 16px/21px Montserrat',
                            }}
                          >
                            {ml.label}
                          </Text>

                          <FaPlusCircle
                            color={
                              selectedMicrolocations?.some(
                                (m) => m.value === ml.value
                              )
                                ? '#17244F80'
                                : '#006DB8'
                            }
                            cursor="pointer"
                            onClick={() => {
                              const ifMicrolocationExists =
                                selectedMicrolocations?.some(
                                  (m) => m.value === ml.value
                                );

                              if (!ifMicrolocationExists) {
                                setSelectedMicrolocations([
                                  ...(selectedMicrolocations ?? []),
                                  ml,
                                ]);
                                handleTaskSelection(
                                  ml?.value,
                                  [],
                                  selectedLocation?.id
                                );
                              }
                            }}
                          />
                        </Flex>
                        <Divider />
                      </>
                    );
                  })}
                </Flex>
              </Box>
              <Box
                overflowY="auto"
                borderLeft="1px solid #52525226"
                flexGrow={1}
              >
                {/* TODO: here show table of task and task list of selected microlocation */}
                {selectedMicrolocations?.length ? (
                  <Table variant="simple">
                    <Thead
                      style={{
                        background: ' #F1F1F1 0% 0% no-repeat padding-box',
                      }}
                    >
                      <Tr>
                        <Th>Microlocation</Th>
                        <Th>Task-List</Th>
                        <Th> </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {selectedMicrolocations.map((ml) => {
                        const taskLists = getTaskListsFromMicrolocations(
                          ml.value
                        );
                        return (
                          <Tr key={ml.value}>
                            <Td>{ml.label} </Td>
                            <Td>
                              <Flex>
                                <TaskListSelect
                                  key={ml.value}
                                  setActivities={setCategoryTasks}
                                  activities={categoryTasks}
                                  arrayOfActivities={arrayOfTasks}
                                  setArrayOfActivities={setArrayOfTasks}
                                  handleSelectedActivities={(activities) => {
                                    // setSelectedActivities(activities);
                                    // handleTaskSelection(
                                    //   ml.value,
                                    //   activities,
                                    //   selectedLocation?.id
                                    // );
                                  }}
                                  handleChange={(activities) => {
                                    if (activities) {
                                      setIsTasksChanged({
                                        ...isTasksChanged,
                                        [selectedLocation?.id]: true,
                                        [ml.value]: true,
                                      });
                                      setSelectedActivities(activities);
                                      handleTaskSelection(
                                        ml.value,
                                        activities,
                                        selectedLocation?.id
                                      );
                                    }
                                  }}
                                  defaultValue={taskLists}
                                  serviceFeatures={
                                    selectedService?.value?.serviceFeatures
                                  }
                                  atJob
                                  selectedActivities={selectedActivities}
                                />
                                {/* <Button
                                  onClick={() =>
                                    removeSelectedMicrolocation(ml.value)
                                  }
                                >
                                  Close
                                </Button> */}
                              </Flex>
                            </Td>
                            <Td>
                              <CloseIcon
                                color="#17244F80"
                                onClick={() =>
                                  removeSelectedMicrolocation(ml.value)
                                }
                              />
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                ) : (
                  <Flex height="450px">
                    <Flex alignSelf="center" paddingLeft="40px">
                      <KeyboardBackspace
                        style={{ color: '#8A91A6' }}
                        color="#17244F80"
                      />
                      <Text>
                        Select Micro-Location from here and then you can asssign
                        tasklist to them
                      </Text>
                    </Flex>
                  </Flex>
                )}
              </Box>
            </Flex>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onClose}
            border="none"
            variant="outline"
            marginRight="10px"
            color="primary.500"
          >
            Cancel
          </Button>
          <Button
            onClick={() => saveTasks()}
            colorScheme="blue"
            background="#006DB8 0% 0%"
            boxShadow=" 0px 3px 6px #006DB85C"
            borderRadius="5px"
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const SelectedLocationComponent = ({
  locations,
  loc,
  handleLocationClick,
  allMarkers,
  saveTasks,
  setTasksUnderMicrolocation,
  setOpenMicrolocationSelectModal,
  tasksUnderMicrolocation,
  setArrayOfTasks,
  arrayOfTasks,
  categoryTasks,
  setCategoryTasks,
  selectedProperties,
  setSelectedProperties,
}) => {
  const location = locations.find((l) => l.id === loc.value);
  const [collapseOpen, setCollapseOpen] = useState(false);

  function handleCollapseOpen() {
    setCollapseOpen(!collapseOpen);
  }

  return (
    <>
      <Flex
        cursor="pointer"
        flexWrap="wrap"
        padding="10px"
        marginTop="15px"
        flexGrow={1}
        key={loc.value}
        justifyContent="space-between"
        style={{ boxShadow: ' 0px 12px 24px #ECEEF5' }}
        background="#FFFFFF 0% 0% no-repeat padding-box"
      >
        <Flex>
          <Avatar name={location?.name} src={location?.imageUrl} />
          <Flex flexDirection="column" paddingLeft="10px">
            <Text key={loc.value} fontWeight="bold" fontSize="18px">
              {location?.name}
            </Text>
            <Text fontWeight={600} fontSize="14px">
              {location?.address}
            </Text>
          </Flex>
        </Flex>
        {!loc.selectedTasks?.length ? (
          <Box padding="5px" alignSelf="center" background="background.600">
            <FaPlusCircle
              style={{
                color: '#006DB8',
                cursor: 'pointer',
              }}
              onClick={() => {
                handleLocationClick(location);
              }}
            />
          </Box>
        ) : (
          <Flex alignItems="center" onClick={handleCollapseOpen}>
            <Flex
              alignItems="center"
              justifyContent="center"
              width="34px"
              height="34px"
              background="#F1F1F1 0% 0% no-repeat padding-box"
              borderRadius="4px"
            >
              <Text fontSize="18px" fontWeight={600}>
                {loc.selectedTasks.length}
              </Text>
            </Flex>
            <Text fontSize="16px" marginLeft="16px" marginRight="7px">
              Micro-Location
            </Text>
            <Icon
              color="#D6D7D9"
              width="10px"
              height="5px"
              as={TriangleDownIcon}
            />
          </Flex>
        )}
      </Flex>
      {loc.selectedTasks?.length && collapseOpen ? (
        <CollapseMicrolocation
          selectedTasks={loc.selectedTasks}
          setActivities={setCategoryTasks}
          activities={categoryTasks}
          setSelectedProperties={setSelectedProperties}
          selectedProperties={selectedProperties}
          arrayOfActivities={arrayOfTasks}
          setArrayOfActivities={setArrayOfTasks}
          microlocations={allMarkers}
          location={loc}
          setOpenMicrolocationSelectModal={setOpenMicrolocationSelectModal}
          handleLocationClick={() => handleLocationClick(location)}
          removeMicrolocationTasks={(microlocationKey) => {
            const locationKey = loc.value;
            const updatedTasksUnderMicrolocation = {
              ...tasksUnderMicrolocation,
            };
            const microlocationTasks = updatedTasksUnderMicrolocation[
              locationKey
            ].filter((mlTask) => {
              return !Object.keys(mlTask).includes(microlocationKey);
            });
            updatedTasksUnderMicrolocation[locationKey] = microlocationTasks;
            setTasksUnderMicrolocation({
              ...updatedTasksUnderMicrolocation,
            });
            saveTasks(updatedTasksUnderMicrolocation);
          }}
        />
      ) : null}
    </>
  );
};

export const SwitchContainer = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: 16px;
    font-weight: bold;
  }
  && {
    justify-content: space-between;
  }
`;
export const RevisedModal = styled(Modal)`
  .chakra-modal__content {
    min-width: '1170px';
    width: '1170px';
  }
  .chakra-modal__content-container {
    min-width: '1170px';
    width: '1170px';
  }
`;

export default JobModal;
