/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_appbar.jsx
 *
 *  Description: Appbar component.
 *
 *  Author: Nabin Kharal (nabin@brainants.com)
 *
 *  Date created: 4-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import {
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { defaultAvatar } from '../../../Components/Generics/snowm_appbar';
import { LocalizationContext } from '../../../contexts/localization_context';
import { getCurrentUser, logout } from '../../../controllers/snowm_firebase';

// functional component
/*
 **************************************************************************************
 * @brief  (AppBarView)  renders the appbar
 * @param{type :object } (props)
 *
 * @returns Appbar component (react element)
 **************************************************************************************
 */
const AppBarView = ({ ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [userName, setUserName] = useState('Snowm');
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /*
   **************************************************************************************
   * @brief  (GetCurrenUser)  sets the text inside the userProfile image if image not provided
   * @param{type :Firebase.user } (user)
   *
   * @returns undefined
   **************************************************************************************
   */

  getCurrentUser((user) => {
    if (user) {
      setUserName(user.displayName);
    } else {
      setUserName('Snowm');
    }
  });

  const open = Boolean(anchorEl);
  const { theme, routes, history } = props;
  const { strings } = useContext(LocalizationContext);

  return (
    <Flex
      h="16"
      zIndex="1"
      paddingRight="12"
      justifyContent="flex-end"
      boxShadow="0px 12px 24px #ECEEF5"
    >
      <Flex justifyContent="flex-end" alignItems="center" gridGap="16px">
        <Text fontSize="sm" color="highlight.500">
          {userName ? userName[0] : 'S'}
        </Text>
        <Menu>
          <MenuButton as={Button} backgroundColor="white" paddingRight="0">
            <Image
              src={theme}
              boxSize="40px"
              borderRadius="50%"
              fallbackSrc={defaultAvatar}
            />
          </MenuButton>
          <MenuList>
            {/* <MenuGroup title="Choose Company" fontSize="md">
              <CompaniesList
                handleCompanySelection={switchCompany}
                companiesInDetail={remainingCompanyDetails}
              />
            </MenuGroup>
            <MenuDivider /> */}
            <MenuGroup>
              {routes.map(({ route, name, menu }) => {
                if (menu) {
                  return (
                    <MenuItem
                      key={name}
                      onClick={() => {
                        history.push(route);
                        handleClose();
                      }}
                    >
                      {name}
                    </MenuItem>
                  );
                }
                return null;
              })}
              <MenuItem onClick={logout}>{strings.auth?.LOG_OUT}</MenuItem>
            </MenuGroup>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};

// proptype validation
AppBarView.defaultProps = {
  toggle: () => null,
};

AppBarView.propTypes = {
  toggle: PropTypes.func,
};

export default AppBarView;
