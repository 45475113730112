import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import Managers from './snowm_manager';

const ManagersRoutes = () => (
  <Switch>
    <Route path="/admin/administrator" exact component={Managers} />
    <Redirect to="/admin/administrator" />
  </Switch>
);

export default ManagersRoutes;
