import React, { useEffect, useContext, useState } from 'react';

import styled from 'styled-components';

import RouteItem from './RouteItem';
import Span from '../../Common/Span';
import Loader from '../../Generics/snowm_loader';
import { Row, Title } from '../../../styles/snowm_styled';
import { ServiceTypesContext } from '../../../../contexts/service_types';
import { getActiveRoutes } from '../../../../controllers/snowm_firebase';

const ActiveRoutes = () => {
  const [activeRoutes, setActiveRoutes] = useState();

  const data = useContext(ServiceTypesContext);

  const { markers } = data;
  useEffect(() => {
    let isCancel = false;
    getActiveRoutes(markers).then((res) => {
      if (!isCancel) setActiveRoutes(res);
    });

    return () => {
      isCancel = true;
    };
  }, [markers]);

  if (!activeRoutes) return <Loader />;

  if (activeRoutes?.length === 0) {
    return (
      <StyledContainer justify="center">
        <Span>No Active Routes</Span>
      </StyledContainer>
    );
  }

  return (
    <Container>
      <Title>Routes</Title>
      <Grid>
        {activeRoutes?.map((route) => {
          return <RouteItem route={route} key={route.key} />;
        })}
      </Grid>
    </Container>
  );
};

export default ActiveRoutes;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 12px;
`;

const Container = styled.div`
  padding: 12px;
`;

const StyledContainer = styled(Row)`
  height: 100%;
`;
