import React, { Fragment, useEffect, useState } from 'react';

import CloseIcon from 'mdi-react/CloseIcon';
import CheckAllIcon from 'mdi-react/CheckAllIcon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
} from '@material-ui/core';
import { Flex, CircularProgress } from '@chakra-ui/react';

import Span from './Span';
import color from '../../utils/color';
import { Col, Row } from '../../styles/snowm_styled';
import { InvisibleMenuItem, StyledTextField } from './styled';
import { getActivitiesOfACompany } from '../../controllers/snowm_firebase';

const ActivitiesSelection = ({
  serviceFeatures,
  handleSelectedActivities,
  marker,
  atJob,
}) => {
  const [selectedActivities, setSelectedActivities] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [activities, setActivities] = useState();

  const [selectedActivityIds, setSelectedActivityIds] = useState([]);
  const [arrayOfActivities, setArrayOfActivities] = useState([]);

  const [expanded, setExpanded] = useState();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(null);

  useEffect(() => {
    handleSelectedActivities(selectedActivities, selectedCategory);
    const ids = selectedActivities?.map((activity) => activity.id);
    setSelectedActivityIds(ids);
  }, [selectedActivities]);

  useEffect(() => {
    setSelectedCategory(marker?.activityType ?? 'none');
    setSelectedActivities(marker?.activities ?? null);
  }, [marker]);

  useEffect(() => {
    let isSubscribe = true;

    function _updateActivities(response = []) {
      const data = response.reduce((acc, curr) => {
        acc[curr.category] = acc[curr.category] ?? [];
        acc[curr.category] = [...acc[curr.category], curr];
        return acc;
      }, {});

      if (isSubscribe) {
        if (atJob) {
          setArrayOfActivities(response);
        }
        setActivities(data);
      }
    }

    if (serviceFeatures?.activities || serviceFeatures?.activitiesOnJob) {
      getActivitiesOfACompany(_updateActivities);
    } else {
      setActivities({});
    }

    return () => {
      isSubscribe = false;
    };
  }, [serviceFeatures]);

  const handleActivitiesChange = (event) => () => {
    setSelectedActivities([]);
    setSelectedCategory(event.target.value);
  };

  const handleCheckboxChange = (event) => () => {
    let activitiesArray = [];
    if (!event.target.checked) {
      activitiesArray = selectedActivities.filter(
        (eachActivity) => eachActivity.id !== event.target.id
      );
      setSelectedActivities([...activitiesArray]);
      return;
    }

    const loopingActivities = atJob
      ? arrayOfActivities
      : Object.values(activities[selectedCategory]);

    activitiesArray = loopingActivities.filter(
      (eachActivity) => eachActivity.id === event.target.id
    );

    setSelectedActivities((prevData) => [
      ...(prevData ?? []),
      ...activitiesArray,
    ]);
  };

  function handleExpand(e, category) {
    e.stopPropagation();
    setSelectedCategory(category);
    setSelectedCategories([...selectedCategories, category]);
    setExpanded({ ...expanded, [category]: !expanded?.[category] });
  }

  function getActivityName(activity) {
    if (!activity.mandatory) {
      return activity.name;
    }

    return (
      <Col>
        <Span>
          {`${activity.name}`}
          <Span color={color.red}>*</Span>
        </Span>
        <Span size="12px" color={color.grey}>
          Mandatory
        </Span>
      </Col>
    );
  }

  const activitiesList = (categorySelected) => {
    const category = categorySelected ?? selectedCategory;

    const categoryActivities = Object.values(activities[category] ?? {});

    return (
      <FormGroup>
        {categoryActivities.map((activity) => (
          <FormControlLabel
            key={activity.id}
            control={
              <Checkbox
                color="primary"
                name={activity.name}
                checked={selectedActivityIds?.includes(activity.id) ?? false}
                onChange={handleCheckboxChange}
                id={activity.id}
              />
            }
            label={getActivityName(activity)}
          />
        ))}
      </FormGroup>
    );
  };

  if (!activities) {
    return (
      <Flex justify="center">
        <CircularProgress isIndeterminate color="primary.500" />
      </Flex>
    );
  }

  if (activities?.length === 0) {
    return (
      <>
        <Row>
          <Span weight="bold" size="18px">
            Select activities for Marker
          </Span>
        </Row>
        <Row justify="center">
          <Span>No activities.</Span>
        </Row>
      </>
    );
  }

  if (atJob && !serviceFeatures?.activitiesOnJob) {
    return null;
  }

  function handleSelectAll(category) {
    setIsSelectAll({ ...isSelectAll, [category]: true });
    const activitiesOfACategory = arrayOfActivities?.filter(
      (activity) => activity.category === category
    );

    setSelectedActivities([
      ...(selectedActivities ?? []),
      ...activitiesOfACategory,
    ]);
  }

  function handleCancelSelectAll(category) {
    setIsSelectAll({ ...isSelectAll, [category]: false });
    const newActivities = selectedActivities?.filter(
      (activity) => activity.category !== category
    );
    setSelectedActivities(newActivities);
  }

  if (atJob) {
    return (
      <>
        <Row>
          <Span weight="bold" size="18px">
            Select activities for Marker
          </Span>
        </Row>
        <Divider />
        {Object.keys(activities ?? {}).map((category) => (
          <Fragment key={category}>
            <Row justify="space-between" style={{ marginTop: 8 }}>
              {category}
              <Row width="auto">
                {isSelectAll?.[category] ? (
                  <IconButton onClick={() => handleCancelSelectAll(category)}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => handleSelectAll(category)}>
                    <CheckAllIcon />
                  </IconButton>
                )}
                <IconButton onClick={(e) => handleExpand(e, category)}>
                  <ExpandMoreIcon />
                </IconButton>
              </Row>
            </Row>
            <Collapse e in={expanded?.[category]}>
              {activitiesList(category)}
            </Collapse>
            <Divider />
          </Fragment>
        ))}
      </>
    );
  }

  if (serviceFeatures?.activitiesOnJob) {
    return null;
  }

  if (!Object.keys(activities)?.length) {
    return null;
  }

  return (
    <>
      <StyledTextField
        select
        defaultValue="none"
        value={selectedCategory ?? 'none'}
        onChange={handleActivitiesChange}
        variant="outlined"
        width="100%"
      >
        <InvisibleMenuItem value="none">Activities Category</InvisibleMenuItem>
        {Object.keys(activities).map((category) => (
          <MenuItem key={category} value={category}>
            {category}
          </MenuItem>
        ))}
      </StyledTextField>

      {activities[selectedCategory] &&
        Object.values(activities[selectedCategory]).length > 0 && (
          <>{activitiesList()}</>
        )}
    </>
  );
};

export default ActivitiesSelection;
