import React, { createContext, useEffect, useState } from 'react';

import { getCompanyDetail } from '../controllers/snowm_firebase';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(null);
  const [user, setUser] = useState(null);

  const [companyKey, setCompanyKey] = useState(null);
  const [userRoleInCompany, setUserRoleInCompany] = useState(null);

  const [company, setCompany] = useState(null);

  useEffect(() => {
    if (companyKey) {
      getCompanyDetail().then((res) => {
        setCompany(res);
      });
    }
  }, [companyKey]);

  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        authenticated,
        setAuthenticated,
        setCompanyKey,
        companyKey,
        company,
        userRoleInCompany,
        setUserRoleInCompany,
        setUser,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
