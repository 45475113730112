import { Report, ScheduledFor } from '../../../enums/reportBy';

const { Location, LocationSchedule, MicroLocation, Janitor, JanitorSchedule } =
  Report;

const { Daily, Weekly, BiWeekly, Monthly, Quarterly } = ScheduledFor;

export const getTypeOfReport = (reportType) => {
  switch (reportType) {
    case MicroLocation:
      return 'Micro-Location';
    case JanitorSchedule:
      return 'Janitor Schedule';
    case LocationSchedule:
      return 'Location Schedule';
    case Location:
      return 'Location';
    case Janitor:
      return 'Janitor';
    default:
      return '-';
  }
};

export const getScheduledFor = (scheduledFor) => {
  switch (scheduledFor) {
    case Daily:
      return 'Daily';
    case Weekly:
      return 'Weekly';
    case BiWeekly:
      return 'Bi-weekly';
    case Monthly:
      return 'Monthly';
    case Quarterly:
      return 'Quarterly';
    default:
      return '-';
  }
};
