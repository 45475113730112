import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

import {
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { LoginToggleButton, NextButton } from './Email';

interface PasswordInterface {
  showPassword: boolean;
  login: (e: any) => void;
  goToPhoneLogin: () => void;
  setShowPassword: (toShow: boolean) => void;
  handlePasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
}

export const Password = (props: PasswordInterface) => {
  const {
    handlePasswordChange,
    login,
    goToPhoneLogin,
    showPassword,
    setShowPassword,
    loading,
  } = props;
  const { register, handleSubmit } = useForm();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handlePasswordChange(event);
  };

  return (
    <form onSubmit={handleSubmit(login)} style={{ width: '100%' }}>
      <Flex direction="column" gridGap="1.5rem">
        <FormControl>
          <InputGroup>
            <Input
              type={showPassword ? 'text' : 'password'}
              placeholder="Password *"
              name="password"
              isRequired
              register={register}
              onChange={handleInputChange}
              height="51px"
              marginBottom="0.5rem"
            />
            <InputRightElement
              onClick={() => setShowPassword(!showPassword)}
              cursor="pointer"
              color="primary.300"
              height="51px"
            >
              {showPassword ? <ViewOffIcon /> : <ViewIcon />}
            </InputRightElement>
          </InputGroup>
        </FormControl>

        <NextButton text="Next" loading={loading} />
        <div style={{ textAlign: 'center' }}>or</div>
        <LoginToggleButton
          text="Log In Using Phone Number"
          shouldDisableButton={loading}
          handleClick={goToPhoneLogin}
        />
      </Flex>
    </form>
  );
};
