/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: service_jobs.js
 *
 *  Description: Context API for Jobs
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 12-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import { createContext, useState } from 'react';

import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin

import {
  getRealTimeJobs,
  getJobInfoFromShift,
  getRealTimePeriodicJobs,
} from '../controllers/snowm_firebase';

dayjs.extend(utc);
dayjs.extend(timezone);

export const JobsContext = createContext();

export const JobsProvider = ({ children }) => {
  const initialFilters = {
    status: '',
    markerKey: '',
    serviceKey: '',
    propertyKey: '',
    providerKey: '',
  };
  const [jobs, setJobs] = useState(null);
  const [periodicJobs, setPeriodicJobs] = useState(null);
  const [filterTypes, setFilterTypes] = useState(initialFilters);

  function _updateJobs(jobsData) {
    setJobs(jobsData);
  }

  function fetchJobs() {
    getRealTimeJobs(_updateJobs);
  }

  const _updatePeriodicJobs = (res) => {
    setPeriodicJobs(res);
  };

  function fetchPeriodicJobs() {
    getRealTimePeriodicJobs(_updatePeriodicJobs);
  }

  const getSchedules = (startTime, endTime) => {
    const listOfSchedules = [];

    const momentedStartTime = dayjs(startTime);
    const momentedEndTime = dayjs(endTime);

    let currentTime = momentedStartTime;

    function isUpcomingShift(shift, currentWeekDay) {
      const startHour = shift.shift.startTime.split(':')[0];
      const startMinute = shift.shift.startTime.split(':')[1];
      const timezoneDate = dayjs.tz(currentTime, shift?.shift.timezone);
      const isValidWeekday = shift?.shift.weekdays.includes(timezoneDate.day());
      if (!isValidWeekday) return false;
      if (
        isValidWeekday &&
        currentWeekDay !== dayjs().tz(shift?.shift.timezone).day()
      ) {
        return true;
      }
      if (startHour > timezoneDate.hour()) return true;
      if (startHour < timezoneDate.hour()) return false;
      if (startHour === timezoneDate.hour()) {
        return startMinute > timezoneDate.minute();
      }

      return startHour > timezoneDate.hour();
    }

    do {
      const currentWeekDay = currentTime.day();
      const shiftsForCurrentWeekDay = Object.values(periodicJobs ?? {}).filter(
        (shift) => shift.shift.weekdays.includes(currentWeekDay)
      );

      // eslint-disable-next-line no-loop-func
      const pureShifts = shiftsForCurrentWeekDay.map((shift) => {
        return {
          ...shift.job,
          createdDate: currentTime
            .set('h', Number(shift.shift.startTime.split(':')[0]))
            .set('minute', Number(shift.shift.startTime.split(':')[1]))
            .valueOf(),
          servedRoutes: [],
          isUpcoming: isUpcomingShift(shift, currentWeekDay),
          servedMarkers: [],
          status: 'assigned',
          startedDate: null,
          allMarkers: [],
          parentKey: shift.key,
          key: `${currentTime.startOf('day').toISOString()}${shift.key}`,
        };
      });

      listOfSchedules.push(...pureShifts);

      currentTime = currentTime.add(1, 'day');
    } while (momentedEndTime.isAfter(currentTime));
    return listOfSchedules;
  };

  async function getJobDetail(shiftId, isPeriodic) {
    if (!jobs?.[shiftId]) {
      const jobDetail = await getJobInfoFromShift(shiftId, isPeriodic);
      return jobDetail;
    }
    return jobs[shiftId];
  }

  function setFilterData(data) {
    setFilterTypes(data);
  }

  return (
    <JobsContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        jobs,
        fetchJobs,
        fetchPeriodicJobs,
        getJobDetail,
        setFilterData,
        filterTypes,
        periodicJobs,
        getSchedules,
      }}
    >
      {children}
    </JobsContext.Provider>
  );
};
