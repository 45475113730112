/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Zendesk from 'react-zendesk';
import color from '../../utils/color';

const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY;

const setting = {
  color: {
    theme: color.blue,
  },
  contactForm: {
    attachments: true,
  },
};

const ZenDesk = () => <Zendesk zendeskKey={ZENDESK_KEY} {...setting} />;

export default ZenDesk;
