/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_jobs.jsx
 *
 *  Description: Show the list of jobs
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 12-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import { Button } from '@chakra-ui/button';
import { Box, Flex, Heading, Text } from '@chakra-ui/layout';
import {
  Icon,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import { CircularProgress, MenuItem } from '@material-ui/core';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import MenuDownIcon from 'mdi-react/MenuDownIcon';
import MenuLeftIcon from 'mdi-react/MenuLeftIcon';
import MenuRightIcon from 'mdi-react/MenuRightIcon';
import React, { useContext, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BsCalendar4 } from 'react-icons/bs';
import { FaPlusCircle } from 'react-icons/fa';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { components } from 'react-select';
import styled from 'styled-components';
import EmptyScheduleImage from '../../../assets/svgs/EmptyScheduleImage.svg';
import { LocalizationContext } from '../../../contexts/localization_context';
import { JobsContext } from '../../../contexts/service_jobs';
import { ServicePContext } from '../../../contexts/service_provider';
import { ServiceTypesContext } from '../../../contexts/service_types';
import {
  completeJob,
  deleteJob,
  deleteShiftJob,
  getOlderSchedules,
  getPropertiesFromFirestore,
  getPropertyById,
  getProviderByUid,
  getRealtimeJobByRangeFilter,
  getServicePointById,
  getServices,
  getShiftById,
  getShiftsOfACompany,
} from '../../../controllers/snowm_firebase';
import { getDayMonthYear, getMonthDayYear } from '../../../helpers/date';
import {
  getFilteredItems,
  getFilteredItems as getFilteredJobs,
} from '../../../helpers/misc';
import { makeFirstLetterUpperCase } from '../../../helpers/string';
import { DetailsDrawer } from '../../../reusableComponent/DetailsDrawer';
import { CustomSelect } from '../../../reusableComponent/shiftSchedule/custom_select';
import JobsTable from '../../../reusableComponent/shiftSchedule/JobsTable';
import JobDetail from '../../../reusableComponent/shiftSchedule/job_detail';
import JobModal from '../../../reusableComponent/shiftSchedule/snowm_add_job';
import ZeroStateComponent from '../../../reusableComponent/ZeroStateComponent';
import { Row } from '../../../styles/snowm_styled';
import AlertBox from '../../Common/alert_box';
import PrimaryButton from '../../Common/primary_button';
import Loader, { Progress } from '../../Generics/snowm_loader';
import IssueReports from '../../../reusableComponent/shiftSchedule/IssueReports';

const ScheduleConsumer = (jobsProps) => {
  const { providers: janitors } = useContext(ServicePContext);
  return (
    <JobsContext.Consumer>
      {({ jobs, periodicJobs, fetchJobs, fetchPeriodicJobs, getSchedules }) => (
        <Shifts
          assignedJobs={jobs}
          fetchJobs={fetchJobs}
          periodicJobs={periodicJobs}
          fetchPeriodicJobs={fetchPeriodicJobs}
          getSchedules={getSchedules}
          janitors={janitors}
          {...jobsProps}
        />
      )}
    </JobsContext.Consumer>
  );
};

const Shifts = ({
  fetchJobs,
  fetchPeriodicJobs,
  periodicJobs,
  assignedJobs,
  janitors,
}) => {
  const { strings } = useContext(LocalizationContext);
  const services = useContext(ServiceTypesContext);

  const subscribedServices = services?.subscribedServices;

  const { ASSIGNED_JOBS, SHIFT_JOBS } = strings.jobType ?? '';

  const [jobs, setJobs] = useState(null);
  const [edit, setEdit] = useState(false);
  const [jobTypes, setJobTypes] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [sortType, setSortType] = useState('desc');
  const [olderJobs, setOlderJobs] = useState(null);
  const [newerJobs, setNewerJobs] = useState(null);
  const [selectedJob, setSelectedJob] = useState([]);
  const [filterTypes, setFilterTypes] = useState({});
  const [filterType, setFilterType] = useState('ALL');
  const [searchResult, setSearchResult] = useState([]);
  const [sortByNames, setSortByNames] = useState(null);
  const [noOlderJobs, setNoOlderJobs] = useState(false);
  const [filteredJobs, setFilteredJobs] = useState(null);
  const [individualJob, setIndividualJob] = useState({});
  const [selectedJobKey, setSelectedJobKey] = useState();
  const [selectedName, setSelectedName] = useState(null);
  const [errorOccured, setErrorOccured] = useState(false);
  const [showJobModal, setShowJobModal] = useState(false);
  const [openJobDialog, setOpenJobDialog] = useState(false);
  const [customerServices, setCustomerServices] = useState();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedServiceKey, setSelectedServiceKey] = useState('all');
  const [isLoadingOlderJobs, setIsLoadingOlderJobs] = useState(false);
  const [selectedJobType, setSelectedJobType] = useState(ASSIGNED_JOBS);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [openActionDrawer, setOpenActionDrawer] = useState(true);
  const [selectedShifts, setSelectedShifts] = useState({});
  const [tabData, setTabData] = useState('Details');
  const [shiftTemplates, setShiftTemplates] = useState(null);
  const [locations, setLocations] = useState(null);
  const [selectedLocations, setSelectedLocations] = useState([
    {
      label: 'All Locations',
      value: 'allLocations',
    },
  ]);
  const [selectedJanitors, setSelectedJanitors] = useState([
    {
      label: 'All Janitors',
      value: 'allJanitors',
    },
  ]);
  const [selectedShiftTemplates, setSelectedShiftTemplates] = useState([
    {
      label: 'All Shifts',
      value: 'allShifts',
    },
  ]);

  const today = dayjs().startOf('day').valueOf();
  const tomorrow = dayjs().endOf('day').valueOf();

  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(tomorrow);
  const [shiftDate, setShiftDate] = useState();
  const [rangeFilterBy, setRangeFilterBy] = useState({
    label: 'Day',
    value: 'day',
  });

  function closeJobDialog() {
    setOpenJobDialog(false);
    setEdit(false);
    setIndividualJob({});
    setOpenActionDrawer(true);
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  function sortByCreatedDate(firstJob, secondJob) {
    const firstJobDate = firstJob?.job?.createdDate ?? firstJob.createdDate;
    const secondJobDate = secondJob.job?.createdDate ?? secondJob.createdDate;

    let integer = 1;
    if (sortType === 'desc') {
      integer = -1;
    }

    if (firstJobDate > secondJobDate) {
      return integer;
    }
    if (firstJobDate < secondJobDate) {
      return -integer;
    }
    return 0;
  }

  function sortJobs(jobsArray) {
    return jobsArray.sort(sortByCreatedDate);
  }

  useEffect(() => {
    let isSubscribe = true;
    getServices().then((res) => {
      const response = res?.map((d) => d.data() ?? []);
      if (isSubscribe) setCustomerServices(response);
    });

    return () => {
      isSubscribe = false;
    };
  }, []);

  useEffect(() => {
    let isSubscribe = true;
    function _updateProperties(response) {
      if (isSubscribe) {
        setLocations(response);
      }
    }

    getPropertiesFromFirestore(_updateProperties);

    return () => {
      isSubscribe = false;
    };
  }, []);

  useEffect(() => {
    let isCancel = false;
    function _updateShifts(res) {
      if (!isCancel) {
        setShiftTemplates(res);
      }
    }

    getShiftsOfACompany(_updateShifts);

    return () => {
      isCancel = true;
    };
  }, []);

  useEffect(() => {
    if (subscribedServices?.shiftManagement) {
      setJobTypes([SHIFT_JOBS, ASSIGNED_JOBS]);
    } else setJobTypes([ASSIGNED_JOBS]);
  }, [subscribedServices]);

  // useEffect(() => {
  //   if (selectedJobType === SHIFT_JOBS) {
  //     setJobs(periodicJobs);
  //   }
  // }, [periodicJobs]);

  useEffect(() => {
    if (endDate) {
      getRealtimeJobByRangeFilter({ startDate, endDate }, (res) => {
        setJobs([...(res ?? [])]);
      });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (
      (selectedJanitors.length === 1 &&
        selectedJanitors[0].key === 'allJanitors') ||
      !jobs
    )
      return;
    filterSchedulesByJanitors();
  }, [selectedJanitors, jobs]);

  useEffect(() => {
    if (
      (selectedLocations.length === 1 &&
        selectedLocations[0].key === 'allLocations') ||
      !jobs
    )
      return;
    filterSchedulesByLocations();
  }, [selectedLocations, jobs]);
  const inputRef = useRef(null);

  useEffect(() => {
    if (
      (selectedShiftTemplates.length === 1 &&
        selectedLocations[0]?.key === 'allShifts') ||
      !jobs
    )
      return;
    filterSchedulesByShifts();
  }, [selectedShiftTemplates, jobs]);

  function filterSchedulesByShifts() {
    let filtered;
    if (
      selectedShiftTemplates.length === 1 &&
      selectedShiftTemplates[0].value === 'allShifts'
    ) {
      filtered = jobs;
    } else {
      filtered = jobs.filter((j) =>
        selectedShiftTemplates.some((selected) => selected.value === j.shiftKey)
      );
    }

    const schedulesByDate = sortAndCategorizeSchedules(filtered);
    setFilteredJobs(schedulesByDate);
  }

  function filterSchedulesByLocations() {
    let filtered;
    if (
      selectedLocations.length === 1 &&
      selectedLocations[0].value === 'allLocations'
    ) {
      filtered = jobs;
    } else {
      filtered = jobs.filter((j) =>
        selectedLocations.some((selected) =>
          j.propertyKeys.includes(selected.value)
        )
      );
    }

    const schedulesByDate = sortAndCategorizeSchedules(filtered);
    setFilteredJobs(schedulesByDate);
  }

  function filterSchedulesByJanitors() {
    let filtered;
    if (
      selectedJanitors.length === 1 &&
      selectedJanitors[0].value === 'allJanitors'
    ) {
      filtered = jobs;
    } else {
      filtered = jobs.filter((j) => {
        const some = selectedJanitors.some((selected) => {
          return j.providerUids.includes(selected.value);
        });
        return some;
      });
    }

    filtered.sort((a, b) => a.createdDate - b.createdDate);

    const schedulesByDate = categorziedSchedulesByDate(filtered);
    setFilteredJobs(schedulesByDate);
  }
  function sortAndCategorizeSchedules(schedules) {
    schedules.sort((a, b) => a.createdDate - b.createdDate);
    return categorziedSchedulesByDate(schedules);
  }

  useEffect(() => {
    if (rangeFilterBy.value === 'day') {
      const end = dayjs(startDate).endOf('day').valueOf();
      setEndDate(end);
    }
  }, [startDate]);

  useEffect(() => {
    setSearchResult([]);
    setFilterType('ALL');
    setOlderJobs(null);
    setFilteredJobs(null);
    fetchJobs();
    fetchPeriodicJobs();
  }, [selectedJobType]);

  function getUniqueIds(fieldName) {
    const uniquefieldKeys = Object.values(jobs).reduce((acc, currentJob) => {
      const job = currentJob?.job ?? currentJob;
      let fieldKeys = [];
      const keys = job[fieldName] ?? [];
      if (typeof keys === 'string') {
        fieldKeys = [...new Set([...acc, keys])];
      } else {
        fieldKeys = [...new Set([...acc, ...keys])];
      }
      return fieldKeys;
    }, []);

    return uniquefieldKeys;
  }

  function getKeyValuePair(data, nameString = 'name', keyString = 'key') {
    const keyValueOfNameAndKey = data?.reduce((acc, datum) => {
      const name = datum[nameString];
      const key = datum[keyString];
      if (!name || !key) {
        return acc;
      }
      return { ...acc, [name]: key };
    }, {});
    return keyValueOfNameAndKey;
  }

  function getDataFromFirestore(key, collection) {
    switch (collection) {
      case 'crews':
        return getProviderByUid(key);
      case 'properties':
        return getPropertyById(key);
      case 'shifts':
        return getShiftById(key);
      case 'markers':
        return getServicePointById(key);
      default:
        throw new Error();
    }
  }

  async function filterByJobField(
    fieldName,
    collectionName,
    nameInDoc,
    docKey
  ) {
    const uniquePropertyKeys = getUniqueIds(fieldName);
    const propertiesPromises = uniquePropertyKeys?.map((key) =>
      getDataFromFirestore(key, collectionName)
    );

    const properties = await Promise.all(propertiesPromises);

    const propertiesKeyValue = getKeyValuePair(properties, nameInDoc, docKey);
    return propertiesKeyValue;
  }

  function getFilteredJobsByType() {
    switch (filterType) {
      case 'By Crew':
        return filterByJobField('providerUids', 'crews', 'name', 'uid');

      case 'By Marker':
        return filterByJobField('allMarkers', 'markers', 'name', 'key');

      case 'By Property':
        return filterByJobField('propertyKey', 'properties', 'name', 'id');
      case 'By Shift':
        return filterByJobField('shiftKey', 'shifts', 'name', 'id');
      default:
        throw new Error();
    }
  }

  useEffect(() => {
    if (filterType === 'ALL' && selectedJobType === ASSIGNED_JOBS) {
      fetchJobs();
    } else if (filterType !== 'ALL') {
      setSortByNames(null);
      getFilteredJobsByType().then((res) => {
        setSortByNames(res);
      });
    }
  }, [filterType]);

  const categorziedSchedulesByDate = (schedules) =>
    schedules.reduce(addScheduleToDate, {});

  const addScheduleToDate = (acc, job) => {
    const jobCreationDay = getMonthDayYear(job?.createdDate);
    if (acc[jobCreationDay]) {
      acc[jobCreationDay].push(job);
    } else {
      acc[jobCreationDay] = [job];
    }
    return acc;
  };

  useEffect(() => {
    const jobsArray = Object.values(filteredJobs ?? {});
    const jobsFromDatabase = Object.values(jobs ?? {});
    let arrayOfJobs = jobsArray;

    if (!isSearch) {
      arrayOfJobs = jobsFromDatabase;
    }

    const sortedJobs = sortJobs(arrayOfJobs);
    let allJobs = [];
    if (searchResult.length > 0) {
      allJobs = getFilteredItems(searchResult, selectedServiceKey);
    } else if (jobs) {
      allJobs = getFilteredJobs(sortedJobs, selectedServiceKey);
    }
  }, [assignedJobs, selectedServiceKey, periodicJobs]);

  const [endDateOfLastSchedule, setEndDateOfLastSchedule] = useState(
    dayjs().add(7, 'days').endOf('day').valueOf()
  );

  useEffect(() => {
    const orderedByCreatedDate = [...(olderJobs ?? []), ...(jobs ?? [])];
    orderedByCreatedDate.sort((a, b) => a.createdDate - b.createdDate);

    const schedulesByDate = categorziedSchedulesByDate(orderedByCreatedDate);
    setFilteredJobs(schedulesByDate);
  }, [olderJobs, jobs]);

  const updateSchedules = (schedules) => {
    const existingDates = Object.keys(filteredJobs);

    const duplicatedFilteredSchedules = { ...filteredJobs };

    const updatedSchedules = existingDates?.reduce(
      (schedulesThatAreUpdated, date) => {
        const oldSchedulesInThisDate =
          schedulesThatAreUpdated[date] ?? duplicatedFilteredSchedules[date];

        const scheduleKeys = oldSchedulesInThisDate?.map(
          (schedule) => schedule.key
        );

        // For update
        // eslint-disable-next-line no-unused-expressions
        schedules?.forEach((schedule) => {
          const indexOfSchedule = scheduleKeys?.indexOf(schedule.key);

          const scheduleCreationDate = getMonthDayYear(schedule.createdDate);

          if (indexOfSchedule === -1 && scheduleCreationDate === date) {
            oldSchedulesInThisDate.push(schedule);
          } else if (indexOfSchedule >= 0) {
            oldSchedulesInThisDate[indexOfSchedule] = schedule;
          }
        });

        schedulesThatAreUpdated[date] = oldSchedulesInThisDate;
        return schedulesThatAreUpdated;
      },
      {}
    );

    setFilteredJobs(updatedSchedules);
  };

  useEffect(() => {
    if (filteredJobs) {
      const arrayOfJobs = Object.values(filteredJobs);
      const sortedJobs = sortJobs(arrayOfJobs);
      const allJobs = getFilteredJobs(sortedJobs, selectedServiceKey);
      setFilteredJobs(allJobs);
    }
  }, [sortType]);

  const handleEditIconClick = (job) => {
    setOpenActionDrawer(false);
    setIndividualJob(job);
    setOpenJobDialog(true);
    setEdit(true);
  };

  const handleDeleteIconPress = (job) => {
    setIndividualJob(job);
    setOpenDeleteDialog(true);
  };

  const handleDetailsIconClick = (job, date) => {
    setShiftDate(date);
    setTabData('Details');
    setSelectedJob(job);
    setShowJobModal(true);
    setOpenActionDrawer(false);
  };

  const handleAddButton = () => {
    setEdit(false);
    setOpenJobDialog(true);
    setIndividualJob({});
    setOpenActionDrawer(false);
  };

  async function handleMultipleDelete(shift) {
    setSelectedShifts({});
    if (selectedJobType === SHIFT_JOBS) {
      deleteShiftJob(shift.key);
    } else {
      const jobCreatedDay = getDayMonthYear(shift.createdDate);

      const currentDay = getDayMonthYear(dayjs().valueOf());
      await deleteJob(shift.key);
      if (jobCreatedDay !== currentDay) {
        const updatedJobs = filteredJobs?.filter(
          (job) => job.key !== shift.key
        );

        setFilteredJobs(updatedJobs);
      }
    }
  }

  const handlePositiveAction = async () => {
    setSelectedShifts({});
    if (selectedJobType === SHIFT_JOBS) {
      deleteShiftJob(individualJob.key);
    } else {
      const jobCreatedDay = getDayMonthYear(individualJob.createdDate);

      const currentDay = getDayMonthYear(dayjs().valueOf());
      await deleteJob(individualJob.key);
    }
    setOpenDeleteDialog(false);
  };

  const handleNegativeAction = () => {
    setOpenActionDrawer(true);
    setOpenDeleteDialog(false);
    setOpenConfirmationDialog(false);
    setErrorOccured();
  };

  const handleShowAllButton = () => {
    setNoOlderJobs(false);
    setFilterTypes({});
    setSelectedServiceKey('all');
    const jobsArrray = Object.values(jobs);
    setFilteredJobs(jobsArrray);
    setIsSearch(false);
  };

  async function openCompleteJobConfirmationDialog(jobKey) {
    setOpenConfirmationDialog(true);
    setSelectedJobKey(jobKey);
  }

  async function handleCompleteJob() {
    if (selectedJobKey) {
      try {
        await completeJob(selectedJobKey);
        setOpenConfirmationDialog(false);
      } catch (error) {
        setErrorOccured(true);
      }
    }
  }

  function handleFilteredJobsByJobFields(name, fieldToBeFiltered) {
    const key = sortByNames[name];
    const sortedJobs = Object.values(jobs).reduce((acc, job) => {
      const currentJob = job?.job ?? job;
      const keys = currentJob[fieldToBeFiltered];
      if (typeof keys !== 'string') {
        const setOfUids = new Set(keys);
        if (!setOfUids.has(key)) {
          return acc;
        }
        return {
          ...acc,
          [job.key]: job,
        };
      }
      if (currentJob[fieldToBeFiltered] !== key) {
        return acc;
      }
      return { ...acc, [job.key]: job };
    }, {});
    setFilteredJobs(sortedJobs);
  }

  function handleSortingNameChange(event) {
    const name = event.target.value;
    setSelectedName(name);
    switch (filterType) {
      case 'By Crew':
        handleFilteredJobsByJobFields(name, 'providerUids');
        break;

      case 'By Marker':
        handleFilteredJobsByJobFields(name, 'allMarkers');
        break;
      case 'By Property':
        handleFilteredJobsByJobFields(name, 'propertyKey');
        break;
      case 'By Shift':
        handleFilteredJobsByJobFields(name, 'shiftKey');
        return;
      default:
        throw new Error();
    }
  }

  function renderFilterByName() {
    if (filterType === 'ALL') {
      return null;
    }

    if (!sortByNames) {
      return <CircularProgress />;
    }

    return (
      <Text
        select
        onChange={handleSortingNameChange}
        value={selectedName}
        label="Filter by name"
      >
        {Object.keys(sortByNames ?? {})?.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Text>
    );
  }

  if (!subscribedServices) {
    return <Loader />;
  }

  function handleFilterClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleSortingChange(sortingType) {
    setSortType(sortingType);
  }

  const handleShowMoreButton = async (isPrevious = false) => {
    // this is handle show button
    setIsLoadingOlderJobs(true);
    setOlderJobs(null);
    setNewerJobs(null);

    const datesList = Object.keys(filteredJobs);

    if (isPrevious) {
      const firstDate = datesList?.[0];

      const oldSchedules = await getOlderSchedules(
        dayjs(firstDate).subtract(1, 'day').endOf('day').valueOf()
      );

      oldSchedules.sort(
        (firstSchedule, secondSchedule) =>
          firstSchedule.createdDate - secondSchedule.createdDate
      );

      setOlderJobs([...(olderJobs ?? []), ...oldSchedules]);
    } else {
      const lastDate = datesList[datesList.length - 1];

      const newEndDate = dayjs(lastDate).add(7, 'days').endOf('day').valueOf();
      setEndDateOfLastSchedule(newEndDate);

      // const newJobsFromDb = await getSchedules(
      //   dayjs(lastDate).endOf('day').valueOf() + 1,
      //   newEndDate
      // );

      // if (!newJobsFromDb?.length) {
      //   setNoOlderJobs(true);
      // }
    }
    setIsLoadingOlderJobs(false);
  };

  function handleTypeSelect(event) {
    // TODO: capitalize the first letter or label
    setRangeFilterBy({
      value: event.value,
      label: makeFirstLetterUpperCase(event.value),
    });
  }

  function handleTabChange(i) {
    if (i === 0) {
      setTabData('Details');
    } else if (i === 1) {
      setTabData('Microlocations');
    } else {
      setTabData('IssueReport');
    }
  }
  const scheduleRangeOptions = [
    {
      label: 'Day',
      value: 'day',
    },
    { label: 'Week', value: 'week' },
  ];

  const defaultRange = {
    label: 'Day',
    value: 'day',
  };
  // TODO: get all the filter options from the context
  const locationOptions = [
    {
      label: 'All Locations',
      value: 'allLocations',
    },
    ...(locations ?? []).map((loc) => {
      return {
        label: loc.name,
        value: loc.id,
      };
    }),
  ];
  const janitorOptions = [
    {
      label: 'All Janitors',
      value: 'allJanitors',
    },
    ...Object.values(janitors ?? {}).map((jan) => {
      return {
        label: jan.name,
        value: jan.uid,
      };
    }),
  ];

  const shiftOptions = [
    {
      label: 'All Shifts',
      value: 'allShifts',
    },
    ...(shiftTemplates ?? []).map((s) => {
      return {
        label: s.name,
        value: s.id,
      };
    }),
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: '12px',
      fontWeight: 'normal',
    }),
    menu: () => ({
      bottom: 90,
      width: 270,
      borderRadius: 4,
      backgroundColor: 'white',
      boxShadow: '0px 1px 4px gray',
    }),
    control: () => ({
      width: 270,
      display: 'flex',
      borderRadius: 4,
      backgroundColor: 'white',
      border: 'solid 1px #E2E8F0',
    }),
    dropdownButton: (provided) => ({
      ...provided,
      width: 270,
      borderRadius: 4,
    }),
  };

  function filterDefaultOption(selectedValue) {
    if (selectedValue.length > 1) {
      if (
        selectedValue[selectedValue.length - 1].value === 'allLocations' ||
        selectedValue[selectedValue.length - 1].value === 'allShifts' ||
        selectedValue[selectedValue.length - 1].value === 'allJanitors'
      ) {
        return selectedValue.filter(
          (v) =>
            v.value === 'allJanitors' ||
            v.value === 'allShifts' ||
            v.value === 'allLocations'
        );
      }
      return selectedValue.filter(
        (v) =>
          !(
            v.value === 'allJanitors' ||
            v.value === 'allShifts' ||
            v.value === 'allLocations'
          )
      );
    }
    if (
      selectedLocations.length === 1 &&
      (selectedLocations[0].value === 'allLocations' ||
        selectedLocations[0].value === 'allShifts' ||
        selectedLocations[0].value === 'allJanitors')
    ) {
      filterSchedulesByJanitors();
      filterSchedulesByLocations();
      filterSchedulesByShifts();
    }

    return selectedValue;
  }
  if (!jobs || !jobTypes || !locations || !shiftTemplates) {
    return <Loader />;
  }

  const handleClick = () => {
    inputRef.current.setFocus();
  };
  const DropdownIndicator = (prop) => {
    return (
      <components.DropdownIndicator {...prop}>
        <MenuDownIcon width="12px" height="7px" />
      </components.DropdownIndicator>
    );
  };
  const handleArrowPress = (type) => {
    setEndDate(undefined);
    if (type === 'add') {
      const dateChange = dayjs(startDate).add(1, 'day').valueOf();
      setStartDate(dateChange);
      return;
    }
    if (startDate > dayjs().startOf('day').valueOf()) {
      const dateChange = dayjs(startDate).add(-1, 'day').valueOf();
      setStartDate(dateChange);
    }
  };
  const detailTabs = {
    Details: (
      <JobDetail
        tabData={tabData}
        date={shiftDate}
        shiftId={selectedJob?.key}
      />
    ),
    Issue_Reported: (
      <IssueReports
        tabData={tabData}
        date={shiftDate}
        job={selectedJob}
        jobKey={selectedJob?.key}
      />
    ),
  };

  const closeDetailsDrawer = () => {
    setShowJobModal(false);
    setOpenActionDrawer(true);
  };
  console.log('filteredJobs',filteredJobs);
  return (
    <Box bg="background.500" overflow="auto" p="12">
      <Flex direction="column" gridGap={4}>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading as="h4">Schedule</Heading>

          <Button
            onClick={handleAddButton}
            colorScheme="blue"
            leftIcon={<FaPlusCircle />}
            paddingLeft="xsm"
          >
            <Text color="white" fontWeight="600">
              Add Shift
            </Text>
          </Button>
        </Flex>
        {/* <SearchBar
          title="Shift"
          LeftIcon={<SearchIcon />}
          setSelectedEntities={setSelectedShifts}
          selectedEntities={selectedShifts}
        />   */}
      </Flex>

      {isSearch ? (
        <ShowAllButton>
          <PrimaryButton onClick={() => handleShowAllButton()}>
            Show all
            {` ${selectedJobType}`}
          </PrimaryButton>
        </ShowAllButton>
      ) : (
        <div />
      )}

      <Flex
        padding="20px"
        boxShadow="0px 3px 6px #F2F3F6"
        background="white"
        className="boxes1"
        wrap="wrap"
        marginTop="15px"
        gap="20px"
        alignItems="center"
      >
        {/* <Box className="createOWn" border="1px solid #8a91a6">
          <Button background="transparent" onClick={handleClick}>
            {selectedLocations.length ? (
              selectedLocations.map((i) => {
                return <Text key={i?.value}> {i?.label}</Text>;
              })
            ) : (
              <Text>check</Text>
            )}
          </Button>
        </Box> */}
        {/* <ReactMultiSelectCheckboxes
            style={{
              // border: ' 1px solid #8A91A6',
              // padding: '8px',
              marginLeft: '15px',
            }}
            options={locationOptions}
            // components={<AiFillEyeInvisible />}
            menuPlacement="auto"
            styles={customStyles}
            onChange={(selectedValue) => {
              const filtered = filterDefaultOption(selectedValue);
              setSelectedLocations(filtered);
            }}
            value={selectedLocations}
          /> */}
        <CustomSelect
          required
          style={{ width: 145, height: 40 }}
          options={scheduleRangeOptions}
          border="1px solid #8a91a6"
          onChange={handleTypeSelect}
          value={rangeFilterBy}
          defaultValue={defaultRange}
          placeholder="Select Schedule range"
        />
        {/* <div
          style={{
            height: 40,
            maxWidth: 227,
            border: ' 1px solid #8A91A6',
            borderRadius: '4px',
          }}
        > */}
        <InputGroup
          style={{
            height: 40,
            maxWidth: rangeFilterBy?.value === 'week' ? '290px' : '227px',

            border: '1px solid #8a91a6',
            borderRadius: '4px',
            // background: '#fbf9fb 0% 0% no-repeat padding-box',
            marginLeft: '7px',
          }}
        >
          {/* <InputLeftAddon>
            <MenuLeftIcon />
          </InputLeftAddon> */}
          <InputLeftElement
            // pointerEvents="none"
            marginLeft="7px"
          >
            <MenuLeftIcon
              color="#8a91a6"
              style={{
                borderRight: ' 1px solid #8a91a6',
                height: '100%',
                marginRight: '5px',
              }}
              onClick={() => handleArrowPress()}
            />
            <Icon onClick={() => handleClick()} as={BsCalendar4} />
          </InputLeftElement>

          {/* <InputLeftElement
            paddingLeft="35px"
            // onClick={() => handleArrowPress()}
            // pointerEvents="none"
          >
          </InputLeftElement> */}

          <RenderCalendar
            ref={inputRef}
            endDate={endDate}
            startDate={startDate}
            setEndDate={setEndDate}
            selectedDay={rangeFilterBy}
            setStartDate={setStartDate}
            minDate={new Date()}
          />
          <InputRightElement onClick={() => handleArrowPress('add')}>
            <MenuRightIcon
              color="#8a91a6"
              style={{
                borderLeft: ' 1px solid #8a91a6',
                height: '100%',
                padding: '2px',
                marginRight: '-5px',
              }}
            />
          </InputRightElement>
          {/* <InputRightAddon>
          <MenuRightIcon />
          </InputRightAddon> */}
        </InputGroup>
        <BoxForSelector>
          {/* <Flex> */}
          <ReactMultiSelectCheckboxes
            menuPortalTarget={document.body}
            style={{
              // border: ' 1px solid #8A91A6',
              // padding: '8px',
              marginLeft: '15px',
            }}
            options={locationOptions}
            menuPlacement="auto"
            styles={customStyles}
            onChange={(selectedValue) => {
              const filtered = filterDefaultOption(selectedValue);
              setSelectedLocations(filtered);
            }}
            value={selectedLocations}
            // components={{ DropdownIndicator }}
            iconAfter={<MenuDownIcon />}
          />
          {/* <ArrowDownIcon /> */}
          {/* </Flex> */}
        </BoxForSelector>
        <BoxForSelector>
          <ReactMultiSelectCheckboxes
            // ref={inputRef}
            className="123"
            options={janitorOptions}
            style={{
              // border: ' 1px solid #8A91A6',
              // padding: '8px',
              marginLeft: '15px',
            }}
            menuPlacement="auto"
            styles={customStyles}
            onChange={(selectedValue) => {
              const filtered = filterDefaultOption(selectedValue);
              setSelectedJanitors(filtered);
            }}
            value={selectedJanitors}
          />
        </BoxForSelector>
        <BoxForSelector>
          <ReactMultiSelectCheckboxes
            style={{
              // border: ' 1px solid #8A91A6',
              // padding: '8px',
              marginLeft: '15px',
            }}
            options={shiftOptions}
            menuPlacement="auto"
            styles={customStyles}
            onChange={(selectedValue) => {
              const filtered = filterDefaultOption(selectedValue);
              setSelectedShiftTemplates(filtered);
            }}
            value={selectedShiftTemplates}
          />
        </BoxForSelector>
      </Flex>
      {isEmpty(jobs) || isEmpty(filteredJobs) ? (
        <>
          {filteredJobs === null ? (
            <Row justify="center">
              <Progress />
            </Row>
          ) : (
            <ZeroStateComponent
              header="Schedule"
              title="Manage your Schedule here"
              subTitle="Click + add shift button on the right top to create your first Shift"
              image={EmptyScheduleImage}
            />
          )}
        </>
      ) : (
        <JobsTable
          jobs={filteredJobs}
          selectedShifts={selectedShifts}
          openActionDrawer={openActionDrawer}
          setSelectedShifts={setSelectedShifts}
          setOpenActionDrawer={setOpenActionDrawer}
          isPeriodic={selectedJobType === SHIFT_JOBS}
          handleMultipleDelete={handleMultipleDelete}
          onEditClick={(job) => handleEditIconClick(job)}
          onDeleteClick={(job) => handleDeleteIconPress(job)}
          onDetailsClick={(job, date) => handleDetailsIconClick(job, date)}
          showUpComingSchedules={() => handleShowMoreButton(false)}
          showMorePreviousSchedule={() => handleShowMoreButton(true)}
          onCheckClick={(job) => openCompleteJobConfirmationDialog(job?.key)}
        />
      )}

      <JobModal
        edit={edit}
        open={openJobDialog}
        onClose={closeJobDialog}
        individualJob={edit ? individualJob : null}
        selectedJobType={!edit ? SHIFT_JOBS : ASSIGNED_JOBS}
      />
      <AlertBox
        open={openDeleteDialog}
        handlePositiveAction={handlePositiveAction}
        handleNegativeAction={handleNegativeAction}
        subtitle="Are you sure you want to delete the job?"
      />
      <AlertBox
        open={openConfirmationDialog}
        handlePositiveAction={handleCompleteJob}
        handleNegativeAction={handleNegativeAction}
        subtitle="Are you sure you want to complete the job?"
        positiveText="Complete Job"
        error={errorOccured ? 'Cannot complete the job' : ''}
      />

      <DetailsDrawer
        open={showJobModal}
        title="Shift Details"
        subtitle=""
        hideEditDeleteButton
        // details={propertyDetails}
        onClose={closeDetailsDrawer}
        detailTabs={detailTabs}
      />
    </Box>
  );
};

const ShowAllButton = styled.div`
  && {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 10px 0;
  }
`;
const BoxForSelector = styled(Box)`
  && {
    border: 1px solid #8a91a6;

    border-radius: 4px;
    background: #fbf9fb 0% 0% no-repeat padding-box;
  }
`;

// eslint-disable-next-line react/display-name
const RenderCalendar = React.forwardRef(
  (
    { endDate, startDate, setEndDate, selectedDay, setStartDate, minDate },
    ref
  ) => {
    const handleDateChange = (date) => {
      setEndDate(undefined);
      if (selectedDay?.value === 'week') {
        setStartDate(dayjs(date[0]).valueOf());
        if (date[1]) setEndDate(dayjs(date[1]).endOf('day').valueOf());
      } else {
        setStartDate(dayjs(date).valueOf());
      }
    };

    if (selectedDay?.value !== 'week') {
      return (
        <Box
          style={{
            textAlign: 'center',
            width: '227px',
            overflow: 'hidden',
            marginLeft: '30px',
            marginRight: '30px',
            paddingLeft: '25px',

            marginTop: '4px',
          }}
        >
          <DatePicker
            ref={ref}
            textAlign="center"
            style={{ textAlign: 'center' }}
            selected={startDate}
            minDate={minDate}
            onChange={handleDateChange}
          />
        </Box>
      );
    }

    return (
      <Box
        style={{
          textAlign: 'center',
          overflow: 'hidden',
          marginLeft: '30px',
          marginTop: '4px',
          paddingLeft: '25px',
        }}
      >
        <DatePicker
          ref={ref}
          textAlign="center"
          style={{ textAlign: 'center' }}
          selectsRange
          endDate={endDate}
          minDate={minDate}
          startDate={startDate}
          onChange={handleDateChange}
        />
      </Box>
    );
  }
);

export default ScheduleConsumer;
