export const selectTypeOfRoutes = (type) => {
  switch (type) {
    case 'started':
      return 'STARTED';
    case 'exited':
      return 'ENDED';
    case 'restarted':
      return 'RESTARTED';
    case 'aborted':
      return 'ABORTED';
    default:
      return 'NONE';
  }
};

export function selectTypesOfServices(type) {
  const lowercaseType = type.toLowerCase();
  switch (lowercaseType) {
    case 'indoor':
      return 'Indoor';
    case 'outdoor':
      return 'Outdoor';
    case 'routebased':
      return 'Route Based';
    case 'markerbased':
      return 'Marker Based';
    case 'attendance':
      return 'Attendance';
    case 'all':
      return 'All';
    default:
      return '';
  }
}

export const routesIncludingServiceTypes = ['indoor', 'outdoor'];
