import { createContext, useState, useEffect, useContext } from 'react';

import { getSubscribedServiceTypes, getFilterTypes } from '../helpers/misc';

import { AuthContext } from './auth_context';
import {
  getLocationsFromFirestore,
  getMarkerAssignedToCustomer,
  getPropertyAssignedMarkers,
  getServiceInfo,
  getServices,
  getCompanyDetail,
} from '../controllers/snowm_firebase';

export const ServiceTypesContext = createContext();

export const ServiceTypeProvider = ({ children }) => {
  const [filterTypes, setFilterTypes] = useState([]);
  const [subscribedTypes, setSubscribedTypes] = useState([]);
  const [subscribedServices, setSubscribedServices] = useState();
  const [companyServices, setCompanyServices] = useState(null);

  const [properties, setProperties] = useState();
  const [markers, setMarkers] = useState();
  const [propertyTypeMarkers, setPropertyTypeMarkers] = useState();
  const [customerMarkers, setCustomerMarkers] = useState();

  const authValues = useContext(AuthContext);

  function _updateProperties(res) {
    setProperties(res);
  }

  function updateMarkers(res) {
    setCustomerMarkers(res);
  }

  useEffect(() => {
    const propertiesKeys = properties?.map((property) => property?.id) ?? [];

    if (authValues?.user && properties) {
      getMarkerAssignedToCustomer(authValues?.user, updateMarkers);
      getPropertyAssignedMarkers(propertiesKeys).then((res) => {
        setPropertyTypeMarkers(res);
      });
    }
  }, [properties, authValues]);

  useEffect(() => {
    if (propertyTypeMarkers && customerMarkers) {
      setMarkers([...(propertyTypeMarkers ?? []), ...(customerMarkers ?? [])]);
    }
  }, [propertyTypeMarkers, customerMarkers]);

  async function checkIfRoutesTypeService() {
    const servicesKeys = markers?.map((marker) => marker.serviceKey) ?? [];

    const uniqueServiceKeys = [...new Set(servicesKeys)];

    const servicesPromise = uniqueServiceKeys?.map((serviceKey) => {
      return getServiceInfo(serviceKey);
    });

    const services = await Promise.all(servicesPromise);

    const outdoorBasedService = services?.find(
      (service) => service.type === 'outdoor'
    );

    return !!outdoorBasedService;
  }

  useEffect(() => {
    let features = {
      properties: properties?.length > 0,
      markers: markers?.length > 0,
    };

    checkIfRoutesTypeService().then((res) => {
      features = { ...features, isRouteAvailable: res };
      if (properties && markers) {
        setSubscribedServices(features);
      }
    });
  }, [properties, markers]);

  const getDetails = async () => {
    getLocationsFromFirestore(authValues?.user, _updateProperties);
  };

  function checkServiceType(services, type) {
    return services?.map((service) => service.type).includes(type);
  }

  const getCompanyDetailAsync = async () => {
    const companyDetail = await getCompanyDetail();
    const markerFeatures = companyDetail?.markerFeatures;

    const response = await getServices();
    const services = response?.map((d) => d.data()) ?? [];
    setCompanyServices(services);

    function isFlagEnabled(flag) {
      const isThereAFlag = services?.some(
        (service) => service.serviceFeatures?.[flag]
      );
      return isThereAFlag;
    }

    const features = {
      invoicing: isFlagEnabled('invoicing'),
      activities: isFlagEnabled('activities'),
      properties: isFlagEnabled('properties'),
      reports: markerFeatures?.reports ?? false,
      workOrders: isFlagEnabled('requestService'),
      indoor: checkServiceType(services, 'indoor'),
      outdoor: checkServiceType(services, 'outdoor'),
      attendance: markerFeatures?.attendance ?? false,
      routeBased: markerFeatures?.routeBased ?? false,
      activitiesOnJob: isFlagEnabled('activitiesOnJob'),
      shiftManagement: isFlagEnabled('shiftManagement'),
      periodicJobs: markerFeatures?.periodicJobs ?? false,
      calendarView: markerFeatures?.calendarView ?? false,
      markerBased: checkServiceType(services, 'markerbased'),
      scheduledReports: markerFeatures?.scheduledReports ?? false,
      endCustomerLogin: markerFeatures?.endCustomerLogin ?? false,
      propertyLevelGeofence: isFlagEnabled('propertyLevelGeofence'),
      enableOrganizationCrew: markerFeatures?.enableOrganizationCrew ?? false,
    };

    const subscribed = {
      attendance: features?.attendance,
      indoor: features?.indoor,
      outdoor: features?.outdoor,
      reports: features?.reports,
      markerbased: features?.markerBased,
      routebased: features?.routeBased,
    };

    const filterTypesData = getFilterTypes(subscribed);

    const subscribedTypesData = getSubscribedServiceTypes(subscribed);

    setFilterTypes(filterTypesData);
    setSubscribedServices(features);
    setSubscribedTypes(subscribedTypesData);
  };

  return (
    <ServiceTypesContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        filterTypes,
        subscribedTypes,
        subscribedServices,
        getCompanyDetailAsync,
        companyServices,
        getDetails,
        properties,
        markers,
      }}
    >
      {children}
    </ServiceTypesContext.Provider>
  );
};
