import styled from 'styled-components';
import React, { useEffect, useState, useContext } from 'react';
import { Card, Typography, CircularProgress } from '@material-ui/core';

import { Row } from '../../styles/snowm_styled';
import { getRelativeDate } from '../../helpers/date';
import IndoorMap from '../../Components/Private/Routes/Canvas/indoor_map_canvas';
import { LocalizationContext } from '../../contexts/localization_context';
import {
  getSubLocationInfo,
  fetchAllBeacons,
} from '../../controllers/navigine';
import {
  getRouteByKey,
  getMarkerLogs,
  getMarkerByBeaconId,
  getRealTimeLocationOfTheIndoorProviders,
} from '../../controllers/snowm_firebase';
import { BoldTitle, Text } from './styled';
import MarkerLogs from './markerLogs/MakerLogs';

const IndoorJobRouteDetail = ({ match }) => {
  const { jobKey, routeKey } = match.params;
  const [routeInfo, setRouteInfo] = useState({});
  const [beaconPoints, setBeaconPoints] = useState([]);
  const [subLocation, setSubLocation] = useState({});
  const [polylineHash, setPolylineHash] = useState();
  const [crews, setCrews] = useState({});
  const [selectedCrew, setSelectedCrew] = useState('');
  const [markerLogs, setMarkerLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedMarker, setSelectedMarker] = useState({});

  const { strings } = useContext(LocalizationContext);

  const { MARKER_LOGS } = strings.markers ?? '';
  const { CREWS } = strings.sidemenu ?? '';
  const { NAME } = strings.inputLabel ?? '';
  const { LAST_UPDATED } = strings.misc ?? '';

  useEffect(() => {
    let isSubscribe = true;
    const updateCrews = (response) => {
      if (isSubscribe) {
        setCrews(response);
      }
    };

    const _updateMarkerLogs = (logs) => {
      if (isSubscribe) {
        setMarkerLogs(logs);
      }
    };

    getRealTimeLocationOfTheIndoorProviders(jobKey, routeKey, updateCrews);

    getMarkerLogs(jobKey, routeKey, false, _updateMarkerLogs);

    return () => {
      isSubscribe = false;
    };
  }, [jobKey, routeKey]);

  useEffect(() => {
    let isSubscribe = true;
    getRouteByKey(routeKey).then((response) => {
      if (isSubscribe) {
        setRouteInfo(response);
        setPolylineHash(response.polyline);
      }
    });
    return () => {
      isSubscribe = false;
    };
  }, [routeKey]);

  useEffect(() => {
    let isSubscribe = true;
    const subLocationId = routeInfo.navigineLocationId;

    if (subLocationId) {
      fetchAllBeacons(subLocationId).then((response) => {
        if (isSubscribe) setBeaconPoints(response.beacons);
      });

      getSubLocationInfo(subLocationId).then((response) => {
        if (isSubscribe) {
          setSubLocation(response.sublocation);
          setLoading(false);
        }
      });
    }
    return () => {
      isSubscribe = false;
    };
  }, [routeInfo]);

  const handleClick = async (
    selectedMarkerPoint,
    isPointClicked,
    crewThatIsSelected
  ) => {
    setSelectedCrew(crewThatIsSelected);

    if (
      isPointClicked &&
      selectedMarkerPoint &&
      Object.values(selectedMarkerPoint).length > 0
    ) {
      const response = await getMarkerByBeaconId(selectedMarkerPoint.uuid);

      setSelectedMarker(response);
    } else {
      setSelectedMarker({});
    }
  };

  return loading ? (
    <CircularProgress />
  ) : (
    <Row align="flex-start">
      <ContentPane>
        <Row>
          <Typography variant="h5">
            {routeInfo ? routeInfo.name : ''}
          </Typography>
        </Row>
        {Object.values(crews).length > 0 && (
          <Typography variant="subtitle1">{CREWS}</Typography>
        )}
        <>
          {Object.values(crews).map((crew) => (
            <Card
              style={{
                padding: 12,
                backgroundColor: selectedCrew === crew && '#ccecf9',
                overflow: 'visible',
              }}
              key={crew.uid}
            >
              <Row>
                <Row width="auto" marginbottom="auto">
                  <BoldTitle style={{ fontSize: 16 }}>{NAME}</BoldTitle>
                  <Typography>:</Typography>
                </Row>
                <Text>{crew.name}</Text>
              </Row>
              <Row>
                <Row width="auto" marginbottom="auto">
                  <BoldTitle style={{ fontSize: 16 }}>{LAST_UPDATED}</BoldTitle>
                  <Typography>:</Typography>
                </Row>
                <Text>{getRelativeDate(crew.lastUpdated)}</Text>
              </Row>
            </Card>
          ))}
        </>
        <div style={{ marginTop: 16 }}>
          <SubTitle style={{ fontWeight: 'bold' }} variant="subtitle1">
            {MARKER_LOGS}
          </SubTitle>
          <div>
            {markerLogs.length > 0 ? (
              <>
                {markerLogs.map((log) => {
                  if (log.endDate) {
                    return <MarkerLogs key={log.key} log={log} />;
                  }
                  return null;
                })}
              </>
            ) : (
              <SubTitle>No logs present here</SubTitle>
            )}
          </div>
        </div>
      </ContentPane>
      {Object.values(subLocation).length > 0 && (
        <IndoorMap
          hash={polylineHash}
          image={subLocation.files[0].fullUrl}
          beaconPoints={beaconPoints}
          crews={crews}
          onTapHandler={handleClick}
          selectedMarker={selectedMarker}
          imageHeight={subLocation.ph}
          imageWidth={subLocation.pw}
        />
      )}
    </Row>
  );
};

export default IndoorJobRouteDetail;

const ContentPane = styled.div`
  padding: 10px;
  overflow-y: auto;
  min-width: 250px;
  height: calc(100vh - 84px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
`;

const SubTitle = styled(Typography)`
  && {
    text-align: center;
  }
`;
