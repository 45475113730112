import { Button } from '@chakra-ui/button';
import { Box, Flex, Grid, Heading, Stack, Wrap } from '@chakra-ui/layout';
import { CircularProgress } from '@material-ui/core';
import countriesInfo from 'countries-information';
import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import { LocalizationContext } from '../../../contexts/localization_context';
import {
  auth,
  editUserProfile,
  getUserInfo,
  uploadUserProfileToStorage,
} from '../../../controllers/snowm_firebase';
import ClickableContainer from '../../../reusableComponent/ClickableContainer';
import ImageContainer from '../../../reusableComponent/ImageContainer';
import { SInput } from '../../Public/SInput';
import PassChange from './ChangePassword';

const allCountries = countriesInfo.getAllCountries();
const countriesDetails = allCountries.reduce((all, country) => {
  const dialingCode = country.countryCallingCodes[0] ?? '';
  if (!dialingCode || !country.emoji) {
    return all;
  }
  all.push({
    value: dialingCode,
    label: `${country.name} (${dialingCode})`,
  });
  return all;
}, []);

const customStyles = {
  menu: (provided, _) => ({
    ...provided,
    width: 400,
    background: 'white',
  }),
  control: () => ({
    width: 100,
    display: 'flex',
    border: 'solid 1px #ECEEF5',
    borderRadius: 5,
    height: '41px',
  }),
};

const UserProfile = () => {
  const { strings } = useContext(LocalizationContext);

  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState<string>('');
  const [isUpdating, setIsUpdating] = useState(false);

  const [flag, setFlag] = useState('ca');
  const [userProfile, setUserProfile] = useState<any>({});
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openPassChange, setOpenPassChange] = useState(false);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [profilePicFile, setProfilePicFile] = useState<File | null>(null);
  const [showChangePasswordMsg, setShowChangePasswordMsg] = useState(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const history = useHistory();

  const {
    sidemenu: { USER_PROFILE },
    inputLabel: { NAME, EMAIL, ADDRESS, CONTACT_NUMBER, DESIGNATION },
    misc: { LANGUAGE_SETTINGS, CHANGE_PASSWORD },
    action: { EDIT },
  } = strings;

  useEffect(() => {
    let unsubscribe = true;
    const updateUser = (response) => {
      if (unsubscribe) {
        setUserProfile(response);
        setLoading(false);
      }
    };
    getUserInfo(auth().currentUser?.uid, updateUser);

    return () => {
      unsubscribe = false;
    };
  }, []);

  useEffect(() => {
    if (userProfile?.imageUrl && !image) {
      setImage(userProfile?.imageUrl);
    }
  }, [userProfile]);

  useEffect(() => {
    if (userProfile?.dialingCode) {
      const allCountry = allCountries.find(
        (c) => c.countryCallingCodes[0] === userProfile?.dialingCode
      );
      const country = (allCountry?.alpha2 ?? ' ').toLowerCase();
      setFlag(country);
    }
  }, [userProfile.dialingCode]);

  const handleLanguageSettingClick = () => {
    setOpenLocationModal(true);
  };

  const handleClose = () => {
    setOpenLocationModal(false);
  };

  const handleLanguage = () => {
    // TODO: set the language of the site
    setOpenLocationModal(false);
  };

  const handleEditButton = () => {
    setOpenEditModal(false);
  };
  const handleEditIcon = () => {
    setOpenEditModal(true);
  };

  const handleEditCloseButton = () => {
    setOpenEditModal(false);
  };

  function handleChangePasswordButtonClick() {
    setOpenChangePasswordModal(true);
  }

  function handleChangePasswordCancel() {
    setOpenChangePasswordModal(false);
  }

  function handleSnackBarClose() {
    setShowChangePasswordMsg(false);
  }

  const handleSubmitAction = async (event) => {
    event.preventDefault();
    setIsUpdating(true);
    let updatedUserProfile = { ...userProfile };
    if (profilePicFile) {
      const url = await uploadUserProfileToStorage(profilePicFile);
      updatedUserProfile = { ...updatedUserProfile, imageUrl: url };
    }

    try {
      await editUserProfile(updatedUserProfile);
    } catch (error) {
      console.error(error);
    } finally {
      setIsUpdating(false);
    }
  };

  const changeProfilePicture = (value: FileList | null) => {
    if (value) {
      setProfilePicFile(value[0]);
      setImage(URL.createObjectURL(value[0]));
    }
  };

  const handleInputChange = (formField: string, value: string) => {
    let newValue = value;
    let actualFormField = formField;
    if (formField === 'firstName') {
      const lastName = getLastName();
      newValue = `${value} ${lastName}`;
      actualFormField = 'name';
    } else if (formField === 'lastName') {
      const firstName = getFirstName();
      newValue = `${firstName} ${value}`;
      actualFormField = 'name';
    }

    setUserProfile((prevData) => ({
      ...prevData,
      [actualFormField]: newValue,
    }));
  };

  const toggleShowPassChange = () => {
    setOpenPassChange(!openPassChange);
  };

  const handleCountryChange = (selectedDialingCode) => {
    const selectedCountry = allCountries.find((country) => {
      return country.countryCallingCodes.includes(selectedDialingCode.value);
    });

    setUserProfile({
      ...userProfile,
      dialingCode: selectedDialingCode.value,
      countryCode: selectedCountry?.alpha2,
    });
  };

  const getFirstName = () => {
    const firstName = userProfile?.name?.split(' ')?.[0] ?? '';
    return firstName;
  };

  const getLastName = () => {
    const lastName = userProfile?.name?.split(' ')?.[1] ?? '';
    return lastName;
  };

  if (loading) {
    return (
      <div style={{ margin: '35% 50%' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <form
        onSubmit={handleSubmitAction}
        style={{
          margin: '20px 0 0 20px',
        }}
      >
        <Grid templateColumns="repeat(auto-fill, minmax(500px, 1fr))">
          <Box h="746px">
            <Box h="92%" boxShadow="xl" p="6" rounded="md" bg="white">
              <Stack spacing="8">
                <Flex justify="space-between" align="center">
                  <Heading as="h1" size="lg" color="text.500">
                    Profile
                  </Heading>
                </Flex>
                <Flex gridGap="8" direction="column">
                  <ImageContainer
                    size="200px"
                    imageURL={image}
                    objectFit="cover"
                    onChange={(e) => changeProfilePicture(e.target.files)}
                  />
                  <Stack spacing="6">
                    <Heading as="h1" size="md" color="text.500">
                      Details
                    </Heading>
                    <Flex gridGap="2">
                      <SInput
                        isRequired
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={getFirstName()}
                        onChange={(e) =>
                          handleInputChange(e.target.name, e.target.value)
                        }
                      />
                      <SInput
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={getLastName()}
                        onChange={(e) =>
                          handleInputChange(e.target.name, e.target.value)
                        }
                      />
                    </Flex>

                    {userProfile.phoneNumber && userProfile.email ? (
                      <>
                        <SInput
                          isRequired
                          type="text"
                          name="email"
                          placeholder="Email"
                          value={userProfile.email}
                          onChange={(e) =>
                            handleInputChange(e.target.name, e.target.value)
                          }
                          errorMessage="Please enter valid email."
                          isDisabled
                        />

                        <Flex gridGap="md" alignItems="center">
                          <Box flexBasis="40%">
                            <Select
                              styles={customStyles}
                              options={countriesDetails}
                              value={{
                                label: (
                                  <img
                                    src={`https://flagcdn.com/24x18/${flag}.png`}
                                    alt="country-flag"
                                  />
                                ),
                                value: userProfile?.countryCode,
                              }}
                              onChange={handleCountryChange}
                              placeholder="Select country"
                              isDisabled
                            />
                          </Box>

                          <SInput
                            type="number"
                            name="phoneNumber"
                            placeholder="Enter Phone Number *"
                            value={userProfile.phoneNumber ?? ''}
                            onChange={(e) =>
                              handleInputChange(e.target.name, e.target.value)
                            }
                            countryCode={`${userProfile.dialingCode ?? ''}-`}
                            isDisabled
                          />
                        </Flex>
                      </>
                    ) : (
                      <SInput
                        isRequired
                        type="text"
                        name="email"
                        placeholder="Email"
                        value={userProfile.email}
                        onChange={(e) =>
                          handleInputChange(e.target.name, e.target.value)
                        }
                        errorMessage="Please enter valid email."
                        isDisabled
                      />
                    )}
                    <Flex justifyContent="space-between" alignItems="center">
                      <ClickableContainer
                        isDisabled={isUpdating}
                        onClick={toggleShowPassChange}
                      >
                        <Heading variant="ghost" size="xs" color="blue.500">
                          Change Password
                        </Heading>
                      </ClickableContainer>
                      <Wrap justify="flex-end">
                        <Button
                          variant="ghost"
                          isDisabled={isUpdating}
                          onClick={() => {
                            history.push('/admin');
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          variant="solid"
                          colorScheme="blue"
                          isLoading={isUpdating}
                        >
                          Update Changes
                        </Button>
                      </Wrap>
                    </Flex>
                  </Stack>
                </Flex>
              </Stack>
            </Box>
          </Box>
        </Grid>
      </form>
      {openPassChange && (
        <PassChange
          username={userProfile?.email ?? ''}
          userKey={userProfile?.userKey ?? ''}
          handleCancel={toggleShowPassChange}
        />
      )}
    </>
  );
};

export default UserProfile;
