import { useContext, useEffect, useState } from 'react';

import { Avatar, Box, Flex, Tbody, Td, Text } from '@chakra-ui/react';
import MicroLocationImage from '../../../../assets/svgs/MicroLocationImage.svg';
import { ServiceTypesContext } from '../../../../contexts/service_types';
import { sortByEntity } from '../../../../helpers/misc';
import EmptyComponent from '../../../../reusableComponent/EmptyComponent';
import {
  MainTable,
  MainTableRow,
} from '../../../../reusableComponent/MainTable';

const CustomerMicrLocationTableRow = (props) => {
  const { microLocation, handleDetailsDrawer, location } = props;

  const { name, imageUrl, propertyKey } = microLocation;

  if (!location) {
    return <h1>loading</h1>;
  }

  return (
    <MainTableRow>
      <Td>
        <Flex
          gridGap="sm"
          alignItems="center"
          cursor="pointer"
          onClick={() => handleDetailsDrawer(microLocation)}
        >
          <Avatar name={name} src={imageUrl} />
          <Text>{name}</Text>
        </Flex>
      </Td>

      <Td>{location?.[propertyKey]?.name ?? '-'}</Td>
      <Td>
        <Flex justify="flex-end">
          {location?.[propertyKey]?.address ?? '-'}
        </Flex>
      </Td>
    </MainTableRow>
  );
};

export const CustomerMicroLocationTable = (props) => {
  const {
    microLocations,
    handleDetailsDrawer,
    searchText,
    microLocationHeaders,
    setMicroLocationHeaders,
    setFilteredMicroLocation,
  } = props;
  const [location, setLocation] = useState<any>();

  const { properties } = useContext(ServiceTypesContext);
  useEffect(() => {
    const locations = properties?.filter(
      (property) => property.archive === false
    );

    const locationWithKey = locations?.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {});

    setLocation(locationWithKey);
  }, [properties]);

  const handleSorting = (header, headerIndex) => {
    const { sort, name: fieldName } = header;

    const newSort = sort === 'desc' ? 'asc' : 'desc';

    const copiedMicroLocations = [...microLocations];

    switch (fieldName) {
      case 'Name':
        copiedMicroLocations?.sort((a, b) =>
          sortByEntity(a.name, b.name, newSort)
        );
        break;

      case 'Location':
        copiedMicroLocations?.sort((a, b) => {
          const firstLocationName = location[a.propertyKey]?.name ?? '';
          const secondLocationName = location[b.propertyKey]?.name ?? '';

          return sortByEntity(firstLocationName, secondLocationName, newSort);
        });

        break;

      case 'Location Address':
        copiedMicroLocations?.sort((a, b) => {
          const firstLocationAddress = location[a.propertyKey]?.address ?? '';
          const secondLocationAddress = location[b.propertyKey]?.address ?? '';

          return sortByEntity(
            firstLocationAddress,
            secondLocationAddress,
            newSort
          );
        });
        break;

      default:
        break;
    }

    const updatedHeaders = [...microLocationHeaders];
    updatedHeaders.splice(headerIndex, 1, {
      name: fieldName,
      sort: newSort,
    });
    setMicroLocationHeaders(updatedHeaders);
    setFilteredMicroLocation(copiedMicroLocations);
  };

  // Todo: add title ,subtitle and image
  if (microLocations?.length === 0) {
    return (
      <EmptyComponent
        title="No microLocation"
        subTitle=""
        isSearch={!!searchText}
        header="Micro-Location"
        zeroStateImage={MicroLocationImage}
      />
    );
  }
  return (
    <Box>
      <MainTable headers={microLocationHeaders} handleSorting={handleSorting}>
        <Tbody bg="white" boxShadow="0px 12px 24px #ECEEF5" borderRadius="16px">
          {microLocations?.map((microLocation) => {
            return (
              <CustomerMicrLocationTableRow
                key={microLocation.id}
                microLocation={microLocation}
                handleDetailsDrawer={handleDetailsDrawer}
                location={location}
              />
            );
          })}
        </Tbody>
      </MainTable>
    </Box>
  );
};
