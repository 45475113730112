/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_beaconms.jsx
 *
 *  Description: Show the list of beaconms
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 10-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import { useEffect, useState, useContext } from 'react';

import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import { EditIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Icon,
  Stack,
  Tbody,
  Td,
  Text,
  useToast,
} from '@chakra-ui/react';
import color from '../../../utils/color';
import SearchBar from '../../Common/Search_Bar';
import Loader from '../../Generics/snowm_loader';
import { BeaconMsContext } from '../../../contexts/beaconMs';
import { SideDrawer } from '../../../reusableComponent/SideDrawer';
import ActionsDrawer from '../../../reusableComponent/ActionsDrawer';
import { CustomInput } from '../../../reusableComponent/CustomInput';
import { ReactComponent as MarkerI } from '../../../assets/svgs/Markeri.svg';
import zeroStateDeviceImage from '../../../assets/images/zeroStateDevice.png';
import { MainTable, MainTableRow } from '../../../reusableComponent/MainTable';
import { ReactComponent as BatteryRed } from '../../../assets/svgs/battery-20.svg';
import { ReactComponent as BatteryGreen } from '../../../assets/svgs/battery80.svg';
import { ReactComponent as BatteryCritical } from '../../../assets/svgs/materialerror.svg';
import {
  editRealTimeDeviceName,
  getMarkersDetails,
  getPropertyById,
} from '../../../controllers/snowm_firebase';
import {
  convertObjectIntoArray,
  filterDataByName,
  filterDataBySerialNumber,
  sortByEntity,
  findSortType,
} from '../../../helpers/misc';
import EmptyComponent from '../../../reusableComponent/EmptyComponent';
import { ServiceTypesContext } from '../../../contexts/service_types';
import { Toast } from '../../Common/Toast';
import { LocationContext } from '../../../contexts/locationContext';

const headers = [
  { name: 'Device Name', sort: 'asc', checkBox: 'true' },
  { name: 'Serial Number', sort: 'asc' },
  { name: 'Location Name', sort: 'asc' },
  { name: 'Micro-Location', sort: 'asc' },
  { name: 'Last seen', sort: 'asc' },
];

const id = '1';
const batteryHealth = {
  red: 33,
  green: 66,
  crital: 0,
};
const BeaconMConsumer = (beaconMsProps) => (
  <BeaconMsContext.Consumer>
    {({ beaconMs, fetchBeaconMs }) => (
      <BeaconMs
        beaconMs={beaconMs}
        fetchBeaconMs={fetchBeaconMs}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...beaconMsProps}
      />
    )}
  </BeaconMsContext.Consumer>
);

const BeaconMs = ({ fetchBeaconMs, beaconMs, ...props }) => {
  const [openActionDrawer, setOpenActionDrawer] = useState(false);
  const [openSideDrawer, setOpenSideDrawer] = useState(false);
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [searchText, setSearchText] = useState();
  const [devices, setDevices] = useState();
  const [editDevice, setEditDevice] = useState({});
  const [loading, setLoading] = useState(false);
  const [deviceHeader, setDeviceHeader] = useState(headers);
  const { handleSubmit, errors } = useForm();
  const toast = useToast();

  const { locations } = useContext(LocationContext);

  useEffect(() => {
    if (!beaconMs) {
      fetchBeaconMs();
    }
  }, []);

  useEffect(() => {
    if (searchText) {
      const name = filterDataByName(searchText, beaconMs);
      const serial = filterDataBySerialNumber(searchText, beaconMs);
      if (isEmpty(name)) {
        const serialNumber = convertObjectIntoArray(serial);
        setDevices([...serialNumber]);
      } else {
        const deviceName = convertObjectIntoArray(name);
        setDevices([...deviceName]);
      }
    }
  }, [searchText]);

  const populateLocationsAndMicroLocations = async () => {
    const populatedDevicesPromise = Object.values(beaconMs)?.map(
      async (beaconM) => {
        beaconM.microLocations = await getMarkersDetails(beaconM.markerKeys);
        const locationKeys = beaconM.microLocations.map(
          (microLocation) => microLocation.propertyKey
        );
        const uniqueLocationKeys = [...new Set(locationKeys)].filter(
          (locationKey) => locationKey !== null
        );
        beaconM.locations = await Promise.all(
          uniqueLocationKeys.map(getPropertyById)
        );
        return beaconM;
      }
    );
    const populatedDevices = await Promise.all(populatedDevicesPromise);

    const { sort } = findSortType(deviceHeader, 'Device Name');
    populatedDevices.sort((a, b) => sortByEntity(a.name, b.name, sort));
    setDevices(populatedDevices);
  };

  useEffect(() => {
    if (beaconMs) {
      populateLocationsAndMicroLocations();
    }
  }, [beaconMs]);

  const getColorForBeacon = (beaconM) => {
    if (beaconM?.assigned) return color.green;
    return color.red;
  };

  const getDateFormat = (date) => {
    if (date) {
      return dayjs(date).format('YYYY/MM/DD HH:mm');
    }
    return '----';
  };

  const handleSorting = (header, headerIndex) => {
    const { sort, name: fieldName } = header;
    const newSort = sort === 'desc' ? 'asc' : 'desc';
    const copyJanitor = [...devices];

    switch (fieldName) {
      case 'Device Name':
        copyJanitor?.sort((a, b) => sortByEntity(a.name, b.name, newSort));
        break;

      case 'Serial Number':
        copyJanitor?.sort((a, b) =>
          sortByEntity(a.serialNumber, b.serialNumber, newSort)
        );
        break;

      case 'Location Name':
        copyJanitor?.sort((a, b) => {
          const firstLocatioName = getLocationName(a);
          const secondLocatioName = getLocationName(b);

          return sortByEntity(firstLocatioName, secondLocatioName, newSort);
        });
        break;

      case 'Micro-Location':
        copyJanitor?.sort((a, b) => {
          const firstLocatioName = getMicroLocationName(a.microLocations);
          const secondLocatioName = getMicroLocationName(b.microLocations);

          return sortByEntity(firstLocatioName, secondLocatioName, newSort);
        });
        break;

      case 'Last seen':
        copyJanitor?.sort((a, b) => sortByEntity(a.date, b.date, newSort));
        break;
      default:
        break;
    }
    const updatedHeaders = [...deviceHeader];
    updatedHeaders.splice(headerIndex, 1, {
      name: fieldName,
      sort: newSort,
    });
    setDeviceHeader(updatedHeaders);
    setDevices(copyJanitor);
  };

  function handleCheck(device) {
    setOpenActionDrawer(true);
    if (selectedEntities?.[0]?.uuid === device.uuid) {
      setSelectedEntities([]);
    } else if (
      selectedEntities.length &&
      selectedEntities?.[0]?.uuid !== device.uuid
    ) {
      if (!toast.isActive(id)) {
        toast({
          id,
          duration: 3000,
          status: 'error',
          isClosable: true,
          title: 'Multiselect not available',
        });
      }
    } else {
      setSelectedEntities([{ ...device }]);
    }
  }

  const searchDevices = (e) => {
    setSearchText(e.target.value);
  };

  function getMicroLocationName(mLocations) {
    const name = mLocations?.map((i) => i?.name);
    return name?.join();
  }

  function getLocationName(locationData) {
    const name = locationData?.locations?.map((i) => i.name);
    return name?.join();
  }
  function getIcon(icon) {
    switch (true) {
      case batteryHealth.red > icon:
        return BatteryRed;
      case batteryHealth.red === icon:
        return BatteryCritical;
      default:
        return BatteryGreen;
    }
  }

  const onEditDevice = (editedDevice) => {
    const device = editedDevice[0];
    setEditDevice({ ...device });
    setOpenSideDrawer(true);
    setOpenActionDrawer(false);
  };

  const showButtons = () => (
    <Button
      size="sm"
      colorScheme="blue"
      border="2px solid #006DB8"
      leftIcon={<EditIcon />}
      variant="outline"
      onClick={() => onEditDevice(selectedEntities)}
    >
      Edit
    </Button>
  );

  const closeEditDrawer = () => {
    setOpenSideDrawer(false);
    setOpenActionDrawer(true);
  };

  const editDeviceName = async () => {
    setLoading(true);
    try {
      await editRealTimeDeviceName({ ...editDevice });
      setLoading(false);
      setSelectedEntities([]);
      setOpenSideDrawer(false);
      toast({
        duration: 3000,
        isClosable: true,
        render: function CustomFile() {
          return (
            <Toast
              title="Success"
              status="success"
              description={renderDeviceToast(editDevice.name)}
            />
          );
        },
      });
    } catch (err) {
      toast({
        duration: 3000,
        status: 'error',
        isClosable: true,
        description: 'Unable to edit.',
        title: 'Error',
      });
      setLoading(false);
      setOpenSideDrawer(false);
      setSelectedEntities([]);
    }
  };
  function renderDeviceToast(name) {
    return (
      <span>
        <span style={{ fontWeight: 'bold', fontSize: 18 }}>
          {name.toString()}&ensp;
        </span>
        edited successfully
      </span>
    );
  }

  const handleName = (e) => {
    const { name, value } = e.target;
    setEditDevice({ ...editDevice, [name]: value });
  };

  if (!devices) {
    return <Loader />;
  }

  const getBatteryPercentageColor = (battery) => {
    if (battery < 50) {
      return 'red.200';
    }
    return 'green.200';
  };

  return (
    <Flex
      p="xxxl"
      gap="sm"
      height="100%"
      overflow="auto"
      flexDir="column"
      bg="background.500"
    >
      <Flex direction="column" gridGap="llg">
        <Heading as="h4">Devices</Heading>
        <SearchBar
          title="Device"
          LeftIcon={<SearchIcon />}
          onChange={(e) => searchDevices(e)}
          setSelectedEntities={setSelectedEntities}
          selectedEntities={selectedEntities}
        />
      </Flex>

      {devices?.length ? (
        <>
          <Box mb="xxxl" overflow="hidden">
            <MainTable headers={deviceHeader} handleSorting={handleSorting}>
              <Tbody
                bg="white"
                boxShadow="0px 12px 24px #ECEEF5"
                borderRadius="16px"
              >
                {devices?.map((beaconM) => (
                  <MainTableRow key={beaconM.serialNumber}>
                    <Td>
                      <Flex gridGap="md">
                        <Checkbox
                          onChange={() => handleCheck(beaconM)}
                          isChecked={
                            selectedEntities?.[0]?.uuid === beaconM.uuid
                          }
                        />
                        <Icon height="40px" width="40px" as={MarkerI} />

                        <Flex flexDirection="column" gridGap="xsm">
                          <Text>{beaconM.name}</Text>
                          {beaconM?.deviceDetail?.battery ? (
                            <Flex alignItems="center" gridGap="xxsm">
                              <Icon
                                width="30px"
                                height="15px"
                                as={getIcon(beaconM?.deviceDetail?.battery)}
                              />
                              <Text
                                color={getBatteryPercentageColor(
                                  beaconM?.deviceDetail?.battery
                                )}
                              >
                                {beaconM?.deviceDetail?.battery}%
                              </Text>
                            </Flex>
                          ) : null}
                        </Flex>
                      </Flex>
                    </Td>
                    <Td>{beaconM.serialNumber}</Td>
                    <Td>
                      {getLocationName(beaconM)?.length
                        ? getLocationName(beaconM)
                        : '---'}
                    </Td>
                    <Td>
                      {getMicroLocationName(beaconM.microLocations)?.length
                        ? getMicroLocationName(beaconM.microLocations)
                        : '---'}
                    </Td>
                    <Td textAlign="right">
                      {getDateFormat(beaconM?.microLocations?.[0]?.lastServed)}
                    </Td>
                  </MainTableRow>
                ))}
              </Tbody>
            </MainTable>
            {isEmpty(beaconMs) && <Box>No Providers</Box>}
          </Box>

          <SideDrawer
            title="Edit Device"
            open={openSideDrawer}
            negativeAction={closeEditDrawer}
          >
            <form onSubmit={handleSubmit(editDeviceName)}>
              <Stack gridGap="md">
                <CustomInput
                  isDisabled
                  placeholder="Serial Number"
                  value={editDevice.serialNumber}
                />
                <CustomInput
                  name="name"
                  value={editDevice.name}
                  placeholder="Device Name*"
                  defaultValue={editDevice.name}
                  onChange={(e) => handleName(e)}
                />
                <Flex justify="flex-end" gridGap="md">
                  <Button
                    variant="ghost"
                    color="primary.500"
                    onClick={closeEditDrawer}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    colorScheme="blue"
                    background="#006DB8 0% 0%"
                    boxShadow=" 0px 3px 6px #006DB85C"
                    borderRadius="5px"
                    isLoading={loading}
                  >
                    Update
                  </Button>
                </Flex>
              </Stack>
            </form>
          </SideDrawer>

          <ActionsDrawer
            openActionDrawer={openActionDrawer}
            selectedEntities={selectedEntities}
            showButtons={() => showButtons()}
            setSelectedEntities={setSelectedEntities}
            entity="Device"
          />
        </>
      ) : (
        <EmptyComponent
          title="No devices have been linked with your account yet"
          subTitle="If you see this message your devices are still being configured by us."
          header="Devices"
          zeroStateImage={zeroStateDeviceImage}
          isSearch={!!searchText}
        />
      )}
    </Flex>
  );
};

export default BeaconMConsumer;
