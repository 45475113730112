import { Checkbox, Text } from '@chakra-ui/react';
import SDialog from './SDialog';

const DeleteDialog = (props: any) => {
  const {
    entity,
    open,
    toggleAgreeCheckbox,
    closeDeleteDialog,
    handleDeleteAction,
    agreed,
    body,
    title,
    loading,
    deleteSubtitle,
    additionalConfirmationText,
  } = props;

  return (
    <SDialog
      size="lg"
      loading={loading}
      height="fit-content"
      positiveLabel="Delete"
      negativeLabel="Cancel"
      body={body}
      positiveLabelColor="primary"
      open={open}
      positiveActionDisabled={!agreed}
      handleNegativeAction={closeDeleteDialog}
      handlePositiveAction={handleDeleteAction}
      title={title}
      subTitle={deleteSubtitle}
    >
      <Checkbox
        checked={agreed}
        borderRadius="4px"
        onChange={toggleAgreeCheckbox}
      >
        <Text fontSize="12px" color="grey.500">
          I agree to delete all data about this {entity}.{' '}
          {additionalConfirmationText && additionalConfirmationText}
        </Text>
      </Checkbox>
    </SDialog>
  );
};

export default DeleteDialog;
