import React, { useEffect, useState } from 'react';
import { Box, Stack, Heading, Text, Flex, Icon } from '@chakra-ui/react';
import styled from 'styled-components';
import {
  completeJob,
  getPropertyById,
  getMicrolocationsByLocationKey,
} from '../../../controllers/snowm_firebase';

import Loader from '../../Generics/snowm_loader';

interface LocationDetailsProps {
  propertyId: string;
  location: any; // TODO: location type
}

const LocationDetails = (props: LocationDetailsProps) => {
  const { propertyId, location } = props;

  const [property, setProperty] = useState(location);

  useEffect(() => {
    let isSubscribe = true;
    if (!property) {
      getPropertyById(propertyId).then((res) => {
        if (isSubscribe) setProperty(res);
      });
    }

    return () => {
      isSubscribe = false;
    };
  }, [propertyId]);

  if (!property) {
    return <Loader />;
  }

  return (
    <Stack spacing="md">
      <Box>
        <Header>Client Access</Header>
        <Text>{property?.customer?.email ?? '-'}</Text>
      </Box>
    </Stack>
  );
};

export default LocationDetails;

const Header = styled(Text)`
  text-align: left;
  font: normal normal 600 16px/30px Montserrat;
  letter-spacing: 0.26px;
  color: #162350;
  opacity: 1;
  // padding-top: 10px;
`;
