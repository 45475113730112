/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: alert_box.jsx
 *
 *  Description: Alert Box Component
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 22-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import { useRef } from 'react';

import {
  AlertDialog,
  Button,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  Divider,
  AlertDialogCloseButton,
  Text,
} from '@chakra-ui/react';

import Span from './Span';
import color from '../../utils/color';
import PrimayButton from './primary_button';
import { Row } from '../../styles/snowm_styled';
import { Progress } from '../Generics/snowm_loader';

const AlertBox = ({
  open,
  handleNegativeAction,
  title = '',
  subtitle,
  handlePositiveAction,
  negativeText = 'Cancel',
  positiveText = 'Delete',
  error = null,
  isTakingAction = false,
}) => {
  const cancelRef = useRef();

  return (
    <AlertDialog
      width="510px"
      isOpen={open}
      onClose={handleNegativeAction}
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      isCentered
      size="md"
    >
      <AlertDialogContent p="4">
        <AlertDialogHeader fontSize="md" fontWeight="bold">
          {title}
        </AlertDialogHeader>
        <AlertDialogCloseButton />
        <Divider w="90%" mx="auto" />
        <AlertDialogBody>
          <Text weight="bold">{subtitle}</Text>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            style={{
              fontSize: 16,
              fontWeight: '700',
              textTransform: 'none',
              marginRight: '5px',
            }}
            onClick={handleNegativeAction}
            color="primary"
          >
            <Text color="primary">{negativeText}</Text>
          </Button>
          <PrimayButton
            onClick={handlePositiveAction}
            disabled={isTakingAction}
          >
            {isTakingAction ? (
              <Progress size={15} progressColor={color.white} />
            ) : (
              <Span color={color.white}>{positiveText}</Span>
            )}
          </PrimayButton>
        </AlertDialogFooter>
        {error ? (
          <Row justify="center">
            <Span weight="bold" color="error">
              {error}
            </Span>
          </Row>
        ) : null}
      </AlertDialogContent>
    </AlertDialog>
  );
};

// const StyledDialogContentText = styled(DialogContentText)`
//   && {
//     margin-top: 10px;
//     line-height: 1;
//     font-size: 1em;
//     font-weight: 700;
//     opacity: 0.6;
//   }
// `;

// const StyledDialogTitle = styled(DialogTitle)`
//   && {
//     h2 {
//       font-size: 1em;
//       font-weight: 700;
//     }
//     padding-left: 0;
//     padding-bottom: 0;
//   }
// `;

// const StyledDialogActions = styled(DialogActions)`
//   && {
//     padding: 16px;
//   }
// `;

export default AlertBox;
