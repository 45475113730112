import { createContext, useState } from 'react';

import { getPropertiesOfCompany } from '../controllers/snowm_firebase';
import { LocationInterface } from '../Components/Private/locations/location';

interface LocationContextInterface {
  getAllPropertiesOfACompany: () => void;
  locations: any;
}

export const LocationContext = createContext<LocationContextInterface>({
  locations: {},
  getAllPropertiesOfACompany: () => null,
});

export const LocationProvider = ({ children }) => {
  const [locations, setLocations] = useState<
    Record<string, LocationInterface | null> | undefined
  >(undefined);

  const getAllPropertiesOfACompany = async () => {
    const companyLocations = await getPropertiesOfCompany();

    const locationsById = companyLocations?.reduce((allLocations, location) => {
      allLocations[location.id] = location;
      return allLocations;
    }, {});

    setLocations(locationsById);
  };

  return (
    <LocationContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        locations,
        getAllPropertiesOfACompany,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};
