/* eslint-disable no-useless-escape */
/* eslint-disable prefer-const */
import {
  Button,
  Checkbox,
  Flex,
  Heading,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { TextField } from '@material-ui/core';
import CloseIcon from 'mdi-react/CloseIcon';
import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { getScheduledFor, getTypeOfReport } from './reports';
import { ServiceTypesContext } from '../../../contexts/service_types';
import { storeSchedulingBody } from '../../../controllers/snowm_firebase';
import { Report, ScheduledFor } from '../../../enums/reportBy';
import { ValidateEmail } from '../../../helpers/snowm_validators';
import { CustomInput } from '../../../reusableComponent/CustomInput';
import { CustomSelect } from '../../../reusableComponent/shiftSchedule/custom_select';
import { Col } from '../../../styles/snowm_styled';
import Span from '../../Common/Span';
import { Toast } from '../../Common/Toast';

const { Location, MicroLocation, Janitor, JanitorSchedule, LocationSchedule } =
  Report;

const { Daily, Weekly, BiWeekly, Monthly, Quarterly } = ScheduledFor;

export const byJanitor = { label: 'Janitor', value: Janitor };
export const byJanitorSchedule = {
  label: 'Janitor Schedule',
  value: JanitorSchedule,
};

export const byLocationSchedule = {
  label: 'Location Schedule',
  value: LocationSchedule,
};

export const byMicroLocation = {
  label: 'Micro-Location',
  value: MicroLocation,
};
export const byLocation = { label: 'Location', value: Location };

export const schedules = [
  {
    label: 'Daily',
    value: Daily,
  },
  {
    label: 'Weekly',
    value: Weekly,
  },
  {
    label: 'Bi-weekly',
    value: BiWeekly,
  },
  {
    label: 'Monthly',
    value: Monthly,
  },
  {
    label: 'Quarterly',
    value: Quarterly,
  },
];

const emailIdPattern =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// eslint-disable-next-line import/prefer-default-export
export const AddScheduledReport = ({
  crews,
  markers,
  properties,
  markersKeys,
  closeScheduleDialog,
  addNewScheduleReport,
  selectedScheduledReport,
  crewUids,
  propertyKeys,
}) => {
  const { handleSubmit, register, errors } = useForm();

  const emailInput = useRef();

  const initialState = {
    reportName: null,
    reportType: null,
  };
  const initialScheduleReport = selectedScheduledReport ?? initialState;

  const reducer = (state, action) => {
    const { type, payload } = action;
    return { ...state, [type]: payload };
  };

  const [scheduleReportReqBody, dispatch] = React.useReducer(
    reducer,
    initialScheduleReport
  );

  const { subscribedServices } = React.useContext(ServiceTypesContext);

  const [isMulti, setIsMulti] = React.useState(false);
  const [error, setError] = React.useState('');
  const [emailError, setEmailError] = React.useState('');
  const [reportTypes, setReportTypes] = React.useState([]);
  const [isStoringReport, setIsStoringReport] = React.useState(false);
  const [dropDownByReportType, setDropDownByReportType] = React.useState(null);
  const [emails, setEmails] = React.useState([]);
  const [tab, setTab] = React.useState('');
  const [scriptString, setScriptString] = React.useState('');

  const toast = useToast();

  const changeValueByReportType = (typeOfReport, fromReportTypeChange) => {
    switch (typeOfReport) {
      case 'byCrew':
        if (!selectedScheduledReport || fromReportTypeChange) {
          setIsMulti(false);
          dispatch({
            type: 'selectedValue',
            payload: { label: 'All', value: crewUids },
          });
        }
        setDropDownByReportType(crews);
        break;
      case 'byMarker':
        if (!selectedScheduledReport || fromReportTypeChange) {
          setIsMulti(false);

          dispatch({
            type: 'selectedValue',
            payload: { label: 'All', value: markersKeys },
          });
        }
        setDropDownByReportType(markers);
        break;
      case 'byProperty':
        if (!selectedScheduledReport || fromReportTypeChange) {
          setIsMulti(false);
          dispatch({
            type: 'selectedValue',
            payload: { label: 'All', value: propertyKeys },
          });
        }
        setDropDownByReportType(properties);
        break;

      default:
        break;
    }
  };

  React.useEffect(() => {
    if (selectedScheduledReport) {
      setIsMulti(true);
      changeValueByReportType(selectedScheduledReport.reportType.value);
    }
  }, [selectedScheduledReport]);

  React.useEffect(() => {
    // eslint-disable-next-line prefer-const
    let typeOfReports = [byJanitor, byJanitorSchedule];

    if (subscribedServices.markerBased) {
      typeOfReports.push(byMicroLocation);
    }
    if (subscribedServices?.properties) {
      typeOfReports.push(byLocation, byLocationSchedule);
    }
    setReportTypes(typeOfReports);
  }, [subscribedServices]);

  const handleReportTypeChange = (res) => {
    dispatch({ type: 'reportType', payload: res.value });
    changeValueByReportType(res.value, true);
  };

  const handleTextFieldChange = (event, type) => {
    event.preventDefault();
    dispatch({ type, payload: event.target.value });
  };

  const handleValueChange = (res) => {
    if (!isMulti && res.label !== 'All') {
      setIsMulti(true);
      dispatch({ type: 'selectedValue', payload: [res] });
      return;
    }
    if (
      !res ||
      res?.length <= 0 ||
      (isMulti && res?.some((r) => r.label === 'All'))
    ) {
      changeValueByReportType(scheduleReportReqBody?.reportType?.value, true);
      return;
    }
    dispatch({ type: 'selectedValue', payload: res });
  };

  const getSelectedLabel = () => {
    switch (scheduleReportReqBody.reportType.value) {
      case 'byCrew':
        return 'Crews';

      case 'byMarker':
        return 'Markers';

      case 'byProperty':
        return 'Properties';
      default:
        return '';
    }
  };

  const renderReportByReportType = () => {
    if (!dropDownByReportType) {
      return null;
    }
    return (
      <Col>
        <Span weight="bold">
          {`Select
          ${getSelectedLabel()}`}
          :
        </Span>

        <CustomSelect
          isMulti={isMulti}
          onChange={handleValueChange}
          options={dropDownByReportType ?? []}
          value={scheduleReportReqBody.selectedValue}
        />
      </Col>
    );
  };

  const handleScheduleChange = (res) => {
    dispatch({ type: 'scheduledFor', payload: res.value });
  };

  const renderSchedulerFor = () => (
    <Col>
      <Span weight="bold">Frequency:</Span>

      <CustomSelect
        options={schedules}
        onChange={handleScheduleChange}
        value={scheduleReportReqBody.scheduledFor}
      />
    </Col>
  );

  const rednerEmailIdsField = () => (
    <Col>
      <Span weight="bold">Emails:</Span>
      <StyledTextField
        variant="outlined"
        placeholder="Enter multiple receiver email ids separated by comma(,)."
        onChange={(event) => handleTextFieldChange(event, 'emailIds')}
        error={!!emailError}
        helperText={emailError}
        size="small"
        value={scheduleReportReqBody?.emailIds}
      />
    </Col>
  );

  function renderScheduleToast(name, key) {
    return (
      <span>
        Schedule Report
        <span style={{ fontWeight: 'bold', fontSize: 18 }}>
          &ensp;{name.toString()}&ensp;
        </span>
        {key ? 'Updated' : 'Created'} successfully.
      </span>
    );
  }

  const checkIfAnythingEmpty = () => {
    const { emailIds, reportName, reportType, scheduledFor, selectedValue } =
      scheduleReportReqBody;

    return (
      !reportName || !reportType || !selectedValue || !scheduledFor || !emailIds
    );
  };

  const getSelectedKeys = (selectedOption) => {
    let selectedKeys;
    if (isMulti) {
      selectedKeys = selectedOption?.map((option) => option.value);
    } else {
      selectedKeys = selectedOption?.value;
    }

    return selectedKeys;
  };

  const handleScheduleButton = async () => {
    setIsStoringReport(true);
    setEmailError('');
    let {
      key,
      emailIds,
      reportName,
      reportType,
      companyKey,
      scheduledFor,
      selectedValue,
      reportFormat,
    } = scheduleReportReqBody;

    const doesInvalidEmailExist = emails.some(
      (emailId = '') => !ValidateEmail(emailId)
    );

    if (doesInvalidEmailExist) {
      setEmailError('Email is not valid.');
      setIsStoringReport(false);
      return;
    }

    if (emails.length > 20) {
      setEmailError('You added more than 20 users.');
      setIsStoringReport(false);
      return;
    }

    if (!reportFormat) {
      setError('Rport format is Required');
      setIsStoringReport(false);
      return;
    }
    const selectedCrewUids =
      reportType === 'byCrew' ? getSelectedKeys(selectedValue) : null;
    const selectedMarkersKeys =
      reportType === 'byMarker' ? getSelectedKeys(selectedValue) : null;
    const selectedPropertyKeys =
      reportType === 'byProperty' ? getSelectedKeys(selectedValue) : null;

    const newlyAddedScheduleReport = await storeSchedulingBody({
      key,
      reportName,
      reportType,
      companyKey,
      scheduledFor,
      selectedCrewUids,
      selectedMarkersKeys,
      selectedPropertyKeys,
      emailIds: emails,
    });
    toast({
      duration: 9000,
      isClosable: true,
      render: function CustomFile() {
        return (
          <Toast
            title="Success"
            status="success"
            description={renderScheduleToast(reportName, key)}
          />
        );
      },
    });
    addNewScheduleReport(newlyAddedScheduleReport, key);
    setIsStoringReport(false);
    closeScheduleDialog();
  };

  const handleReportFormat = (e, value) => {
    if (e.target.checked) {
      dispatch({ type: 'reportFormat', payload: value });
    } else {
      dispatch({ type: 'reportFormat', payload: null });
    }
  };

  const validateName = () => {
    if (!scheduleReportReqBody.reportName) return 'Report name is required.';
    return null;
  };

  const validateReportType = () => {
    if (!scheduleReportReqBody.reportType)
      return 'Report by field is required.';
    return null;
  };

  const validateSchedule = () => {
    if (!scheduleReportReqBody.scheduledFor)
      return 'Schedule for field is required.';
    return null;
  };

  const validateEmail = () => {
    if (!emails.length) return 'Email is required.';
    return null;
  };

  const removeEmail = (email) => {
    const filterEmail = emails.filter((userEmail) => {
      return userEmail !== email;
    });

    setEmails(filterEmail);
  };

  const showEmails = () => {
    return (
      <Flex gridGap="sm" flexWrap="wrap">
        {emails.map((email) => {
          return (
            <Flex
              width="fit-content"
              border="1px solid #8A91A6"
              gridGap="xxsm"
              alignItems="center"
              key="email"
              p="xxsm"
              borderRadius="sm"
              color="grey.500"
            >
              {/*  Todo uncomment Avatar later */}
              {/* <Avatar size="10" name="n" /> */}
              <Text>{email}</Text>
              <CloseIcon
                onClick={() => removeEmail(email)}
                colorScheme="800"
                cursor="pointer"
              />
            </Flex>
          );
        })}
      </Flex>
    );
  };

  const handleMultipleEmail = (event) => {
    event.preventDefault();
    if (event.key.length === 1) {
      setTab(tab + event.key);
    }
    if (['Tab', 'Enter'].includes(event.key) && tab) {
      if (!ValidateEmail(tab)) {
        setEmailError('Invalid email');
        return;
      }
      const uniqueEmail = new Set([...emails, tab]);
      const emailInArray = Array.from(uniqueEmail);
      setEmailError('');
      setEmails(emailInArray);
      setTab('');
    }

    if (event.key === 'Backspace' && tab) {
      const value = tab.slice(0, -1);
      setTab(value);
    }

    // Todo  change cursor position
    if (event.key === 'ArrowLeft' && tab) {
      const value = tab.slice(0, -1);
      setTab(value);
    }

    // Todo
    // if (event.key === 'ArrowRight' && tab) {
    //   const value = tab.slice(0, -1);
    //   setTab(value);
    // }
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleScheduleButton)}>
        <Flex direction="column" gridGap="llg">
          <Stack spacing="md">
            <CustomInput
              placeholder=" Name of the report *"
              value={scheduleReportReqBody.reportName}
              onChange={(event) => handleTextFieldChange(event, 'reportName')}
              register={{
                ...register('name', {
                  validate: validateName,
                }),
              }}
              error={errors?.name}
              errorMessage={errors?.name?.message}
            />
            <CustomInput
              value={scheduleReportReqBody.reportType}
              placeholder="Report by"
              type="children"
              register={{
                ...register('reportTypes', {
                  validate: validateReportType,
                }),
              }}
              error={errors?.reportTypes}
              errorMessage={errors?.reportTypes?.message}
            >
              <CustomSelect
                options={reportTypes}
                onChange={handleReportTypeChange}
                value={
                  scheduleReportReqBody.reportType && {
                    label: getTypeOfReport(scheduleReportReqBody.reportType),
                  }
                }
                placeholder="Report by *"
                variant="unstyled"
              />
            </CustomInput>
            <CustomInput
              value={scheduleReportReqBody.scheduledFor}
              placeholder="Scheduled for"
              type="children"
              register={{
                ...register('schedule', {
                  validate: validateSchedule,
                }),
              }}
              error={errors?.schedule}
              errorMessage={errors?.schedule?.message}
            >
              <CustomSelect
                options={schedules}
                onChange={handleScheduleChange}
                value={
                  scheduleReportReqBody.scheduledFor && {
                    label: getScheduledFor(scheduleReportReqBody.scheduledFor),
                  }
                }
                placeholder="Scheduled for *"
                variant="unstyled"
              />
            </CustomInput>
            <CustomInput
              placeholder="Email"
              ref={emailInput}
              value={tab}
              register={{
                ...register('email', {
                  validate: validateEmail,
                }),
              }}
              error={errors?.email || !!emailError}
              errorMessage={errors?.email?.message || emailError}
              onKeyDown={handleMultipleEmail}
            />
            {emails?.length && showEmails()}
            <Text opacity="0.5" fontSize="md">
              You may add 20 users at a time by pressing ‘tab’ after each email
              entry. Any duplicate and registered emails will be removed while
              registering the requested users.
            </Text>
          </Stack>

          <Stack spacing="sm">
            <Heading fontSize="lg" letterSpacing={0} fontWeight="bold">
              Report format
            </Heading>
            <Flex gridGap="llg">
              <Flex gridGap="sm">
                <Checkbox
                  onChange={(event) => handleReportFormat(event, 'pdf')}
                  isChecked={scheduleReportReqBody?.reportFormat === 'pdf'}
                >
                  PDF
                </Checkbox>
              </Flex>
              <Flex gridGap="sm">
                <Checkbox
                  onChange={(event) => handleReportFormat(event, 'xlsx')}
                  isChecked={scheduleReportReqBody?.reportFormat === 'xlsx'}
                >
                  Excel
                </Checkbox>
              </Flex>
            </Flex>
            <Text color="red.200">{error}</Text>
          </Stack>

          <Flex justify="flex-end" gridGap="4">
            <Button
              variant="ghost"
              color="primary.500"
              isDisabled={isStoringReport}
              onClick={closeScheduleDialog}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              colorScheme="blue"
              background="#006DB8 0% 0%"
              boxShadow=" 0px 3px 6px #006DB85C"
              borderRadius="5px"
              isLoading={isStoringReport}
            >
              {scheduleReportReqBody?.key ? 'Update' : 'Create Schedule report'}
            </Button>
          </Flex>
        </Flex>
      </form>

      {/* <ScheduleContainer gap="8px">
        <Col>
          <Span weight="bold">Name of the report:</Span>
          <StyledTextField
            size="small"
            variant="outlined"
            error={!!nameError}
            helperText={nameError}
            value={scheduleReportReqBody.reportName}
            onChange={(event) => handleTextFieldChange(event, 'reportName')}
          />
        </Col>

        <Col>
          <Span weight="bold">Report By:</Span>
          <CustomSelect
            required
            options={reportTypes}
            onChange={handleReportTypeChange}
            value={scheduleReportReqBody.reportType}
          />
        </Col>

        {renderReportByReportType()}

        {renderSchedulerFor()}

        {rednerEmailIdsField()}

        <Row justify="flex-end" gap="4px">
          <Button
            variant="ghost"
            color="primary.500"
            onClick={closeScheduleDialog}
          >
            Cancel
          </Button>
          <Button
            isLoading={isStoringReport}
            onClick={handleScheduleButton}
            disabled={checkIfAnythingEmpty()}
            colorScheme="blue"
            background="#006DB8 0% 0%"
            boxShadow=" 0px 3px 6px #006DB85C"
            borderRadius="5px"
          >
            Schedule
          </Button>
        </Row>
        <Row justify="flex-end">
          <Span weight="bold" color={color.red}>
            * All fields are required.
          </Span>
        </Row>
      </ScheduleContainer> */}
    </>
  );
};

const ScheduleContainer = styled(Col)`
  margin: 8px;
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-inputMarginDense {
    font-size: 14px;
    font-weight: bold;
  }
`;
