const characters =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

interface SubStringInterface {
  word: string;
  indexEnd?: number;
  indexStart: number;
}

export function lowerCase(string) {
  return string.toLowerCase();
}
export function upperCase(string) {
  return string.toUpperCase();
}

export const trimTheString = (word) => {
  return word.trim();
};

export const changeStringToNumber = (string) => {
  return Number(string) || 0;
};

const getCharacterOfAPosition = (word: string, index: number) => {
  return word.charAt(index);
};

const getSubString = ({ word, indexEnd, indexStart }: SubStringInterface) => {
  if (indexEnd) {
    return word.substring(indexStart, indexEnd);
  }
  return word.substring(indexStart);
};

export const makeFirstLetterUpperCase = (word: string) => {
  const firstCharacter = getCharacterOfAPosition(word, 0);
  const upperCaseFirstCharacter = upperCase(firstCharacter);
  const remainingCharacter = getSubString({ word, indexStart: 1 });
  const wordWithFirstCharCapitalized = `${upperCaseFirstCharacter}${remainingCharacter}`;
  return wordWithFirstCharCapitalized;
};

const getRandomNumber = (maxNumber: number) => {
  return Math.random() * maxNumber;
};

const getInteger = (randomNumber: number) => {
  return Math.floor(randomNumber);
};

export const generateRandomString = (stringLength: number) => {
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < stringLength; i += 1) {
    const randomNumber = getRandomNumber(charactersLength);
    const integerNumber = getInteger(randomNumber);
    const randomCharacter = getCharacterOfAPosition(characters, integerNumber);
    result = `${result}${randomCharacter}`;
  }
  return result;
};
