import { EmailIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Tbody,
  Td,
  Text,
  Wrap,
  useToast,
} from '@chakra-ui/react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import EmailSendOutlineIcon from 'mdi-react/EmailSendOutlineIcon';
import React, { useContext, useEffect, useState } from 'react';
import ReportSchedule from '../../../assets/svgs/ReportSchedule.svg';
import { ServiceTypesContext } from '../../../contexts/service_types';
import {
  deleteScheduledReportFromDatabase,
  getMarkersForService,
  getPropertiesOfCompany,
  getProvidersOfCompany,
  sendEmail,
} from '../../../controllers/snowm_firebase';
import { sortByEntity } from '../../../helpers/misc';
import ActionsDrawer from '../../../reusableComponent/ActionsDrawer';
import EmptyComponent from '../../../reusableComponent/EmptyComponent';
import { MainTable, MainTableRow } from '../../../reusableComponent/MainTable';
import { SideDrawer } from '../../../reusableComponent/SideDrawer';
import AlertBox from '../../Common/alert_box';
import { Toast } from '../../Common/Toast';
import Loader, { Progress } from '../../Generics/snowm_loader';
import { AddScheduledReport } from './AddScheduleReport';
import { getScheduledFor, getTypeOfReport } from './reports';

export const ScheduleReport = (props) => {
  const { subscribedServices } = useContext(ServiceTypesContext);

  const {
    isScheduleModalOpen,
    setIsScheduleModalOpen,
    scheduledReports,
    allScheduledReports,
    setScheduleReportHeader,
    setScheduledReports,
    scheduleReportHeader,
    searchText,
    setSelectedReports,
    selectedReports,
    setOpenActionDrawer,
    openActionDrawer,
  } = props;

  const [crews, setCrews] = useState(null);
  const [markers, setMarkers] = useState(null);
  const [crewUids, setCrewUids] = useState([]);
  const [markersKeys, setMarkersKeys] = useState([]);
  const [properties, setProperties] = useState(null);
  const [propertyKeys, setPropertyKeys] = useState([]);
  const [isDeletingReport, setIsDeletingReport] = useState(false);
  const [openWarningPopUp, setOpenWarningPopUp] = useState(false);
  const [selectedScheduledReport, setSelectedScheduledReport] = useState(null);

  useEffect(() => {
    let isSubscribe = true;
    if (subscribedServices.markerBased) {
      getMarkersForService('markerbased').then((res) => {
        const keysOfMarkers = res?.map((marker) => marker.key);
        const labelledMarkers = res?.map((marker) => ({
          label: marker?.name,
          value: marker?.key,
        }));
        if (isSubscribe) {
          setMarkersKeys(keysOfMarkers);
          setMarkers([
            { label: 'All', value: keysOfMarkers },
            ...labelledMarkers,
          ]);
        }
      });
    }
    return () => {
      isSubscribe = false;
    };
  }, [subscribedServices]);

  useEffect(() => {
    let isSubscribe = true;
    getProvidersOfCompany().then((res) => {
      if (isSubscribe) {
        const uidsOfCrews = res?.map((crew) => crew?.masterUid ?? crew?.uid);
        const crewsWithlabel = res?.map((crew) => ({
          label: crew?.name,
          value: crew?.masterUid ?? crew?.uid,
        }));
        setCrewUids(uidsOfCrews);
        setCrews([{ label: 'All', value: uidsOfCrews }, ...crewsWithlabel]);
      }
    });

    return () => {
      isSubscribe = false;
    };
  }, [subscribedServices]);

  useEffect(() => {
    let isSubscribe = true;

    if (subscribedServices?.properties) {
      getPropertiesOfCompany().then((res) => {
        const keysOfProperties = res.map((property) => property.id);
        const propertiesOptions = res?.map((property) => ({
          label: property?.name,
          value: property?.id,
        }));

        if (isSubscribe) {
          setPropertyKeys(keysOfProperties);
          setProperties([
            { label: 'All', value: propertyKeys },
            ...propertiesOptions,
          ]);
        }
      });
    }
    return () => {
      isSubscribe = false;
    };
  }, [subscribedServices]);

  const openSchedulingModal = () => {
    setOpenActionDrawer(false);
    setSelectedScheduledReport(null);
    setIsScheduleModalOpen(true);
  };

  const closeDialog = () => {
    setIsScheduleModalOpen(false);
    setSelectedScheduledReport(null);
  };

  const getPropertyNameById = (propertyKey) => {
    const selectedProperty = properties?.find(
      (property) => property.value === propertyKey
    );
    return selectedProperty?.label;
  };
  const getCrewNameByUid = (crewUid) => {
    const selectedCrew = crews?.find((crew) => crew.value === crewUid);
    return selectedCrew?.label;
  };

  const getMarkerNameByKey = (markerKey) => {
    const selectedMarker = markers?.find(
      (marker) => marker.value === markerKey
    );
    return selectedMarker?.label;
  };

  const getSelectedValue = ({
    selectedMarkersKeys,
    selectedCrewUids,
    selectedPropertyKeys,
    reportType,
  }) => {
    switch (reportType) {
      case 'byCrew': {
        const selectedCrews = selectedCrewUids?.map((crewUid) => ({
          value: crewUid,
          label: getCrewNameByUid(crewUid),
        }));

        return selectedCrews;
      }

      case 'byProperty': {
        const selectedProperties = selectedPropertyKeys?.map((propertyKey) => ({
          value: propertyKey,
          label: getPropertyNameById(propertyKey),
        }));
        return selectedProperties;
      }

      case 'byMarker': {
        const selectedMarkers = selectedMarkersKeys?.map((markerKey) => ({
          value: markerKey,
          label: getMarkerNameByKey(markerKey),
        }));

        return selectedMarkers;
      }

      default:
        break;
    }

    return null;
  };

  const handleEditIcon = (report) => {
    setIsScheduleModalOpen(true);
    setOpenActionDrawer(false);
    setSelectedScheduledReport({ ...report });
  };

  const handleDeleteIcon = (report) => {
    setSelectedScheduledReport(report);
    setOpenWarningPopUp(true);
  };

  const cancelReportDeletion = () => {
    setOpenWarningPopUp(false);
  };

  const deleteTheReport = async () => {
    setIsDeletingReport(true);
    await deleteScheduledReportFromDatabase(selectedScheduledReport.key);

    const remainingScheduledReport = scheduledReports?.filter(
      (report) => report.key !== selectedScheduledReport.key
    );

    setScheduledReports(remainingScheduledReport);
    setOpenWarningPopUp(false);
    setIsDeletingReport(false);
  };

  function renderScheduleToast(name) {
    return (
      <span>
        <span style={{ fontWeight: 'bold', fontSize: 18 }}>
          {name.toString()}&ensp;
        </span>
        edited successfully
      </span>
    );
  }

  const addNewSchedule = (newReport, editedReportKey) => {
    if (editedReportKey) {
      const scheduledReportWithEditReport = allScheduledReports?.reduce(
        (acc, report) => {
          if (report.key === editedReportKey) {
            return [...acc, newReport];
          }
          return [...acc, report];
        },
        []
      );
      setScheduledReports(scheduledReportWithEditReport);
    } else {
      setScheduledReports([newReport, ...allScheduledReports]);
    }
  };
  const handleCancelButton = () => {
    setIsScheduleModalOpen(false);
    setOpenActionDrawer(true);
    setSelectedScheduledReport(null);
  };

  if (!scheduledReports) {
    return <Loader />;
  }

  return (
    <Box bg="background.500" overflow="auto">
      <ScheduledReportsTable
        crews={crews}
        handleEditIcon={handleEditIcon}
        handleDeleteIcon={handleDeleteIcon}
        scheduledReports={scheduledReports}
        setIsScheduleModalOpen={setIsScheduleModalOpen}
        isScheduleModalOpen={isScheduleModalOpen}
        openActionDrawer={openActionDrawer}
        setOpenActionDrawer={setOpenActionDrawer}
        selectedScheduledReport={selectedScheduledReport}
        setScheduledReports={setScheduledReports}
        selectedReports={selectedReports}
        setSelectedReports={setSelectedReports}
        setScheduleReportHeader={setScheduleReportHeader}
        scheduleReportHeader={scheduleReportHeader}
        searchText={searchText}
      />

      <SideDrawer
        open={isScheduleModalOpen}
        title={
          selectedScheduledReport?.key
            ? 'Edit Scheduled Report'
            : 'Add Schedule Report'
        }
        maxWidth="400px"
        negativeAction={handleCancelButton}
      >
        <AddScheduledReport
          crews={crews}
          markers={markers}
          crewUids={crewUids}
          properties={properties}
          markersKeys={markersKeys}
          propertyKeys={propertyKeys}
          closeScheduleDialog={closeDialog}
          addNewScheduleReport={addNewSchedule}
          selectedScheduledReport={selectedScheduledReport}
          setScheduledReports={setScheduledReports}
        />
      </SideDrawer>

      <AlertBox
        title="Delete?"
        open={openWarningPopUp}
        handleNegativeAction={cancelReportDeletion}
        handlePositiveAction={deleteTheReport}
        subtitle="Are you sure that you want to delete the selected report?"
        isTakingAction={isDeletingReport}
      />
    </Box>
  );
};

const ScheduledReportsTable = ({
  scheduledReports,
  handleDeleteIcon,
  handleEditIcon,
  setIsScheduleModalOpen,
  openActionDrawer,
  setOpenActionDrawer,
  setScheduledReports,
  selectedReports,
  setSelectedReports,
  setScheduleReportHeader,
  scheduleReportHeader,
  searchText,
}) => {
  const [selectedReportsCount, setSelectedReportsCount] = useState(0);

  const selectedReportData = Object.values(selectedReports);

  const selectedReportToEdit = selectedReportData[0];

  useEffect(() => {
    setSelectedReportsCount(selectedReportData.length);
  }, [selectedReports]);

  function selectAllReport(e) {
    if (e.target.checked) {
      const checkedReports = scheduledReports?.reduce((reports, report) => {
        if (report.key) {
          reports[report.key] = report;
        }
        return reports;
      }, {});
      setSelectedReports({ ...checkedReports });
    } else {
      setSelectedReports({});
    }
  }

  async function handleMultipleDeleteButton(report) {
    try {
      await deleteScheduledReportFromDatabase(report.key);
      setIsScheduleModalOpen(false);
      setOpenActionDrawer(false);
      setSelectedReports({});
    } catch (error) {
      console.error('@@Error', error);
    }
  }

  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const sendEmaiWithPdf = async (scheduledReport) => {
    setIsSendingEmail(true);
    try {
      await sendEmail(scheduledReport);
    } catch (error) {
      console.error({ error });
    }
    setIsSendingEmail(false);
  };

  const renderWithRespecToSendingEmail = () =>
    isSendingEmail ? (
      <Progress size={15} />
    ) : (
      <EmailSendOutlineIcon fontSize="small" />
    );

  const actions = [
    {
      title: 'Edit',
      onClick: (param) => handleEditIcon(param),
      icon: <EditIcon fontSize="small" />,
    },
    {
      title: 'Delete',
      onClick: (param) => handleDeleteIcon(param),
      icon: <DeleteIcon fontSize="small" />,
    },
    {
      title: 'Send now',
      onClick: (param) => sendEmaiWithPdf(param),
      icon: renderWithRespecToSendingEmail(),
    },
  ];

  function showButtons() {
    return (
      <Wrap>
        <Button
          size="sm"
          colorScheme="blue"
          border="2px solid #006DB8"
          leftIcon={<EmailIcon />}
          variant="outline"
          isLoading={isSendingEmail}
          onClick={() => {
            sendEmaiWithPdf(selectedReportToEdit);
          }}
        >
          Send Email
        </Button>
        <Button
          size="sm"
          colorScheme="blue"
          border="2px solid #006DB8"
          leftIcon={<EditIcon />}
          variant="outline"
          onClick={() => {
            handleEditIcon(selectedReportToEdit);
          }}
        >
          Edit
        </Button>
      </Wrap>
    );
  }

  const handleSorting = (header, headerIndex) => {
    const { sort, name: fieldName } = header;
    const newSort = sort === 'desc' ? 'asc' : 'desc';

    const copiedScheduledReports = [...scheduledReports];

    switch (fieldName) {
      case 'Report Name':
        copiedScheduledReports?.sort((a, b) =>
          sortByEntity(a.reportName, b.reportName, newSort)
        );
        break;

      case 'Report Type':
        copiedScheduledReports?.sort((a, b) => {
          const firstReportType = a.reportType;
          const secondReportType = b.reportType;
          return sortByEntity(firstReportType, secondReportType, newSort);
        });
        break;

      case 'Scheduled For':
        copiedScheduledReports?.sort((a, b) => {
          return sortByEntity(a.scheduledFor, b.scheduledFor, newSort);
        });
        break;

      case 'Emails':
        copiedScheduledReports?.sort((a, b) => {
          return sortByEntity(a.emailIds[0], b.emailIds[0], newSort);
        });
        break;

      default:
        break;
    }

    const updatedHeaders = [...scheduleReportHeader];
    updatedHeaders.splice(headerIndex, 1, {
      name: fieldName,
      sort: newSort,
    });
    setScheduleReportHeader(updatedHeaders);
    setScheduledReports(copiedScheduledReports);
  };

  if (scheduledReports?.length === 0) {
    return (
      <Box h="500px" bgColor="white">
        <EmptyComponent
          header="Scheduled Reports"
          title="Schedule your report here"
          subTitle="Click + Add schedule report button on the right top to create your first Scheduled report "
          isSearch={!!searchText}
          zeroStateImage={ReportSchedule}
        />
      </Box>
    );
  }

  return (
    <Box>
      <ActionsDrawer
        openActionDrawer={openActionDrawer}
        selectedEntities={Object.values(selectedReports).map((e) => {
          return { key: e.key, name: e.reportName };
        })}
        setSelectedEntities={setSelectedReports}
        showButtons={showButtons}
        showDeleteButton
        handleDeleteEntities={(i) => handleMultipleDeleteButton(i)}
        entity="Scheduled Reports"
      />
      <MainTable
        headers={scheduleReportHeader}
        onSelectedEntities={(e) => selectAllReport(e)}
        checked={selectedReportsCount === scheduledReports.length}
        handleSorting={handleSorting}
      >
        <Tbody bg="white" boxShadow="0px 12px 24px #ECEEF5" borderRadius="16px">
          {scheduledReports?.map((report) => (
            <ScheduledReportDetail
              key={report.key}
              report={report}
              handleDeleteIcon={handleDeleteIcon}
              handleEditIcon={handleEditIcon}
              selectedReports={selectedReports}
              setSelectedReports={setSelectedReports}
              setIsScheduleModalOpen={setIsScheduleModalOpen}
              setOpenActionDrawer={setOpenActionDrawer}
            />
          ))}
        </Tbody>
      </MainTable>
    </Box>
  );
};

const ScheduledReportDetail = ({
  report,
  setSelectedReports,
  selectedReports,
  setIsScheduleModalOpen,
  setOpenActionDrawer,
}) => {
  const { reportName, reportType, scheduledFor, emailIds } = report;

  const selectReport = (selectedReport) => {
    setOpenActionDrawer(true);
    const { key } = selectedReport;
    if (selectedReports[key]) {
      const { [key]: value, ...remainingReports } = selectedReports;
      setSelectedReports({ ...remainingReports });
    } else {
      setSelectedReports({
        ...selectedReports,
        [key]: { ...selectedReport },
      });
    }
  };

  return (
    <MainTableRow>
      <Td>
        <Flex gridGap="md">
          <Checkbox
            isChecked={!!selectedReports[report.key ?? '']}
            onChange={() => selectReport(report)}
            borderColor="primary.500"
            bg="white"
          />
          <Text>{reportName}</Text>
        </Flex>
      </Td>
      <Td>{getTypeOfReport(reportType)}</Td>
      <Td>{getScheduledFor(scheduledFor)}</Td>
      <Td textAlign="right">{emailIds?.join(', ') ?? '-'}</Td>
    </MainTableRow>
  );
};
