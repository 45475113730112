import { extendTheme } from '@chakra-ui/react';

const chakraTheme = extendTheme({
  space: {
    xxsm: '4px',
    xsm: '8px',
    sm: '12px',
    md: '16px',
    lg: '24px',
    llg: '28px',
    xl: '32px',
    xxl: '42px',
    xxxl: '3rem',
  },
  sizes: {
    lg: '602px',
  },
  colors: {
    highlight: { 200: '#FFFFFF', 300: '#000000', 500: '#162350' },
    primary: {
      200: '#65CAF0',
      300: ' #85B9DD',
      400: '#006EB8',
      500: '#187CBE',
      600: '#17244FFA',
      700: '#00AEEF',
      900: '#006DB8',
    },
    text: { 700: '#64686D', 800: '17244f' },
    background: {
      500: '#FBF9FB',
      600: '#F1F1F1',
      700: '#F4F4F4',
      800: '#f2f8fb',
    },
    red: { 200: '#ff0000' },
    blue: { 200: '#0000FF' },
    grey: { 200: '#808080', 300: '#17244F', 500: '#8A91A6', 600: '#AEB0B5' },
    green: { 200: '#0EA90F', 300: '#00754E' },
    black: { 200: '#505050' },
  },
  fonts: {
    p: 'Montserrat',
    body: 'Montserrat',
    text: 'Montserrat',
    heading: 'Montserrat',
  },
  fontSizes: {
    xsm: '8px',
    mdsm: '14px',
    md: '1rem',
    mdl: '24px',
    lg: '30px',
    xl: '36px',
  },
  fontWeights: {
    semibold: 600,
    extrabold: 800,
    bold: 900,
  },
  radii: {
    sm: '6px',
    lg: '16px',
  },
  components: {
    Text: {
      baseStyle: {
        color: 'highlight.500',
        fontFamily: 'Montserrat',
        fontWeight: 400,
      },
    },
    Switch: {
      baseStyle: {
        track: {
          _focus: {
            boxShadow: 'none',
          },
        },
      },
    },
    Button: {
      defaultProps: {
        size: 'md',
      },
      baseStyle: {
        _focus: {
          boxShadow: 'none',
        },
      },
    },
    Heading: {
      baseStyle: {
        fontSize: 20,
        fontWeight: 600,
        color: 'highlight.500',
        fontFamily: 'Montserrat',
      },
    },

    Drawer: {
      variants: {
        alwaysOpen: {
          dialog: {
            pointerEvents: 'auto',
          },
          dialogContainer: {
            pointerEvents: 'none',
          },
        },
      },
    },
  },
});

export const size = {
  icon: '24px',
};
export default chakraTheme;
