import React, { useReducer, useContext, useEffect, useState } from 'react';

import Select from 'react-select';
import styled from 'styled-components';
import { Card } from '@material-ui/core';
import { useHistory } from 'react-router';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';

import Button from './FetchButton';
import Span from '../../Common/Span';
import color from '../../../utils/color';
import Loader from '../../Generics/snowm_loader';
import MarkerLogDetails from '../markers/MarkerLogDetails';
import { Row, Title, Col } from '../../../styles/snowm_styled';
import { ServiceTypesContext } from '../../../../contexts/service_types';
import { getMarkerLogsForMarkers } from '../../../../controllers/snowm_firebase';

const LogDetail = () => {
  const defaultLoading = {
    fetching: false,
    moreFetching: false,
  };
  const data = useContext(ServiceTypesContext);
  const history = useHistory();

  const [propertiesOptions, setPropertiesOptions] = useState();
  const [selectedProperty, setSelectedProperty] = useState();
  const [markersOptions, setMarkersOptions] = useState();
  const [selectedMarkers, setSelectedMarkers] = useState();
  const [markerLogs, setMarkerLogs] = useState();
  const [loading, setLoading] = useState(defaultLoading);

  function reducer(state, action) {
    switch (action.type) {
      case 'startDate':
        return { ...state, startDate: action.payload };
      case 'endDate':
        return { ...state, endDate: action.payload };
      default:
        throw new Error();
    }
  }

  const initialState = {
    startDate: null,
    endDate: null,
  };
  const [date, dispatch] = useReducer(reducer, initialState);

  const { properties, markers } = data;

  useEffect(() => {
    let isCancel = false;
    const markersKeys = markers?.map((marker) => marker.key);

    if (markersKeys) {
      getMarkerLogsForMarkers(markersKeys).then((res) => {
        if (!isCancel) {
          setMarkerLogs(res);
        }
      });
    }

    return () => {
      isCancel = true;
    };
  }, [markers]);

  useEffect(() => {
    const optionsProperties = properties?.map((property) => {
      return { label: property.name, value: property.id };
    });

    setPropertiesOptions(optionsProperties);
  }, [properties]);

  useEffect(() => {
    if (selectedProperty) {
      const options = markers?.reduce((acc, marker) => {
        if (marker.propertyKey === selectedProperty) {
          return [...acc, { value: marker.key, label: marker.name }];
        }
        return acc;
      }, []);

      setMarkersOptions(options);
    }
  }, [selectedProperty]);

  function handlePropertiesChange(selectedOption) {
    setSelectedProperty(selectedOption?.value);
    setSelectedMarkers();
    setMarkerLogs();
  }

  function handleMarkersChange(selectedOptions) {
    setMarkerLogs();
    setSelectedMarkers(selectedOptions);
  }

  function handleDateChange(selectedDate, type) {
    dispatch({ type, payload: selectedDate });
  }

  const Markers = () => {
    return (
      <Col>
        <Span weight="bold" color={color.grey}>
          Select Markers
        </Span>
        <Select
          placeholder="Markers"
          isMulti
          value={selectedMarkers}
          options={markersOptions}
          onChange={handleMarkersChange}
        />
      </Col>
    );
  };

  function getMarkersKeys() {
    return selectedMarkers?.map((marker) => marker?.value);
  }

  function checkForError() {
    return (
      !selectedProperty || !selectedMarkers || !date.startDate || !date.endDate
    );
  }

  async function handleButtonClick(e) {
    e.stopPropagation();
    setLoading({ ...loading, fetching: true });
    const markersKeys = getMarkersKeys();
    try {
      const response = await getMarkerLogsForMarkers(markersKeys, date);

      setMarkerLogs(response);
    } catch (error) {
      console.error(error);
    }

    setLoading(defaultLoading);
  }

  async function handleMoreButton(e) {
    e.stopPropagation();
    setLoading({ ...loading, moreFetching: true });
    const dateOfLastMarkerLog = markerLogs
      ? markerLogs[(markerLogs?.length ?? 0) - 1]?.startDate
      : null;

    const markersKeys = getMarkersKeys();

    try {
      const response = await getMarkerLogsForMarkers(markersKeys, {
        endDate: dateOfLastMarkerLog,
        startDate: date?.endDate,
      });

      setMarkerLogs([...markerLogs, ...response]);
    } catch (error) {
      console.error(error);
    }

    setLoading(defaultLoading);
  }

  function handleMarkerLogClick(markerLog) {
    history.push(
      `/customer/servicelogs/details/${markerLog.jobKey}/${markerLog.key}`
    );
  }

  const markersLogsDetails = () => {
    if (!markerLogs) return null;

    if (markerLogs?.length === 0) {
      return (
        <Row justify="center">
          <Title>No marker logs.</Title>
        </Row>
      );
    }

    return (
      <>
        <hr />

        <Title>Marker Log Details</Title>

        <MarkerLogsContainer>
          {markerLogs?.map((log) => {
            return (
              <MarkerLogDetails
                markerLog={log}
                key={log.key}
                handleCardClick={() => handleMarkerLogClick(log)}
              />
            );
          })}
        </MarkerLogsContainer>

        <Button
          handleButtonClick={handleMoreButton}
          loading={loading.moreFetching}
          text="More Logs"
        />
      </>
    );
  };

  if (!properties) return <Loader />;

  return (
    <LogContainer>
      <Title margintop="0">Logs Details</Title>
      <CardContainer>
        <StyledDetail>
          <Col>
            <Span weight="bold" color={color.grey}>
              Select Property
            </Span>
            <Select
              placeholder="Property"
              options={propertiesOptions}
              onChange={handlePropertiesChange}
            />
          </Col>
          <Markers />

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              disableFuture
              label="Start Date"
              onChange={(d) => handleDateChange(d, 'startDate')}
              value={date.startDate}
              format="yyyy/MM/DD hh:mm A"
            />
            <DateTimePicker
              disableFuture
              label="End Date"
              onChange={(d) => handleDateChange(d, 'endDate')}
              value={date.endDate}
              format="yyyy/MM/DD hh:mm A"
            />
          </MuiPickersUtilsProvider>
          <Span size="12px" color={color.grey}>
            * All fields are required.
          </Span>
          <Button
            loading={loading.fetching}
            emptyFieldExist={checkForError()}
            text="Get Markers Logs"
            handleButtonClick={handleButtonClick}
          />
        </StyledDetail>
      </CardContainer>

      {markersLogsDetails()}
    </LogContainer>
  );
};

export default LogDetail;

export const LogContainer = styled.div`
  padding: 12px;
`;

const CardContainer = styled(Card)`
  && {
    padding: 16px;
    min-height: 350px;
    width: 350px;
  }
`;

const StyledDetail = styled.div`
  display: grid;
  grid-row-gap: 16px;
  padding: 16px;
`;

export const MarkerLogsContainer = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`;
