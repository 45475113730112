/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: index.jsx
 *
 *  Description: Defines Routes for Service Points
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 9-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import { Switch, Route, Redirect } from 'react-router-dom';

import MicroLocationDetails from './micro_location_details';
import MicroLocations from './snowm_micro_locations';
import AddMicroLocation from './snowm_add_micro_locations';
import { ServicePointsContext } from '../../../contexts/service_points';

const MicroLocationRoutes = () => (
  <Switch>
    <Route
      path="/admin/micro-locations/add"
      exact
      component={AddMicroLocation}
    />
    <Route
      path="/admin/micro-locations/edit/:uid"
      exact
      component={(props) => (
        <ServicePointsContext.Consumer>
          {({ servicePoints, spLoading, fetchServicePoints }) => {
            if (spLoading === null) {
              fetchServicePoints();
            }
            return (
              <AddMicroLocation edit servicePoints={servicePoints} {...props} />
            );
          }}
        </ServicePointsContext.Consumer>
      )}
    />
    <Route
      path="/admin/micro-locations/:microLocationKey"
      exact
      component={MicroLocationDetails}
    />
    <Route path="/admin/micro-locations/" component={MicroLocations} />
    <Redirect to="/admin/micro-locations" />
  </Switch>
);

export default MicroLocationRoutes;
