import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  Td,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  CircularProgress,
  Divider,
  InputAdornment,
  Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import countriesInfo from 'countries-information';
import { isEmpty } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaPlusCircle } from 'react-icons/fa';
import 'react-phone-number-input/style.css';
import Select from 'react-select';
import styled from 'styled-components';
import EmptyJanitorImage from '../../../assets/svgs/EmptyJanitorImage.svg';
import { AuthContext } from '../../../contexts/auth_context';
import { LocalizationContext } from '../../../contexts/localization_context';
import { ServicePContext } from '../../../contexts/service_provider';
import {
  createCrew,
  deleteProvider,
  disableCrewAccount,
  enableCrewAccount,
  getCompanyDetail,
  getDataByName,
  getOrganizationalCrew,
  getScheduleOfJanitors,
  resetCrewDeviceId,
  sendPasswordResetEmail,
  uploadImageToStorage,
} from '../../../controllers/snowm_firebase';
import { getDateForJob } from '../../../helpers/date';
import {
  filterDataByName,
  findSortType,
  sortByEntity,
} from '../../../helpers/misc';
import { CustomInput } from '../../../reusableComponent/CustomInput';
import { DetailsDrawer } from '../../../reusableComponent/DetailsDrawer';
import EmptyComponent from '../../../reusableComponent/EmptyComponent';
import ImageContainer from '../../../reusableComponent/ImageContainer';
import { MainTableRow } from '../../../reusableComponent/MainTable';
import SDialog from '../../../reusableComponent/SDialog';
import { MarkerOfAJob } from '../../../reusableComponent/shiftSchedule/JobsTable';
import { SideDrawer } from '../../../reusableComponent/SideDrawer';
import { Row } from '../../../styles/snowm_styled';
import color from '../../../utils/color';
import NotifyBox from '../../Common/notify_box';
import PrimaryButton from '../../Common/primary_button';
import SearchBar from '../../Common/Search_Bar';
import Span from '../../Common/Span';
import { Toast } from '../../Common/Toast';
import Loader from '../../Generics/snowm_loader';
import { useModal } from '../../Generics/snowm_modal';
import { StyledTextField } from '../styled';
import DeleteDialog from '../tasks/DeleteTaskDialog';
import CrewsTable, { deleteJanitorList } from './CrewsTable';
import { ValidateEmail } from '../../../helpers/snowm_validators';

const crewEnabledMsg = 'Crew account enabled.';
const crewDisabledMsg = 'Crew account disabled.';
const crewResetLoggedIn = 'Logged In device reset.';
const archiveDeleteError = 'Cannot take the action. Some error occured.';
const resetEmailSuccessMsg =
  'Reset email link sent to the email address of the crew.';
const resetEmailErrorMsg =
  "Reset email can't be sent to the email address of the crew.";
const filters = ['All', 'Active', 'Disabled'];

export const scheduleHeader = [
  {
    name: 'Shifts',
    sort: 'asc',
    checkBox: true,
  },
  {
    name: 'Status',
    sort: 'asc',
    view: true,
  },

  {
    name: 'Start Time - End Time',
    sort: 'asc',
    view: true,
  },
  {
    name: 'MicroLocations',
    sort: 'asc',
  },
];

const headers = [
  { name: 'Name', sort: 'asc' },
  { name: 'Last Served', sort: 'asc' },
];
const JanitorsConsumer = (normalProps) => (
  <ServicePContext.Consumer>
    {({ fetchProviders, providers, providersLoading, locations }) => (
      <Providers
        fetchProviders={fetchProviders}
        providers={providers}
        providersLoading={providersLoading}
        locations={locations}
        {...normalProps}
      />
    )}
  </ServicePContext.Consumer>
);

const allCountries = countriesInfo.getAllCountries();

const countriesDetails = allCountries.reduce((all, country) => {
  const dialingCode = country.countryCallingCodes[0] ?? '';
  if (!dialingCode || !country.emoji) {
    return all;
  }
  all.push({
    value: dialingCode,
    label: `${country.name} (${dialingCode})`,
  });
  return all;
}, []);

const uniqueCountryCode = [...new Set(countriesDetails)];

uniqueCountryCode.sort((a, b) => a - b);

export const Providers = ({
  fetchProviders,
  providers,
  vendors,
  typeOfCrew,
}) => {
  const toast = useToast();
  const tabs = {
    EMAIL: 'Login With Email',
    PHONENUMBER: 'Login With Phone',
  };
  const tabsKeys = Object.keys(tabs);

  const { register, handleSubmit, errors, setValue, watch, unregister, reset } =
    useForm();
  const notifyBoxModal = useModal();

  const [action, setAction] = useState();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [crewId, setCrewId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [allCrews, setAllCrews] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [sortType, setSortType] = useState('asc');
  const [noOrgCrew, setNoOrgCrew] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [anchorEl, setAnchorEl] = useState(undefined);
  const [filteredCrew, setFilteredCrew] = useState([]);
  const [companyDetail, setCompanyDetail] = useState();
  const [isSearching, setIsSearching] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [filterValue, setFilterValue] = useState('All');
  const [tabValue, setTabValue] = useState('individual');
  const [addressError, setAddressError] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState({});
  const [selectedJanitors, setSelectedJanitors] = useState({});
  const [openProviderDetail, setOpenProviderDetail] = useState(false);
  const [openActionDrawer, setOpenActionDrawer] = useState(true);
  const [isActionPerforming, setIsActionPerforming] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [openDisableConfirmationDialog, setOpenDisableConfirmationDialog] =
    useState(false);
  const [actionsLoading, setActionsLoading] = useState(false);
  const [janitorHeaders, setJanitorHeaders] = useState(headers);

  const [flag, setFlag] = useState('ca');
  const [crewsName, setCrewsName] = useState({
    names: [],
    nameExist: false,
  });
  const [imageUrl, setImageUrl] = useState(null);
  const [image, setImage] = useState(null);

  const [emailPhoneError, setEmailPhoneError] = useState('');
  const [noEmailAndPhoneNumberError, setNoEmailAndPhoneNumberError] =
    useState(false);

  const [emailPhoneTabValue, setEmailPhoneTabValue] = useState('EMAIL');

  const { strings } = useContext(LocalizationContext);

  const watchEmailAddress = watch('email', selectedProvider?.email ?? null);
  const watchImage = watch('image');
  const photoInputRef = useRef(null);

  const watchPhoneNumber = watch(
    'phoneNumber',
    selectedProvider?.phoneNumber ?? null
  );

  useEffect(() => {
    if (selectedProvider?.dialingCode) {
      const countries = allCountries.find(
        (c) => c.countryCallingCodes[0] === selectedProvider?.dialingCode
      );
      const country = (countries?.alpha2 ?? ' ').toLowerCase();
      setFlag(country);
    }
  }, [selectedProvider?.dialingCode]);

  const registerValues = (values) => {
    values.forEach((value) => {
      register({ name: value });
    });
  };

  useEffect(() => {
    registerValues(['longitude', 'latitude', 'uid', 'disabled']);
  }, []);

  useEffect(() => {
    if (open) {
      setValue('countryCode', '1');
    }
  }, [open]);

  useEffect(() => {
    setValue('longitude', selectedProvider?.longitude ?? 0.0);
    setValue('latitude', selectedProvider?.latitude ?? 0.0);
    setValue('uid', selectedProvider?.uid ?? null);
    setValue('disabled', selectedProvider?.disabled ?? false);
    if (selectedProvider?.crewId) {
      const arrayOfCrewId = selectedProvider?.crewId?.split(
        companyDetail?.companyCode
      );
      if (arrayOfCrewId?.length > 1) {
        const crewName = arrayOfCrewId[1];
        setValue('crewId', crewName);
      }
    }
  }, [selectedProvider, companyDetail]);

  useEffect(() => {
    let isCancel = false;
    if (fetchProviders) {
      fetchProviders();
    }
    if (typeOfCrew) {
      setTabValue(typeOfCrew);
    }
    getCompanyDetail().then((res) => {
      if (!isCancel) {
        setCompanyDetail(res ?? {});
      }
    });
    return () => {
      isCancel = true;
    };
  }, []);

  function makeCrewsWithUids(crews) {
    return crews?.reduce((acc, curr) => ({ ...acc, [curr.uid]: curr }), {});
  }

  function sortCrewByOrder() {
    if (providers === null) {
      return null;
    }
    const { sort } = findSortType(janitorHeaders, 'Name');
    const crews = Object.values(providers ?? {});
    const sortedCrews = crews?.sort((a, b) =>
      sortByEntity(a.name, b.name, sort)
    );
    let filteredCrews = sortedCrews;
    if (filterValue === 'Disabled') {
      filteredCrews = sortedCrews?.filter((crew) => crew.disabled);
    } else if (filterValue === 'Active') {
      filteredCrews = sortedCrews?.filter((crew) => !crew.disabled);
    }
    const crewsAccordingToUid = makeCrewsWithUids(filteredCrews);
    return crewsAccordingToUid;
  }

  useEffect(() => {
    let filteredJanitor = {};
    const orderedCrews = sortCrewByOrder() ?? {};
    setAllCrews(orderedCrews);
    if (isSearch) {
      filteredJanitor = filterDataByName(searchText, orderedCrews);
      setFilteredCrew(Object.values(filteredJanitor) ?? []);
    } else {
      setFilteredCrew(Object.values(orderedCrews) ?? []);
    }
  }, [sortType, providers, filterValue, searchText]);

  function handleCloseDialog() {
    setOpenActionDrawer(true);
    setSelectedProvider({});
    setCrewsName((prevState) => ({
      ...prevState,
      nameExist: false,
    }));
    setOpen(false);
    unregister('name');
    unregister('email');
    unregister('phoneNumber');
  }

  function getCrewType() {
    if (openEdit && selectedProvider) {
      return selectedProvider?.crewType ?? null;
    }

    if (tabValue === 'individual') {
      return 'Individual';
    }
    return 'Org';
  }

  function getCrewId(idOfCrew) {
    if (openEdit && selectedProvider) {
      return selectedProvider?.crewId ?? null;
    }

    if (!idOfCrew) {
      return null;
    }
    return `${companyDetail?.companyCode}${idOfCrew}`;
  }

  const getCountryCode = (crewFormData) => {
    if (crewFormData?.phoneNumber) {
      return crewFormData?.countryCode;
    }
    return null;
  };

  const getDialingCode = (crewFormData) => {
    if (crewFormData.phoneNumber) {
      return crewFormData.dialingCode;
    }
    return null;
  };

  const getEmailAddress = (email) => {
    if (emailPhoneTabValue === 'EMAIL') return email;
    return null;
  };

  const getPhoneNumber = (phoneNo) => {
    if (emailPhoneTabValue === 'PHONENUMBER') return phoneNo;
    return null;
  };

  async function handlePositiveAction() {
    setAddressError('');

    setNoEmailAndPhoneNumberError(false);
    if (
      emailPhoneTabValue === 'EMAIL' &&
      !watchEmailAddress &&
      emailPhoneTabValue === 'PHONENUMBER' &&
      !watchPhoneNumber
    ) {
      setNoEmailAndPhoneNumberError(true);
      return;
    }

    setCrewsName({ ...crewsName, nameExist: false });
    const data = { ...selectedProvider };
    setError(false);
    if (!data.name) {
      return;
    }
    const trimmedName = data.name.trim();

    const crewBySameName = await getDataByName('providers', trimmedName);

    if (!selectedProvider && crewBySameName) {
      setCrewsName({ ...crewsName, nameExist: true });
      return;
    }

    if (
      crewBySameName &&
      selectedProvider &&
      selectedProvider?.uid !== crewBySameName?.uid
    ) {
      setCrewsName({ ...crewBySameName, nameExist: true });
      return;
    }

    const crewType = getCrewType();
    const idOfCrew = getCrewId(data?.crewId);
    const profileImageUrl = image
      ? await uploadImageToStorage(image, image.name)
      : data.imageUrl;

    let requestData = {
      ...data,
      crewType,
      name: trimmedName,
      crewId: idOfCrew,
      countryCode: getCountryCode(data),
      dialingCode: getDialingCode(data),
      email: getEmailAddress(data.email),
      phoneNumber: getPhoneNumber(data.phoneNumber),
      loginMode: emailPhoneTabValue,
      imageUrl: profileImageUrl,
    };
    setLoading(true);

    if (tabValue === 'organization') {
      const masterUid = data.uid;
      const masterCompanyKey = selectedProvider.companyKey;
      const companyKey = companyDetail.key;
      const uid = `${masterUid}_${companyDetail?.key}`;
      requestData = {
        ...requestData,
        token: '',
        ...selectedProvider,
        masterUid,
        uid,
        masterCompanyKey,
        companyKey,
      };
    }
    try {
      const httpsResponse = await createCrew(requestData);

      if (httpsResponse?.data?.success) {
        setLoading(false);
        renderJanitorToast();
        setSelectedProvider({
          countryCode: 'CA',
          dialingCode: '+1',
        });
        setSelectedJanitors({});
      }

      if (!httpsResponse?.data?.success) {
        toast({
          status: 'error',
          description: httpsResponse?.data?.message,
          title: 'Error!',
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (openEdit || tabValue === 'organization') {
        handleCloseDialog();
        setLoading(false);
        setSelectedJanitors({});
        return;
      }
      handleCloseDialog();
    } catch (e) {
      toast({
        status: 'error',
        description:
          e?.data?.message ?? openEdit
            ? 'Unable to edit janitor.'
            : 'Unable to create janitor.',
        title: 'Error!',
        duration: 3000,
        isClosable: true,
      });
    }
    setLoading(false);
  }

  function renderJanitorToast() {
    return toast({
      duration: 3000,
      isClosable: true,
      render: function CustomFile() {
        return (
          <Toast
            title="Success"
            status="success"
            description={renderJanitorName()}
          />
        );
      },
    });
  }
  function renderJanitorName() {
    return (
      <span>
        Janitor&ensp;
        <span style={{ fontWeight: 'bold', fontSize: 18 }}>
          {selectedProvider?.name}&ensp;
        </span>
        {`${openEdit ? 'updated' : 'created'}`} successfully
      </span>
    );
  }
  useEffect(() => {
    const names = Object.values(allCrews ?? {}).map((crew) =>
      crew?.name?.toLowerCase()
    );
    setCrewsName((prevState) => ({
      ...prevState,
      names,
    }));
  }, [allCrews]);

  function addProvider() {
    setSelectedProvider({
      countryCode: 'CA',
      dialingCode: '+1',
    });
    setEmailPhoneTabValue('EMAIL');
    // setSelectedJanitors({});
    setOpenActionDrawer(false);
    setLoading(false);
    setEmailExists(false);
    setError(false);
    setTabValue(typeOfCrew ?? 'individual');
    setOpenEdit(false);
    setNoOrgCrew(null);
    setOpen(true);
    setNoEmailAndPhoneNumberError(false);
  }

  const editProviderModal = (provider) => {
    setOpenActionDrawer(false);
    setSelectedProvider(provider);
    setOpenEdit(true);
    setOpen(true);
  };

  async function deleteProviderData() {
    try {
      await deleteProvider(selectedProvider);
    } catch (e) {
      console.error('@@Error', e);
    }
  }

  const showProviderDetail = (p) => {
    setSelectedProvider(p);
    setOpenProviderDetail(true);
    setOpenActionDrawer(false);
  };

  const handleProviderDetailClose = () => {
    setOpenProviderDetail(false);
    setOpenActionDrawer(true);
  };

  const handleSearchInput = async (items) => {
    setSearchText(items);
    const filteredCrewList = filterDataByName(items, allCrews);
    setIsSearching(true);
    setFilteredCrew(Object.values(filteredCrewList) ?? []);
    setIsSearching(false);
    setIsSearch(true);
  };

  function handleOnFocus(event) {
    event.stopPropagation();
    setEmailExists(false);
  }

  const getCompanyCode = () => {
    if (!companyDetail) {
      return <CircularProgress size={20} />;
    }
    return companyDetail?.companyCode ?? '';
  };

  function handleCrewIdChange(e) {
    const { value } = e.target;
    if (tabValue === 'organization') {
      setCrewId(value);
    }
  }

  const crewIdField = () => (
    <StyledTextField
      required={!watchEmailAddress}
      variant="outlined"
      placeholder="Janitor Id"
      inputProp
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {tabValue !== 'organization' && getCompanyCode()}
          </InputAdornment>
        ),
      }}
      inputRef={register}
      name="crewId"
      error={!!errors.crewId}
      helperText={errors?.crewId?.message}
      onChange={handleCrewIdChange}
      disabled={tabValue === 'organization' && selectedProvider}
    />
  );

  const handleEmailPhoneTabChange = (newValue) => {
    if (newValue === 'EMAIL' && !watchPhoneNumber) {
      unregister('phoneNumber');
    }

    if (newValue !== 'EMAIL' && !watchEmailAddress) {
      unregister('email');
    }
    setEmailPhoneTabValue(newValue);
  };

  const renderCrewAddForm = () => {
    function openBrowseWindow() {
      photoInputRef.current.click();
    }
    const handleInput = (e) => {
      const { name, value } = e.target;
      setSelectedProvider({
        ...selectedProvider,
        [name]: value,
      });
    };

    const handleCountryCode = (selectedDialingCode) => {
      const selectedCountry = allCountries.find((country) => {
        return country.countryCallingCodes.includes(selectedDialingCode.value);
      });
      setSelectedProvider({
        ...selectedProvider,
        dialingCode: selectedDialingCode.value,
        countryCode: selectedCountry?.alpha2,
      });
    };

    const renderImage = () => {
      const handleImageChange = (e) => {
        if (e.target.files.length !== 0) {
          const file = e.target.files[e.target.files.length - 1];
          const url = URL.createObjectURL(file);
          setImageUrl(url);
          setImage(file);
          setValue('image', file);
        }
      };

      const handleRemoveImage = () => {
        // TODO: remove imageUrl from janitor document too
        setValue('image', null);
        setImageUrl(null);
      };
      return (
        <Flex gridGap="2" align="center" marginBottom="23px">
          <ImageContainer
            size="100"
            onPress={openBrowseWindow}
            externalRef={photoInputRef}
            onChange={(e) => handleImageChange(e)}
            imageURL={imageUrl ?? selectedProvider?.imageUrl}
            // imageURL={URL.createObjectURL(watchImage)}
          />

          <Flex gridGap="2" wrap="wrap">
            <Button
              variant="outline"
              colorScheme="blue"
              width="149px"
              height="40px"
              onClick={() => photoInputRef?.current?.click()}
            >
              Choose Image
            </Button>
            {(watchImage || selectedProvider?.imageUrl) && (
              <Button
                variant="outline"
                colorScheme="blue"
                width="149px"
                height="40px"
                onClick={handleRemoveImage}
              >
                Remove Image
              </Button>
            )}
          </Flex>
        </Flex>
      );
    };

    const validateJanitorEmail = () => {
      if (selectedProvider?.email && !ValidateEmail(selectedProvider?.email)) {
        return 'Invalid email pattern.';
      }
      if (!selectedProvider?.email && !selectedProvider?.phoneNumber) {
        return 'Email is required.';
      }
      return null;
    };

    const validateJanitorPhoneNumber = () => {
      if (
        emailPhoneTabValue === 'PHONENUMBER' &&
        !selectedProvider?.phoneNumber
      ) {
        return 'Phone number is required.';
      }
      return null;
    };

    return (
      <form onSubmit={handleSubmit(handlePositiveAction)}>
        <Flex direction="column">
          {error && (
            <Text color="red">
              Something went wrong while creating crew account.
            </Text>
          )}
          {selectedProvider.uid ? renderImage() : null}
          <CustomInput
            type="text"
            name="name"
            error={errors?.name || crewsName?.nameExist}
            onChange={handleInput}
            placeholder="Janitor name *"
            value={selectedProvider?.name}
            defaultValue={selectedProvider?.name}
            isDisabled={tabValue === 'organization'}
            register={{
              ...register('name', {
                required: !selectedProvider?.name,
                pattern: {
                  value: /^[^!@#$%^&*()_+~|}<>?:]+$/,
                  message: 'Name should contain alphabets or with numbers.',
                },
              }),
            }}
          />

          <Text color={color.red}>
            {errors?.name?.type === 'required' && 'Janitor name is required.'}
            {errors?.name?.type === 'pattern' &&
              'Name should contain alphabets or with numbers.'}
          </Text>

          {crewsName.nameExist && (
            <Text color={color.red}>Name already exists</Text>
          )}

          <RadioGroup
            onChange={handleEmailPhoneTabChange}
            value={emailPhoneTabValue}
            spacing="4"
            marginTop="24px"
          >
            <Text fontSize="22px" fontWeight={600} marginBottom="15px">
              Login Method
            </Text>
            <Stack direction="row">
              {tabsKeys?.map((label) => (
                <Radio value={label} key={label}>
                  <Text
                    fontWeight="medium"
                    fontSize="15px"
                    letterSpacing="0.26px"
                    color="grey.500"
                  >
                    {tabs[label]}
                  </Text>
                </Radio>
              ))}
            </Stack>
          </RadioGroup>

          <Box marginTop="14px">
            {emailPhoneTabValue === 'EMAIL' && (
              <>
                <CustomInput
                  placeholder="Email *"
                  onChange={handleInput}
                  value={selectedProvider?.email}
                  name="email"
                  register={
                    emailPhoneTabValue === 'EMAIL' && {
                      ...register('email', {
                        validate: validateJanitorEmail,
                      }),
                    }
                  }
                  error={errors.email}
                  errorMessage={errors?.email?.message}
                  defaultValue={selectedProvider?.email}
                  onFocus={handleOnFocus}
                  isDisabled={tabValue === 'organization'}
                />

                {emailExists && (
                  <Text color={color.red} weight="bold">
                    Email already exists.
                  </Text>
                )}
              </>
            )}

            {emailPhoneTabValue === 'PHONENUMBER' && (
              <Flex alignItems="center" gridGap="xsm">
                <Select
                  options={countriesDetails}
                  styles={{
                    menu: (provided, _) => ({
                      ...provided,
                      width: 400,
                      background: 'white',
                    }),
                    control: () => ({
                      width: 100,
                      display: 'flex',
                      border: 'solid 1px #ECEEF5',
                      borderRadius: 5,
                      height: '41px',
                    }),
                  }}
                  value={{
                    label: (
                      <img
                        src={`https://flagcdn.com/24x18/${flag}.png`}
                        alt="country-flag"
                      />
                    ),
                    value: selectedProvider?.dialingCode,
                  }}
                  onChange={handleCountryCode}
                />
                <CustomInput
                  type="number"
                  placeholder="Phone number"
                  name="phoneNumber"
                  countryCode={selectedProvider?.dialingCode ?? '+1'}
                  value={selectedProvider?.phoneNumber}
                  onChange={handleInput}
                  register={
                    emailPhoneTabValue === 'PHONENUMBER' && {
                      ...register('phoneNumber', {
                        validate: validateJanitorPhoneNumber,
                      }),
                    }
                  }
                  error={errors.phoneNumber}
                  errorMessage={errors?.phoneNumber?.message}
                />
              </Flex>
            )}
            {noEmailAndPhoneNumberError && (
              <Span color={color.red}>
                Either email or phone number is required.
              </Span>
            )}
          </Box>

          <Flex justify="flex-end" gridGap="md" marginTop="32px">
            <Button
              variant="ghost"
              color="primary.500"
              isDisabled={loading}
              onClick={handleCloseDialog}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              colorScheme="blue"
              background="#006DB8 0% 0%"
              boxShadow=" 0px 3px 6px #006DB85C"
              isLoading={loading}
              borderRadius="5px"
            >
              {openEdit ? 'Update' : 'Invite Janitor'}
            </Button>
          </Flex>
        </Flex>
      </form>
    );
  };

  async function handleGetCrew(e) {
    e.stopPropagation();
    setNoOrgCrew(null);
    setLoading(true);
    try {
      const organizationalCrew = await getOrganizationalCrew(crewId);
      if (organizationalCrew) {
        setSelectedProvider(organizationalCrew);
      } else {
        setNoOrgCrew('No organizational crew with this id is found.');
      }
    } catch (err) {
      setNoOrgCrew('Organizational crew with this id already exists.');
    }
    setLoading(false);
  }
  const renderOrganization = () =>
    selectedProvider && tabValue === 'organization' ? (
      <> {renderCrewAddForm()} </>
    ) : (
      <Stack gridGap="12px">
        {crewIdField()}
        <PrimaryButton loading={loading} onClick={handleGetCrew}>
          <Row gap="4px" marginbottom="0">
            {loading && <CircularProgress size={15} color="inherit" />}
            <Text color={color.white}>Get Crew</Text>
          </Row>
        </PrimaryButton>
        {noOrgCrew ? <Text color={color.red}>{noOrgCrew}</Text> : null}
      </Stack>
    );

  function handleSortChange() {
    let newSortType = '';
    if (sortType === 'asc') {
      newSortType = 'desc';
    } else {
      newSortType = 'asc';
    }
    setSortType(newSortType);
  }

  function handleFilterChange(event) {
    setFilterValue(event.target.value);
  }

  const showAllCrews = () => {
    const sortedCrew = sortCrewByOrder();
    setFilteredCrew(sortedCrew);
    setIsSearch(false);
  };

  const closeAlertDialog = () => {
    setOpenDeleteConfirmDialog(false);
    setOpenActionDrawer(true);
  };

  function handleNegativeAction() {
    setAction(null);
  }

  const getMessage = () => {
    if (alert === 'Delete') {
      return 'Crew deleted.';
    }
    return 'Crew archived.';
  };

  function handleCloseDisableConfirmation() {
    setOpenDisableConfirmationDialog(false);
    setAction(null);
  }

  async function handleConfirmation(
    actionForCrew = null,
    janitor = selectedProvider ?? null
  ) {
    const { masterUid, uid } = janitor;
    setLoading(true);
    setActionsLoading({ [actionForCrew]: true });

    setIsActionPerforming({ [masterUid ?? uid]: true });
    // TODO: discuss this, how to handle setState of action
    const alert = action ?? actionForCrew;

    if (alert === 'Archive' || alert === 'Delete') {
      const response = await deleteProvider(janitor);
      if (!response?.data?.success) {
        toast({
          duration: 3000,
          status: 'success',
          isClosable: true,
          description: `Janitor ${alert}d`,
          title: 'Success',
        });
        return;
      }

      const updatedCrew = { ...janitor };

      updatedCrew.archived = true;
      setLoading(false);
      setActionsLoading(false);
      handleProviderDetailClose();
      setIsActionPerforming(null);
      toast({
        duration: 3000,
        status: 'success',
        isClosable: true,
        description: `Janitor ${alert}d`,
        title: 'Success',
      });
      return;
    }
    switch (alert) {
      case 'Reset Password':
        try {
          sendPasswordResetEmail(janitor?.email);
          toast({
            duration: 3000,
            status: 'success',
            isClosable: true,
            description: `Janitor ${janitor.name} password reset sucessfully.`,
            title: 'Success',
          });
        } catch (e) {
          toast({
            duration: 3000,
            status: 'error',
            isClosable: true,
            description: `Janitor ${janitor.name} password reset failed`,
            title: 'Error!',
          });
        }
        break;

      case 'Disable': {
        const response = await disableCrewAccount(janitor);
        if (!response?.data?.success) {
          toast({
            duration: 3000,
            status: 'error',
            isClosable: true,
            description: `Janitor ${janitor.name} disabled failed`,
            title: 'Error!',
          });
        } else if (response?.data?.success) {
          const updatedCrew = { ...janitor, disabled: true };
          setSelectedProvider(updatedCrew);
          toast({
            duration: 3000,
            status: 'success',
            isClosable: true,
            description: `Janitor ${janitor.name} disabled successfully`,
            title: 'Success',
          });
          handleCloseDisableConfirmation();
        }

        break;
      }

      case 'Reset Logged in Device': {
        await resetCrewDeviceId(janitor);
        const updatedCrew = { ...janitor, deviceId: null };

        setSelectedProvider(updatedCrew);
        toast({
          duration: 3000,
          status: 'success',
          isClosable: true,
          description: `Janitor${janitor.name} device has been reset.`,
          title: 'Success',
        });
        break;
      }

      case 'Enable': {
        const response = await enableCrewAccount(janitor);
        if (!response?.data?.success) {
          toast({
            duration: 3000,
            status: 'error',
            isClosable: true,
            description: `Janitor${janitor.name} enabled failed.`,
            title: 'Error!',
          });
        } else {
          const updatedCrew = { ...janitor, disabled: false };
          setSelectedProvider(updatedCrew);
          toast({
            duration: 3000,
            status: 'success',
            isClosable: true,
            description: `Janitor ${janitor.name} enabled successfully`,
            title: 'Success',
          });
        }
        break;
      }

      default:
        break;
    }
    setOpenDeleteConfirmDialog(false);
    setSelectedJanitors({});
    setIsActionPerforming(null);
    setLoading(false);
    setActionsLoading(false);
    setAnchorEl(undefined);
    setAction(null);
  }

  const handleCrewAction = (crew, actionForCrew) => {
    setSelectedProvider(crew);
    switch (actionForCrew) {
      case 'Reset Password':
        setOpenDeleteConfirmDialog(true);
        break;
      case 'Disable':
        setOpenDisableConfirmationDialog(true);
        break;

      default:
        handleConfirmation(actionForCrew, crew);
        break;
    }
  };
  const auth = useContext(AuthContext);

  async function handleMultipleDelete(crew) {
    setLoading(true);
    const response = await deleteProvider({
      uid: crew.key,
      companyKey: auth.companyKey,
    });

    if (!response?.data?.success) {
      toast({
        duration: 3000,
        status: 'error',
        isClosable: true,
        description: `Cannot take the action`,
        title: 'Error!',
      });

      return;
    }
    setLoading(false);
    handleProviderDetailClose();
    setIsActionPerforming(null);
  }

  const handleSnackbarClose = () => {
    setEmailPhoneError('');
  };

  const searchJanitor = (e) => {
    setSearchText(e.target.value);
    setIsSearch(true);
  };

  const onEditIconPress = (crew) => {
    if (!crew) return;
    if (tabValue !== 'organization') {
      editProviderModal(crew);
      setEmailPhoneTabValue(
        crew.loginMode ?? crew.email ? 'EMAIL' : 'PHONENUMBER'
      );
    }
  };
  const renderDetails = () => (
    <JanitorDetails janitorDetails={selectedProvider} />
  );
  const detailTabs = {
    Details: renderDetails(),
    Schedule: 'To be designed',
  };

  if (!allCrews) {
    return <Loader />;
  }
  if (allCrews) {
    return (
      <Box bg="background.500" overflow="auto" p="12">
        {/* <Row>
          <StyledTitle>{vendors ? 'Vendors' : 'Janitors'}</StyledTitle>
        </Row>
        <Header
          heading={`Search ${crewOrVendor()}`}
          subheading={`Search by ${crewOrVendor()} name`}
          placeholder={`${vendors ? 'Vendor' : 'Janitor'} name`}
          handleSearch={(items) => handleSearchInput(items)}
          paddingbottom="1em"
          isSearching={isSearching}
          indexName="crews"
          localSearch
          reset={!isSearch}
        >
          <Tooltip title="Sort">
            <IconButton onClick={handleSortChange}>
              {sortType === 'asc' ? (
                <SortBoolAscendingVariantIcon size={30} />
              ) : (
                <SortBoolDescendingVariantIcon size={30} />
              )}
            </IconButton>
          </Tooltip>
          <StyledTextField
            style={{ marginRight: 4 }}
            select
            value={filterValue}
            label={<Span weight="bold">Filter by</Span>}
            onChange={handleFilterChange}
          >
            {filters.map((filteringValue) => (
              <MenuItem key={filteringValue} value={filteringValue}>
                <Span>{filteringValue}</Span>
              </MenuItem>
            ))}
          </StyledTextField>
        </Header> */}
        <Flex direction="column" gridGap="llg">
          <Flex justifyContent="space-between">
            <Heading as="h4">Janitors</Heading>
            <Button
              onClick={addProvider}
              colorScheme="blue"
              leftIcon={<FaPlusCircle />}
              paddingLeft="xsm"
            >
              <Text color="white" fontWeight="600">
                Invite Janitors
              </Text>
            </Button>
          </Flex>
          <SearchBar
            title="Janitor"
            LeftIcon={<SearchIcon />}
            onChange={(e) => searchJanitor(e)}
            setSelectedEntities={setSelectedJanitors}
            selectedEntities={selectedJanitors}
          />
        </Flex>
        {!filteredCrew.length ? (
          <EmptyComponent
            isSearch={!!searchText}
            title="Manage your janitors here"
            header="Janitor"
            subTitle="Click + invite Janitor button on the right top to invite your first Janitor"
            zeroStateImage={EmptyJanitorImage}
          />
        ) : (
          <CrewsTable
            vendors={!!vendors}
            loading={isActionPerforming}
            onEditIconPress={onEditIconPress}
            crews={filteredCrew}
            openActionDrawer={openActionDrawer}
            selectedJanitors={selectedJanitors}
            showProviderDetail={showProviderDetail}
            setOpenActionDrawer={setOpenActionDrawer}
            setSelectedJanitors={setSelectedJanitors}
            handleMultipleDelete={handleMultipleDelete}
            actionsLoading={actionsLoading}
            selectedProvider={selectedProvider}
            handleCrewAction={(crew, actionForCrew) => {
              setAction(actionForCrew);
              handleCrewAction(crew, actionForCrew);
            }}
            setJanitorHeaders={setJanitorHeaders}
            setFilteredCrew={setFilteredCrew}
            janitorHeaders={janitorHeaders}
          />
        )}

        <SideDrawer
          open={open}
          edit={openEdit}
          loading={loading}
          negativeAction={handleCloseDialog}
          title={openEdit ? 'Edit Janitor' : 'Invite Janitor'}
          // handlePositiveAction={handleSubmit(handlePositiveAction)}
          // organizationalCrew={tabValue === 'organization' && !selectedProvider}
        >
          {tabValue === 'individual' ? (
            <> {renderCrewAddForm()} </>
          ) : (
            <>{renderOrganization()}</>
          )}
        </SideDrawer>

        {/* <DetailsDrawer
          isOpen={openProviderDetail}
          title="tilte"
          onClose={handleProviderDetailClose}
          detailTabs={detailTabs}
          subtitle="subtitle"
          body={() => {}}
          deleteSubtitle="delete "
          details={selectedProvider}
        /> */}
        <DetailsDrawer
          open={openProviderDetail}
          title={`${selectedProvider?.name}
        `}
          // subtitle={propertyDetails.address}
          image={selectedProvider?.imageUrl}
          details={selectedProvider}
          onClose={handleProviderDetailClose}
          detailTabs={detailTabs}
          // hideEditDeleteButton
          width="54px"
          // openActionDrawer={openActionDrawer}
          onEdit={onEditIconPress}
          onDelete={handleMultipleDelete}
          setOpenDeleteDialog={setOpenActionDrawer}
        />
        {/* <ProviderDetail
          closeProviderDetailCard={handleProviderDetailClose}
          providerDetail={selectedProvider}
          handleCrewAction={handleCrewAction}
          crewsActionAnchorEl={anchorEl}
          isPerformingAction={
            isActionPerforming?.[
              selectedProvider?.masterUid ?? selectedProvider?.uid
            ]
          }
        /> */}

        {/* <Drawer
          isOpen={openProviderDetail}
          placement="right"
          size="lg"
          onClose={handleProviderDetailClose}
        >
          <DrawerOverlay />
          <DrawerContent>
            <Flex justifyContent="space-between">
              <Flex gridGap="md" paddingLeft="25px" paddingTop="15px">
                <Image
                  src={selectedProvider?.imageUrl}
                  objectFit="cover"
                  height="75px"
                  width="75px"
                />
                <Stack>
                  <Text
                    textAlign="left"
                    letterSpacing="0px"
                    color="#17244F"
                    style={{ font: 'normal normal 600 25px/40px Montserrat' }}
                  >
                    {selectedProvider?.name}
                  </Text>
                  <Text>{selectedProvider?.designation ?? '--'}</Text>
                </Stack>
              </Flex>
              <Button
                backgroundColor="transparent"
                _hover={{ backgroundColor: 'transparent' }}
                onClick={handleProviderDetailClose}
                leftIcon={<CloseIcon />}
              />
            </Flex>

            <DrawerBody>
              <Box paddingTop="15px">
                <Divider />
                <Tabs
                  paddingTop="10px"
                  _selected={{ color: 'black' }}
                  boxShadow="none"
                >
                  <TabList
                    style={{ font: ' normal normal 600 22px/27px Montserrat' }}
                    boxShadow="none"
                    textAlign="left"
                    letterSpacing="0px"
                  >
                    <Tab
                      _selected={{
                        font: ' normal normal 600 22px/27px Montserrat',
                        color: '#006DB8',
                      }}
                      style={{
                        font: ' normal normal 600 22px/27px Montserrat',
                      }}
                    >
                      Details
                    </Tab>
                    <Tab
                      _selected={{
                        font: ' normal normal 600 22px/27px Montserrat',
                        color: '#006DB8',
                      }}
                      style={{
                        font: ' normal normal 600 22px/27px Montserrat',
                      }}
                    >
                      Schedule
                    </Tab>
                  </TabList>

                  <TabPanels>
                    <TabPanel>
                 
                      <ProviderDetail
                        closeProviderDetailCard={handleProviderDetailClose}
                        providerDetail={selectedProvider}
                        handleCrewAction={handleCrewAction}
                        crewsActionAnchorEl={anchorEl}
                        isPerformingAction={
                          isActionPerforming?.[
                            selectedProvider?.masterUid ?? selectedProvider?.uid
                          ]
                        }
                      />
                    </TabPanel>
                    <TabPanel>
                      <MainTable headers={scheduleHeader}>
                        <ShowSchedule selectedProvider={selectedProvider} />
                      </MainTable>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </DrawerBody>
          </DrawerContent>
        </Drawer> */}

        <SDialog
          size="md"
          height="fit-content"
          positiveLabel="Ok"
          positiveButtonColor="#006DB8"
          open={openDeleteConfirmDialog}
          handleNegativeAction={() => null}
          handlePositiveAction={handleConfirmation}
          hideNegativeAction
          title="Reset Password"
        >
          <Text fontSize="14px">
            An email has been sent to{' '}
            <span style={{ fontWeight: 600 }}>{selectedProvider.email}</span> to
            reset the password
          </Text>
        </SDialog>

        <SDialog
          size="md"
          loading={loading}
          height="fit-content"
          positiveLabel="Ok"
          positiveButtonColor="#006DB8"
          open={openDisableConfirmationDialog}
          handleNegativeAction={handleCloseDisableConfirmation}
          handlePositiveAction={handleConfirmation}
          title={`Disable ${selectedProvider.name} `}
        >
          <Text fontSize="14px">
            Are you sure you want to disable{' '}
            <span style={{ fontWeight: 600 }}>{selectedProvider.name}</span>?
          </Text>
        </SDialog>

        <DeleteDialog
          open={action === 'Delete'}
          handleCancel={handleNegativeAction}
          selectedEnitities={Object.values(selectedJanitors)}
          listsComponent={() => deleteJanitorList(selectedJanitors)}
          handleDelete={handleConfirmation}
          entity="Janitor"
        />
        <Snackbar
          open={!!emailPhoneError}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
        >
          <Alert severity="error">
            <Span>{emailPhoneError}</Span>
          </Alert>
        </Snackbar>
        {isEmpty(allCrews) && (
          <StyledContainer justifycontent="center">
            {!vendors ? '' : 'No vendors added.'}
          </StyledContainer>
        )}
        <NotifyBox notifyBoxModal={notifyBoxModal} message="Success" />
      </Box>
    );
  }
  return <Loader />;
};

export const ShowSchedule = ({ selectedProvider }) => {
  const [selectedJob, setSelectedJob] = useState([]);

  useEffect(() => {
    if (selectedProvider?.uid) {
      getScheduleOfJanitors(selectedProvider?.uid).then((job) =>
        setSelectedJob(job)
      );
    }
  }, []);
  const renderTimeStampForJob = (actualJob) => {
    const startDate = actualJob?.startedDate
      ? getDateForJob(actualJob.startedDate)
      : '-';
    const endedDate = actualJob?.endDate
      ? getDateForJob(actualJob.endDate)
      : '-';
    return (
      <>
        <Flex justifyContent="space-between">
          <Text>{startDate}</Text>
        </Flex>
        <Flex justifyContent="space-between">
          <Text>{endedDate}</Text>
        </Flex>
      </>
    );
  };

  return selectedJob?.length ? (
    selectedJob?.map((job) => (
      <MainTableRow key={job.key}>
        <Td>
          <Text>{job?.name ?? '--'}</Text>
        </Td>
        <Td>
          <Text>{job?.status ?? '--'}</Text>
        </Td>
        <Td>
          <Text>{renderTimeStampForJob(job)}</Text>
        </Td>
        <MarkerOfAJob job={job} />
      </MainTableRow>
    ))
  ) : (
    <p>No Data</p>
  );
};
const JanitorDetails = ({ janitorDetails }) => {
  return (
    <Box>
      <Header>Login method</Header>

      <Text>{janitorDetails?.loginMode ?? '--'} </Text>
      <Divide />
      <Header>Phone</Header>
      <Text> {janitorDetails?.phoneNumber ?? '--'}</Text>
      <Divide />
      <Header>Email</Header>
      <Text>{janitorDetails?.email ?? '--'} </Text>
      <Divide />
    </Box>
  );
};
const Divide = styled(Divider)`
  margin: 10px 0px;
`;
const Header = styled(Text)`
  text-align: left;
  font: normal normal 600 16px/30px Montserrat;
  letter-spacing: 0.26px;
  color: #162350;
  opacity: 1;
  // padding-top: 10px;
`;
const StyledContainer = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifycontent || 'space-between'};
  align-items: center;
`;

export default JanitorsConsumer;
