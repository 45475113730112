import { useState } from 'react';

import { TriangleDownIcon } from '@chakra-ui/icons';
import { Box, Flex, Text } from '@chakra-ui/react';
import MomentUtils from '@date-io/moment';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { withStyles } from '@material-ui/styles';
import moment from 'moment';

interface DatePickerProps {
  endTime: string;
  startTime: string;
  handleDataChange: (date: MaterialUiPickersDate, type: string) => void;
}
export const DatePickers = ({
  startTime,
  endTime,
  handleDataChange,
}: DatePickerProps) => {
  const [openStartPicker, setOpenStartPicker] = useState(false);
  const [openEndPicker, setOpenEndPicker] = useState(false);

  function getMomentObject(time: MaterialUiPickersDate | string) {
    if (time) {
      return moment(time, 'HH:mm a');
    }
    return null;
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Flex>
        <Box mr={2}>
          <CssTimeField
            required
            minutesStep={15}
            label={
              <Text fontSize={16} color="grey">
                Select start time *
              </Text>
            }
            inputVariant="outlined"
            open={openStartPicker}
            onClick={() => setOpenStartPicker(true)}
            onClose={() => setOpenStartPicker(false)}
            value={getMomentObject(startTime)}
            onChange={(date) => handleDataChange(date, 'startTime')}
            keyboardIcon={
              <TriangleDownIcon
                style={{ width: '12px', height: '7px', marginRight: '-10px' }}
              />
            }
            inputProps={{
              style: {
                borderRight: '1px solid #D3D3D3',
              },
            }}
          />
        </Box>
        <Text mt={3} fontSize={16} color="grey">
          to
        </Text>
        <Box ml={2}>
          <CssTimeField
            required
            onClick={() => setOpenEndPicker(true)}
            open={openEndPicker}
            onClose={() => setOpenEndPicker(false)}
            minutesStep={15}
            label={
              <Text fontSize={16} color="grey">
                Select end time *
              </Text>
            }
            inputVariant="outlined"
            value={getMomentObject(endTime)}
            onChange={(date) => handleDataChange(date, 'endTime')}
            keyboardIcon={
              <TriangleDownIcon
                style={{
                  width: '12px',
                  height: '7px',
                  marginRight: '-10px',
                }}
              />
            }
            inputProps={{
              style: {
                borderRight: '1px solid #D3D3D3',
              },
            }}
          />
        </Box>
      </Flex>
    </MuiPickersUtilsProvider>
  );
};

export const CssTimeField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#006DB8',
    },
    '& .MuiInputBase-input': {
      fontSize: '16px',
      padding: '14px 14.5px',
    },
    '& .MuiFormLabel-asterisk': {
      display: 'none',
    },
    '& .chakra-text css-wca5hg': {
      margin: '0px',
    },
    '&.MuiIconButton-root:hover': {
      background: 'transparent',
    },
    '&.MuiFormControl-root': {
      width: 'fit-content',
    },
    '& .MuiInput-underline:after': {
      border: '1px solid #006DB8',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#D3D3D3',
      },
      '&:hover fieldset': {
        borderColor: '#D3D3D3',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#D3D3D3',
      },
    },
  },
})(KeyboardTimePicker);
