import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { Card } from '@material-ui/core';
import ArrowLeft from 'mdi-react/ArrowLeftBoldIcon';
import { useParams, useHistory } from 'react-router';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from '@material-ui/lab';

import Span from '../../Common/Span';
import RequestItem from './RequestItem';
import color from '../../../utils/color';
import { Grid } from '../../Common/Grid';
import Button from '../serviceLogs/FetchButton';
import Loader from '../../Generics/snowm_loader';
import { getFormattedDate } from '../../../helpers/date';
import ListTitleItem from '../../Generics/list_title_item';
import { Col, Row, Title } from '../../../styles/snowm_styled';
import {
  getOrderProgress,
  getOrderDetail,
} from '../../../../controllers/snowm_firebase';

const WorkOrderDetails = () => {
  const params = useParams();

  const [progressDetails, setProgressDetails] = useState();
  const [serviceRequest, setServiceRequest] = useState();

  const history = useHistory();
  const { markerKey, orderId } = params;

  useEffect(() => {
    getOrderProgress(markerKey, orderId).then((res) => {
      setProgressDetails(res);
    });
    getOrderDetail(markerKey, orderId).then((res) => {
      setServiceRequest(res);
    });
  }, [markerKey, orderId]);

  if (!progressDetails || !serviceRequest) {
    return <Loader />;
  }

  if (progressDetails?.length === 0) {
    return (
      <NoReportContainer justify="center">
        <Span>No progress reports for the order.</Span>
      </NoReportContainer>
    );
  }

  return (
    <Container>
      <Button
        handleButtonClick={() => history.goBack()}
        text={<ArrowLeft />}
        anchor="left"
      />
      <Title>Service Request Details</Title>
      <Grid>
        <Row>
          <RequestItem request={serviceRequest} onDetailPage />
        </Row>
      </Grid>
      <StyledCard>
        <Timeline>
          {progressDetails?.map((progress) => {
            return (
              <TimelineItem key={progress.date}>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Col>
                    <ListTitleItem
                      title={progress.status}
                      content={getFormattedDate(progress.date)}
                    />
                    {progress.message && (
                      <Span color={color.grey}>{progress.message}</Span>
                    )}
                  </Col>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      </StyledCard>
    </Container>
  );
};

export default WorkOrderDetails;

const Container = styled(Col)`
  margin: 12px;
  gap: 12px;
`;

const StyledCard = styled(Card)`
  padding: 8px;
`;

const NoReportContainer = styled(Row)`
  height: 100%;
`;
