import * as React from 'react';
import { Button, Divider } from '@material-ui/core';

import Span from '../../Components/Common/Span';
import { Row } from '../../styles/snowm_styled';
import PrimaryButton from '../../Components/Common/primary_button';
import { CustomSelect } from './custom_select';
import { CustomInput } from '../CustomInput';

export const defaultPeriodicTimeSchedule = {
  hours: null,
  minutes: null,
  weekDays: [],
  hoursData: null,
  minutesData: null,
  weekDaysData: null,
};

// eslint-disable-next-line import/prefer-default-export
export const PeriodicJobTimeSelection = ({
  hoursData,
  minutesData,
  periodicSchedule,
  weekDaysData,
  handleSelection,
  handleCancel,
}) => {
  const [periodicData, setPeriodicData] = React.useState(
    defaultPeriodicTimeSchedule
  );

  React.useEffect(() => {
    setPeriodicData(periodicSchedule);
  }, [periodicSchedule]);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleSelection(periodicData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row justify="center">
        <Span weight="bold" size="24px">
          Schedule
        </Span>
      </Row>
      <div style={{ marginTop: '1em' }}>
        <CustomInput
          type="children"
          placeholder="Hours*"
          value={
            periodicData.jobhoursData?.length ? periodicData.jobhoursData : null
          }
        >
          <CustomSelect
            defaultValue={periodicData.jobhoursData}
            value={
              periodicData.jobhoursData?.length
                ? periodicData.jobhoursData
                : null
            }
            placeholder="Hours*"
            isMulti
            name="hours"
            options={hoursData}
            onChange={(hours) => {
              const data = hours && hours.map((hour) => hour.value);
              setPeriodicData({
                ...periodicData,
                hours: data,
                jobhoursData: hours,
              });
            }}
            required={!periodicData?.hours?.length}
          />
        </CustomInput>
      </div>
      <div style={{ marginTop: 12, marginBottom: 10 }}>
        <CustomInput
          type="children"
          placeholder="Minutes*"
          value={
            periodicData.jobminutesData?.length
              ? periodicData.jobminutesData
              : null
          }
        >
          <CustomSelect
            defaultValue={periodicData.jobminutesData ?? []}
            value={
              periodicData.jobminutesData?.length
                ? periodicData.jobminutesData
                : null
            }
            placeholder="Minutes*"
            isMulti
            name="minutes"
            options={minutesData}
            onChange={(minutes) => {
              const data = minutes && minutes.map((minute) => minute.value);
              setPeriodicData({
                ...periodicData,
                minutes: data,
                jobminutesData: minutes,
              });
            }}
            required={!periodicData?.minutes?.length}
          />
        </CustomInput>
      </div>

      <div style={{ marginBottom: '1em' }}>
        <CustomInput
          type="children"
          placeholder="Week days*"
          value={
            periodicData.jobWeekDaysData?.length
              ? periodicData.jobWeekDaysData
              : null
          }
        >
          <CustomSelect
            defaultValue={periodicData.jobWeekDaysData}
            value={
              periodicData.jobWeekDaysData?.length
                ? periodicData.jobWeekDaysData
                : null
            }
            placeholder="Week days*"
            isMulti
            name="weekDays"
            options={weekDaysData}
            onChange={(weekDays) => {
              const data = weekDays && weekDays.map((weekDay) => weekDay.value);
              setPeriodicData({
                ...periodicData,
                weekDays: data,
                jobWeekDaysData: weekDays,
              });
            }}
          />
        </CustomInput>
      </div>

      <Divider />

      <Row justify="flex-end">
        <Button style={{ textTransform: 'none' }} onClick={handleCancel}>
          Cancel
        </Button>
        <PrimaryButton type="submit">Done</PrimaryButton>
      </Row>
    </form>
  );
};
