/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: Jobs/index.jsx
 *
 *  Description: Index file that contains routes for jobs
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 12-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Shifts from './Schedule';
import { JobLogs } from '../../../reusableComponent/shiftSchedule/JobsLogs';
import JobDetail from '../../../reusableComponent/shiftSchedule/job_detail';
import LogDetailOfAMarker from '../../../reusableComponent/shiftSchedule/log_detail_of_marker';
import IndoorJobRouteDetail from '../../../reusableComponent/shiftSchedule/indoor_route_job_detail';
import OutdoorJobRouteDetail from '../../../reusableComponent/shiftSchedule/outdoor_route_job_detail';
import { ServiceTypesContext } from '../../../contexts/service_types';

const ScheduleRoutes = () => {
  const { companyServices } = useContext(ServiceTypesContext);

  if (companyServices?.length <= 0) {
    return <Redirect to="/" />;
  }

  return (
    <Switch>
      <Route path="/admin/jobs/details/:shiftId" component={JobDetail} />
      <Route
        path="/admin/shifts/details/:jobKey/logs"
        exact
        component={JobLogs}
      />
      <Route
        path="/admin/shifts/details/:shiftId"
        exact
        component={JobDetail}
      />
      <Route
        path="/admin/shifts/details/:jobKey/indoor/route/:routeKey"
        exact
        component={IndoorJobRouteDetail}
      />
      <Route
        path="/admin/shifts/details/:jobKey/outdoor/route/:routeKey"
        exact
        component={OutdoorJobRouteDetail}
      />
      <Route
        path="/admin/shifts/details/:jobKey/marker/:markerKey" // TODO: change marker to microLocations
        exact
        component={LogDetailOfAMarker}
      />
      <Route path="/admin/schedule/" exact component={Shifts} />

      <Redirect to="/admin/schedule" />
    </Switch>
  );
};

export default ScheduleRoutes;
