/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: Jobs/index.jsx
 *
 *  Description: Index file that contains routes for jobs
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 12-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Jobs from './snowm_jobs';

const JobsRoutes = () => {
  return (
    <Switch>
      <Route path="/customer/jobs/" component={Jobs} />
      <Redirect to="/customer/jobs" />
    </Switch>
  );
};

export default JobsRoutes;
