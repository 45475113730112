import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Box,
  Text,
  Flex,
  Heading,
  Button,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getCompanyDetail } from '../../../controllers/snowm_firebase';
import { Col, Row } from '../../../styles/snowm_styled';
import Span from '../../Common/Span';
import Loader from '../../Generics/snowm_loader';
import GeneralSettings from './GeneralSettings';

const settings = {
  general: 'General',
};

const Settings = () => {
  const [company, setCompany] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    let isCancel = false;
    getCompanyDetail().then((res) => {
      if (!isCancel) {
        setCompany(res);
      }
    });
    return () => {
      isCancel = true;
    };
  }, []);

  const handleTabChange = (val) => {
    setTabValue(val);
  };

  const renderSettingsTab = (index) => {
    switch (index) {
      case 0:
        return <GeneralSettings company={company} setCompany={setCompany} />;

      default:
        return null;
    }
  };

  if (!company) {
    return <Loader />;
  }
  return (
    <Container className="11" gap="8px">
      <StyledRow>
        <Heading as="h4">Settings</Heading>
      </StyledRow>
      {/* <Tabs onChange={handleTabChange} colorScheme="black">
          <TabList>
            {Object.keys(settings).map((r) => {
              return <Tab>{settings[r]}</Tab>;
            })}
          </TabList>

          <TabPanels>
          </TabPanels>
        </Tabs> */}
      {Object.keys(settings).map((r, i) => {
        return <Box key={r}>{renderSettingsTab(i)}</Box>;
      })}
    </Container>
  );
};

const ButtonContainer = styled(Col)`
  margin: 8px 0 0 16px;
`;

const Container = styled(Col)`
  margin: 16px;
`;

const StyledRow = styled(Row)`
  margin: 8px 16px;
`;
export default Settings;
