import React, { useReducer, useState, useEffect } from 'react';

import styled from 'styled-components';
import { TextField, MenuItem } from '@material-ui/core';

import FormModal from '../../Common/form_modal';
import { addRequest } from '../../../../controllers/snowm_firebase';
import { getFirstLetterCapitilizedWord } from '../../../helpers/misc';

const AddServiceRequest = ({
  markers,
  customerUid,
  open,
  handleCancel,
  handleAddedOrEditedRequest,
  selectedRequest,
}) => {
  const initialArg = {
    id: null,
    markerKey: null,
    companyKey: null,
    customerUid: null,
    jobKey: null,
    routeKey: null,
    requestNote: null,
    status: null,
    requestedDate: null,
    urgency: 'HIGH',
    preferredTime: '',
  };

  const urgencies = ['HIGH', 'MEDIUM', 'LOW'];

  function reducer(state, action) {
    const { type, payload } = action;
    if (type !== 'reset' && type !== 'edit') {
      return { ...state, [type]: payload };
    }
    switch (type) {
      case 'reset':
        return { ...initialArg, customerUid };
      case 'edit': {
        return { ...state, ...payload };
      }
      default:
        throw new Error();
    }
  }

  const [requestData, dispatch] = useReducer(reducer, initialArg);

  const [selectedMarker, setSelectedMarker] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch({ type: 'customerUid', payload: customerUid });
  }, []);

  useEffect(() => {
    if (!open) {
      setOpenDialog(false);
    } else {
      setOpenDialog(true);
    }
  }, [open]);

  function findMarkerByKey(eachMarker) {
    return eachMarker.key === selectedRequest.markerKey;
  }

  useEffect(() => {
    if (selectedRequest) {
      dispatch({ type: 'edit', payload: selectedRequest });
      const marker = markers?.find(findMarkerByKey);
      setSelectedMarker(marker);
    } else {
      dispatch({ type: 'reset' });
      setSelectedMarker(null);
    }
  }, [selectedRequest]);

  useEffect(() => {
    if (!selectedMarker) {
      return;
    }
    dispatch({ type: 'companyKey', payload: selectedMarker?.companyKey });
    dispatch({ type: 'markerKey', payload: selectedMarker?.key });
  }, [selectedMarker]);

  function handleMarkerChange(event) {
    const marker = event.target.value;
    setSelectedMarker(marker);
  }

  function handleNote(event) {
    dispatch({ type: 'requestNote', payload: event.target.value });
  }

  function handlePreferredTimeChange(event) {
    dispatch({ type: 'preferredTime', payload: event.target.value });
  }

  function handleCancelButton() {
    handleCancel();
  }

  async function handlePositive(event) {
    event.preventDefault();
    setLoading(true);
    try {
      const newRequest = await addRequest(requestData);
      handleAddedOrEditedRequest(newRequest);
      handleCancel();
      dispatch({ type: 'reset' });
      setSelectedMarker(null);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }

  function isAddOrEdit() {
    return selectedRequest ? 'Edit' : 'Add';
  }

  function handleUrgencyChange(event) {
    dispatch({ type: 'urgency', payload: event.target.value });
  }

  return (
    <FormModal
      open={openDialog}
      handleNegativeAction={handleCancelButton}
      handlePositiveAction={handlePositive}
      title={`${isAddOrEdit()} Service Request`}
      loading={loading}
      edit={!!selectedRequest}
    >
      <Container>
        <TextField
          select
          variant="outlined"
          label="Marker"
          onChange={handleMarkerChange}
          value={selectedMarker ?? ''}
          required
        >
          {markers?.map((marker) => (
            <MenuItem key={marker.key} value={marker}>
              {marker.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          value={requestData.urgency}
          onChange={handleUrgencyChange}
          variant="outlined"
          label="Urgency"
        >
          {urgencies.map((urgency) => {
            return (
              <MenuItem key={urgency} value={urgency}>
                {getFirstLetterCapitilizedWord(urgency)}
              </MenuItem>
            );
          })}
        </TextField>

        <TextField
          label="Tasks"
          variant="outlined"
          onChange={handleNote}
          value={requestData.requestNote}
          inputProps={{ maxLength: 256 }}
        />

        <TextField
          variant="outlined"
          required
          label="Expected Service Time"
          helperText="Hint: Tomorrow after 2 PM"
          value={requestData.preferredTime}
          onChange={handlePreferredTimeChange}
        />
      </Container>
    </FormModal>
  );
};

export default AddServiceRequest;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
