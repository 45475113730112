/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_jobs.jsx
 *
 *  Description: Show the list of jobs
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 12-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import React, { useEffect, useState, useContext } from 'react';

import { isEmpty } from 'lodash';
import styled from 'styled-components';

import JobItem from './job_item';
import Span from '../../Common/Span';
import { StyledGrid } from '../styled';
import Loader from '../../Generics/snowm_loader';
import { JobsContext } from '../../../../contexts/service_jobs';
import { ServiceTypesContext } from '../../../../contexts/service_types';
import { getActiveJobsDetails } from '../../../../controllers/snowm_firebase';

const JobsConsumer = (jobsProps) => {
  return (
    <JobsContext.Consumer>
      {({ jobs, fetchJobs, fetchPeriodicJobs }) => {
        return (
          <Jobs
            jobs={jobs}
            fetchJobs={fetchJobs}
            fetchPeriodicJobs={fetchPeriodicJobs}
            {...jobsProps}
          />
        );
      }}
    </JobsContext.Consumer>
  );
};

const Jobs = () => {
  const data = useContext(ServiceTypesContext);

  const { markers } = data;

  const [filteredJobs, setFilteredJobs] = useState();

  useEffect(() => {
    let isCancel = false;

    getActiveJobsDetails(markers).then((res) => {
      if (!isCancel) setFilteredJobs(res);
    });

    return () => {
      isCancel = true;
    };
  }, []);

  if (!filteredJobs) return <Loader />;

  return (
    <>
      {isEmpty(filteredJobs) && (
        <StyledContainer justifycontent="center">
          <Span>No Active Jobs</Span>
        </StyledContainer>
      )}

      <StyledGrid>
        {filteredJobs?.map((job) => (
          <JobItem key={job.key} job={job} />
        ))}
      </StyledGrid>
    </>
  );
};

const StyledContainer = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifycontent || 'space-between'};
  align-items: center;
  height: 100%;
`;

export default JobsConsumer;
