/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: Private/index.js
 *
 *  Description: Entry point of private components.
 *
 *  Author: Nabin Kharal (nabin@brainants.com) and Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 4-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import styled from 'styled-components';
import { Flex } from '@chakra-ui/react';
import RoutesIcon from 'mdi-react/RoutesIcon';
import ReceiptIcon from 'mdi-react/ReceiptIcon';
import { withTheme } from '@material-ui/core/styles';
import { useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import ViewDashboardIcon from 'mdi-react/ViewDashboardIcon';
import OfficeBuildingMarkerIcon from 'mdi-react/OfficeBuildingMarkerIcon';

import Home from './Home';
import Tasks from './tasks';
import Routes from './Routes';
import Vendors from './vendors';
import ZenDesk from './ZenDesk';
import Managers from './manager';
import BeaconMs from './BeaconMs';
import Invoices from './invoices';
import Janitors from './janitors';
import Schedule from './schedule';
import Locations from './locations';
import WorkOrders from './serviceRequests';
import Settings from './settings/Settings';
import AppBar from '../Generics/snowm_appbar';
import Drawer from '../Generics/snowm_drawer';
import Loader from '../Generics/snowm_loader';
import MicroLocations from './microLocations';
import shiftTemplates from './shiftTemplates';
import UserProfile from './Profile/UserProfile';
import ReportsTabs from './reports/ReportsTabsToggle';
import { ServiceTypesContext } from '../../contexts/service_types';
import { LocalizationContext } from '../../contexts/localization_context';
import { ReactComponent as ReportsIcon } from '../../assets/svgs/Reports.svg';
import { ReactComponent as ActiveReportIcon } from '../../assets/svgs/ActiveReportIcon.svg';
import { ReactComponent as MicroLocationIcon } from '../../assets/svgs/MicroLocationIcon.svg';
import { ReactComponent as Administrator } from '../../assets/svgs/administrator/Administrator.svg';
import { ReactComponent as DeactiveMicroLocationIcon } from '../../assets/svgs/DeactiveMicroLocationIcon.svg';
import { ReactComponent as ActiveAdministrator } from '../../assets/svgs/administrator/AdministratorWhite.svg';
import {
  TaskIcon,
  DeviceIcon,
  JanitorIcon,
  SettingIcon,
  ScheduleIcon,
  ActiveTaskIcon,
  WorkerOrderIcon,
  ActiveDeviceIcon,
  ActiveJanitorIcon,
  ActiveSettingIcon,
  ShiftTemplateIcon,
  ActiveScheduleIcon,
  DrawerLocationIcon,
  ActiveWorkerOrderIcon,
  ActiveShiftTemplateIcon,
  DrawerActiveLocationIcon,
} from '../../reusableComponent/Icons';

// const { HOME, JOBS, CREW, SERVICES, MARKERS, ROUTES } = en.sidemenu;

/*
 ****************************************************************************
 * @brief  (getRoutes)      renders the list of routes
 * @param{type : Array,Function,Object } (routesToRender,setActive,theme)
 *
 * @return  returns a jsx element containing list of all routesToRender
 *
 ****************************************************************************
 */
function getRoutes(routesToRender, setActive, theme) {
  return (
    <>
      {routesToRender.map((route) => (
        <Switch key={route.name}>
          <Route
            path={route.route}
            component={(p) => {
              useEffect(() => {
                setActive(route.route);
              }, []);
              const Component = route.component;
              return <Component {...p} theme={theme} />;
            }}
            exact={route.exact}
          />
        </Switch>
      ))}
    </>
  );
}

const drawerWidth = 240;

// Functional Component
const AdminDashboard = (props) => {
  const { strings } = useContext(LocalizationContext);
  const {
    ROUTES,
    REPORTS,
    JANITORS,
    DASHBOARD,
    TASKSLIST,
    LOCATIONS,
    USER_PROFILE,
    MICROLOCATIONS,
  } = strings.sidemenu ?? '';

  const [active, setActive] = useState('');
  const { theme } = props;

  // return content of the private component (with appropriate routes)
  const data = useContext(ServiceTypesContext);

  const { subscribedServices, companyServices } = data ?? {};

  const { activities, activitiesOnJob } = subscribedServices ?? {};

  const routes = [
    {
      route: '/admin',
      component: Home,
      name: DASHBOARD,
      Icon: ViewDashboardIcon,
      exact: true,
    },
    {
      name: LOCATIONS,
      component: Locations,
      Icon: DrawerLocationIcon,
      route: '/admin/locations',
      hide: !subscribedServices?.properties,
      ActiveIcon: DrawerActiveLocationIcon,
    },
    {
      route: '/admin/micro-locations',
      component: MicroLocations,
      name: MICROLOCATIONS,
      Icon: DeactiveMicroLocationIcon,
      ActiveIcon: MicroLocationIcon,
    },
    {
      route: '/admin/tasks',
      component: Tasks,
      name: TASKSLIST,
      hide: !activities && !activitiesOnJob,
      Icon: TaskIcon,
      ActiveIcon: ActiveTaskIcon,
    },

    {
      route: '/admin/shift-templates',
      component: shiftTemplates,
      hide: !subscribedServices?.shiftManagement,
      name: 'Shifts Templates', // TODO: need to localize this string too
      Icon: ShiftTemplateIcon,
      ActiveIcon: ActiveShiftTemplateIcon,
    },
    {
      route: '/admin/work-orders',
      component: WorkOrders,
      name: 'Work Orders',
      Icon: WorkerOrderIcon,
      ActiveIcon: ActiveWorkerOrderIcon,
      hide: !subscribedServices?.workOrders,
    },
    {
      route: '/admin/schedule',
      component: Schedule,
      name: 'Schedule',
      Icon: ScheduleIcon,
      ActiveIcon: ActiveScheduleIcon,
      hide: companyServices?.length <= 0 ?? true,
    },
    {
      route: '/admin/administrator',
      component: Managers,
      name: 'Administrators',
      Icon: Administrator,
      ActiveIcon: ActiveAdministrator,
    },
    {
      route: '/admin/janitors',
      component: Janitors,
      name: JANITORS,
      Icon: JanitorIcon,
      ActiveIcon: ActiveJanitorIcon,
    },
    {
      route: '/admin/vendors',
      name: 'Vendors',
      hide: !subscribedServices?.enableOrganizationCrew,
      component: Vendors,
      Icon: OfficeBuildingMarkerIcon,
    },
    // {
    //   route: '/admin/services',
    //   component: Services,
    //   name: SERVICES,
    //   icon: <ServiceIcon />
    // },
    {
      route: '/admin/routes',
      component: Routes,
      name: ROUTES,
      hide: !subscribedServices?.indoor && !subscribedServices?.outdoor,
      Icon: RoutesIcon,
    },
    {
      route: '/admin/invoices',
      component: Invoices,
      name: 'Invoices',
      Icon: ReceiptIcon,
      hide: !subscribedServices?.invoicing,
    },
    {
      route: '/admin/profile',
      component: UserProfile,
      name: USER_PROFILE,
      icon: AccountCircleIcon,
      menu: true,
    },
    {
      route: '/admin/report',
      component: ReportsTabs,
      name: REPORTS,
      hide: !subscribedServices?.reports,
      Icon: ReportsIcon,
      ActiveIcon: ActiveReportIcon,
    },
    {
      route: '/admin/devices',
      component: BeaconMs,
      name: 'Devices',
      Icon: DeviceIcon,
      ActiveIcon: ActiveDeviceIcon,
    },
    // {
    //   route: '/admin/company-profile',
    //   component: CompanyProfile,
    //   name: COMPANY_PROFILE,
    //   Icon: OfficeBuildingIcon,
    // },
    {
      route: '/admin/settings',
      component: Settings,
      name: 'Settings',
      Icon: SettingIcon,
      ActiveIcon: ActiveSettingIcon,
    },
  ];

  useEffect(() => {
    data.getCompanyDetailAsync();
  }, []);

  if (!subscribedServices) {
    return <Loader />;
  }

  return (
    <>
      <ZenDesk />
      <Body>
        <Flex
          zIndex="2"
          height="100%"
          borderTopRadius="40px"
          boxShadow="0px 12px 24px #ECEEF5"
        >
          <Drawer
            active={active}
            width={drawerWidth}
            routes={routes}
            {...props}
          />
        </Flex>
        <Flex flexDirection="column" width="100%" height="100%">
          <AppBar routes={routes} {...props} />
          <Main theme={theme}>
            <Switch>
              {getRoutes(routes, setActive, theme)}
              <Redirect to="/admin" />
            </Switch>
          </Main>
        </Flex>
      </Body>
    </>
  );
};

export default withTheme(AdminDashboard);

const Body = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
  flex-direction: row;
`;

const Main = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #fbf9fb;
`;
