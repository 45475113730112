import styled from 'styled-components';
import { Text } from '@chakra-ui/react';

import color from '../../utils/color';

const Span = styled(Text)`
  && {
    color: ${(props) => props.color || color.black};
    font-size: ${(props) => props.size || '14px'};
    font-weight: ${(props) => props.weight || 'normal'};
  }
`;

export const BrokenWord = styled(Span)`
  word-break: break-all;
`;

export default Span;
