import { useState, ReactNode, useEffect } from 'react';

import { Checkbox, Text } from '@chakra-ui/react';

import SDialog from '../../../reusableComponent/SDialog';

interface DeleteDialogProps {
  entity: any;
  open: boolean;
  listsComponent: ReactNode;
  selectedEnitities: Array<any>;
  handleDelete: () => void;
  handleCancel: () => void;
}

const DeleteDialog = (props: DeleteDialogProps) => {
  const {
    open,
    entity,
    listsComponent,
    selectedEnitities = {},
    handleDelete,
    handleCancel,
  } = props;

  const [agreed, setAgreed] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [openDialog, setOpenDialog] = useState(open);

  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const closeDeleteDialog = () => {
    setOpenDialog(false);
    handleCancel();
    // setSelectedEntities([]);
    setAgreed(false);
  };

  const toggleAgreeCheckbox = () => {
    setAgreed(!agreed);
  };

  const handleDeleteLocation = async () => {
    setDeleting(true);
    if (!agreed) return;
    await handleDelete();
    setAgreed(false);
    setDeleting(false);
  };

  return (
    <SDialog
      size="md"
      loading={deleting}
      height="fit-content"
      positiveLabel="Delete"
      negativeLabel="Cancel"
      body=""
      positiveLabelColor="primary"
      open={openDialog}
      positiveActionDisabled={!agreed}
      handleNegativeAction={closeDeleteDialog}
      handlePositiveAction={handleDeleteLocation}
      title={`Delete ${Object.values(selectedEnitities).length} ${entity}?`}
      subTitle={`Remember, this action cannot be undone. It also removed data related to ${entity}`}
    >
      {/* TODO: here keep the box of listed tasks from props */}
      <>
        {listsComponent}
        <Checkbox checked borderRadius="4px" onChange={toggleAgreeCheckbox}>
          <Text fontSize="12px" color="grey.500">
            I agree to delete all data about this {entity}
          </Text>
        </Checkbox>
      </>
    </SDialog>
  );
};

export default DeleteDialog;
