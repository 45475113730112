import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Tbody,
  Td,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { ReactComponent as DeleteIcon } from '../../../assets/svgs/Delete.svg';
import { ReactComponent as DisabledGrey } from '../../../assets/svgs/DisabledGrey.svg';
import { ReactComponent as EditBlueIcon } from '../../../assets/svgs/Editblue.svg';
import { ReactComponent as EnableBlue } from '../../../assets/svgs/EnableBlue.svg';
import { ReactComponent as ResendInvitationBlue } from '../../../assets/svgs/ResendInvitationBlue.svg';
import { ReactComponent as ResetLoggedInDevice } from '../../../assets/svgs/ResetLoggedInDevice.svg';
import { ReactComponent as ResetPassword } from '../../../assets/svgs/ResetPassword.svg';
import { isCrewWorking } from '../../../controllers/snowm_firebase';
import { getRelativeDate } from '../../../helpers/date';
import { sortByEntity } from '../../../helpers/misc';
import ActionsDrawer from '../../../reusableComponent/ActionsDrawer';
import { MainTable, MainTableRow } from '../../../reusableComponent/MainTable';

const CrewsTable = ({
  crews,
  onEditIconPress,
  showProviderDetail,
  handleCrewAction,
  anchorElForActions,
  loading,
  vendors,
  handleMultipleDelete,
  openActionDrawer,
  selectedJanitors,
  setSelectedJanitors,
  actionsLoading,
  selectedProvider,
  setSortType,
  setFilteredCrew,
  setJanitorHeaders,
  janitorHeaders,
}) => {
  const [isWorking, setIsWorking] = useState(null);
  const [selectedJanitorsCount, setSelectedJanitorsCount] = useState(0);
  const [actions, setActions] = useState([]);
  const [selectedJanitorToEdit, setSelectedJantorToEdit] = useState({});

  useEffect(() => {
    const selectedJanitorsData = Object.values(selectedJanitors);
    setSelectedJanitorsCount(selectedJanitorsData.length);

    const janitorToEdit = selectedJanitorsData[0];

    setSelectedJantorToEdit(janitorToEdit);
  }, [selectedJanitors]);

  const updateActions = () => {
    let actionsForCrew = [];

    const { disabled, loggedIn, deviceId } = selectedJanitorToEdit ?? {};
    // TODO: resend invitation to crew action
    if (deviceId) {
      actionsForCrew = [...actionsForCrew, 'Reset Logged in Device'];
    }
    if (!disabled) {
      actionsForCrew = [...actionsForCrew, 'Reset Password'];
    }
    if (loggedIn && !disabled) {
      actionsForCrew = [...actionsForCrew, 'Disable'];
    } else if (loggedIn) {
      actionsForCrew = [...actionsForCrew, 'Enable'];
    }

    const newSetOfActions = [...new Set(actionsForCrew)];
    setActions(newSetOfActions);
  };

  const getButtonIconForActions = (action) => {
    switch (action) {
      case 'Delete':
        return <DeleteIcon />;
      case 'Disable':
        return <DisabledGrey />;
      case 'Enable':
        return <EnableBlue />;
      case 'Reset Password':
        return <ResetPassword />;
      case 'Reset Logged in Device':
        return <ResetLoggedInDevice />;
      case 'Resend Invitation':
        return <ResendInvitationBlue />;
      default:
        return null;
    }
  };
  useEffect(() => {
    updateActions();
  }, [selectedJanitorToEdit]);

  const showButtons = () => (
    <Flex gridGap="4">
      {actions.map((action) => {
        if (
          action === 'Reset Password' &&
          selectedJanitorToEdit?.loginMode !== 'EMAIL'
        ) {
          return null;
        }
        return (
          <Button
            key={action}
            size="sm"
            disabled={Object.values(actionsLoading).includes(true)}
            colorScheme="blue"
            border="2px solid #006DB8"
            variant="outline"
            isLoading={actionsLoading[action]}
            leftIcon={getButtonIconForActions(action)}
            onClick={() => {
              handleCrewAction(selectedJanitorToEdit, action);
            }}
          >
            {action}
          </Button>
        );
      })}

      {!selectedJanitorToEdit?.disabled && !vendors && (
        <Button
          size="sm"
          colorScheme="blue"
          border="2px solid #006DB8"
          variant="outline"
          disabled={Object.values(actionsLoading).includes(true)}
          leftIcon={<EditBlueIcon />}
          onClick={() => onEditIconPress(selectedJanitorToEdit)}
        >
          Edit
        </Button>
      )}
    </Flex>
  );

  const selectAllJanitors = (e) => {
    if (e.target.checked) {
      const checkedJanitor = crews?.reduce((janitors, janitor) => {
        if (janitor.uid) {
          janitors[janitor.uid] = janitor;
        }
        return janitors;
      }, {});
      setSelectedJanitors({ ...checkedJanitor });
    } else {
      setSelectedJanitors({});
    }
  };

  const selectJanitor = (selectedJanitor) => {
    const { uid } = selectedJanitor;

    if (selectedJanitors[uid]) {
      const { [uid]: value, ...remainingTask } = selectedJanitors;
      setSelectedJanitors({ ...remainingTask });
    } else {
      setSelectedJanitors({
        ...selectedJanitors,
        [uid]: { ...selectedJanitor },
      });
    }
  };

  const handleSorting = (header, headerIndex) => {
    const { sort, name: fieldName } = header;
    const newSort = sort === 'desc' ? 'asc' : 'desc';
    const copiedJanitor = [...crews];

    if (sort === 'desc') {
      copiedJanitor?.sort((a, b) => {
        return b?.name.localeCompare(a?.name);
      });
    } else {
      copiedJanitor?.sort((a, b) => {
        return a?.name.localeCompare(b?.name);
      });
    }

    switch (fieldName) {
      case 'Name':
        copiedJanitor.sort((a, b) => sortByEntity(a.name, b.name, newSort));
        break;

      case 'Last Served':
        copiedJanitor.sort((a, b) =>
          sortByEntity(a.lastSignIn ?? 0, b.lastSignIn ?? 0, newSort)
        );
        break;

      default:
        break;
    }

    const updatedHeaders = [...janitorHeaders];
    updatedHeaders.splice(headerIndex, 1, {
      name: fieldName,
      sort: newSort,
    });
    setJanitorHeaders(updatedHeaders);
    setFilteredCrew(copiedJanitor);
  };

  return (
    <Box mb="50px">
      <MainTable
        headers={janitorHeaders}
        checked={selectedJanitorsCount === crews?.length}
        onSelectedEntities={(e) => selectAllJanitors(e)}
        handleSorting={handleSorting}
      >
        <Tbody bg="white" boxShadow="0px 12px 24px #ECEEF5" borderRadius="16px">
          {crews?.map((crew) => (
            <CrewTableRow
              key={crew.uid}
              janitor={crew}
              isWorking={isWorking}
              setIsWorking={setIsWorking}
              onEditIconPress={onEditIconPress}
              showProviderDetail={showProviderDetail}
              handleCrewAction={handleCrewAction}
              anchorElForActions={anchorElForActions}
              loading={loading}
              vendors={vendors}
              selectJanitor={() => selectJanitor(crew)}
              selectedJanitors={selectedJanitors}
              setSelectedJanitors={setSelectedJanitors}
            />
          ))}
        </Tbody>
      </MainTable>

      <ActionsDrawer
        setSelectedEntities={setSelectedJanitors}
        selectedEntities={Object.values(selectedJanitors).map((e) => ({
          key: e.uid,
          name: e.name,
        }))}
        openActionDrawer={openActionDrawer}
        showButtons={() => showButtons()}
        actionHappening={Object.values(actionsLoading).includes(true)}
        showDeleteButton
        entity="Janitors"
        body={() => deleteJanitorList(selectedJanitors)}
        handleDeleteEntities={(i) => handleMultipleDelete(i, 'Delete')}
      />
    </Box>
  );
};

const CrewTableRow = (props) => {
  const {
    janitor,
    isWorking,
    setIsWorking,
    selectJanitor,
    selectedJanitors,
    showProviderDetail,
  } = props;
  const {
    name,
    email,
    phoneNumber,
    crewId,
    disabled,
    uid,
    masterUid,
    loggedIn,
    lastSignIn,
    imageUrl,
  } = janitor;

  useEffect(() => {
    if (uid) {
      isCrewWorking(uid, (res) => {
        setIsWorking(res);
      });
    }
  }, [janitor]);

  const renderStatus = () => {
    if (isWorking === null) {
      return '-';
    }

    if (isWorking) {
      return 'Working';
    }
    return 'Not Working';
  };

  const lastLoggedInTime = () => {
    if (!loggedIn) {
      return '-';
    }

    return getRelativeDate(lastSignIn);
  };
  if (!janitor) {
    return <h1>loading</h1>;
  }
  return (
    <MainTableRow>
      <Td>
        <Flex gridGap="md">
          <Checkbox
            onChange={selectJanitor}
            isChecked={!!selectedJanitors[uid ?? '']}
            borderColor="primary.500"
            bg="white"
          />
          <Flex
            onClick={() => showProviderDetail(janitor)}
            cursor="pointer"
            flexGrow="1"
            gridGap="sm"
            alignItems="center"
          >
            <Avatar width="50px" height="50px" name={name} src={imageUrl} />
            <Text fontSize="16px">{name} </Text>
            {!loggedIn && (
              <Text color="grey.500" fontSize="16px">
                (Invited)
              </Text>
            )}
            {disabled && (
              <DisabledGrey width="20px" height="20px" fill="red" color="red" />
            )}
          </Flex>
        </Flex>
      </Td>

      <Td textAlign="right">{lastLoggedInTime()}</Td>
    </MainTableRow>
  );
};

export const deleteJanitorList = (selectedJanitors) => {
  return (
    <Box
      background="#F1F1F1 0% 0% no-repeat padding-box"
      border="0.5px solid #AEB0B5"
      borderRadius="4px"
      maxWidth="474px"
      maxHeight="220px"
      overflowY="auto"
      padding="16px 8px 8px 16.5px"
    >
      {Object.values(selectedJanitors ?? {})?.map((janitor, i) => {
        return (
          <>
            <Flex
              cursor="pointer"
              flexGrow="1"
              gridGap="sm"
              alignItems="center"
            >
              <Avatar
                width="34px"
                height="34px"
                name={janitor.name}
                src={janitor.imageUrl}
                borderRadius="3px"
              />
              <Text fontSize="14px" fontWeight="medium">
                {janitor.name}
              </Text>
            </Flex>
            {i < Object.keys(selectedJanitors).length - 1 && (
              <Divider margin="8px auto" />
            )}
          </>
        );
      })}
    </Box>
  );
};

export default CrewsTable;
