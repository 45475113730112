import React, { useEffect, useState } from 'react';

import Loader from '../../Generics/snowm_loader';
import { Providers } from '../janitors/snowm_janitors';
import { getProvidersOfCompany } from '../../../controllers/snowm_firebase';

const Vendors = () => {
  const [crews, setCrews] = useState(null);
  useEffect(() => {
    let isCancel = false;
    getProvidersOfCompany(null, true).then((res) => {
      if (!isCancel) {
        setCrews(res);
      }
    });
    return () => {
      isCancel = true;
    };
  }, []);

  if (!crews) {
    return <Loader />;
  }

  return <Providers providers={crews} vendors typeOfCrew="organization" />;
};

export default Vendors;
