/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: contexts/beaconMs.js
 *
 *  Description: Contex API for BeaconMs
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 10-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import { createContext, useState } from 'react';
import { getRealTimeBeaconMs } from '../controllers/snowm_firebase';

export const BeaconMsContext = createContext();

export const BeaconMsProvider = ({ children }) => {
  const [beaconMs, setbeaconMs] = useState(null);

  function _updateBeaconMs(beaconMsData) {
    setbeaconMs(beaconMsData);
  }

  function fetchBeaconMs() {
    getRealTimeBeaconMs(_updateBeaconMs);
  }
  return (
    <BeaconMsContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        beaconMs,
        fetchBeaconMs,
      }}
    >
      {children}
    </BeaconMsContext.Provider>
  );
};
