function getSubtotal(entity) {
  const { unitPrice, quantity, discountPercentage } = entity;
  return unitPrice * quantity * (1 - discountPercentage / 100) ?? 0;
}

export default function getAmountsOfAEntity(entity) {
  const subTotal = getSubtotal(entity);
  const taxValue = 0.13;
  const tax = subTotal * taxValue;
  const total = subTotal + tax;
  return { subTotal, tax, total };
}
