import React from 'react';

import styled from 'styled-components';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PlaylistCheckIcon from 'mdi-react/PlaylistCheckIcon';
import { Divider, Grid, IconButton, Tooltip } from '@material-ui/core';

import { possibleJobStatuses } from '../../enums/jobStatus';
import { ItemCard, Row, Col } from '../../styles/snowm_styled';

const Card = (props) => {
  const {
    small,
    medium,
    large,
    children,
    onPress,
    onDeletePress,
    onEditIconPress,
    minHeight,
    status,
    onCheckIconPress,
    boxShadow = 10,
  } = props;

  const handleCheckIcon = (event) => () => {
    event.stopPropagation();
    onCheckIconPress();
  };

  function getTip() {
    return 'Edit';
  }

  return (
    <Grid style={{ padding: '1em 0' }} item sm={small} md={medium} xl={large}>
      <ItemsCard
        boxShadow={boxShadow}
        borderRadius="10px"
        onClick={onPress}
        minheight={minHeight}
      >
        {children}
        <StyledCol>
          {(onEditIconPress || onDeletePress) && (
            <Divider style={{ marginTop: 10 }} />
          )}

          <Row justify="center">
            {onEditIconPress ? (
              <Tooltip title={getTip()}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onEditIconPress();
                  }}
                >
                  {(!status || status === 'assigned') && <EditIcon />}
                </IconButton>
              </Tooltip>
            ) : null}

            {(status === possibleJobStatuses.STARTED ||
              status === possibleJobStatuses.NOT_COMPLETED) && (
              <Tooltip title="Complete Job">
                <IconButton onClick={handleCheckIcon}>
                  <PlaylistCheckIcon />
                </IconButton>
              </Tooltip>
            )}

            {onDeletePress ? (
              <Tooltip title="Delete">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeletePress();
                  }}
                >
                  <DeleteIcon htmlColor="#173964" />
                </IconButton>
              </Tooltip>
            ) : null}
          </Row>
        </StyledCol>
      </ItemsCard>
    </Grid>
  );
};

const ItemsCard = styled(ItemCard)`
  && {
    display: flex;
    flex: 2;
    flex-direction: column;
    align-items: center;
    background: white;
    margin: 0 16px;
    text-align: center;
    height: auto;
    padding: 1em;
    min-height: ${(props) => props.minheight};
    cursor: pointer;
    box-shadow: ${(props) => props.boxShadow};
  }
`;

const StyledCol = styled(Col)`
  width: 100%;
`;

export default Card;
