import React, { Fragment } from 'react';

import { View, Text, Image, StyleSheet } from '@react-pdf/renderer';

import color from '../../utils/color';
import statuses, { possibleJobStatuses } from '../../enums/jobStatus';
import { getDayMonthYearTime, getFormattedDate } from '../../helpers/date';

function getColorForActivities(activity, activities) {
  let isActivityCompleted = false;
  if (activities) {
    const setOfActivitiesName = new Set(
      activities?.map((activityLog) => activityLog?.name)
    );
    isActivityCompleted = setOfActivitiesName.has(activity.name);
  } else {
    isActivityCompleted = activity.completed ?? false;
  }
  return isActivityCompleted;
}

const imageStyles = {
  width: 150,
  height: 150,
  objectFit: 'cover',
};

export const styles = StyleSheet.create({
  page: { padding: 10 },
  section: {
    flexDirection: 'row',
    margin: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleText: {
    fontSize: 14,
    fontFamily: 'Helvetica-Bold',
    fontWeight: 700,
  },
  valueText: {
    fontSize: 12,
    fontFamily: 'Helvetica',
    marginLeft: 4,
  },
  middleContainer: {
    padding: 8,
    border: '1px solid black',
  },
  header: {
    flexDirection: 'row',
    // justifyContent: 'space-between',
    backgroundColor: '#006eb9',
    color: color.white,
    padding: 8,
    margin: '8px 4px',
  },
});
export function checkForMarkerRouteKey(issueReport) {
  return issueReport?.markerKey || issueReport?.routeKey;
}

const sortActivitiesByName = (firstActivity, secondActivity) => {
  const firstLowerCaseActivityName = firstActivity.name.toLowerCase();
  const secondLowerCaseActivityName = secondActivity.name.toLowerCase();
  if (firstLowerCaseActivityName > secondLowerCaseActivityName) {
    return 1;
  }
  if (firstLowerCaseActivityName < secondLowerCaseActivityName) {
    return -1;
  }
  return 0;
};

const Divider = () => (
  <View
    style={{
      backgroundColor: '#000',
      height: 1,
      width: '100%',
      marginVertical: 10,
    }}
  />
);

const returnSrc = (imagePath) => ({ uri: imagePath, method: 'GET' });

const getStatus = (job) => {
  const { status } = job;

  switch (status) {
    case possibleJobStatuses.ASSIGNED:
      return 'Assigned';

    case possibleJobStatuses.NOT_STARTED:
      return 'Not started at all';

    case possibleJobStatuses.NOT_COMPLETED:
      return 'Started serving but not completed';
    case possibleJobStatuses.STARTED:
      return 'Currently serving';
    case possibleJobStatuses.COMPLETED:
      return `Completed by ${job.closedBy ?? '-'}`;
    default:
      return `${statuses[status]}: ${job.closedBy ?? '-'}`;
  }
};

const getJobStatus = (job) => getStatus(job);

const getColorForJobStatus = (job) => {
  if (job.status === 'started') {
    return color.orange;
  }
  if (job.closedBy === 'shift') {
    return color.red;
  }
  return color.white;
};

const ReportType = ({ type }) => (
  <View style={{ flexDirection: 'row' }}>
    <Text style={{ ...styles.titleText, fontSize: 12 }}>Issue Type:</Text>
    <Text style={styles.valueText}>{type}</Text>
  </View>
);

export const RenderIssueReports = ({ issueReports, selectImages }) => (
  <View wrap={false} style={{ margin: 4, border: '1px solid black' }}>
    <View style={{ margin: '8px 0 8px' }}>
      <Text style={{ ...styles.titleText, marginLeft: 8 }}>Issue Reports:</Text>
      <Divider />
      {issueReports?.map((report) => (
        <Fragment key={report?.key}>
          <View style={{ margin: 8 }}>
            <View wrap={false} style={{ flexDirection: 'row' }}>
              <Text style={styles.valueText}>{report?.message}</Text>
            </View>

            {selectImages && (
              <View
                style={{
                  flexDirection: 'row',
                  margin: 8,
                  justifyContent: 'space-around',
                }}
              >
                {report?.imageUrls?.map((url) => (
                  <Image key={url} src={returnSrc(url)} style={imageStyles} />
                ))}
              </View>
            )}
          </View>
          {/* {i < issueReports?.length - 1 && <Divider />} */}
        </Fragment>
      ))}
    </View>
  </View>
);

export const JobHeader = ({ job, crew }) => (
  <View
    style={{
      ...styles.header,
      justifyContent: 'flex-start',
      flexDirection: 'column',
    }}
  >
    <View
      wrap={false}
      style={{
        ...styles.header,
        padding: 0,
        justifyContent: 'space-between',
      }}
    >
      <View
        style={{
          width: '50%',
        }}
      >
        <RowData titleText="Job Name: " valueText={job?.name} />
        {job?.serviceType === 'markerbased' ? (
          <RowData
            titleText="Total Markers: "
            valueText={job?.allMarkers?.length}
          />
        ) : (
          <RowData
            titleText="Total Routes: "
            valueText={job?.allRoutes?.length}
          />
        )}
        <RowData titleText="Served By: " valueText={crew?.name} />
      </View>
      <View>
        <RowData
          fontcolor={getColorForJobStatus(job)}
          titleText="Job Status: "
          valueText={getJobStatus(job)}
        />
        <RowData
          color={color.white}
          titleText="Started at: "
          valueText={getDayMonthYearTime(job.startedDate)}
        />
        {job.endDate ? (
          <RowData
            titleText="Ended at: "
            valueText={getDayMonthYearTime(job.endDate)}
          />
        ) : null}
      </View>
    </View>
    {job.noShowe ? (
      <View
        style={{
          backgroundColor: color.red,
          width: '100%',
          color: color.white,
          padding: 4,
          fontWeight: '200',
        }}
      >
        <RowData titleText="Job Exception (Late or no Show)" />
      </View>
    ) : null}
  </View>
);

const MarkerName = ({ log }) => {
  function markerName() {
    if (log.property?.name) {
      return `${log?.name} (${log.property.name})`;
    }
    return log?.name;
  }
  return (
    <Text style={{ padding: 8, color: color.white }}>
      {markerName() ?? 'Deleted Marker'}
    </Text>
  );
};

const MarkerDetail = ({ log, selectImage }) => {
  if (log?.activities?.length === 0) {
    return <MarkerName log={log} />;
  }

  if (log?.imageUrl && selectImage) {
    return (
      <View
        style={{
          padding: 8,
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Image
          style={{ ...imageStyles, marginLeft: 8, height: 30, width: 30 }}
          src={returnSrc(log?.imageUrl)}
        />
        <MarkerName log={log} />
      </View>
    );
  }
  return <MarkerName log={log} />;
};

export const RenderActivities = ({ activities, log }) => (
  <View
    wrap={false}
    style={{
      ...styles.middleContainer,
      margin: !log ? '4px' : 'none',
      padding: 0,
    }}
  >
    <View style={{ padding: '8px 4px 0 4px' }}>
      <Text style={styles.titleText}>Activities</Text>
    </View>
    <Divider />
    <View style={{ padding: 4 }}>
      {activities?.sort(sortActivitiesByName).map((activity) => (
        <Text
          key={activity?.id}
          style={{
            fontSize: 12,
            color: getColorForActivities(activity, log?.log?.activities)
              ? '#4caf50'
              : '#ff0000',
          }}
        >
          {activity?.name}
        </Text>
      ))}
    </View>
  </View>
);

export const LogData = ({ log, selectImages }) => {
  let images = [...(log?.log?.images ?? [])];
  if (log?.image) {
    images = [...images, log?.image];
  }
  return (
    <View
      key={log?.key}
      wrap={false}
      style={{
        flexDirection: 'column',
        border: '1px solid black',
        margin: 4,
      }}
    >
      <View
        style={{
          backgroundColor: '#00afee',
        }}
      >
        <MarkerDetail log={log} selectImage={selectImages} />
      </View>

      <View
        style={{
          ...styles.middleContainer,
          justifyContent: 'space-between',
          flexDirection: 'row',
          width: '100%',
        }}
      >
        <RowData
          titleText="Started At: "
          valueText={getDayMonthYearTime(log?.log?.startDate)}
        />
        <RowData
          titleText="Ended At: "
          valueText={getDayMonthYearTime(log?.log?.endDate)}
        />
      </View>

      {log?.log?.activities?.length ? (
        <RenderActivities activities={log.log.activities} log={log} />
      ) : null}
      {selectImages && images?.length > 0 ? (
        <View style={styles.middleContainer}>
          <View style={{ margin: '4px 0px' }}>
            <Text style={styles.titleText}>Marker Logs:</Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              padding: 4,
              justifyContent: 'space-around',
              flexWrap: 'wrap',
            }}
          >
            <LogImages images={images} />
          </View>
        </View>
      ) : null}

      {log?.reports?.length > 0 ? (
        <View>
          <View style={styles.middleContainer}>
            <Text
              style={{ ...styles.titleText, margin: '4px 0px ', color: 'red' }}
            >
              Issue Reported:
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'flex-start',
            }}
          >
            {log?.reports?.map((report, i) => (
              <View
                key={report?.key}
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  flexWrap: 'wrap',
                }}
              >
                {/* <ReportType type={report?.type} /> */}
                {selectImages && report?.imageUrls?.length ? (
                  <View style={{ padding: 4 }}>
                    {report?.imageUrls?.map((image) => (
                      <View key={image} style={{ alignItems: 'center' }}>
                        <Image src={returnSrc(image)} style={imageStyles} />
                        <Text style={{ fontSize: 12 }}>{report?.message}</Text>
                      </View>
                    ))}
                  </View>
                ) : (
                  <>
                    <View style={{ padding: 4 }}>
                      <Text style={{ fontSize: 12 }}>{report?.message}</Text>
                    </View>
                    {log?.reports[i + 1] ? <Divider /> : null}
                  </>
                )}
              </View>
            ))}
          </View>
        </View>
      ) : null}

      {log?.log?.notes ? (
        <View style={{ borderTop: '1px solid black' }}>
          <Text style={{ fontSize: 12, padding: 8 }}>{log?.log?.notes}</Text>
        </View>
      ) : null}
    </View>
  );
};

const LogImages = ({ images }) => {
  if (images) {
    return images?.map((image) => (
      <Image
        key={image}
        src={returnSrc(image)}
        style={{ ...imageStyles, margin: '8px 0' }}
      />
    ));
  }
  return null;
};

export const RowData = ({ titleText, valueText, fontcolor }) => (
  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
    <Text style={{ ...styles.titleText, color: fontcolor }}>{titleText}</Text>
    <Text style={{ ...styles.valueText, color: fontcolor }}>{valueText}</Text>
  </View>
);

export const RenderImage = ({ imageUrl, height, width }) => {
  if (imageUrl) {
    return (
      <Image
        src={returnSrc(imageUrl)}
        style={{
          ...imageStyles,
          height: height ?? 60,
          width: width ?? 80,
        }}
      />
    );
  }
  return null;
};

export const TopPdfHeader = ({ companyDetail, generatedBy, id, reportFor }) => (
  <View fixed>
    <View style={styles.section}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          width: '50%',
        }}
      >
        <RenderImage imageUrl={companyDetail?.imageUrl} />

        <Text
          style={{
            marginLeft: 8,
            fontFamily: 'Helvetica-Bold',
            fontWeight: 'bold',
            flexWrap: 'wrap',
          }}
        >
          {companyDetail?.name ?? '-'}
        </Text>
      </View>
      <View>
        <RowData
          fontcolor="#2e88c6"
          titleText="Report For:"
          valueText={reportFor}
        />
        <RowData
          fontcolor="#2e88c6"
          titleText="Generated at:"
          valueText={getFormattedDate()}
        />
        <RowData
          fontcolor="#2e88c6"
          titleText="Generated by:"
          valueText={generatedBy}
        />
        <RowData fontcolor="#2e88c6" titleText="Report id:" valueText={id} />
      </View>
    </View>
    <View
      style={{
        backgroundColor: '#000',
        height: 1,
        width: '100%',
        marginVertical: 10,
      }}
    />
  </View>
);
