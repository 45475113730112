/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: notify_box.jsx
 *
 *  Description: Notify Box Component
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 22-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

/* eslint-disable react/destructuring-assignment */
import { Card, IconButton } from '@material-ui/core';
import React from 'react';
import Styled from 'styled-components';
import CloseIcon from 'mdi-react/CloseIcon';

import Modal from '../Generics/snowm_modal';

const NotifyBox = props => {
  const closeDialog = () => {
    props.notifyBoxModal.close();
  };
  return (
    <Modal {...props.notifyBoxModal.props}>
      <ModalCard>
        <div style={{ marginLeft: 24, marginRight: 24 }}>
          <IconButton
            style={{ position: 'absolute', right: 0, top: 0 }}
            onClick={closeDialog}
          >
            <CloseIcon />
          </IconButton>
          <h4>{props.message}</h4>
        </div>
      </ModalCard>
    </Modal>
  );
};

const ModalCard = Styled(Card)`
  &&&& {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 16px;
  }
`;

export default NotifyBox;
