import React, { ReactNode, useRef, useState } from 'react';

import {
  Box,
  Text,
  Flex,
  Button,
  Divider,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogContent,
} from '@chakra-ui/react';
import { Action } from '../enums/actions';

interface DialogProps {
  open: boolean;
  size?: string;
  color?: string;
  body: ReactNode;
  height?: string;
  title: ReactNode;
  loading?: boolean;
  subTitle?: ReactNode;
  positiveLabel?: string;
  negativeLabel?: string;
  children?: React.ReactChild;
  positiveLabelColor?: string;
  positiveActionDisabled: boolean;
  handlePositiveAction: () => void;
  handleNegativeAction: () => void;
  hideNegativeAction?: boolean;
  positiveButtonColor?: undefined | string;
}

const SDialog = (props: DialogProps) => {
  const {
    open,
    body,
    title,
    loading,
    subTitle,
    size = 'md',
    positiveLabel,
    negativeLabel,
    height = '288px',
    color = 'grey.200',
    handlePositiveAction,
    handleNegativeAction,
    positiveActionDisabled,
    positiveLabelColor = 'primary',
    children,
    hideNegativeAction = false,
    positiveButtonColor = undefined,
  } = props;
  const cancelRef: any = useRef();
  const [shouldDisableCancel, setshouldDisableCancel] = useState(false);

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      isOpen={open}
      onClose={() => handleNegativeAction()}
      isCentered
      size={size}
    >
      <AlertDialogOverlay>
        <AlertDialogContent
          p="4"
          width="554px"
          height={height}
          borderRadius=" 8px"
          background="#FFFFFF 0% 0% no-repeat padding-box"
        >
          <AlertDialogHeader fontSize="18px" fontWeight="bold">
            {title}
          </AlertDialogHeader>
          <Divider w="90%" mx="auto" />
          <AlertDialogBody>
            <Flex direction="column" gridGap="25px">
              {subTitle && (
                <Text color="grey.500" margiTop="6px" fontSize="xs">
                  {subTitle}
                </Text>
              )}
              {body && (
                <Box overflowY="auto" maxHeight="280px">
                  {body}
                </Box>
              )}
              {children}
            </Flex>
          </AlertDialogBody>

          <AlertDialogFooter>
            {!hideNegativeAction ? (
              <Button
                onClick={() => handleNegativeAction()}
                variant="ghost"
                disabled={loading || shouldDisableCancel}
              >
                {negativeLabel ?? Action.Cancel}
              </Button>
            ) : null}
            <Button
              width="85px"
              height="40px"
              background={
                positiveButtonColor
                  ? ` ${positiveButtonColor} 0% 0% no-repeat padding-box`
                  : '#EE0101 0% 0% no-repeat padding-box'
              }
              boxShadow={
                positiveButtonColor
                  ? `
              0px 3px 6px ${positiveButtonColor}C`
                  : '0px 3px 6px #EE01015C'
              }
              isLoading={loading}
              color={color}
              colorScheme={positiveButtonColor ? 'blue' : 'primary'}
              onClick={() => {
                handlePositiveAction();
                setshouldDisableCancel(true);
                setshouldDisableCancel(false);
              }}
              disabled={positiveActionDisabled ?? false}
              ml={3}
            >
              <Text color="highlight.200">
                {positiveLabel ?? Action.Cancel}
              </Text>
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default SDialog;
