import { SmallCloseIcon } from '@chakra-ui/icons';
import { Box, HStack, Text, VStack } from '@chakra-ui/layout';
import { Button, IconButton, useToast } from '@chakra-ui/react';

import { ReactNode } from 'react';
import { SuccessIcon } from './SuccessIcon';
import { VerticalLine } from './VerticalLine';

interface DisplayProps {
  name: string;
}
interface ToastProps {
  displayData?: DisplayProps;
  title: string;
  status: string;
  description: ReactNode;
}

export const Toast = (props: ToastProps) => {
  const toast = useToast();
  const { displayData, title, status, description } = props;

  return (
    <Box
      color="white"
      p={5}
      minWidth="581px"
      minHeight="72px"
      backgroundColor="green.300"
      border-radius="4px"
      textAlig="left"
      display="flex"
      justifyContent="space-between"
    >
      <HStack alignItems="center">
        <SuccessIcon height={30} width={30} marginRight="20px" />

        <VStack alignItems="flex-start" spacing={1}>
          <Text color="white" font="normal normal 600 20px/24px Montserrat">
            {title}
          </Text>
          <Text color="white">{description}</Text>
        </VStack>
      </HStack>
      <HStack alignItems="center">
        <VerticalLine height={30} margin="auto 15px" />
        <IconButton
          background="transparent"
          aria-label=""
          onClick={() => toast.closeAll()}
          icon={<SmallCloseIcon />}
        />
      </HStack>
    </Box>
  );
};
