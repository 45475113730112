import React, { Fragment, useEffect, useState } from 'react';

import moment from 'moment';
import styled from 'styled-components';
import { Box } from '@chakra-ui/layout';
import { useHistory } from 'react-router';
import ClockIcon from 'mdi-react/ClockIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import ClockEndIcon from 'mdi-react/ClockEndIcon';
import ClockStartIcon from 'mdi-react/ClockStartIcon';
import SyncCirlceIcon from 'mdi-react/SyncCircleIcon';
import AccountHardHatIcon from 'mdi-react/AccountHardHatIcon';
import HomeCityOutlineIcon from 'mdi-react/HomeCityOutlineIcon';
import OrderBoolAscendingVariantIcon from 'mdi-react/OrderBoolAscendingVariantIcon';

import {
  Card,
  Grid,
  Divider,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';

import Span from '../../Components/Common/Span';
import { BoldTitle } from './styled';
import color from '../../utils/color';
import ADialog from '../../Components/Common/styled';
import ListTitleItem from '../../Components/Generics/list_title_item';
import { getDateInDashFormat } from '../../helpers/date';
import { Col, Row, Title } from '../../styles/snowm_styled';
import MarkerBasicDetail from '../../Components/Private/microLocations/marker_basic_detail';
import {
  getServicePointById,
  getPropertyById,
  getProviderByUid,
} from '../../controllers/snowm_firebase';

const MarkerDetailOfAJob = ({
  markerKey,
  markerLog,
  job,
  markerLogDetailPage,
}) => {
  const [marker, setMarker] = useState();
  const [property, setProperty] = useState();
  const [markerStatus, setMarkerStatus] = useState();
  const [crew, setCrew] = useState();
  const [openActivityModal, setOpenActivityModal] = useState(false);
  const [setOfActivitiesIds, setSetOfActivitiesIds] = useState();

  const history = useHistory();

  useEffect(() => {
    let isSubscribe = true;
    getServicePointById(markerKey).then((res) => {
      if (isSubscribe) setMarker(res);
    });

    return () => {
      isSubscribe = false;
    };
  }, [markerKey]);

  useEffect(() => {
    let isSubscribe = true;
    if (marker?.propertyKey) {
      getPropertyById(marker?.propertyKey).then((res) => {
        if (isSubscribe) setProperty(res);
      });
    }

    return () => {
      isSubscribe = false;
    };
  }, [marker]);

  useEffect(() => {
    if (markerLog?.aborted) {
      setMarkerStatus('ABORTED');
    } else if (markerLog?.working === false) setMarkerStatus('COMPLETED');
    else if (markerLog?.working === true) setMarkerStatus('WORKING');
  }, [markerLog]);

  useEffect(() => {
    let isSubscribe = true;
    if (markerLog?.providerUid) {
      getProviderByUid(markerLog?.providerUid).then((response) => {
        if (isSubscribe) setCrew(response);
      });
    }
    return () => {
      isSubscribe = false;
    };
  }, [markerLog]);

  useEffect(() => {
    const completedActivityIds = markerLog?.activities?.reduce((acc, cur) => {
      if (cur?.completed) return [...acc, cur?.id];
      return acc;
    }, []);

    const newSetOfActivitiesId = new Set([...(completedActivityIds ?? [])]);

    setSetOfActivitiesIds(newSetOfActivitiesId);
  }, [markerLog]);

  function isActivityCompleted(activityLog) {
    return !!activityLog?.completed;
  }

  function getNumberOfCompletedActivities() {
    const completedActivities =
      markerLog?.activities?.filter(isActivityCompleted);
    return completedActivities?.length ?? 0;
  }

  function getTotalNumberOfActivities() {
    return Math.max(
      getNumberOfCompletedActivities(),
      marker?.activities?.length ?? 0
    );
  }

  function getRatioOfActivities() {
    if (markerLog) {
      return `${getNumberOfCompletedActivities()} / ${getTotalNumberOfActivities()}`;
    }
    return Object.keys(job?.activities[markerKey] ?? {}).length;
  }

  function handleActivitiesClick(e) {
    e.stopPropagation();
    setOpenActivityModal(true);
  }

  function renderRatioOfCompletedActivities() {
    if (
      (markerLog && !markerLog?.activities?.length) ||
      (!markerLog && !Object.keys(job?.activities[markerKey] ?? {}).length)
    ) {
      return null;
    }
    return (
      <ListTitleItem
        onClick={handleActivitiesClick}
        Icon={OrderBoolAscendingVariantIcon}
        content={getRatioOfActivities()}
        title="Activities"
      />
    );
  }

  function getMarkerStatusColor() {
    switch (markerStatus) {
      case 'ABORTED':
        return 'error';
      case 'WORKING':
        return color?.green;
      default:
        return 'black';
    }
  }

  function getDate(date) {
    return date ? getDateInDashFormat(date) : '-';
  }

  function handleMarkerDetailClick(e) {
    e.stopPropagation();
    if (markerLog && !markerLogDetailPage) {
      history.push(
        `/admin/jobs/details/${markerLog?.jobKey}/marker/${markerLog?.servicePointId}`
      );
    }
  }

  function checkActivityComplete(activity) {
    return setOfActivitiesIds?.has(activity?.id);
  }

  function handleClose(event) {
    event.stopPropagation();
    setOpenActivityModal(false);
  }

  function getDuration(duration) {
    const minutes = moment.duration(duration).asMinutes();
    return `${Math.floor(minutes)} minutes`;
  }

  const activityLabel = ({ activity }) => {
    return (
      <Col>
        <Span>{activity?.name}</Span>
        {activity.mandatory && <Span color={color.grey}>Mandatory</Span>}
      </Col>
    );
  };

  const activityDetails = ({ activity }) => {
    const checkIfActivityIsCompleted = () => {
      if (markerLog) {
        return checkActivityComplete(activity);
      }
      return !!activity.completed;
    };
    return (
      <StyledFormControlLabel
        labelPlacement="start"
        control={
          <Checkbox color="primary" checked={checkIfActivityIsCompleted()} />
        }
        label={activityLabel({ activity })}
      />
    );
  };

  const listOfActivities = ({ activities }) => {
    const uniqueActivities = activities?.reduce((acc, activity) => {
      return { ...acc, [activity.name]: activity };
    }, {});
    return Object.values(uniqueActivities ?? {}).map((activity) => {
      return (
        <Row key={activity.name} justify="space-between">
          {activityDetails({ activity })}
        </Row>
      );
    });
  };

  const ActivitiesDetail = () => {
    return (
      <ADialog onClose={handleClose} width="300px" open={openActivityModal}>
        <ActivitiesContainer>
          <Row justify="space-between">
            <Title align="center">Activities</Title>
            <CloseIcon cursor="pointer" onClick={handleClose} />
          </Row>
          {markerLog
            ? listOfActivities({ activities: markerLog.activities })
            : job?.activities?.[`${markerKey}`]?.map((activity) => {
                return (
                  <Fragment key={activity.id}>
                    {activityDetails({ activity })}
                  </Fragment>
                );
              })}
        </ActivitiesContainer>
      </ADialog>
    );
  };

  const OnSiteTime = () => {
    if (markerLog?.onSiteTime) {
      return (
        <ListTitleItem
          Icon={ClockIcon}
          content={getDuration(markerLog?.onSiteTime)}
          title="On-Site Time"
        />
      );
    }
    return null;
  };

  return (
    <>
      <Box item xs={4}>
        {/* <StyledCard
          onClick={handleMarkerDetailClick}
          cursor={markerLog ? 'pointer' : 'auto'}
        > */}
        <Col>
          {!markerLog ? (
            <MarkerBasicDetail marker={marker} property={property} />
          ) : (
            <StyledCol>
              <Row justify="space-between">
                <BoldTitle>
                  {marker && (marker?.name ?? 'Marker Deleted')}
                </BoldTitle>
                {markerStatus && (
                  <Span weight="bold" color={getMarkerStatusColor()}>
                    {markerStatus}
                  </Span>
                )}
              </Row>
              <Divider />

              <ListTitleItem
                Icon={AccountHardHatIcon}
                content={crew?.name}
                title="Crew"
              />
              <ListTitleItem
                Icon={HomeCityOutlineIcon}
                content={property?.name}
                title="Property"
              />

              <ListTitleItem
                Icon={HomeCityOutlineIcon}
                content={property?.address ?? marker?.address ?? '-'}
                title="Address"
              />
              <ListTitleItem
                Icon={ClockStartIcon}
                content={getDate(markerLog.startDate)}
                title="Started At"
              />

              <ListTitleItem
                Icon={ClockEndIcon}
                content={getDate(markerLog?.endDate)}
                title="Completed At"
              />

              {renderRatioOfCompletedActivities()}

              <OnSiteTime />

              {(markerLog?.iteration || false) && (
                <ListTitleItem
                  Icon={SyncCirlceIcon}
                  content={markerLog?.iteration}
                  title="Iteration"
                />
              )}
            </StyledCol>
          )}
          {Object.keys(job?.activities ?? {}).length ? (
            <PointerText>{renderRatioOfCompletedActivities()}</PointerText>
          ) : null}
        </Col>
        {/* </StyledCard> */}
      </Box>
      <ActivitiesDetail />
    </>
  );
};

export default MarkerDetailOfAJob;

const StyledCard = styled(Card)`
  padding: 16px;
  min-height: 200px;
  cursor: ${(props) => props.cursor};
`;

const StyledCol = styled(Col)`
  margin-top: 8px;
`;

const StyledGrid = styled(Grid)`
  min-height: 200px;
  && {
    margin-left: 4px;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  justify-content: space-between;
  width: 100%;
`;

const ActivitiesContainer = styled(Col)`
  padding: 12px;
`;

const PointerText = styled.span`
  cursor: pointer;
`;
