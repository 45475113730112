import { TriangleDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
// eslint-disable-next-line import/no-cycle
// import { DatePicker } from './AddStartEndTime'; // TODO: confirm this
import MomentUtils from '@date-io/moment';
import { withStyles } from '@material-ui/core/styles';
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { BsCalendar4 } from 'react-icons/bs';
import { getAllDaysOfAWeek } from '../../../helpers/date';
import { formatTime, getRepeatOrNotRepeat } from '../../../helpers/misc';
import { CustomInput } from '../../../reusableComponent/CustomInput';
import { CustomSelect } from '../../../reusableComponent/shiftSchedule/custom_select';
import { DatePickers } from './AddStartEndTime';

export const weeklyDays = [
  { id: 0, name: 'Sunday' },
  { id: 1, name: 'Monday' },
  { id: 2, name: 'Tuesday' },
  { id: 3, name: 'Wednesday' },
  { id: 4, name: 'Thursday' },
  { id: 5, name: 'Friday' },
  { id: 6, name: 'Saturday' },
];

export const weekDays = getAllDaysOfAWeek();

export const weekDay = weekDays.map((day, index) => ({
  id: index,
  name: day,
}));

export const notificationTimes = ['15 minutes', '30 minutes', '1 hour'];

export const notificationTime = notificationTimes.map((wD, index) => ({
  label: wD,
  value: index,
}));

const notificationOptions = [
  {
    label: '15 minutes',
    value: 15,
  },
  {
    label: '30 minutes',
    value: 30,
  },
  {
    label: '1 hour',
    value: 60,
  },
];

interface ShiftTemplateFieldsProps {
  handleDataChange: (value: any, field: string) => void;
  shiftData: any;
  selectedWeekDays: number[];
  isEdit: boolean;
  fromSchedule?: boolean;
  onHandleRepeatDays?: (day: any) => void;
  setSelectedWeekDays?: React.Dispatch<React.SetStateAction<number[]>>;
  handleEndOnAfterCancel?: () => void;
  periodicData?: any;
  setPeriodicData?: any;
}

export const AddShiftTemplateFields = ({
  handleDataChange,
  shiftData,
  setSelectedWeekDays,
  selectedWeekDays,
  isEdit,
  fromSchedule,
  onHandleRepeatDays,
  handleEndOnAfterCancel,
  periodicData,
  setPeriodicData,
}: ShiftTemplateFieldsProps) => {
  const [openDialogForRepeatShift, setopenDialogForRepeatShift] =
    useState(false);

  const showExceptionMinute = () => {
    if (shiftData?.exceptionMinutes) {
      const value = shiftData?.exceptionMinutes;

      const label = notificationOptions.find((p) => {
        return p.value === value;
      });
      return label;
    }

    return null;
  };

  const handleRepeatDays = (day) => {
    const daysInWeek = [...(selectedWeekDays ?? [])];
    const index = daysInWeek.indexOf(day);
    if (daysInWeek.includes(day)) {
      daysInWeek.splice(index, 1);
    } else {
      daysInWeek.splice(index, 0, day);
    }
    if (setSelectedWeekDays) setSelectedWeekDays(daysInWeek);
  };

  const selectedWeekDaysWithValue = React.useMemo(() => {
    const uniqueSelectedDays = new Set(selectedWeekDays ?? []);
    const selectedDays = weekDay.reduce((allDays: string[], d) => {
      const { name, id } = d;
      if (uniqueSelectedDays.has(id)) {
        allDays.push(name);
      }
      return allDays;
    }, []);

    return selectedDays;
  }, [selectedWeekDays]);

  const handleScheduleNotification = (value) => {
    if (setPeriodicData) {
      setPeriodicData({ ...periodicData, minutes: value.value });
    }
  };

  const showValue = () => {
    if (!periodicData?.minutes) return '';

    if (periodicData?.minutes === 60) {
      return { label: ' 1 hour' };
    }
    return {
      label: `${periodicData?.minutes} minutes`,
    };
  };

  function getMomentObject(time: MaterialUiPickersDate | string) {
    if (time) {
      return moment(time, 'HH:mm a');
    }
    return null;
  }

  if (fromSchedule) {
    return (
      <>
        <Heading>Select Shift Timing & Janitor</Heading>
        <Heading fontSize="lg">Details</Heading>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Flex flexWrap="wrap" paddingY="10px" justifyContent="space-between">
            <CssTimeField
              minutesStep={15}
              label="Start Time"
              inputVariant="outlined"
              value={getMomentObject(shiftData?.startTime)}
              onChange={(dat) => handleDataChange(dat, 'startTime')}
              keyboardIcon={
                <TriangleDownIcon
                  style={{ width: '15px', marginRight: '-15px' }}
                />
              }
              inputProps={{
                style: {
                  borderRight: '1px solid #D3D3D3',
                },
              }}
            />
            <CssDateField
              label="start Date"
              required
              disablePast
              minDateMessage="Start date should be same or before than end date"
              maxDate={
                shiftData?.endsOn &&
                shiftData?.endsOn > dayjs().startOf('day').valueOf()
                  ? new Date(dayjs(shiftData?.endsOn).startOf('day').valueOf())
                  : new Date(dayjs().endOf('day').valueOf())
              }
              inputVariant="outlined"
              value={
                shiftData?.startDate &&
                shiftData?.startDate > dayjs().startOf('day').valueOf()
                  ? shiftData?.startDate
                  : undefined
              }
              onChange={(dat) => handleDataChange(dat, 'startDate')}
            />
            <Flex
              justifyContent="center"
              alignItems="center"
              position="relative"
            >
              <Text position="absolute" top="16px">
                to
              </Text>
            </Flex>
            <CssTimeField
              minutesStep={15}
              label="End Time"
              inputVariant="outlined"
              value={getMomentObject(shiftData?.endTime)}
              onChange={(dat) => handleDataChange(dat, 'endTime')}
              keyboardIcon={
                <TriangleDownIcon
                  style={{ width: '15px', marginRight: '-15px' }}
                />
              }
              inputProps={{
                style: {
                  borderRight: '1px solid #D3D3D3',
                },
              }}
            />
            <CssDateField
              label="End Date"
              inputVariant="outlined"
              value={
                shiftData?.endsOn &&
                shiftData?.endsOn > dayjs().startOf('day').valueOf()
                  ? shiftData?.endsOn
                  : undefined
              }
              disablePast
              required
              minDateMessage="End date should be same or later than start date"
              minDate={new Date(shiftData?.startDate)}
              onChange={(dat) => handleDataChange(dat, 'endsOn')}
            />
          </Flex>
        </MuiPickersUtilsProvider>

        <CustomInput
          type="children"
          placeholder="Notification "
          name="select"
          value={showExceptionMinute()}
          onChange={() => null}
        >
          <CustomSelect
            value={showExceptionMinute()}
            required={!isEdit}
            options={notificationOptions}
            onChange={handleScheduleNotification}
            variant="unstyled"
            placeholder="Notification"
          />
        </CustomInput>
      </>
    );
  }

  const onClose = () => {
    setopenDialogForRepeatShift(false);
    if (setSelectedWeekDays && handleEndOnAfterCancel) {
      setSelectedWeekDays([]);
      handleDataChange(false, 'repetative');
      handleEndOnAfterCancel();
    }
  };

  const isRepetative = () => {
    return (
      shiftData?.repetative &&
      shiftData?.startTime &&
      shiftData?.endTime &&
      shiftData?.weekdays?.length !== 0
    );
  };

  const showRepetativeWeekDays = () => {
    return `${getRepeatOrNotRepeat(
      selectedWeekDaysWithValue,
      false
    )} ${showShiftTemplateTime()}`;
  };
  const showShiftTemplateTime = () => {
    if (shiftData?.id && shiftData.startTime && shiftData.endTime) {
      return `${formatTime(
        moment(shiftData?.startTime).format('hh:mm:a')
      )} to ${formatTime(moment(shiftData?.endTime).format('hh:mm:a'))}`;
    }
    return `${moment(shiftData?.startTime).format('hh:mm:a')} to ${moment(
      shiftData?.endTime
    ).format('hh:mm:a')}`;
  };

  return (
    <>
      <CustomInput
        name="name"
        type="text"
        placeholder="Shift Name *"
        onChange={(e) => handleDataChange(e.target.value, 'name')}
        value={shiftData?.name}
        isRequired={!isEdit}
        isDisabled={isEdit}
      />

      <DatePickers
        startTime={shiftData?.startTime}
        endTime={shiftData?.endTime}
        handleDataChange={handleDataChange}
      />

      {/* <CustomSwitch
          checked={shiftData.raiseException}
          onChange={handleDataChange}
          color="primary"
          label="Raise Exception if late"
          type="raiseException"
        /> */}

      {/* {exceptionDuration()} */}
      {/* {finishJobsAtEnd()} */}

      <CustomInput
        type="children"
        placeholder="Notification"
        name="select"
        value={shiftData?.exceptionMinutes}
        onChange={() => null}
        isRequired
      >
        <CustomSelect
          value={showExceptionMinute()}
          options={notificationOptions}
          onChange={(value: any) => {
            handleDataChange(value.value, 'exceptionMinutes');
          }}
          variant="unstyled"
          isDisabled={isEdit}
          placeholder="Notification"
        />
      </CustomInput>

      <Flex flexDirection="column">
        <Flex gridGap="xsm" cursor="pointer">
          <Checkbox
            isChecked={shiftData?.repetative}
            onChange={(e) => {
              setopenDialogForRepeatShift(e.target.checked);
              handleDataChange(e.target.checked, 'repetative');
            }}
          />
          <Text
            color="grey"
            onClick={() => {
              setopenDialogForRepeatShift(true);
              handleDataChange(true, 'repetative');
            }}
          >
            Repeat Shift
          </Text>
        </Flex>
        {isRepetative() && (
          <Text color="primary.900" fontWeight="bold">
            {showRepetativeWeekDays()}
          </Text>
        )}
      </Flex>
      <RepeatModal
        shiftData={shiftData}
        openDialogForRepeatShift={openDialogForRepeatShift}
        selectedWeekDays={selectedWeekDays}
        onClose={onClose}
        setSelectedWeekDays={setSelectedWeekDays}
        handleRepeatDays={handleRepeatDays}
        onHandleRepeatDays={onHandleRepeatDays}
        handleDataChange={handleDataChange}
        saveDaysSelect={() => setopenDialogForRepeatShift(false)}
      />
    </>
  );
};
export const RepeatModal = ({
  openDialogForRepeatShift,
  onClose,
  selectedWeekDays,
  onHandleRepeatDays,
  handleRepeatDays,
  setSelectedWeekDays,
  shiftData,
  handleDataChange,
  saveDaysSelect,
}) => {
  return (
    <Modal
      blockScrollOnMount={false}
      isOpen={openDialogForRepeatShift}
      onClose={onClose}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Repeat Shift</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex gridGap="llg" alignItems="center">
            <Flex direction="column" gridGap="xl">
              <Text>Repeat on</Text>
              <Text>Ends on</Text>
            </Flex>

            <Flex direction="column" gridGap="lg">
              <HStack spacing="xxsm">
                {weekDay.map((weekday) => {
                  return (
                    <Button
                      key={weekday.id}
                      colorScheme={
                        selectedWeekDays?.includes(weekday.id) ? 'blue' : 'gray'
                      }
                      onClick={() => {
                        if (setSelectedWeekDays) {
                          if (onHandleRepeatDays) {
                            onHandleRepeatDays(weekday.id);
                            return;
                          }
                          handleRepeatDays(weekday.id);
                        }
                      }}
                    >
                      {weekday.name.charAt(0)}
                    </Button>
                  );
                })}
              </HStack>

              <Box
                border="1px solid gray"
                borderRadius="2px"
                width="max-content"
              >
                <Flex alignItems="center" gridGap="md" ml="6">
                  <Icon as={BsCalendar4} />
                  <DatePicker
                    selected={
                      shiftData?.endsOn
                        ? new Date(shiftData?.endsOn)
                        : new Date()
                    }
                    minDate={new Date()}
                    onChange={(d: any) =>
                      handleDataChange(dayjs(d).valueOf(), 'endsOn')
                    }
                  />
                </Flex>
              </Box>
            </Flex>
          </Flex>
        </ModalBody>

        <ModalFooter gridGap="md">
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => {
              saveDaysSelect();
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
const CssTimeField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#006DB8',
    },
    '&.MuiIconButton-root:hover': {
      background: 'transparent',
    },
    '&.MuiFormControl-root': {
      width: '160px',
    },
    '& .MuiInput-underline:after': {
      border: ' 1px solid #006DB8',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '006DB8',
      },
      '&:hover fieldset': {
        borderColor: '006DB8',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#006DB8',
      },
    },
  },
})(KeyboardTimePicker);
const CssDateField = withStyles({
  root: {
    // '& label.Mui-focused': {
    //   color: 'white',
    // },
    '&.MuiIconButton-root:hover': {
      background: 'transparent',
    },
    '&.MuiFormControl-root': {
      width: '200px',
    },
    '& .MuiInput-underline:after': {
      border: ' 1px solid #006DB8',
    },
    '& .MuiOutlinedInput-root': {
      // '& fieldset': {
      //   borderColor: 'white',
      // },
      // '&:hover fieldset': {
      //   borderColor: 'white',
      // },
      '&.Mui-focused fieldset': {
        borderColor: '#006DB8',
      },
    },
  },
})(KeyboardDatePicker);
