import React, { useEffect, useState, useContext } from 'react';

import { useParams } from 'react-router';
import { Typography, Grid } from '@material-ui/core';

import Map from '../Maps/map_polyline';
import IssueReports from '../Jobs/IssueReports';
import Loader from '../../Generics/snowm_loader';
import MarkerDetailOfAJob from '../Jobs/marker_detail_job';
import { getRouteForPoints } from '../../../helpers/polyline';
import RouteDetailOfAJob from '../Jobs/route_detail_of_a_job';
import { routesIncludingServiceTypes } from '../../../helpers/misc';
import { LocalizationContext } from '../../../../contexts/localization_context';
import {
  getIssueReports,
  getProviderByUid,
  getRouteByKey,
  getServicePointById,
  getMarkerLog,
  getServiceInfo,
  getJobInfo,
} from '../../../../controllers/snowm_firebase';

const JobDetail = () => {
  const [job, setJob] = useState(null);
  const [jobReports, setJobReports] = useState([]);
  const [routeDetailsOfJob, setRouteDetailsOfJob] = useState([]);
  const [latLong, setLatLong] = useState({});
  const [crewsOfReports, setCrewsOfReports] = useState({});
  const [jobInfo, setJobInfo] = useState({
    keys: [],
    isPeriodic: false,
  });
  const [markerKeys, setMarkerKeys] = useState([]);
  const [polyline, setPolyline] = useState('');
  const [markerLogs, setMarkerLogs] = useState();
  const [remainingKeys, setRemainingKeys] = useState();
  const [service, setService] = useState();

  const { strings } = useContext(LocalizationContext);

  const { ISSUE_REPORTS } = strings.detail ?? {};
  const { ROUTES } = strings.sidemenu ?? {};
  const { MAP_VIEW } = strings.misc ?? {};
  const { NO_MESSAGES } = strings.messages ?? {};

  const { jobId, markerLogKey } = useParams();

  useEffect(() => {
    let isSubscribe = true;

    getJobInfo(jobId).then((jobDetail) => {
      if (isSubscribe) {
        setJob({ ...jobDetail });
      }
    });

    const _updateReports = (messages) => {
      if (isSubscribe) setJobReports(messages);
    };

    getIssueReports(jobId, _updateReports).catch((error) => new Error(error));

    return () => {
      isSubscribe = false;
    };
  }, []);

  useEffect(() => {
    let isSubscribe = true;
    function _updateMarkerLogs(response) {
      if (isSubscribe) setMarkerLogs(response);
    }
    if (jobId) {
      getMarkerLog(jobId, markerLogKey, null, null, _updateMarkerLogs);
    }
    return () => {
      isSubscribe = false;
    };
  }, [jobId]);

  useEffect(() => {
    let isSubscribe = true;

    function updateService(res) {
      if (isSubscribe) setService(res);
    }
    if (markerLogs?.length > 0) {
      if (job?.job?.serviceKey) {
        getServiceInfo(job?.job?.serviceKey).then((res) => updateService(res));
      } else if (job?.serviceKey) {
        getServiceInfo(job?.serviceKey).then((res) => updateService(res));
      }
    }
    return () => {
      isSubscribe = false;
    };
  }, [job, markerLogs]);

  useEffect(() => {
    if (markerLogs && jobInfo.keys) {
      const markerIds = markerLogs?.map((log) => log.servicePointId);
      const setOfMarkerKeys = new Set(markerIds);
      const remainingMarkerIds = jobInfo.keys?.filter(
        (key) => !setOfMarkerKeys.has(key)
      );
      setRemainingKeys(remainingMarkerIds);
    }
  }, [markerLogs, jobInfo]);

  useEffect(() => {
    let isSubscribe = true;
    jobReports.forEach((report) => {
      const eachReport = {};
      eachReport[report.key] = {
        latitude: report.lat,
        longitude: report.long,
        type: report.type,
        message: report.message,
        key: report.key,
        jobReport: true,
      };
      if (isSubscribe) {
        setLatLong((prevData) => {
          return {
            ...prevData,
            ...eachReport,
          };
        });
      }
    });
    return () => {
      isSubscribe = false;
    };
  }, [jobReports]);

  const getCrewInfo = async (crewId) => {
    const crew = await getProviderByUid(crewId);
    return crew.name;
  };

  useEffect(() => {
    let isSubscribe = true;
    jobReports.forEach((report) => {
      getCrewInfo(report.providerUid).then((crewName) => {
        if (isSubscribe) {
          setCrewsOfReports((prevData) => {
            return {
              ...prevData,
              [report.key]: crewName,
            };
          });
        }
      });
    });

    return () => {
      isSubscribe = false;
    };
  }, [jobReports]);

  useEffect(() => {
    let keys = [];
    const isPeriodic = false;
    let isSubscribe = true;

    if (routesIncludingServiceTypes.includes(job?.serviceType)) {
      keys = job?.job?.allRoutes ?? job?.allRoutes;
    } else {
      keys = job?.job?.allMarkers ?? job?.allMarkers;
    }

    setJobInfo({ keys, isPeriodic });

    return () => {
      isSubscribe = false;
    };
  }, [job]);

  useEffect(() => {
    let isSubscribe = true;
    const promise = job?.allRoutes?.map((routeKey) => {
      return getRouteByKey(routeKey);
    });

    if (promise) {
      Promise.all(promise).then((routesInfo) => {
        if (isSubscribe) setRouteDetailsOfJob(routesInfo);
      });
    }

    return () => {
      isSubscribe = false;
    };
  }, [job]);

  useEffect(() => {
    const acc = routeDetailsOfJob.reduce((accumulator, routeDetail) => {
      if (routeDetail) {
        return [...accumulator, ...routeDetail.servicePointsKeys];
      }
      return accumulator;
    }, []);

    setMarkerKeys([...acc]);
  }, [routeDetailsOfJob]);

  useEffect(() => {
    let isSubscribe = true;

    const getMarkerDetails = async () => {
      const promise = markerKeys?.map((markerKey) => {
        return getServicePointById(markerKey);
      });

      const markerResponse = await Promise.all(promise);

      markerResponse.sort((a, b) => {
        return a.latitude - b.latitude;
      });

      const responsePolyline = await getRouteForPoints(markerResponse);
      if (isSubscribe) setPolyline(responsePolyline?.routePath ?? '');
    };

    if (markerKeys.length > 0) getMarkerDetails();

    return () => {
      isSubscribe = false;
    };
  }, [markerKeys]);

  if (!job || (!markerLogs && !remainingKeys)) {
    return <Loader />;
  }

  return (
    <div style={{ padding: 16, margin: 16, height: '100%' }}>
      {job && (
        <>
          <Typography variant="h4">{job.name}</Typography>
          {job.serviceType === 'outdoor' && (
            <>
              <Typography>{MAP_VIEW}</Typography>
              <div
                style={{
                  height: 400,
                }}
              >
                <Map points={latLong} polyline={polyline} />
              </div>
            </>
          )}
        </>
      )}

      {!routesIncludingServiceTypes.includes(job?.serviceType) ? (
        <>
          <Typography>Markers</Typography>
          {jobInfo?.keys?.length > 0 ? (
            <Grid container spacing={3}>
              {markerLogs?.map((log) => (
                <MarkerDetailOfAJob
                  key={log.key}
                  markerKey={log.servicePointId}
                  markerLog={log}
                  service={service}
                />
              ))}
            </Grid>
          ) : (
            <Typography>No Markers added on this job.</Typography>
          )}
        </>
      ) : (
        <>
          <Typography>{ROUTES}</Typography>
          {jobInfo?.keys?.length > 0 ? (
            <>
              {jobInfo.keys.map((routeKey) => {
                return (
                  <RouteDetailOfAJob
                    key={routeKey}
                    routeKey={routeKey}
                    jobKey={job?.key}
                    isPeriodic={jobInfo?.isPeriodic}
                  />
                );
              })}
            </>
          ) : (
            <Typography>No routes</Typography>
          )}
        </>
      )}

      <Typography>{ISSUE_REPORTS}</Typography>
      <Grid container spacing={3}>
        {jobReports ? (
          jobReports.map((report) => (
            <IssueReports
              key={report.key}
              crew={crewsOfReports[report.key]}
              report={report}
            />
          ))
        ) : (
          <i>{NO_MESSAGES}</i>
        )}
      </Grid>
    </div>
  );
};

export default JobDetail;
