import React, { useState } from 'react';

import styled from 'styled-components';
import { Alert } from '@material-ui/lab';
import { Card, Divider, Snackbar } from '@material-ui/core';
import {
  createIcon,
  QuestionIcon,
  QuestionOutlineIcon,
} from '@chakra-ui/icons';

import {
  Button,
  Switch,
  Tooltip,
  Checkbox,
  Flex,
  Box,
  Text,
} from '@chakra-ui/react';
import Span from '../../Common/Span';
import color from '../../../utils/color';
import ADialog from '../../Common/styled';
import AlertBox from '../../Common/alert_box';
import { JobConfiguration } from './JobConfiguration';
import { Col, Row } from '../../../styles/snowm_styled';
import { Progress } from '../../Generics/snowm_loader';
import {
  cleanOlderJobs,
  updateCompanyClosingAndOpening,
} from '../../../controllers/snowm_firebase';
import { SwitchContainer } from '../../../reusableComponent/shiftSchedule/snowm_add_job';

interface GeneralSettingsProps {
  company: any; // TODO: change the type
  setCompany: (_: any) => void;
}
const settings = {
  general: 'General',
};

const GeneralSettings = (props: GeneralSettingsProps) => {
  const { company, setCompany } = props;
  const [openAlert, setOpenAlert] = useState(false);
  const [isUpdatingCompany, setIsUpdatingCompany] = useState(false);
  const [isCleaningStarted, setIsCleaningStarted] = useState(false);
  const [isConfigureJobOpen, setIsConfigureJobOpen] = useState(false);

  const handleChangeCleanJob = async (event) => {
    setIsUpdatingCompany(true);
    const updatedCompany = {
      ...company,
      markerFeatures: {
        ...company.markerFeatures,
        shouldCleanJobs: event.target.checked,
      },
    };
    setCompany(updatedCompany);
    await updateCompanyClosingAndOpening(updatedCompany);
    setIsUpdatingCompany(false);
  };

  const handleCleanOlderBtn = () => {
    setOpenAlert(true);
  };

  const handleOlderJobsCleaning = async () => {
    setOpenAlert(false);
    setIsCleaningStarted(true);
    await cleanOlderJobs();
  };

  const cancelCleaningOlderJobs = () => {
    setOpenAlert(false);
  };

  const renderLabel = (title, subTitle) => {
    return (
      <Col>
        <Span font-weight="bold">{title}</Span>
        <Span size="12px" color={color.grey}>
          {subTitle}
        </Span>
      </Col>
    );
  };

  const handleClose = () => {
    setIsCleaningStarted(false);
  };

  const handleConfigureJobButton = () => {
    setIsConfigureJobOpen(true);
  };

  const handleCancelClick = () => {
    setIsConfigureJobOpen(false);
  };

  function showTooltipDetails() {
    return (
      <Flex color="white" flexDirection="column" gridGap="xsm">
        <Text color="highlight.200"> Once enabled, the system will ,</Text>
        <Text color="highlight.200">
          a) automatically mark assigned jobs that were not started as `Not
          Started` (24 hours after their created time), and
        </Text>
        <Text color="highlight.200">
          b) automatically mark assigned jobs only partially completed as `Not
          Completed` 12 hours after they were started. In either case, the
          system will remove these jobs from the Crew dashboard, and they will
          not be able to make any further updates at later times.
        </Text>
      </Flex>
    );
  }
  const description = () => {
    return (
      <Tooltip label={showTooltipDetails()}>
        <QuestionOutlineIcon width="20px" height="fit-content" />
      </Tooltip>
    );
  };

  return (
    <>
      <Box
        boxShadow="0px 12px 24px #ECEEF5"
        borderRadius="16px"
        opacity="1"
        style={{ padding: 8 }}
        marginTop="20px"
      >
        <Col grid-gap="8px">
          <Row justify-content="space-between">
            <Col>
              <Flex padding="40px 20px">
                <Checkbox
                  borderColor=" #006DB8"
                  borderRadius="4px"
                  opacity="1"
                  onChange={handleChangeCleanJob}
                  isChecked={company?.markerFeatures?.shouldCleanJobs ?? true}
                />
                <Text
                  style={{ font: 'normal normal medium 16px/28px Montserrat' }}
                  color="#162350"
                  paddingLeft="15px"
                  font-weight="bold"
                  letterSpacing="0.26px"
                >
                  Automatic Shift Cleanup
                </Text>
                <Text paddingLeft="15px">{description()}</Text>
              </Flex>
            </Col>
            {/* {isUpdatingCompany && <Progress size="24px" progressColor="blue" />} */}
          </Row>
          {/* <Divider /> */}

          <ButtonContainer
            style={{ display: 'none' }}
            justify-content="space-around"
            grid-gap="4px"
          >
            <div>
              <Button
                colorScheme="blue"
                background="#006DB8 0% 0%"
                boxShadow=" 0px 3px 6px #006DB85C"
                borderRadius="5px"
                onClick={handleCleanOlderBtn}
              >
                Clean Historical Jobs
              </Button>
            </div>
            <Span size="12px" color={color.grey}>
              Will clean all the not started and not completed jobs from Crew
              Dashboard
            </Span>
          </ButtonContainer>

          <ButtonContainer style={{ display: 'none' }} grid-gap="4px">
            <div>
              <Button
                colorScheme="blue"
                background="#006DB8 0% 0%"
                boxShadow=" 0px 3px 6px #006DB85C"
                borderRadius="5px"
                onClick={handleConfigureJobButton}
              >
                Configure Location
              </Button>
            </div>
            <Span size="12px" color={color.grey}>
              One time marker configuration is necessary to assign/create
              "No-Touch Job" & if marker is relocated from one property or
              another property.
            </Span>
          </ButtonContainer>
        </Col>
      </Box>

      <Snackbar
        open={isCleaningStarted}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert severity="info" onClose={handleClose}>
          <Span font-size="16px">Cleaning historical jobs.</Span>
        </Alert>
      </Snackbar>
      <AlertBox
        open={openAlert}
        subtitle="This action will mark all the not started jobs since last 24 hours to not started status and all the jobs that are started but not completed since last 12 hours to not completed status."
        handleNegativeAction={cancelCleaningOlderJobs}
        handlePositiveAction={handleOlderJobsCleaning}
        negativeText="No"
        positiveText="Yes"
      />
      <ADialog open={isConfigureJobOpen}>
        <JobConfiguration
          handleCancel={handleCancelClick}
          isOpen={isConfigureJobOpen}
        />
      </ADialog>
    </>
  );
};

const StyledSwitchContainer = styled(SwitchContainer)`
  width: 90%;
`;

const ButtonContainer = styled(Col)`
  margin: 8px 0 0 16px;
`;

const Container = styled(Col)`
  margin: 16px;
`;

const StyledRow = styled(Row)`
  margin: 8px 16px;
`;

export default GeneralSettings;
