import { SearchIcon } from '@chakra-ui/icons';
import { Box, Divider, Flex, Heading, Text } from '@chakra-ui/react';
import { useContext, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../../../contexts/auth_context';
import { getManager } from '../../../controllers/snowm_firebase';
import {
  filterDataByName,
  findSortType,
  sortByEntity,
} from '../../../helpers/misc';
import { DetailsDrawer } from '../../../reusableComponent/DetailsDrawer';
import ZeroStateComponent from '../../../reusableComponent/ZeroStateComponent';
import SearchBar from '../../Common/Search_Bar';
import Loader from '../../Generics/snowm_loader';
import EmptyJanitorImage from '../../../assets/svgs/EmptyJanitorImage.svg';
import { ManagerTable } from './ManagerTable';

const ManagerPage = () => {
  const headers = useMemo(
    () => [{ name: 'Name', sort: 'asc' }, { name: 'Last Served' }],
    []
  );
  const [managerLists, setManagerLists] = useState();
  const [filteredAdmins, setFilteredAdmins] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [managerDetails, setManagerDetails] = useState();
  const [openManagerDetails, setOpenManagerDetails] = useState(false);
  const [managerHeader, setManagerHeader] = useState(headers);

  const [searchText, setSearchText] = useState();
  const userDetails = useContext(AuthContext);
  const { company } = userDetails;

  useEffect(() => {
    getManager(company)
      .then((res) => {
        setManagerLists(res);
        setFilteredAdmins(res);
      })
      .catch((e) => new Error(e));
  }, []);

  useEffect(() => {
    if (searchText || searchText === '') {
      const name = filterDataByName(searchText, managerLists);
      setFilteredAdmins(Object.values(name ?? {}));
    }
  }, [searchText]);

  useEffect(() => {
    if (managerLists) {
      const { sort } = findSortType(managerHeader, 'Name');
      const sortedManagers = managerLists?.sort((a, b) =>
        sortByEntity(a.name, b.name, sort)
      );
      setFilteredAdmins(sortedManagers);
    }
  }, [managerLists]);

  const onClickAddButtonOfManager = () => {
    setOpenDrawer(true);
  };
  function handleCardPress(manager) {
    setManagerDetails(manager);
    setOpenManagerDetails(true);
  }
  function closeDetailsDrawer() {
    setOpenManagerDetails(false);
  }

  const searchAdmins = (e) => {
    setSearchText(e.target.value);
  };

  if (!filteredAdmins) {
    return <Loader />;
  }
  const renderDetails = () => (
    <ManagerDetails managerDetails={managerDetails} />
  );

  const detailTabs = {
    Details: renderDetails(),
    Schedule: 'To be designed',
  };
  return (
    <Flex
      bg="background.500"
      overflow="auto"
      p="xxxl"
      direction="column"
      gridGap="sm"
    >
      <Flex direction="column" gridGap="llg">
        <Heading as="h4">Administrators</Heading>
        <SearchBar
          fromAdministrator
          title="Administrator"
          LeftIcon={<SearchIcon />}
          onChange={(e) => searchAdmins(e)}
          selectedEntities={filteredAdmins}
          setSelectedEntities={setFilteredAdmins}
        />
      </Flex>
      {!filteredAdmins.length ? (
        <ZeroStateComponent
          title="Searched administrators not found"
          header="Administrators"
          image={EmptyJanitorImage}
        />
      ) : (
        <ManagerTable
          handleCardPress={handleCardPress}
          setOpenManagerDetails={setOpenManagerDetails}
          openManagerDetails={openManagerDetails}
          filteredAdmins={filteredAdmins}
          setFilteredAdmins={setFilteredAdmins}
          setManagerHeader={setManagerHeader}
          managerHeader={managerHeader}
        />
      )}

      <DetailsDrawer
        open={openManagerDetails}
        title={`${managerDetails?.name}
        `}
        // subtitle={propertyDetails.address}
        image={managerDetails?.imageUrl}
        details={managerDetails}
        onClose={closeDetailsDrawer}
        detailTabs={detailTabs}
        hideEditDeleteButton
        width="54px"
        // openActionDrawer={openActionDrawer}
        // onEdit={handleEditLocationDetails}
        // onDelete={handleDeleteSingleLocation}
        // setOpenDeleteDialog={setOpenDeleteDialog}
      />
    </Flex>
  );
};
const ManagerDetails = ({ managerDetails }) => {
  return (
    <Box>
      <Header>Login method</Header>
      <Text>{managerDetails?.loginMode ?? '--'} </Text>
      <Divide />
      <Header>Phone</Header>
      <Text> {managerDetails?.phoneNumber ?? '--'}</Text>
      <Divide />
      <Header>Email</Header>
      <Text>{managerDetails?.email ?? '--'} </Text>
      <Divide />
    </Box>
  );
};

export default ManagerPage;
const Divide = styled(Divider)`
  margin: 10px 0px;
`;
const Header = styled(Text)`
  text-align: left;
  font: normal normal 600 16px/30px Montserrat;
  letter-spacing: 0.26px;
  color: #162350;
  opacity: 1;
  // padding-top: 10px;
`;
