/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_firebase.js
 *
 *  Description: Provides all the firebase functioality
 *
 *  Author: Nabin Kharal (nabin@brainants.com), Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 4-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import dayjs from 'dayjs';
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/messaging';
import 'firebase/storage';
import moment, { Moment } from 'moment';

import roles from '../enums/roles';
import { getLowerCasedWord } from '../helpers/misc';
import { generateRandomString } from '../helpers/string';
import { Schedule, Shift } from '../Components/Private/schedule/shift';
import {
  getCurrentTimeInEpoch,
  getStartTimeStampOfTheDay,
  getTimeInEpoch,
  getTimeInStartOfMinute,
} from '../helpers/date';

// stores the configuation data for the firebase project
const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

let companyKey: null | string = null;
let userRoleInCompany: null | string = null;

let init = false;

/**
 *************************************************************************************
 * @brief  (initFirebase) initializes firebase application, should be initialized only
 * once for a firebase project
 * @param{} ()
 *
 * @returns undefined
 *************************************************************************************
 */

export const getUserCollectionName = () => {
  switch (userRoleInCompany) {
    case 'administrator':
      return 'administrators';
    case 'crew':
      return 'providers';
    case 'customer':
      return 'users';
    default:
      // eslint-disable-next-line no-throw-literal
      throw 'Invalid user role';
  }
};

export const checkIfEmailExists = (email) => {
  return firebase.auth().fetchSignInMethodsForEmail(email);
};

export async function getUserInfo(uid, updateUser) {
  const ref = firebase.firestore().collection(getUserCollectionName()).doc(uid);
  if (updateUser) {
    return ref.onSnapshot((doc) => {
      updateUser(doc.data() ?? {});
    });
  }
  const response = await ref.get();
  return response.data() ?? {};
}

/**
 ****************************************************************************
 * @brief  (parseJwt) parse the jwt token
 * @param{type {string}} (token)
 *
 * @returns object
 ****************************************************************************
 */
export function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export function initFirebase(setAuthenticated, setCompanyKey, setUser) {
  if (!init) {
    firebase.initializeApp(config);
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        // const userDetails = await getUserInfo(user?.uid);
        const tokenId = await user.getIdToken();
        const claims = parseJwt(tokenId);
        const keyOfCompany = localStorage.getItem('selectedCompanyKey');
        const userRole = localStorage.getItem('userRoleInCompany');

        if (claims?.roles?.length === 1) {
          companyKey = claims?.roles?.[0]?.companyKey;
          userRoleInCompany = claims?.roles?.[0]?.role;
          setUser(claims); // TODO: manage user from authenticated object in customer app
          setCompanyKey(companyKey!);
          setAuthenticated({ userClaims: claims, canLogIn: true });
        } else {
          setAuthenticated({ userClaims: claims, canLogIn: false });
        }

        // if (userRole?.role === roles.administrator) {
        //   companyKey = userRole.companyKey;
        //   userRoleInCompany = userRole.role;
        //   setCompanyKey(companyKey!);
        //   setAuthenticated({ userClaims: claims, canLogIn: true });
        // } else {
        //   // setCompanyKey(companyKey!); //This is not needed, i guess
        // }
      } else {
        setAuthenticated(false);
      }
    });
    init = true;
  }
}

export const { auth } = firebase;

export const getAllCompanies = async () => {
  const res = await firebase.firestore().collection('customers').get();
  return res.docs.map((d) => d.data());
};

// authentication functions
/*
 ****************************************************************************
 * @brief  (loginWithEmailPassword) tries to login with given email and password
 * @param{type {string,string}} (email,password)
 *
 * @returns promise
 ****************************************************************************
 */
export async function loginWithCredential(authCredential) {
  const loggedinInfo = await firebase
    .auth()
    .signInWithCredential(authCredential);

  const token = await loggedinInfo?.user?.getIdToken();
  const claims = parseJwt(token);

  const userRoles = claims?.roles ?? [];
  const setOfUserRoles = new Set(userRoles.map((r) => r.role));

  if (claims.super_admin) {
    return { pathname: '/choose-company' };
  }

  // TODO: If the user is superadmin?

  // TODO: if roles if empty, then redirect to login

  // This is removed and roles can contain customer too
  if (
    !setOfUserRoles.has(roles.administrator) &&
    !setOfUserRoles.has(roles.customer) &&
    !setOfUserRoles.has(roles.superAdmin)
  ) {
    alert('Not allowed to login.');
    await logout();
    return { pathname: '/login' };
  }

  if (claims?.roles?.length === 1) {
    handleCompanySelection(userRoles[0]);
    return {
      pathname: setOfUserRoles.has(roles.customer) ? '/customer' : '/admin',
      data: claims,
      keyOfCompany: userRoles[0].companyKey,
      userRoleInCompany: userRoles[0].role,
    };
  }

  if (claims?.roles?.length > 1) {
    return { pathname: '/choose-company' };
  }

  throw Error;
}

export const handleCompanySelection = (roleObj) => {
  const keyOfCompany = roleObj.companyKey;
  const { role } = roleObj ?? {};
  localStorage.setItem('selectedCompanyKey', keyOfCompany);
  // localStorage.setItem('userRoleInCompany', role);
  companyKey = keyOfCompany;
  userRoleInCompany = role;
};

export const getUserDetails = async () => {
  const user = firebase.auth().currentUser;

  const token = await user?.getIdToken();
  const claims = parseJwt(token);
  return claims?.roles;
};

export async function updatePassword(newPassword, oldPassword, fromProfile) {
  const user = firebase.auth().currentUser;

  const credential = firebase.auth.EmailAuthProvider.credential(
    user!.email!,
    oldPassword
  );

  await user!.reauthenticateWithCredential(credential);
  await firebase
    .firestore()
    .collection(getUserCollectionName())
    .doc(user!.uid)
    .update({ loggedIn: true });

  await user!.updatePassword(newPassword);

  const selectedCompanyKey = localStorage.getItem('selectedCompanyKey');

  const token = await user!.getIdToken();

  const claims = parseJwt(token);

  const companiesKeys = claims?.roles?.map((claim) => claim.companyKey!);
  const setOfCompaniesKeys = new Set(companiesKeys);

  if (selectedCompanyKey!) {
    companyKey = selectedCompanyKey;
    window.location.replace('/admin');
    return;
  }

  if (!selectedCompanyKey && claims.roles?.length === 1) {
    companyKey = companiesKeys?.[0];
    if (!fromProfile) {
      window.location.replace('/admin');
    }
    return;
  }

  if (!selectedCompanyKey && claims.roles?.length > 1) {
    if (!fromProfile) {
      window.location.replace('/choose-company');
    }
  }

  if (setOfCompaniesKeys?.has(selectedCompanyKey!)) {
    if (!fromProfile) {
      window.location.replace('/admin');
    }
  }
  if (!fromProfile) {
    window.location.replace('/admin');
  }
}

export const storeCompanyKey = (keyOfCompany) => {
  companyKey = keyOfCompany;
};

/**
 ****************************************************************************
 * @brief  (createNewProvider) creates new provider using the provided credentials
 * @param{type {Object}} (provider)
 *
 * @returns promise
 ****************************************************************************
 */
export async function createCrew(prov) {
  const provider = { ...prov, archive: false };
  const lowerCasedName = provider?.name?.toLowerCase() ?? '';
  provider.companyKey = companyKey;
  provider.label = lowerCasedName;
  if (provider.uid) {
    const updateProvider = firebase
      .functions()
      .httpsCallable('providersGroup-providersGroup-updateServiceProvider');

    const res = await updateProvider({ ...provider });

    if (res.data.success) {
      await firebase
        .firestore()
        .collection('providers')
        .doc(provider.uid)
        .set({ ...provider }, { merge: true });
    }
    return res;
  }

  const addProviderFunc = firebase
    .functions()
    .httpsCallable('providersGroup-providersGroup-createServiceProvider');

  return addProviderFunc({ ...provider });
}

export function getCode(email) {
  const getCodeForEmail = firebase
    .functions()
    .httpsCallable('authGroup-authGroup-sendResetPassword');

  return getCodeForEmail({ email, app: 'admin' }); // TODO: app as admin and customer
}

export async function resetPassword({ code, password }) {
  const res = await firebase
    .database()
    .ref()
    .child('res-data')
    .child(code)
    .once('value');

  const oobCode = res.val();

  if (!oobCode) {
    throw new Error();
  }

  firebase.auth().confirmPasswordReset(oobCode, password);
}

export async function getRealTimeProviders(updateProviders) {
  firebase
    .firestore()
    .collection('providers')
    .where('companyKey', '==', companyKey!)
    .onSnapshot((data) => {
      const _allProviders = data.docs.reduce((acc, p) => {
        const crew: any = {
          ...p.data(),
          crewType: p.data().crewType ?? 'Individual',
        };

        if (crew.crewType !== 'Individual' || crew.archived) {
          return acc;
        }
        acc[p.data().uid] = p.data();
        return acc;
      }, {});
      updateProviders(_allProviders);
    });
}

export function getLocationOfProvider(providerUid, onChange) {
  firebase
    .database()
    .ref()
    .child('locations')
    .child(companyKey!)
    .child(providerUid)
    .on('value', (snap) => {
      onChange(providerUid, snap.val());
    });
}

// returns location information of all providers inside a company
export async function getProvidersLocation() {
  const locs = await firebase
    .database()
    .ref()
    .child('locations')
    .child(companyKey!)
    .once('value');
  return locs.val() ?? {};
}

// gets and updates location information of all the providers inside a company
export function getRealTimeLocationOfAllProviders(onChange) {
  firebase
    .database()
    .ref()
    .child('locations')
    .child(companyKey!)
    .on('value', (snap) => {
      onChange(snap.val());
    });
}

export async function getRealTimeLogs(updateLogs) {
  firebase
    .firestore()
    .collection('customers')
    .doc(companyKey!)
    .collection('logs')
    .orderBy('date', 'desc')
    .limit(500)
    .onSnapshot((res) => {
      const logs = res.docs.map((d) => {
        if (!d.data().topic) {
          return { ...d.data(), topic: 'General Information' };
        }
        return d.data();
      });
      updateLogs(logs);
    });
}

export function getRealTimeRoutes(updateRoutes) {
  let _allRoutes = {};
  return firebase
    .firestore()
    .collection('routes')
    .where('companyKey', '==', companyKey!)
    .onSnapshot((data) => {
      _allRoutes = {};
      data.docs.forEach((r) => {
        _allRoutes[r.data().key] = r.data();
      });
      updateRoutes(_allRoutes);
    });
}

export async function editRoute(r) {
  const route = { ...r };
  return firebase
    .firestore()
    .collection('routes')
    .doc(route.key)
    .update({ ...route });
}

export async function createServiceRoute(r) {
  const route = { ...r };
  route.companyKey = companyKey;
  const docRef = firebase.firestore().collection('routes').doc();
  route.key = docRef.id;
  await docRef.set(route);
  return docRef.id;
}

export function getRealTimeServicePointsWithBattery(updateServicePoints) {
  return firebase
    .firestore()
    .collection('servicePoints')
    .where('companyKey', '==', companyKey)
    .where('deviceDetail.battery', '<=', 20)
    .onSnapshot((data) => {
      const allMarkers = data.docs.reduce((acc, p) => {
        const marker = p.data();
        const { key: markerKey, archive: isArchived } = marker;
        if (isArchived) {
          return acc;
        }
        return { ...acc, [markerKey]: { ...marker, archive: false } };
      }, {});
      updateServicePoints(allMarkers);
    });
}

export function getRealTimeServicePoints(updateServicePoints) {
  return firebase
    .firestore()
    .collection('servicePoints')
    .where('companyKey', '==', companyKey!)
    .where('archive', '==', false)
    .orderBy('name')
    .onSnapshot((data) => {
      const allMarkers = data.docs.reduce((acc, p) => {
        const marker = p.data();
        const { key: markerKey, archive: isArchived } = marker;
        if (isArchived) {
          return acc;
        }
        return { ...acc, [markerKey]: { ...marker } };
      }, {});
      updateServicePoints(allMarkers);
    });
}

export function getRealTimeUnAssignedServicePoints(updateServicePoints) {
  let _allServicePoints = {};
  return firebase
    .firestore()
    .collection('servicePoints')
    .where('companyKey', '==', companyKey!)
    .where('assigned', '==', false)
    .onSnapshot((data) => {
      _allServicePoints = {};
      data.docs.forEach((p) => {
        _allServicePoints[p.data().key] = p.data();
      });
      updateServicePoints(_allServicePoints);
    });
}

/*
 ****************************************************************************
 * @brief  get the service point by id
 * @param{type {string}} (servicePointId)
 *
 * @returns promise
 ****************************************************************************
 */
export async function getServicePointById(servicePointId) {
  const servicePoint = await firebase
    .firestore()
    .collection('servicePoints')
    .doc(servicePointId)
    .get();

  return servicePoint.data() || {};
}

/**
 ****************************************************************************
 * @brief  get the provider  by uid
 * @param{type {string}} (crewUid)
 *
 * @returns promise
 ****************************************************************************
 */
export async function getProviderByUid(providerUid) {
  if (!providerUid) {
    return {};
  }
  const result = await firebase
    .firestore()
    .collection('providers')
    .doc(providerUid)
    .get();

  return result.data() ?? {};
}
export async function getScheduleOfJanitors(janitorKey) {
  const startofToday = dayjs().startOf('day').unix();

  const result = await firebase
    .firestore()
    .collection('jobs')
    .where('providerUids', 'array-contains', janitorKey)
    .where('createdDate', '>=', startofToday)
    .limit(50)
    .get();
  const janitors = result.docs.map((d) => d.data());

  return janitors;
}

export function getRealTimeProvider(providerUid, updateStatus) {
  if (!providerUid) {
    return {};
  }
  return firebase
    .firestore()
    .collection('providers')
    .doc(providerUid)
    .onSnapshot((doc) => {
      updateStatus(doc.data());
    });
}

/*
 ****************************************************************************
 * @brief  get the route by key.
 * @param{type {string}} (routeKey)
 *
 * @returns promise
 ****************************************************************************
 */
export async function getRouteByKey(routeKey) {
  const routeData = await firebase
    .firestore()
    .collection('routes')
    .doc(routeKey)
    .get();

  return routeData.data();
}

export function getRealTimeServices(updateServices) {
  let _allServices = {};
  return firebase
    .firestore()
    .collection('services')
    .where('companyKey', '==', companyKey!)
    .onSnapshot((data) => {
      _allServices = {};
      data.docs.forEach((p) => {
        _allServices[p.data().key] = p.data();
      });
      updateServices(_allServices);
    });
}

/**
 ****************************************************************************
 * @brief  get the services of a company
 * @param{type {}} ()
 *
 * @returns promise
 ****************************************************************************
 */
export async function getServices() {
  const servicesData = await firebase
    .firestore()
    .collection('services')
    .where('companyKey', '==', companyKey!)
    .get();

  return servicesData.docs;
}
/**
 ****************************************************************************
 * @brief  Logs the current user out from the application.
 *
 * @returns Promise
 ****************************************************************************
 */
export async function logout() {
  await firebase.auth().signOut();
  localStorage.removeItem('selectedCompanyKey');
  companyKey = null;
  window.location.reload();
}

/*
 ****************************************************************************
 * @brief  (getCurrentUser) calls the given callback whenever authentication
 *  state is changed
 * @param{type {function}} (onChanged)
 *
 * @returns undefuned
 ****************************************************************************
 */
export async function getCurrentUser(onChange) {
  firebase.auth().onAuthStateChanged(async (user) => {
    onChange(user);
  });
}

/*
 ****************************************************************************
 * @brief  (createNewService) creates new service using the provided credentials
 * @param{type {Object}} (service)
 *
 * @returns promise
 ****************************************************************************
 */
export async function createEditService(serv) {
  const service = { ...serv };
  service.companyKey = companyKey;
  let ref;
  if (!service.key) {
    ref = firebase.firestore().collection('services').doc();
    service.key = ref.id;
  } else {
    ref = firebase.firestore().collection('services').doc(service.key);
  }
  return ref.set(service);
}

// BeaconM firebase functions

/*
 ****************************************************************************
 * @brief  (getRealTimeBeaconMs) calls the given callback BeaconMs are changed
 *
 * @param{type {function}} (updateBeaconMs)
 *
 * @returns undefined
 ****************************************************************************
 */
export async function getRealTimeBeaconMs(updateBeaconMs) {
  const _allBeaconMs = {};
  firebase
    .firestore()
    .collection('beacons')
    .where('companyKey', '==', companyKey!)
    .onSnapshot((data) => {
      data.docs.forEach((p) => {
        _allBeaconMs[p.data().uuid] = p.data();
      });
      updateBeaconMs(_allBeaconMs);
    });
}

export async function editRealTimeDeviceName({ uuid, ...device }) {
  return firebase.firestore().collection('beacons').doc(uuid).update(device);
}

/*
 ****************************************************************************
 * @brief  (getUnAssignedBeacons) returns the list of unassigned beacons
 *
 * @param{type {}} ()
 *
 * @returns promise
 ****************************************************************************
 */

export function getRealTimeUnAssignedBeacons(updateBeacons) {
  return firebase
    .firestore()
    .collection('beacons')
    .where('companyKey', '==', companyKey!)
    .where('assigned', '==', false)
    .onSnapshot((data) => {
      updateBeacons(data.docs.map((p) => p.data()));
    });
}

/*
 ****************************************************************************
 * @brief  (uploadImageToStorage) upload service point image
 *
 * @param{type {File, string}} (image, imageName)
 *
 * @returns promise
 ****************************************************************************
 */
export async function uploadImageToStorage(image, imageName) {
  const snapshot = await firebase
    .storage()
    .ref()
    .child(`service_point_images/${imageName}`)
    .put(image);
  return snapshot.ref.getDownloadURL();
}

/*
 ****************************************************************************
 * @brief  (addNewServicePoint) add new service point
 *
 * @param{type {Object} (formData)
 *
 * @returns promise
 ****************************************************************************
 */
export async function addEditNewServicePoint(formData) {
  const data = { ...formData };
  data.companyKey = companyKey;
  data.label = data.name?.toLowerCase();
  let ref;
  if (!data.key) {
    ref = firebase.firestore().collection('servicePoints').doc();
    data.key = ref.id;
    data.archive = false;
  } else {
    ref = firebase.firestore().collection('servicePoints').doc(data.key);
  }

  await ref.set(data, { merge: true });
  return data;
}

/*
 ****************************************************************************
 * @brief  (getRealTimeJobs) calls the given callback Jobs are changed
 *
 * @param{type {function}} (updateJobs)
 *
 * @returns undefined
 ****************************************************************************
 */

export async function getRealTimeJobs(
  updateJobs: (allJobs: Record<string, Schedule>) => void,
  providerKey,
  servicePointKey
) {
  const startingTimeStampOfToday = moment().startOf('day').valueOf();
  let ref = firebase
    .firestore()
    .collection('jobs')
    .where('companyKey', '==', companyKey!)
    .where('createdDate', '>=', startingTimeStampOfToday)
    .orderBy('createdDate', 'desc');

  if (providerKey) {
    ref = ref.where('providerUid', '==', providerKey);
  }

  if (servicePointKey) {
    ref = ref.where('servicePointKey', '==', servicePointKey);
  }

  ref.onSnapshot((res) => {
    const _allJobs = res.docs.reduce(
      (acc: Record<string, Schedule>, jobRes) => {
        const job = jobRes.data() as Schedule;

        acc[job.key] = { ...job, closedBy: job.closedBy ?? 'crew' };

        return acc;
      },
      {}
    );

    updateJobs(_allJobs);
  });
}

export async function getRealTimePeriodicJobs(updateJobs) {
  const ref = firebase
    .firestore()
    .collection('customers')
    .doc(companyKey!)
    .collection('shiftJobs');

  ref.onSnapshot((data) => {
    const _allJobs = {};
    data.docs.forEach((p) => {
      _allJobs[p.data().key] = p.data();
    });
    updateJobs(_allJobs, 'Periodic Jobs');
  });
}

export async function getJobInfoFromShift(shiftId, isPeriodic = false) {
  const collectionName = isPeriodic ? 'periodicJobs' : 'jobs';

  if (isPeriodic) {
    const response = await firebase
      .firestore()
      .collection('customers')
      .doc(companyKey!)
      .collection('shiftJobs')
      .doc(shiftId)
      .get();

    return response.data();
  }

  const response = await firebase
    .firestore()
    .collection(collectionName)
    .where('companyKey', '==', companyKey!)
    .where('key', '==', shiftId)
    .get();

  return response.docs[0]?.data() ?? null;
}

// firebase functions for pdf data

/**
 * @brief  (getActiveRoutesForProvider) returns the list of active routes
 * for a provider
 *
 * @param{type {string, string, string}} (providerUid, startDate, endDate)
 *
 * @returns Promise
 */

export async function getJobsForCrew(crewUid, startDate, endDate) {
  const response = await firebase
    .firestore()
    .collection('jobs')
    .where('providerUids', 'array-contains', crewUid)
    .orderBy('createdDate', 'desc')
    .where('createdDate', '>=', startDate)
    .where('createdDate', '<=', endDate)
    .get();

  const jobs = response.docs.map((d) => d.data());

  return jobs;
}

const getOnlyNonArchivedCrew = (crews, currentCrew) => {
  const crew = currentCrew.data();

  if (!crew.archived) {
    return [...crews, crew];
  }

  return crews;
};

/** 
 * @brief  (getProvidersOfCompany) returns the list of providers of company.

 * @param crewType(optional) Crew Type to be filtered out.
 * @param fetchEnabledUsersOnly(optional) To fetch enabled user only. 
 
 * @returns Promise
 */

export async function getProvidersOfCompany(
  fetchEnabledUsersOnly,
  vendorsOnly = false,
  includeArchivedCrew = false
) {
  let ref = firebase
    .firestore()
    .collection('providers')
    .where('companyKey', '==', companyKey!);
  // .where('archived', '==', false);

  if (typeof fetchEnabledUsersOnly === 'boolean') {
    ref = ref.where('disabled', '==', !fetchEnabledUsersOnly);
  }

  if (vendorsOnly) {
    ref = ref.where('crewType', '==', 'Org');
  }
  const providers = await ref.get();

  let companyCrews;
  if (includeArchivedCrew) {
    companyCrews = providers.docs.map((doc) => doc.data());
  } else {
    companyCrews = providers.docs.reduce(getOnlyNonArchivedCrew, []);
  }

  return companyCrews;
}

export const getCrewsForJobs = async () => {
  const response = await getProvidersOfCompany(true);

  const crewMembers = Object.values(response).map((provider: any) => {
    const { masterUid, uid } = provider;
    return { value: masterUid ?? uid, label: provider.name };
  });

  return crewMembers;
};

export const getManager = async (selectedCompany) => {
  const manager = await firebase
    .firestore()
    .collection('administrators')
    .where('companyKey', '==', selectedCompany.key)
    .get();

  return manager.docs.map((managerList) => managerList.data()) || [];
};

export async function getRoutesForService(service) {
  const routes = await firebase
    .firestore()
    .collection('routes')
    .where('companyKey', '==', companyKey!)
    .where('serviceKey', '==', service.key)
    .get();
  return routes.docs.map((route) => route.data()) || [];
}

export const getRoutesFromDatabase = async (routeKey = '') => {
  const result = await firebase
    .firestore()
    .collection('routes')
    .doc(routeKey)
    .get();

  return result.data()?.name;
};

export const getCrewsFromDatabase = async (crewKey) => {
  const result = await firebase
    .firestore()
    .collection('providers')
    .doc(crewKey)
    .get();
  return result.data()?.name || '';
};
/*
 ****************************************************************************
 * @brief  (getRouteInfo) returns route according to routeKey
 *
 * @param{type {string}} (routeKey)
 *
 * @returns promise
 ****************************************************************************
 */
export async function getRouteInfo(routeKey) {
  const response = await firebase
    .firestore()
    .collection('routes')
    .doc(routeKey)
    .get();

  return response.data() || {};
}

/*
 ****************************************************************************
 * @brief  (getServiceInfo) returns service according to serviceId
 *
 * @param{type {string}} (serviceId)
 *
 * @returns promise
 ****************************************************************************
 */
export async function getServiceInfo(serviceId) {
  if (!serviceId) {
    return {};
  }
  const response = await firebase
    .firestore()
    .collection('services')
    .doc(serviceId)
    .get();
  return response.data() || {};
}

/*
 ****************************************************************************
 * @brief  (getJobsForActiveRoute) returns jobs for a active route
 *
 * @param{type {string}} (activeRouteKey)
 *
 * @returns promise
 ****************************************************************************
 */

export async function getJobsForRoute(routeKey, startDate, endDate) {
  const response = await firebase
    .firestore()
    .collection('jobs')
    .where('allRoutes', 'array-contains', routeKey)
    .orderBy('createdDate', 'desc')
    .where('createdDate', '>=', startDate)
    .where('createdDate', '<=', endDate)
    .get();

  const jobs = response.docs.reduce((accumulator: any, eachResponse) => {
    const job = eachResponse.data();
    const jobEndDate = job.endDate || 0;

    if (jobEndDate <= endDate) {
      return [...accumulator, job];
    }
    return accumulator;
  }, []);

  return jobs;
}

// service point by id is up ^^^^^

/*
 ****************************************************************************
 * @brief  (deleteServicePoint) deletes the service point
 *
 * @param{type {string}} (servicePointKey)
 ****************************************************************************
 */

export async function deleteServicePoint(servicePointKey) {
  return firebase
    .firestore()
    .collection('servicePoints')
    .doc(servicePointKey)
    .update({ archive: true });
}

export async function deleteJob(jobKey) {
  return firebase.firestore().collection('jobs').doc(jobKey).delete();
}

export const deleteShiftJob = (jobKey) => {
  return firebase
    .firestore()
    .collection('customers')
    .doc(companyKey!)
    .collection('shiftJobs')
    .doc(jobKey)
    .delete();
};

export function deleteServiceRoute(routeKey) {
  return firebase.firestore().collection('routes').doc(routeKey).delete();
}

/*
 ****************************************************************************
 * @brief  (deleteProvider) deletes the provider.
 *
 * @param{type {string}} (providerUid)
 ****************************************************************************
 */

export function deleteProvider(crew) {
  const { uid, companyKey: keyOfCompany } = crew;
  const deleteCrew = firebase
    .functions()
    .httpsCallable('authGroup-authGroup-deleteAccount');

  return deleteCrew({ uid, companyKey: keyOfCompany });
}

/*
 ****************************************************************************
 * @brief  (deleteService) deletes the service
 *
 * @param{type {string}} (serviceKey)
 ****************************************************************************
 */

export async function deleteService(serviceKey) {
  return firebase.firestore().collection('services').doc(serviceKey).delete();
}

export async function getAllServiceRoutes() {
  const routes = await firebase
    .firestore()
    .collection('routes')
    .where('companyKey', '==', companyKey!)
    .get();
  return routes.docs.map((route) => route.data());
}

/*
 ****************************************************************************
 * @brief  (getActiveRoutesForRoute) returns the list of active routes
 * for a route
 *
 * @param{type {string, string, string}} (providerUid, startDate, endDate)
 *
 * @returns promise
 ****************************************************************************
 */
export async function getActiveRoutesForJob(jobKey) {
  const response = await firebase
    .firestore()
    .collection('jobs')
    .doc(jobKey)
    .collection('routes')
    .get();

  return response.docs.map((d) => d.data());
}

/*
 ****************************************************************************
 * @brief  (getCompanyDetail) returns detail of the company
 *
 * @param{type {}} ()
 *
 * @returns promise
 ****************************************************************************
 */

export async function getCompanyDetail(key) {
  const companyId = key ?? companyKey;
  const company = await firebase
    .firestore()
    .collection('customers')
    .doc(companyId)
    .get();
  return company.data();
}

export const getRealtimeCompanyDetails = (updateCompanyDetail) => {
  return firebase
    .firestore()
    .collection('customers')
    .doc(companyKey!)
    .onSnapshot((res) => {
      updateCompanyDetail(res.data());
    });
};

export const getCompanyKey = () => {
  return companyKey;
};

export async function assignProviderToRoute(routeKey, providerUid) {
  await firebase
    .firestore()
    .collection('routes')
    .doc(routeKey)
    .update({ providerUid });
}

export async function getIssueReports(
  jobKey,
  _updateReports?: (res: any) => void
) {
  const ref = firebase
    .firestore()
    .collection('jobs')
    .doc(jobKey)
    .collection('reports')
    .orderBy('date', 'desc');

  if (_updateReports) {
    ref.onSnapshot((data) => {
      const response = data.docs.map((d) => d.data());
      _updateReports(response);
    });
    return undefined;
  }

  const response = await ref.get();
  return response.docs.map((d) => d.data());
}

function getArrayOfData(res) {
  return res.docs.map((d) => d.data());
}

export async function getIssueReportsOfAMarker(
  jobKey,
  markerKey,
  _updateReports
) {
  const ref = firebase
    .firestore()
    .collection('jobs')
    .doc(jobKey)
    .collection('reports')
    .where('markerKey', '==', markerKey);
  if (_updateReports) {
    return ref.onSnapshot((res) => {
      const response = res?.docs?.map((d) => d.data()) ?? [];
      _updateReports(response);
    });
  }
  const response = await ref.get();
  const reports = getArrayOfData(response);
  return reports;
}

export async function assignBeacon(uuid, locId) {
  if (uuid === null || locId === null) {
    throw Error('error');
  }
  try {
    firebase.firestore().collection('beacons').doc(uuid).update({
      locationId: locId,
      assigned: true,
    });
    return true;
  } catch (e) {
    throw Error(e);
  }
}

export async function unassignBeacon(uuid) {
  if (uuid === null) {
    throw Error('select beaon');
  }
  try {
    firebase.firestore().collection('beacons').doc(uuid).update({
      locationId: null,
      assigned: false,
    });
  } catch (e) {
    console.error(e);
    throw Error('error ');
  }
}

export async function getUnassignedIndoorServicePoints() {
  const servicePointsData = await firebase
    .firestore()
    .collection('servicePoints')
    .where('companyKey', '==', companyKey!)
    .where('serviceType', '==', 'indoor')
    .where('assigned', '==', false)
    .get();

  return servicePointsData.docs.map((servicePoint) => servicePoint.data());
}

export async function getBeaconByBeaconId(beaconId: string) {
  const beaconData = await firebase
    .firestore()
    .collection('beacons')
    .doc(beaconId)
    .get();
  return beaconData.data();
}

export async function addBeaconIdToFirestore(beaconUuid, navigineBeaconId) {
  const response = await firebase
    .firestore()
    .collection('servicePoints')
    .where('beaconId', '==', beaconUuid)
    .get();

  const servicePointKey = response.docs[0].data().key;

  await firebase
    .firestore()
    .collection('servicePoints')
    .doc(servicePointKey)
    .update({
      navigineBeaconId: `${navigineBeaconId}`,
    });
}

export async function getLocationIdOfTheCompany() {
  return (
    await firebase.firestore().collection('customers').doc(companyKey!).get()
  ).data()!.locationId;
}

export async function updateRouteData(routeKey, routeData) {
  const routeRef = await firebase
    .firestore()
    .collection('routes')
    .doc(routeKey);
  await routeRef.update(routeData);
  return routeRef;
}

export async function createEditJob(jobData) {
  let ref;
  const job = { ...jobData };
  const timeInEpoch = getTimeInEpoch();
  job.companyKey = companyKey;
  if (!job.key) job.createdDate = timeInEpoch;

  if (!job.key) {
    ref = firebase.firestore().collection('jobs').doc();
    job.key = ref.id;
  } else {
    ref = firebase.firestore().collection('jobs').doc(job.key);
  }
  await ref.set(job);
  return job;
}

const makeSchedulesFromShift = (newShiftDetails: Shift) => {
  const { shift, job, startDate, endDate } = newShiftDetails;

  const { timezone, startTime, endTime, weekdays } = shift;

  const endDateInDayJS = dayjs(endDate).tz(timezone).endOf('day');

  const currentTimezonedDate = dayjs().tz(timezone);
  let startDateInDayJS = dayjs(startDate).tz(timezone).startOf('day');

  const setOfWeekDays = new Set(weekdays);

  return firebase.firestore().runTransaction(async (transaction) => {
    do {
      const currentWeekDay = currentTimezonedDate.day();
      // if (setOfWeekDays.has(currentWeekDay)) { //TODO: confirm this with client
      const startHour = Number(startTime.split(':')[0]);
      const startMinute = Number(startTime.split(':')[1]);
      const endHour = Number(endTime.split(':')[0]);
      const endMinute = Number(endTime.split(':')[1]);
      const scheduleRef = firebase.firestore().collection('jobs').doc();
      const parentKey = generateRandomString(16);
      const schedule: Schedule = {
        ...job,
        parentKey,
        servedRoutes: [],
        servedMarkers: [],
        startedDate: null,
        status: 'assigned',
        abortedMarkers: [],
        key: scheduleRef.id,
        companyKey: companyKey!,
        createdDate: startDateInDayJS
          .set('h', startHour)
          .set('minute', startMinute)
          .valueOf(),
        dateToEndJob: currentTimezonedDate
          .set('h', endHour)
          .set('minute', endMinute)
          .valueOf(),
        shift,
      };

      transaction.set(scheduleRef, schedule);
      // }
      startDateInDayJS = startDateInDayJS.add(1, 'day').startOf('day');
    } while (endDateInDayJS.isAfter(startDateInDayJS));
  });
};

export async function createEditShiftJob(jobData) {
  const job = { ...jobData };

  const isAdd = !job.key;

  // job.companyKey = companyKey;
  // job.job.companyKey = companyKey;
  // if (!job.key) {
  //   const timeInEpoch = getTimeInEpoch();
  //   job.createdDate = timeInEpoch;
  // }
  // let ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  // if (!job.key) {
  //   ref = firebase
  //     .firestore()
  //     .collection('customers')
  //     .doc(companyKey!)
  //     .collection('shiftJobs')
  //     .doc();
  //   job.key = ref.id;
  // } else {
  //   ref = firebase
  //     .firestore()
  //     .collection('customers')
  //     .doc(companyKey!)
  //     .collection('shiftJobs')
  //     .doc(job.key);
  // }
  // await ref.set(job);

  if (isAdd) {
    try {
      await makeSchedulesFromShift(job);
      console.log('Schedules created successfully');
    } catch (error) {
      console.log('error in creating shifttttt ', error);
    }
  }

  return job;
}
// service point by id is up ^^^^^ //

export async function providerLatestJob(providerId, timestamp, date) {
  const ref = firebase.firestore().collection('jobs');
  let selectedDate = timestamp;
  if (!timestamp) {
    selectedDate = getStartTimeStampOfTheDay();
  }

  let jobsRef = ref
    .where('providerUids', 'array-contains', providerId)
    .where('createdDate', '>=', selectedDate);

  if (date) {
    jobsRef = jobsRef.where('createdDate', '<', date);
  }

  const jobsRes = await jobsRef.orderBy('createdDate', 'desc').get();
  const jobs = jobsRes.docs.map((job) => job.data());
  return jobs;
}

export const searchCrew = async (input) => {
  const lowerCaseInput = input.toLowerCase();

  const result = await firebase
    .firestore()
    .collection('providers')
    .where('companyKey', '==', companyKey!)
    .where('label', '>=', lowerCaseInput)
    .where('label', '<=', `${lowerCaseInput}~`)
    .get();

  return result.docs.map((queryEachResult) => queryEachResult.data()) || [];
};

export const searchService = async (input, serviceType) => {
  const lowerCaseInput = input.toLowerCase();
  let result: firebase.firestore.QuerySnapshot;

  if (serviceType === 'ALL') {
    result = await firebase
      .firestore()
      .collection('services')
      .where('companyKey', '==', companyKey!)
      .where('label', '>=', lowerCaseInput)
      .where('label', '<=', `${lowerCaseInput}~`)
      .get();
  } else {
    result = await firebase
      .firestore()
      .collection('services')
      .where('companyKey', '==', companyKey!)
      .where('type', '==', serviceType.toLowerCase())
      .where('label', '>=', lowerCaseInput)
      .where('label', '<=', `${lowerCaseInput}~`)
      .get();
  }

  return result.docs.map((doc) => doc.data());
};

export const updatePeriodicJob = async (key, providerUids) => {
  await firebase
    .firestore()
    .collection('periodicJobs')
    .doc(key)
    .update({
      'job.providerUids': providerUids,
    })
    .catch((error) => console.error(`cannot update periodic jobs ${error}`));
};

export const updateJob = async (key, providerUids) => {
  await firebase
    .firestore()
    .collection('jobs')
    .doc(key)
    .update({ providerUids })
    .catch((error) => console.error(`cannot update periodic jobs ${error}`));
};

export const searchRoutes = async (input) => {
  const lowerCaseInput = input.toLowerCase();

  const result = await firebase
    .firestore()
    .collection('routes')
    .where('companyKey', '==', companyKey!)
    .where('label', '>=', lowerCaseInput)
    .where('label', '<=', `${lowerCaseInput}~`)
    .get();

  return result.docs.map((queryEachResult) => queryEachResult.data()) || [];
};

export const searchJobs = async (searchWord, jobType) => {
  const lowerCaseWord = getLowerCasedWord(searchWord);
  const res = await firebase
    .firestore()
    .collection(jobType)
    .where('companyKey', '==', companyKey!)
    .orderBy('name')
    .where('name', '>=', lowerCaseWord)
    .where('name', '<=', `${lowerCaseWord}~`)
    .get();
  const jobs = res.docs.map((d) => d.data());
  return jobs;
};

export const getSearchedJobs = async (input, jobType) => {
  const lowerCaseInput = input.toLowerCase();

  let jobCollection = '';
  if (jobType === 'Assigned Jobs') {
    jobCollection = 'jobs';
  } else {
    jobCollection = 'periodicJobs';
  }

  const result = await firebase
    .firestore()
    .collection(jobCollection)
    .where('companyKey', '==', companyKey!)
    .where('label', '>=', lowerCaseInput)
    .where('label', '<=', `${lowerCaseInput}~`)
    .get();

  return result.docs.map((queryEachResult) => queryEachResult.data()) || [];
};

export const editUserProfile = (userData) => {
  return firebase
    .firestore()
    .collection(getUserCollectionName())
    .doc(userData.uid)
    .set({ ...userData }, { merge: true });
};

export const uploadUserProfileToStorage = async (image) => {
  try {
    const uploadTask = await firebase
      .storage()
      .ref()
      .child(`profile_images/${image.name}`)
      .put(image);

    return uploadTask.ref.getDownloadURL();
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getAttendanceDetailOfADay = async (date) => {
  const response = await firebase
    .firestore()
    .collection('attendance')
    .doc(`${date}_${companyKey}`)
    .get();
  return response.data() || {};
};

export const getPresentEmployeesDetails = async (uid, date) => {
  const response = await firebase
    .firestore()
    .collection('attendance')
    .doc(`${date}_${companyKey}`)
    .collection('attendees')
    .doc(uid)
    .get();

  const userName = (
    await firebase.firestore().collection('providers').doc(uid).get()
  ).data()!.name;

  return { ...response.data(), name: userName };
};

export const getRealTimeLocationOfTheIndoorProviders = (
  jobKey,
  routeKey,
  updateCrews
) => {
  firebase
    .database()
    .ref()
    .child(`indoorLocations/${companyKey}/${jobKey}/${routeKey}`)
    .on('value', (snapshot) => {
      updateCrews(snapshot.val() || {});
    });
};

export const getRouteName = async (routeKey) => {
  const response = await firebase
    .firestore()
    .collection('routes')
    .doc(routeKey)
    .get();

  return response.data()!.name;
};

export const getRouteDetailsOfAJob = async (
  jobKey,
  routeKey,
  isPeriodic,
  update
) => {
  let job = '';
  if (isPeriodic) job = 'periodicJobs';
  else job = 'jobs';

  const routeInfo = await firebase
    .firestore()
    .collection('routes')
    .doc(routeKey)
    .get();

  firebase
    .firestore()
    .collection(job)
    .doc(jobKey)
    .collection('routes')
    .doc(routeKey)
    .onSnapshot((docSnap) => {
      const routeDetail = docSnap.data();
      const route = routeInfo.data();
      const r = { ...route, ...routeDetail };
      update(r);
    });
};

export const getMarkerLogsForAJob = async (job) => {
  if (!job) {
    return [];
  }
  const res = job?.allMarkers?.map(async (markerKey) => {
    const response = await firebase
      .firestore()
      .collection('marker_logs')
      .where('jobKey', '==', job.key)
      .where('servicePointId', '==', markerKey)
      .get();

    return response.docs?.[0]?.data() ?? null;
  });
  const response = await Promise.all(res ?? []);
  const markerLogs = response?.filter((r) => !!r) ?? [];
  return markerLogs;
};

export const getMarkerLogs = async (
  jobKey,
  routeKey,
  working,
  _updateMarkerLogs
) => {
  let query = firebase
    .firestore()
    .collection('marker_logs')
    .where('companyKey', '==', companyKey!)
    .where('jobKey', '==', jobKey);
  if (routeKey) {
    query = query.where('routeKey', '==', routeKey);
  }
  query = query.orderBy('startDate');
  if (_updateMarkerLogs) {
    return query.onSnapshot((response) => {
      const logs = response.docs.map((r) => r.data());

      _updateMarkerLogs(logs);
    });
  }
  const response = query.get();
  return (await response).docs.map((r) => r.data());
};

export const getMarkerLogOfAMarkerOfJob = async (
  markerKey,
  jobKey,
  routeKey
) => {
  let query = firebase
    .firestore()
    .collection('marker_logs')
    .where('companyKey', '==', companyKey!)
    .where('jobKey', '==', jobKey);

  if (markerKey) {
    query = query.where('servicePointId', '==', markerKey);
  }
  if (routeKey) {
    query = query.where('routeKey', '==', routeKey);
  }

  query = query.orderBy('startDate');
  const response = await query.get();
  const newRes = response.docs.sort((a, b) => {
    if (a.data().working === true && b.data()?.working === false) {
      return 1;
    }
    return -1;
  });
  const markerLog = newRes[0]?.data() ?? null;
  return markerLog;
};

export function getMarkerLogOfAMarker(jobKey, markerKey, _updateMarkerLog) {
  firebase
    .firestore()
    .collection('marker_logs')
    .where('companyKey', '==', companyKey!)
    .where('jobKey', '==', jobKey)
    .where('servicePointId', '==', markerKey)
    .onSnapshot((res) => {
      const response = res?.docs.map((d) => d.data());
      _updateMarkerLog(response);
    });
}

export const getAttendanceDetailOfATimePeriod = async (
  startTimeStamp,
  endTimeStamp
) => {
  const response = await firebase
    .firestore()
    .collection('attendance')
    .where('companyKey', '==', companyKey!)
    .where('attendanceDate', '>=', startTimeStamp)
    .where('attendanceDate', '<=', endTimeStamp)
    .get();

  return response.docs.map((d) => d.data()) || [];
};

export const getCrewDetailOfATimePeriod = async (
  uid,
  startTimeStamp,
  endTimeStamp
) => {
  const response = await firebase
    .firestore()
    .collectionGroup('attendees')
    .where('attendanceDate', '>=', startTimeStamp)
    .where('attendanceDate', '<=', endTimeStamp)
    .where('uid', '==', uid)
    .get();

  return response.docs.map((d) => d.data()) || [];
};

export const updateCompanyClosingAndOpening = (data) => {
  const cleanUpStartDate = getCurrentTimeInEpoch();
  return firebase
    .firestore()
    .collection('customers')
    .doc(companyKey!)
    .update({ ...data, cleanUpStartDate });
};

export const getActiveRouteLogs = async (jobKey, routeKey) => {
  const response = await firebase
    .firestore()
    .collection('jobs')
    .doc(jobKey)
    .collection('routes')
    .doc(routeKey)
    .collection('logs')
    .get();

  return response.docs.map((d) => d.data()) || [];
};

export const getMarkersOfARoute = async (servicePointsKeys = []) => {
  const promise = servicePointsKeys.map((markerKey) => {
    return getServicePointById(markerKey);
  });

  const servicePointInfos = await Promise.all(promise);
  return servicePointInfos;
};

export const getMarkerByBeaconId = async (beaconId) => {
  const response = await firebase
    .firestore()
    .collection('servicePoints')
    .where('beaconId', '==', beaconId)
    .get();

  return response.docs[0].data() || {};
};

export async function getMarkersForService(type) {
  const response = await firebase
    .firestore()
    .collection('servicePoints')
    .where('companyKey', '==', companyKey!)
    .where('serviceType', '==', type)
    .get();

  return response.docs.map((d) => d.data()) ?? [];
}

export async function addOrEditActivity(data) {
  const collection = firebase
    .firestore()
    .collection('customers')
    .doc(companyKey!)
    .collection('activities');

  const id = data.id ? data.id : collection.doc().id;

  return collection.doc(id).set({ ...data, id });
}

export function getActivitiesOfACompany(_updateActivities) {
  return firebase
    .firestore()
    .collection('customers')
    .doc(companyKey!)
    .collection('activities')
    .onSnapshot((data) => {
      const response =
        data.docs.map((eachActivity) => eachActivity.data()) ?? [];
      _updateActivities(response, true);
    });
}

export async function deleteActivityDatabase(activityId = '') {
  return firebase
    .firestore()
    .collection('customers')
    .doc(companyKey!)
    .collection('activities')
    .doc(activityId)
    .delete();
}

export async function createEditProperty(propertyDetails: any) {
  const data = { ...propertyDetails };
  const lowerCasedName = data?.name?.toLowerCase() ?? '';
  data.label = lowerCasedName;
  const collection = firebase
    .firestore()
    .collection('customers')
    .doc(companyKey!)
    .collection('properties');

  const id = data.id ?? collection.doc().id;

  if (data.imageFile) {
    const file = data.imageFile;

    const storage = firebase.storage();

    const storageRef = storage.ref();

    const imageRef = storageRef.child(`property_images/${id}.jpg`);

    const snapshot = await imageRef.put(file);

    const downloadUrl = await snapshot.ref.getDownloadURL();

    data.imageUrl = downloadUrl;
  }

  delete data.imageFile;

  return collection.doc(id).set({ ...data, id });
}

export function getPropertiesFromFirestore(_updateProperties, includeArchive) {
  return firebase
    .firestore()
    .collection('customers')
    .doc(companyKey!)
    .collection('properties')
    .onSnapshot((snap) => {
      const res = snap.docs.reduce((acc: any, d) => {
        if (includeArchive || !d.data().archive) {
          return [...acc, d.data()];
        }
        return acc;
      }, []);
      _updateProperties(res);
    });
}

export async function getPropertiesOfCompany() {
  const response = await firebase
    .firestore()
    .collection('customers')
    .doc(companyKey!)
    .collection('properties')
    .where('archive', '==', false)
    .get();

  return response.docs.map((d) => d.data());
}

export function archiveProperty(propertyId) {
  return firebase
    .firestore()
    .collection('customers')
    .doc(companyKey!)
    .collection('properties')
    .doc(propertyId)
    .update({ archive: true });
}

export async function getPropertyById(propertyId) {
  const response = await firebase
    .firestore()
    .collection('customers')
    .doc(companyKey!)
    .collection('properties')
    .doc(propertyId)
    .get();

  return response.data() || null;
}

export async function getMicrolocationsByLocationKey(
  propertyKey: string,
  serviceType = null,
  serviceKey = null,
  showArchive = false
) {
  let query = firebase
    .firestore()
    .collection('servicePoints')
    .where('propertyKey', '==', propertyKey)
    .where('archive', '==', false);

  if (serviceType) {
    query = query.where('serviceType', '==', serviceType);
  }

  if (serviceKey) {
    query = query.where('serviceKey', '==', serviceKey);
  }
  const response = await query.get();

  const markers = response.docs.reduce((acc: any, doc) => {
    const marker = doc.data();

    if (showArchive || !marker.archive) {
      return [...acc, marker];
    }

    return acc;
  }, []);

  return markers;
}

export function chunk(array: any[], chunkSize: number) {
  const length = array?.length ?? 0;
  const chunks: any[] = [];
  for (let i = 0; i < length; i += chunkSize) {
    const size = i + chunkSize;
    const newArray = array.slice(i, size > length ? length : size);
    chunks.push(newArray);
  }

  return chunks;
}

export async function getJobsForMarkers(markersKeys, startDate, endDate) {
  const markersKeysChunk = chunk(markersKeys, 10);

  const promises = markersKeysChunk?.map((keys) => {
    return firebase
      .firestore()
      .collection('jobs')
      .where('allMarkers', 'array-contains-any', keys)
      .orderBy('createdDate', 'desc')
      .where('createdDate', '>=', startDate)
      .where('createdDate', '<=', endDate)
      .get();
  });

  const response = await Promise.all(promises ?? []);

  let jobKeys: string[] = [];

  let jobs = response?.reduce((acc: any, curr) => {
    const j = curr.docs.reduce((a: any, d) => {
      const job = d.data();

      return [...a, job];
    }, []);
    return [...acc, ...j];
  }, []);

  jobs = jobs.filter((job) => {
    if (!jobKeys.includes(job.key)) {
      jobKeys = [...jobKeys, job.key];
      return true;
    }
    return false;
  });

  return jobs;
}

export const getMarkersOfAJob = async (markersKeys: string[]) => {
  const promises = markersKeys?.map((markerKey) => {
    return firebase
      .firestore()
      .collection('servicePoints')
      .doc(markerKey)
      .get();
  });

  const markersResponse = await Promise.all(promises);

  const markers = markersResponse?.reduce((acc: any, marker) => {
    if (!marker.data()) {
      return acc;
    }
    return [...acc, marker.data()];
  }, []);

  return markers;
};

export async function getMarkersForServiceKey(serviceKey: string) {
  const response = await firebase
    .firestore()
    .collection('servicePoints')
    .where('serviceKey', '==', serviceKey)
    .get();

  const markers = response.docs.reduce((allMarkers: any, d) => {
    const marker = d.data();
    if (marker.archive) {
      return allMarkers;
    }
    return [...allMarkers, marker];
  }, []);

  return markers;
}

export async function editCategoryInFirestore(
  oldCategoryName,
  newCategoryName
) {
  const response = await firebase
    .firestore()
    .collection('customers')
    .doc(companyKey!)
    .collection('activities')
    .where('category', '==', oldCategoryName)
    .get();

  const updates = response.docs.map((d) =>
    d.ref.update({ category: newCategoryName })
  );

  return Promise.all(updates);
}

// export async function addUpdateActivities(
//   oldCategoryName,
//   newCategoryName
// ) {
//   const response = await firebase
//     .firestore()
//     .collection('customers')
//     .doc(companyKey!)
//     .collection('activities')
//     .where('category', '==', oldCategoryName)
//     .get();

//   const updates = response.docs.map((d) =>
//     d.ref.update({ category: newCategoryName })
//   );

//   return Promise.all(updates);
// }

export async function deleteActivitiesOfACategory(categoryName) {
  const response = await firebase
    .firestore()
    .collection('customers')
    .doc(companyKey!)
    .collection('activities')
    .where('category', '==', categoryName)
    .get();

  const deletions = response.docs.map((d) => d.ref.delete());

  return Promise.all(deletions);
}

export async function completeJob(jobKey) {
  const currentTimestamp = getCurrentTimeInEpoch();
  const closedBy = 'admin'; // TODO: confirm this if needed changes
  return firebase
    .firestore()
    .collection('jobs')
    .doc(jobKey)
    .update({ status: 'completed', endDate: currentTimestamp, closedBy });
}

export function sendPasswordResetEmail(email) {
  return firebase.auth().sendPasswordResetEmail(email);
}

export function disableCrewAccount(crew) {
  const { uid, companyKey: keyOfCompany } = crew;
  const disableAccount = firebase
    .functions()
    .httpsCallable('authGroup-authGroup-disableAccount');

  return disableAccount({ uid, companyKey: keyOfCompany });
}

export function resetCrewDeviceId(crew) {
  return firebase
    .firestore()
    .collection('providers')
    .doc(crew.uid)
    .update({ deviceId: null });
}

export function deleteCrewAccount(crew) {
  const { uid, companyKey: keyOfCompany } = crew;

  const deleteAccount = firebase
    .functions()
    .httpsCallable('authGroup-authGroup-deleteAccount');

  return deleteAccount({ uid, companyKey: keyOfCompany });
}

export function enableCrewAccount(crew) {
  const { uid, companyKey: keyOfCompany } = crew;

  const enableAccount = firebase
    .functions()
    .httpsCallable('authGroup-authGroup-enableAccount');

  return enableAccount({ uid, companyKey: keyOfCompany });
}

export async function getEventsForMarker(jobKey, markerKey) {
  const response = await firebase
    .firestore()
    .collection('marker_logs')
    .doc(`${jobKey}_${markerKey}`)
    .collection('events')
    .where('markerKey', '==', markerKey)
    .orderBy('timestamp')
    .get();

  const markerEvents = response.docs.map((d) => d.data()) ?? [];
  return markerEvents;
}

export async function addOrEditShift(shiftData) {
  let data = { ...shiftData };
  const ref = firebase
    .firestore()
    .collection('customers')
    .doc(companyKey!)
    .collection('shifts');
  if (!data.id) {
    const { id } = ref.doc();
    data = { ...data, id };
  }

  await ref.doc(data?.id).set({ ...data, companyKey }, { merge: true });
}

export async function getShiftsOfACompany(_updateShifts) {
  firebase
    .firestore()
    .collection('customers')
    .doc(companyKey!)
    .collection('shifts')
    .onSnapshot((res) => {
      const shifts = res.docs?.map((d) => d.data());
      _updateShifts(shifts);
    });
}

export async function shiftDelete(shiftId) {
  return firebase
    .firestore()
    .collection('customers')
    .doc(companyKey!)
    .collection('shifts')
    .doc(shiftId)
    .delete();
}

export async function getShiftById(shiftId: string) {
  const res = await firebase
    .firestore()
    .collection('customers')
    .doc(companyKey!)
    .collection('shifts')
    .doc(shiftId)
    .get();

  const shift = res.data() ?? {};

  return shift;
}

export async function getServiceRequestOfACompany() {
  const response = await firebase
    .firestore()
    .collectionGroup('workOrders')
    .where('companyKey', '==', companyKey!)
    .orderBy('requestedDate', 'desc')
    .get();

  const serviceRequest = response.docs.map((d) => d.data());

  return serviceRequest;
}
const AlphabetsColor = {
  65: '#EB5463',
  66: '#FA6C51',
  67: '#FDCD56',
  68: '#4693CE',
  69: '#46CEAC',
  70: '#AC6D83',
  71: '#5E9CEA',
  72: '#AC92EA',
  73: '#00B77A',
  74: '#87C000',
  75: '#636C77',
  76: '#F19065',
  77: '#574B90',
  78: '#F78FB3',
  79: '#556EE6',
  80: '#3EC1D3',
  81: '#303A52',
  82: '#C34568',
  83: '#6B39A3',
  84: '#E27B47',
  85: '#CE2949',
  86: '#3E6B8B',
  87: '#582841',
  88: '#F89E4C',
  89: '#2AA788',
  90: '#2976CE',
};
export function getImageBackgroundColor(str) {
  const firstLetter = str[0].toUpperCase();
  const isAlphabet = /^[a-zA-Z]/.test(firstLetter);
  if (isAlphabet) {
    const asciCode = firstLetter.charCodeAt();
    return AlphabetsColor[asciCode];
  }
  return '#EB5463';
}

export async function getEndUserByUid(endUserUid) {
  const response = await firebase
    .firestore()
    .collection('users')
    .doc(endUserUid)
    .get();

  const endUser = response.data() ?? {};
  return endUser;
}

export async function getOrderProgress(markerKey, orderKey) {
  const response = await firebase
    .firestore()
    .collection('servicePoints')
    .doc(markerKey)
    .collection('workOrders')
    .doc(orderKey)
    .collection('progress')
    .orderBy('date', 'desc')
    .get();

  const progress = response.docs.map((d) => d.data());

  return progress;
}
export async function getWorkOrderOfAMarker(markerKey) {
  const response = await firebase
    .firestore()
    .collection('servicePoints')
    .doc(markerKey)
    .collection('workOrders')
    .where('status', '==', 'REQUESTED')
    .get();

  if (response.docs.length < 1) return null;

  const workOrder = response.docs.map((d) => d.data());

  return workOrder[0];
}

export async function updateWorkOrder(markerKey, workOrderId, data) {
  return firebase
    .firestore()
    .collection('servicePoints')
    .doc(markerKey)
    .collection('workOrders')
    .doc(workOrderId)
    .update({ ...data });
}
export async function getOrderById(markerKey, orderKey) {
  const response = await firebase
    .firestore()
    .collection('servicePoints')
    .doc(markerKey)
    .collection('workOrders')
    .doc(orderKey)
    .get();

  const order = response.data() ?? {};

  return order;
}

export async function getWorkOrdersByMarkersKeys(markersKeys: string[]) {
  const promises = markersKeys?.map((markerKey) => {
    return firebase
      .firestore()
      .collection('servicePoints')
      .doc(markerKey)
      .collection('workOrders')
      .where('status', '==', 'REQUESTED')
      .get();
  });

  const workOrdersResponse = await Promise.all(promises ?? []);

  const worksOrders = workOrdersResponse?.reduce((acc, res) => {
    if (res.docs.length === 0) {
      return acc;
    }

    const response = res.docs.map((d) => d.data());

    const workOrder = response[0];
    return { ...acc, [workOrder.markerKey]: workOrder.id };
  }, {});

  return worksOrders;
}

export async function getWorkOrderFromRoutes(routesKeys = []) {
  const routesPromises = routesKeys?.map((routeKey) => {
    return firebase.firestore().collection('routes').doc(routeKey).get();
  });

  const routesResponse = await Promise.all(routesPromises ?? []);

  const markersKeys = routesResponse?.reduce((acc: string[], res) => {
    const route = res.data();
    return [...acc, ...(route?.servicePointsKeys ?? [])];
  }, []);

  const uniqueMarkersKeys = Array.from(new Set(markersKeys));

  const workOrders = await getWorkOrdersByMarkersKeys(uniqueMarkersKeys);

  return workOrders;
}

export async function getOrganizationalCrew(crewId) {
  const res = await firebase
    .firestore()
    .collection('providers')
    .where('crewId', '==', crewId)
    .where('crewType', '==', 'Org')
    .where('companyKey', '!=', companyKey!)
    .get();

  if (!res.docs.length) {
    return null;
  }

  const isMultipleCrewAdmin = res.docs.some(
    (d) => d.data().companyKey === companyKey
  );

  if (!isMultipleCrewAdmin) {
    return res.docs[0].data();
  }
  throw new Error();
}

export async function getMarkerLogsHavingInvoices() {
  const res = await firebase
    .firestore()
    .collection('marker_logs')
    .where('companyKey', '==', companyKey!)
    .orderBy('invoice.invoicedDate', 'desc')
    .get();

  const invoices = res.docs.map((d) => d.data());
  return invoices;
}

export function updateInvoice(markerLogKey, markerLog) {
  return firebase
    .firestore()
    .collection('marker_logs')
    .doc(markerLogKey)
    .set(markerLog, { merge: true });
}

export function getCurrentUserName() {
  return firebase.auth().currentUser!.displayName;
}

export async function getMarkerLogByKey(markerLogKey) {
  const res = await firebase
    .firestore()
    .collection('marker_logs')
    .doc(markerLogKey)
    .get();

  const markerLog = res.data() ?? {};
  return markerLog;
}

export async function getDataByName(collection, name) {
  const res = await firebase
    .firestore()
    .collection(collection)
    .where('companyKey', '==', companyKey!)
    .where('name', '==', name)
    .limit(1)
    .get();

  const data = res.docs.map((d) => d.data());
  return data?.[0] ?? null;
}

export async function getPropertyByName(name) {
  const lowerCasedName = name?.toLowerCase();
  const res = await firebase
    .firestore()
    .collection('customers')
    .doc(companyKey!)
    .collection('properties')
    .where('label', '==', lowerCasedName)
    .limit(1)
    .get();

  const data = res.docs.map((d) => d.data());
  return data?.[0];
}

export async function getFilteredJobs({
  propertyKey,
  serviceKey,
  markerKey,
  status,
  providerKey,
  lastData,
}) {
  const jobref = firebase
    .firestore()
    .collection('jobs')
    .where('companyKey', '==', companyKey!);
  let data: firebase.firestore.DocumentData[] = [];
  let newref = jobref;
  const { createdDate } = lastData ?? {};
  if (!markerKey && !serviceKey && !propertyKey && !status && providerKey) {
    newref = jobref.where('providerUids', 'array-contains', providerKey);
  }
  if (markerKey && serviceKey && propertyKey && status) {
    // abcd
    newref = jobref
      .where('allMarkers', 'array-contains', markerKey)
      .where('serviceKey', '==', serviceKey)
      .where('status', '==', status)
      .limit(50);
    if (createdDate) {
      data = (
        await newref.where('createdDate', '<', createdDate).get()
      ).docs.filter((e) => e.data().propertyKeys?.includes(propertyKey));
    } else {
      data = (await newref.get()).docs.filter((e) =>
        e.data().propertyKeys?.includes(propertyKey)
      );
    }
  }
  // abc

  if (markerKey && serviceKey && propertyKey && !status) {
    newref = jobref
      .where('allMarkers', 'array-contains', markerKey)
      .where('serviceKey', '==', serviceKey)
      .limit(50);
    if (createdDate) {
      data = (
        await newref.where('createdDate', '<', createdDate).get()
      ).docs.filter((e) => e.data().propertyKeys?.includes(propertyKey));
    } else {
      data = (await newref.get()).docs.filter((e) =>
        e.data().propertyKeys?.includes(propertyKey)
      );
    }
  }
  // bcd
  if (!markerKey && serviceKey && propertyKey && status) {
    newref = jobref
      .where('serviceKey', '==', serviceKey)
      .where('propertyKeys', 'array-contains', markerKey)
      .where('status', '==', status)
      .limit(50);
  }

  // abd

  if (markerKey && serviceKey && !propertyKey && status) {
    newref = jobref
      .where('allMarkers', 'array-contains', markerKey)
      .where('serviceKey', '==', serviceKey)
      .where('status', '==', status)
      .limit(50);
  }
  // acd
  if (markerKey && !serviceKey && propertyKey && status) {
    newref = jobref
      .where('allMarkers', 'array-contains', markerKey)
      .where('status', '==', status);
    if (createdDate) {
      data = (
        await newref.where('createdDate', '<', createdDate).get()
      ).docs.filter((e) => e.data().propertyKeys?.includes(propertyKey));
    } else {
      data = (await newref.get()).docs.filter((e) =>
        e.data().propertyKeys?.includes(propertyKey)
      );
    }
  }
  // ab
  if (markerKey && serviceKey && !propertyKey && !status) {
    newref = jobref
      .where('allMarkers', 'array-contains', markerKey)
      .where('serviceKey', '==', serviceKey);
  }
  // ac
  if (markerKey && !serviceKey && propertyKey && !status) {
    newref = jobref.where('allMarkers', 'array-contains', markerKey).limit(50);
    if (createdDate) {
      data = (
        await newref.where('createdDate', '<', createdDate).get()
      ).docs.filter((e) => e.data().propertyKeys?.includes(propertyKey));
    } else {
      data = (await newref.get()).docs.filter((e) => {
        return e.data().propertyKeys?.includes(propertyKey);
      });
    }
  }
  // bc
  if (!markerKey && serviceKey && propertyKey && !status) {
    newref = jobref
      .where('serviceKey', '==', serviceKey)
      .where('propertyKeys', 'array-contains', propertyKey)
      .limit(50);
  }
  // cd
  if (!markerKey && !serviceKey && propertyKey && status) {
    newref = jobref
      .where('propertyKeys', 'array-contains', propertyKey)
      .where('status', '==', status)
      .limit(50);
  }
  // bd
  if (!markerKey && serviceKey && !propertyKey && status) {
    newref = jobref
      .where('serviceKey', '==', serviceKey)
      .where('status', '==', status)
      .limit(50);
  }
  // ad
  if (markerKey && !serviceKey && !propertyKey && status) {
    newref = jobref
      .where('allMarkers', 'array-contains', propertyKey)
      .where('status', '==', status)
      .limit(50);
  }
  // c
  if (!markerKey && !serviceKey && propertyKey && !status) {
    newref = jobref
      .where('propertyKeys', 'array-contains', propertyKey)
      .limit(50);
  }
  // b
  if (!markerKey && serviceKey && !propertyKey && !status) {
    newref = jobref.where('serviceKey', '==', serviceKey).limit(50);
  }
  // a
  if (markerKey && !serviceKey && !propertyKey && !status) {
    newref = jobref.where('allMarkers', 'array-contains', markerKey).limit(50);
  }
  // d
  if (!markerKey && !serviceKey && !propertyKey && status) {
    newref = jobref.where('status', '==', status).limit(50);
  }

  let jobs: Schedule[] = [];
  let lastJob: firebase.firestore.DocumentData | null = null;
  if (!data) {
    if (createdDate) {
      data = (
        await newref
          .orderBy('createdDate', 'desc')
          .where('createdDate', '<', createdDate)
          .limit(50)
          .get()
      ).docs;
    } else {
      data = (await newref.orderBy('createdDate', 'desc').limit(50).get()).docs;
    }
    jobs =
      data?.map((d) => {
        return { ...d.data(), closedBy: d.data().closedBy ?? 'crew' };
      }) ?? [];
    lastJob = data?.[(data?.length ?? 0) - 1] ?? null;
  } else {
    jobs =
      data?.map((d) => {
        return { ...d.data(), closedBy: d.data().closedBy ?? 'crew' };
      }) ?? [];
    lastJob = data?.[(data?.length ?? 0) - 1] ?? null;
  }

  return { jobs, lastJob };
}

// Get no of Jobs assigned to property
export async function getJobsCountForProperty(propertyKey) {
  const resp = await firebase
    .firestore()
    .collection('jobs')
    .where('propertyKeys', 'array-contains', propertyKey)
    .get();
  return resp.size;
}

// Get no of markers assigned to property
export async function getMarkersCountForProperty(propertyKey) {
  const resp = await firebase
    .firestore()
    .collection('servicePoints')
    .where('companyKey', '==', companyKey!)
    .where('propertyKey', '==', propertyKey)
    .get();
  return resp.size;
}
export async function getShiftJobsStatus(shiftKey) {
  const nonDeletableJobsStatuses = ['started', 'assigned'];

  const resp = await firebase
    .firestore()
    .collection('jobs')
    .where('shiftKey', '==', shiftKey)
    .where('status', 'in', nonDeletableJobsStatuses)
    .limit(1)
    .get();
  return resp.size;
}

export async function getShiftOfLocation(locationKey) {
  const resp = await firebase
    .firestore()
    .collection('customers')
    .doc(companyKey ?? '')
    .collection('shiftJobs')
    .where('job.propertyKeys', 'array-contains', locationKey)
    .get();

  return resp.docs.map((d) => d.data());
}

export async function getScheduleOfLocation(locationKey) {
  const resp = await firebase
    .firestore()
    .collection('jobs')
    .where('companyKey', '==', companyKey)
    .where('propertyKeys', 'array-contains', locationKey)
    .get();

  return resp.docs.map((d) => d.data());
}

export const storeCompanyKeyToLocalStorage = (keyOfCompany) => {
  localStorage.setItem('selectedCompanyKey', keyOfCompany);
  window.location.replace('/admin');
};

export const cleanOlderJobs = () => {
  const callableFunctions = firebase
    .functions()
    .httpsCallable('cleanup-cleanOlderJobs');

  return callableFunctions({ companyKey });
};

const removeSecondsFromJob = (job) => {
  const updatedJob = { ...job };
  if (job.createdDate) {
    updatedJob.createdDate = getTimeInStartOfMinute(job.createdDate);
  }

  if (job.startedDate) {
    updatedJob.startedDate = getTimeInStartOfMinute(job.startedDate);
  }
  if (job.endDate) {
    updatedJob.endDate = getTimeInStartOfMinute(job.endDate);
  }

  return updatedJob;
};

export const getTimeSheet = async (
  uids: string[],
  startDate,
  endDate,
  propertyKeys: string[] = []
) => {
  const startDateInTimeStamp = getTimeInEpoch(startDate);
  const endDateInTimeStamp = getTimeInEpoch(endDate);

  const jobsRef = firebase
    .firestore()
    .collection('jobs')
    .where('companyKey', '==', companyKey!)
    .where('createdDate', '>=', startDateInTimeStamp)
    .where('createdDate', '<=', endDateInTimeStamp + 24 * 60 * 60 * 1000);

  let promises: Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  >[];

  if (uids) {
    const crewsUidsChunk = chunk(uids, 10);
    promises = crewsUidsChunk.map((crewsUids) => {
      return jobsRef
        .where('providerUids', 'array-contains-any', crewsUids)
        .get();
    });
  } else {
    promises = [jobsRef.get()];
  }

  const res = await Promise.all(promises);

  const docs = res.reduce((acc: Schedule[], r) => {
    const eachDocs = r.docs.map((d) => d.data() as Schedule);
    return [...acc, ...eachDocs];
  }, []);

  const response = docs.reduce((acc: Schedule[], d) => {
    if (!propertyKeys?.length && (!d.endDate || d.endDate <= endDate)) {
      return [...acc, removeSecondsFromJob(d)];
    }
    const { propertyKeys: propertyKeysOfJob } = d;
    const setOfPropertyKeys = new Set(propertyKeys ?? []);
    if (
      (!d.endDate || d.endDate <= endDate) &&
      propertyKeysOfJob?.some((key) => setOfPropertyKeys.has(key))
    ) {
      return [...acc, removeSecondsFromJob(d)];
    }

    return acc;
  }, []);

  let arrayOfDates: Moment[] = [];

  let updatedStartDate = moment(startDateInTimeStamp);
  do {
    arrayOfDates = [...arrayOfDates, moment(updatedStartDate)];
    updatedStartDate = moment(updatedStartDate).add(1, 'day');
  } while (updatedStartDate.isSameOrBefore(endDate));

  return { response, arrayOfDates };
};

export const updateSomeJobDetail = (job, status, startedDate, endDate) => {
  return firebase
    .firestore()
    .collection('jobs')
    .doc(job.key)
    .update({
      status,
      endDate,
      startedDate: startedDate ?? job.createdDate,
      modified: true,
    });
};

const getRequiredDataForJobHistory = (job) => {
  const { startedDate, endDate, status } = job;

  return {
    startedDate,
    endDate,
    status,
  };
};

export const storeAuditHistory = async (beforeJob, afterJob) => {
  const { uid } = firebase.auth().currentUser!;

  const { key: jobKey } = afterJob;

  const beforeJobData = getRequiredDataForJobHistory(beforeJob);
  const afterJobData = getRequiredDataForJobHistory(afterJob);

  const auditedDate = getTimeInEpoch();

  const historyData = {
    beforeJobData,
    afterJobData,
    auditedDate,
    uid,
  };

  const jobAuditHistoryRef = firebase
    .firestore()
    .collection('jobs')
    .doc(jobKey)
    .collection('jobAuditHistories')
    .doc();

  const { id: key } = jobAuditHistoryRef;

  await jobAuditHistoryRef.set({ ...historyData, key });
  const jobHistory = { ...historyData, key };
  return jobHistory;
};

export const fetchJobAuditHistory = async (jobKey, limit) => {
  const jobRef = firebase.firestore().collection('jobs').doc(jobKey);

  let jobHistoryRef = await jobRef
    .collection('jobAuditHistories')
    .orderBy('auditedDate', 'desc');

  if (limit) {
    jobHistoryRef = jobHistoryRef.limit(limit);
  }

  const jobHistoryRes = await jobHistoryRef.get();

  const auditHistories = jobHistoryRes.docs.map((d) => d.data());

  return auditHistories;
};

export const getOlderJobs = async (lastJobCreatedDate, limit = 25) => {
  let olderJobRef = firebase
    .firestore()
    .collection('jobs')
    .where('companyKey', '==', companyKey!);

  if (lastJobCreatedDate) {
    olderJobRef = olderJobRef.where('createdDate', '<', lastJobCreatedDate);
  }
  const olderJobsRes = await olderJobRef
    .orderBy('createdDate', 'desc')
    .limit(limit)
    .get();

  const jobs = olderJobsRes.docs.map((d) => d.data());
  return jobs;
};

export const getRealtimeJobByRangeFilter = (
  { startDate, endDate }: { startDate: number; endDate: number },
  cb
) => {
  let olderJobRef = firebase
    .firestore()
    .collection('jobs')
    .where('companyKey', '==', companyKey!);

  olderJobRef = olderJobRef.where('createdDate', '>=', startDate);
  olderJobRef = olderJobRef.where('createdDate', '<=', endDate);

  return olderJobRef.orderBy('createdDate', 'desc').onSnapshot((snap) => {
    cb(snap.docs.map((d) => d.data()));
  });
};

export const isCrewWorking = (crewUid, onUpdate) => {
  return firebase
    .firestore()
    .collection('jobs')
    .where('providerUids', 'array-contains', crewUid)
    .where('status', '==', 'started')
    .where('aborted', '==', false)
    .limit(1)
    .onSnapshot((res) => {
      const isWorking = res.docs.length;
      onUpdate(!!isWorking);
    });
};

export const getJobsLogs = async (jobKey, updateJobsLogs) => {
  return firebase
    .database()
    .ref(`logs/${jobKey}`)
    .on('child_added', (res) => {
      updateJobsLogs(res.val());
    });
};

export const storeSchedulingBody = async (requestBody) => {
  const requestData = { ...requestBody };

  let { key } = requestData;

  const scheduledReportCollectionRef = firebase
    .firestore()
    .collection('customers')
    .doc(companyKey!)
    .collection('scheduledReport');

  let scheduledReportRef: firebase.firestore.DocumentReference;

  if (!key) {
    scheduledReportRef = scheduledReportCollectionRef.doc();
    key = scheduledReportRef.id;
    requestData.key = key;
    requestData.companyKey = companyKey;
  } else {
    scheduledReportRef = scheduledReportCollectionRef.doc(key);
  }

  requestData.userUid = firebase.auth().currentUser!.uid;

  await scheduledReportRef.set(requestData, { merge: true });

  return requestData;
};

export const getScheduledReportsOfACompany = async () => {
  const companyRef = firebase
    .firestore()
    .collection('customers')
    .doc(companyKey!);

  const res = await companyRef.collection('scheduledReport').get();

  const scheduledReports = res.docs.map((doc) => doc.data());

  return scheduledReports;
};

export const getRTScheduledReportsOfACompany = async (updateReports) => {
  const companyRef = firebase
    .firestore()
    .collection('customers')
    .doc(companyKey!);

  const res = await companyRef
    .collection('scheduledReport')
    .onSnapshot((data) => {
      const reports = data.docs.map((d) => d.data());
      updateReports(reports);
    });
};

export const deleteScheduledReportFromDatabase = (scheduledReportKey) => {
  return firebase
    .firestore()
    .collection('customers')
    .doc(companyKey!)
    .collection('scheduledReport')
    .doc(scheduledReportKey)
    .delete();
};

export const sendEmail = (scheduledReport) => {
  const generatePdfAndSend = firebase
    .functions()
    .httpsCallable('pdfGeneration-pdfCrons-pdfOnRequest', {
      timeout: 540000,
    });

  return generatePdfAndSend(scheduledReport);
};

export const storeConfigurableJob = (reqBody) => {
  const configurationJobRef = firebase
    .firestore()
    .collection('customers')
    .doc(companyKey!)
    .collection('configurationjob')
    .doc();

  const { id } = configurationJobRef;
  return configurationJobRef.set({ ...reqBody, id });
};

const ReposnseBody = {
  validPhone: false,
  roles: [],
};

/**
 *
 * @param {string} phoneNumber
 */
export const checkIfPhoneNumberValid = (phoneNumber) => {
  const hasPhoneNumber = firebase
    .functions()
    .httpsCallable('authGroup-authGroup-hasPhoneNumber');

  return hasPhoneNumber({ phoneNumber });
};

export const sendOTPToPhoneNumber = (phoneNumber: string) => {
  return (
    firebase
      .auth()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .signInWithPhoneNumber(phoneNumber, window.recaptchaVerifier)
  );
};

export const signInWithPhoneNumber = (confirmationResult, smsCode) => {
  const { verificationId } = confirmationResult;
  const credential = firebase.auth.PhoneAuthProvider.credential(
    verificationId,
    smsCode
  );

  return loginWithCredential(credential);
};

export const getPendingJobsByMarkerKey = async (markerKey) => {
  const res = await firebase
    .firestore()
    .collection('jobs')
    .where('status', 'in', ['started', 'assigned'])
    .where('allMarkers', 'array-contains', markerKey)
    .get();

  return res.docs.length;
};

/// ////  BELOW ARE THE FUNCTIONS FROM CUSTOMER APP

export async function createCrewForCustomer(prov) {
  const provider = { ...prov };
  provider.companyKey = companyKey;
  if (provider.uid) {
    return firebase
      .firestore()
      .collection('providers')
      .doc(provider.uid)
      .set({ ...provider }, { merge: true });
  }
  const addProviderFunc = firebase
    .functions()
    .httpsCallable('providersGroup-providersGroup-createServiceProvider');

  return addProviderFunc({ ...provider });
}

function getCrewsLocations(data: any, uniqueUids) {
  return Object.entries(data ?? {}).reduce((acc: any, locationData: any[]) => {
    if (locationData[1]?.latitude && uniqueUids.has(locationData[0])) {
      return [...acc, { uid: locationData[0], ...(locationData[1] ?? {}) }];
    }
    return acc;
  }, []);
}

export async function getCrewsLocationsOfAJob(jobKey) {
  const response = await firebase
    .firestore()
    .collection('jobs')
    .doc(jobKey)
    .get();

  const job = response?.data() ?? {};

  if (!job?.companyKey) return [];

  const crewUids = new Set(job?.providerUids ?? []);

  if (!crewUids) return [];

  const data = await firebase
    .database()
    .ref()
    .child('locations')
    .child(job?.companyKey)
    .once('value');

  const crewLocations = getCrewsLocations(data.val(), crewUids);

  return crewLocations ?? [];
}

// gets and updates location information of all the providers of the customers
export async function getRealTimeLocationOfAllProvidersForMarkers(
  markers,
  onChange
) {
  if (!markers || markers?.length === 0) return onChange([]);

  const promises = markers?.map((marker) => {
    return firebase
      .firestore()
      .collection('marker_logs')
      .where('servicePointId', '==', marker.key)
      .get();
  });

  const markerLogsResponse = await Promise.allSettled(promises ?? []);

  const crewUids = Object.values(markerLogsResponse)?.reduce(
    (acc: string[], response) => {
      if (response?.status === 'fulfilled') {
        const markerLog = response.value.docs[0]?.data() ?? {};

        if (markerLog?.working && markerLog?.providerUid) {
          return [...acc, markerLog?.providerUid];
        }
        return acc;
      }
      return acc;
    },
    []
  );

  const uniqueUids = new Set(crewUids);

  return firebase
    .database()
    .ref()
    .child('locations')
    .child(companyKey!)
    .on('value', (snap) => {
      const data = getCrewsLocations(snap.val(), uniqueUids);
      onChange(data ?? []);
    });
}

function getPromiseValue(response) {
  const values = response?.reduce((acc, eachResponse) => {
    if (eachResponse.status === 'fulfilled') {
      const value = eachResponse.value.docs.map((d) => d.data());
      return [...acc, ...value];
    }
    return acc;
  }, []);

  return values ?? [];
}

export async function getRealTimeLogsForMarkers(markers) {
  if (!markers || markers?.length === 0) return [];

  const markerKeys = markers?.map((marker) => marker.key);

  const key = markers[0].companyKey;

  const markersChunks = chunk(markerKeys, 10);

  const promise = markersChunks?.map((keysMarkers) => {
    return firebase
      .firestore()
      .collection('customers')
      .doc(key)
      .collection('logs')
      .where('markerKey', 'in', keysMarkers)
      .orderBy('date', 'desc')
      .get();
  });

  const response = await Promise.allSettled(promise ?? []);

  const allLogs = getPromiseValue(response);

  return allLogs;
}

export function getRealTimeServicePointsOfCustomer(updateServicePoints) {
  const user = firebase.auth().currentUser;
  return firebase
    .firestore()
    .collection('servicePoints')
    .where('customerDetail.uid', '==', user!.uid)
    .onSnapshot((data) => {
      const markersOfACustomer = data.docs.map((d) => d.data());
      updateServicePoints(markersOfACustomer);
    });
}

export async function addEditNewServicePointForCustomer(formData) {
  const data = { ...formData };
  data.companyKey = companyKey;
  let ref;
  if (!data.key) {
    const getName = await firebase
      .firestore()
      .collection('servicePoints')
      .where('name', '==', data.name)
      .get();

    if (getName.docs.length > 1) {
      return true;
    }

    ref = firebase.firestore().collection('servicePoints').doc();
    data.key = ref.id;
  } else {
    ref = firebase.firestore().collection('servicePoints').doc(data.key);
  }
  return ref.set(data);
}

/*
 ****************************************************************************
 * @brief  (getRealTimeJobs) calls the given callback Jobs are changed
 *
 * @param{type {function}} (updateJobs)
 *
 * @returns undefined
 ****************************************************************************
 */

export function getRealTimeJobsForMarkers(markers, updateJobs) {
  const markersKeys = markers?.map((marker) => marker?.key) ?? [];

  if (markersKeys?.length === 0) return updateJobs([]);

  const ref = firebase
    .firestore()
    .collection('jobs')
    .where('allMarkers', 'array-contains-any', markersKeys)
    .orderBy('createdDate', 'desc');

  return ref.onSnapshot((data) => {
    const response = data?.docs?.map((d) => d.data()) ?? [];
    updateJobs(response);
  });
}

// export async function getRealTimePeriodicJobs(updateJobs) {
//   const ref = firebase
//     .firestore()
//     .collection('periodicJobs')
//     .where('companyKey', '==', companyKey!)
//     .orderBy('createdDate', 'desc');

//   ref.onSnapshot((data) => {
//     const _allJobs = {};
//     data.docs.forEach((p) => {
//       _allJobs[p.data().key] = p.data();
//     });
//     updateJobs(_allJobs);
//   });
// }

export async function getJobInfo(jobId) {
  let response = await firebase.firestore().collection('jobs').doc(jobId).get();

  if (response.data()) {
    return response.data();
  }

  response = await firebase
    .firestore()
    .collection('periodicJobs')
    .doc(jobId)
    .get();
  return response.data();
}

// firebase functions for pdf data

/*
 ****************************************************************************
 * @brief  (getActiveRoutesForProvider) returns the list of active routes
 * for a provider
 *
 * @param{type {string, string, string}} (providerUid, startDate, endDate)
 *
 * @returns promise
 ****************************************************************************
 */

// export async function getJobsForCrew(crewUid, startDate, endDate) {
//   const response = await firebase
//     .firestore()
//     .collection('jobs')
//     .where('companyKey', '==', companyKey!)
//     .where('providerUids', 'array-contains', crewUid)
//     .orderBy('startedDate', 'desc')
//     .where('startedDate', '>=', startDate)
//     .get();

//   const jobs = response.docs.reduce((accumulator, eachResponse) => {
//     const job = eachResponse.data();
//     const jobEndDate = job.endDate || 0;

//     if (jobEndDate <= endDate) {
//       return [...accumulator, job];
//     }
//     return accumulator;
//   }, []);

//   return jobs;
// }

/*
 ****************************************************************************
 * @brief  (getProvidersOfCompany) returns the list of providers of company
 *
 * @param{type {}} ()
 *
 * @returns promise
 ****************************************************************************
 */

// export async function getProvidersOfCompany() {
//   const providers = await firebase
//     .firestore()
//     .collection('providers')
//     .where('companyKey', '==', companyKey!)
//     .get();
//   return providers.docs.map((provider) => provider.data()) || [];
// }

/*
 ****************************************************************************
 * @brief  (getJobsForActiveRoute) returns jobs for a active route
 *
 * @param{type {string}} (activeRouteKey)
 *
 * @returns promise
 ****************************************************************************
 */

// export async function getJobsForRoute(routeKey, startDate, endDate) {
//   const response = await firebase
//     .firestore()
//     .collection('jobs')
//     .where('companyKey', '==', companyKey!)
//     .where('allRoutes', 'array-contains', routeKey)
//     .orderBy('startedDate', 'desc')
//     .where('startedDate', '>=', startDate)
//     .get();

//   const jobs = response.docs.reduce((accumulator, eachResponse) => {
//     const job = eachResponse.data();
//     const jobEndDate = job.endDate || 0;

//     if (jobEndDate <= endDate) {
//       return [...accumulator, job];
//     }
//     return accumulator;
//   }, []);

//   return jobs;
// }

// service point by id is up ^^^^^

/*
 ****************************************************************************
 * @brief  (deleteServicePoint) deletes the service point
 *
 * @param{type {string}} (servicePointKey)
 ****************************************************************************
 */

export const deletePeriodicJob = (jobKey) => {
  return firebase.firestore().collection('periodicJobs').doc(jobKey).delete();
};

export async function createEditPeriodicJob(jobData) {
  const job = { ...jobData };

  job.companyKey = companyKey;
  if (!job.key) {
    const timeInEpoch = getTimeInEpoch();
    job.createdDate = timeInEpoch;
  }
  let ref;
  if (!job.key) {
    ref = firebase.firestore().collection('periodicJobs').doc();
    job.key = ref.id;
  } else {
    ref = firebase.firestore().collection('periodicJobs').doc(job.key);
  }
  return ref.set(job);
}
// service point by id is up ^^^^^ //

export const getMarkerLog = async (
  jobKey,
  markerLogKey,
  routeKey,
  working,
  _updateMarkerLogs
) => {
  const query = firebase
    .firestore()
    .collection('marker_logs')
    .doc(markerLogKey);
  if (_updateMarkerLogs) {
    return query.onSnapshot((response) => {
      let logs;
      if (response.data()) {
        logs = [response.data()];
      }

      _updateMarkerLogs(logs ?? []);
    });
  }
  const response = await query.get();
  return response.data();
};

export async function getLocationsFromFirestore(user, _updateProperties) {
  firebase
    .firestore()
    .collectionGroup('properties')
    .where('customer.uid', '==', user.user_id)
    .onSnapshot((snap) => {
      const res = snap.docs.map((d) => d.data());
      _updateProperties(res);
    });
}

// export async function completeJob(jobKey) {
//   return firebase
//     .firestore()
//     .collection('jobs')
//     .doc(jobKey)
//     .update({ status: 'completed' });
// }

// export function disableCrewAccount(uid) {
//   const disableAccount = firebase
//     .functions()
//     .httpsCallable('authGroup-authGroup-disableAccount');

//   return disableAccount({ uid });
// }

// export function deleteCrewAccount(uid) {
//   const deleteAccount = firebase
//     .functions()
//     .httpsCallable('authGroup-authGroup-deleteAccount');

//   return deleteAccount({ uid });
// }

// export function enableCrewAccount(uid) {
//   const enableAccount = firebase
//     .functions()
//     .httpsCallable('authGroup-authGroup-enableAccount');

//   return enableAccount({ uid });
// }

export async function getPropertyAssignedMarkers(propertiesKeys) {
  if (propertiesKeys?.length === 0) return [];

  const propertyChunks = chunk(propertiesKeys, 10);

  const promise = propertyChunks?.map((keys) => {
    return firebase
      .firestore()
      .collection('servicePoints')
      .where('propertyKey', 'in', keys)
      .where('archive', '==', false)
      .get();
  });

  const response = await Promise.allSettled(promise ?? []);

  const markers = getPromiseValue(response);

  return markers;
}

export function getMarkerAssignedToCustomer(user, updateMarkers) {
  firebase
    .firestore()
    .collection('servicePoints')
    .where('customerDetail.uid', '==', user?.user_id)
    .onSnapshot((res) => {
      const response = res.docs.map((d) => d.data());
      updateMarkers(response);
    });
}

export async function getActiveJobsDetails(markers) {
  if (!markers || markers?.length === 0) return [];

  const markersKeys = markers?.map((marker) => marker?.key);

  const markersKeysArray = chunk(markersKeys, 10);

  const promise = markersKeysArray?.map((keys) => {
    return firebase
      .firestore()
      .collection('jobs')
      .where('allMarkers', 'array-contains-any', keys)
      .where('status', '==', 'started')
      .get();
  });

  const response = await Promise.allSettled(promise ?? []);

  const jobs = getPromiseValue(response);

  return jobs;
}

export async function getMarkerLogsForMarkers(markersKeys, date) {
  let startDate;
  let endDate;

  if (date?.startDate) {
    startDate = getTimeInEpoch(date?.startDate);
  }

  if (date?.endDate) {
    endDate = getTimeInEpoch(date?.endDate);
  }

  const markersKeysChunk = chunk(markersKeys, 10);

  const promise = markersKeysChunk?.map((keys) => {
    let ref = firebase
      .firestore()
      .collection('marker_logs')
      .where('servicePointId', 'in', keys)
      .orderBy('startDate', 'desc');

    if (endDate) {
      ref = ref.where('startDate', '<', endDate);
    }

    if (startDate) {
      ref = ref.where('startDate', '>=', startDate);
    }

    return ref.limit(100).get();
  });

  const response = await Promise.allSettled(promise ?? []);

  const markerLogs = getPromiseValue(response);

  return markerLogs;
}

export async function getActiveRoutes(markers) {
  if (!markers || markers?.length === 0) return [];

  const markersKeys = markers?.map((marker) => marker.key);

  const markersChunks = chunk(markersKeys, 10);

  const promise = markersChunks?.map((keys) => {
    return firebase
      .firestore()
      .collectionGroup('routes')
      .where('servicePointsKeys', 'array-contains-any', keys)
      .where('status', '==', 'in_progress')
      .get();
  });

  const response = await Promise.allSettled(promise ?? []);

  const routes = getPromiseValue(response);

  return routes;
}

export async function getActiveRouteDetails(jobKey, activeRouteKey) {
  const response = await firebase
    .firestore()
    .collection('jobs')
    .doc(jobKey)
    .collection('routes')
    .doc(activeRouteKey)
    .get();

  const routeDetails = response.data() ?? {};

  return routeDetails;
}

export async function getMarkersDetails(markersKeys) {
  if (!markersKeys || markersKeys?.length === 0) return [];

  const markersChunks = chunk(markersKeys, 10);

  const promise = markersChunks?.map((keys) => {
    return firebase
      .firestore()
      .collection('servicePoints')
      .where('key', 'in', keys)
      .get();
  });

  const response = await Promise.allSettled(promise ?? []);

  const markers = getPromiseValue(response);

  return markers;
}

export async function getMarkersLogsForAJob(jobKey, markersKeys, routeKey) {
  if (!jobKey || !routeKey || !markersKeys || markersKeys?.length === 0) {
    return [];
  }

  const markersChunks = chunk(markersKeys, 10);

  const promise = markersChunks?.map((keys) => {
    return firebase
      .firestore()
      .collection('marker_logs')
      .where('jobKey', '==', jobKey)
      .where('routeKey', '==', routeKey)
      .where('servicePointId', 'in', keys)
      .get();
  });

  const response = await Promise.allSettled(promise ?? []);

  const markerLogs = getPromiseValue(response);

  return markerLogs;
}

export async function getServiceRequestsOfCustomer(customerUid) {
  const response = await firebase
    .firestore()
    .collectionGroup('workOrders')
    .where('customerUid', '==', customerUid)
    .orderBy('requestedDate', 'desc')
    .get();

  const serviceRequests = response.docs.map((d) => {
    return { urgency: 'HIGH', ...d.data() };
  });

  return serviceRequests;
}

export async function addRequest(requestData) {
  const data = { ...requestData };

  data.requestedDate = data.requestedDate ?? getTimeInEpoch();
  data.status = data.status ?? 'REQUESTED';

  const ref = firebase
    .firestore()
    .collection('servicePoints')
    .doc(data.markerKey)
    .collection('workOrders');

  data.id = data.id ?? ref.doc().id;
  await ref.doc(data.id).set(data);
  return data;
}

export async function getOrderDetail(markerKey, orderKey) {
  const response = await firebase
    .firestore()
    .collection('servicePoints')
    .doc(markerKey)
    .collection('workOrders')
    .doc(orderKey)
    .get();

  let order = {};
  if (response.data()) {
    order = { urgency: 'HIGH', ...(response.data() ?? {}) };
  }

  return order;
}

export async function getJobByWorkOrderId(jobKey) {
  const res = await firebase.firestore().collection('jobs').doc(jobKey).get();

  const job = res.data();
  return job ?? {};
}

export function deleteServiceRequest(request) {
  const { markerKey, id } = request;

  return firebase
    .firestore()
    .collection('servicePoints')
    .doc(markerKey)
    .collection('workOrders')
    .doc(id)
    .delete();
}

export async function getOlderSchedules(createdDateOfLastJob: number) {
  const jobsRes = await firebase
    .firestore()
    .collection('jobs')
    .where('companyKey', '==', companyKey)
    .orderBy('createdDate', 'desc')
    .limit(25)
    .startAfter(createdDateOfLastJob)
    .get();

  return jobsRes.docs.map((d) => d.data());
}

export function getLatestJobs(updateJobs) {
  const todayTimestamp = moment().startOf('day').valueOf();
  const tomorrowTimestamp = todayTimestamp + 24 * 60 * 60 * 1000;
  const lastWeek = todayTimestamp - 7 * 24 * 60 * 60 * 1000;

  return firebase
    .firestore()
    .collection('jobs')
    .where('companyKey', '==', companyKey)
    .where('createdDate', '>=', lastWeek)
    .where('createdDate', '<=', tomorrowTimestamp)
    .orderBy('createdDate', 'desc')
    .onSnapshot((snap) => {
      updateJobs(snap.docs.map((d) => d.data()));
    });
}

export const editSchedule = async (
  updatedSchedule: Schedule,
  scheduleEditType: 'onlyThis' | 'thisAndFollowing'
) => {
  const {
    key,
    notes,
    parentKey,
    activities,
    allMarkers,
    createdDate,
    providerUids,
    propertyKeys,
    enabledGeofencing,
    minimumServingDistance,
    shift: { startTime, endTime, timezone },
  } = updatedSchedule;

  const startHour = Number(startTime.split(':')[0]);
  const startMinute = Number(startTime.split(':')[1]);
  const endHour = Number(endTime.split(':')[0]);
  const endMinute = Number(endTime.split(':')[1]);

  switch (scheduleEditType) {
    case 'onlyThis': {
      const currentTimezonedDate = dayjs(createdDate).tz(timezone);
      const newCreatedDate = currentTimezonedDate
        .set('h', startHour)
        .set('minute', startMinute)
        .valueOf();
      const dateToEndJob = currentTimezonedDate
        .set('h', endHour)
        .set('minute', endMinute)
        .valueOf();
      const oldScheduleRef = firebase.firestore().collection('jobs').doc(key);

      const oldSchedule = (await oldScheduleRef.get()).data()!;

      const revisedSchedule = {
        ...oldSchedule,
        notes,
        activities,
        allMarkers,
        providerUids,
        propertyKeys,
        parentKey: null,
        enabledGeofencing,
        minimumServingDistance,
        createdDate: newCreatedDate,
        dateToEndJob,
        shift: { ...(oldSchedule.shift ?? {}), startTime, endTime },
      };

      return oldScheduleRef.update(revisedSchedule);
    }
    case 'thisAndFollowing': {
      const res = await firebase
        .firestore()
        .collection('jobs')
        .where('parentKey', '==', parentKey)
        .where('createdDate', '>=', createdDate)
        .get();

      const newParentKey = generateRandomString(16);

      return firebase.firestore().runTransaction(async (transaction) => {
        return res.docs.map((d) => {
          const oldSchedule = d.data()!;

          const currentTimezonedDate = dayjs(oldSchedule.createdDate).tz(
            timezone
          );
          const newCreatedDate = currentTimezonedDate
            .set('h', startHour)
            .set('minute', startMinute)
            .valueOf();

          const dateToEndJob = currentTimezonedDate
            .set('h', endHour)
            .set('minute', endMinute)
            .valueOf();

          const revisedSchedule = {
            ...oldSchedule,
            notes,
            activities,
            allMarkers,
            providerUids,
            propertyKeys,
            dateToEndJob,
            enabledGeofencing,
            minimumServingDistance,
            parentKey: newParentKey,
            createdDate: newCreatedDate,
            shift: { ...(oldSchedule.shift ?? {}), startTime, endTime },
          };

          return transaction.update(d.ref, revisedSchedule);
        });
      });
    }

    default:
      return null;
  }
};

interface ReportDetails {
  name: string;
  id: string | string[];
}
interface Report {
  key: ReportDetails;
  toDate: string;
  fromDate: string;
  convertTo: string;
  createReportName: string;
  showImage: boolean;
}

export const getGeneratedReport = ({
  key,
  toDate,
  fromDate,
  convertTo,
  createReportName,
  showImage,
}: Report) => {
  const { name, id } = key;

  // TODO: add callable function
  const getReport = firebase.functions().httpsCallable(createReportName);

  return getReport({
    [name]: id,
    toDate,
    fromDate,
    companyKey,
    convertTo,
    generatedBy: auth().currentUser?.displayName,
    showImage,
  });
};
