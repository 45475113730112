/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: Private/index.js
 *
 *  Description: Entry point of private components.
 *
 *  Author: Nabin Kharal (nabin@brainants.com) and Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 4-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useState, useEffect, useContext } from 'react';

import styled from 'styled-components';
import BookIcon from 'mdi-react/BookIcon';
import RoutesIcon from 'mdi-react/RoutesIcon';
import HomeIcon from 'mdi-react/HomeOutlineIcon';
import { withTheme } from '@material-ui/core/styles';
import { Redirect, Route, Switch } from 'react-router-dom';
import PlaylistCheckIcon from 'mdi-react/PlaylistCheckIcon';
import HomeMapMarkerIcon from 'mdi-react/HomeMapMarkerIcon';
import AccountCircleIcon from 'mdi-react/AccountCircleIcon';
import AccountHardHatIcon from 'mdi-react/AccountHardHatIcon';
import HomeCityOutlineIcon from 'mdi-react/HomeCityOutlineIcon';
import ViewDashboardIcon from 'mdi-react/ViewDashboardIcon';

import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import { Box, Flex } from '@chakra-ui/react';
import Home from './Home';
import Jobs from './Jobs';
import Routes from './routes';
import ServicePoints from './markers';
import Properties from './properties';
import ServiceLogs from './serviceLogs';
import WorkOrders from './serviceRequests';
import AppBar from '../Generics/snowm_appbar';
import Drawer from '../Generics/snowm_drawer';
import UserProfile from './Profile/UserProfile';
import { ServiceTypesContext } from '../../../contexts/service_types';
import { LocalizationContext } from '../../../contexts/localization_context';
import { ReactComponent as DeactiveMicroLocationIcon } from '../../../assets/svgs/DeactiveMicroLocationIcon.svg';
import { ReactComponent as MicroLocationIcon } from '../../../assets/svgs/MicroLocationIcon.svg';
import Loader from '../../../Components/Generics/snowm_loader';

/*
 ****************************************************************************
 * @brief  (getRoutes)      renders the list of routes
 * @param{type : Array,Function,Object } (routesToRender,setActive,theme)
 *
 * @return  returns a jsx element containing list of all routesToRender
 *
 ****************************************************************************
 */
function getRoutes(routesToRender, setActive, theme) {
  return (
    <>
      {routesToRender.map((route) => {
        return (
          <Switch key={route.name}>
            <Route
              path={route.route}
              component={(p) => {
                useEffect(() => {
                  setActive(route.route);
                }, []);
                const Component = route.component;
                return <Component {...p} theme={theme} />;
              }}
              exact={route.exact}
            />
          </Switch>
        );
      })}
    </>
  );
}

const drawerWidth = 240;

// Functional Component
const CustomerDashboard = (props) => {
  const { strings } = useContext(LocalizationContext);
  const {
    DASHBOARD,
    SHIFTS,
    JANITORS,
    ROUTES,
    MICROLOCATIONS,
    REPORTS,
    COMPANY_PROFILE,
    TASKS,
    LOCATIONS,
    MANAGER,
    USER_PROFILE,
  } = strings.sidemenu;
  const [active, setActive] = useState('');
  const { theme } = props;

  // return content of the private component (with appropriate routes)

  const data = useContext(ServiceTypesContext);

  const { subscribedServices } = data;

  const routes = [
    {
      route: '/customer',
      component: Home,
      name: DASHBOARD,
      Icon: ViewDashboardIcon,
      exact: true,
    },
    {
      route: '/customer/locations',
      component: Properties,
      name: LOCATIONS,
      Icon: MapMarkerIcon,
    },
    {
      route: '/customer/microlocations',
      component: ServicePoints,
      name: MICROLOCATIONS,
      Icon: DeactiveMicroLocationIcon,
      ActiveIcon: MicroLocationIcon,
    },
    {
      route: '/customer/jobs',
      component: Jobs,
      name: SHIFTS,
      Icon: PlaylistCheckIcon,
      hide: true,
    },
    {
      route: '/customer/routes',
      component: Routes,
      name: ROUTES,
      Icon: RoutesIcon,
      hide: !subscribedServices?.isRouteAvailable,
    },
    {
      route: '/customer/serviceRequests',
      component: WorkOrders,
      name: 'Service Requests',
      Icon: AccountHardHatIcon,
      hide: true,
    },
    {
      route: '/customer/profile',
      component: UserProfile,
      name: USER_PROFILE,
      Icon: AccountCircleIcon,
      menu: true,
    },
    {
      route: '/customer/servicelogs',
      name: 'Service Logs',
      component: ServiceLogs,
      Icon: BookIcon,
      hide: true,
    },
  ];

  useEffect(() => {
    data.getDetails();
  }, []);

  if (!subscribedServices) return <Loader />;

  return (
    <Body>
      <Flex
        zIndex="2"
        height="100%"
        borderTopRadius="40px"
        boxShadow="0px 12px 24px #ECEEF5"
      >
        <Drawer
          active={active}
          width={drawerWidth}
          routes={routes}
          {...props}
        />
      </Flex>

      <Flex flexDirection="column" width="100%" height="100%">
        <AppBar routes={routes} {...props} />
        <Main theme={theme}>
          <Switch>
            {getRoutes(routes, setActive, theme)}
            <Redirect to="/customer" exact />
          </Switch>
        </Main>
      </Flex>
    </Body>
  );
};

export default withTheme(CustomerDashboard);

const Body = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
  flex-direction: row;
`;

const Main = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #fbf9fb;
`;
