/* eslint-disable react/destructuring-assignment */
import { isEmpty } from 'lodash';
import Styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
import SearchIcon from 'mdi-react/SearchIcon';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { Card, Button, Paper, InputBase, IconButton } from '@material-ui/core';

import Modal from './snowm_modal';
import { Col, Row } from '../../styles/snowm_styled';
import MapMarker from '../Private/Maps/snowm_marker';
import {
  getLatLongFor,
  getAddressFromLongLat,
} from '../../../controllers/map_helper';

// eslint-disable-next-line import/prefer-default-export
export const LocationModal = (props) => {
  const zoom = 13;

  const [searchText, setSearchText] = useState('');
  const [mapState, setMapState] = useState({
    mapApi: null,
    mapInstance: null,
    mapApiLoaded: false,
  });

  const [latLng, setLatLng] = useState({});
  const [address, setAddress] = useState('Unnamed Place');
  const [draggable, setDraggable] = useState(true);

  useEffect(() => {
    let unsubscribe = true;

    navigator.geolocation.getCurrentPosition((position) => {
      if (unsubscribe) {
        setLatLng({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      }
      getAddressFromLongLat(
        position.coords.latitude,
        position.coords.longitude
      ).then((locAddress) => {
        if (unsubscribe) {
          setAddress(locAddress);
        }
      });
    });
    return () => {
      unsubscribe = false;
    };
  }, []);

  const handleTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleApiLoaded = ({ map, maps }) => {
    setMapState({
      mapApiLoaded: true,
      mapApi: maps,
      mapInstance: map,
    });
  };

  const onSearchPlace = () => {
    getLatLongFor(searchText).then((response) => {
      setLatLng(response.latlng);
      setAddress(response.address);
      mapState.mapInstance.setCenter({
        lat: response.latlng.lat,
        lng: response.latlng.lng,
      });
    });
  };

  const onMouseDown = () => {
    setDraggable(false);
  };

  const onMouseMove = (childKey, childProps, mouse) => {
    setLatLng(mouse);
    getAddressFromLongLat(mouse.lat, mouse.lng).then((locAddress) => {
      setAddress(locAddress);
    });
  };

  const onMouseUp = () => {
    setDraggable(true);
  };

  const onMapClick = ({ lat, lng }) => {
    getAddressFromLongLat(lat, lng).then((locAddress) => {
      setAddress(locAddress);
    });
    setLatLng({ lat, lng });
  };

  const renderMarker = () => {
    return <MapMarker lat={latLng.lat} lng={latLng.lng} />;
  };

  const handleLocationClose = () => {
    props.locationModal.close();
  };

  return (
    <Modal {...props.locationModal.props}>
      <ModalCard>
        <Col align="center" justify="center" style={{ padding: 16 }}>
          <Row justify="space-between">
            <h4>Add Location</h4>
            <IconButton onClick={handleLocationClose}>
              <CloseIcon />
            </IconButton>
          </Row>
          {!isEmpty(latLng) && (
            <MapContainer>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
                }}
                defaultCenter={[]}
                center={latLng}
                defaultZoom={zoom}
                draggable={draggable}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={handleApiLoaded}
                onChildMouseDown={onMouseDown}
                onChildMouseMove={onMouseMove}
                onChildMouseUp={onMouseUp}
                onClick={onMapClick}
              >
                {renderMarker()}
              </GoogleMapReact>
              {mapState.mapApiLoaded && (
                <StyledPaper>
                  <InputBase
                    style={{ marginLeft: 8, flex: 1 }}
                    placeholder="Search Google Maps"
                    inputProps={{ 'aria-label': 'Search Google Maps' }}
                    onChange={handleTextChange}
                  />
                  <IconButton
                    style={{ padding: 10 }}
                    aria-label="Search"
                    onClick={onSearchPlace}
                  >
                    <SearchIcon />
                  </IconButton>
                </StyledPaper>
              )}
              <StyledButton
                variant="contained"
                color="primary"
                onClick={() => {
                  props.onLocationChange(latLng, address);
                }}
              >
                Pick
              </StyledButton>
            </MapContainer>
          )}
        </Col>
      </ModalCard>
    </Modal>
  );
};

const MapContainer = Styled.div`
    position:relative;
    display:flex;
    flex-direction:row;
    height:calc(50vh);
    width:100%;
`;

const StyledPaper = Styled(Paper)`
&& {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 16px;
  margin: auto;
  padding: 4px;
  display: flex;
  align-items: center;
  width: 400px;
}
`;

const StyledButton = Styled(Button)`
&& {
  position: absolute;
  bottom: 16px;
  left: 0px;
  right: 0px;
  margin: auto;
  width: 150px;
}`;
const ModalCard = Styled(Card)`
  &&&& {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow:scroll;
    min-height:50%;
    min-width: 50%;
    max-height:90%;
  }
`;
