import React, { useEffect, useState } from 'react';

import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import Span from '../../../Common/Span';
import color from '../../../../utils/color';
import { Row } from '../../../../styles/snowm_styled';
import { StyledTableRow } from '../../../Common/styled';
import { Progress } from '../../../Generics/snowm_loader';
import { getUserInfo } from '../../../../controllers/snowm_firebase';
import { getDateForJob, getDayMonthYear } from '../../../../helpers/date';
import jobStatusForUI, { getColorForStatus } from '../../../../enums/jobStatus';

const HistoryTable = ({ jobHistories }) => {
  if (!jobHistories.length) {
    return (
      <Row justify="center">
        <Span weight="bold">No job histories.</Span>
      </Row>
    );
  }

  const headers = ['Date', 'Edited by', '', 'Start date', 'End date', 'Status'];

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <StyledTableRow bgcolor={color.snomwBlue}>
            {headers.map((header) => (
              <TableCell key={header}>
                <Span color={color.white}>{header}</Span>
              </TableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {jobHistories?.map((history) => (
            <JobHistoryTable history={history} key={history.auditedDate} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const JobHistoryTable = ({ history }) => {
  const { afterJobData, beforeJobData, uid, auditedDate } = history;

  const [crew, setCrew] = useState(null);

  useEffect(() => {
    getUserInfo(uid).then((res) => {
      setCrew(res);
    });
  }, [uid]);

  return (
    <TableRow>
      <TableCell>
        <Span>{getDayMonthYear(auditedDate)}</Span>
      </TableCell>

      <TableCell>
        {!crew ? <Progress /> : <Span weight="bold">{crew.name ?? '-'}</Span>}
      </TableCell>

      <TableCell>
        <Span weight="bold">From: </Span>
        <br />
        <Divider />
        <Span weight="bold">To: </Span>
      </TableCell>
      <TableCell>
        <Span>
          {!beforeJobData.startedDate
            ? '-'
            : getDateForJob(beforeJobData.startedDate)}
        </Span>
        <br />
        <Divider />
        <Span>
          {!afterJobData.startedDate
            ? '-'
            : getDateForJob(afterJobData.startedDate)}
        </Span>
      </TableCell>
      <TableCell>
        <Span>
          {!beforeJobData.endDate ? '-' : getDateForJob(beforeJobData.endDate)}
        </Span>
        <br />
        <Divider />
        <Span>
          {!afterJobData.endDate ? '-' : getDateForJob(afterJobData.endDate)}
        </Span>
      </TableCell>
      <TableCell>
        <Span color={getColorForStatus(beforeJobData)} weight="bold">
          {jobStatusForUI[beforeJobData.status]}
        </Span>
        <br />
        <Divider />
        <Span color={getColorForStatus(afterJobData)} weight="bold">
          {jobStatusForUI[afterJobData.status]}
        </Span>
      </TableCell>
    </TableRow>
  );
};

export default HistoryTable;
