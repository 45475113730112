import React, { useContext } from 'react';

import { Switch, Route, Redirect } from 'react-router';

import ActiveRoutes from './ActiveRoutes';
import RouteDetails from './RouteDetails';
import LogDetailOfAMarker from './LogDetailOfMarker';
import { ServiceTypesContext } from '../../../../contexts/service_types';

const Routes = () => {
  const data = useContext(ServiceTypesContext);

  const subscribedServices = data?.subscribedServices;

  if (!subscribedServices?.isRouteAvailable) {
    return <Redirect to="/customer" />;
  }

  return (
    <Switch>
      <Route
        path="/customer/routes/:jobKey/:key/marker/:markerKey"
        component={LogDetailOfAMarker}
      />
      <Route path="/customer/routes/:jobKey/:key" component={RouteDetails} />
      <Route path="/customer/routes" component={ActiveRoutes} />
    </Switch>
  );
};

export default Routes;
