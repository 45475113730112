import React, { useEffect, useState, useContext } from 'react';

import styled from 'styled-components';
import { useParams, Redirect, useHistory } from 'react-router';

import { StyledGrid } from '../styled';
import Span from '../../Common/Span';
import MapPolyline from '../Maps/map_polyline';
import Loader from '../../Generics/snowm_loader';
import { Title, Col } from '../../../styles/snowm_styled';
import MarkerLogDetails from '../markers/MarkerLogDetails';
import { ServiceTypesContext } from '../../../../contexts/service_types';
import {
  getActiveRouteDetails,
  getMarkersDetails,
  getCrewsLocationsOfAJob,
  getMarkersLogsForAJob,
} from '../../../../controllers/snowm_firebase';

const RouteDetails = () => {
  const data = useContext(ServiceTypesContext);

  const { markers } = data;
  const [routeDetails, setRouteDetails] = useState();

  const [outdoorMarkers, setOutdoorMarkers] = useState();
  const [crewsLocations, setCrewsLocations] = useState();
  const [markersKeys, setMarkersKeys] = useState();
  const [markersLogs, setMarkersLogs] = useState();
  const [remainingMarkers, setRemainingMarkers] = useState();

  const { key, jobKey } = useParams();

  const history = useHistory();

  useEffect(() => {
    let isCancel = false;
    if (key && jobKey) {
      getActiveRouteDetails(jobKey, key).then((res) => {
        if (!isCancel) setRouteDetails(res);
      });

      getCrewsLocationsOfAJob(jobKey).then((res) => {
        if (!isCancel) setCrewsLocations(res);
      });
    }

    return () => {
      isCancel = true;
    };
  }, [key, jobKey]);

  function getSubscribedMarkersKeys(keysMarkers) {
    const filteredMarkersKeys = keysMarkers?.filter((markerKey) => {
      return markersKeys?.has(markerKey);
    });
    return filteredMarkersKeys ?? [];
  }

  useEffect(() => {
    let isCancel = false;
    if (routeDetails) {
      const { servicePointsKeys, routeKey } = routeDetails;
      const subscribedMarkersKeys = getSubscribedMarkersKeys(servicePointsKeys);
      getMarkersDetails(subscribedMarkersKeys).then((res) => {
        if (!isCancel) setOutdoorMarkers(res);
      });

      getMarkersLogsForAJob(jobKey, subscribedMarkersKeys, routeKey).then(
        (res) => {
          setMarkersLogs(res);
        }
      );
    }
    return () => {
      isCancel = true;
    };
  }, [routeDetails, markersKeys]);

  useEffect(() => {
    const keysOfLoggedMarkers = markersLogs?.map((log) => log.servicePointId);
    const uniqueKeys = new Set(keysOfLoggedMarkers ?? []);
    const markersRemaining = outdoorMarkers?.filter(
      (marker) => !uniqueKeys?.has(marker.key)
    );

    setRemainingMarkers(markersRemaining ?? []);
  }, [markersLogs, outdoorMarkers]);

  useEffect(() => {
    const keys = markers?.map((marker) => marker.key);

    const uniqueKeys = new Set(keys ?? []);
    setMarkersKeys(uniqueKeys);
  }, [markers]);

  function doesMarkerExists() {
    const keys = routeDetails?.servicePointsKeys?.filter((markerKey) => {
      return markersKeys.has(markerKey);
    });

    return keys?.length > 0;
  }

  function handleCardClick(markerKey) {
    history.push(`/customer/routes/${jobKey}/${key}/marker/${markerKey}`);
  }

  if (!routeDetails || !markers) return <Loader />;

  if (!doesMarkerExists()) {
    return <Redirect to="/customer" />;
  }

  return (
    <ActiveRoutesContainer>
      <Title>Active Routes</Title>
      <MapContainer>
        <MapPolyline
          center={[47.367347, 8.5500025]}
          polyline={routeDetails.polyline}
          points={[...(outdoorMarkers ?? []), ...(crewsLocations ?? [])]}
          dashboard
        />
      </MapContainer>
      <Col>
        <Span weight="bold" size="16px">
          Logs
        </Span>

        <StyledGrid padding="0">
          {markersLogs?.map((log) => {
            return (
              <MarkerLogDetails
                handleCardClick={handleCardClick}
                markerLog={log}
                key={log.key}
              />
            );
          })}
          {remainingMarkers?.map((marker) => {
            return <MarkerLogDetails markerDetail={marker} key={marker.key} />;
          })}
        </StyledGrid>
      </Col>
    </ActiveRoutesContainer>
  );
};

export default RouteDetails;

const MapContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  height: calc(100vh - 64px);
  flex: 3;
`;

const ActiveRoutesContainer = styled.div`
  padding: 16px;
`;
