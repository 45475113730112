import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Heading,
  Tbody,
  Td,
  Text,
  useToast,
  Wrap,
} from '@chakra-ui/react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useContext, useEffect, useState } from 'react';
import { FaPlusCircle } from 'react-icons/fa';
import { Redirect } from 'react-router';
import { ReactComponent as EditBlueIcon } from '../../../assets/svgs/Editblue.svg';
import EmptyShiftTemplateImage from '../../../assets/svgs/EmptyShiftTemplateImage.svg';
import { ServiceTypesContext } from '../../../contexts/service_types';
import {
  getShiftJobsStatus,
  getShiftsOfACompany,
  shiftDelete,
} from '../../../controllers/snowm_firebase';
import {
  findSortType,
  formatTime,
  getLowerCasedWord,
  getRepeatOrNotRepeat,
  sortByEntity,
} from '../../../helpers/misc';
import ActionsDrawer from '../../../reusableComponent/ActionsDrawer';
import {
  MainTable,
  EachHeader,
  MainTableRow,
} from '../../../reusableComponent/MainTable';
import ZeroStateComponent from '../../../reusableComponent/ZeroStateComponent';
import color from '../../../utils/color';
import AlertBox from '../../Common/alert_box';
import SearchBar from '../../Common/Search_Bar';
import Span from '../../Common/Span';
import { Toast } from '../../Common/Toast';
import Loader from '../../Generics/snowm_loader';
import { weekDays } from './ AddShiftTemplateFields';
import AddUpdateShift from './AddShiftTemplates';
import { ShiftTemplate } from './shiftTemplate';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const headers: EachHeader[] = [
  {
    name: 'Shift Name',
    sort: 'asc',
  },
  {
    name: 'Shift Type',
    sort: 'asc',
  },
  {
    name: 'Details',
  },
];
const ShiftTemplates = () => {
  const data = useContext(ServiceTypesContext);

  const toast = useToast();

  const [shifts, setShifts] = useState<ShiftTemplate[] | null>(null);
  const [allShifts, setAllShifts] = useState<ShiftTemplate[] | null>(null);
  const [openSnackBar, setSnackBar] = useState(false);
  const [openAlertBox, setOpenAlertBox] = useState(false);
  const [selectedShift, setSelectedShift] = useState<ShiftTemplate | null>(
    null
  );
  const [openShiftDialog, setOpenShiftDialog] = useState(false);
  const [selectedShiftTemplates, setSelectedShiftTemplates] = useState<
    Record<string, ShiftTemplate>
  >({});
  const [shiftTemplatesCount, setShiftTemplatesCount] = useState(0);
  const [openActionDrawer, setOpenActionDrawer] = useState(true);
  const [shiftTemplateHeader, setShiftTemplateHeader] = useState(headers);

  const subscribedServices = data?.subscribedServices;

  const shiftTemplatesData = Object.values(selectedShiftTemplates);

  useEffect(() => {
    setShiftTemplatesCount(shiftTemplatesData.length);
  }, [selectedShiftTemplates]);

  useEffect(() => {
    let isCancel = false;
    function _updateShifts(res) {
      if (!isCancel) {
        setAllShifts(res);
        // setShifts(res);
      }
    }

    getShiftsOfACompany(_updateShifts);

    return () => {
      isCancel = true;
    };
  }, []);

  useEffect(() => {
    const { sort } = findSortType(shiftTemplateHeader, 'Shift Name');
    allShifts?.sort((a, b) => sortByEntity(a.name, b.name, sort));
    setShifts(allShifts);
  }, [allShifts]);

  if (!subscribedServices || !shifts) return <Loader />;

  if (!subscribedServices?.shiftManagement) return <Redirect to="/home" />;

  function handleAddButton() {
    setOpenShiftDialog(true);
    setOpenActionDrawer(false);
  }

  function closeDialog() {
    setOpenShiftDialog(false);
    setOpenActionDrawer(true);
  }

  function closeAlertDialog() {
    setOpenAlertBox(false);
    setSelectedShift(null);
  }

  function closeSnackBar(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBar(false);
  }

  async function deleteShift() {
    setSelectedShiftTemplates({});

    try {
      await shiftDelete(selectedShift?.id);
      closeAlertDialog();
      renderDeleteToast();
    } catch (error) {
      console.error('@@error', error);
    }
  }
  function renderDeleteToast() {
    return toast({
      duration: 3000,
      isClosable: true,
      render: function CustomFile() {
        return (
          <Toast
            title="Success"
            status="success"
            description={renderDeletedItem()}
          />
        );
      },
    });
  }

  function renderDeletedItem() {
    return (
      <span>
        Shift Template&ensp;
        <span style={{ fontWeight: 'bold', fontSize: 18 }}>
          {selectedShift?.name.toString()}&ensp;
        </span>
        deleted successfully
      </span>
    );
  }

  async function handleMultipleDelete(shiftTemplate) {
    const activeJobs = await getShiftJobsStatus(shiftTemplate.key);

    if (activeJobs) {
      setSnackBar(true);
    } else {
      await shiftDelete(shiftTemplate?.key);

      closeAlertDialog();
    }
  }

  function handleEditPress(shift) {
    setSelectedShift(shift);
    setOpenShiftDialog(true);
    setOpenActionDrawer(false);
  }

  const renderShifts = () => {
    if (!shifts?.length) {
      return (
        <ZeroStateComponent
          title="Manage your shift templates here"
          header="Shift Template"
          subTitle="Click + add shift template button on the right top to create your first Shift Template"
          image={EmptyShiftTemplateImage}
        />
      );
    }

    const selectAllShiftTemplates = (e) => {
      if (e.target.checked) {
        const checkedShiftTemplate = shifts?.reduce(
          (shiftTemplates: Record<string, ShiftTemplate>, shiftTemplate) => {
            if (shiftTemplate.id) {
              shiftTemplates[shiftTemplate.id] = shiftTemplate;
            }
            return shiftTemplates;
          },
          {}
        );
        setSelectedShiftTemplates({ ...checkedShiftTemplate });
      } else {
        setSelectedShiftTemplates({});
      }
    };
    const onSelectedShiftTemplate = (selectedShiftTemplate: ShiftTemplate) => {
      const { id } = { ...selectedShiftTemplate };
      if (id) {
        if (selectedShiftTemplates[id]) {
          const { [id]: value, ...remainingTask } = selectedShiftTemplates;
          setSelectedShiftTemplates({ ...remainingTask });
        } else {
          setSelectedShiftTemplates({
            ...selectedShiftTemplates,
            [id]: { ...selectedShiftTemplate },
          });
        }
      }
    };

    const handleSorting = (header, headerIndex) => {
      const { sort, name: fieldName } = header;
      const newSort = sort === 'desc' ? 'asc' : 'desc';

      const copyShiftTemplates = [...shifts];
      switch (fieldName) {
        case 'Shift Name':
          copyShiftTemplates?.sort((a, b) =>
            sortByEntity(a.name, b.name, newSort)
          );
          break;

        case 'Shift Type':
          copyShiftTemplates?.sort((a, b) =>
            sortByEntity(
              getRepeatOrNotRepeat(null, a.repetative) ?? '',
              getRepeatOrNotRepeat(null, b.repetative) ?? '',

              newSort
            )
          );
          break;

        default:
          break;
      }

      const updatedHeaders = [...shiftTemplateHeader];
      updatedHeaders.splice(headerIndex, 1, {
        name: fieldName,
        sort: newSort,
      });
      setShiftTemplateHeader(updatedHeaders);
      setShifts(copyShiftTemplates);
    };

    const showButtons = () => {
      const selectedShiftTemplatesToEdit = Object.values(
        selectedShiftTemplates
      )[0];
      return (
        <Wrap>
          <Button
            onClick={() => handleEditPress(selectedShiftTemplatesToEdit)}
            size="sm"
            colorScheme="blue"
            border="2px solid #006DB8"
            variant="outline"
            leftIcon={<EditBlueIcon />}
          >
            Edit
          </Button>
        </Wrap>
      );
    };

    return (
      <Box mb="50px">
        <MainTable
          headers={shiftTemplateHeader}
          checked={shiftTemplatesCount === shifts?.length}
          onSelectedEntities={selectAllShiftTemplates}
          handleSorting={handleSorting}
        >
          <Tbody
            bg="white"
            boxShadow="0px 12px 24px #ECEEF5"
            borderRadius="16px"
          >
            {shifts.map((shift) => (
              <STableRow
                key={shift.id}
                onSelectedShiftTemplate={() => onSelectedShiftTemplate(shift)}
                shift={shift}
                // setSelectedEntities={setSelectedShiftTemplates}
                selectedShiftTemplates={selectedShiftTemplates}
                // showButtons={showButtons}
                // openActionDrawer={openActionDrawer}
                // handleMultipleDelete={handleMultipleDelete}
              />
            ))}
          </Tbody>
        </MainTable>

        <ActionsDrawer
          setSelectedEntities={setSelectedShiftTemplates}
          selectedEntities={Object.values(selectedShiftTemplates).map((e) => ({
            key: e.id,
            name: e.name,
          }))}
          showButtons={showButtons}
          openActionDrawer={openActionDrawer}
          entity="Shift Templates"
          handleDeleteEntities={(shift) => handleMultipleDelete(shift)}
          showDeleteButton
          body={deleteShiftTemplatesList}
          deleteSubtitle="Remember, this action cannot be undone."
          additionalConfirmationText="This won't affect the schedule created previously using the template"
        />
      </Box>
    );
  };

  const deleteShiftTemplatesList = () => {
    return (
      <Box
        background="#F1F1F1 0% 0% no-repeat padding-box"
        border="0.5px solid #AEB0B5"
        borderRadius="4px"
        maxWidth="474px"
        maxHeight="220px"
        overflowY="auto"
      >
        {Object.values(selectedShiftTemplates ?? {})?.map((template, i) => {
          return (
            <>
              <Flex
                cursor="pointer"
                flexGrow={1}
                gridGap="sm"
                alignItems="center"
                padding="16px 8px 8px 16.5px"
              >
                <Text fontSize="14px" fontWeight="medium">
                  {template.name}
                </Text>
              </Flex>
              {i < Object.keys(selectedShiftTemplates).length - 1 && (
                <Divider />
              )}
            </>
          );
        })}
      </Box>
    );
  };

  // updating shift templates after searching
  function _updateShiftTemplates(contents, fromDatabase) {
    if (fromDatabase) {
      setAllShifts(contents);
    }
    setShifts(contents);
  }

  // handling search bar
  const handleSearchShiftTemplates = (e) => {
    const searchWord = e.target.value;
    const res = allShifts?.reduce((s: any, curr: any) => {
      const shiftsName = curr.name;
      const shiftName = getLowerCasedWord(shiftsName);
      const searchString = getLowerCasedWord(searchWord);
      if (shiftName.includes(searchString)) {
        return [...s, curr];
      }
      return s;
    }, []);

    _updateShiftTemplates(res ?? [], false);
  };

  return (
    <Box bg="background.500" overflow="auto" p="12">
      <Flex direction="column" gridGap={4}>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading as="h4">Shift Templates</Heading>

          <Button
            onClick={handleAddButton}
            colorScheme="blue"
            leftIcon={<FaPlusCircle />}
            paddingLeft="xsm"
          >
            <Text color="white" fontWeight="600">
              Add Shift Templates
            </Text>
          </Button>
        </Flex>

        <SearchBar
          title="Shift templates"
          LeftIcon={<SearchIcon />}
          onChange={handleSearchShiftTemplates}
          setSelectedEntities={setSelectedShiftTemplates}
          selectedEntities={selectedShiftTemplates}
        />
      </Flex>

      {renderShifts()}

      <AddUpdateShift
        open={openShiftDialog}
        handleNegativeAction={closeDialog}
        selectedShift={selectedShift}
        setSelectedShiftTemplates={setSelectedShiftTemplates}
      />
      <AlertBox
        open={openAlertBox}
        handlePositiveAction={deleteShift}
        handleNegativeAction={closeAlertDialog}
        title="Delete shift template"
        subtitle="Do you want to delete shift template?"
      />
      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={closeSnackBar}
      >
        <Alert onClose={closeSnackBar} severity="error">
          <Span color={color.white}>
            Currently there are active jobs on this schedule !
          </Span>
        </Alert>
      </Snackbar>
    </Box>
  );
};

const STableRow = ({
  shift,
  selectedShiftTemplates,
  onSelectedShiftTemplate,
}) => {
  const { id, name, startTime, endTime, weekdays, repetative } = shift;

  const [selectedWeekDays, setSelectedWeekDays] = useState<string[]>([]);

  useEffect(() => {
    const uniqueWeekDays = new Set(weekdays);
    const selectedDays = weekDays.filter((_, index) =>
      uniqueWeekDays.has(index)
    );
    setSelectedWeekDays(selectedDays);
  }, [weekdays]);

  return (
    <MainTableRow>
      <Td>
        <Flex gridGap="md">
          <Checkbox
            isChecked={!!selectedShiftTemplates[id ?? '']}
            onChange={() => onSelectedShiftTemplate()}
          />
          <Text>{name}</Text>
        </Flex>
      </Td>
      <Td>{getRepeatOrNotRepeat(null, repetative)}</Td>

      <Td>
        <Flex justifyContent="flex-end">
          {`${getRepeatOrNotRepeat(selectedWeekDays)} ${formatTime(
            startTime
          )} to ${formatTime(endTime)}`}
        </Flex>
      </Td>

      {/* <Td fontWeight="bold">{repetative ? 'Yes' : 'No'}</Td>
        <Td fontWeight="bold">{finishJobAtEnd ? 'Yes' : 'No'}</Td> */}
    </MainTableRow>
  );
};

export default ShiftTemplates;
