import React from 'react';

import styled from 'styled-components';
import { Card, Button } from '@material-ui/core';
import { Text, Box, Progress } from '@chakra-ui/react';

import { primaryDark } from '../../styles/snowm_theme';
import { ReactComponent as MarkerLogo } from '../../assets/svgs/StratosfyLogo.svg';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${primaryDark};
  justify-content: center;
  display: flex;
  opacity: 1;
`;

const StyledCard = styled(Card)`
  && {
    margin: auto;
    padding-top: 45px;
    padding-bottom: 28px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 12px 24px #005996;
  }
`;
const UnAuthenticated = ({ children, fromProfile }) => {
  if (fromProfile) {
    return <>{children}</>;
  }
  if (!children.props.companiesInDetail) {
    return <Progress hasStripe value={64} />;
  }
  return (
    <Overlay>
      <StyledCard>
        <Box display="flex" alignContent="center" flexDir="column">
          <MarkerLogo
            style={{
              alignSelf: 'center',
              width: '183px',
              marginBottom: '8px',
            }}
          />
          <Text
            size="20px"
            color="#17244F"
            opacity={1}
            textAlign="center"
            fontWeight="bold"
            pb={33}
          >
            Welcome to Stratosfy marker
          </Text>
        </Box>
        {children}
      </StyledCard>
    </Overlay>
  );
};

export default UnAuthenticated;

export const StyledOutlineButton = styled(Button)`
  && {
    :hover {
      border-width: 2px;
    }
    width: ${(props) => props.width ?? '100%'};
    color: ${(props) => props.theme.highlight};
    font-weight: bold;
    align-self: center;
    border-color: ${(props) => props.theme.primary};
    border-width: 2px;
    border-radius: 0px;
    text-transform: none;
    color: white;
    padding: 8px 25px;
    margin: 24px 0px;
    flex-basis: ${(props) => props.flexbasis ?? '315px'};
    background-image: linear-gradient(to right, #00afee, #006eb9);
  }
`;

export const passwordRange = {
  minLength: 7,
  maxLength: 25,
};
