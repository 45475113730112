/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: App/index.js
 *
 *  Description: Entry point of the Application.
 *
 *  Author: Nabin Kharal (nabin@brainants.com)
 *
 *  Date created: 4-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import React from 'react';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { createBrowserHistory } from 'history';
import { ChakraProvider } from '@chakra-ui/react';
import { Redirect, Router, Switch, Route } from 'react-router-dom';

import AdminDashboard from '../Private';

import ChooseCompany from '../Public/ChooseCompany';
import { getRedirectPath } from '../../helpers/misc';
import ChangePassword from '../Public/ChangePassword';
import { AuthContext } from '../../contexts/auth_context';
import CustomerDashboard from '../../customer/Components/Private';
import { LoginRoute, PrivateRoute } from '../../HOC/snowm_authenticator';
import Login from '../Public/login/Login';
import chakraTheme from '../../styles/chakraTheme';
import { initFirebase } from '../../controllers/snowm_firebase';

const history = createBrowserHistory();

const AuthConsumer = () => (
  <AuthContext.Consumer>
    {({ setAuthenticated, setCompanyKey, setUser }) => (
      <App
        setAuthenticated={setAuthenticated}
        setCompanyKey={setCompanyKey}
        setUser={setUser}
      />
    )}
  </AuthContext.Consumer>
);

const App = ({ setAuthenticated, setCompanyKey, setUser }) => {
  initFirebase(setAuthenticated, setCompanyKey, setUser);

  return (
    <ChakraProvider theme={chakraTheme}>
      <Router history={history}>
        <Switch>
          <Route path="/choose-company" component={ChooseCompany} />
          <LoginRoute path="/login" component={Login} />
          <LoginRoute path="/change-password" component={ChangePassword} />
          <LoginRoute path="/choose-company" component={ChooseCompany} />
          <PrivateRoute path="/admin" component={AdminDashboard} />
          <PrivateRoute path="/customer" component={CustomerDashboard} />
          <Redirect to={getRedirectPath()} />
        </Switch>
      </Router>
    </ChakraProvider>
  );
};

export default AuthConsumer;
