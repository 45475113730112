import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { Card } from '@material-ui/core';
import { useParams } from 'react-router';
import TimeLineDot from '@material-ui/lab/TimelineDot';
import TimeLineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import { Timeline, TimelineConnector, TimelineContent } from '@material-ui/lab';

import Span from '../../Common/Span';
import Loader from '../../Generics/snowm_loader';
import ServiceRequestItem from './ServiceRequestItem';
import { getFormattedDate } from '../../../helpers/date';
import { Row, Col, Title } from '../../../styles/snowm_styled';
import {
  getOrderProgress,
  getOrderById,
} from '../../../controllers/snowm_firebase';

const OrderDetails = () => {
  const [progress, setProgress] = useState();
  const [order, setOrder] = useState();

  const params = useParams();

  const { markerKey, orderKey } = params;

  useEffect(() => {
    if (markerKey && orderKey) {
      getOrderProgress(markerKey, orderKey).then((res) => setProgress(res));
      getOrderById(markerKey, orderKey).then((res) => setOrder(res));
    }
  }, [params]);

  if (!progress || !order) {
    return <Loader />;
  }

  return (
    <Container gap="12px">
      <Row>
        <Title>Work Order Detail</Title>
      </Row>
      <Grid>
        <ServiceRequestItem serviceRequest={order} />
      </Grid>
      <Card>
        <Timeline>
          {progress?.map((p, index) => {
            return (
              <TimeLineItem key={p.key}>
                <TimelineSeparator>
                  <TimeLineDot color="primary" />
                  {progress.length - 1 !== index && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <Row justify="space-between">
                    <Col>
                      <Span>{p.status}</Span>
                      <Span>{p.message}</Span>
                    </Col>
                    <Span>{getFormattedDate(p.date)}</Span>
                  </Row>
                </TimelineContent>
              </TimeLineItem>
            );
          })}
        </Timeline>
      </Card>
    </Container>
  );
};

export default OrderDetails;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`;

const Container = styled(Col)`
  margin: 8px;
`;
