/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_modal.jsx
 *
 *  Description: Modal Component for SnowM
 *
 *  Author: Nabin Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 11-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import MaterialModal from '@material-ui/core/Modal';
import React, { useState } from 'react';

const Modal = ({ children, open = false, onClose = () => null }) => {
  return (
    <MaterialModal open={open} onClose={onClose}>
      {children}
    </MaterialModal>
  );
};

export function useModal(state = false) {
  const [open, setOpen] = useState(state);
  return {
    props: {
      open,
      onClose: () => setOpen(false),
    },
    open: () => setOpen(true),
    close: () => setOpen(false),
  };
}

export default Modal;
