/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_loader.jsx
 *
 *  Description: Loader component.
 *
 *  Author: Nabin Kharal (nabin@brainants.com)
 *
 *  Date created: 4-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React from 'react';
import { Box, Image, CircularProgress, Center } from '@chakra-ui/react';
import loadingGif from '../../assets/images/loader_1.gif';

import color from '../../utils/color';

// functional component
const Loader = () => (
  // returns loader in the center of screen
  <Center mt="250px">
    <Image src={loadingGif} alt="laoding" />
  </Center>
);

export const Progress = ({ size, progressColor = color.white }) => (
  <CircularProgress indeterminate color={progressColor} />
);

export default Loader;
