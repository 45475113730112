import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { Grid, Card } from '@material-ui/core';
import ThermometerIcon from 'mdi-react/ThermometerIcon';
import ClockOutlineIcon from 'mdi-react/ClockOutlineIcon';
import Battery50BluetoothIcon from 'mdi-react/Battery50BluetoothIcon';
import OrderBoolAscendingVariantIcon from 'mdi-react/OrderBoolAscendingVariantIcon';

import Span from '../../Common/Span';
import color from '../../../utils/color';
import IssueReports from '../Jobs/IssueReports';
import Loader from '../../Generics/snowm_loader';
import MarkerDetailOfAJob from '../Jobs/marker_detail_job';
import ListTitleItem from '../../Generics/list_title_item';
import { getDayMonthYearTime } from '../../../helpers/date';
import { Col, Row, Title } from '../../../styles/snowm_styled';
import {
  getIssueReportsOfAMarker,
  getProviderByUid,
  getMarkerLogOfAMarker,
  getServiceInfo,
  getEventsForMarker,
} from '../../../../controllers/snowm_firebase';

const LogDetailOfAMarker = ({ match, history }) => {
  const [crew, setCrew] = useState();
  const [service, setService] = useState();
  const [markerLog, setMarkerLog] = useState();
  const [images, setImages] = useState([]);
  const [events, setEvents] = useState();
  const [markerIssueReport, setMarkerIssueReport] = useState();

  const { jobKey, markerKey } = match.params;

  useEffect(() => {
    function updateReports(res) {
      setMarkerIssueReport(res);
    }
    getIssueReportsOfAMarker(jobKey, markerKey, updateReports);
  }, []);

  useEffect(() => {
    getEventsForMarker(jobKey, markerKey).then((res) => {
      setEvents(res);
    });
  }, [match]);

  useEffect(() => {
    let isSubscribe = true;
    if (markerLog?.providerUid) {
      getProviderByUid(markerLog?.providerUid).then((res) => {
        if (isSubscribe) setCrew(res);
      });
    } else setCrew({});
    return () => {
      isSubscribe = false;
    };
  }, [markerLog]);

  useEffect(() => {
    let isSubscribe = true;
    if (markerLog?.serviceKey) {
      getServiceInfo(markerLog?.serviceKey).then((res) => {
        if (isSubscribe) setService(res);
      });
    }
    return () => {
      isSubscribe = false;
    };
  }, [markerLog]);

  useEffect(() => {
    let imageArray = [];
    imageArray = [...(markerLog?.images ?? [])];
    if (markerLog?.image) {
      imageArray = [...imageArray, markerLog?.image];
    }
    setImages(imageArray);
  }, [markerLog]);

  useEffect(() => {
    let isSubscribe = true;
    function _updateLog(res) {
      if (res?.length > 0) {
        if (isSubscribe) setMarkerLog(res[0]);
      }
    }
    getMarkerLogOfAMarker(jobKey, markerKey, _updateLog);

    return () => {
      isSubscribe = false;
    };
  }, [markerIssueReport]);

  if (!markerIssueReport || !crew || !markerLog || !service) return <Loader />;

  if (Object.values(markerLog).length === 0) {
    history.push('/admin/routes');
  }

  function getBgColor(eventType) {
    switch (eventType) {
      case 'entered':
        return color.green;
      case 'detected':
        return color.yellow;
      default:
        return color.red;
    }
  }

  const Events = () => {
    if (events?.length === 0) return null;
    return (
      <>
        <Row>
          <Title>Events</Title>
        </Row>
        <Grid container spacing={3}>
          {events?.map((event) => {
            return (
              <Grid item xs={12} md={6} lg={4} key={event.id || event.key}>
                <StyledCard>
                  <ListTitleItem
                    Icon={OrderBoolAscendingVariantIcon}
                    title="Event Type"
                    content={event?.eventType?.toUpperCase()}
                    bgColor={getBgColor(event?.eventType)}
                  />
                  <ListTitleItem
                    Icon={ClockOutlineIcon}
                    title="Event Time"
                    content={getDayMonthYearTime(event?.timestamp)}
                  />

                  {event?.eventType === 'detected' && (
                    <>
                      <ListTitleItem
                        Icon={ThermometerIcon}
                        title="Temperature"
                        content={`${event?.temperature}C`}
                      />
                      <ListTitleItem
                        Icon={Battery50BluetoothIcon}
                        title="Battery Percentage"
                        content={`${event?.battery}%`}
                      />
                    </>
                  )}
                </StyledCard>
              </Grid>
            );
          })}
        </Grid>
      </>
    );
  };

  return (
    <StyledContainer>
      <StyledSpan size="24px" weight="bold">
        Marker Log Detail
      </StyledSpan>
      <MarkerDetailOfAJob
        markerKey={markerKey}
        markerLog={markerLog}
        service={service}
        markerLogDetailPage
      />

      <StyledRow>
        <MarkerLogImages images={images} />
      </StyledRow>

      <Events />

      <StyledCol>
        <Title>Issue Reports</Title>
        <Grid container spacing={2}>
          {markerIssueReport?.length > 0 ? (
            <>
              {markerIssueReport?.map((report) => (
                <IssueReports
                  key={report?.key}
                  crew={crew?.name}
                  report={report}
                />
              ))}
            </>
          ) : (
            <Row justify="center">
              <Span>No Issue Reports</Span>
            </Row>
          )}
        </Grid>
      </StyledCol>
    </StyledContainer>
  );
};

const MarkerLogImages = ({ images }) => {
  if (images?.length > 0) {
    return images?.map((image) => (
      <StyledAnchor
        href={image}
        rel="noopener noreferrer"
        target="_blank"
        key={image}
      >
        <StyledImg height={300} width={300} alt="Log area" src={image} />
      </StyledAnchor>
    ));
  }
  return null;
};

export default LogDetailOfAMarker;

const StyledContainer = styled.div`
  margin: 24px;
`;

const StyledCol = styled(Col)`
  margin-top: 12px;
`;

const StyledRow = styled(Row)`
  margin-top: 12px;
`;

const StyledImg = styled.img`
  object-fit: cover;
`;

const StyledAnchor = styled.a`
  margin-left: 4px;
`;

const StyledSpan = styled(Span)`
  && {
    margin-left: 4px;
  }
`;

const StyledCard = styled(Card)`
  && {
    padding: 12px;
    height: 85%;
  }
`;
