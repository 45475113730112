import { useReducer, useState, useEffect } from 'react';

import { Button, Flex, Stack, Switch, Text, useToast } from '@chakra-ui/react';
import styled from 'styled-components';
import { addOrEditShift } from '../../../controllers/snowm_firebase';
import { CustomInput } from '../../../reusableComponent/CustomInput';
import { getTime, getTimeInEpoch } from '../../../helpers/date';
import { SideDrawer } from '../../../reusableComponent/SideDrawer';
import { Col } from '../../../styles/snowm_styled';
import color from '../../../utils/color';
import Span from '../../Common/Span';
import { Toast } from '../../Common/Toast';
import {
  AddShiftTemplateFields,
  weekDay,
  weekDays,
} from './ AddShiftTemplateFields';

const AddUpdateShift = ({
  open,
  handleNegativeAction,
  selectedShift,
  setSelectedShiftTemplates,
}) => {
  const initialShiftData = {
    id: null,
    name: '',
    startTime: null,
    endTime: null,
    exceptionMinutes: null,
    repetative: false,
    finishJobAtEnd: false,
    raiseException: true,
    companyKey: null,
    active: false,
    endsOn: getTimeInEpoch(),
    weekdays: [],
  };
  const toast = useToast();

  // const daysOfWeek = Object.keys(weekDays);

  const [selectedWeekDays, setSelectedWeekDays] = useState([]);

  const stateVariables = [
    'name',
    'startTime',
    'endTime',
    'repetative',
    'finishJobAtEnd',
    'raiseException',
    'exceptionMinutes',
    'endsOn',
    'weekdays',
  ];

  function reducer(state, action) {
    if (stateVariables.includes(action.type)) {
      return { ...state, [action.type]: action.payload };
    }
    switch (action.type) {
      case 'all':
        return { ...action.payload };
      case 'reset':
        return initialShiftData;
      default:
        throw new Error();
    }
  }

  const [shiftData, dispatch] = useReducer(reducer, initialShiftData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedShift) {
      dispatch({ type: 'all', payload: { ...selectedShift } });
      const selectedDays = selectedShift.weekdays?.reduce((acc, day) => {
        acc.push(day);
        return acc;
      }, []);

      setSelectedWeekDays(selectedDays ?? []);
    } else {
      dispatch({ type: 'reset' });
    }
  }, [selectedShift]);

  function handleDataChange(data, type) {
    if (type === 'raiseException' && !data) {
      dispatch({ type: 'exceptionMinutes', payload: null });
    }
    if (type === 'repetative' && data === false) {
      setSelectedWeekDays([]);
      dispatch({ type: 'weekdays', payload: [] });
    }
    dispatch({ type, payload: data });
  }

  const handleEndOnAfterCancel = () => {
    dispatch({ type: 'endsOn', payload: null });
  };

  async function addDetails(e) {
    if (selectedShift) {
      setSelectedShiftTemplates({});
    }
    e.preventDefault();
    if (!shiftData.startTime || !shiftData.endTime) {
      alert('Times should be specified.');
      return;
    }

    // const daysSelected = new Set(selectedWeekDays);

    // const arrayOfSelectedWeekDays = daysOfWeek?.reduce(
    //   (arrayOfIndex, day, index) => {
    //     if (daysSelected.has(day)) arrayOfIndex.push(index);
    //     return arrayOfIndex;
    //   },
    //   []
    // );

    setLoading(true);

    const startTime = getTime(shiftData.startTime);
    const endTime = getTime(shiftData.endTime);
    try {
      await addOrEditShift({
        ...shiftData,
        startTime,
        endTime,
        weekdays: selectedWeekDays,
      });
      dispatch({ type: 'reset' });
      setSelectedWeekDays([]);
      if (selectedShift === null) {
        handleToast(shiftData.name, 'created');
      } else {
        handleToast(selectedShift.name, 'edited');
      }
    } catch (error) {
      console.error('@@error', error);
    }
    handleNegativeAction();
    setLoading(false);
  }
  function handleToast(name, actionName) {
    return toast({
      duration: 3000,
      isClosable: true,
      render: function CustomFile() {
        return (
          <Toast
            title="Success"
            status="success"
            description={renderEditToastItem(name, actionName)}
          />
        );
      },
    });
  }
  function renderEditToastItem(name, actionName) {
    return (
      <span>
        Shift Template&ensp;
        <span style={{ fontWeight: 'bold', fontSize: 18 }}>
          {name.toString()}&ensp;
        </span>
        {actionName} successfully
      </span>
    );
  }

  function handleMinuteChange(value, type) {
    const numberValue = parseInt(value, 10);
    handleDataChange(numberValue, type);
  }

  const getErrorMessage = () => {
    if (shiftData.exceptionMinutes > 15) {
      return (
        <Span color={color.red} size="12px">
          Exception duration can be maximum of 15 minutes
        </Span>
      );
    }
    return '';
  };

  const exceptionDuration = () => {
    if (!shiftData.raiseException) return null;

    return (
      <CustomInput
        isRequired
        placeholder="Exception Duration"
        type="number"
        inputProps={{ max: 15, min: 1 }}
        onChange={(e) => handleMinuteChange(e.target.value, 'exceptionMinutes')}
        value={shiftData.exceptionMinutes}
        error={shiftData.exceptionMinutes > 15}
        helperText={getErrorMessage()}
      />
    );
  };

  const finishJobsAtEnd = () => {
    return (
      <CustomSwitch
        checked={shiftData.finishJobAtEnd}
        onChange={handleDataChange}
        color="primary"
        label="Finish jobs at end"
        type="finishJobAtEnd"
      />
    );
  };

  const handleWeekDays = (selWeekDays) => {
    const selecteDays = selWeekDays.reduce((days, day) => {
      days.push(day.value);
      return days;
    }, []);
    setSelectedWeekDays([selecteDays]);
  };

  const closeAddDrawer = () => {
    dispatch({ type: 'reset' });
    handleNegativeAction();
  };

  return (
    <SideDrawer
      title={selectedShift ? 'Edit Shift Template' : 'Add Shift Template'}
      open={open}
      negativeAction={closeAddDrawer}
      handlePositiveAction={addDetails}
      loading={loading}
      edit={selectedShift}
    >
      <form onSubmit={addDetails}>
        <Stack gridGap="md">
          <AddShiftTemplateFields
            shiftData={shiftData}
            handleWeekDays={handleWeekDays}
            handleDataChange={handleDataChange}
            weekDay={weekDay}
            selectedWeekDays={selectedWeekDays}
            // onHandleRepeatDays={onHandleRepeatDays}
            setSelectedWeekDays={setSelectedWeekDays}
            handleEndOnAfterCancel={handleEndOnAfterCancel}
            isEdit={!!selectedShift}
          />

          {/* <CustomSwitch
            checked={shiftData.raiseException}
            onChange={handleDataChange}
            color="primary"
            label="Raise Exception if late"
            type="raiseException"
          /> */}

          {/* {exceptionDuration()} */}
          {/* {finishJobsAtEnd()} */}

          <Flex justify="flex-end" gridGap="md">
            <Button
              onClick={closeAddDrawer}
              variant="outline"
              colorScheme="blue"
              isDisabled={loading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              isLoading={loading}
              colorScheme="blue"
              background="#006DB8 0% 0%"
              boxShadow=" 0px 3px 6px #006DB85C"
              borderRadius="5px"
            >
              {selectedShift ? 'Edit' : 'Add'}
            </Button>
          </Flex>
        </Stack>
      </form>
    </SideDrawer>
  );
};

export default AddUpdateShift;

const CustomSwitch = ({ checked, onChange, type, label }) => {
  return (
    <Flex justify="space-between">
      <Text>{label}</Text>
      <Switch
        checked={checked}
        onChange={(e) => onChange(e.target.checked, type)}
        color="primary"
      />
    </Flex>
  );
};

const ListOfCheckBoxes = styled(Col)`
  margin-left: 16px;
`;
