/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: /snowm_validator.js
 *
 *  Description: List of all the validator functions required within the application
 *
 *  Author: Nabin Kharal (nabin@brainants.com)
 *
 *  Date created: 4-july-2019
 *
 *
 *********************************************************************************
 * No import statements
 */

/*
 *********************************************************************************
 * @brief  (ValidateEmail)    checks if the email is validated
 * @param{type : string } (email)
 *
 * @returns bool
 *********************************************************************************
 */

export const ValidateEmail = (email) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
    email
  );
};

/*
 *********************************************************************************
 * @brief  (ValidatePhone)    checks if the phone number is validated
 * @param{type : string } (phone)
 *
 * @returns bool
 *********************************************************************************
 */

export const ValidatePhone = (phone) => {
  return /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(phone);
};
