import { CircularProgress, Flex } from '@chakra-ui/react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { CustomInput } from '../../../reusableComponent/CustomInput';
import { CustomSelect } from '../../../reusableComponent/shiftSchedule/custom_select';
import { Col, Row } from '../../../styles/snowm_styled';
import color from '../../../utils/color';
import Span from '../../Common/Span';
import { InvisibleMenuItem } from '../../Common/styled';

const TaskListSelect = ({
  serviceFeatures,
  handleSelectedActivities,
  marker,
  atJob,
  handleChange,
  defaultValue,
  setArrayOfActivities,
  arrayOfActivities,
  activities,
  setActivities,
}) => {
  const [selectedActivities, setSelectedActivities] = useState<any>(null);
  const [selectedCategory, setSelectedCategory] = useState<any>(null);

  const [selectedActivityIds, setSelectedActivityIds] = useState<any>([]);

  const [expanded, setExpanded] = useState<any>();
  const [selectedCategories, setSelectedCategories] = useState<any>([]);
  const [isSelectAll, setIsSelectAll] = useState<any>(null);

  useEffect(() => {
    handleSelectedActivities(selectedActivities, selectedCategory);

    handleChange(selectedActivities, selectedCategory);

    const ids = selectedActivities?.map((activity) => activity.id);
    setSelectedActivityIds(ids);
  }, [selectedActivities]);

  useEffect(() => {
    setSelectedCategory(marker?.activityType ?? 'none');
    setSelectedActivities(marker?.activities ?? null);
  }, [marker]);

  const handleActivitiesChange = (event) => () => {
    setSelectedActivities([]);
    setSelectedCategory(event.target.value);
  };

  const handleCheckboxChange = (event) => () => {
    let activitiesArray = [];
    if (!event.target.checked) {
      activitiesArray = selectedActivities.filter(
        (eachActivity) => eachActivity.id !== event.target.id
      );
      setSelectedActivities([...activitiesArray]);
      return;
    }

    const loopingActivities = atJob
      ? arrayOfActivities
      : Object.values(activities[selectedCategory]);

    activitiesArray = loopingActivities.filter(
      (eachActivity) => eachActivity.id === event.target.id
    );

    setSelectedActivities((prevData) => [
      ...(prevData ?? []),
      ...activitiesArray,
    ]);
  };

  function handleExpand(e, category) {
    e.stopPropagation();
    setSelectedCategory(category);
    setSelectedCategories([...selectedCategories, category]);
    setExpanded({ ...expanded, [category]: !expanded?.[category] });
  }

  function getActivityName(activity) {
    if (!activity.mandatory) {
      return activity.name;
    }

    return (
      <Col>
        <Span>
          {`${activity.name}`}
          <Span color={color.red}>*</Span>
        </Span>
        <Span size="12px" color={color.grey}>
          Mandatory
        </Span>
      </Col>
    );
  }

  const activitiesList = (categorySelected) => {
    const category = categorySelected ?? selectedCategory;

    const categoryActivities: any = Object.values(activities[category] ?? {});

    return (
      <FormGroup>
        {categoryActivities.map((activity: any) => (
          <FormControlLabel
            key={activity.id}
            control={
              <Checkbox
                color="primary"
                name={activity.name}
                checked={selectedActivityIds?.includes(activity.id) ?? false}
                onChange={handleCheckboxChange}
                id={activity.id}
              />
            }
            label={getActivityName(activity)}
          />
        ))}
      </FormGroup>
    );
  };

  if (!activities) {
    return (
      <Flex justify="center">
        <CircularProgress isIndeterminate color="primary.500" />
      </Flex>
    );
  }

  if (activities?.length === 0) {
    return (
      <Row justify-content="center">
        <Span>No activities.</Span>
      </Row>
    );
  }

  if (atJob && !serviceFeatures?.activitiesOnJob) {
    return null;
  }

  function handleSelectAll(category) {
    setIsSelectAll({ ...isSelectAll, [category]: true });
    const activitiesOfACategory = arrayOfActivities?.filter(
      (activity) => activity.category === category
    );

    setSelectedActivities([...activitiesOfACategory]);
    setSelectedActivities([
      ...(selectedActivities ?? []),
      ...activitiesOfACategory,
    ]);
  }

  function handleTaskChange(category) {
    const activitiesOfACategory = arrayOfActivities?.filter(
      (activity) => activity.category === category
    );

    return activitiesOfACategory;
  }

  function handleCancelSelectAll(category) {
    setIsSelectAll({ ...isSelectAll, [category]: false });
    const newActivities = selectedActivities?.filter(
      (activity) => activity.category !== category
    );
    setSelectedActivities(newActivities);
  }

  const taskListOptions =
    activities &&
    Object.keys(activities).map((t: any) => {
      return { label: t, value: t };
    });

  if (atJob) {
    return (
      <CustomInput
        type="children"
        placeholder=" "
        name="select"
        value={selectedActivities}
        onChange={() => null}
      >
        <CustomSelect
          onChange={(data) => {
            let selectedTasks: any = [];
            data.forEach((d) => {
              const resp = handleTaskChange(d.value);
              selectedTasks = [...selectedTasks, ...resp];
            });
            setSelectedActivities(selectedTasks);
          }}
          value={defaultValue}
          variant="unstyled"
          options={taskListOptions}
          placeholder="Select Task-List"
          // value={selectedTasks}
          //   TODO: value is needed for edit
          isMulti
        />
      </CustomInput>
    );
  }

  if (serviceFeatures?.activitiesOnJob) {
    return null;
  }

  if (!Object.keys(activities)?.length) {
    return null;
  }

  return (
    <>
      <TextField
        select
        defaultValue="none"
        value={selectedCategory ?? 'none'}
        onChange={handleActivitiesChange}
        variant="outlined"
      >
        <InvisibleMenuItem value="none">Activities Category</InvisibleMenuItem>
        {Object.keys(activities).map((category) => (
          <MenuItem key={category} value={category}>
            {category}
          </MenuItem>
        ))}
      </TextField>

      {activities[selectedCategory] &&
        Object.values(activities[selectedCategory]).length > 0 && (
          <>{activitiesList(null)}</>
        )}
    </>
  );
};

export default TaskListSelect;
