import { Flex, Text, Box } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { SeeMoreInfo } from './EntityCount';

interface Props {
  text: string | undefined;
  onPress: () => void;
  count?: number | string | undefined;
  justifyContent?: string | undefined;
  background?: string | undefined;
  color?: string | undefined;
  icon?: ReactNode;
  children?: ReactNode;
}

export const RowEntityCount = (props: Props) => {
  const {
    text,
    onPress,
    count,
    justifyContent,
    background,
    icon,
    color,
    children,
  } = props;
  const fontColor = color ?? 'black';

  return (
    <Box
      background={background ?? 'background.800'}
      borderRadius="12px"
      color={fontColor}
      padding="2px 12px"
    >
      <Flex direction="column">
        <Flex justifyContent="space-between" alignItems="flex-start">
          <Flex direction="column">
            {icon}
            {text ? (
              <Text
                marginTop="7px"
                fontWeight="bold"
                fontSize="14px"
                color={fontColor}
              >
                {text}
              </Text>
            ) : null}
          </Flex>
          <Text
            fontWeight="bold"
            marginTop="4px"
            fontSize="23px"
            color={fontColor}
          >
            {count}
          </Text>
        </Flex>
        <SeeMoreInfo
          color={fontColor}
          onPress={onPress}
          justify={justifyContent ?? 'flex-end'}
        />
        {children}
      </Flex>
    </Box>
  );
};
