import {
  Box,
  Button,
  Flex,
  Heading,
  Tbody,
  Text,
  Wrap,
} from '@chakra-ui/react';
import { CheckCircleOutline } from '@material-ui/icons';
import Close from '@material-ui/icons/Close';
import React, { useContext, useEffect, useState } from 'react';
import { FaPlusCircle } from 'react-icons/fa';
import { Redirect } from 'react-router';
import styled from 'styled-components';
import { LocalizationContext } from '../../../contexts/localization_context';
import { ServiceTypesContext } from '../../../contexts/service_types';
import {
  getServiceInfo,
  getServicePointById,
  getServiceRequestOfACompany,
  updateWorkOrder,
} from '../../../controllers/snowm_firebase';
import { MainTable } from '../../../reusableComponent/MainTable';
import JobModal from '../../../reusableComponent/shiftSchedule/snowm_add_job';
import { SideDrawer } from '../../../reusableComponent/SideDrawer';
import { Row } from '../../../styles/snowm_styled';
import AlertBox from '../../Common/alert_box';
import Span from '../../Common/Span';
import Loader from '../../Generics/snowm_loader';
import ScheduleConsumer from '../schedule/Schedule';
import ServiceRequestItem from './ServiceRequestItem';

const headers = [
  { name: 'Name', sort: 'asc', checkBox: true },
  { name: 'Requested Date', sort: 'asc' },
  { name: 'Request Note', sort: 'asc' },
  { name: 'Customer', sort: 'asc' },
  { name: 'Status', sort: 'asc' },
  { name: 'Actions', sort: 'asc' },
];

const ServiceRequests = () => {
  const workOrdersStatuses = [
    'All',
    'Completed',
    'Dispatched',
    'Rejected',
    'Requested',
    'Working',
  ];

  const { strings } = useContext(LocalizationContext);

  const { ASSIGNED_JOBS, SHIFT_JOBS } = strings.jobType ?? '';

  const data = useContext(ServiceTypesContext);
  const subscribedServices = data?.subscribedServices;
  const [openAddDialog, setOpenAddDialog] = useState(false);

  const [serviceRequests, setServiceRequests] = useState();
  const [allServiceRequests, setAllServiceRequests] = useState(null);
  const [openJobDialog, setOpenJobDialog] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [openAlertBox, setOpenAlertBox] = useState(false);
  const [selectedFilterStatus, setSelectedFilterStatus] = useState('All');
  const [selectedWorkCount, setSelectedWorkCount] = useState(0);
  const [selectedWork, setSelectedWork] = useState({});
  const [openActionDrawer, setOpenActionDrawer] = useState(true);
  const selectedWorkData = Object.values(selectedWork);

  useEffect(() => {
    let isCancel = false;
    getServiceRequestOfACompany().then((res) => {
      if (!isCancel) {
        setAllServiceRequests(res);
        setServiceRequests(res);
      }
    });

    return () => {
      isCancel = true;
    };
  }, []);

  useEffect(() => {
    if (selectedOrder?.markerKey) {
      getServicePointById(selectedOrder.markerKey).then((res) => {
        setSelectedMarker(res);
      });
    }
  }, [selectedOrder]);
  useEffect(() => {
    setSelectedWorkCount(selectedWorkData.length);
  }, [selectedWork]);

  useEffect(() => {
    if (selectedMarker?.serviceKey) {
      getServiceInfo(selectedMarker.serviceKey).then((res) => {
        setSelectedService({ label: res?.name, value: res });
      });
    }
  }, [selectedMarker]);

  if (!serviceRequests || !subscribedServices) {
    return <Loader />;
  }

  if (!subscribedServices?.workOrders) {
    return <Redirect to="/admin" />;
  }

  function handleStartButton(order) {
    setSelectedOrder(order);
    setOpenJobDialog(true);
  }

  function handleJobModalClose() {
    setOpenJobDialog(false);
    setSelectedOrder(null);
    setSelectedService(null);
    setSelectedMarker(null);
  }

  async function handleJobCreation(jobRes) {
    const { id, markerKey } = selectedOrder;
    const newData = {
      status: 'DISPATCHED',
      jobKey: jobRes?.key ?? null,
    };
    try {
      await updateWorkOrder(markerKey, id, newData);
      const updatedWorkOrders = serviceRequests?.reduce((acc, curr) => {
        if (curr.id === id) {
          return [...acc, { ...curr, status: 'DISPATCHED' }];
        }
        return [...acc, curr];
      }, []);

      setServiceRequests(updatedWorkOrders);
      handleJobModalClose();
    } catch (error) {
      console.error({ error });
    }
  }

  function closeAlertDialog() {
    setOpenAlertBox(false);
  }

  async function rejectWorkOrder() {
    setSelectedWork({});
    const { id, markerKey } = selectedOrder;
    try {
      await updateWorkOrder(markerKey, id, 'REJECTED');
      const updatedWorkOrders = serviceRequests?.reduce((acc, curr) => {
        if (curr.id !== id) {
          return [...acc, curr];
        }
        return [...acc, { ...curr, status: 'REJECTED' }];
      }, []);
      setServiceRequests(updatedWorkOrders);
      setOpenAlertBox(false);
    } catch (error) {
      console.error({ error });
    }
  }

  function handleRejectButton(selectedWorkOrder) {
    setSelectedOrder(selectedWorkOrder);
    setOpenAlertBox(true);
  }

  function handleFilterChange(event) {
    setSelectedFilterStatus(event.target.value);
    const selectedFilter = event.target.value.toUpperCase();
    if (selectedFilter === 'ALL') {
      setServiceRequests(allServiceRequests);
    } else {
      const filteredServiceRequests = allServiceRequests?.filter(
        (request) => request.status === selectedFilter
      );
      setServiceRequests(filteredServiceRequests);
    }
  }

  const renderServiceRequests = () => {
    if (!serviceRequests?.length) {
      return (
        <Row justify="center">
          <Span weight="bold">No service requests.</Span>
        </Row>
      );
    }
    return (
      <Box>
        {serviceRequests?.map((request) => {
          return (
            <ServiceRequestItem
              key={request.id}
              serviceRequest={request}
              inWorkOrdersPage
              handleStartButton={() => handleStartButton(request)}
              handleRejectButton={() => handleRejectButton(request)}
            />
          );
        })}
      </Box>
    );
  };
  // return (
  //   <MainTableRow>
  //     <Td>
  //       <Flex gridGap="md">
  //         <Checkbox />
  //         <Text>{service.serialNumber}</Text>
  //       </Flex>
  //     </Td>
  //     <Td>{service.status}</Td>
  //     <Td>{getYearMonthDay()}</Td>
  //     <Td>-</Td>
  //     <Td>{service?.type ?? 'Sensor'}</Td>
  //   </MainTableRow>
  // );
  const selectedWorkToEdit = selectedWorkData[0];
  function selectAllWork(e) {
    if (e.target.checked) {
      setOpenActionDrawer(true);
      const checkedWork = serviceRequests?.reduce((services, service) => {
        if (service.id) {
          services[service.id] = service;
        }
        return services;
      }, {});
      setSelectedWork({ ...checkedWork });
    } else {
      setSelectedWork({});
    }
  }
  const selectWork = (service) => {
    setOpenActionDrawer(true);

    const { id } = service;
    if (selectedWork[id]) {
      const { [id]: value, ...remainingWork } = selectedWork;
      setSelectedWork({ ...remainingWork });
    } else {
      setSelectedWork({
        ...selectedWork,
        [id]: { ...service },
      });
    }
  };
  function showButtons(serviceRequest) {
    if (serviceRequest?.status !== 'REQUESTED') {
      return null;
    }

    return (
      <Wrap>
        <Button
          size="sm"
          colorScheme="blue"
          border="2px solid #006DB8"
          leftIcon={<CheckCircleOutline />}
          variant="outline"
          // isLoading={deleting}
          onClick={() => {
            handleStartButton(serviceRequest);
          }}
        >
          Start the order
        </Button>
        <Button
          size="sm"
          colorScheme="red"
          border="2px solid #006DB8"
          leftIcon={<Close />}
          variant="outline"
          // isLoading={deleting}
          onClick={(e) => {
            handleRejectButton(serviceRequest);
          }}
        >
          Reject the order
        </Button>
      </Wrap>
    );
  }
  function handleCancelButton() {
    setOpenAddDialog(false);
    setOpenActionDrawer(true);
  }
  function handleAddPress() {
    setOpenActionDrawer(false);
    setOpenAddDialog(true);
  }

  return (
    <>
      <Box bg="background.500" overflow="auto" height="100%" p="12">
        <Flex justifyContent="space-between" alignItems="center">
          <Heading as="h4"> Work Orders</Heading>
          <Button
            onClick={handleAddPress}
            colorScheme="blue"
            leftIcon={<FaPlusCircle />}
            paddingLeft="xsm"
          >
            <Text color="white">Add Work Orders</Text>
          </Button>
        </Flex>

        {serviceRequests.length ? (
          <Box mt="10">
            <MainTable
              headers={headers}
              checked={selectedWorkCount === serviceRequests.length}
              onSelectedEntities={(e) => selectAllWork(e)}
            >
              <Tbody
                bg="white"
                boxShadow="0px 12px 24px #ECEEF5"
                borderRadius="16px"
              >
                {serviceRequests.map((request) => {
                  return (
                    <ServiceRequestItem
                      selectWork={() => selectWork(request)}
                      openActionDrawer={openActionDrawer}
                      setOpenActionDrawer={setOpenActionDrawer}
                      selectedWork={selectedWork}
                      setSelectedWork={setSelectedWork}
                      key={request.id}
                      serviceRequest={request}
                      inWorkOrdersPage
                      showButtons={() => showButtons(request)}
                      handleStartButton={() => handleStartButton(request)}
                      handleRejectButton={() => handleRejectButton(request)}
                    />
                  );
                })}
              </Tbody>
            </MainTable>
          </Box>
        ) : (
          <Row justify="center">
            <Span weight="bold">No service requests.</Span>
          </Row>
        )}
      </Box>
      {/* <Container>
        <Row justify="space-between">
          <Title>Work Orders</Title>
          <TextField
            select
            label="Filter"
            value={selectedFilterStatus}
            onChange={handleFilterChange}
          >
            {workOrdersStatuses.map((workStatus) => (
              <MenuItem key={workStatus} value={workStatus}>
                {workStatus}
              </MenuItem>
            ))}
          </TextField>
        </Row>
        {renderServiceRequests()}
      </Container> */}
      <JobModal
        open={openAddDialog}
        orderSelected
        serviceOfOrder={selectedService}
        markerSelected={selectedMarker}
        onJobCreation={handleJobCreation}
        onClose={handleCancelButton}
        title="Add Work Order"
      />
      <AlertBox
        open={openAlertBox}
        handleNegativeAction={closeAlertDialog}
        subtitle="Are you sure you want to reject this order?"
        title="Reject Order"
        handlePositiveAction={rejectWorkOrder}
        positiveText="Reject"
      />
    </>
  );
};

export default ServiceRequests;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 12px;
`;

const Container = styled.div`
  margin: 12px;
`;
