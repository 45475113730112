import axios from 'axios';

const deviceBaseUrl = 'https://api.genius.stratosfy.io/device';
const rangeDataUrl = `${deviceBaseUrl}/rangedata`;

const makeRequest = (url, method) => {
  const config = {
    method,
    url,
  };
  return axios(config);
};

export const getDeviceTemperature = ({ deviceId, dataSet, dateRange }) => {
  const url = `${rangeDataUrl}?deviceID=${deviceId}&dataSet=${dataSet}&startDate&endDate&dateRange=${dateRange}`;
  return makeRequest(url, 'GET').then((res) => res.data);
};

export default getDeviceTemperature;
