import React from 'react';
import styled from 'styled-components';
import { Avatar } from '@material-ui/core';
import CameraIcon from 'mdi-react/CameraIcon';

import DummyAvatar from '../../assets/images/bg.png';

const ProfilePic = ({ selectedImage, onPress }) => {
  return (
    <div style={{ position: 'relative' }}>
      <ProfilePicture
        alt="Profile Picture"
        src={selectedImage || DummyAvatar}
        style={{ marginVertical: 16 }}
        onClick={onPress}
      />
      <ProfilePictureWrapper>
        <CameraIcon color="#fff" />
      </ProfilePictureWrapper>
    </div>
  );
};

const ProfilePicture = styled(Avatar)`
  && {
    height: 120px;
    width: 120px;
  }
`;

const ProfilePictureWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  pointer-events: none;
  height: 120px;
  width: 120px;
  border-radius: 60px;
  background-color: black;
  opacity: 0.4;
  justify-content: center;
  align-items: center;
`;

export default ProfilePic;
