import { useRef, useEffect } from 'react';

import { InputGroup, InputLeftElement, Input } from '@chakra-ui/react';

const SearchBar = (props) => {
  const {
    title,
    onChange,
    value,
    LeftIcon,
    width = '40%',
    selectedEntities,
    fromAdministrator,
    setSelectedEntities,
  } = props;

  const searchRef = useRef(null);

  useEffect(() => {
    if (selectedEntities && searchRef.current) {
      searchRef.current.focus();
    }
  }, [selectedEntities]);

  const handleOnClickEvent = () => {
    if (fromAdministrator) return;
    if (selectedEntities instanceof Array) {
      setSelectedEntities([]);
    } else {
      setSelectedEntities({});
    }
  };

  return (
    <InputGroup>
      <InputLeftElement
        color="gray"
        pointerEvents="none"
        // eslint-disable-next-line react/no-children-prop
        children={LeftIcon}
      />
      <Input
        size="sm"
        width={width}
        value={value}
        height="40px"
        ref={searchRef}
        variant="filled"
        borderRadius="8px"
        bg="background.600"
        onChange={onChange}
        placeholder={`Search ${title}`}
        onClick={() => handleOnClickEvent()}
      />
    </InputGroup>
  );
};
export default SearchBar;
