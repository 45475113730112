import React, { ReactNode, useContext } from 'react';

import styled from 'styled-components';
import BackArrowIcon from 'mdi-react/ChevronRightIcon';
import { Flex, Text, Box } from '@chakra-ui/react';
import { LocalizationContext } from '../contexts/localization_context';

import { Row } from '../styles/snowm_styled';

interface RowCardProps {
  text: string | undefined;
  onPress: () => void;
  count?: number | string | undefined;
  background?: string | undefined;
  color?: string | undefined;
  customDescription?: ReactNode;
  countFont?: number | string | undefined;
}

export const EntityCount = (props: RowCardProps) => {
  const {
    text,
    onPress,
    count,
    background,
    customDescription,
    color,
    countFont,
  } = props;

  return (
    <Box
      background={background ?? 'background.800'}
      borderRadius="12px"
      color={color ?? 'black'}
      padding="12px"
      height="100%"
    >
      <Flex direction="column" justifyContent="space-around" height="100%">
        <Text
          fontWeight="bold"
          fontSize={countFont ?? '22px'}
          color={color ?? 'black'}
        >
          {count}
        </Text>
        <div>
          {text ? <Text fontWeight="bold">{text}</Text> : null}
          {customDescription && customDescription}
          <SeeMoreInfo
            onPress={onPress}
            justify="flex-start"
            color={color ?? 'black'}
          />
        </div>
      </Flex>
    </Box>
  );
};

interface SeeMoreContainerProps {
  justifyContent: string;
  marginbottom: string;
  align: string;
}

const SeeMoreContainer = styled(Row)`
  cursor: pointer;
  justify-content: ${(props: SeeMoreContainerProps) => props.justifyContent};
`;

export const SeeMoreInfo = ({ onPress, justify, color = 'black' }) => {
  const { strings } = useContext(LocalizationContext);

  const {
    home: { VIEW },
  } = strings;

  return (
    <SeeMoreContainer
      marginbottom="0"
      align="center"
      onClick={onPress}
      justifyContent={justify}
    >
      <Text size="12px" color="primary">
        View
      </Text>
      <BackArrowIcon color={color} size={20} />
    </SeeMoreContainer>
  );
};
