import { Grid, SimpleGrid, useToast } from '@chakra-ui/react';
import dayjs from 'dayjs';
import AccountGroupIcon from 'mdi-react/AccountGroupIcon';
import CalendarCheckIcon from 'mdi-react/CalendarCheckIcon';
import { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../../../contexts/auth_context';
import {
  getGeneratedReport,
  getProvidersOfCompany,
} from '../../../controllers/snowm_firebase';
import { getYearMonthDayFormat } from '../../../helpers/date';
import { DescriptionTitle } from '../../../reusableComponent/DescriptionTitle';
import { SideDrawer } from '../../../reusableComponent/SideDrawer';
import { ReportByType } from './snowm_reports';

interface DateInterface {
  janitor: null | number;
}

interface SelectedJanitor {
  uid: string;
  name: string;
}
export const JanitorReports = () => {
  const [reportType, setReportType] = useState<null | string>(null);
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState<SelectedJanitor>({
    name: '',
    uid: '',
  });
  const [startDate, setStartDate] = useState<DateInterface>({
    janitor: null,
  });
  const [endDate, setEndDate] = useState<DateInterface>({
    janitor: null,
  });
  const [pdfURL, setPdfURL] = useState('');
  const [crewLoading, setCrewLoading] = useState(false);
  const [selectImages, setSelectImages] = useState({ janitor: false });
  const [errorMessage, setErrorMessage] = useState({
    startDate: '',
    endDate: '',
    selectedValue: '',
  });

  const [showImage, setShowImage] = useState(false);
  const toast = useToast();
  const anchorRef = useRef<null | any>(null);

  useEffect(() => {
    if (pdfURL && anchorRef.current) {
      anchorRef.current.click();
    }
  }, [pdfURL]);

  const { userDetails } = useContext(AuthContext);

  useEffect(() => {
    let isSubscribe = true;
    getProvidersOfCompany(null, false, true).then((prov) => {
      if (isSubscribe) {
        const crews = prov?.map((crew) => ({
          label: crew?.name,
          value: crew,
        }));
        setProviders(crews);
      }
    });
    // getAllServiceRoutes().then((servRoutes) => {
    //   if (isSubscribe) setServiceRoutes(servRoutes);
    // });

    return () => {
      isSubscribe = false;
    };
  }, []);

  const handleChange = (e) => {
    e.stopPropagation();
    setSelectImages({ ...selectImages, [e.target.name]: e.target.checked });
  };

  const handleProviderChange = (selectedCrew) => {
    setSelectedProvider(selectedCrew?.value);
  };

  const handleStartDateChange = (date) => {
    setStartDate({ ...startDate, janitor: dayjs(date).valueOf() });
  };

  const handleEndDateChange = (date) => {
    setEndDate({ ...endDate, janitor: dayjs(date).valueOf() });
  };

  const handleCancelButton = () => {
    setReportType(null);
    setEndDate({ janitor: null });
    setStartDate({ janitor: null });
    setSelectedProvider({ name: '', uid: '' });
    setPdfURL('');
    setErrorMessage({
      startDate: '',
      endDate: '',
      selectedValue: '',
    });
  };

  const onProviderSubmit = async (type) => {
    if (!startDate.janitor || !endDate.janitor) {
      setErrorMessage({
        ...errorMessage,
        endDate: 'Dates shouldnot be empty.',
        startDate: 'Dates shouldnot be empty.',
      });
      return;
    }
    if (startDate.janitor > endDate.janitor) {
      setErrorMessage({
        ...errorMessage,
        startDate: 'Dates shouldnot be empty.',
      });

      return;
    }
    if (!selectedProvider?.name) {
      setErrorMessage({
        ...errorMessage,
        selectedValue: 'Please select janitor to export pdf',
      });
      setCrewLoading(false);
      return;
    }

    setCrewLoading(true);

    try {
      const response = await getGeneratedReport({
        convertTo: 'pdf',
        toDate: getYearMonthDayFormat(endDate.janitor),
        fromDate: getYearMonthDayFormat(startDate.janitor),
        key: { name: 'janitorKey', id: selectedProvider?.uid },
        showImage: selectImages.janitor,
        createReportName:
          type === 'janitor'
            ? 'reports-createJanitorReport'
            : 'reports-createJanitorsScheduleReport',
      });

      if (response?.data?.success) {
        setPdfURL(response?.data?.url?.[0]);
      }
      setCrewLoading(false);
    } catch (error) {
      setCrewLoading(false);
      toast({
        title: 'Error while creating report',
        duration: 3000,
      });
    }
  };

  const renderReportDrawerContent = () => {
    switch (reportType) {
      case 'Janitor':
        return (
          <ReportByType
            options={providers}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            setOptions={() => {}}
            onChange={handleProviderChange}
            selectMulti={false}
            type="janitor"
            selectedValue={selectedProvider ?? {}}
            selectTitle="Janitor"
            startDate={startDate}
            endDate={endDate}
            handleStartDateChange={handleStartDateChange}
            handleEndDateChange={handleEndDateChange}
            onSubmit={() => onProviderSubmit('janitor')}
            showImage
            handleChange={handleChange}
            selectImage={selectImages?.janitor}
            crewLoading={crewLoading}
            errorMessage={errorMessage}
          />
        );

      case 'Janitor Schedule':
        return (
          <ReportByType
            options={providers}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            setOptions={() => {}}
            onChange={handleProviderChange}
            selectMulti={false}
            type="janitor"
            selectedValue={selectedProvider ?? {}}
            selectTitle="Janitor"
            startDate={startDate}
            endDate={endDate}
            handleStartDateChange={handleStartDateChange}
            handleEndDateChange={handleEndDateChange}
            onSubmit={() => onProviderSubmit('janitorSchedule')}
            showImage={false}
            selectImage={selectImages?.janitor}
            handleChange={handleChange}
            crewLoading={crewLoading}
            errorMessage={errorMessage}
          />
        );

      default:
        return 'No report service available';
    }
  };
  return (
    <>
      <SimpleGrid columns={[1, null, 2]} gap={6}>
        <DescriptionTitle
          title="Janitor"
          icon={AccountGroupIcon}
          onClick={() => {
            setReportType('Janitor');
          }}
          subTitle="Janitor report with detailed timings and tasks performed. "
        />
        {/* Todo :Change Janitor Schedule Icon later */}
        <DescriptionTitle
          title="Janitor Schedule"
          icon={CalendarCheckIcon}
          onClick={() => {
            setReportType('Janitor Schedule');
          }}
          subTitle="Janitor report with detailed scheduled."
        />
      </SimpleGrid>

      <SideDrawer
        open={!!reportType}
        title={`${reportType} Report`}
        negativeAction={handleCancelButton}
      >
        {renderReportDrawerContent()}
      </SideDrawer>
      <a
        href={pdfURL}
        ref={anchorRef}
        target="_blank"
        rel="noreferrer"
        style={{ opacity: 0, height: 0, position: 'absolute' }}
      >
        Here
      </a>
    </>
  );
};
