const color = {
  red: '#f44336',
  grey: '#9d9595',
  blue: '#2196f3',
  green: '#1e6309',
  black: '#000000',
  white: '#ffffff',
  amber: '#ffbf00',
  yellow: '#ffeb3b',
  orange: '#ff9800',
  lightRed: '#ea3616',
  darkBlue: '#006eb9',
  snomwBlue: '#006DB8',
  lightGrey: '#cac5c5',
  greyLight: '#e0e0e0',
  lightBlack: '#6d6868',
  whiteSmoke: '#f5f5f5',
  detailColor: '#110759',
  greenAccent: '#69f0ae',
  dividerColor: '#fbf1f1',
  boxShadowColor: '#b8b8b8',
  gradientEndColor: '#006eb9',
  menuColor: '#8A91A6',
};

export default color;
