import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Routes from './snowm_Routes';
import RouteDetail from './snowm_route_detail';

const RoutesRoutes = () => (
  <Switch>
    <Route path="/admin/routes/add" exact component={() => <div>add</div>} />
    <Route
      path="/admin/routes/details/:routeKey"
      exact
      component={(props) => <RouteDetail viewOnly {...props} />}
    />
    <Route path="/admin/routes/" component={Routes} />
    <Redirect to="/admin/routes" />
  </Switch>
);

export default RoutesRoutes;
