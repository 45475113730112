import * as React from 'react';

import { Button } from '@chakra-ui/button';

interface ButtonProps {
  bgGradient?: string;
  isLoading?: boolean;
  onClick?: () => void;
  colorScheme?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  children: React.ReactNode;
  type?: 'submit' | 'button' | 'reset' | undefined;
}
export const CustomButton: React.FC<ButtonProps> = (props) => {
  const {
    type,
    onClick,
    children,
    isLoading,
    bgGradient,
    colorScheme,
    backgroundImage,
    backgroundColor,
  } = props;
  return (
    <Button
      height="10"
      type={type}
      width="100%"
      variant="ghost"
      onClick={onClick}
      isLoading={isLoading}
      bgGradient={bgGradient}
      colorScheme={colorScheme}
      backgroundImage={backgroundImage}
      backgroundColor={backgroundColor}
      _hover={{
        backgroundColor,
      }}
      _focus={{
        border: 0,
      }}
    >
      {children}
    </Button>
  );
};
