import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  Flex,
  Stack,
  Text,
  useDisclosure,
  Wrap,
  useToast,
} from '@chakra-ui/react';
import { ReactNode, useEffect, useState } from 'react';
import { ReactComponent as DeleteIcon } from '../assets/svgs/Delete.svg';
import { Toast } from '../Components/Common/Toast';
import DeleteDialog from './DeleteDialog';

interface ActionEntity {
  key: string;
  name: string;
}
interface ActionsDrawerProps {
  entity: string;
  showButtons: () => void;
  openActionDrawer: boolean;
  showDeleteButton: boolean;
  actionHappening?: boolean;
  handleDeleteEntities?: any;
  showCustomDelete?: () => void | undefined;
  setSelectedEntities: (_: any) => void;
  selectedEntities: ActionEntity[]; // TODO: need to change this according to the entities where this component is used
  body?: (entities: any) => ReactNode;
  deleteSubtitle: string;
  additionalConfirmationText?: string | undefined;
}

const ActionsDrawer = (props: ActionsDrawerProps) => {
  const {
    entity,
    showButtons,
    openActionDrawer,
    selectedEntities,
    showDeleteButton,
    setSelectedEntities,
    handleDeleteEntities,
    showCustomDelete,
    actionHappening,
    body,
    deleteSubtitle,
    additionalConfirmationText,
  } = props;
  const toast = useToast();
  const [agreed, setAgreed] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const deleteConfirmation = useDisclosure();

  function renderDeletedItem() {
    return (
      <Flex gap="sm">
        {entity}
        <Text fontWeight="bold" fontSize={18} color="white">
          {selectedEntities.map((e) => e.name).join(', ')}
        </Text>
        <Text color="white">deleted successfully</Text>
      </Flex>
    );
  }

  function renderDeleteToast() {
    return toast({
      duration: 3000,
      isClosable: true,
      render: function CustomFile() {
        return (
          <Toast
            title={`Deleted ${selectedEntities.length} ${
              entity.length ? entity : null
            }`}
            status="success"
            description={renderDeletedItem()}
          />
        );
      },
    });
  }

  const closeDeleteDialog = () => {
    deleteConfirmation.onClose();
    setSelectedEntities([]);
    setAgreed(false);
  };

  const handleDeleteAction = async () => {
    setDeleting(true);
    if (!agreed) return;

    try {
      await Promise.all(
        selectedEntities.map((i: ActionEntity) => {
          return handleDeleteEntities(i);
        })
      );
      renderDeleteToast();
      setSelectedEntities([]);
    } catch (error) {
      toast({
        title: 'Error!',
        description: 'Unable to delete.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    closeDeleteDialog();
    setDeleting(false);
  };

  const toggleAgreeCheckbox = () => {
    setAgreed(!agreed);
  };

  return (
    <>
      <DeleteDialog
        body={body ? body(selectedEntities) : null}
        open={deleteConfirmation.isOpen}
        closeDeleteDialog={closeDeleteDialog}
        handleDeleteAction={handleDeleteAction}
        agreed={agreed}
        toggleAgreeCheckbox={toggleAgreeCheckbox}
        entity={entity}
        loading={deleting}
        title={`Delete ${selectedEntities.length} ${entity}?`}
        deleteSubtitle={deleteSubtitle}
        additionalConfirmationText={additionalConfirmationText}
      />
      <Drawer
        trapFocus={false}
        blockScrollOnMount={false}
        placement="bottom"
        variant="alwaysOpen"
        onClose={() => setSelectedEntities([])}
        isOpen={!!selectedEntities.length && openActionDrawer}
      >
        <DrawerContent
          p="6"
          w="70%"
          ml="20rem"
          height="90px"
          borderRadius="15px 15px 0px 0px"
          boxShadow="0px -3px 4px #ECEEF5"
        >
          <DrawerBody overflow="hidden">
            <Stack direction="row" justify="space-between">
              <Flex>
                <DrawerCloseButton position="relative" top="0" />
                <Text display="flex" alignItems="center">
                  {selectedEntities.length} {entity} selected
                </Text>
              </Flex>
              <Wrap>
                {selectedEntities.length === 1 && showButtons()}

                {showDeleteButton && (
                  <Button
                    size="sm"
                    colorScheme="blue"
                    variant="outline"
                    disabled={actionHappening}
                    isLoading={deleting}
                    leftIcon={<DeleteIcon />}
                    border="2px solid #006DB8"
                    onClick={() => {
                      deleteConfirmation.onOpen();
                    }}
                  >
                    Delete
                  </Button>
                )}
              </Wrap>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
export default ActionsDrawer;
