import React, { useMemo, useState, useContext, useEffect } from 'react';
import {
  Box,
  Td,
  Flex,
  Text,
  Avatar,
  Icon,
  Collapse,
  Tbody,
  Tr,
} from '@chakra-ui/react';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  MainTable,
  MainTableRow,
} from '../../../../reusableComponent/MainTable';
import EmptyComponent from '../../../../reusableComponent/EmptyComponent';
import LocationZeroStateImage from '../../../../assets/images/zeroStateLocation.png';
import { ServiceTypesContext } from '../../../../contexts/service_types';
import { DetailsDrawer } from '../../../../reusableComponent/DetailsDrawer';
import MarkerDetails from '../../../../Components/Private/microLocations/micro_location_details';
import { sortByEntity } from '../../../../helpers/misc';

const CollapeDataTableRow = ({ microLocation, handleMicroLocationDetails }) => {
  const { name } = microLocation;

  return (
    <MainTableRow>
      <Td>
        <Flex
          onClick={() => handleMicroLocationDetails(microLocation)}
          cursor="pointer"
          gridGap="sm"
          alignItems="center"
        >
          <Text>{name}</Text>
        </Flex>
      </Td>
    </MainTableRow>
  );
};

const CollapseData = (props) => {
  const collapseHeaders = useMemo(() => [{ name: 'Name' }], []);
  const { locationKey, handleMicroLocationDetails } = props;
  const data = useContext(ServiceTypesContext);

  const [microLocationDataFromLocation, setMicroLocationDataFromLocation] =
    useState([]);

  const { markers } = data;

  useEffect(() => {
    const microLocationsOfALocation = markers.filter(
      (marker) => marker.propertyKey === locationKey
    );
    setMicroLocationDataFromLocation(microLocationsOfALocation);
  }, [markers]);

  if (microLocationDataFromLocation.length === 0) {
    return null;
  }
  return (
    <MainTable headers={collapseHeaders}>
      <Tbody bg="#ECEEF5" boxShadow="0px 12px 24px #ECEEF5" borderRadius="16px">
        {microLocationDataFromLocation.map((microLocation: any) => {
          return (
            <CollapeDataTableRow
              microLocation={microLocation}
              key={microLocation?.key}
              handleMicroLocationDetails={handleMicroLocationDetails}
            />
          );
        })}
      </Tbody>
    </MainTable>
  );
};

const CustomerTableRow = (props) => {
  const {
    location,
    handleDetailsDrawer,
    locationKeyToOpenCollapse,
    setLocationKeyToOpenCollapse,
    handleMicroLocationDetails,
  } = props;

  const [collapseOpen, setCollapseOpen] = useState(false);

  const {
    name,
    address,
    imageUrl,
    markers: microLocationCount,
    id: locationKey,
  } = location;

  const collapseOpenCloseIcon = () => {
    if (collapseOpen && locationKeyToOpenCollapse === locationKey) {
      return TriangleUpIcon;
    }
    return TriangleDownIcon;
  };
  const handleCollapse = (key) => {
    setLocationKeyToOpenCollapse(key);
    setCollapseOpen(true);

    if (locationKeyToOpenCollapse === key) {
      setLocationKeyToOpenCollapse('');
      setCollapseOpen(false);
    }
  };
  return (
    <>
      <MainTableRow>
        <Td>
          <Flex
            gridGap="sm"
            alignItems="center"
            cursor="pointer"
            onClick={() => handleDetailsDrawer(location)}
          >
            <Avatar name={name} src={imageUrl} />
            <Text>{name}</Text>
          </Flex>
        </Td>

        <Td>{address}</Td>
        <Td>
          <Flex
            gridGap="20px"
            cursor="pointer"
            alignItems="center"
            justifyContent="flex-end"
            onClick={() => handleCollapse(locationKey)}
          >
            <Box
              p="xxsm"
              backgroundColor="#F1F1F1"
              borderRadius="4px"
              opacity="1"
            >
              {microLocationCount ?? 0}
            </Box>
            Micro-Locations
            <Icon as={collapseOpenCloseIcon()} />
          </Flex>
        </Td>
      </MainTableRow>

      <td style={{ overflow: 'hidden' }} colSpan={100}>
        <Collapse
          in={collapseOpen && locationKeyToOpenCollapse === locationKey}
        >
          <Box bg="background.500">
            <CollapseData
              locationKey={locationKey}
              handleMicroLocationDetails={handleMicroLocationDetails}
            />
          </Box>
        </Collapse>
      </td>
    </>
  );
};

export const CustomerPropertiesTable = (props) => {
  const {
    locations,
    handleDetailsDrawer,
    searchText,
    locationKeyToOpenCollapse,
    setLocationKeyToOpenCollapse,
    setLocationHeaders,
    locationHeaders,
    setFilteredLocation,
  } = props;

  const [
    microLocationDetailsFromLocation,
    setMicroLocationDetailsFromLocation,
  ] = useState<any>({});
  const [openMicrolocationDetails, setOpenMicrolocationDetails] =
    useState(false);

  const renderMicroLocationDetails = () => {
    return (
      <MarkerDetails
        details={microLocationDetailsFromLocation ?? {}}
        setShowDetails={setOpenMicrolocationDetails}
      />
    );
  };

  // Todo Schedule
  const renderScheduleDetails = () => {
    return <h1>No schedule</h1>;
  };

  const detailTabs = {
    Details: renderMicroLocationDetails(),
    Schedule: renderScheduleDetails(),
  };

  const handleMicroLocationDetails = (microLocation) => {
    setMicroLocationDetailsFromLocation(microLocation);
    setOpenMicrolocationDetails(true);
  };

  const handleSorting = (header, headerIndex) => {
    const { sort, name: fieldName } = header;
    const newSort = sort === 'desc' ? 'asc' : 'desc';

    const copiedLocations = [...locations];

    switch (fieldName) {
      case 'Name':
        copiedLocations?.sort((a, b) => sortByEntity(a.name, b.name, newSort));
        break;

      case 'Address':
        copiedLocations?.sort((a, b) =>
          sortByEntity(a.address, b.address, newSort)
        );
        break;

      case 'Micro-Location':
        copiedLocations?.sort((a, b) => {
          return sortByEntity(a.markers ?? 0, b.markers ?? 0, newSort);
        });

        break;
      default:
        break;
    }

    const updatedHeaders = [...locationHeaders];
    updatedHeaders.splice(headerIndex, 1, {
      name: fieldName,
      sort: newSort,
    });

    setLocationHeaders(updatedHeaders);

    setFilteredLocation(copiedLocations);
  };

  // Todo: add title ,subtitle and image
  if (locations.length === 0) {
    return (
      <EmptyComponent
        title="No Locations"
        subTitle=""
        isSearch={!!searchText}
        header="Location"
        zeroStateImage={LocationZeroStateImage}
      />
    );
  }

  return (
    <Box>
      <MainTable headers={locationHeaders} handleSorting={handleSorting}>
        <Tbody bg="white" boxShadow="0px 12px 24px #ECEEF5" borderRadius="16px">
          {locations?.map((location) => {
            return (
              <CustomerTableRow
                key={location?.id}
                location={location}
                handleDetailsDrawer={handleDetailsDrawer}
                setLocationKeyToOpenCollapse={setLocationKeyToOpenCollapse}
                locationKeyToOpenCollapse={locationKeyToOpenCollapse}
                handleMicroLocationDetails={handleMicroLocationDetails}
              />
            );
          })}
        </Tbody>
      </MainTable>
      <DetailsDrawer
        open={openMicrolocationDetails}
        onClose={() => {
          setOpenMicrolocationDetails(false);
        }}
        title={microLocationDetailsFromLocation.name}
        details={microLocationDetailsFromLocation}
        detailTabs={detailTabs}
        hideEditDeleteButton
        subtitle={microLocationDetailsFromLocation.address}
      />
    </Box>
  );
};
