/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_loader.jsx
 *
 *  Description: Loader component.
 *
 *  Author: Nabin Kharal (nabin@brainants.com)
 *
 *  Date created: 4-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React from 'react';

import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

import color from '../../utils/color';
import { CenterScreen } from '../../styles/snowm_styled';

// functional component
const Loader = () => {
  // returns loader in the center of screen
  return (
    <CenterScreen>
      <CircularProgress />
    </CenterScreen>
  );
};

export const Progress = ({ colorOfProgress, size = 15 }) => {
  return <StyledProgress color={colorOfProgress} size={size} />;
};

const StyledProgress = styled(CircularProgress)`
  && {
    color: ${(props) => props.color ?? color.white};
  }
`;

export default Loader;
