export enum Report {
  Location = 'location',
  LocationSchedule = 'locationSchedule',
  MicroLocation = 'microLocation',
  Janitor = 'janitor',
  JanitorSchedule = 'janitorSchedule',
}

export enum ScheduledFor {
  Daily = 'daily',
  Weekly = 'weekly',
  BiWeekly = 'biWeekly',
  Monthly = 'monthly',
  Quarterly = 'quarterly',
}
