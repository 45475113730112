import React, { createContext } from 'react';
import LocalizedStrings from 'react-localization';

import hindi from '../lang/hi';
import english from '../lang/en';
import french from '../lang/fr';

export const LocalizationContext = createContext();

const defaultLanguage = localStorage.getItem('locale') || 'en';
const strings = new LocalizedStrings({
  en: { en: english },
  hi: { hi: hindi },
  fr: { fr: french },
});
strings.setLanguage([defaultLanguage]);

export const LocalizationProvider = ({ children }) => (
  <LocalizationContext.Provider
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    value={{
      strings: strings[defaultLanguage],
    }}
  >
    {children}
  </LocalizationContext.Provider>
);
