/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: BeaconM/index.jsx
 *
 *  Description: Index file that contains routes for becaonms
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 10-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import BeaconMs from './snowm_beaconms';

const BeaconMRoutes = () => (
  <Switch>
    <Route path="/admin/devices" component={BeaconMs} />
    <Redirect to="/admin/devices" />
  </Switch>
);

export default BeaconMRoutes;
