import { useEffect, useState } from 'react';
import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Divider,
  Flex,
  Checkbox,
  Text,
} from '@chakra-ui/react';
import CloseIcon from 'mdi-react/CloseIcon';
import { FaPlusCircle } from 'react-icons/fa';

const CollapseMicrolocation = (props) => {
  const {
    selectedTasks,
    microlocations,
    removeMicrolocationTasks,
    setOpenMicrolocationSelectModal,
    handleLocationClick,
    setArrayOfActivities,
    arrayOfActivities,
    activities,
    setActivities,
    location,
    setSelectedProperties,
    selectedProperties,
  } = props;
  const [selectedTaskList, setSelectedTaskList] = useState();
  const [selectedMicrolocationKey, setSelectedMicrolocationkey] = useState();

  function onTaskListModalClose() {
    setSelectedTaskList(undefined);
  }

  const isTaskListEmpty = selectedTasks.some((task, i) => {
    const tasks = Object.values(task)?.[0];
    return !tasks.length;
  });
  return (
    <>
      <TaskListModal
        selectedProperties={selectedProperties}
        setSelectedProperties={setSelectedProperties}
        onTaskListModalClose={onTaskListModalClose}
        selectedTaskList={selectedTaskList}
        selectedTasks={selectedTasks}
        selectedMicrolocationKey={selectedMicrolocationKey}
        location={location}
        setSelectedTaskList={setSelectedTaskList}
      />
      <Table background="#F1F1F1" variant="simple">
        <Thead background="#FBF9FB">
          <Tr
            color="#17244FFA"
            style={{ font: ' normal normal 600 16px/19px Montserrat' }}
          >
            <Th
              color="#17244FFA"
              style={{ font: ' normal normal 600 16px/19px Montserrat' }}
              fontWeight="bold"
            >
              Micro-Location name
            </Th>
            <Th
              color="#17244FFA"
              style={{ font: ' normal normal 600 16px/19px Montserrat' }}
              fontWeight="bold"
            >
              Task-List name
            </Th>
            {isTaskListEmpty ? (
              <Th> </Th>
            ) : (
              <Th
                color="#17244FFA"
                style={{ font: ' normal normal 600 16px/19px Montserrat' }}
                fontWeight="bold"
              >
                Tasks
              </Th>
            )}
            <Th> </Th>
          </Tr>
        </Thead>
        <Tbody>
          {selectedTasks.map((task, i) => {
            const tasks = Object.values(task)?.[0];
            const taskCategoryArray = Object.values(tasks)?.length
              ? [...new Set(Object.values(tasks)?.map((t) => t.category))]
              : [];
            const taskCategory = Object.values(tasks)?.length
              ? [...new Set(Object.values(tasks)?.map((t) => t.category))].join(
                  ','
                )
              : null;
            const microlocationKey = Object.keys(task)?.[0];

            return (
              <Tr key={taskCategory}>
                <Td>{microlocations[microlocationKey]?.name ?? '-'}</Td>
                <Td>
                  {!taskCategory ? (
                    <Text
                      textDecoration="underline"
                      color="#006DB8"
                      cursor="pointer"
                      onClick={() => {
                        handleLocationClick();
                        setOpenMicrolocationSelectModal(true);
                      }}
                    >
                      Assign now
                    </Text>
                  ) : (
                    <Text>{taskCategory}</Text>
                  )}
                </Td>
                <Td>
                  <Flex>
                    {tasks.length ? (
                      <Text
                        _hover={{
                          textDecoration: 'underline',
                        }}
                        color="#006DB8"
                        cursor="pointer"
                        onClick={() => {
                          // TODO: here show the original list, and add delete the selected tasks list
                          const allTasks = arrayOfActivities?.filter((t) =>
                            taskCategoryArray.includes(t.category)
                          );
                          setSelectedMicrolocationkey(microlocationKey);
                          setSelectedTaskList(allTasks);
                        }}
                      >
                        {tasks.length} Tasks
                      </Text>
                    ) : null}
                  </Flex>
                </Td>
                <Td>
                  <CloseIcon
                    onClick={() => {
                      removeMicrolocationTasks(microlocationKey);
                    }}
                    color="#17244F80"
                  />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Box width="100%" height="51px" backgroundColor="white">
        <Button
          // size="sm"
          isDisabled={false}
          leftIcon={<FaPlusCircle />}
          alignItems="center"
          paddingTop="23px"
          paddingLeft="15px"
          justifyContent="start"
          color="#006DB8"
          width="inherit"
          paddingBottom="25px"
          style={{
            background: 'transparent',
            boxShadow: '0px 12px 24px #ECEEF5',
            borderRadius: ' 8px',
          }}
          isLoading={false}
          onClick={() => {
            handleLocationClick();
            setOpenMicrolocationSelectModal(true);
          }}
        >
          Assign Micro location
        </Button>
      </Box>
    </>
  );
};

const TaskListModal = ({
  onTaskListModalClose,
  selectedTaskList,
  selectedTasks,
  selectedMicrolocationKey,
  selectedProperties,
  location,
  setSelectedProperties,
  setSelectedTaskList,
}) => {
  const [localSelectedTasks, setLocalSelectedTasks] = useState();
  useEffect(() => {
    if (selectedTasks?.length) {
      setLocalSelectedTasks(selectedTasks);
    }
  }, [selectedTasks]);

  const saveTaskList = () => {
    const revisedSelectedLocations = selectedProperties?.map((loc) => {
      if (loc.value === location.value) {
        return {
          ...loc,
          selectedTasks: localSelectedTasks,
        };
      }
      return loc;
    });

    setSelectedProperties([...revisedSelectedLocations]);
  };

  const addTasksToList = (task) => {
    const microlocationTasks = localSelectedTasks?.find((t) =>
      Object.keys(t).includes(selectedMicrolocationKey)
    )[selectedMicrolocationKey];
    const revisedTask = [...microlocationTasks, task];
    const revisedTaskLists = localSelectedTasks.map((t) => {
      if (Object.keys(t)[0] === selectedMicrolocationKey) {
        return {
          [selectedMicrolocationKey]: revisedTask,
        };
      }
      return t;
    });

    setLocalSelectedTasks(revisedTaskLists);
  };

  const removeTasksFromList = (task) => {
    const microlocationTasks = localSelectedTasks?.find((t) =>
      Object.keys(t).includes(selectedMicrolocationKey)
    )[selectedMicrolocationKey];
    const revisedTask = microlocationTasks.filter((t) => {
      return t.id !== task.id;
    });
    const revisedTaskLists = localSelectedTasks.map((t) => {
      if (Object.keys(t)[0] === selectedMicrolocationKey) {
        return {
          [selectedMicrolocationKey]: revisedTask,
        };
      }
      return t;
    });

    setLocalSelectedTasks(revisedTaskLists);
  };
  return (
    <Modal onClose={onTaskListModalClose} isOpen={!!selectedTaskList} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Tasks</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody>
          <Box>
            {selectedTaskList?.map((task) => {
              return (
                <Flex key={task.id}>
                  <Text>{task.name}</Text>
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        addTasksToList(task);
                      } else {
                        removeTasksFromList(task);
                      }
                    }}
                    marginLeft="auto"
                    isChecked={localSelectedTasks
                      ?.find((t) =>
                        Object.keys(t).includes(selectedMicrolocationKey)
                      )
                      ?.[selectedMicrolocationKey]?.map((s) => s.id)
                      .includes(task.id)}
                  />
                </Flex>
              );
            })}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              onTaskListModalClose();
              setLocalSelectedTasks(selectedTasks);
            }}
            variant="outline"
            color="primary.500"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              saveTaskList();
              setSelectedTaskList();
            }}
            colorScheme="blue"
            background="#006DB8 0% 0%"
            boxShadow=" 0px 3px 6px #006DB85C"
            borderRadius="5px"
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CollapseMicrolocation;
