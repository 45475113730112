import React, { useContext } from 'react';

import { Redirect, Route } from 'react-router';

import Vendors from './Vendors';
import Loader from '../../Generics/snowm_loader';
import { ServiceTypesContext } from '../../../contexts/service_types';

const VendorsRoutes = () => {
  const data = useContext(ServiceTypesContext);

  const { subscribedServices } = data;

  if (!subscribedServices) {
    return <Loader />;
  }

  if (!subscribedServices?.enableOrganizationCrew) {
    return <Redirect to="/admin" />;
  }

  return <Route path="/admin/vendors" exact component={Vendors} />;
};

export default VendorsRoutes;
