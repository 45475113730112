import React from 'react';

import { Switch, Route } from 'react-router-dom';

import Tasks from './Tasks';

const TasksRoutes = () => {
  return (
    <Switch>
      <Route path="/admin/tasks" component={Tasks} />
    </Switch>
  );
};

export default TasksRoutes;
