/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: pdf_helper.js
 *
 *  Description: Helper file that includes functions for getting data for pdf and
 * exporting too
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 13-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import { getTimeInEpoch } from '../helpers/date';
import {
  auth,
  getJobsForCrew,
  getCompanyDetail,
  getServiceInfo,
  getServicePointById,
  getMarkerLogs,
  getActiveRouteLogs,
  getJobsForRoute,
  getActiveRoutesForJob,
  getMarkersOfARoute,
  getProviderByUid,
  getJobsForMarkers,
  getIssueReportsOfAMarker,
  getIssueReports,
  chunk,
  getCurrentUserName,
  getPropertyById,
  getMarkersOfAJob,
  getMarkerLogOfAMarkerOfJob,
} from './snowm_firebase';

const removeNullFromJobs = (jobs) => {
  return jobs.reduce((acc, curr) => {
    if (!curr) {
      return acc;
    }

    return [...acc, curr];
  }, []);
};

function sortJobs(firstJob, secondJob) {
  if (firstJob?.job?.createdDate > secondJob?.job?.createdDate) return 1;
  if (firstJob?.job?.createdDate < secondJob?.job?.createdDate) return -1;
  return 0;
}

function filterReportWithMarkerKey(report, markerLog) {
  return report?.markerKey === markerLog?.servicePointId;
}

const getActivities = async (job) => {
  const { activities } = job;

  const activitiesDetails = Object.values(activities ?? {});

  const allActivities = activitiesDetails?.reduce((acc, activity) => {
    return [...acc, ...(activity ?? [])];
  }, []);

  const uniqueActivities = allActivities?.reduce((acc, activity) => {
    return {
      ...acc,
      [activity.id]: activity,
    };
  }, {});

  const arrayOfActivities = Object.values(uniqueActivities ?? {});
  return arrayOfActivities;
};

export async function getDataForProivderPDF(
  selectedProvider,
  startDate,
  endDate,
  // eslint-disable-next-line default-param-last
  selectImages = false,
  callback
) {
  const exportData = {
    provider: selectedProvider,
    servedJobs: [],
    startDate: getTimeInEpoch(startDate),
    endDate: getTimeInEpoch(endDate),
    id: Math.round(getTimeInEpoch() / 1000).toString(),
    companyDetail: (await getCompanyDetail()) || {},
    generatedBy: auth().currentUser.displayName,
    selectImages,
  };

  const crewUid = selectedProvider?.masterUid ?? selectedProvider?.uid;

  const jobs = await getJobsForCrew(
    crewUid,
    getTimeInEpoch(startDate),
    getTimeInEpoch(endDate)
  );

  if (jobs.length <= 0) {
    callback(exportData);
  }

  const promise = jobs?.map(async (job) => {
    const promise1 = getServiceInfo(job.serviceKey);
    const issueReports = await getIssueReports(job?.key);

    const markers = await getMarkersOfAJob(job.allMarkers);

    const logsPromise = markers?.map(async (marker) => {
      const markerLog = await getMarkerLogOfAMarkerOfJob(marker.key, job.key);

      let property = {};
      if (marker.propertyKey) {
        property = await getPropertyById(marker.propertyKey);
      }
      if (!markerLog) {
        return {
          ...marker,
          property,
          log: null,
        };
      }

      const detectionTime = job?.detectionTime?.[markerLog?.servicePointId];
      const reports = issueReports?.filter((report) =>
        filterReportWithMarkerKey(report, markerLog)
      );

      return {
        ...marker,
        property,
        reports,
        log: markerLog,
        detectionTime,
      };
    });

    const logs = await Promise.all(logsPromise ?? []);

    const activeRoutes = await getActiveRoutesForJob(job.key);

    const routesInfoPromise = activeRoutes?.map(async (route) => {
      const promise3 = getActiveRouteLogs(job.key, route.key);
      const markerLogs = await getMarkerLogs(job.key, route.key, false);
      const promise5 = markerLogs?.map(async (markerLog) => {
        const marker = await getServicePointById(markerLog.servicePointId);
        let property = {};
        if (marker.propertyKey) {
          property = await getPropertyById(marker.propertyKey);
        }
        const detectionTime = route.detectionTime?.[markerLog.servicePointId];

        return { ...marker, property, log: markerLog, detectionTime };
      });

      const logData = await Promise.all([...promise5]);

      const activeRouteLogs = await Promise.resolve(promise3);

      return { route, activeRouteLogs, logData };
    });

    const service = await Promise.resolve(promise1 ?? {});
    const routeInfo = await Promise.all(routesInfoPromise ?? []);
    let activities = null;
    if (!logs?.length) {
      activities = await getActivities(job);
    }
    return { service, routeInfo, job, logs, issueReports, activities };
  });

  const servJobs = await Promise.all(promise ?? []);

  servJobs.sort(sortJobs);

  const filteredJobs = removeNullFromJobs(servJobs);

  const arrayOfJobs = chunk(filteredJobs, 10);

  exportData.servedJobs = [...arrayOfJobs];
  callback(exportData);
}

export async function getDataForPDFForServiceRoute(
  selectedServiceRoute,
  startDate,
  endDate,
  // eslint-disable-next-line default-param-last
  selectImages = false,
  callback
) {
  const exportData = {
    route: selectedServiceRoute,
    service: await getServiceInfo(selectedServiceRoute.serviceKey),
    servedJobs: [],
    startDate: getTimeInEpoch(startDate),
    endDate: getTimeInEpoch(endDate),
    id: Math.round(getTimeInEpoch() / 1000).toString(),
    companyDetail: (await getCompanyDetail()) || {},
    generatedBy: auth().currentUser.displayName,
    selectImages,
  };

  const jobs = await getJobsForRoute(
    selectedServiceRoute.key,
    getTimeInEpoch(startDate),
    getTimeInEpoch(endDate)
  );

  if (jobs.length <= 0) {
    callback(exportData);
  }

  const promise = jobs.map(async (job) => {
    const crewsPromise = job.providerUids.map((crewUid) => {
      const crewPromise = getProviderByUid(crewUid);
      return crewPromise;
    });
    const routes = await getActiveRoutesForJob(
      job.key,
      selectedServiceRoute.key
    );

    const promise1 = routes.map(async (route) => {
      const promise2 = getActiveRouteLogs(job.key, route.key);
      const logs = await getMarkerLogs(job.key, route.key, false);
      const markers = await getMarkersOfARoute(route.servicePointsKeys);

      const logDataPromise = markers.map(async (marker) => {
        const filteredLog = logs.filter((l) => l.servicePointId === marker.key);
        const detectionTime = route.detectionTime?.[marker.key];
        const log = filteredLog[0];
        const reports = await getIssueReportsOfAMarker(job.key, marker.key);
        return { ...marker, reports, log, detectionTime };
      });

      const logData = await Promise.all(logDataPromise ?? []);

      const activeRoutesLogs = await Promise.resolve(promise2);
      return { logData, route, activeRoutesLogs };
    });
    const routeInfo = await Promise.all(promise1);
    const crews = await Promise.all(crewsPromise);

    return { job, routeInfo, crews };
  });

  const listOfJobs = await Promise.all(promise);

  listOfJobs.sort(sortJobs);

  const filteredJobs = removeNullFromJobs(listOfJobs);

  const arrayOfJobs = chunk(filteredJobs, 5);

  exportData.servedJobs = [...arrayOfJobs];
  callback(exportData);
}

export async function getDataForPDF(
  selectedMarkers,
  startDate,
  endDate,
  propertyDetail,
  selectImages = false
) {
  const startTimestamp = getTimeInEpoch(startDate);
  const endTimeStamp = getTimeInEpoch(endDate);

  let property = propertyDetail ?? null;

  const exportingData = {
    markers: selectedMarkers,
    startDate: startTimestamp,
    endDate: endTimeStamp,
    id: Math.round(getTimeInEpoch() / 1000).toString(),
    companyDetail: (await getCompanyDetail()) ?? {},
    generatedBy: auth().currentUser.displayName,
    selectImages,
  };

  if (property) {
    exportingData.property = property;
  }

  const markersKeys = selectedMarkers?.map((marker) => marker.key);

  const jobs = await getJobsForMarkers(
    markersKeys,
    startTimestamp,
    endTimeStamp
  );

  const jobPromise = jobs?.map(async (job) => {
    const crew = await getProviderByUid(job.providerUids[0]);
    const service = await getServiceInfo(job.serviceKey);
    const issueReports = await getIssueReports(job.key);

    const markers = await getMarkersOfAJob(job.allMarkers);
    let logsPromise = [];

    const markersSelected = markers?.reduce((accumulator, marker) => {
      if (markersKeys.includes(marker.key)) {
        return [...accumulator, marker];
      }
      return accumulator;
    }, []);

    logsPromise = markersSelected?.map(async (marker) => {
      const markerLog = await getMarkerLogOfAMarkerOfJob(marker.key, job.key);
      if (!property && marker?.propertyKey) {
        property = await getPropertyById(marker?.propertyKey);
      }
      if (!markerLog) {
        return {
          ...marker,
          log: null,
          property,
        };
      }

      const detectionTime =
        job.detectionTime?.[markerLog.servicePointId] ?? '-';

      const reports = issueReports?.filter((report) =>
        filterReportWithMarkerKey(report, markerLog)
      );
      return { log: markerLog, detectionTime, reports, ...marker, property };
    });

    const routes = await getActiveRoutesForJob(job.key);

    const routeInfo = routes?.map(async (route) => {
      const activeRouteLogs = await getActiveRouteLogs(job.key, route.key);

      const markerLogs = await getMarkerLogs(job.key, route.key, false);

      const logDataPromises = markerLogs?.map(async (log) => {
        const detectionTime = route.detectionTime?.[log.servicePointId];
        const marker = await getServicePointById(log.servicePointId);
        return { log, detectionTime, ...marker };
      });

      const logData = await Promise.all(logDataPromises);

      return { route, activeRouteLogs, logData };
    });

    const logs = await Promise.all(logsPromise);

    let activities = null;

    if (!logs.length) {
      activities = getActivities(job);
    }
    return { job, crew, service, logs, routeInfo, issueReports, activities };
  });

  const servedJobs = await Promise.all(jobPromise ?? []);

  servedJobs.sort(sortJobs);

  const newArray = removeNullFromJobs(servedJobs);

  const arrayOfJobs = chunk(newArray, 5);

  exportingData.servedJobs = arrayOfJobs;
  return exportingData;
}

export async function getInvoiceData(selectedMarkerlog) {
  const { companyKey, invoice, providerUid } = selectedMarkerlog;
  const adminCompany = await getCompanyDetail(companyKey);
  const currentUser = getCurrentUserName();
  const crew = await getProviderByUid(providerUid);

  const signature = invoice?.signatureUrl;

  return {
    servicedBy: adminCompany,
    serviceAddress: adminCompany,
    signature,
    currentUser,
    invoice,
    crew,
  };
}
