import React from 'react';
import { RoutesProvider } from './service_routes';
import { ServicePProvider } from './service_provider';
import { JobsProvider } from './service_jobs';
import { BeaconMsProvider } from './beaconMs';
import { ServicePointsProvider } from './service_points';
import { ServiceProvider } from './services';
import { AuthProvider } from './auth_context';
import { ServiceTypeProvider } from './service_types';
import { LocalizationProvider } from './localization_context';
import { LocationProvider } from './locationContext';

const ContextProvider = ({ children }) => {
  return (
    <LocalizationProvider>
      <AuthProvider>
        <ServicePProvider>
          <RoutesProvider>
            <LocationProvider>
              <ServicePointsProvider>
                <JobsProvider>
                  <ServiceProvider>
                    <BeaconMsProvider>
                      <ServiceTypeProvider>{children}</ServiceTypeProvider>
                    </BeaconMsProvider>
                  </ServiceProvider>
                </JobsProvider>
              </ServicePointsProvider>
            </LocationProvider>
          </RoutesProvider>
        </ServicePProvider>
      </AuthProvider>
    </LocalizationProvider>
  );
};

export default ContextProvider;
