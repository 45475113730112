/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_appbar.jsx
 *
 *  Description: Appbar component.
 *
 *  Author: Nabin Kharal (nabin@brainants.com)
 *
 *  Date created: 4-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */

import { useContext, useEffect, useState } from 'react';

import { Flex, Text } from '@chakra-ui/layout';
import {
  Button,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';

import AlertBox from '../Common/alert_box';
import CompaniesList from '../Public/CompaniesList';
import { AuthContext } from '../../contexts/auth_context';
import { LocalizationContext } from '../../contexts/localization_context';
import {
  logout,
  getCurrentUser,
  getAllCompanies,
  getCompanyDetail,
  storeCompanyKeyToLocalStorage,
} from '../../controllers/snowm_firebase';

// functional component
/*
 **************************************************************************************
 * @brief  (AppBarView)  renders the appbar
 * @param{type :object } (props)
 *
 * @returns Appbar component (react element)
 **************************************************************************************
 */
export const defaultAvatar =
  'https://iupac.org/wp-content/uploads/2018/05/default-avatar.png';

const AppBarView = ({ ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [userName, setUserName] = useState('Stratosfy');
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [openLogoutPopUp, setOpenLogoutPopUp] = useState(false);
  const [remainingCompanyDetails, setRemainingCompanyDetails] = useState(null);

  const { authenticated } = useContext(AuthContext);

  const { userClaims } = authenticated;
  // eslint-disable-next-line prefer-const
  let { roles, super_admin: superAdmin } = userClaims;

  useEffect(() => {
    let isCancel = false;
    const fetchCompanyDetails = async () => {
      const selectedCompanyKey = localStorage.getItem('selectedCompanyKey');

      let companies;

      if (superAdmin) {
        companies = await getAllCompanies();

        roles = companies?.map((company) => ({
          role: 'administrator',
          companyKey: company.key,
        }));
      }

      const rolesRemaining = roles?.filter(
        (role) => role.companyKey !== selectedCompanyKey
      );

      const companiesPromises = rolesRemaining.map((role) => {
        const companyDetail = companies?.find(
          (company) => company.key === role.companyKey
        );

        if (companyDetail) {
          return companyDetail;
        }

        return getCompanyDetail(role.companyKey);
      });

      const companiesDetails = await Promise.all(companiesPromises);
      if (!isCancel) {
        setRemainingCompanyDetails(companiesDetails);
      }
    };

    fetchCompanyDetails();

    return () => {
      isCancel = true;
    };
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   **************************************************************************************
   * @brief  (GetCurrenUser)  sets the text inside the userProfile image if image not provided
   * @param{type :Firebase.user } (user)
   *
   * @returns undefined
   **************************************************************************************
   */

  getCurrentUser((user) => {
    if (user) {
      setUserName(user.displayName);
    } else {
      setUserName('Stratosfy');
    }
  });

  const switchCompany = (company) => {
    const { key } = company;
    storeCompanyKeyToLocalStorage(key);
  };

  const handleLogoutButton = () => {
    setIsLoggingOut(false);
    setOpenLogoutPopUp(true);
  };

  const cancelLogout = () => {
    setOpenLogoutPopUp(false);
  };

  const handleLogOutAction = async () => {
    setIsLoggingOut(true);
    await logout();
    setIsLoggingOut(false);
  };

  const { theme, routes, history } = props;
  const { strings } = useContext(LocalizationContext);

  return (
    <>
      <Flex
        h="16"
        zIndex="1"
        paddingRight="12"
        justifyContent="flex-end"
        boxShadow="0px 12px 24px #ECEEF5"
      >
        <Flex justifyContent="flex-end" alignItems="center" gridGap="16px">
          <Text fontSize="sm" color="highlight.500">
            {userName ? userName[0] : 'S'}
          </Text>
          <Menu>
            <MenuButton as={Button} backgroundColor="white" paddingRight="0">
              <Image
                src={theme}
                boxSize="40px"
                borderRadius="50%"
                fallbackSrc={defaultAvatar}
              />
            </MenuButton>
            <MenuList>
              <MenuGroup title="Choose Company" fontSize="md">
                <CompaniesList
                  handleCompanySelection={switchCompany}
                  companiesInDetail={remainingCompanyDetails}
                />
              </MenuGroup>
              <MenuDivider />
              <MenuGroup>
                {routes.map(({ route, name, menu }) => {
                  if (menu) {
                    return (
                      <MenuItem
                        key={name}
                        onClick={() => {
                          history.push(route);
                          handleClose();
                        }}
                      >
                        {name}
                      </MenuItem>
                    );
                  }
                  return null;
                })}
                <MenuItem onClick={handleLogoutButton}>
                  {strings.auth?.LOG_OUT}
                </MenuItem>
              </MenuGroup>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
      <AlertBox
        title="Log Out"
        positiveText="Log Out"
        open={openLogoutPopUp}
        isTakingAction={isLoggingOut}
        handleNegativeAction={cancelLogout}
        handlePositiveAction={handleLogOutAction}
        subtitle="Are you sure you want to logout from the application?"
      />
    </>
  );
};

export default AppBarView;
