import * as React from 'react';

import { Redirect, Route } from 'react-router';

import Invoices from './Invoices';
import Loader from '../../Generics/snowm_loader';
import { ServiceTypesContext } from '../../../contexts/service_types';

const InvoicesRoutes = () => {
  const data = React.useContext(ServiceTypesContext);
  const { subscribedServices } = data;

  if (!subscribedServices) {
    return <Loader />;
  }

  if (!subscribedServices?.invoicing) {
    return <Redirect to="/admin" />;
  }

  return <Route path="/admin/invoices" component={Invoices} />;
};

export default InvoicesRoutes;
