import { ReactComponent as IconLocation } from '../assets/svgs/Location.svg';
import { ReactComponent as IconDashboard } from '../assets/svgs/Dashboard.svg';
import { ReactComponent as IconLocationDrawer } from '../assets/svgs/drawer/location/Location.svg';
import { ReactComponent as IconActiveLocationDrawer } from '../assets/svgs/drawer/location/ActiveLocation.svg';
import { ReactComponent as JanitorActiveIcon } from '../assets/svgs/janitor/JanitorActiveIcon.svg';
import { ReactComponent as JanitorrIcon } from '../assets/svgs/janitor/JanitorIcon.svg';
import { ReactComponent as ScheduleeIcon } from '../assets/svgs/schedule/ScheduleIcon.svg';
import { ReactComponent as ScheduleActiveIcon } from '../assets/svgs/schedule/ScheduleActiveIcon.svg';
import { ReactComponent as TasksIcon } from '../assets/svgs/task/Tasks.svg';
import { ReactComponent as ActiveTasksIcon } from '../assets/svgs/task/ActiveTaskIcon.svg';
import { ReactComponent as SettingsIcon } from '../assets/svgs/setting/Settings.svg';
import { ReactComponent as ActiveSettings } from '../assets/svgs/setting/ActiveSettings.svg';
import { ReactComponent as Workorders} from '../assets/svgs/workorder/Workorders.svg';
import { ReactComponent as ActiveWorkorders} from '../assets/svgs/workorder/ActiveWorkorder.svg';
import { ReactComponent as Devices} from '../assets/svgs/device/Devices.svg';
import { ReactComponent as ActiveDevices} from '../assets/svgs/device/ActiveDevices.svg';
import { ReactComponent as ShiftTemplate} from '../assets/svgs/shiftTemplate/ShiftTemplate.svg';
import { ReactComponent as ActiveShiftTemplate} from '../assets/svgs/shiftTemplate/ActiveShiftTemplate.svg';


export const DashboardIcon = () => {
  return <IconDashboard />;
};

export const LocationIcon = () => {
  return <IconLocation />;
};

export const DrawerLocationIcon = () => {
  return <IconLocationDrawer />;
};

export const DrawerActiveLocationIcon = () => {
  return <IconActiveLocationDrawer />;
};

export const ActiveJanitorIcon = () => {
  return <JanitorActiveIcon />;
};

export const JanitorIcon = () => {
  return <JanitorrIcon />;
};

export const ActiveScheduleIcon = () => {
  return <ScheduleActiveIcon />;
};

export const ScheduleIcon = () => {
  return <ScheduleeIcon />;
};


export const TaskIcon = () => {
  return <TasksIcon />

}

export const ActiveTaskIcon = () => {
  return <ActiveTasksIcon />

}

export const SettingIcon = () => {
  return <SettingsIcon />

}

export const ActiveSettingIcon = () => {
  return <ActiveSettings />

}

export const ActiveWorkerOrderIcon = () => {
  return <ActiveWorkorders/>
}

export const WorkerOrderIcon = () => {
  return <Workorders />
}


export const ActiveDeviceIcon = () => {
  return <ActiveDevices/>
}

export const DeviceIcon = () => {
  return <Devices />
}
export const ActiveShiftTemplateIcon = () => {
  return <ActiveShiftTemplate/>
}

export const ShiftTemplateIcon = () => {
  return <ShiftTemplate />
}