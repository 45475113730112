/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_service_points.jsx
 *
 *  Description: Show the list of service points
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 8-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useContext, useState, useEffect, useMemo } from 'react';

import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { Redirect } from 'react-router';
import { Box, Text, Flex, Heading, Button } from '@chakra-ui/react';

import SearchIcon from 'mdi-react/SearchIcon';
import { StyledGrid } from '../styled';
import { Row } from '../../../styles/snowm_styled';
import ServicePointItem from './service_point_item';
import { ServiceTypesContext } from '../../../../contexts/service_types';
import { ServicePointsContext } from '../../../../contexts/service_points';
import SearchBar from '../../../../Components/Common/Search_Bar';
import { CustomerMicroLocationTable } from './CustomerMicroLocationTable';
import { DetailsDrawer } from '../../../../reusableComponent/DetailsDrawer';
import MicroLocationDetails from '../../../../Components/Private/microLocations/micro_location_details';
import {
  filterDataByName,
  findSortType,
  sortByEntity,
} from '../../../../helpers/misc';
import Loader from '../../../../Components/Generics/snowm_loader';

const MicroLocationConsumer = (servicePointProps) => {
  return (
    <ServicePointsContext.Consumer>
      {({ markers, fetchServicePoints }) => {
        return (
          <ServicePoints
            markers={markers}
            fetchServicePoints={fetchServicePoints}
            {...servicePointProps}
          />
        );
      }}
    </ServicePointsContext.Consumer>
  );
};

const ServicePoints = () => {
  const header = useMemo(
    () => [
      { name: 'Name', sort: 'asc' },
      { name: 'Location', sort: 'asc' },
      { name: 'Location Address', sort: 'asc' },
    ],
    []
  );

  const [searchText, setSearchText] = useState('');
  const [openDetailsDrawer, setOpenDetailsDrawer] = useState(false);
  const [microLocationDetails, setMicroLocationDetails] = useState();
  const [filteredMicroLocation, setFilteredMicroLocation] = useState();
  const [microLocationHeaders, setMicroLocationHeaders] = useState(header);

  const { markers } = useContext(ServiceTypesContext);

  useEffect(() => {
    const microLocations = [...markers];
    const { sort } = findSortType(microLocationHeaders, 'Name');
    microLocations.sort((a, b) => sortByEntity(a.name, b.name, sort));
    if (searchText) {
      const microLocationAfterFilter = filterDataByName(
        searchText,
        microLocations
      );
      const micro = Object.values(microLocationAfterFilter);
      setFilteredMicroLocation([...micro]);
    } else {
      setFilteredMicroLocation(markers);
    }
  }, [markers, searchText]);

  const renderMicroLocationDetails = () => {
    return (
      <MicroLocationDetails
        details={microLocationDetails ?? {}}
        setShowDetails={setOpenDetailsDrawer}
      />
    );
  };

  // Todo Schedule
  const renderScheduleDetails = () => {
    return <h1>No schedule</h1>;
  };

  const detailTabs = {
    Details: renderMicroLocationDetails(),
    Schedule: renderScheduleDetails(),
  };

  const handleDetailsDrawer = (microLocation) => {
    setOpenDetailsDrawer(true);
    setMicroLocationDetails(microLocation);
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  if (!filteredMicroLocation) {
    return <Loader />;
  }

  return (
    <Box bg="background.500" overflow="auto" height="100%" p="12">
      <Flex direction="column" gridGap="llg">
        <Heading as="h4">Micro-Locations</Heading>
        <SearchBar
          title="Micro-Location"
          LeftIcon={<SearchIcon />}
          value={searchText}
          onChange={(e) => onSearch(e)}
          fromAdministrator
        />
      </Flex>
      <CustomerMicroLocationTable
        microLocations={filteredMicroLocation}
        handleDetailsDrawer={handleDetailsDrawer}
        searchText={searchText}
        setMicroLocationHeaders={setMicroLocationHeaders}
        microLocationHeaders={microLocationHeaders}
        setFilteredMicroLocation={setFilteredMicroLocation}
      />
      <DetailsDrawer
        open={openDetailsDrawer}
        onClose={() => {
          setOpenDetailsDrawer(false);
        }}
        title={microLocationDetails?.name}
        details={microLocationDetails}
        detailTabs={detailTabs}
        hideEditDeleteButton
        subtitle={microLocationDetails?.address}
      />
    </Box>
  );
};

export default MicroLocationConsumer;
