const hi = {
  validation: {},
  auth: {
    LOGIN: 'लॉग इन करें',
    LOG_OUT: 'लोग आउट',
  },
  inputLabel: {
    EMAIL: 'ईमेल',
    PASSWORD: 'पारण शब्',
    NAME: 'नाम',
    PHONE: 'फ़ोन',
    ADDRESS: 'पता',
    CONTACT_NUMBER: 'संपर्क संख्या',
    DESIGNATION: 'पद',
  },
  sidemenu: {
    // TODO: Change local lang according to the keys
    TASKS: 'Tasks',
    LOCATIONS: 'Locations',
    DASHBOARD: 'घर',
    SHIFTS: 'नौकरियां',
    JANITORS: 'कर्मी दल',
    SERVICES: 'सेवाएं',
    ROUTES: 'मार्गों',
    MICROLOCATIONS: 'मार्करों',
    REPORTS: 'रिपोर्ट',
    COMPANY_PROFILE: 'प्रोफ़ाइल',
    DEVICES: 'उपकरण',
    ATTENDANCE: 'उपस्थिति',
    USER_PROFILE: 'उपयोगकर्ता प्रोफ़ाइल',
  },
  jobType: {
    ASSIGNED_JOBS: 'असाइन किए गए कार्य',
    PERIODIC_JOBS: 'समय-समय पर नौकरियां',
  },
  action: {
    CREATE_JOB: 'कार्य बनाएं',
    UPDATE_ROUTE: 'अद्यतन रूट',
    EDIT_ROUTE: 'रूट संपादित करें',
    EDIT: 'संपादित करें',
    CANCEL: 'रद्द करें',
    CONFIRM: 'पुष्टि करें"',
    ADD: 'जोड़े',
  },
  location: {
    CURRENT_LOCATION: 'वर्तमान थान',
  },
  detail: {
    CREW_DETAIL: 'क्रू विस्तार',
    ISSUE_REPORTS: 'मुद्दा रिपोर्ट',
  },
  messages: {
    NO_CREWS_ADDED: 'कोई क्रू मेंबर नहीं',
    MINIMUM_MARKERS:
      'वहाँ मार्ग पर पर्याप्त मार्कर एक दल को सौंपा जा करने के लिए नहीं कर रहे हैं। कृपया कम से कम 3 अंक जोड़ने',
    YES: 'हाँ',
    NO: 'नहीं',
    NO_MESSAGES: 'कोई संदेश नहीं',
    NOT_AVAILABLE: 'उपलब्ध नहीं है',
  },
  search: {
    SEARCH: 'खोजें',
  },
  home: {
    NOTIFICATIONS: 'सूचनाएं',
    SEE_MORE_INFO: 'अधिक जानकारी देखें',
  },
  status: {
    ASSIGNED: 'सौंपा',
    COMPLETED: 'पूरा कर लिया',
    STARTED: 'शुरू कर दिया',
  },
  markers: {
    LAST_SERVED: 'अंतिम सेवित',
    MARKER_NAME: 'मार्कर नाम',
    SELECTED_MARKERS: 'चयनित मार्कर',
    SELECT_MARKER: 'मार्कर का चयन करें',
    MARKER_LOGS: 'मार्कर लॉग्स',
    SERVED_MARKERS: 'सेवा की मार्करों',
  },
  misc: {
    CHANGE_LANGUAGE: 'भाषा बदलो',
    LANGUAGE_SETTINGS: 'भाषा सेटिंग',
    DISTANCE: 'दूरी',
    METERS: 'मीटर की दूरी पर',
    LAST_UPDATED: 'आखरी अपडेट',
    MAP_VIEW: 'नक्शा देखें',
    CHANGE_PASSWORD: 'पासवर्ड बदलें',
  },
  serviceType: {
    INDOOR: 'इंडोर',
  },
  image: {
    AFTER_IMAGE: 'छवि के बाद',
    BEFORE_IMAGE: 'छवि से पहले',
  },
  time: {
    COMPLETED_AT: 'पर पूरा किया गया',
  },
  taskList: {
    TASK_LIST: 'कार्य सूची',
    TASK_LISTS: 'कार्य सूचियाँ',
    task: {
      TASK: 'कार्य',
      TASKS: 'कार्य',
    },
  },
};

export default hi;
