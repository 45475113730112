import React from 'react';

import { Button } from '@chakra-ui/react';

interface ClickableContainerProps {
  onClick: () => void;
  isDisabled?: boolean;
  children: any;
}

const ClickableContainer = (props: ClickableContainerProps) => {
  const { onClick, isDisabled, children } = props;

  return (
    <Button
      variant="unstyled"
      isDisabled={isDisabled}
      onClick={() => onClick()}
      _focus={{ outline: 'none' }}
    >
      {children}
    </Button>
  );
};

export default ClickableContainer;
