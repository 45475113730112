/* eslint-disable import/prefer-default-export */
export function generateCronExpression(
  minutes = [],
  hours = [],
  weekDays = [],
  dates = [],
  months = []
) {
  let exp = '';
  if (minutes.length === 0) {
    exp += '* ';
  } else {
    minutes.forEach((minute) => {
      if (minutes.indexOf(minute) === minutes.length - 1) {
        exp += `${minute} `;
      } else {
        exp += `${minute},`;
      }
    });
  }
  if (hours.length === 0) {
    exp += '* ';
  } else {
    hours.forEach((hour) => {
      if (hours.indexOf(hour) === hours.length - 1) {
        exp += `${hour} `;
      } else {
        exp += `${hour},`;
      }
    });
  }

  if (dates.length === 0) {
    exp += '* ';
  } else {
    dates.forEach((date) => {
      if (dates.indexOf(date) === dates.length - 1) {
        exp += `${date} `;
      } else {
        exp += `${date},`;
      }
    });
  }

  if (months.length === 0) {
    exp += '* ';
  } else {
    months.forEach((month) => {
      if (months.indexOf(month) === months.length - 1) {
        exp += `${month} `;
      } else {
        exp += `${month},`;
      }
    });
  }

  if (!weekDays?.length) {
    exp += '*';
  } else {
    weekDays.forEach((day) => {
      if (weekDays.indexOf(day) === weekDays.length - 1) {
        exp += `${day}`;
      } else {
        exp += `${day},`;
      }
    });
  }
  return exp;
}
