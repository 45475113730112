import axios from 'axios';

const userHash = '62F5-26E5-DD24-053D';
const baseUrl = 'https://navigine-api.snowm.io/client';

const saveVersionUrl = `${baseUrl}/versions/save`;
const beaconUrl = `${baseUrl}/beacon`;
const addBeaconUrl = `${baseUrl}/beacon/add`;
const getAllBeacons = `${beaconUrl}/getAll`;
const addNewSubLocationUrl = `${baseUrl}/sublocations/add`;
const getSubLocationUrl = `${baseUrl}/sublocations/get`;
const deleteBeaconUrl = `${baseUrl}/beacon/delete`;

// eslint-disable-next-line import/prefer-default-export
export async function fetchAllBeacons(subId) {
  const allBeacons = await axios.get(
    `${getAllBeacons}?userHash=${userHash}&sublocationId=${subId}`
  );

  return allBeacons.data;
}

export async function addSubLocation(
  locid,
  name,
  image,
  height,
  width,
  latitude,
  longitude
) {
  const formData = {
    title: name,
    filewidth: width,
    fileheight: height,
    file: image,
    lat: latitude,
    lon: longitude,
    hor: 2,
    timezone: 5
  };

  const res = await axios({
    method: 'POST',
    url: `${addNewSubLocationUrl}?userHash=${userHash}&locationId=${locid}`,
    data: formData
  });

  return res.data;
}

export async function getSubLocationInfo(subId) {
  const res = await axios({
    url: `${getSubLocationUrl}?userHash=${userHash}&sublocationId=${subId}`,
    method: 'GET'
  });
  return res.data;
}

export async function addBeacon(subId, beacon, beaconPoint) {
  const data = {
    description: beacon.uuid,
    uuid: beacon.uuid,
    major: beacon.max,
    minor: beacon.min,
    kx: beaconPoint.kx,
    ky: beaconPoint.ky,
    pw: -2,
    isUsed: 0,
    sublocationId: subId,
    powerNav: -72
  };

  const res = await axios({
    url: `${addBeaconUrl}?userHash=${userHash}`,
    method: 'POST',
    data
  });

  return res.data;
}

export const saveLocation = async id => {
  await axios({
    url: `${saveVersionUrl}?userHash=${userHash}&locationId=${id}`,
    method: 'POST'
  });
};

export const deleteBeacon = async bid => {
  const data = {
    id: parseInt(bid, 10)
  };
  const res = await axios({
    url: `${deleteBeaconUrl}?userHash=${userHash}`,
    method: 'POST',
    data
  });

  return res.data;
};
