import React, { Fragment, useEffect, useState } from 'react';

import styled from 'styled-components';
import CloseIcon from 'mdi-react/CloseIcon';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Button, CircularProgress, Collapse, Divider } from '@material-ui/core';

import Span from '../../Common/Span';
import InvoicePdf from './InvoicePdf';
import color from '../../../utils/color';
import Loader from '../../Generics/snowm_loader';
import ADialog, { Grid } from '../../Common/styled';
import MarkerLogs from '../../../reusableComponent/shiftSchedule/markerLogs/MakerLogs';
import getAmountsOfAEntity from './invoiceCalculation';
import PrimaryButton from '../../Common/primary_button';
import ListTitleItem from '../../Generics/list_title_item';
import AssetInformation from '../microLocations/AssetInformation';
import { Col, Row, Title } from '../../../styles/snowm_styled';
import { getInvoiceData } from '../../../controllers/pdf_helper';
import { getCurrentDate, getFormattedDate } from '../../../helpers/date';
import {
  getMarkerLogsHavingInvoices,
  getServicePointById,
} from '../../../controllers/snowm_firebase';

const InvoiceEntity = ({
  entities,
  title,
  handleInvoiceEntityClick,
  expanded,
}) => {
  if (!entities) {
    return null;
  }
  return entities?.map((entity) => {
    const amounts = getAmountsOfAEntity(entity);
    const { subTotal, tax, total } = amounts;
    return (
      <Col key={entity.title}>
        <Span size="20px" weight="bold">
          {title}
        </Span>
        <Divider />
        <Col onClick={() => handleInvoiceEntityClick(entity.title)}>
          <ListTitleItem content={`$ ${total}`} />
          <ListTitleItem title="Name" content={entity.title} />
          <ListTitleItem title="Description" content={entity.description} />
        </Col>
        <Collapse in={expanded?.[entity.title]}>
          <ListTitleItem
            title="Sub total"
            content={`$ ${subTotal?.toFixed(2)}`}
          />
          <ListTitleItem title="Tax" content={`$ ${tax?.toFixed(2)}`} />
        </Collapse>
      </Col>
    );
  });
};

const Invoices = () => {
  const [markerLogs, setMarkerLogs] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMarkerLog, setSelectedMarkerLog] = useState(null);
  const [marker, setMarker] = useState(null);
  const [expanded, setExpanded] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

  useEffect(() => {
    let isCancel = false;
    getMarkerLogsHavingInvoices().then((res) => {
      if (!isCancel) {
        setMarkerLogs(res);
      }
    });
    return () => {
      isCancel = true;
    };
  }, []);

  useEffect(() => {
    if (selectedMarkerLog) {
      getServicePointById(selectedMarkerLog.servicePointId).then((res) => {
        setMarker(res);
      });
    } else {
      setMarker(null);
    }
  }, [selectedMarkerLog]);

  function handleCancelButton() {
    setOpenDialog(false);
  }

  const date = ({ invoice }) => (
    <Span weight="bold">{getFormattedDate(invoice?.invoicedDate)}</Span>
  );

  function handleCardClick(markerLog, totalsOfAll) {
    setInvoiceData(null);
    const { invoice } = markerLog;
    setOpenDialog(true);
    setSelectedMarkerLog({
      ...markerLog,
      invoice: { ...(invoice ?? {}), totalsOfAll },
    });
  }

  function totallingOperation(acc, current) {
    const amounts = getAmountsOfAEntity(current);
    const { subTotal, tax, total } = amounts;
    acc.subTotal += subTotal;
    acc.tax += tax;
    acc.total += total;
    return acc;
  }

  function getTotalAmount(invoice) {
    const { materials, labour, misc, fixedFee } = invoice;
    const allEntities = [
      ...(materials ?? []),
      ...(labour ?? []),
      ...(misc ?? []),
      ...(fixedFee ?? []),
    ];

    const totalOfAll = allEntities.reduce(totallingOperation, {
      subTotal: 0,
      tax: 0,
      total: 0,
    });

    return totalOfAll;
  }

  const DetailButton = ({ log, text, totalsOfAll }) => (
    <ButtonContainer justify="flex-end">
      <StratosfyButton onClick={() => handleCardClick(log, totalsOfAll)}>
        <Span size="12px" color={color.white}>
          {text}
        </Span>
        <ArrowContainer>
          <ArrowRightIcon size="12px" color={color.white} />
        </ArrowContainer>
      </StratosfyButton>
    </ButtonContainer>
  );

  const invoicesDetails = () =>
    markerLogs?.map((markerLog) => {
      const invoice = markerLog.invoice ?? {};
      const totalsOfAll = getTotalAmount(invoice);
      return (
        <InvoiceCard key={invoice?.invoicedDate}>
          <Row>{date({ invoice })}</Row>

          <Divider />

          <ListTitleItem
            title="Sub total"
            content={`$ ${totalsOfAll?.subTotal?.toFixed(2)}`}
          />
          <ListTitleItem
            title="Tax"
            content={`$ ${totalsOfAll?.tax?.toFixed(2)}`}
          />
          <ListTitleItem
            title="Total"
            content={`$ ${totalsOfAll?.total?.toFixed(2)}`}
          />

          <Divider />

          <DetailButton
            text="Invoice Details"
            log={markerLog}
            totalsOfAll={totalsOfAll}
          />
        </InvoiceCard>
      );
    });

  const assetInformation = () => {
    if (!marker) {
      return (
        <Row justify="center">
          <CircularProgress />
        </Row>
      );
    }
    return <AssetInformation details={marker} />;
  };

  function handleInvoiceEntityClick(title) {
    setExpanded({ [title]: !expanded?.[title] });
  }

  async function handleGeneratePdf() {
    setIsGeneratingPdf(true);
    const data = await getInvoiceData(selectedMarkerLog);
    setIsGeneratingPdf(false);
    setInvoiceData(data);
  }

  function handleDownloadButton() {
    setOpenDialog(false);
  }

  if (!markerLogs) {
    return <Loader />;
  }

  return (
    <>
      <InvoiceContainer>
        <Title>Invoices</Title>
        <Grid gap="12px">{invoicesDetails()}</Grid>
      </InvoiceContainer>
      <ADialog overflow="auto" open={openDialog} maxwidth="600" padding="16px">
        <Row justify="flex-end">
          <CloseIcon cursor="pointer" onClick={handleCancelButton} />
        </Row>
        {assetInformation()}
        <Divider />
        <Span size="20px" weight="bold">
          Marker Detail
        </Span>
        <Divider />
        <MarkerLogs log={selectedMarkerLog} invoice />

        <Divider />
        <InvoiceEntity
          entities={selectedMarkerLog?.invoice?.materials}
          title="Materials"
          expanded={expanded}
          handleInvoiceEntityClick={handleInvoiceEntityClick}
        />
        <Divider />
        <InvoiceEntity
          entities={selectedMarkerLog?.invoice?.labour}
          expanded={expanded}
          handleInvoiceEntityClick={handleInvoiceEntityClick}
          title="Labors"
        />
        <Divider />
        <InvoiceEntity
          entities={selectedMarkerLog?.invoice?.misc}
          expanded={expanded}
          handleInvoiceEntityClick={handleInvoiceEntityClick}
          title="Miscellaneous"
        />
        <Divider />
        <InvoiceEntity
          entities={selectedMarkerLog?.invoice?.fixedFee}
          expanded={expanded}
          handleInvoiceEntityClick={handleInvoiceEntityClick}
          title="Fixed Fee"
        />
        <Buttons justify="flex-end">
          {!invoiceData ? (
            <Row>
              <Button color="primary" onClick={handleCancelButton}>
                Cancel
              </Button>
              <PrimaryButton
                onClick={handleGeneratePdf}
                loading={isGeneratingPdf}
              >
                {isGeneratingPdf ? (
                  <DataFetchingProgress size={24} />
                ) : (
                  <Span color={color.white}>Generate Pdf</Span>
                )}
              </PrimaryButton>
            </Row>
          ) : (
            <PDFLink
              fileName={`Invoice_${getCurrentDate()}`}
              document={<InvoicePdf invoiceData={invoiceData} />}
            >
              {({ loading }) =>
                loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <PrimaryButton onClick={handleDownloadButton}>
                    Download invoice pdf
                  </PrimaryButton>
                )
              }
            </PDFLink>
          )}
        </Buttons>
      </ADialog>
    </>
  );
};

export default Invoices;

const InvoiceCard = styled.div`
  background-color: ${color.white};
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px ${color.boxShadowColor};
`;

const InvoiceContainer = styled.div`
  margin: 8px 8px 8px 16px;
`;

const ArrowContainer = styled(Span)`
  && {
    background-color: ${color.snomwBlue};
    height: 16px;
    width: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: 4px;
  }
`;

const ButtonContainer = styled(Row)`
  margin-top: 8px;
`;

const StratosfyButton = styled(Button)`
  && {
    background-image: linear-gradient(to right, #00afee, #006eb9);
    border-radius: 4px;
    box-shadow: 0px 0px 4px ${color.boxShadowColor};
    text-transform: none;
  }
`;

const PDFLink = styled(PDFDownloadLink)`
  text-decoration: none;
`;

const Buttons = styled(Row)`
  margin-top: 8px;
`;

const DataFetchingProgress = styled(CircularProgress)`
  && {
    color: ${color.white};
  }
`;
