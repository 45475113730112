import React, { useEffect, useState } from 'react';

import CloseIcon from 'mdi-react/CloseIcon';
import { Divider, IconButton } from '@material-ui/core';

import Span from '../../../Common/Span';
import HistoryTable from './HistoryTable';
import { Progress } from '../../../Generics/snowm_loader';
import { Col, Row } from '../../../../styles/snowm_styled';
import { fetchJobAuditHistory } from '../../../../controllers/snowm_firebase';

const JobHistoryDetails = ({ job, handleCloseClick }) => {
  const [jobAuditHistories, setJobAuditHistories] = useState(null);
  const getJobAuditHistory = async (jobKey) => {
    const res = await fetchJobAuditHistory(jobKey);
    setJobAuditHistories(res);
  };

  useEffect(() => {
    if (job) {
      getJobAuditHistory(job.key);
    }
  }, [job]);

  return (
    <Col gap="8px">
      <Row justify="space-between">
        <Span weight="bold">Audit Job History</Span>
        <IconButton onClick={handleCloseClick}>
          <CloseIcon />
        </IconButton>
      </Row>
      <Divider />
      {!jobAuditHistories ? (
        <Row justify="center">
          <Progress />
        </Row>
      ) : (
        <HistoryTable jobHistories={jobAuditHistories} />
      )}
    </Col>
  );
};

export default JobHistoryDetails;
