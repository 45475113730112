import React from 'react';

import styled from 'styled-components';
import { Card, Divider } from '@material-ui/core';

import Span from '../../Common/Span';
import color from '../../../utils/color';
import { Row } from '../../../styles/snowm_styled';
import ListTitleItem from '../../Generics/list_title_item';

const BeaconItem = ({ beacon, onSelectBeacon }) => (
  <StyledCard onClick={() => onSelectBeacon(beacon)}>
    <Row justify="flex-end">
      <Typography size="16px" weight="bold" color={color.white}>
        {beacon.assigned ? 'ASSIGNED' : 'UNASSIGNED'}
      </Typography>
    </Row>

    <ListTitleItem title="Serial Number" content={beacon?.serialNumber} />

    <ListTitleItem title="Name" content={beacon?.name} />
    <ListTitleItem title="Type" content={beacon?.type ?? 'Sensor'} />
  </StyledCard>
);

const StyledCard = styled(Card)`
  && {
    margin: 6px;
    padding: 1em;
    cursor: pointer;
    border-radius: 10px;
    width: 100%;
  }
`;

const Typography = styled(Span)`
  && {
    background-color: ${color.lightRed};
    padding: 4px;
    border-radius: 6px;
  }
`;

export default BeaconItem;
