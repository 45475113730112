import React from 'react';
import { Text, Flex, Image, Stack } from '@chakra-ui/react';
import EmptyJanitorImage from '../assets/svgs/EmptyJanitorImage.svg';

const NoResultFound = (props) => {
  const { image = EmptyJanitorImage } = props;
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      direction="column"
      opacity={1}
      gridGap="xxl"
      color="text.800"
      h="80%"
      alignContent="center"
      mixBlendMode="luminosity"
    >
      {image && <Image src={image} maxHeight="294px" />}
      <Stack>
        <Text fontWeight={600} fontSize="24px">
          No Results found
        </Text>
        <Text opacity="0.5" as="i" color="color.300" marginTop="16.33px">
          We cannot find any item matching you search
        </Text>
      </Stack>
    </Flex>
  );
};

export default NoResultFound;
