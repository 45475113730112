import React, { ChangeEvent, MutableRefObject, useRef, useState } from 'react';

import {
  Flex,
  Icon,
  Image,
  ResponsiveValue,
  Stack,
  Box,
  Text,
} from '@chakra-ui/react';
import { AiFillCamera } from 'react-icons/ai';
import dummyImage from '../assets/images/dummyImage.png';
import { defaultAvatar } from '../helpers/misc';

interface ImageContainerProps {
  imageURL: string | undefined;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  size?: string;
  externalRef?: MutableRefObject<any>;
  objectFit?: ResponsiveValue<any>;
  profilePic?: boolean;
}

const ImageContainer = (props: ImageContainerProps) => {
  const { imageURL, onChange, size, externalRef, objectFit, profilePic } =
    props;
  const [hover, setHover] = useState(false);
  const ref = useRef<any>();

  function handleClick() {
    if (externalRef) {
      externalRef?.current?.click();
    } else {
      ref?.current?.click();
    }
  }

  return (
    <Flex justifyContent="center">
      <Box
        boxSize={size ?? 300}
        position="relative"
        alignContent="center"
        justifyContent="center"
        onClick={() => handleClick()}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Image
          boxSize={size ?? 300}
          position="absolute"
          alt="Profile Picture"
          onClick={() => handleClick()}
          objectFit={objectFit ?? 'cover'}
          // TODO: dummy image check
          src={imageURL || dummyImage}
          fallbackSrc={defaultAvatar}
        />

        {profilePic && hover && (
          <Flex
            w={size}
            h={size}
            position="absolute"
            alignItems="center"
            margin="0 !important"
            flexDirection="column"
            justifyContent="center"
            background="blackAlpha.500"
          >
            <Icon as={AiFillCamera} color="white" h={25} w={25} />
            <Text color="white" textAlign="center" fontSize="s">
              CHANGE PROFILE PHOTO
            </Text>
          </Flex>
        )}
      </Box>
      <input
        type="file"
        name="image"
        ref={externalRef ?? ref}
        style={{ display: 'none' }}
        onChange={onChange}
      />
    </Flex>
  );
};

export default ImageContainer;
