import React, { useContext, useEffect, useState } from 'react';

import { Redirect, useHistory } from 'react-router';
import roles from '../../enums/roles';
import CompaniesList from './CompaniesList';
import UnAuthenticated from './UnAuthenticated';
import { AuthContext } from '../../contexts/auth_context';
import {
  getAllCompanies,
  getCompanyDetail,
  handleCompanySelection,
} from '../../controllers/snowm_firebase';
import Loader from '../Generics/snowm_loader';

const ChooseCompany = () => {
  const history = useHistory();
  const userDetails = useContext(AuthContext);
  const {
    authenticated,
    setCompanyKey,
    setAuthenticated,
    setUserRoleInCompany,
  } = userDetails;

  const { userClaims } = userDetails?.authenticated ?? {};

  const [companiesInDetail, setCompaniesInDetail] = useState(null);

  const getCompanyDetails = async (claims) => {
    const { super_admin: superAdmin } = claims;
    let { roles: userRoles } = claims;

    let companies;

    if (superAdmin) {
      companies = await getAllCompanies();
      userRoles = companies.map((company) => ({
        role: 'administrator',
        companyKey: company.key,
      }));
    }

    const companiesPromises = userRoles
      .filter((r) => ['administrator', 'customer'].includes(r.role))
      .map(async (roleDetail) => {
        const companyDetails = companies?.find(
          (company) => company.key === roleDetail.companyKey
        );
        const comp =
          companyDetails ?? (await getCompanyDetail(roleDetail.companyKey));
        return { ...comp, userRoleInCompany: roleDetail.role };
      });

    const userCompanies = await Promise.all(companiesPromises);
    setCompaniesInDetail(userCompanies);
  };

  useEffect(() => {
    if (userClaims) {
      getCompanyDetails(userClaims);
    }
  }, [userClaims]);

  const handleCompaniesSelection = (company) => {
    handleCompanySelection({
      companyKey: company.key,
      role: company.userRoleInCompany,
    });
    setCompanyKey(company.key);
    setUserRoleInCompany(company.userRoleInCompany);
    if (company.userRoleInCompany === roles.administrator) {
      // history.push('/admin');
      history.push('/admin');
    } else {
      history.push('/customer');
    }
    setAuthenticated({ ...authenticated, canLogIn: true });
  };

  if (authenticated === null) return <Loader />;

  if (authenticated === false) {
    return <Redirect to="/login" />;
  }

  if (authenticated?.userClaims?.roles?.length === 1) {
    let path = '';
    if (authenticated?.userClaims?.roles?.[0] === roles?.administrator) {
      // path = '/admin';
      path = '/admin';
    } else {
      path = '/customer';
    }
    return <Redirect to={path} />;
  }

  return (
    <UnAuthenticated>
      <CompaniesList
        companiesInDetail={companiesInDetail}
        handleCompanySelection={handleCompaniesSelection}
      />
    </UnAuthenticated>
  );
};

export default ChooseCompany;
