import React, { useEffect, useState } from 'react';

import moment from 'moment';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import ClockIcon from 'mdi-react/ClockIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import ServiceIcon from 'mdi-react/BriefcaseIcon';
import ClockEndIcon from 'mdi-react/ClockEndIcon';
import ClockStartIcon from 'mdi-react/ClockStartIcon';
import AccountHardHatIcon from 'mdi-react/AccountHardHatIcon';
import OrderBoolAscendingVariantIcon from 'mdi-react/OrderBoolAscendingVariantIcon';
import {
  Card,
  Grid,
  Divider,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';

import Span from '../../Common/Span';
import { BoldTitle } from './styled';
import color from '../../../utils/color';
import ADialog from '../../Common/styled';
import { getDateForMarker } from '../../../helpers/date';
import ListTitleItem from '../../Generics/list_title_item';
import { Col, Row, Title } from '../../../styles/snowm_styled';
import MarkerBasicDetail from '../markers/marker_basic_detail';
import {
  getServicePointById,
  getPropertyById,
  getProviderByUid,
} from '../../../../controllers/snowm_firebase';

const MarkerDetailOfAJob = ({ markerKey, markerLog, markerLogDetailPage }) => {
  const [marker, setMarker] = useState();
  const [property, setProperty] = useState();
  const [markerStatus, setMarkerStatus] = useState();
  const [crew, setCrew] = useState();
  const [openActivityModal, setOpenActivityModal] = useState(false);
  const [setOfActivitiesIds, setSetOfActivitiesIds] = useState();

  const history = useHistory();

  useEffect(() => {
    let isSubscribe = true;
    getServicePointById(markerKey).then((res) => {
      if (isSubscribe) setMarker(res);
    });

    return () => {
      isSubscribe = false;
    };
  }, [markerKey]);

  useEffect(() => {
    let isSubscribe = true;
    if (marker?.propertyKey) {
      getPropertyById(marker?.propertyKey).then((res) => {
        if (isSubscribe) setProperty(res);
      });
    }

    return () => {
      isSubscribe = false;
    };
  }, [marker]);

  useEffect(() => {
    if (markerLog?.aborted) {
      setMarkerStatus('ABORTED');
    } else if (markerLog?.working === false) setMarkerStatus('COMPLETED');
    else if (markerLog?.working === true) setMarkerStatus('WORKING');
  }, [markerLog]);

  useEffect(() => {
    let isSubscribe = true;
    if (markerLog?.providerUid) {
      getProviderByUid(markerLog?.providerUid).then((response) => {
        if (isSubscribe) setCrew(response);
      });
    }
    return () => {
      isSubscribe = false;
    };
  }, [markerLog]);

  useEffect(() => {
    const completedActivityIds = markerLog?.activities?.reduce((acc, cur) => {
      if (cur?.completed) return [...acc, cur?.id];
      return acc;
    }, []);

    const newSetOfActivitiesId = new Set([...(completedActivityIds ?? [])]);

    setSetOfActivitiesIds(newSetOfActivitiesId);
  }, [markerLog]);

  function isCompleted(activityLog) {
    return activityLog?.completed === true;
  }

  function getNumberOfCompletedActivities() {
    const completedActivities = markerLog?.activities?.filter(isCompleted);
    return completedActivities?.length ?? 0;
  }

  function getTotalNumberOfActivities() {
    return Math.max(
      getNumberOfCompletedActivities(),
      marker?.activities?.length ?? 0
    );
  }

  function getRatioOfActivities() {
    return `${getNumberOfCompletedActivities()} / ${getTotalNumberOfActivities()}`;
  }

  function handleActivitiesClick(e) {
    e.stopPropagation();
    setOpenActivityModal(true);
  }

  function renderRatioOfCompletedActivities() {
    if (markerLog?.activities?.length > 0) {
      return (
        <ListTitleItem
          onClick={handleActivitiesClick}
          Icon={OrderBoolAscendingVariantIcon}
          content={getRatioOfActivities()}
          title="Activities"
        />
      );
    }
    return null;
  }

  function getMarkerStatusColor() {
    switch (markerStatus) {
      case 'ABORTED':
        return 'error';
      case 'WORKING':
        return color?.green;
      default:
        return 'black';
    }
  }

  function getDate(date) {
    return date ? getDateForMarker(date) : 'Never';
  }

  function handleMarkerDetailClick(e) {
    e.stopPropagation();
    if (markerLog && !markerLogDetailPage) {
      history.push(
        `/customer/jobs/details/${markerLog?.jobKey}/marker/${markerLog?.servicePointId}`
      );
    }
  }

  function checkActivityComplete(activity) {
    return setOfActivitiesIds?.has(activity?.id);
  }

  function handleClose(event) {
    event.stopPropagation();
    setOpenActivityModal(false);
  }

  function getDuration(duration) {
    const minutes = moment.duration(duration).asMinutes();
    return `${Math.floor(minutes)} minutes`;
  }

  const ActivitiesDetail = () => {
    return (
      <ADialog onClose={handleClose} width="300px" open={openActivityModal}>
        <ActivitiesContainer>
          <Row justify="space-between">
            <Title align="center">Activities</Title>
            <CloseIcon cursor="pointer" onClick={handleClose} />
          </Row>
          {markerLog?.activities?.map((activity) => {
            return (
              <Row key={activity.id} justify="space-between">
                <StyledFormControlLabel
                  labelPlacement="start"
                  control={
                    <Checkbox
                      color="primary"
                      checked={checkActivityComplete(activity)}
                    />
                  }
                  label={activity?.name}
                />
              </Row>
            );
          })}
        </ActivitiesContainer>
      </ADialog>
    );
  };

  const OnSiteTime = () => {
    if (markerLog?.onSiteTime) {
      return (
        <ListTitleItem
          Icon={ClockIcon}
          content={getDuration(markerLog?.onSiteTime)}
          title="On-Site Time"
        />
      );
    }
    return null;
  };

  return (
    <>
      <StyledGrid item xs={4}>
        <StyledCard cursor={markerLog ? 'pointer' : 'auto'}>
          <Col>
            {!markerLog ? (
              <MarkerBasicDetail marker={marker} property={property} />
            ) : (
              <StyledCol>
                <Row justify="space-between">
                  <BoldTitle>
                    {marker && (marker?.name ?? 'Marker Deleted')}
                  </BoldTitle>
                  {markerStatus && (
                    <Span weight="bold" color={getMarkerStatusColor()}>
                      {markerStatus}
                    </Span>
                  )}
                </Row>
                <Divider />

                <ListTitleItem
                  Icon={AccountHardHatIcon}
                  content={crew?.name}
                  title="Crew"
                />
                <ListTitleItem
                  Icon={ClockStartIcon}
                  content={getDate(markerLog.startDate)}
                  title="Started At"
                />

                <ListTitleItem
                  Icon={ClockEndIcon}
                  content={getDate(markerLog?.endDate)}
                  title="Completed At"
                />

                {/* <OnSiteTime /> */}

                {/* {renderRatioOfCompletedActivities()} */}
              </StyledCol>
            )}
          </Col>
        </StyledCard>
      </StyledGrid>
      <ActivitiesDetail />
    </>
  );
};

export default MarkerDetailOfAJob;

const StyledCard = styled(Card)`
  padding: 16px;
  min-height: 200px;
  cursor: ${(props) => props.cursor};
`;

const StyledCol = styled(Col)`
  margin-top: 8px;
`;

const StyledGrid = styled(Grid)`
  min-height: 200px;
  && {
    margin-left: 4px;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  justify-content: space-between;
  width: 100%;
`;

const ActivitiesContainer = styled(Col)`
  padding: 12px;
`;
