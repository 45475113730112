/* eslint-disable react/jsx-wrap-multilines */
/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_add_service_points.jsx
 *
 *  Description: Add Service Points
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 8-july-2019
 *
 *
 *********************************************************************************
 */

import React, { useContext, useEffect, useState } from 'react';

import moment from 'moment';
import QRCode from 'qrcode.react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import MomentUtils from '@date-io/moment';
import CloseIcon from 'mdi-react/CloseIcon';
import { RHFInput } from 'react-hook-form-input';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  Flex,
  Text,
  Stack,
  Button,
  Switch,
  Checkbox,
  Collapse,
  useToast,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import {
  Card,
  MenuItem,
  IconButton,
  DialogTitle,
  DialogContent,
  InputAdornment,
} from '@material-ui/core';

import Span from '../../Common/Span';
import BeaconItem from './beacon_item';
import color from '../../../utils/color';
import { StyledFormControlLabel } from '../styled';
import { lowerCase } from '../../../helpers/string';
import { Col, Row } from '../../../styles/snowm_styled';
import PrimaryButton from '../../Common/primary_button';
import ListTitleItem from '../../Generics/list_title_item';
import Modal, { useModal } from '../../Generics/snowm_modal';
import { BeaconMsContext } from '../../../contexts/beaconMs';
import ADialog, { StyledTextField } from '../../Common/styled';
import ActivitiesSelection from '../../Common/ActivitiesSelection';
import { CustomInput } from '../../../reusableComponent/CustomInput';
import { ServiceTypesContext } from '../../../contexts/service_types';
import ImageContainer from '../../../reusableComponent/ImageContainer';
import { ServicePointsContext } from '../../../contexts/service_points';
import { CustomSelect } from '../../../customer/Components/Private/Jobs/custom_select';
import {
  getServices,
  getDataByName,
  getCompanyDetail,
  getBeaconByBeaconId,
  uploadImageToStorage,
  addEditNewServicePoint,
  getPropertiesFromFirestore,
  getRealTimeUnAssignedBeacons,
} from '../../../controllers/snowm_firebase';

const AddServicePoint = ({
  edit,
  marker,
  handleCloseDialog,
  addMicroLocationFromLocation,
  selectedServicePoint,
  setMicroLocations,
  microLocations,
  setSelectedLocation,
  setSelectedMicroLocations,
}) => {
  const types = useContext(ServiceTypesContext);
  const { fetchServicePoints, markers } = useContext(ServicePointsContext);

  const { beaconMs: devicesFromContext, fetchBeaconMs } =
    useContext(BeaconMsContext);

  const sameMarkerErrorMessage = React.useMemo(
    () => 'Marker of this name is already assigned.',
    []
  );

  const subscribedServices = React.useMemo(
    () => types?.subscribedServices ?? {},
    [types]
  );

  const toast = useToast();

  useEffect(() => {
    if (!devicesFromContext) {
      fetchBeaconMs();
    }
  }, []);

  useEffect(() => {
    if (!markers) {
      fetchServicePoints();
    }
  }, []);

  const { register, handleSubmit, watch, setValue, errors } = useForm();

  const errorMessages = React.useMemo(
    () => ({
      nameError: '',
      deviceError: '',
      locationError: '',
      propertyError: '',
      serviceTypeError: '',
    }),
    []
  );

  const initialAssetValues = {
    name: '',
    model: '',
    price: null,
    purchase_date: null,
    sn: '',
    warranty_valid: false,
    validUntil: null,
  };

  const [services, setServices] = useState();
  const [beaconMs, setBeaconMs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [properties, setProperties] = useState();
  const [markerTypes, setMarkerTypes] = useState();
  const [serialNumber, setSerialNumber] = useState();
  const [messages, setMessages] = useState(errorMessages);
  const [serviceFeatures, setServiceFeatures] = useState();
  const [finalCustomData, setFinalCustomData] = useState();
  const [selectedProperty, setSelectedProperty] = useState();
  const [customerDetails, setCustomerDetails] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [customData, setCustomData] = useState(initialAssetValues);
  const [selectedActivities, setSelectedActivities] = useState(null);
  const [openAssetSelection, setOpenAssetSelection] = useState(false);
  const [markerNameCount, setMarkerNameCount] = useState(0);

  const [watchDeviceType, setWatchDeviceType] = useState('');

  const photoInputRef = React.useRef(null);

  const beaconMModal = useModal();
  const locationModal = useModal();

  function getDefaultServiceType() {
    if (services?.length > 1) return 'none';
    return services ? services[0] : 'none';
  }

  const watchImage = watch('image');
  // const watchBeaconId = watch('beaconId', microLocations?.beaconId);
  const watchService = watch('service', getDefaultServiceType());
  const watchBeaconName = watch('beaconName', microLocations?.beaconName);
  const watchMarkerType = watch(
    'markerType',
    microLocations?.markerType ?? 'Area'
  );
  // const watchDeviceType = watch('deviceType', marker?.deviceType ?? 'QR');

  const registerValues = (values) => {
    values.forEach((value) => {
      register({ name: value });
    });
  };

  useEffect(() => {
    setMarkerNameCount(microLocations?.name?.length || 0);
  }, [microLocations]);

  useEffect(() => {
    registerValues([
      'address',
      'longitude',
      'latitude',
      'key',
      'beaconId',
      'image',
      'serviceType',
      'beaconName',
      'markerType',
      'deviceType',
    ]);
  }, []);

  useEffect(() => {
    setValue('address', microLocations?.address || '');
    setValue('longitude', microLocations?.longitude || '');
    setValue('latitude', microLocations?.latitude || '');
    setValue('key', microLocations?.key ?? null);
    setValue('beaconId', microLocations?.beaconId ?? '');
    setValue('beaconName', microLocations?.beaconName ?? '');
    setValue('markerType', microLocations?.markerType ?? 'Area');
    setValue('captureImage', true);

    setSerialNumber(microLocations?.serialNumber ?? '');

    setSelectedCategory(microLocations?.activityType ?? 'none');

    if (microLocations?.customerDetail) {
      setChecked(true);
      setCustomerDetails({ ...microLocations?.customerDetail });
    }

    setSelectedActivities(microLocations?.activities ?? null);

    setCustomData(microLocations?.customData ?? initialAssetValues);
  }, [microLocations]);

  useEffect(() => {
    if (!microLocations?.beaconName && microLocations?.beaconId) {
      getBeaconByBeaconId(microLocations?.beaconId).then((res) => {
        setMicroLocations({ ...microLocations, beaconName: res?.name ?? null });
        setSerialNumber(res?.serialNumber ?? null);
      });
    }
  }, [microLocations]);

  useEffect(() => {
    getCompanyDetail().then((res) => {
      const { markerFeatures } = res;

      setMarkerTypes(markerFeatures?.markerTypes);
    });
  }, []);

  useEffect(() => {
    if (microLocations?.propertyKey) {
      const propertyDetails = properties?.filter(
        (property) => property.id === microLocations.propertyKey
      );

      setSelectedProperty(propertyDetails ? propertyDetails[0] : null);
    }
  }, [properties, microLocations]);

  useEffect(() => {
    let isSubscribe = true;
    getServices().then((res) => {
      const servicesFromFirestore = res.map((d) => d.data()) ?? [];
      if (isSubscribe) setServices(servicesFromFirestore);
    });
    return () => {
      isSubscribe = false;
    };
  }, []);

  useEffect(() => {
    const selectedService = services?.filter(
      (service) => service.key === microLocations?.serviceKey
    );

    const value = selectedService && selectedService[0];
    setValue('service', value ?? 'none');
  }, [services, microLocations]);

  useEffect(() => {
    setValue('service', watchService);
    setServiceFeatures(watchService.serviceFeatures);
  }, [watchService]);

  function getActivityType() {
    if (!serviceFeatures?.activities || serviceFeatures?.activitiesOnJob) {
      return null;
    }
    if (edit && selectedCategory === 'none') {
      return microLocations?.activityType;
    }
    if (selectedCategory === 'none') return null;
    return selectedCategory;
  }

  // function getPropertyKey() {
  //   if (serviceFeatures?.properties) return selectedProperty ?? null;
  //   return null;
  // }
  function getActivities() {
    const { activities, activitiesOnJob } = serviceFeatures;
    if (activities && !activitiesOnJob) {
      return selectedActivities ?? [];
    }
    return [];
  }

  function checkForCustomerLogin() {
    if (watchService !== 'none') {
      return (
        !serviceFeatures?.properties && subscribedServices?.endCustomerLogin
      );
    }
    return false;
  }

  function getCustomerDetail() {
    if (checkForCustomerLogin()) return customerDetails ?? null;
    return null;
  }

  function getCustomData() {
    if (watchMarkerType !== 'Asset') {
      return null;
    }
    return finalCustomData ?? null;
  }

  function getServiceType(data) {
    return data.service?.type?.toLowerCase() ?? watchService?.type;
  }

  function getServiceKey(data) {
    return data.service?.key ?? watchService?.key;
  }

  const checkIfPropertyIsEnabled = () =>
    !!watchService.serviceFeatures?.properties;

  async function onSubmit() {
    if (addMicroLocationFromLocation) {
      setSelectedLocation({});
      setSelectedMicroLocations({});
    }
    if (edit) {
      setSelectedMicroLocations({});
    }
    const data = { ...microLocations };
    let writeToFirestore = false;
    setLoading(true);
    setMessages(errorMessages);

    const trimmedName = data.name.trim();

    const markerWithSameName = await getDataByName(
      'servicePoints',
      trimmedName
    );

    if (
      markerWithSameName &&
      (!edit || (edit && markerWithSameName?.key !== microLocations?.key))
    ) {
      setMessages((prevMessages) => ({
        ...prevMessages,
        nameError: sameMarkerErrorMessage,
      }));
      writeToFirestore = false;
      setLoading(false);
      toast({
        description: 'Marker of this name is already assigned.',
        duration: 3000,
        title: 'Error!',
        status: 'error',
        isClosable: true,
      });
      return;
    }

    if (watchService === 'none') {
      setMessages((prevMessages) => ({
        ...prevMessages,
        serviceTypeError: 'Service Type should be specified.',
      }));
      setLoading(false);
    } else {
      writeToFirestore = true;
    }

    if (writeToFirestore) {
      let profileImageUrl = '';

      if (edit) {
        profileImageUrl =
          watchImage === undefined ? microLocations?.imageUrl : watchImage;
      }
      if (watchImage) {
        profileImageUrl = await uploadImageToStorage(
          watchImage,
          watchImage.name
        );
      }

      const spData = {
        ...data,
        serviceType: getServiceType(data),
        serviceKey: getServiceKey(data),
        longitude: null,
        latitude: null,
        address: checkIfPropertyIsEnabled()
          ? selectedProperty?.address ?? null
          : null,
        imageUrl: profileImageUrl,
        assigned: false,
        lastServed: null,
        serialNumber,
      };

      setMessages(errorMessages);
      try {
        delete spData.image;
        delete spData.service;

        spData.activities = getActivities();
        spData.activityType = getActivityType();
        spData.customerDetail = getCustomerDetail();
        spData.customData = getCustomData();

        const allMicroLocations = Object.values(markers ?? {});

        const doesSameNameExist = allMicroLocations.some(
          (microLocation) =>
            spData.key !== microLocation.key &&
            lowerCase(spData.name) === lowerCase(microLocation.name)
        );

        if (doesSameNameExist) {
          toast({
            description: 'Micro location with same name exists.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          return;
        }

        await addEditNewServicePoint(spData);

        toast({
          description: edit
            ? `Micro-Location ${spData?.name} updated successfully.`
            : `Micro-Location ${spData?.name} created successfully.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
          title: 'Success',
        });
        handleCloseDialog();

        setLoading(false);
      } catch (err) {
        toast({
          description: edit
            ? 'Unable to edit microlocation'
            : 'Unable to create microlocation',
          duration: 3000,
          title: 'Error!',
          status: 'error',
          isClosable: true,
        });
        setLoading(false);
      }
    }
  }

  const onLocationChange = (loc, address) => {
    setValue('address', address);
    setValue('longitude', loc.lng);
    setValue('latitude', loc.lat);

    locationModal.close();
  };

  useEffect(() => {
    const selectedDevice = devicesFromContext?.[microLocations?.beaconId];

    setWatchDeviceType(selectedDevice?.type ?? 'sensor');
  }, [microLocations?.beaconId, devicesFromContext]);

  useEffect(() => {
    const unsubscribeRealTimeBeacons = getRealTimeUnAssignedBeacons(
      (beacons) => {
        setBeaconMs(beacons);
      }
    );
    return () => {
      unsubscribeRealTimeBeacons();
    };
  }, []);

  useEffect(() => {
    let isSubscribe = true;
    function _updateProperties(response = []) {
      if (isSubscribe) setProperties(response);
    }

    if (serviceFeatures?.properties) {
      getPropertiesFromFirestore(_updateProperties);
    }

    return () => {
      isSubscribe = false;
    };
  }, [serviceFeatures]);

  function openBrowseWindow() {
    photoInputRef.current.click();
  }

  function handleBeaconSelection(beaconM) {
    setValue('beaconId', beaconM?.uuid);
    setValue('deviceType', beaconM?.type);
    setValue('beaconName', beaconM?.name);
    setSerialNumber(beaconM?.serialNumber?.toString() ?? '');
    beaconMModal.close();
  }

  const BeaconMModal = () => (
    <Modal {...beaconMModal.props}>
      <ModalCard>
        <BeaconContainer align="center" justify="center">
          <StyledRow justify="space-between">
            <Span weight="bold" size="16px">
              Add Device
            </Span>
            <IconButton onClick={() => beaconMModal.close()}>
              <CloseIcon />
            </IconButton>
          </StyledRow>
          {beaconMs.length > 0 ? (
            beaconMs.map((beaconM) => (
              <BeaconItem
                key={beaconM.uuid}
                beacon={beaconM}
                onSelectBeacon={() => handleBeaconSelection(beaconM)}
              />
            ))
          ) : (
            <Span> No devices available</Span>
          )}
        </BeaconContainer>
      </ModalCard>
    </Modal>
  );

  function handleSwitchChange() {
    setChecked(!checked);
  }

  function handleEmailChange(event) {
    event.persist();

    setCustomerDetails(() => ({
      ...customerDetails,
      email: event.target.value,
    }));
  }

  function handleNameChange(event) {
    event.persist();

    setCustomerDetails(() => ({
      ...customerDetails,
      name: event.target.value,
    }));
  }

  function handlePropertiesChange(selectedLocation) {
    // setSelectedProperty(selectedLocation.value);
    setMicroLocations({
      ...microLocations,
      propertyKey: selectedLocation.value,
    });

    setMessages((prevData) => ({
      ...prevData,
      propertyError: '',
    }));
  }

  function handleSelectedActivities(activitiesSelected, categorySelected) {
    setSelectedActivities(activitiesSelected ?? []);
    setSelectedCategory(categorySelected ?? 'none');
  }

  function handleAddAssetBtn() {
    setCustomData({ ...(finalCustomData ?? initialAssetValues) });
    setOpenAssetSelection(true);
  }

  const markerTypeSelection = () => {
    if (
      !markerTypes ||
      (markerTypes?.length === 1 && markerTypes?.includes('Area'))
    ) {
      return null;
    }

    return (
      <>
        <RHFInput
          as={<StyledTextField rightmargin="8px" select />}
          required
          fullWidth
          variant="outlined"
          label="Marker Type"
          name="markerType"
          register={register}
          setValue={setValue}
          defaultValue={watchMarkerType}
        >
          {markerTypes?.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </RHFInput>
        {watchMarkerType === 'Asset' && (
          <Col width="100%">
            {finalCustomData && (
              <>
                <ListTitleItem
                  title="Serial Number"
                  content={finalCustomData.sn ?? '-'}
                  size="14px"
                />
                <ListTitleItem
                  title="Model"
                  content={finalCustomData.model ?? '-'}
                  size="14px"
                />
                <ListTitleItem
                  title="Price"
                  content={finalCustomData.price ?? '-'}
                  size="14px"
                />
                <ListTitleItem
                  title="Purchase date"
                  content={moment(finalCustomData.purchase_date).format(
                    'YYYY-MMM-DD'
                  )}
                  size="14px"
                />
                <ListTitleItem
                  title="Warranty valid"
                  content={finalCustomData.warranty_valid ? 'Yes' : 'No'}
                  size="14px"
                />
                {customData.warranty_valid && (
                  <ListTitleItem
                    title="Warranty upto"
                    content={moment(finalCustomData.validUntil).format(
                      'YYYY-MMM-DD'
                    )}
                    size="14px"
                  />
                )}
              </>
            )}

            <Row justify="center">
              <PrimaryButton onClick={handleAddAssetBtn}>
                <Span color={color.white}>
                  {customData?.model ? 'Edit ' : 'Add '}
                  Marker Asset
                </Span>
              </PrimaryButton>
            </Row>
          </Col>
        )}
      </>
    );
  };

  function handleChange(string, event) {
    event.stopPropagation();
    setCustomData({
      ...customData,
      [string]: event.target.value,
    });
  }

  function handleDateChange(string, e) {
    const timestamp = moment(e._d).valueOf();
    setCustomData((prevState) => ({
      ...prevState,
      [string]: timestamp,
    }));
  }

  function handleValidChange(e) {
    setCustomData({
      ...customData,
      [e.target.name]: e.target.checked,
    });
  }

  function handleAddData(e) {
    e.preventDefault();
    const {
      purchase_date: purchaseDate,
      warranty_valid: warrantyValid,
      validUntil,
    } = customData;

    if (!purchaseDate || (warrantyValid && !validUntil)) {
      alert('Please specify date.');
      return;
    }
    setOpenAssetSelection(false);
    setFinalCustomData({ ...customData });
  }

  function handleAssetBoxClose() {
    setOpenAssetSelection(false);
  }

  const assetTypeMarkerForm = () => {
    if (watchMarkerType !== 'Asset') {
      return null;
    }

    return (
      <ADialog
        open={openAssetSelection}
        onClose={handleAssetBoxClose}
        padding="16px"
      >
        <DialogTitle>Add Marker Asset</DialogTitle>
        <form onSubmit={handleAddData}>
          <DialogContent>
            <StyledTextField
              fullWidth
              variant="outlined"
              label="Model"
              type="text"
              onChange={(e) => handleChange('model', e)}
              value={customData?.model}
              required
            />

            <StyledTextField
              fullWidth
              variant="outlined"
              label="Serial Number"
              type="text"
              onChange={(e) => handleChange('sn', e)}
              value={customData?.sn}
              required
            />

            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                style={{ margin: '12px 0', width: '100%' }}
                value={
                  customData?.purchase_date
                    ? moment(customData?.purchase_date)
                    : null
                }
                onChange={(momentObject) =>
                  handleDateChange('purchase_date', momentObject)
                }
                label="Purchase Date"
                format="YYYY-MMM-DD"
                required
              />
            </MuiPickersUtilsProvider>

            <StyledTextField
              fullWidth
              variant="outlined"
              label="Price"
              type="number"
              InputProps={{
                startAdornment: <InputAdornment>$</InputAdornment>,
              }}
              onChange={(e) => handleChange('price', e)}
              value={customData?.price}
              required
            />

            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={customData?.warranty_valid ?? null}
                  onChange={handleValidChange}
                  name="warranty_valid"
                  color="primary"
                />
              }
              label="Warranty Upto"
              labelPlacement="start"
            />

            {customData?.warranty_valid && (
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  style={{ margin: '12px 0', width: '100%' }}
                  value={customData?.validUntil}
                  onChange={(momentObject) =>
                    handleDateChange('validUntil', momentObject)
                  }
                  label="Valid Date"
                  format="YYYY-MMM-DD"
                  required
                  disablePast
                  minDate={customData?.purchase_date}
                />
              </MuiPickersUtilsProvider>
            )}
          </DialogContent>

          <Row justify="flex-end">
            <Button
              color="primary"
              onClick={handleAssetBoxClose}
              variant="outline"
            >
              Cancel
            </Button>
            <PrimaryButton type="submit">Add data</PrimaryButton>
          </Row>
        </form>
      </ADialog>
    );
  };

  useEffect(() => {
    setMarkerNameCount((microLocations?.name ?? '').length);
  }, [microLocations?.name]);

  useEffect(() => {
    if (addMicroLocationFromLocation) {
      setMicroLocations({
        ...microLocations,
        propertyKey: selectedServicePoint?.id,
      });
    }
  }, [selectedServicePoint]);

  function optionForSelect(p, selection) {
    return p?.reduce((a, c) => {
      if (selection === 'location') {
        const option = {
          label: c.name,
          value: c.id,
        };
        a.push(option);
      }

      if (selection === 'device') {
        const option = {
          label: `${c.name} ( ${c.type ?? 'sensor'} )`,
          value: c.uuid,
        };
        a.push(option);
      }

      return a;
    }, []);
  }

  const handleMicroLocationInput = (event) => {
    const { value, name } = event.target;
    setMicroLocations({
      ...microLocations,
      [name]: value,
    });
  };

  const selectLocation = (selectedLocation) => {
    setMicroLocations({
      ...microLocations,
      propertyKey: selectedLocation.value,
    });
  };

  const onSlectDevice = (selectedDevice) => {
    setMicroLocations({
      ...microLocations,
      beaconId: selectedDevice.value,
    });
  };

  const validateName = () => {
    if (!microLocations?.name) {
      return 'MicroLocation name is required';
    }

    return null;
  };

  const validateDevice = () => {
    if (!microLocations?.beaconId) {
      return 'Device is required.';
    }
    return null;
  };

  const validateLocation = () => {
    if (serviceFeatures?.properties && !microLocations.propertyKey) {
      return 'Location is required.';
    }
    return null;
  };

  const getValueForDevice = React.useCallback(() => {
    const selectedDevice = devicesFromContext?.[microLocations.beaconId];

    if (!selectedDevice) {
      return null;
    }

    return {
      label: `${selectedDevice?.name} ( ${selectedDevice?.type ?? 'sensor'} )`,
      value: selectedDevice?.uuid,
    };
  }, [devicesFromContext, microLocations]);

  const getValueForLocation = (locations) => {
    const selectedLocation = locations?.find(
      (location) => location.id === microLocations?.propertyKey
    );

    if (selectedLocation) {
      return {
        label: selectedLocation?.name,
        value: selectedLocation?.id,
      };
    }

    return null;
  };

  function handleCaptureImageEnablingChange(captureImage) {
    setMicroLocations({
      ...microLocations,
      captureImage: captureImage.target.checked,
    });
  }

  const handleRemoveImage = () => {
    setValue('image', null);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 30 }}>
      <Stack spacing="5">
        <>
          {serviceFeatures?.properties && (
            <CustomInput
              type="children"
              isDisabled={edit || addMicroLocationFromLocation}
              error={errors?.location}
              placeholder="Select Location*"
              value={microLocations.propertyKey}
              errorMessage={errors?.location?.message}
              register={{
                ...register('location', {
                  validate: validateLocation,
                }),
              }}
            >
              <CustomSelect
                isDisabled={edit || addMicroLocationFromLocation}
                placeholder="Select Location*"
                onChange={handlePropertiesChange}
                value={getValueForLocation(properties)}
                options={optionForSelect(properties, 'location')}
              />
            </CustomInput>
          )}
        </>
        <h4
          style={{
            font: 'normal normal 600 22px/27px Montserrat',
            color: ' #323A45',
          }}
        >
          Details
        </h4>
        {/* skdon */}
        <NewImage
          setValue={setValue}
          openBrowseWindow={openBrowseWindow}
          photoInputRef={photoInputRef}
          watchImage={watchImage}
          microLocations={microLocations}
          handleRemoveImage={handleRemoveImage}
        />
        <CustomInput
          type="text"
          name="name"
          error={errors?.name}
          value={microLocations?.name}
          placeholder="MicroLocation Name *"
          onChange={handleMicroLocationInput}
          errorMessage={errors?.name?.message}
          register={{
            ...register('name', {
              validate: validateName,
            }),
          }}
        />
        <Flex justify="flex-end">
          {markerNameCount}
          /50
        </Flex>
        {markerTypeSelection()}
        <CustomInput
          placeholder="Remarks"
          value={microLocations?.note}
          name="note"
          type="text"
          onChange={handleMicroLocationInput}
          defaultValue={microLocations?.note}
        />
        {services?.length > 1 && (
          <CustomInput
            type="children"
            value="Services"
            placeholder="Select Service"
          >
            <CustomSelect options={optionForSelect(services, 'service')} />
          </CustomInput>
        )}
        <ActivitiesSelection
          serviceFeatures={serviceFeatures}
          handleSelectedActivities={handleSelectedActivities}
          marker={microLocations}
        />
        <CustomInput
          type="children"
          error={errors?.device}
          placeholder="Select Device*"
          value={microLocations?.beaconId}
          errorMessage={errors?.device?.message}
          register={{
            ...register('device', {
              validate: validateDevice,
            }),
          }}
        >
          <CustomSelect
            onChange={onSlectDevice}
            value={getValueForDevice()}
            placeholder="Select Device*"
            options={optionForSelect(beaconMs, 'device')}
          />
        </CustomInput>
        {watchDeviceType === 'QR' && (
          <Flex justify="center">
            <QRCode
              value={microLocations?.beaconId}
              size={150}
              fgColor="#29abe2"
            />
          </Flex>
        )}

        <Flex>
          {/* <img src={DeviceIcon} alt="Device Icon" height="30px" width="20px" /> */}
          {/* <Button
            color="primary"
            variant="outlined"
            onClick={() => beaconMModal.open()}
          >
            {!watchBeaconId ? (
              'SELECT DEVICE'
            ) : (
              <Stack>
                <Text color="primary" align="left">
                  Device:
                </Text>
                {watchBeaconName && (
                  <Flex justify="space-between">
                    <Text size="16px" color="primary">
                      {watchBeaconName}
                    </Text>
                    <Text
                      weight="bold"
                      size="16px"
                      color="primary"
                      align="left"
                    >
                      {serialNumber}
                    </Text>
                  </Flex>
                )}
              </Stack>
            )}
          </OutlinedButton> */}
        </Flex>

        {watchService && watchService.type?.toLowerCase() !== 'attendance' && (
          <FormControl
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <FormLabel htmlFor="allow-capture" mb="0" cursor="pointer">
              Allow to capture images at this micro-location during shifts
            </FormLabel>
            <Switch
              id="allow-capture"
              isChecked={microLocations?.captureImage}
              onChange={handleCaptureImageEnablingChange}
            />
          </FormControl>
        )}

        {checkForCustomerLogin() && (
          <>
            <Flex justify="space-between">
              <Text>Enable Customer Login</Text>
              <Switch onChange={handleSwitchChange} isChecked={checked} />
            </Flex>
            <Collapse in={checked} unmountOnExit>
              <Stack>
                <CustomInput
                  onChange={handleNameChange}
                  value={customerDetails?.name}
                  placeholder="Customer Name"
                  type="text"
                />
                <CustomInput
                  onChange={handleEmailChange}
                  placeholder="Customer Email"
                  type="email"
                  value={customerDetails?.email}
                />
              </Stack>
            </Collapse>
          </>
        )}
        <Flex justify="flex-end" gridGap="md">
          <Button
            variant="outline"
            colorScheme="blue"
            isDisabled={loading}
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            isLoading={loading}
            colorScheme="blue"
            background="#006DB8 0% 0%"
            boxShadow=" 0px 3px 6px #006DB85C"
            borderRadius="5px"
          >
            {edit ? 'Update' : 'Add Micro-Location'}
          </Button>
        </Flex>
        <BeaconMModal />
        {assetTypeMarkerForm()}
      </Stack>
    </form>
  );
};
const NewImage = ({
  setValue,
  openBrowseWindow,
  photoInputRef,
  watchImage,
  microLocations,
  handleRemoveImage,
}) => {
  const [imageUrl, setImageUrl] = useState();
  const [removeImage, setRemoveImage] = useState(false);

  const handleImageChange = (e) => {
    if (e.target.files.length !== 0) {
      const file = e.target.files[e.target.files.length - 1];
      setValue('image', file);
    }
  };

  useEffect(() => {
    if (!imageUrl || imageUrl == null || watchImage) {
      setImageUrl(
        watchImage ? URL.createObjectURL(watchImage) : microLocations?.imageUrl
      );
    } else setImageUrl(null);
  }, [watchImage, removeImage]);

  const getImageURL = () => {
    if (!removeImage) {
      return imageUrl;
    }
    return null;
  };
  return (
    <Flex gridGap="8" align="center" flexWrap="wrap">
      <ImageContainer
        size="150"
        onPress={openBrowseWindow}
        externalRef={photoInputRef}
        onChange={(e) => handleImageChange(e)}
        imageURL={getImageURL()}
      />

      <Flex gridGap="sm">
        <Button
          variant="outline"
          colorScheme="blue"
          onClick={() => {
            setRemoveImage(false);

            photoInputRef?.current?.click();
          }}
        >
          Choose Image
        </Button>
        {(watchImage || microLocations?.imageUrl) && (
          <Button
            variant="outline"
            colorScheme="blue"
            onClick={() => {
              handleRemoveImage();
              setRemoveImage(true);
            }}
          >
            Remove Image
          </Button>
        )}
      </Flex>
    </Flex>
  );
};

const ModalCard = styled(Card)`
  &&&& {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: scroll;
    min-height: 30%;
    max-height: 60%;
    width: 40%;
    padding: 12px;
    background-color: ${color.whiteSmoke};
  }
`;

const BeaconContainer = styled(Col)`
  padding: 16px;
`;

const StyledRow = styled(Row)`
  margin: 8px;
`;

export default AddServicePoint;
