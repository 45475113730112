import { Flex, Text } from '@chakra-ui/layout';
import { useToast, Button, Divider, Box } from '@chakra-ui/react';
import countriesInfo from 'countries-information';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useHistory } from 'react-router';
import Select from 'react-select';
import { AuthContext } from '../../../contexts/auth_context';
import {
  auth,
  checkIfPhoneNumberValid,
  sendOTPToPhoneNumber,
  signInWithPhoneNumber,
} from '../../../controllers/snowm_firebase';
import { CustomButton } from '../../../reusableComponent/CustomButton';
import { SInput } from '../SInput';
import {
  LoginMethod,
  LoginMethodType,
  LoginToggleButton,
  NextButton,
} from './Email';
import { VerifyCode } from './VerifyCode';

interface PhoneInterface {
  handleLogin: (_: LoginMethodType) => void;
}

export interface NewUserCheck {
  newUser: boolean | null;
}

interface SelectOption {
  label: string;
  value: string;
}

export const PhoneNumber = (props: PhoneInterface) => {
  const { handleLogin } = props;
  const [phoneNumber, setPhoneNumber] = useState('');

  const [smsCodeError, setSmsCodeError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [otpSuccessResult, setOtpSuccessResult] = useState<any>(null); // TODO: change type

  const [phoneNumberWithCountryCode, setPhoneNumberWithCountryCode] = useState<
    null | string
  >(null);

  const history = useHistory();
  const toast = useToast();
  const [fetching, setFetching] = useState(false);

  const [smsCode, setSMSCode] = useState<null | string>(null);
  const [isSendingOTPAgain, setIsSendingOTPAgain] = useState(false);

  const authContext = useContext(AuthContext);

  const {
    register,
    // formState: { errors },
  } = useForm<any>({
    defaultValues: {},
  });

  const allCountries = countriesInfo.getAllCountries();

  const countrySelectOptions = allCountries.reduce((all, country) => {
    const countryCode = country.countryCallingCodes[0] ?? '';
    if (!countryCode || !country.emoji) {
      return all;
    }
    all.push({
      value: countryCode,
      label: `${country.emoji ?? ''} ${country.name} (${countryCode})`,
    });
    return all;
  }, []);

  const defaultCountry = countrySelectOptions?.find(
    (country) => country.value === '+1'
  );

  const [selectedCountryCode, setSelectedCountryCode] =
    useState<SelectOption | null>(defaultCountry);

  const handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { value } = event.target ?? {};
    setPhoneNumber(value);
  };

  const { setAuthenticated, setCompanyKey } = authContext;

  const handleOTPSending = async () => {
    if (!phoneNumberWithCountryCode) {
      toast({
        duration: 3000,
        status: 'error',
        description: 'Please select country code.',
      });
      return;
    }
    try {
      const confimrationResult = await sendOTPToPhoneNumber(
        phoneNumberWithCountryCode
      );
      setOtpSuccessResult(confimrationResult);
    } catch (err) {
      switch (err.code) {
        case 'auth/too-many-requests':
          toast({
            duration: 3000,
            status: 'error',
            description:
              'All requests block due to unusual activity. Please try again later.',
          });
          break;

        default:
          toast({
            duration: 3000,
            status: 'error',
            description: 'Cannot send OTP.',
          });
          break;
      }
      console.error('optError', err);
      setFetching(false);
    }
  };

  const sendOtpCodeAgain = async () => {
    setIsSendingOTPAgain(true);
    await handleOTPSending();
    setIsSendingOTPAgain(false);
  };

  useEffect(() => {
    if (phoneNumberWithCountryCode) {
      checkIfPhoneNumberValid(phoneNumberWithCountryCode ?? '').then((res) => {
        const { data } = res;
        const { roles, validPhone } = data ?? {};
        const isOtherUser =
          !roles?.some((role) => role.role === 'administrator') &&
          !roles?.some((role) => role.role === 'customer');

        if (validPhone && !isOtherUser) {
          handleOTPSending()
            .then(() => {
              setFetching(false);
            })
            .catch();
        } else if (!validPhone) {
          toast({
            duration: 3000,
            status: 'error',
            description: `${phoneNumberWithCountryCode} is not registered on our system. Consult your admin.`,
          });

          setFetching(false);
        } else if (isOtherUser) {
          toast({
            duration: 3000,
            status: 'error',
            description: `${phoneNumberWithCountryCode}  is not allowed to login in marker admin app.`,
          });
          setFetching(false);
        }
      });
    }
  }, [phoneNumberWithCountryCode]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.recaptchaVerifier = new auth.RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
      }
    );
  }, []);

  const handleSignIn = async () => {
    try {
      const res = await signInWithPhoneNumber(otpSuccessResult, smsCode);
      if (res.pathname === '/admin') {
        setCompanyKey(res.keyOfCompany);
        setAuthenticated({ userClaims: res.data, canLogIn: true });
        history.push(res.pathname);
        return;
      }
      history.push(res.pathname);
    } catch (e) {
      switch (e.code) {
        case 'auth/invalid-verification-code':
          toast({
            duration: 3000,
            status: 'error',
            description: `Invalid verificaiton code.`,
          });
          break;

        default:
          break;
      }
      console.error(e);
      setFetching(false);
    }
  };

  function validatePhoneNumber() {
    if (!selectedCountryCode?.value) return 'Please select country.';
    if (!phoneNumber) return 'Please enter phone number';
    if (phoneNumber?.length !== 10 && !isValidPhoneNumber(phoneNumber)) {
      return 'Invalid phone number.';
    }
    return null;
  }

  const handleSendOTPButton = async (event) => {
    event.preventDefault();
    if (phoneNumber?.length < 10) {
      setPhoneNumberError('Phone number must be exactly 10 digits.');
      return;
    }

    setFetching(true);
    if (!otpSuccessResult) {
      setPhoneNumberWithCountryCode(
        `${selectedCountryCode?.value}${phoneNumber}`
      );
    } else {
      if ((smsCode?.length ?? 0) < 6) {
        setSmsCodeError('SMS code must be exactly 6 digits.');
        setFetching(false);
        return;
      }
      handleSignIn();
    }
  };

  const handleCodeChange = (code: string) => {
    setSMSCode(code);
  };

  const renderPhoneAndOTPScreen = () => {
    if (otpSuccessResult) {
      return (
        <VerifyCode
          handleSendOTPButton={handleSendOTPButton}
          handleCodeChange={handleCodeChange}
          sendOtpCodeAgain={sendOtpCodeAgain}
          fetching={fetching}
          isSendingOTPAgain={isSendingOTPAgain}
          otpSuccessResult={otpSuccessResult}
        />
      );
    }

    return (
      <form
        onSubmit={handleSendOTPButton}
        style={{
          width: '100%',
        }}
      >
        <Flex
          flexDirection="column"
          width="100%"
          justifyContent="space-between"
          gridGap="1.5rem"
        >
          <Flex gridGap="2" alignItems={phoneNumberError ? 'top' : 'center'}>
            <Select
              options={countrySelectOptions}
              styles={{
                menu: (provided, state) => ({
                  ...provided,
                  width: 400,
                  background: 'white',
                }),
                control: () => ({
                  width: 100,
                  display: 'flex',
                  border: 'solid 1px #ECEEF5',
                  borderRadius: 5,
                  height: '51px',
                }),
              }}
              value={selectedCountryCode}
              onChange={(selectedValue) =>
                setSelectedCountryCode(selectedValue)
              }
            />
            <SInput
              type="text"
              name="phoneNumber"
              placeholder="Enter Phone Number *"
              value={phoneNumber}
              onChange={(e) => handleInputChange(e)}
              // register={{
              //   ...register('phoneNumber', {
              //     validate: validatePhoneNumber,
              //   }),
              // }}
              height="51px"
              error={!!phoneNumberError}
              errorMessage={phoneNumberError}
              countryCode={selectedCountryCode?.value}
            />
          </Flex>
          <Flex
            flexDirection="column"
            width="100%"
            marginTop="20px"
            gridGap="1.5rem"
          >
            <NextButton text="Next" loading={fetching} />
            <div style={{ textAlign: 'center' }}>or</div>
            <LoginToggleButton
              text=" Log In Using Email"
              shouldDisableButton={fetching}
              handleClick={() => handleLogin(LoginMethod.Email)}
            />
          </Flex>
        </Flex>
      </form>
    );
  };

  return (
    <>
      <div id="recaptcha-container" />
      {renderPhoneAndOTPScreen()}
    </>
  );
};
