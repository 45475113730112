import React, { useContext } from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import Locations from './Locations';
import PropertyMarkers from './LocationMarkers';
import { ServiceTypesContext } from '../../../contexts/service_types';
import Loader from '../../Generics/snowm_loader';

const LocationRoutes = () => {
  const services = useContext(ServiceTypesContext);
  const subscribedServices = services?.subscribedServices;

  if (!subscribedServices) {
    return <Loader />;
  }

  if (!subscribedServices.properties) {
    return <Redirect to="/admin" />;
  }

  return (
    <Switch>
      <Route path="/admin/locations/:key" component={PropertyMarkers} />
      <Route path="/admin/locations" component={Locations} />
    </Switch>
  );
};

export default LocationRoutes;
