import React from 'react';

import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from '@david.kucsai/react-pdf-table';

import color from '../../../utils/color';
import { RowData } from '../../Generics/pdf_utils';
import getAmountsOfAEntity from './invoiceCalculation';
import { getCurrentDate } from '../../../helpers/date';

const invoiceStyles = StyleSheet.create({
  title: {
    color: color.snomwBlue,
  },
  header: {
    fontSize: 30,
    textAlign: 'center',
    width: '100%',
  },
  content: {
    fontSize: 12,
  },
  contentHeader: {
    padding: '4px 0px ',
    backgroundColor: color.greyLight,
  },
  contentBody: {
    paddingTop: 4,
  },
  lineItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const TextData = ({ text, makeBold }) => (
  <Text
    style={{
      ...invoiceStyles.content,
      fontWeight: makeBold && 'bold',
      fontFamily: makeBold && 'Helvetica-Bold',
    }}
  >
    {text}
  </Text>
);

const TableData = ({ data, title }) => {
  function getString(param) {
    return param?.toString();
  }

  function getTotal() {
    const totalOfAEntity = data?.reduce((acc, current) => {
      const amounts = getAmountsOfAEntity(current);
      const { total } = amounts;
      return acc + total;
    }, 0);
    return totalOfAEntity?.toFixed(2);
  }

  return (
    <View
      style={{
        marginTop: 16,
      }}
    >
      <Table data={data}>
        <TableHeader fontSize="12px" textAlign="center">
          <TableCell style={invoiceStyles.contentHeader} weighting={0.6}>
            {title}
          </TableCell>
          <TableCell style={invoiceStyles.contentHeader} weighting={0.4}>
            Quantity
          </TableCell>
          <TableCell style={invoiceStyles.contentHeader} weighting={0.4}>
            Unit Price
          </TableCell>
          <TableCell style={invoiceStyles.contentHeader} weighting={0.4}>
            Discount %
          </TableCell>
          <TableCell style={invoiceStyles.contentHeader} weighting={0.4}>
            Total
          </TableCell>
        </TableHeader>
        <TableBody textAlign="center">
          <DataTableCell
            style={{
              textAlign: 'left',
            }}
            weighting={0.6}
            getContent={(r) => (
              <View
                style={{
                  ...invoiceStyles.contentBody,
                  paddingLeft: 2,
                }}
              >
                <TextData text={r.title} makeBold />
                <TextData text={r.description} />
              </View>
            )}
          />

          <DataTableCell
            weighting={0.4}
            getContent={(r) => getString(r.quantity)}
            style={invoiceStyles.contentBody}
          />
          <DataTableCell
            weighting={0.4}
            textAlign="center"
            getContent={(r) => `$ ${r.unitPrice}`}
            style={invoiceStyles.contentBody}
          />
          <DataTableCell
            weighting={0.4}
            textAlign="center"
            getContent={(r) => getString(r.discountPercentage?.toFixed(2))}
            style={invoiceStyles.contentBody}
          />
          <DataTableCell
            weighting={0.4}
            textAlign="center"
            getContent={() => `$ ${getTotal()?.toString()}`}
            style={invoiceStyles.contentBody}
          />
        </TableBody>
      </Table>
    </View>
  );
};

const RenderTable = ({ data, header }) => {
  if (!data?.length) {
    return null;
  }

  return <TableData data={data} title={header} />;
};

const InvoicePdf = ({ invoiceData }) => {
  const { servicedBy, serviceAddress, signature, invoice, currentUser, crew } =
    invoiceData;

  const { materials, labour, misc, fixedFee, totalsOfAll, comment } =
    invoice ?? {};

  function getSubTotal() {
    return totalsOfAll?.subTotal?.toFixed() ?? 0.0;
  }

  function getTaxValue() {
    return totalsOfAll?.tax?.toFixed(2) ?? 0.0;
  }

  function getTotal() {
    return totalsOfAll?.total?.toFixed(2) ?? 0.0;
  }
  return (
    <Document>
      <Page>
        <View
          style={{
            padding: 24,
          }}
        >
          <View style={invoiceStyles.header}>
            <Text style={invoiceStyles.title}>Invoice</Text>
          </View>
          <View style={invoiceStyles.lineItem}>
            <View style={{ marginTop: 24 }}>
              <TextData text="Serviced By" makeBold />
              <TextData text={servicedBy?.name} />
              <TextData text={servicedBy?.address} />
              <TextData text={servicedBy?.email} />
              <TextData text={servicedBy?.phoneNumber} />
            </View>
            <View style={{ marginTop: 16 }}>
              <TextData text="Service Address" makeBold />
              <TextData text={serviceAddress?.name} />
              <TextData text={serviceAddress?.address} />
              <TextData text={serviceAddress?.email} />
              <TextData text={serviceAddress?.phoneNumber} />
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 16,
            }}
          >
            <RowData titleText="Work completed by: " valueText={crew?.name} />
            <RowData
              titleText="Invoice generated by: "
              valueText={currentUser}
            />
          </View>
          <View style={{ marginTop: 16, marginBottom: 8 }}>
            <TextData text="Items" makeBold />
          </View>
          <RenderTable data={materials} header="Materials" />
          <RenderTable data={labour} header="Labors" />
          <RenderTable data={misc} header="Miscellaneous" />
          <RenderTable data={fixedFee} header="Fixed fees" />

          <View
            style={{
              marginTop: 16,
            }}
          >
            <View style={{ flexDirection: 'row' }}>
              <TextData text="Comment: " />
              <TextData text={comment} makeBold />
            </View>
            <View style={invoiceStyles.lineItem}>
              <TextData text="Service Charge 2% per month on overdue accounts." />
              <TextData text={`Sub Total: ${getSubTotal()} `} makeBold />
            </View>
            <View
              style={{
                ...invoiceStyles.lineItem,
                marginTop: 8,
              }}
            >
              <TextData text="We also accept VISA and MASTERCARD" />
              <TextData text={`HST (13%): ${getTaxValue()} `} makeBold />
            </View>
            <View style={{ ...invoiceStyles.lineItem, marginTop: 8 }}>
              <View>
                <TextData text="All parts and material are considered leased until paid for in full." />
                <TextData text="Minimum Total Charge: 1.5 hour" />
              </View>
              <Text
                style={{
                  fontFamily: 'Helvetica-Bold',
                  fontSize: 16,
                }}
                text={`Total: ${getTotal()}`}
                makeBold
              >
                {`Total: ${getTotal()}`}
              </Text>
            </View>
            <View style={{ ...invoiceStyles.lineItem, marginTop: 24 }}>
              <Image
                src={signature}
                style={{
                  width: '25vw',
                  objectFit: 'contain',
                }}
              />
              <TextData text={getCurrentDate()} />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePdf;
