import algoliasearch from 'algoliasearch/lite';

import { getCompanyKey } from './snowm_firebase';

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY
);

const getItemsFromAlgolia = async (inputValue, indexName) => {
  searchClient.clearCache();
  const index = searchClient.initIndex(indexName);
  const { hits } = await index.search(inputValue);
  const companyKey = getCompanyKey();
  const itemsOfACompany = hits.filter(item => {
    return item.companyKey === companyKey;
  });

  return itemsOfACompany;
};

export default getItemsFromAlgolia;
