import {
  Box,
  Divider,
  Flex,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import Chart from 'chart.js';
import InformationIcon from 'mdi-react/InformationIcon';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as BatteryRed } from '../../../assets/svgs/battery-20.svg';
import { ReactComponent as BatteryGreen } from '../../../assets/svgs/battery80.svg';
import { ReactComponent as MarkerI } from '../../../assets/svgs/Markeri.svg';
import { ReactComponent as BatteryCritical } from '../../../assets/svgs/materialerror.svg';
import { getDeviceTemperature } from '../../../controllers/api';
import {
  getPropertyById,
  getServiceInfo,
  getServicePointById,
} from '../../../controllers/snowm_firebase';
import { getRelativeDate } from '../../../helpers/date';
import ListTitleItem from '../../Generics/list_title_item';

const batteryHealth = {
  red: 33,
  green: 66,
  crital: 0,
};
const MarkerDetails = ({ details, setShowDetails }) => {
  const { microLocationKey } = details;

  const [property, setProperty] = useState();
  const [activities, setActivities] = useState();
  const [batteryData, setBatteryData] = useState(null);
  const [markerDetails, setMarkerDetails] = useState(null);
  const [service, setService] = useState(null);
  const [ranges, setRanges] = useState({
    detectionRange: '',
    servingRange: '',
  });

  const canvasRef = useRef(null);

  function getIcon(icon) {
    switch (true) {
      case batteryHealth.red > icon:
        return BatteryRed;
      case batteryHealth.red === icon:
        return BatteryCritical;
      default:
        return BatteryGreen;
    }
  }
  useEffect(() => {
    if (markerDetails?.propertyKey) {
      getPropertyById(markerDetails?.propertyKey).then((res) => {
        setProperty(res);
      });
    }
  }, [markerDetails]);

  useEffect(() => {
    let isCancel = false;
    if (microLocationKey) {
      getServicePointById(microLocationKey).then((res) => {
        if (!isCancel) {
          setMarkerDetails(res);
        }
      });
    } else if (details) {
      if (!isCancel) {
        setMarkerDetails(details);
      }
    }

    return () => {
      isCancel = true;
    };
  }, [microLocationKey]);

  useEffect(() => {
    let isCancel = false;
    if (markerDetails) {
      getServiceInfo(markerDetails.serviceKey).then((res) => {
        if (!isCancel) {
          setService(res);
        }
      });
    }

    return () => {
      isCancel = true;
    };
  }, [markerDetails]);

  useEffect(() => {
    const data = markerDetails?.activities?.reduce((acc, curr) => {
      acc[curr.category] = acc[curr.category] ?? [];
      acc[curr.category] = [...acc[curr.category], curr];
      return acc;
    }, {});

    setActivities(data);
  }, []);

  const convertDateToReadableDate = (date) =>
    moment(date).format('MMM DD h:mm A');

  useEffect(() => {
    if (markerDetails) {
      getDeviceTemperature({
        deviceId: markerDetails?.beaconId,
        dataSet: 'battery',
        startDate: '',
        endDate: '',
        dateRange: '6 hours',
      })
        .then((res) => {
          if (Array.isArray(res.data)) {
            const newData = res.data?.reduce(
              (acc, eachRes) => ({
                surfaceTemperature: [
                  ...(acc.surfaceTemperature ?? []),
                  eachRes.surface_temperature,
                ],
                airTemperature: [
                  ...(acc.airTemperature ?? []),
                  eachRes.air_temperature,
                ],
                time: [
                  ...(acc.time ?? []),
                  convertDateToReadableDate(eachRes.timestamp),
                ],
              }),
              {}
            );

            setBatteryData(newData);
          } else {
            setBatteryData([]);
          }
        })
        .catch((error) => {
          console.error('Error', error);
        });
    }
  }, [markerDetails]);

  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');

      // eslint-disable-next-line no-new
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: batteryData?.time,
          datasets: [
            {
              label: 'Air Temperature',
              data: batteryData?.airTemperature,
              pointBackgroundColor: 'red',
            },
            {
              label: 'Surface Temperature',
              data: batteryData?.surfaceTemperature,
              pointBackgroundColor: 'blue',
            },
          ],
        },
        options: {},
      });
    }
  }, [batteryData]);

  function handleCloseButton() {
    // closeDetailsBox();
    setShowDetails(false);
  }

  const BeaconDetails = () => (
    <>
      <ListTitleItem
        Icon={InformationIcon}
        title="Device Name"
        content={markerDetails?.beaconName ?? markerDetails?.beaconId}
      />
      {markerDetails?.serialNumber && (
        <ListTitleItem
          Icon={InformationIcon}
          title="Serial Number"
          content={markerDetails?.serialNumber}
        />
      )}
    </>
  );

  const temperatureGraph = () => {
    if (!service || !service?.serviceFeatures?.temperatureMonitoring) {
      return null;
    }

    return <canvas ref={canvasRef} width="400" height="400" />;
  };

  // return (

  //   <MarkerDetailContainer>
  //     <MarkerBasicDetail
  //       ranges={ranges}
  //       marker={markerDetails}
  //       property={property}
  //       // inModal={!microLocationKey && inModal}
  //       handleCloseIcon={handleCloseButton}
  //     />

  //     <Divider />

  //     <StyledTitle>Device Detail</StyledTitle>
  //     <Divider />

  //     <BeaconDetails />

  //     <Divider />

  //     {markerDetails?.activities?.length > 0 && (
  //       <>
  //         <StyledTitle>Task Detail</StyledTitle>
  //         <Divider />
  //         <Col>
  //           {Object.keys(activities ?? {})?.map((category) => (
  //             <React.Fragment key={category}>
  //               <Row justify="space-between">
  //                 <Span>Activity Category</Span>
  //                 <Span weight="700">{category}</Span>
  //               </Row>
  //               {Object.values(activities[category]).map((activity) => (
  //                 <Row justify="space-between" key={activity.id}>
  //                   <Span>{activity.name}</Span>
  //                   <Span weight="700">
  //                     {activity.mandatory ? 'mandatory' : '-'}
  //                   </Span>
  //                 </Row>
  //               ))}
  //             </React.Fragment>
  //           ))}
  //         </Col>
  //       </>
  //     )}

  //     <AssetInformation details={markerDetails} />
  //     {markerDetails?.totalRepairCost ? (
  //       <Row justify="space-between">
  //         <Span size="16px">Total Repair cost till date</Span>
  //         <Span weight="bold" size="16px">
  //           ${Math.floor(markerDetails?.totalRepairCost)}
  //         </Span>
  //       </Row>
  //     ) : null}
  //     {markerDetails?.customerDetail && (
  //       <>
  //         <StyledTitle>Company Detail</StyledTitle>
  //         <Divider />

  //         <Row justify="space-between">
  //           <Span>Name</Span>
  //           <Span weight="700">
  //             {markerDetails?.customerDetail?.name ?? '-'}
  //           </Span>
  //         </Row>
  //         <Row justify="space-between">
  //           <Span>Email</Span>
  //           <Span weight="700">
  //             {markerDetails?.customerDetail?.email ?? '-'}
  //           </Span>
  //         </Row>
  //       </>
  //     )}

  //     {property && (
  //       <>
  //         <StyledTitle>Property Detail</StyledTitle>
  //         <Divider />
  //         {property.imageUrl && (
  //           <ProfilePicture alt="Property" src={property?.imageUrl} />
  //         )}

  //         <Row justify="space-between">
  //           <Span>Name</Span>
  //           <Span weight="700">{property.name}</Span>
  //         </Row>
  //         <Row justify="space-between">
  //           <Span>Address</Span>
  //           <Span weight="700">{property.address}</Span>
  //         </Row>
  //         <Row justify="space-between">
  //           <Span>Customer Name</Span>
  //           <Span weight="700">{property?.customer?.name ?? '-'}</Span>
  //         </Row>
  //         <Row justify="space-between">
  //           <Span>Customer Email</Span>
  //           <Span weight="700">{property?.customer?.email ?? '-'}</Span>
  //         </Row>
  //       </>
  //     )}

  //     <Divider />
  //     {/* {inModal && (
  //       <StyledRow justify="flex-end">
  //         <PrimayButton onClick={handleCloseButton}>Close</PrimayButton>
  //       </StyledRow>
  //     )} */}

  //     {temperatureGraph()}
  //   </MarkerDetailContainer>
  // );
  function getDate(date) {
    return date ? getRelativeDate(date) : 'Never';
  }
  function getColor(healthOfTheBatter) {
    if (healthOfTheBatter <= 30) {
      return 'red';
    }
    return '#03E105';
  }

  const battery = details?.deviceDetail?.deviceDetail?.battery ?? null;

  return (
    <Box width="100%">
      <Header>Location Address</Header>
      <Text>{details?.address ?? '---'}</Text>

      <Divide />
      <Header>Device</Header>
      <Flex gridGap="xsm">
        <Icon height="46px" width="52px" as={MarkerI} />

        <Flex flexDirection="column">
          <Text>{details?.deviceDetail?.serialNumber}</Text>

          {battery ? (
            <Flex alignItems="center" gridGap="xxsm">
              <Icon width="30px" as={getIcon(battery)} />
              <Text color={getColor(battery)}>{battery}%</Text>
            </Flex>
          ) : null}
        </Flex>
      </Flex>
      <Divide />
      <Header>Remarks</Header>
      <Text> {details?.note ?? '--'}</Text>
      <Divide />
      <Header>Last Served</Header>
      <Text> {getDate(details?.lastServed)}</Text>
    </Box>
  );
};

export default MarkerDetails;

const Header = styled(Text)`
  text-align: left;
  font: normal normal 600 16px/30px Montserrat;
  letter-spacing: 0.26px;
  color: #162350;
  opacity: 1;
`;
const Divide = styled(Divider)`
  margin: 10px 0px;
`;
