import { Image } from '@chakra-ui/image';
import { Flex, Text, Stack } from '@chakra-ui/layout';

interface ZeroStateComponentProps {
  header: string;
  title: string;
  subTitle: string;
  image: string;
}
const ZeroStateComponent = (props: ZeroStateComponentProps) => {
  const { header, title, subTitle, image } = props;

  return (
    <Flex
      h="80%"
      opacity={1}
      gridGap="xxl"
      color="text.800"
      textAlign="center"
      direction="column"
      alignItems="center"
      justifyContent="center"
      mixBlendMode="luminosity"
    >
      <Image src={image} maxHeight="294px" />
      <Stack>
        <Text fontWeight={600} fontSize="mdl">
          {header}
        </Text>

        <Stack>
          <Text opacity="0.8">{title}</Text>
          <Text opacity="0.5" as="i" color="color.300" marginTop="16.33px">
            {subTitle}
          </Text>
        </Stack>
      </Stack>
    </Flex>
  );
};

export default ZeroStateComponent;
