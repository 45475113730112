import { Box, Stack } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { LocalizationContext } from '../../../contexts/localization_context';
import { AddShiftTemplateFields } from '../shiftTemplates/ AddShiftTemplateFields';

interface SelectShiftTemplateProps {
  shifts: any;
  selectedJobType: string;
  selectedShift: any;
  handleShiftChange: () => void;
  shiftData: any;
  handleDataChange: (value: any, type: string) => void;
  handleWeekDays: (value: any[]) => void;
  weekDay: OptionType[];
  selectedWeekDays: number[];
  setCustomShiftTemplate: (value: any) => void;
  setSelectedShift: (value: any) => void;
  isEditShift: boolean;
  setPeriodicData: any;
  periodicData: any;
  job: any;
  setJob: (j: any) => void;
  handleStartDateChange: (date: Date | number) => void;
  handleEndDateChange: (date: Date | number) => void;
  shiftStartDate: Date | number;
  shiftEndDate: Date | number;
}

type OptionType = { label: string; value: number };

export const CustomShiftForm = ({
  shifts,
  selectedJobType,
  selectedShift,
  handleShiftChange,
  shiftData,
  handleDataChange,
  handleWeekDays,
  setCustomShiftTemplate,
  setSelectedShift,
  isEditShift,
  weekDay,
  selectedWeekDays: weekDaysThatAreSelected,
  setPeriodicData,
  periodicData,
  job,
  setJob,
  handleStartDateChange,
  handleEndDateChange,
  shiftStartDate,
  shiftEndDate,
}: SelectShiftTemplateProps) => {
  const { strings } = useContext(LocalizationContext);

  const [selectedWeekDays, setSelectedWeekDays] = useState<OptionType[]>([]);

  useEffect(() => {
    if (selectedShift?.value) {
      const selectedWeekDaysFromDb = new Set(selectedShift.value.weekdays);
      const selectedDays = weekDay?.reduce((allDays: OptionType[], day) => {
        if (selectedWeekDaysFromDb.has(day.value)) {
          allDays.push(day);
        }
        return allDays;
      }, []);
      setSelectedWeekDays(selectedDays);
    }
  }, [selectedShift?.value]);

  // useEffect(() => {
  //   if (!isEditShift) {
  //     setCustomShiftTemplate(null);
  //   } else {
  //     setSelectedShift(null);
  //   }
  // }, [ isEditShift]);

  return (
    <Box>
      <AddShiftTemplateFields
        shiftData={shiftData}
        handleDataChange={handleDataChange}
        selectedWeekDays={shiftData?.weekdays ?? selectedShift?.value?.weekdays}
        isEdit={isEditShift}
        setPeriodicData={setPeriodicData}
        periodicData={periodicData}
        fromSchedule
      />
    </Box>
  );
};
