import { Avatar, Box, Flex, Tbody, Td, Text } from '@chakra-ui/react';
import { getRelativeDate } from '../../../helpers/date';
import { sortByEntity } from '../../../helpers/misc';
import { MainTable, MainTableRow } from '../../../reusableComponent/MainTable';

export const ManagerTable = (props) => {
  const {
    filteredAdmins,
    handleCardPress,
    setFilteredAdmins,
    managerHeader,
    setManagerHeader,
  } = props;

  const handleSorting = (header, headerIndex) => {
    const { sort, name: fieldName } = header;

    let newSort;
    if (fieldName === 'Last served') {
      newSort = null;
    } else {
      newSort = sort === 'desc' ? 'asc' : 'desc';
    }
    const copiedLocations = [...filteredAdmins];

    switch (fieldName) {
      case 'Name':
        copiedLocations?.sort((firstLocation, secondLocation) =>
          sortByEntity(firstLocation.name, secondLocation.name, newSort)
        );
        break;

      default:
        break;
    }

    const updatedHeaders = [...managerHeader];
    updatedHeaders.splice(headerIndex, 1, {
      name: fieldName,
      sort: newSort,
    });
    setManagerHeader(updatedHeaders);
    setFilteredAdmins(copiedLocations);
  };

  return (
    <Box>
      <MainTable headers={managerHeader} handleSorting={handleSorting}>
        <Tbody bg="white" boxShadow="0px 12px 24px #ECEEF5" borderRadius="16px">
          {filteredAdmins.map((managerList) => (
            <ManagerTableRow
              handleCardPress={handleCardPress}
              managerList={managerList}
              key={managerList.uid}
            />
          ))}
        </Tbody>
      </MainTable>
    </Box>
  );
};

const ManagerTableRow = (props) => {
  const { managerList, handleCardPress } = props;
  const { name, imageUrl, loggedIn, lastSignIn } = managerList;

  const lastLoggedInTime = () => {
    if (loggedIn !== true) {
      return ' Invited';
    }
    if (!loggedIn) {
      return '-';
    }

    return getRelativeDate(lastSignIn);
  };
  function isInvited() {
    return (
      <Text
        marginLeft="5px"
        style={{
          font: 'normal normal normal 16px/19px Montserrat',
          color: '#8A91A6',
        }}
      >
        (Invited)
      </Text>
    );
  }
  return (
    <MainTableRow>
      <Td>
        <Flex gridGap="md">
          <Flex
            onClick={() => handleCardPress(managerList)}
            cursor="pointer"
            gridGap="sm"
          >
            <Avatar name={name} src={imageUrl} />

            <Flex mt="15.5">
              <Text>{name} </Text>
              {loggedIn !== true ? isInvited() : null}{' '}
            </Flex>
          </Flex>
        </Flex>
        {/* <Stack>
          <Text>{name}</Text>
          {disabled && <Text color={color.red}>{}</Text>}
        </Stack> */}
      </Td>

      <Td textAlign="right">{lastLoggedInTime()}</Td>
    </MainTableRow>
  );
};

const lastLoggedInTime = (loggedIn, lastSignIn) => {
  if (loggedIn !== true) {
    return ' Invited';
  }
  if (!loggedIn) {
    return '-';
  }

  return getRelativeDate(lastSignIn);
};
