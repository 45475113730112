import { HStack, Text, Wrap, Image, Button } from '@chakra-ui/react';
import { FaPlusCircle } from 'react-icons/fa';
import { CSSObject } from '@emotion/react';
import React, { useState, ChangeEvent } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import ClickableContainer from '../../../reusableComponent/ClickableContainer';
import { CustomInput } from '../../../reusableComponent/CustomInput';
import { Task, TaskListInterface } from './task';
import CloseIcon from '../../../assets/svgs/CloseIcon.svg';
import AddIcon from '../../../assets/svgs/AddIcon.svg';

const initialTask = {
  name: '',
};

interface TaskNameBoxPropsInterface {
  task: Task;
  taskList: TaskListInterface;
  setTaskList: React.Dispatch<React.SetStateAction<TaskListInterface>>;
  index: number;
}

const initialTaskList: TaskListInterface = {
  name: '',
  type: '',
  businessKey: '',
  tasks: [{ ...initialTask }],
};

export const TaskNameBox = (props: TaskNameBoxPropsInterface) => {
  const [error, setError] = useState('');

  const { taskList, index, setTaskList, task } = props;

  const { register, handleSubmit, errors, setValue, watch } = useForm();

  const handleTasksNameInput = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    const tasks = [...taskList.tasks];

    tasks[index] = {
      ...tasks[index],
      [name]: value,
    };

    setTaskList({ ...taskList, tasks });
  };

  const onCloseTaskBox = () => {
    const tasks = [...taskList.tasks];

    const remainingTaskBox = tasks.filter((t, taskIndex) => {
      if (taskIndex > 0 && tasks.length > 0) {
        return index !== taskIndex;
      }
      return t;
    });

    setTaskList({ ...taskList, tasks: [...remainingTaskBox] });
  };

  const validateTaskName = () => {
    if (!taskList.tasks[index].name) {
      return 'Task name field is required.';
    }
    return null;
  };

  return (
    <>
      <HStack alignItems="center">
        <CustomInput
          name="name"
          placeholder="Task name"
          type="text"
          onChange={handleTasksNameInput}
          value={taskList.tasks[index].name}
          //   register={{
          //     ...register(`tasks.${index}.name`, {
          //       validate: validateTaskName,
          //     }),
          //   }}
          error={errors?.tasks?.[index]?.name}
          errorMessage={errors?.tasks?.[index]?.name?.message}
          isRequired
        />
        <Image
          src={CloseIcon}
          cursor="pointer"
          height="20px"
          width="20px"
          onClick={onCloseTaskBox}
        />
      </HStack>

      {/* <CloseIcon /> */}
    </>
  );
};

function cssControls(base: CSSObject) {
  return {
    ...base,
    border: 0,
    boxShadow: 'none',
    marginTop: '4px',
  };
}
