import { SearchIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Stack,
  Switch,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaPlusCircle } from 'react-icons/fa';
import { Redirect } from 'react-router';
import { ServicePointsContext } from '../../../contexts/service_points';
import { ServiceTypesContext } from '../../../contexts/service_types';
import {
  archiveProperty,
  createEditProperty,
  deleteServicePoint,
  getJobsCountForProperty,
  getMarkersCountForProperty,
  getPropertiesFromFirestore,
} from '../../../controllers/snowm_firebase';
import {
  filterDataByName,
  getLowerCasedWord,
  sortByEntity,
} from '../../../helpers/misc';
import { ValidateEmail } from '../../../helpers/snowm_validators';
import { CustomInput } from '../../../reusableComponent/CustomInput';
import { DetailsDrawer } from '../../../reusableComponent/DetailsDrawer';
import ImageContainer from '../../../reusableComponent/ImageContainer';
import { SideDrawer } from '../../../reusableComponent/SideDrawer';
import AlertBox from '../../Common/alert_box';
import SearchBar from '../../Common/Search_Bar';
import { LocationModal } from '../../Generics/location_modal';
import Loader from '../../Generics/snowm_loader';
import { microLocationBoxInDeleteDialog } from '../microLocations/MarkersTable';
import MarkerDetails from '../microLocations/micro_location_details';
import AddServicePoint from '../microLocations/snowm_add_micro_locations';
import LocationDetails from './LocationDetails';
import PropertiesMarker from './LocationMarkers';
import LocationSchedules from './LocationSchedules';
import LocationShifts from './LocationShifts';
import { PropertiesTable } from './PropertiesTable';

const Properties = () => {
  const toast = useToast();
  const { register, handleSubmit, errors, unregister, reset } = useForm();

  const defaultPropertyDetails = {
    name: null,
    address: null,
    customer: null,
    imageUrl: null,
    latitude: null,
    longitude: null,
    geoFenceRadius: 200,
    archive: false,
  };

  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openAlertBox, setOpenAlertBox] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState(
    defaultPropertyDetails
  );
  const [filtered, setFiltered] = useState();
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [jobsCount, setJobsCount] = useState(0);
  const [properties, setProperties] = useState();
  const [sortType, setSortType] = useState('desc');
  const [isSearch, setIsSearch] = useState(false);
  const [warningOne, setWarningOne] = useState(false);
  const [warningTwo, setWarningTwo] = useState(false);
  const [openActionDrawer, setOpenActionDrawer] = useState(true);
  const [openMicroLocationActionDrawer, setOpenMicroLocationActionDrawer] =
    useState(false);
  const [markersCount, setMarkersCount] = useState(0);
  const [addressError, setAddressError] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [serviceData, setServiceData] = useState();
  const [selectedServicePoint, setSelectedServicePoint] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [selectedMicroLocations, setSelectedMicroLocations] = useState({});
  const [selectedMarker, setSelectedMarker] = useState();
  const [showDetails, setShowDetails] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [openDetailsDrawer, setOpenDetailsDrawer] = useState(false);
  const [agreed, setAgreed] = useState(false);

  const [openAddMiroLocationDialog, setOpenAddMiroLocationDialog] =
    useState(false);

  const [searchText, setSearchText] = useState('');
  const [microLocation, setMicroLocation] = useState({});
  const deleteConfirmation = useDisclosure();

  const photoInputRef = useRef(null);

  const serviceContext = useContext(ServiceTypesContext);

  // Todo delete microlocation
  const { markers, fetchServicePoints } = useContext(ServicePointsContext);

  const subscribedServices = serviceContext?.subscribedServices;

  useEffect(() => {
    const sortedData = properties?.sort((a, b) => sortByEntity(a.name, b.name));
    const data = filtered?.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {});
    if (searchText) {
      const LocationAfterFilter = filterDataByName(searchText, data);
      const filteredLocation = Object.values(LocationAfterFilter);
      setFiltered(filteredLocation);
    } else {
      setFiltered(sortedData);
    }
  }, [sortType, properties, searchText]);

  useEffect(() => {
    let isSubscribe = true;
    function _updateProperties(response) {
      if (isSubscribe) {
        setProperties(response);
      }
    }

    getPropertiesFromFirestore(_updateProperties);

    return () => {
      isSubscribe = false;
    };
  }, []);

  useEffect(() => {
    if (!markers) {
      fetchServicePoints();
    }
  }, []);

  if (!properties || !subscribedServices) {
    return <Loader />;
  }

  if (!subscribedServices?.properties) {
    return <Redirect to="/admin" />; // TODO: check routing for customer too
  }

  function handleAddPress() {
    setOpenActionDrawer(false);

    setOpenAddDialog(true);
    setAddressError('');
  }

  function handleCancelButton() {
    unregister('email');
    unregister('clientName');
    unregister('name');
    setLoading(false);
    setChecked(false);
    setOpenAddDialog(false);
    setOpenActionDrawer(true);
    setPropertyDetails(defaultPropertyDetails);
  }

  function closeDetailsDrawer() {
    setOpenDetailsDrawer(false);
    setOpenActionDrawer(true);
    setOpenMicroLocationActionDrawer(true);
    setOpenAddDialog(false);
    setPropertyDetails(defaultPropertyDetails);
  }
  function openWindow() {
    photoInputRef.current.click();
  }

  function handleImageChange(event) {
    event.preventDefault();
    const filesRef = event.target?.files[0];
    const file = URL.createObjectURL(filesRef);
    setPropertyDetails((prevData) => ({
      ...prevData,
      imageUrl: file,
      imageFile: filesRef,
    }));
  }

  const handleRemoveImage = () => {
    setPropertyDetails((prevData) => ({
      ...prevData,
      imageUrl: null,
      imageFile: null,
    }));
  };
  function handlePropertyName(event) {
    event.persist();

    setPropertyDetails((prevData) => ({
      ...prevData,
      name: event.target.value,
    }));
  }

  function onLocationChange(address, latlng) {
    setPropertyDetails((prevData) => ({
      ...prevData,
      latitude: latlng.lat,
      longitude: latlng.lng,
      address,
    }));
    setAddressError('');
  }

  function handleSwitchChange() {
    setChecked(!checked);
  }

  async function addOrEditProperty() {
    setOpenActionDrawer(false);
    setSelectedLocation({});
    setLoading(true);
    if (!propertyDetails.address) {
      setAddressError('Please select address from map.');
      setLoading(false);
      return;
    }

    const updatedDetails = { ...propertyDetails };
    if (!checked) {
      updatedDetails.customer = null;
      setPropertyDetails(updatedDetails);
    }
    try {
      await createEditProperty(updatedDetails);
      setLoading(false);
      setOpenAddDialog(false);
      setPropertyDetails(defaultPropertyDetails);
      toast({
        title: 'Success',
        description: propertyDetails?.id
          ? `Location ${propertyDetails?.name} updated successfully.`
          : `Location ${propertyDetails?.name} created successfully.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      setLoading(false);
      toast({
        title: 'Error!',
        description: propertyDetails?.id
          ? 'Unable to edit location.'
          : 'Unable to create location.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
    setChecked(false);
    reset();
    unregister('phoneNumber');
    unregister('email');
  }

  const handleGetMarkersCount = async (property) => {
    const count = await getMarkersCountForProperty(property.id);
    setMarkersCount(count);
  };

  const handleGetJobsCount = async (property) => {
    const count = await getJobsCountForProperty(property.id);
    setJobsCount(count);
  };

  async function handleDeleteSingleLocation(location) {
    await archiveProperty(location.key);

    const microlocations = Object.values(markers).filter(
      (marker) => marker.propertyKey === location.key
    );

    await Promise.all(
      microlocations?.map((microlocation) => {
        return deleteServicePoint(microlocation.key);
      })
    );
  }

  function handleAlexBoxCancel() {
    setOpenActionDrawer(true);

    setOpenAlertBox(false);
  }

  async function handleDeleteButton() {
    try {
      await archiveProperty(propertyDetails.id);
      setOpenAlertBox(false);
      setOpenActionDrawer(false);

      setPropertyDetails(defaultPropertyDetails);
    } catch (error) {
      console.error('@@Error', error);
    }
  }

  async function handleMultipleDeleteButton(propertyDetail) {
    try {
      await archiveProperty(propertyDetail.id);
      setOpenAlertBox(false);
      setOpenActionDrawer(false);

      setPropertyDetails(defaultPropertyDetails);
    } catch (error) {
      console.error('@@Error', error);
    }
  }

  function handleEditLocationDetails(property) {
    setOpenActionDrawer(false);
    setPropertyDetails({
      ...property,
      geoFenceRadius: property.geoFenceRadius ?? 200,
    });
    if (property.customer) setChecked(true);
    setOpenAddDialog(true);
  }

  function handleCustomerNameChange(event) {
    event.persist();

    setPropertyDetails((prevData) => ({
      ...prevData,
      customer: {
        ...prevData.customer,
        name: event.target.value,
      },
    }));
  }

  function handleEmail(event) {
    event.persist();

    setPropertyDetails((prevData) => ({
      ...prevData,
      customer: {
        ...prevData.customer,
        email: event.target.value,
      },
    }));
  }
  const selectedMicroLocation = Object.values(selectedMicroLocations);

  const microLocationsToEditDelete = selectedMicroLocation?.[0];

  function handleCardPress(location) {
    // TODO: open detail drawer
    setOpenActionDrawer(false);
    setOpenMicroLocationActionDrawer(false);

    setPropertyDetails({
      ...location,
      geoFenceRadius: location.geoFenceRadius ?? 200,
    });
    setOpenDetailsDrawer(true);
  }

  const onSearch = (e) => {
    setSearchText(e.target.value);
    setIsSearch(true);
  };

  function handleSortingChange() {
    if (sortType === 'asc') {
      setSortType('desc');
    } else {
      setSortType('asc');
    }
  }

  const resetDetails = () => {
    setJobsCount(0);
    setMarkersCount(0);
  };

  function onEditLocation(location) {
    setChecked(!!location.customer);
    setPropertyDetails(location);
    setOpenAddDialog(true);
    setOpenActionDrawer(false);
  }

  function onEditMicroLocationFromLocation(marker) {
    setShowDetails(false);
    setOpenMicroLocationActionDrawer(false);
    setOpenAddMiroLocationDialog(true);
    setMicroLocation(marker);
  }
  function handleServiceDelete(service) {
    setServiceData(service);
    setOpenDeleteDialog(true);
  }

  function handleMarkerItemPressed(marker) {
    setShowDetails(true);
    setSelectedMarker(marker);
    setOpenDetailsDrawer(false);
    setOpenMicroLocationActionDrawer(false);

    // history.push(`/admin/micro_locations/${marker.key}`);
  }
  function handleCloseAddDialog() {
    setOpenAddMiroLocationDialog(false);
    setOpenMicroLocationActionDrawer(true);
    setOpenActionDrawer(true);
    setIsEdit(false);
  }

  async function deleteServicePointData() {
    setOpenMicroLocationActionDrawer(false);
    setSelectedMicroLocations({});

    const { key: markerKey } = serviceData;

    await deleteServicePoint(markerKey);
    setOpenDeleteDialog(false);
  }
  async function deleteMicroLocationFromLocation(microlocation) {
    // setOpenMicroLocationActionDrawer(false);
    // setSelectedMicroLocations({});
    const { key: microLocationKey } = microlocation;
    await deleteServicePoint(microLocationKey);
    closeDeleteDialog();
    setOpenDeleteDialog(false);
  }
  function closeAlertBox() {
    setOpenMicroLocationActionDrawer(true);
    setOpenDeleteDialog(false);
    setServiceData({});
  }

  const resetFilteredData = () => {
    setFiltered(properties);
  };

  const handleGeoFenceChange = (event) => {
    setPropertyDetails({
      ...propertyDetails,
      geoFenceRadius: parseInt(event.target.value, 10),
    });
  };

  const handleGeoFencingEnablingChange = (event) => {
    setPropertyDetails({
      ...propertyDetails,
      enabledGeofencing: event.target.checked,
    });
  };
  function hanldeAddMicroLocation(location) {
    setMicroLocation({});
    setSelectedServicePoint(location);
    setOpenAddMiroLocationDialog(true);
    setOpenActionDrawer(false);
  }

  const image = () => (
    <Flex gridGap="md" align="center" flexWrap="wrap">
      <ImageContainer
        size="150"
        imageURL={propertyDetails?.imageUrl}
        onPress={openWindow}
        externalRef={photoInputRef}
        onChange={handleImageChange}
      />

      <Flex flexWrap="wrap" gridGap="md">
        <Button
          variant="outline"
          colorScheme="blue"
          onClick={() => photoInputRef?.current?.click()}
        >
          Choose Image
        </Button>
        {(propertyDetails?.imageUrl || propertyDetails?.imageFile) && (
          <Button
            variant="outline"
            colorScheme="blue"
            onClick={handleRemoveImage}
          >
            Remove Image
          </Button>
        )}
      </Flex>
    </Flex>
  );

  const renderDetails = () => (
    <LocationDetails
      propertyId={propertyDetails?.id}
      location={propertyDetails}
    />
  );

  const renderMicroLocations = () => (
    <PropertiesMarker
      location={propertyDetails}
      locationId={propertyDetails?.id}
      onMicroLocationClick={handleMarkerItemPressed}
    />
  );

  const renderShifts = () => (
    <LocationShifts
      propertyId={propertyDetails?.id}
      location={propertyDetails}
    />
  );

  const renderSchedules = () => (
    <LocationSchedules
      propertyId={propertyDetails?.id}
      location={propertyDetails}
    />
  );

  const detailTabs = {
    Details: renderDetails(),
    MicroLocations: renderMicroLocations(),
    Schedules: renderSchedules(),
  };

  const renderMicroLocationSchedules = () => {
    return <Text>No schedules</Text>;
  };

  const microLocationDetailTabs = {
    Details: (
      <MarkerDetails details={selectedMarker} setShowDetails={setShowDetails} />
    ),
    Schedules: renderMicroLocationSchedules(),
  };

  const validateLocationName = () => {
    if (!propertyDetails?.name) return 'Location name is required';
    const propertyWithSameName = filtered.some(
      (property) =>
        property.id !== propertyDetails.id &&
        getLowerCasedWord(property.name) ===
          getLowerCasedWord(propertyDetails.name)
    );

    return propertyWithSameName
      ? 'Location name with same name already exists.'
      : null;
  };

  const validateCustomerName = () => {
    if (checked && !propertyDetails?.customer?.name)
      return 'Client name is required.';
    return null;
  };

  const validateCustomerEmail = () => {
    if (checked && !propertyDetails?.customer?.email) {
      return 'Client email is required.';
    }
    if (checked && !ValidateEmail(propertyDetails?.customer?.email)) {
      return 'Invalid email.';
    }
    return null;
  };

  const closeDeleteDialog = () => {
    deleteConfirmation.onClose();
    setAgreed(false);
  };
  const locationBoxInDeleteDialog = (locations) => {
    return (
      <Box
        overflowY="auto"
        margin="24px 0px"
        borderRadius="4px"
        border="0.5px solid #AEB0B5"
        padding="16px 8px 8px 16.5px"
        background="#F1F1F1 0% 0% no-repeat padding-box"
      >
        {locations?.map((location, i) => {
          return (
            <Fragment key={location.id}>
              <Flex gridGap="md" alignItems="center">
                <Avatar
                  src={location?.imageUrl}
                  w="34px"
                  h="34px"
                  borderRadius="4px"
                  name={location?.name}
                />

                <Text>{location?.name}</Text>
              </Flex>
              {i < locations.length - 1 && <Divider margin="8px auto" />}
            </Fragment>
          );
        })}
      </Box>
    );
  };

  const getLocatioNameFromMicroLocation = (locationKey) => {
    if (locationKey) {
      const { name } =
        (filtered ?? [])?.find((location) => location.id === locationKey) ?? {};
      return name;
    }
    return '';
  };

  return (
    <Flex
      bg="background.500"
      p="xxxl"
      direction="column"
      overflow="auto"
      gridGap="sm"
      height="100%"
    >
      <Flex direction="column" gridGap="llg">
        <Flex justifyContent="space-between" alignItems="center">
          <Heading as="h4">Locations</Heading>
          <Button
            onClick={handleAddPress}
            colorScheme="blue"
            leftIcon={<FaPlusCircle />}
            paddingLeft="xsm"
          >
            <Text color="white">Add Location</Text>
          </Button>
        </Flex>
        <SearchBar
          title="Location"
          LeftIcon={<SearchIcon />}
          value={searchText}
          onChange={(e) => onSearch(e)}
          setSelectedEntities={setSelectedLocation}
          selectedEntities={selectedLocation}
        />
      </Flex>
      <PropertiesTable
        properties={filtered}
        searchText={searchText}
        setProperties={setFiltered}
        onEditLocation={onEditLocation}
        body={locationBoxInDeleteDialog}
        DeletePress={handleServiceDelete}
        openActionDrawer={openActionDrawer}
        selectedLocation={selectedLocation}
        ViewPress={handleMarkerItemPressed}
        openPropertiesDetail={handleCardPress}
        setOpenActionDrawer={setOpenActionDrawer}
        setSelectedLocation={setSelectedLocation}
        onDeleteIconPress={handleDeleteSingleLocation}
        selectedMicroLocations={selectedMicroLocations}
        hanldeAddMicroLocation={hanldeAddMicroLocation}
        setSelectedServicePoint={setSelectedServicePoint}
        setSelectedMicroLocations={setSelectedMicroLocations}
        handleMultipleDeleteButton={handleMultipleDeleteButton}
        selectedLocationForMicroLocations={selectedServicePoint}
        setOpenAddMiroLocationDialog={setOpenAddMiroLocationDialog}
        openMicroLocationActionDrawer={openMicroLocationActionDrawer}
        deleteMicroLocationFromLocation={deleteMicroLocationFromLocation}
        onEditMicroLocationFromLocation={onEditMicroLocationFromLocation}
        setOpenMicroLocationActionDrawer={setOpenMicroLocationActionDrawer}
      />
      {/* <AddButton onPress={handleAddPress} title="Create New Location" /> */}

      {/* Location Details drawer */}
      <DetailsDrawer
        open={openDetailsDrawer}
        title={`${propertyDetails.name}
        `}
        subtitle={propertyDetails.address}
        image={propertyDetails?.imageUrl}
        details={propertyDetails}
        onClose={closeDetailsDrawer}
        detailTabs={detailTabs}
        openActionDrawer={openActionDrawer}
        onEdit={handleEditLocationDetails}
        onDelete={handleDeleteSingleLocation}
        setOpenDeleteDialog={setOpenDeleteDialog}
        body={locationBoxInDeleteDialog([propertyDetails])}
        deleteSubtitle="Remember, this action cannot be undone.Also it removes data such as Location Details and Schedule."
      />

      {/* Location Add edit form */}
      <SideDrawer
        open={openAddDialog}
        title={propertyDetails?.id ? 'Edit Location' : 'Add Location'}
        negativeAction={handleCancelButton}
      >
        <form onSubmit={handleSubmit(addOrEditProperty)}>
          <Flex gridGap="md" direction="column" mt="32px">
            <Text font="text" size="mdl" fontWeight="semibold">
              Details
            </Text>
            {image()}
            <CustomInput
              placeholder="Location Name *"
              type="text"
              value={propertyDetails?.name}
              onChange={handlePropertyName}
              register={{
                ...register('name', {
                  validate: validateLocationName,
                }),
              }}
              error={errors?.name}
              errorMessage={errors?.name?.message}
            />

            <LocationModal
              value={propertyDetails?.address}
              name={propertyDetails?.address}
              onLocationChange={onLocationChange}
              error={addressError}
            />
            <Text color="red.200">{addressError}</Text>

            {/* 
            {subscribedServices?.propertyLevelGeofence === "levelOfGeofence" && (
              <Flex justify="space-between">
                <Text>Enable Job Geofencing</Text>
                <Switch
                  onChange={handleGeoFencingEnablingChange}
                  isChecked={propertyDetails?.enabledGeofencing}
                />
              </Flex>
            )} */}

            {propertyDetails?.enabledGeofencing && (
              <>
                <CustomInput
                  placeholder="Geofence Radius"
                  InputProps={{
                    min: '200',
                  }}
                  type="number"
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  value={propertyDetails?.geoFenceRadius}
                  onChange={handleGeoFenceChange}
                  isRequired
                  RightIcon="m"
                />
                <Stack gap="12px">
                  <Text>
                    The distance at which crew is mandated to be present while
                    serving a marker withing this property, if crew comes out of
                    the location during an active job, you will get a
                    notification.
                  </Text>
                  <Text>
                    Please note that, this will also ask crew to provide
                    background location permission and is mandatory for this
                    feature.
                  </Text>
                </Stack>
              </>
            )}

            {subscribedServices.endCustomerLogin && (
              <>
                <FormControl
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <FormLabel htmlFor="client-access" mb="0" cursor="pointer">
                    Client Access
                  </FormLabel>
                  <Switch
                    id="client-access"
                    isChecked={checked}
                    onChange={handleSwitchChange}
                  />
                </FormControl>

                <Collapse in={checked} unmountOnExit>
                  <Stack spacing="sm">
                    <CustomInput
                      placeholder="Client Name"
                      required
                      onChange={handleCustomerNameChange}
                      value={propertyDetails?.customer?.name}
                      register={
                        checked && {
                          ...register('clientName', {
                            validate: validateCustomerName,
                          }),
                        }
                      }
                      error={errors?.clientName}
                      errorMessage={errors?.clientName?.message}
                    />
                    <CustomInput
                      value={propertyDetails?.customer?.email}
                      placeholder="Client Email"
                      required
                      onChange={handleEmail}
                      register={
                        checked && {
                          ...register('email', {
                            validate: validateCustomerEmail,
                          }),
                        }
                      }
                      error={errors?.email}
                      errorMessage={errors?.email?.message}
                    />
                  </Stack>
                </Collapse>
              </>
            )}

            <Flex justify="flex-end" gridGap="4">
              <Button
                variant="ghost"
                color="primary.500"
                isDisabled={loading}
                onClick={handleCancelButton}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                colorScheme="blue"
                background="#006DB8 0% 0%"
                boxShadow=" 0px 3px 6px #006DB85C"
                borderRadius="5px"
                isLoading={loading}
              >
                {propertyDetails?.id ? 'Update' : 'Add Location'}
              </Button>
            </Flex>
          </Flex>
        </form>
      </SideDrawer>
      {/* <AlertBox
        open={openAlertBox}
        handleNegativeAction={handleAlexBoxCancel}
        title="Delete Location"
        subtitle="Are you sure you want to delete the location?"
        handlePositiveAction={handleDeleteButton}
        border="1px solid red"
      /> */}
      {/* <AlertBox
        open={warningOne}
        handleNegativeAction={() => {
          setOpenActionDrawer(true);

          setWarningOne(false);
          resetDetails();
        }}
        title="Warning"
        subtitle={`There are ${markersCount} marker/s within this location and they will be unassigned from this property. Are you sure want to delete this property? `}
        positiveText="Yes"
        negativeText="No"
        handlePositiveAction={() => {
          setWarningOne(false);
          setWarningTwo(true);
        }}
      />
      <AlertBox
        open={warningTwo}
        handleNegativeAction={() => {
          setOpenActionDrawer(true);

          setWarningTwo(false);
          resetDetails();
        }}
        title="Warning"
        subtitle={`You have ${jobsCount} numbers of Jobs assigned to this location and will not have any impact on the Jobs associated  with this property, Do you stil want to continue deletion ? `}
        positiveText="Yes"
        negativeText="No"
        handlePositiveAction={() => {
          setWarningTwo(false);
          setOpenAlertBox(true);
        }}
      /> */}

      {/* MicroLocation from location */}
      <SideDrawer
        open={openAddMiroLocationDialog}
        title={microLocation.key ? 'Edit Micro-Location' : 'Add Micro-Location'}
        negativeAction={handleCloseAddDialog}
      >
        <AddServicePoint
          handleCloseDialog={handleCloseAddDialog}
          selectedServicePoint={selectedServicePoint}
          edit={!!microLocation.key}
          addMicroLocationFromLocation={!microLocation.key}
          marker={isEdit ? selectedServicePoint : ''}
          setMicroLocations={setMicroLocation}
          microLocations={microLocation}
          openActionDrawer={openActionDrawer}
          setOpenActionDrawer={setOpenActionDrawer}
          setSelectedLocation={setSelectedLocation}
          setSelectedMicroLocations={setSelectedMicroLocations}
        />
      </SideDrawer>

      <DetailsDrawer
        open={showDetails}
        onClose={() => {
          setShowDetails(false);
          setOpenMicroLocationActionDrawer(true);
        }}
        title={selectedMarker?.name}
        subtitle={getLocatioNameFromMicroLocation(selectedMarker?.propertyKey)}
        deleteSubtitle="Remember,this action cannot be undone."
        detailTabs={microLocationDetailTabs}
        details={selectedMarker}
        onEdit={() => onEditMicroLocationFromLocation(selectedMarker)}
        body={microLocationBoxInDeleteDialog([selectedMarker])}
        setOpenDeleteDialog={setOpenDeleteDialog}
        onDelete={() => deleteMicroLocationFromLocation(selectedMarker)}
      />
    </Flex>
  );
};

export default Properties;
