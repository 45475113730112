/* eslint-disable no-undef */
/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_drawer.jsx
 *
 *  Description: Drawer component.
 *
 *  Author: Nabin Kharal (nabin@brainants.com)
 *
 *  Date created: 4-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useState, useEffect } from 'react';
import { Drawer } from '@chakra-ui/react';

import styled from 'styled-components';
import ExpandIcon from 'mdi-react/ChevronDownIcon';
import CollapseIcon from 'mdi-react/ChevronRightIcon';
import {
  ListItem,
  List,
  ListItemText,
  ListItemIcon,
  Divider,
  Grid,
} from '@material-ui/core';

import { Box, Flex, Text } from '@chakra-ui/layout';

import Span from '../Common/Span';
import color from '../../utils/color';
import { ReactComponent as MarkerLogo } from '../../assets/svgs/StratosfyLogo.svg';
import { Row } from '../../styles/snowm_styled';

// functional component
/*
 ****************************************************************************
 * @brief  (DrawerView)  Renders the contents of drawer
 * @param{type : Array,string,object } ( routes,active,props)
 *
 * @returns content of the drawer (react element)
 ****************************************************************************
 */
const DrawerView = ({ routes, active, staticContext, ...props }) => {
  const [expanded, setExpanded] = useState(active);
  const [activeLocal, setActive] = useState(active);
  useEffect(() => {
    setActive(active);
    setExpanded(active);
  }, [active]);

  /*
   **************************************************************************************
   * @brief  (SideItems)  renders the items inside the drawer
   * @param{type : Array } (routesToRender)
   *
   * @returns Appropriate React element including all the routes listed in routesToRender
   **************************************************************************************
   */

  // returns the Content of SideItems inside of sidebar
  return (
    <Grid
      templateRows="repeat(2, 1fr)"
      templateColumns="minmax(250px, 12%) repeat(5, 1fr)"
      minH="100vh"
      maxH="100vh"
    >
      <Flex flexDirection="column" {...props} height="100%">
        <Flex justifyContent="center" m="30px 25px">
          <MarkerLogo />
        </Flex>
        <SideItems
          routes={routes}
          expanded={expanded}
          activeLocal={activeLocal}
          setActive={setActive}
          setExpanded={setExpanded}
          props={props}
        />
      </Flex>
    </Grid>
  );
};

export default DrawerView;

const StyledListItem = styled(ListItem)`
  && {
    padding-left: 20px;
    border-radius: 5px;
    background: ${(props) =>
      props.active
        ? 'transparent linear-gradient(95deg, #65CAF0 0%, #00AEEF 100%) 0% 0% no-repeat padding-box'
        : 'transparent'} !important;
    box-shadow: ${(props) => props.active && '0px 3px 6px #006db85c'};
    &:hover {
      background: ${(props) => props.theme.palette.primary};
    }
  }
`;

const SideItems = ({
  routes: routesToRender,
  expanded,
  activeLocal,
  setActive,
  setExpanded,
  props,
}) => (
  <Flex flexDirection="column" overflow="auto">
    {routesToRender.map(
      ({ route, name, Icon, children, menu, hide, ActiveIcon }) => {
        const routeAbs = `${route}`;
        const expand = expanded.includes(route, 0);

        const isActiveRoute = () => activeLocal === route;

        if (menu) {
          return null;
        }

        if (hide) {
          return null;
        }

        return (
          <div key={name} style={{ padding: 0 }}>
            <Flex
              padding="4px 10px"
              gridGap="16px"
              marginTop="12px"
              flexDirection="column"
            >
              <StyledListItem
                active={activeLocal === route ? '1' : undefined}
                button
                onClick={() => {
                  if (!isActiveRoute()) {
                    setActive(route);
                    if (children) {
                      if (!expand) {
                        setExpanded(route);
                      } else {
                        setExpanded('*');
                      }
                    }
                    props.history.push(routeAbs);
                  }
                }}
                {...props}
              >
                <Flex
                  width="100%"
                  gridGap="xsm"
                  alignItems="center"
                  color={isActiveRoute() ? 'highlight.200' : 'highlight.300'}
                >
                  {ActiveIcon && isActiveRoute() ? (
                    <ActiveIcon />
                  ) : (
                    <Icon
                      color={isActiveRoute() ? color.white : color.menuColor}
                    />
                  )}

                  <Text
                    fontSize="md"
                    fontWeight={isActiveRoute() && 'semibold'}
                    color={isActiveRoute() ? 'highlight.200' : 'grey.500'}
                  >
                    {name}
                  </Text>
                </Flex>
                {children ? (
                  <ListItemIcon
                    style={{ margin: 'auto' }}
                    active={isActiveRoute()}
                  >
                    {expand ? <CollapseIcon /> : <ExpandIcon />}
                  </ListItemIcon>
                ) : (
                  <div />
                )}
              </StyledListItem>
              {children && expand ? (
                <SideItems
                  routes={children}
                  expanded={expanded}
                  activeLocal={activeLocal}
                  setActive={setActive}
                  setExpanded={setExpanded}
                  props={props}
                />
              ) : null}
            </Flex>
          </div>
        );
      }
    )}
    <ListItem>
      <Row justify="center">
        <Span weight="bold" color={color.white}>
          2.12 (97)
        </Span>
      </Row>
    </ListItem>
  </Flex>
);
