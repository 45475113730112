import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  IconButton,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FaPlusCircle } from 'react-icons/fa';
import { ServicePointsContext } from '../../../contexts/service_points';
import { ServiceTypesContext } from '../../../contexts/service_types';
import { getRTScheduledReportsOfACompany } from '../../../controllers/snowm_firebase';
import {
  findSortType,
  getLowerCasedWord,
  sortByEntity,
} from '../../../helpers/misc';
import {
  DrawerActiveLocationIcon,
  DrawerLocationIcon,
  ActiveJanitorIcon,
  JanitorIcon,
  ScheduleIcon,
  ActiveScheduleIcon,
} from '../../../reusableComponent/Icons';
import SearchBar from '../../Common/Search_Bar';
import Loader from '../../Generics/snowm_loader';
import { JanitorReports } from './JanitorReport';
import { ScheduleReport } from './ScheduleReport';
import Reports from './snowm_reports';

const reports = [
  {
    name: 'location',
    Icon: DrawerLocationIcon,
    ActiveIcon: DrawerActiveLocationIcon,
  },
  {
    name: 'janitor',
    Icon: JanitorIcon,
    ActiveIcon: ActiveJanitorIcon,
  },
  {
    name: 'schedule',
    Icon: ScheduleIcon,
    ActiveIcon: ActiveScheduleIcon,
  },
];

const ReportsTabs = () => {
  const scheduleReportHeaders = useMemo(() => [
    {
      name: 'Report Name',
      sort: 'asc',
    },
    {
      name: 'Report Type',
      sort: 'asc',
    },
    {
      name: 'Scheduled For',
      sort: 'asc',
    },
    {
      name: 'Emails',
      sort: 'asc',
    },
  ]);

  const { subscribedServices } = useContext(ServiceTypesContext);
  const { fetchServicePoints, markers } = useContext(ServicePointsContext);

  const { scheduledReports: isScheduledReportsEnabled } = subscribedServices;

  const [tabValue, setTabValue] = useState(0);
  const [toggledValue, setToggledValue] = useState('location');
  const [reportsTabs, setReportsTabs] = useState({});

  const [searchText, setSearchText] = useState('');
  const [openSideDrawer, setOpenSideDrawer] = useState(false);
  const [allScheduledReports, setAllScheduledReports] = useState(null);
  const [scheduledReports, setScheduledReports] = useState(null);
  const [scheduleReportHeader, setScheduleReportHeader] = useState(
    scheduleReportHeaders
  );
  const [selectedReports, setSelectedReports] = useState({});
  const [openActionDrawer, setOpenActionDrawer] = useState(true);

  useEffect(() => {
    if (!markers) {
      fetchServicePoints();
    }
  }, []);

  useEffect(() => {
    getRTScheduledReportsOfACompany((res) => {
      const { sort } = findSortType(scheduleReportHeader, 'Report Name');
      res.sort((a, b) => sortByEntity(a.reportName, b.reportName, sort));
      setAllScheduledReports(res);
      setScheduledReports(res);
      setSearchText('');
    });
  }, []);

  useEffect(() => {
    if (isScheduledReportsEnabled) {
      setReportsTabs({ ...reports, scheduleReports: 'Scheduled Report' });
    } else {
      setReportsTabs(reports);
    }
  }, subscribedServices);

  const renderLocationAndMicroLocations = () => {
    if (reports[tabValue]?.name !== 'location') {
      return null;
    }

    return <Reports markers={markers} />;
  };

  const renderScheduleReports = () => {
    if (reports[tabValue]?.name !== 'schedule') {
      return null;
    }
    return (
      <ScheduleReport
        isScheduleModalOpen={openSideDrawer}
        setIsScheduleModalOpen={setOpenSideDrawer}
        allScheduledReports={allScheduledReports}
        setAllScheduledReports={setAllScheduledReports}
        setScheduledReports={setScheduledReports}
        scheduledReports={scheduledReports}
        setScheduleReportHeader={setScheduleReportHeader}
        scheduleReportHeaders={scheduleReportHeaders}
        scheduleReportHeader={scheduleReportHeader}
        setSelectedReports={setSelectedReports}
        selectedReports={selectedReports}
        searchText={searchText}
        setOpenActionDrawer={setOpenActionDrawer}
        openActionDrawer={openActionDrawer}
      />
    );
  };

  const renderJanitorReports = () => {
    if (reports[tabValue]?.name !== 'janitor') {
      return null;
    }
    return <JanitorReports />;
  };

  const handleAddPress = () => {
    setOpenSideDrawer(true);
    setSelectedReports({});
    setOpenActionDrawer(false);
  };

  const _updateScheduleReports = (contents) => {
    setScheduledReports(contents);
  };

  const handleScheduledReports = (e) => {
    const searchWord = e.target.value;
    setSearchText(searchWord);
    const res = allScheduledReports?.reduce((s, curr) => {
      const reportsName = curr.reportName;
      const reportName = getLowerCasedWord(reportsName);
      const searchString = getLowerCasedWord(searchWord);
      if (reportName.includes(searchString)) {
        return [...s, curr];
      }
      return s;
    }, []);

    _updateScheduleReports(res ?? []);
  };
  if (!markers) {
    return <Loader />;
  }
  return (
    <Box bgColor="white" p="12" height="100vh">
      <Stack gridGap="md">
        <Flex justifyContent="space-between" alignItems="center">
          <Heading as="h4">Reports</Heading>
          {toggledValue === 'schedule' && (
            <Button
              onClick={handleAddPress}
              colorScheme="blue"
              leftIcon={<FaPlusCircle />}
              paddingLeft="xsm"
            >
              <Text color="white">Add scheduled report</Text>
            </Button>
          )}
        </Flex>

        <Flex
          height="80px"
          gridGap="md"
          alignItems="center"
          justifyContent="flex-start"
          boxShadow=" rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
          borderRadius="lg"
        >
          <ButtonGroup size="md" isAttached variant="outline" ml="15px">
            {reports.map((report, i) => {
              const { name, Icon, ActiveIcon } = report;
              return (
                <IconButton
                  icon={name === toggledValue ? <ActiveIcon /> : <Icon />}
                  key={name}
                  colorScheme={name === toggledValue ? 'blue' : null}
                  variant={name === toggledValue ? 'solid' : 'outline'}
                  onClick={() => {
                    setToggledValue(name);
                    setTabValue(i);
                  }}
                />
              );
            })}
          </ButtonGroup>

          {toggledValue === 'schedule' ? (
            <SearchBar
              title="Search schedule by name"
              LeftIcon={<SearchIcon />}
              value={searchText}
              onChange={handleScheduledReports}
              setSelectedEntities={setSelectedReports}
              selectedEntities={selectedReports}
            />
          ) : null}
        </Flex>

        <Box>
          {renderLocationAndMicroLocations()}
          {renderJanitorReports()}
          {renderScheduleReports()}
        </Box>
      </Stack>
    </Box>
  );
};

export default ReportsTabs;
