/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_drawer.jsx
 *
 *  Description: Drawer component.
 *
 *  Author: Nabin Kharal (nabin@brainants.com)
 *
 *  Date created: 4-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import { Flex, Image, Text } from '@chakra-ui/react';
import { ListItem, ListItemIcon } from '@material-ui/core';
import ExpandIcon from 'mdi-react/ChevronDownIcon';
import CollapseIcon from 'mdi-react/ChevronRightIcon';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import color from '../../../utils/color';
import { logo } from '../../helpers/misc';

// functional component
/*
 ****************************************************************************
 * @brief  (DrawerView)  Renders the contents of drawer
 * @param{type : Array,string,object } ( routes,active,props)
 *
 * @returns content of the drawer (react element)
 ****************************************************************************
 */
const DrawerView = ({ routes, active, staticContext, ...props }) => {
  const [expanded, setExpanded] = useState(active);
  const [activeLocal, setActive] = useState(active);
  useEffect(() => {
    setActive(active);
    setExpanded(active);
  }, [active]);

  /*
   **************************************************************************************
   * @brief  (SideItems)  renders the items inside the drawer
   * @param{type : Array } (routesToRender)
   *
   * @returns Appropriate React element including all the routes listed in routesToRender
   **************************************************************************************
   */
  const SideItems = ({ routes: routesToRender }) => {
    return (
      <Flex direction="column">
        {routesToRender.map(
          ({ route, name, Icon, children, menu, hide, ActiveIcon }) => {
            const routeAbs = `${route}`;
            const expand = expanded.includes(route, 0);
            const isActiveRoute = () => activeLocal === route;
            if (menu) {
              return null;
            }
            if (hide) {
              return null;
            }

            return (
              <div key={name} style={{ padding: 0 }}>
                <Flex
                  padding="4px 10px"
                  gridGap="16px"
                  marginTop="12px"
                  flexDirection="column"
                >
                  <StyledListItem
                    active={activeLocal === route ? '1' : undefined}
                    button
                    onClick={() => {
                      if (!isActiveRoute()) {
                        setActive(route);
                        if (children) {
                          if (!expand) {
                            setExpanded(route);
                          } else {
                            setExpanded('*');
                          }
                        }
                        props.history.push(routeAbs);
                      }
                    }}
                    {...props}
                  >
                    <Flex
                      width="100%"
                      gridGap="xsm"
                      alignItems="center"
                      color={
                        isActiveRoute() ? 'highlight.200' : 'highlight.300'
                      }
                    >
                      {ActiveIcon && isActiveRoute() ? (
                        <ActiveIcon />
                      ) : (
                        <Icon
                          color={
                            isActiveRoute() ? color.white : color.menuColor
                          }
                        />
                      )}

                      <Text
                        fontSize="md"
                        fontWeight={isActiveRoute() && 'semibold'}
                        color={isActiveRoute() ? 'highlight.200' : 'grey.500'}
                      >
                        {name}
                      </Text>
                    </Flex>
                    {children ? (
                      <ListItemIcon
                        style={{ margin: 'auto' }}
                        active={isActiveRoute()}
                      >
                        {expand ? <CollapseIcon /> : <ExpandIcon />}
                      </ListItemIcon>
                    ) : (
                      <div />
                    )}
                  </StyledListItem>
                </Flex>
              </div>
            );
          }
        )}
      </Flex>
    );
  };

  // returns the Content of SideItems inside of sidebar
  return (
    <div>
      <SideBar {...props}>
        <Flex justifyContent="center">
          <Image src={logo} width={128} />
        </Flex>
        <SideItems routes={routes} />
      </SideBar>
    </div>
  );
};

export default DrawerView;

const StyledListItem = styled(ListItem)`
  && {
    border-radius: 5px;
    padding-left: 20px;
    background: ${(props) =>
      props.active
        ? 'transparent linear-gradient(95deg, #65CAF0 0%, #00AEEF 100%) 0% 0% no-repeat padding-box'
        : 'transparent'} !important;
    box-shadow: ${(props) => props.active && '0px 3px 6px #006db85c'};
    &:hover {
      background: ${(props) => props.theme.palette.primary};
    }
  }
`;

const SideBar = styled.div`
  background-color: white;
  flex-grow: 1;
  height: 100%;
  width: ${(props) => `${props.width}px`};
`;
