import { useContext, useEffect, useState } from 'react';
import { Avatar, Divider, Flex, Image, Text } from '@chakra-ui/react';
import { LocationContext } from '../../contexts/locationContext';
import { ServicePointsContext } from '../../contexts/service_points';
import { getIssueReports } from '../../controllers/snowm_firebase';
import { getComparedDate } from '../../helpers/date';
import { CrewsOfJob } from './JobsTable';

const IssueReports = ({ jobKey, job }) => {
  const [issueReportsOfAJob, setIssueReportsOfAJob] = useState<any[]>();

  const { markers } = useContext(ServicePointsContext);
  const { locations } = useContext(LocationContext);

  useEffect(() => {
    getIssueReports(jobKey).then((allIssueReports) => {
      if (allIssueReports) setIssueReportsOfAJob(allIssueReports);
    });
  }, []);

  const getHours = (dat: number) => {
    return getComparedDate(dat);
  };

  const getLocation = (markerKey) => {
    const propertyKey = markers[markerKey]?.propertyKey;
    const locationName = locations[propertyKey]?.name ?? '--';
    return locationName;
  };

  if (issueReportsOfAJob?.length) {
    return (
      <>
        {issueReportsOfAJob.map((issueReport) => {
          return (
            <>
              <Flex key={issueReport.key} gap="sm">
                <Avatar
                  width="50px"
                  height="50px"
                  name={issueReport?.name}
                  src={issueReport?.imageUrl}
                />
                <Flex flexDirection="column" gap="xxsm">
                  <Flex flexDirection="column">
                    <Flex gap="xxsm">
                      <CrewsOfJob job={job} /> <Text color="grey.300"> - </Text>
                      <Text color="grey.300">{issueReport?.message}</Text>
                    </Flex>

                    <Flex alignSelf="flex-start" gap="xxsm">
                      <Text color="grey.500" fontSize="mdsm">
                        {getLocation(issueReport?.markerKey)}
                      </Text>
                      <Text
                        fontSize="mdl"
                        lineHeight="10px"
                        paddingBottom="xsm"
                        color="grey.500"
                        fontWeight="extrabold"
                      >
                        .
                      </Text>

                      <Text color="grey.500" fontSize="mdsm">
                        {getHours(issueReport?.date)}
                      </Text>
                    </Flex>
                  </Flex>

                  <Flex gap="xsm">
                    {issueReport?.imageUrls.map((imageUrl) => {
                      return (
                        <Flex boxSize="80px" key={issueReport.key}>
                          <Image
                            src={imageUrl}
                            borderRadius="sm"
                            onClick={() => window.open(imageUrl, '_blank')}
                          />
                        </Flex>
                      );
                    })}
                  </Flex>
                </Flex>
              </Flex>
              <Divider pt="xsm" />
            </>
          );
        })}
      </>
    );
  }
  return <Text>No Issue Reported</Text>;
};

export default IssueReports;
