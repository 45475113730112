import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';

import IndoorRouteDetail from './snowm_indoor_route_detail';
import OutdoorRouteDetail from './snowm_outdoor_route_detail';
import AttendanceRouteDetail from './snowm_attendance_route_detail';
import {
  getServiceInfo,
  getRouteByKey,
} from '../../../controllers/snowm_firebase';

const RouteDetail = (props) => {
  const [service, setService] = useState(null);
  const [routeInfo, setRouteInfo] = useState(null);

  const { history, match } = props;

  if (!match.params.routeKey) {
    history.push('/admin/routes');
  }
  const { routeKey } = match.params;

  useEffect(() => {
    let isSubscribe = true;
    getRouteByKey(routeKey).then((info) => {
      if (isSubscribe) setRouteInfo(info);
    });

    return () => {
      isSubscribe = false;
    };
  }, [routeKey]);

  useEffect(() => {
    let isSubscribe = true;
    if (routeInfo) {
      getServiceInfo(routeInfo.serviceKey).then((srv) => {
        if (isSubscribe) {
          setService(srv);
        }
      });
    }

    return () => {
      isSubscribe = false;
    };
  }, [routeInfo]);

  if (service && routeInfo) {
    if (service.type === 'indoor') {
      return (
        <IndoorRouteDetail
          {...props}
          type={service.type}
          routeInfo={routeInfo}
        />
      );
    }

    if (service.type === 'attendance') {
      return <AttendanceRouteDetail routeInfo={routeInfo} />;
    }
    return (
      <OutdoorRouteDetail
        {...props}
        type={service.type}
        routeInfo={routeInfo}
      />
    );
  }
  return <CircularProgress />;
};

export default RouteDetail;
