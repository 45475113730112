import {
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@material-ui/core';
import { Avatar, Flex, Box, Progress } from '@chakra-ui/react';

import Span from '../Common/Span';
import color from '../../utils/color';

const CompaniesList = ({ companiesInDetail, handleCompanySelection }) => {
  return (
    <List
      style={{
        overflow: 'auto',
        padding: 0,
      }}
    >
      {companiesInDetail?.map((company, i) => {
        if (!company) {
          return null;
        }

        return (
          <>
            <ListItem
              button
              style={{
                padding: 0,
                paddingBottom: '15px',
                paddingTop: '15px',
                width: '375px',
              }}
              onClick={() => handleCompanySelection(company)}
              key={company.key}
            >
              <Avatar size="sm" name={company.name} mr={3} />

              <ListItemText
                primary={
                  <Span weight="bold" size="14px" color={color.snomwBlue}>
                    {company.name}
                  </Span>
                }
                secondary={
                  <Span color="#17244F" size="11px">
                    {company.address}
                  </Span>
                }
              />
            </ListItem>
            {companiesInDetail.length !== i + 1 && <Divider />}
          </>
        );
      })}
    </List>
  );
};

export default CompaniesList;
