import React from 'react';

import styled from 'styled-components';
import { Box } from '@material-ui/core';
import PlusIcon from 'mdi-react/PlusIcon';

import Span from './Span';
import color from '../../utils/color';

const AddButton = ({ onPress, title }) => (
  <ProviderCard boxShadow={10} onClick={onPress}>
    <ButtonContainer>
      <PlusIcon size={30} color={color.white} />
      <Span color="white">{title}</Span>
    </ButtonContainer>
  </ProviderCard>
);

const ProviderCard = styled(Box)`
  && {
    height: 50px;
    position: fixed;
    bottom: 16px;
    right: 16px;
    display: flex;
    cursor: pointer;
    border-radius: 10px;
    z-index: 2;
    background-image: linear-gradient(to right, #00afee, #006eb9);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
`;

export default AddButton;
