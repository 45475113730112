/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: snowm_service_points.jsx
 *
 *  Description: Show the list of service points
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 8-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import { SearchIcon } from '@chakra-ui/icons';
import { Button, Flex, Text, useToast } from '@chakra-ui/react';
import SortBoolAscendingVariantIcon from 'mdi-react/SortBoolAscendingVariantIcon';
import SortBoolDescendingVariantIcon from 'mdi-react/SortBoolDescendingVariantIcon';
import { useContext, useEffect, useState } from 'react';
import { FaPlusCircle } from 'react-icons/fa';
import { LocationContext } from '../../../contexts/locationContext';
import { ServicePointsContext } from '../../../contexts/service_points';
import MicroLocationImage from '../../../assets/svgs/MicroLocationImage.svg';

import {
  deleteServicePoint,
  getPendingJobsByMarkerKey,
} from '../../../controllers/snowm_firebase';
import {
  filterDataByName,
  findSortType,
  getFilteredItems as getFilteredMarkers,
  sortByEntity,
} from '../../../helpers/misc';
import { DetailsDrawer } from '../../../reusableComponent/DetailsDrawer';
import EmptyComponent from '../../../reusableComponent/EmptyComponent';
import { SideDrawer } from '../../../reusableComponent/SideDrawer';
import SearchBar from '../../Common/Search_Bar';
import Loader from '../../Generics/snowm_loader';
import MarkersTable, { microLocationBoxInDeleteDialog } from './MarkersTable';
import MarkerDetails from './micro_location_details';
import AddServicePoint from './snowm_add_micro_locations';

const initialMicroLocations = {
  name: '',
  note: '',
  beaconId: '',
  beaconName: '',
  captureImage: false,
};
const MircoLocationsConsumer = (servicePointProps) => (
  <ServicePointsContext.Consumer>
    {({ markers, fetchServicePoints }) => (
      <MicroLocations
        markers={markers}
        fetchServicePoints={fetchServicePoints}
        {...servicePointProps}
      />
    )}
  </ServicePointsContext.Consumer>
);

const headers = [
  { name: 'Name', sort: 'asc' },
  { name: 'Location', sort: 'asc' },
  { name: 'Location Address', sort: 'asc' },
];

const MicroLocations = ({ fetchServicePoints = Function, markers }) => {
  const { locations, getAllPropertiesOfACompany } = useContext(LocationContext);

  const [isEdit, setIsEdit] = useState(false);
  const [sortType, setSortType] = useState('asc');
  const [searchText, setSearchText] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [markerNames, setMarkerNames] = useState([]);

  const [microLocationHeaders, setMicroLocationHeaders] = useState(headers);
  const [openActionDrawer, setOpenActionDrawer] = useState(true);
  const [selectedMarker, setSelectedMarker] = useState();
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [searchedMarkers, setSearchedMarkers] = useState([]);
  const [filteredMarkers, setFilteredMarkers] = useState();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedServiceKey, setSelectedServiceKey] = useState('all');
  const [microLocations, setMicroLocations] = useState(initialMicroLocations);

  const [selectedServicePoint, setSelectedServicePoint] = useState({});
  const [pendingJobsWarning, setPendingJobsWarning] = useState(0);

  const [selectedMicroLocations, setSelectedMicroLocations] = useState({});

  const toast = useToast();

  const renderMicroLocationDetails = () => {
    return (
      <MarkerDetails details={selectedMarker} setShowDetails={setShowDetails} />
    );
  };

  // Todo Schedule
  const renderScheduleDetails = () => {
    return <h1>No schedule</h1>;
  };
  const detailTabs = {
    Details: renderMicroLocationDetails(),
    Schedule: renderScheduleDetails(),
  };

  useEffect(() => {
    if (!markers) fetchServicePoints();
  }, []);

  useEffect(() => {
    if (!locations) getAllPropertiesOfACompany();
  }, []);

  useEffect(() => {
    let names = [];
    let result = [];
    if (searchedMarkers.length > 0) {
      result = getFilteredMarkers(searchedMarkers, selectedServiceKey);
    } else {
      result = getFilteredMarkers(markers, selectedServiceKey);
    }

    if (markers) {
      Object.values(markers).forEach((marker) => {
        names = [...names, marker.name.toLowerCase()];
      });
      setMarkerNames(names);
    }

    const serchedMicroLocation = Object.values(result);

    const { sort } = findSortType(microLocationHeaders, 'Name');

    serchedMicroLocation.sort((a, b) => sortByEntity(a.name, b.name, sort));

    setFilteredMarkers(serchedMicroLocation);
  }, [markers, selectedServiceKey]);

  useEffect(() => {
    if (isSearch) {
      const markersAfterFilter = filterDataByName(searchText, markers);
      setFilteredMarkers(Object.values(markersAfterFilter));
    }
  }, [searchText]);

  function onAddButtonPress() {
    let names = [];
    Object.values(markers).forEach((marker) => {
      names = [...names, marker.name.toLowerCase()];
    });

    setOpenActionDrawer(false);
    setMarkerNames(names);
    setOpenAddDialog(true);
    setMicroLocations({});
  }
  function handleCloseAddDialog() {
    setOpenActionDrawer(true);
    setOpenAddDialog(false);
    setIsEdit(false);
  }

  function handleCloseDialog() {
    setOpenDeleteDialog(false);
    setPendingJobsWarning(0);
  }

  function onEditPress(marker) {
    const selectedMicroLocation = markers[marker.key];
    setShowDetails(false);
    setOpenActionDrawer(false);
    setOpenAddDialog(true);
    setMicroLocations(selectedMicroLocation);
    setIsEdit(true);
  }

  async function onDeletePress(servicePoint) {
    const noOfJobs = await getPendingJobsByMarkerKey(servicePoint?.key);
    if (noOfJobs > 0) {
      setPendingJobsWarning(noOfJobs);
    } else {
      setOpenDeleteDialog(true);
    }
    setSelectedServicePoint(servicePoint);
  }

  async function deleteServicePointData(selectedMicroLocationToDelete) {
    const { key: microLocationKey, name } = selectedMicroLocationToDelete;
    try {
      await deleteServicePoint(microLocationKey);
      setShowDetails(false);
      toast({
        duration: 3000,
        title: 'Deleted Micro-Location',
        isClosable: true,
        status: 'success',
        description: `Micro-Location ${name} deleted successfully.`,
      });
    } catch (err) {
      toast({
        duration: 3000,
        title: 'Error!',
        status: 'error',
        isClosable: true,
        description: 'Unable to delete microlocation.',
      });
    }
  }

  const handleSearchInput = (e) => {
    setSearchText(e.target.value);
    setIsSearch(true);
  };

  function handleMarkerItemPressed(marker) {
    setOpenActionDrawer(false);
    setShowDetails(true);
    setSelectedMarker(marker);
  }

  if (!markers) {
    return <Loader />;
  }

  const sortIconButton = () => {
    if (sortType === 'asc') {
      return <SortBoolAscendingVariantIcon size={30} />;
    }
    return <SortBoolDescendingVariantIcon size={30} />;
  };

  const handleChangeSort = () => {
    switch (sortType) {
      case 'asc': {
        setSortType('desc');
        break;
      }
      case 'desc': {
        setSortType('asc');
        break;
      }

      default:
        setSortType(sortType);
    }
  };

  async function deleteMicroLocation(microlocation) {
    return deleteServicePoint(microlocation.key);
  }

  const handleDelete = () => {
    deleteServicePointData(selectedMarker);
  };

  if (filteredMarkers) {
    return (
      <Flex
        p="12"
        overflow="auto"
        bg="background.500"
        height="100%"
        flexDirection="column"
        gridGap="sm"
      >
        <Flex direction="column" gridGap="llg">
          <Flex justifyContent="space-between" alignItems="center">
            <Text fontSize="xl" fontWeight="600">
              Micro-Locations
            </Text>
            <Button
              onClick={onAddButtonPress}
              colorScheme="blue"
              leftIcon={<FaPlusCircle />}
              paddingLeft="xsm"
            >
              <Text color="white" fontWeight="600">
                Add Micro-Location
              </Text>
            </Button>
          </Flex>
          <SearchBar
            title="Micro Location"
            LeftIcon={<SearchIcon />}
            onChange={handleSearchInput}
            setSelectedEntities={setSelectedMicroLocations}
            selectedEntities={selectedMicroLocations}
          />
        </Flex>

        {filteredMarkers?.length ? (
          <MarkersTable
            searchText={searchText}
            markers={filteredMarkers}
            onEditIconPress={onEditPress}
            onDeletePress={deleteMicroLocation}
            openActionDrawer={openActionDrawer}
            onViewPress={handleMarkerItemPressed}
            setFilteredMarkers={setFilteredMarkers}
            setOpenActionDrawer={setOpenActionDrawer}
            microLocationHeaders={microLocationHeaders}
            selectedMicroLocations={selectedMicroLocations}
            setMicroLocationHeaders={setMicroLocationHeaders}
            setSelectedMicroLocations={setSelectedMicroLocations}
          />
        ) : (
          <EmptyComponent
            isSearch={!!searchText}
            header="Micro Locations"
            zeroStateImage={MicroLocationImage}
            title="Manage your Micro-Locations here"
            subTitle="Click + add Micro-Location button at top right corner to create your first micro-location "
          />
        )}

        <SideDrawer
          open={openAddDialog}
          title={isEdit ? 'Edit Micro-Location ' : 'Add Micro-Location'}
          negativeAction={handleCloseAddDialog}
        >
          <AddServicePoint
            edit={isEdit}
            names={markerNames}
            microLocations={microLocations}
            setMicroLocations={setMicroLocations}
            handleCloseDialog={handleCloseAddDialog}
            selectedMicroLocations={selectedMicroLocations}
            setSelectedMicroLocations={setSelectedMicroLocations}
          />
        </SideDrawer>

        {/* MIcroLocation details */}

        <DetailsDrawer
          open={showDetails}
          title={selectedMarker?.name}
          subtitle={locations?.[selectedMarker?.propertyKey]?.name ?? ''}
          details={selectedMarker}
          onClose={() => setShowDetails(false)}
          detailTabs={detailTabs}
          onEdit={() => onEditPress(selectedMarker)}
          deleteSubtitle="Remember,this action cannot be undone."
          body={microLocationBoxInDeleteDialog([selectedMarker])}
          setOpenDeleteDialog={setOpenDeleteDialog}
          onDelete={handleDelete}
        />
      </Flex>
    );
  }
  return <Loader />;
};

export default MircoLocationsConsumer;
