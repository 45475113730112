import { useContext, useEffect, useRef, useState } from 'react';

import { TriangleDownIcon } from '@chakra-ui/icons';
import {
  Button,
  Checkbox,
  Flex,
  Icon,
  SimpleGrid,
  Stack,
  Text,
  toast,
} from '@chakra-ui/react';
import DateFnsUtils from '@date-io/moment';
import { CircularProgress } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { withStyles } from '@material-ui/styles';
import dayjs from 'dayjs';
import CalendarCheckIcon from 'mdi-react/CalendarCheckIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import moment from 'moment';
import { Redirect } from 'react-router';
import styled from 'styled-components';
import exportPdf from '../../../assets/svgs/exportPdf.svg';
import { ServiceTypesContext } from '../../../contexts/service_types';
import { LocalizationContext } from '../../../contexts/localization_context';
import { getDataForPDFForServiceRoute } from '../../../controllers/pdf_helper';
import { ReactComponent as BlueMicrolocationImage } from '../../../assets/svgs/BlueMicroLocation.svg';
import {
  getAllServiceRoutes,
  getGeneratedReport,
  getPropertiesFromFirestore,
  getProvidersOfCompany,
} from '../../../controllers/snowm_firebase';
import { getYearMonthDayFormat } from '../../../helpers/date';
import { LocationIcon } from '../../../reusableComponent/Icons';
import { CustomInput } from '../../../reusableComponent/CustomInput';
import { DescriptionTitle } from '../../../reusableComponent/DescriptionTitle';
import { CustomSelect } from '../../../reusableComponent/shiftSchedule/custom_select';
import { SideDrawer } from '../../../reusableComponent/SideDrawer';

const Reports = ({ markers }) => {
  const types = useContext(ServiceTypesContext);
  const { locations } = useContext(LocalizationContext);

  const [providers, setProviders] = useState([]);
  const [dateOfReport, setDateOfReport] = useState('');

  const [selectedProvider, setSelectedProvider] = useState({ name: '' });
  const [serviceRoutes, setServiceRoutes] = useState([]);
  const [selectedServiceRoute, setSelectedServiceRoute] = useState({
    name: '',
  });
  const [pdfDataRoute, setPdfDataRoute] = useState({});
  const [pdfDataProperties, setPdfDataProperties] = useState();

  const [startDate, setStartDate] = useState({
    LocationSchedule: null,
    serviceRoute: null,
    markers: null,
    properties: null,
  });
  const [endDate, setEndDate] = useState({
    LocationSchedule: null,
    serviceRoute: null,
    markers: null,
    properties: null,
  });

  const [errorMessage, setErrorMessage] = useState({
    startDate: '',
    endDate: '',
    selectedValue: '',
  });

  const [crewLoading, setCrewLoading] = useState(false);
  const [routeLoading, setRouteLoading] = useState(false);
  const [markerLoading, setMarkerLoading] = useState(false);
  const [propertiesLoading, setPropertiesLoading] = useState(false);
  const [pdfDataMarker, setPdfDataMarker] = useState();

  const [reportType, setReportType] = useState(null);

  const [optionMarkers, setOptionMarkers] = useState([]);
  const [properties, setProperties] = useState();
  const [selectedMarkers, setSelectedMarkers] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState();
  const [pdfURL, setPdfURL] = useState('');
  const [selectImages, setSelectImages] = useState({
    crew: false,
    markers: false,
    routes: false,
    properties: false,
  });

  const errorsByType = {
    crew: '',
    marker: '',
    route: '',
    property: '',
  };
  const [errors, setErrors] = useState(errorsByType);

  const anchorRef = useRef(null);

  const noDataError = '*No data to be reported for the selection.';

  const data = useContext(ServiceTypesContext);
  const subscribedServices = data?.subscribedServices;

  const dateTime = moment().format('YYYY_MM_DD_hm_A');

  useEffect(() => {
    if (pdfURL && anchorRef.current) {
      anchorRef.current.click();
    }
  }, [pdfURL]);

  useEffect(() => {
    setDateOfReport(`Marker_Exported_${dateTime}`);
  }, [dateTime]);

  useEffect(() => {
    if (subscribedServices?.markerBased && markers) {
      const labelledMarkers = Object.values(markers ?? {})?.map((marker) => ({
        label: marker?.name,
        value: marker?.key,
        data: marker,
      }));
      setOptionMarkers(labelledMarkers);
    }
  }, [subscribedServices, markers, reportType]);

  useEffect(() => {
    let isSubscribe = true;
    function _updateProperties(res) {
      const propertiesOptions = res?.map((property) => ({
        label: property?.name,
        value: property,
      }));
      if (isSubscribe) {
        setProperties(propertiesOptions);
      }
    }

    if (subscribedServices?.properties) {
      getPropertiesFromFirestore(_updateProperties);
    }
    return () => {
      isSubscribe = false;
    };
  }, [subscribedServices]);

  const onRouteSubmit = async () => {
    setPdfDataRoute({});
    setErrors(errorsByType);
    setRouteLoading(true);
    if (selectedServiceRoute.name) {
      getDataForPDFForServiceRoute(
        selectedServiceRoute,
        startDate.serviceRoute,
        endDate.serviceRoute,
        selectImages.routes,
        (pdfData) => {
          if (!pdfData.servedJobs?.length) {
            setErrors({ ...errorsByType, route: noDataError });
          } else {
            setPdfDataRoute(pdfData);
          }
          setRouteLoading(false);
        }
      );
    } else {
      alert('Please select route to export pdf.');
      setRouteLoading(false);
    }
  };

  async function onSubmit(e, type) {
    e.preventDefault();

    if (type === 'properties' || type === 'locationShedule') {
      if (!startDate.properties || !endDate.properties) {
        setErrorMessage({
          ...errorMessage,
          endDate: 'Dates should not be empty.',
          startDate: 'Dates should not be empty.',
        });
        return;
      }
      if (startDate.properties > endDate.properties) {
        setErrorMessage({
          ...errorMessage,
          startDate: 'Start date should be lesser than the end date.',
        });
        return;
      }

      if (!selectedProperty) {
        setErrorMessage({
          ...errorMessage,
          selectedValue: 'Please select location to export pdf',
        });

        return;
      }
      setPropertiesLoading(true);
      setErrors(errorsByType);

      setPdfDataProperties();
      setCrewLoading(true);

      try {
        const response = await getGeneratedReport({
          convertTo: 'pdf',
          key: { name: 'locationKey', id: selectedProperty?.id },
          toDate: getYearMonthDayFormat(endDate.properties),
          fromDate: getYearMonthDayFormat(startDate.properties),
          showImage: selectImages?.properties,
          createReportName:
            type === properties
              ? 'reports-createLocationReport'
              : 'reports-createLocationScheduleReport',
        });

        if (response?.data?.success) {
          setPdfURL(response?.data?.url?.[0]);
        }
        setCrewLoading(false);
      } catch (err) {
        setCrewLoading(false);
        toast({
          title: 'Error while creating report',
          duration: 3000,
        });
      }

      setPropertiesLoading(false);
    }

    if (type === 'markers') {
      if (!startDate.markers || !endDate.markers) {
        setErrorMessage({
          ...errorMessage,
          endDate: 'Dates shouldnot be empty.',
          startDate: 'Dates shouldnot be empty.',
        });

        return;
      }

      if (startDate.markers > endDate.markers) {
        setErrorMessage({
          ...errorMessage,
          startDate: 'Start date should be lesser than the end date',
        });

        return;
      }

      if (!selectedMarkers) {
        setErrorMessage({
          ...errorMessage,
          selectedValue: 'Please select at least one microlocation',
        });
        return;
      }
      setMarkerLoading(true);
      setErrors(errorsByType);

      setPdfDataMarker();
      setCrewLoading(true);

      const microLocationKeys = selectedMarkers.reduce(
        (microLocationkeys, microLocations) => {
          microLocationkeys.push(microLocations.key);

          return microLocationkeys;
        },
        []
      );

      try {
        setCrewLoading(false);
        const response = await getGeneratedReport({
          convertTo: 'pdf',
          key: { name: 'microLocationsKeys', id: microLocationKeys },
          toDate: getYearMonthDayFormat(endDate.markers),
          fromDate: getYearMonthDayFormat(startDate.markers),
          createReportName: 'reports-createMicroLocationServiceReport',
          showImage: selectImages?.markers,
        });

        if (response?.data?.success) {
          setPdfURL(response?.data?.url?.[0]);
        }
        setMarkerLoading(false);
      } catch (err) {
        setCrewLoading(false);

        console.log(err.data);
      }
    }
  }

  const handleProviderChange = (selectedCrew) => {
    setSelectedProvider(selectedCrew?.value);
  };

  const handleRouteChange = (event) => {
    setSelectedServiceRoute(event.target.value);
  };

  const handleStartDateChange = (date, type) => {
    setStartDate({ ...startDate, [type]: dayjs(date).valueOf() });
  };

  const handleEndDateChange = (date, type) => {
    setEndDate({ ...endDate, [type]: dayjs(date).valueOf() });
  };
  useEffect(() => {
    let isSubscribe = true;
    getProvidersOfCompany(null, null, true).then((prov) => {
      if (isSubscribe) {
        const crews = prov?.map((crew) => ({
          label: crew?.name,
          value: crew,
        }));
        setProviders(crews);
      }
    });
    getAllServiceRoutes().then((servRoutes) => {
      if (isSubscribe) setServiceRoutes(servRoutes);
    });

    return () => {
      isSubscribe = false;
    };
  }, []);

  useEffect(() => {
    if (selectedMarkers) {
      const a = [...optionMarkers];
      const filtered = a?.filter((marker) => {
        return !selectedMarkers?.find((selectedmarker) => {
          return selectedmarker?.value === marker?.value;
        });
      });

      setOptionMarkers([...filtered]);
    }
  }, [selectedMarkers]);

  function handleMarkerSelect(markersSelected) {
    setSelectedMarkers(markersSelected);
  }

  function handleValueChange(propertySelected, type) {
    switch (type) {
      case 'properties': {
        setSelectedProperty(propertySelected?.value);
        break;
      }
      default:
    }
  }

  function doRenderRoute() {
    return subscribedServices?.indoor || subscribedServices?.outdoor;
  }

  function handleChange(e) {
    e.stopPropagation();
    setSelectImages({ ...selectImages, [e.target.name]: e.target.checked });
  }

  function renderReportDrawerContent() {
    switch (reportType) {
      case 'Micro-Location':
        return (
          subscribedServices.markerBased && (
            <ReportByType
              title="micro-Location"
              load={markerLoading}
              onChange={handleMarkerSelect}
              onSubmit={(e) => onSubmit(e, 'markers')}
              options={optionMarkers}
              pdfData={pdfDataMarker}
              startDate={startDate}
              endDate={endDate}
              handleStartDateChange={handleStartDateChange}
              handleEndDateChange={handleEndDateChange}
              selectMulti
              selectTitle="Micro-Location"
              selectedValue={selectedMarkers ?? []}
              setOptions={setOptionMarkers}
              type="markers"
              selectImage={selectImages?.markers}
              handleChange={handleChange}
              noDataError={errors.marker}
              showImage
              errorMessage={errorMessage}
              crewLoading={crewLoading}
            />
          )
        );

      case 'Location':
        return (
          subscribedServices?.properties && (
            <ReportByType
              key="properties"
              title="Location Report"
              selectTitle="Location"
              options={properties}
              startDate={startDate}
              endDate={endDate}
              handleStartDateChange={handleStartDateChange}
              handleEndDateChange={handleEndDateChange}
              type="properties"
              onChange={(selectedOption) =>
                handleValueChange(selectedOption, 'properties')
              }
              load={propertiesLoading}
              onSubmit={(e) => onSubmit(e, 'properties')}
              selectedValue={selectedProperty ?? {}}
              pdfData={pdfDataProperties}
              selectImage={selectImages.properties}
              handleChange={handleChange}
              noDataError={errors.property}
              showImage
              errorMessage={errorMessage}
              crewLoading={crewLoading}
            />
          )
        );

      case 'Location Schedule':
        return (
          subscribedServices?.properties && (
            <ReportByType
              key="properties"
              title="Location Report"
              selectTitle="Location"
              options={properties}
              startDate={startDate}
              endDate={endDate}
              handleStartDateChange={handleStartDateChange}
              handleEndDateChange={handleEndDateChange}
              type="properties"
              onChange={(selectedOption) =>
                handleValueChange(selectedOption, 'properties')
              }
              load={propertiesLoading}
              onSubmit={(e) => onSubmit(e, 'properties')}
              selectedValue={selectedProperty ?? {}}
              pdfData={pdfDataProperties}
              selectImage={selectImages.properties}
              handleChange={handleChange}
              noDataError={errors.property}
              showImage={false}
              errorMessage={errorMessage}
              crewLoading={crewLoading}
            />
          )
        );

      default:
        return 'No report service available';
    }
  }

  function handleCancelButton() {
    setReportType(null);
    setStartDate({
      LocationSchedule: null,
      serviceRoute: null,
      markers: null,
      properties: null,
    });
    setEndDate({
      LocationSchedule: null,
      serviceRoute: null,
      markers: null,
      properties: null,
    });
    setSelectedProperty();
    setSelectedMarkers();
    setPdfURL('');
    setErrorMessage({
      startDate: '',
      endDate: '',
      selectedValue: '',
    });
  }

  if (!subscribedServices) {
    return <StyledCircularProgress size={24} />;
  }

  if (!subscribedServices.reports) {
    return <Redirect to="/admin" />; // TODO: might need to change routing
  }
  return (
    <>
      <SimpleGrid columns={[1, null, 2]} gap="xl">
        <DescriptionTitle
          title="Location"
          icon={LocationIcon}
          onClick={() => {
            setReportType('Location');
          }}
          subTitle="Location report with detailed timings and tasks performed."
        />

        {/* Todo :Change location schedule Icon later */}

        <DescriptionTitle
          title="Location Schedule"
          icon={CalendarCheckIcon}
          onClick={() => {
            setReportType('Location Schedule');
          }}
          subTitle="Location report with detailed timings."
        />
        <DescriptionTitle
          title="Micro-Location"
          icon={BlueMicrolocationImage}
          onClick={() => {
            setReportType('Micro-Location');
          }}
          subTitle="Micro-Location report with detailed timings and tasks performed."
        />
      </SimpleGrid>

      <SideDrawer
        open={!!reportType}
        title={`${reportType} Report`}
        negativeAction={handleCancelButton}
      >
        {renderReportDrawerContent()}
      </SideDrawer>

      <a
        href={pdfURL}
        ref={anchorRef}
        target="_blank"
        rel="noreferrer"
        style={{ opacity: 0, height: 0, position: 'absolute' }}
      >
        Here
      </a>
    </>
  );
};

export const ReportByType = ({
  selectTitle,
  options,
  onChange,
  selectMulti,
  type,
  onSubmit,
  selectImage,
  handleChange,
  setOptions,
  crewLoading,
  startDate,
  endDate,
  handleStartDateChange,
  handleEndDateChange,
  selectedValue,
  showImage,
  errorMessage,
}) => {
  const dateTime = moment().format('YYYY_MM_DD_hm_A');

  const [dateOfReport, setDateOfReport] = useState();
  const [selectedMicroLocations, setSelectedMicroLocations] = useState([]);

  useEffect(() => {
    setDateOfReport(`Marker_Exported_${dateTime}`);
  }, [dateTime]);

  useEffect(() => {
    if (selectMulti && selectedValue) {
      setSelectedMicroLocations([...selectedMicroLocations, ...selectedValue]);
    }
  }, [selectedValue]);

  const removeFromSelectedOptions = (microLocation) => {
    const microlocations = [...selectedMicroLocations];
    const filteredMicroLocation = microlocations.filter((microlocation) => {
      return microlocation !== microLocation;
    });
    setSelectedMicroLocations(filteredMicroLocation);

    setOptions([...options, microLocation]);
  };

  const selectedName =
    selectedValue instanceof Array
      ? selectedValue[0]?.name
      : selectedValue.name;

  const getValues = () => {
    if (!selectMulti && selectedValue.name)
      return { label: selectedValue?.name, value: selectedValue?.name };
    return null;
  };

  const showMicroLocation = () => {
    return (
      <Flex gridGap="sm" flexWrap="wrap">
        {selectedMicroLocations.map((selectedMicroLocation) => {
          const {
            label,
            value,
            data: { key },
          } = selectedMicroLocation;
          return (
            <Flex
              width="fit-content"
              border="1px solid #8A91A6"
              gridGap="xxsm"
              alignItems="center"
              key={key}
              p="xxsm"
              borderRadius="sm"
              color="grey.500"
            >
              {/*  Todo uncomment Avatar later */}
              {/* <Avatar size="10" name="n" /> */}
              <Text>{label}</Text>
              <Icon
                onClick={() => removeFromSelectedOptions(selectedMicroLocation)}
                colorScheme="800"
                cursor="pointer"
                as={CloseIcon}
              />
            </Flex>
          );
        })}
      </Flex>
    );
  };

  return (
    <form onSubmit={onSubmit}>
      <Flex gridGap="llg" direction="column">
        <Stack spacing="md">
          <RenderDatePickerss
            startDate={startDate}
            endDate={endDate}
            handleStartDateChange={handleStartDateChange}
            handleEndDateChange={handleEndDateChange}
            type={type}
            errorMessage={errorMessage}
          />
          <CustomInput
            type="children"
            placeholder={`Select ${selectTitle}`}
            value={getValues()}
            error={!!errorMessage?.selectedValue && !selectedName}
            errorMessage={errorMessage?.selectedValue}
          >
            <CustomSelect
              isMulti={selectMulti ?? false}
              options={options}
              onChange={onChange}
              placeholder={`Select ${selectTitle} *`}
              value={getValues()}
            />
          </CustomInput>

          {selectedValue && showMicroLocation()}

          {showImage && (
            <Flex gridGap="md">
              <Checkbox
                isChecked={!!selectImage}
                onChange={handleChange}
                name={type}
              />
              <Text>Show Images</Text>
            </Flex>
          )}
        </Stack>

        <Stack spacing="md">
          <Button
            isFullWidth
            variant="solid"
            colorScheme="blue"
            onClick={onSubmit}
            leftIcon={exportPdf && <exportPdf />}
            disabled={crewLoading}
          >
            Export PDF
          </Button>
          {/* // TODO: Excel report */}
          {/* <Button isFullWidth variant="outline" leftIcon={<exportExcel />}>
            Export Excel
          </Button> */}
        </Stack>
      </Flex>
    </form>
  );
};

const DownLoadPdf = ({ url, dateOfReport }) => {
  useEffect(() => {
    if (url) {
      const a = document.createElement('a');
      a.href = url;
      a.download = `${dateOfReport}.pdf`;
      a.click();
      a.remove();
    }
  }, [url]);
  return null;
};

const StyledCircularProgress = styled(CircularProgress)`
  && {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -8px;
    margin-left: -12px;
  }
`;

export default Reports;

export const CssDateField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#006DB8',
    },
    '& .MuiInputBase-input': {
      fontSize: '16px',
      padding: '14px 14.5px',
    },
    '& .MuiFormLabel-asterisk': {
      display: 'none',
    },
    '& .chakra-text css-wca5hg': {
      margin: '0px',
    },
    '&.MuiIconButton-root:hover': {
      background: 'transparent',
    },

    '& .MuiInput-underline:after': {
      border: '1px solid #006DB8',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#D3D3D3',
      },
      '&:hover fieldset': {
        borderColor: '#D3D3D3',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#D3D3D3',
      },
    },
  },
})(KeyboardDatePicker);

export const RenderDatePickerss = (props) => {
  const {
    startDate,
    endDate,
    type,
    errorMessage,
    handleStartDateChange,
    handleEndDateChange,
  } = props;
  const [openStartPicker, setOpenStartPicker] = useState(false);
  const [openEndPicker, setOpenEndPicker] = useState(false);

  const handleEndDateOpen = () => {
    if (startDate[type]) {
      setOpenEndPicker(true);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Stack spacing="md">
        <CssDateField
          required
          open={openStartPicker}
          onClick={() => setOpenStartPicker(true)}
          onClose={() => setOpenStartPicker(false)}
          minutesStep={15}
          label={
            <Text fontSize={16} color={startDate[type] ? 'blue' : 'grey'}>
              Select start date *
            </Text>
          }
          inputVariant="outlined"
          value={startDate[type]}
          onChange={(date) => handleStartDateChange(date, type)}
          keyboardIcon={
            <TriangleDownIcon
              style={{
                width: '12px',
                height: '7px',
                marginRight: '-10px',
              }}
            />
          }
          inputProps={{
            style: {
              borderRight: '1px solid #D3D3D3',
            },
          }}
          format="YYYY/MM/DD"
          colorScheme="blue"
          maxDate={new Date()}
        />
        <Text color="red.200" fontSize="sm">
          {!!errorMessage?.startDate && !startDate[type]
            ? errorMessage?.startDate
            : null}
        </Text>

        <CssDateField
          required
          onClick={handleEndDateOpen}
          open={openEndPicker}
          onClose={() => setOpenEndPicker(false)}
          minutesStep={15}
          label={
            <Text fontSize={16} color={endDate[type] ? 'blue' : 'grey'}>
              Select end date *
            </Text>
          }
          inputVariant="outlined"
          value={endDate[type]}
          onChange={(date) => handleEndDateChange(date, type)}
          keyboardIcon={
            <TriangleDownIcon
              style={{
                width: '12px',
                height: '7px',
                marginRight: '-10px',
              }}
            />
          }
          inputProps={{
            style: {
              borderRight: '1px solid #D3D3D3',
            },
          }}
          disabled={!startDate[type]}
          format="YYYY/MM/DD"
          maxDate={new Date()}
          minDate={startDate[type]}
        />
        <Text color="red.200" fontSize="sm">
          {errorMessage?.endDate}
        </Text>
      </Stack>
    </MuiPickersUtilsProvider>
  );
};
