import { useRef, useState, useEffect } from 'react';

import Select from 'react-select';

// eslint-disable-next-line
export const CustomSelect = (props) => {
  const [value, setValue] = useState('');
  const { required, isDisabled, onChange, autoFocus, ...rest } = props;
  const selectRef = useRef(null);
  const enableRequired = !isDisabled;

  useEffect(() => {
    if (autoFocus) {
      selectRef.current.focus();
    }
  }, [props]);

  const handleOnChange = (selectedOption) => {
    onChange(selectedOption);
    setValue(selectedOption);
  };

  return (
    <Select
      ref={selectRef}
      isDisabled={isDisabled}
      onChange={handleOnChange}
      styles={reactSelectControls}
      {...rest}
    />
  );
};

export function cssControls(base) {
  return {
    ...base,
    border: 0,
    marginTop: '4px',
    boxShadow: 'none',
    backgroundColor: 'white',
  };
}

export const reactSelectControls = {
  control: cssControls,
  menu: (provided) => ({
    ...provided,
    zIndex: 22222,
  }),
};
