import {
  Box,
  Flex,
  Stack,
  Td,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
} from '@chakra-ui/react';
import { Card } from '@material-ui/core';
import cronstrue from 'cronstrue';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  getShiftById,
  getShiftOfLocation,
} from '../../../controllers/snowm_firebase';
import { getJobStatus } from '../../../enums/jobStatus';
import { getDateForJob } from '../../../helpers/date';
import {
  CrewsOfJob,
  flteredJobsHeaders,
  MarkerOfAJob,
} from '../../../reusableComponent/shiftSchedule/JobsTable';
import { Row } from '../../../styles/snowm_styled';
import Span from '../../Common/Span';
import Loader from '../../Generics/snowm_loader';

interface LocationDetailsProps {
  propertyId: string;
  location: any; // TODO: location type
}

export function renderHeader(headers) {
  return (
    <Tr top="0" zIndex="10" cursor="pointer" height="50px">
      {headers.map((header, index) => (
        <Th key={header}>
          <Flex alignItems="center" gridGap="md">
            <Text fontWeight="bold" mr="1">
              {header.name}
            </Text>
          </Flex>
        </Th>
      ))}
    </Tr>
  );
}

const LocationShifts = (props: LocationDetailsProps) => {
  const { propertyId, location } = props;

  const [shifts, setShifts] = useState<any>();

  useEffect(() => {
    let isSubscribe = true;
    if (!shifts) {
      getShiftOfLocation(location?.id).then((res) => {
        if (isSubscribe) setShifts(res);
      });
    }

    return () => {
      isSubscribe = false;
    };
  }, [propertyId]);

  if (!shifts) {
    return <Loader />;
  }

  if (shifts.length === 0) {
    return (
      <Flex justifyContent="center" height="100%">
        <Span weight="bold">No shifts for this location.</Span>
      </Flex>
    );
  }

  return (
    <Box overflowX="scroll">
      <Table size="sm" overflowX="scroll">
        <Thead>{renderHeader(flteredJobsHeaders(true))}</Thead>
        <Tbody>
          {shifts?.map((property) => (
            <STableRow key={property.id} job={property} />
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

const STableRow = (props) => {
  const { job } = props;
  const isPeriodic = false;

  const getJob = () => {
    if (isPeriodic) {
      return job?.job;
    }
    return job;
  };
  const [shift, setShift] = useState<any>(null);
  const [actualJob, setActualJob] = useState(getJob());

  useEffect(() => {
    const updatedJob = getJob();
    setActualJob(updatedJob);
  }, [job, isPeriodic]);

  useEffect(() => {
    let isCancel = false;

    if ((actualJob?.shift || actualJob?.shiftKey) && !isPeriodic) {
      if (actualJob?.shift) {
        setShift(actualJob.shift);
      } else {
        getShiftById(actualJob?.shiftKey).then((res) => {
          if (!isCancel) {
            setShift(res);
          }
        });
      }
    }
    return () => {
      isCancel = true;
    };
  }, [actualJob]);

  const renderTimeStampForJob = () => {
    const startDate = actualJob?.startedDate
      ? getDateForJob(actualJob.startedDate)
      : '-';
    const endedDate = actualJob?.endDate
      ? getDateForJob(actualJob.endDate)
      : '-';
    return (
      <>
        <Flex justifyContent="space-between">
          <Span>{startDate}</Span>
        </Flex>
        <Flex justifyContent="space-between">
          <Span>{endedDate}</Span>
        </Flex>
      </>
    );
  };

  return (
    <Tr>
      <Td>
        <Flex gridGap="md">
          <Stack>
            <Text>{job?.shift?.name ?? '---'}</Text>
            {isPeriodic && job?.cronExp && (
              <Text>{cronstrue.toString(job?.cronExp)}</Text>
            )}
          </Stack>
        </Flex>
      </Td>
      <Td>{getJobStatus(actualJob, shift)}</Td>
      <CrewsOfJob job={actualJob} />
      <Td>{renderTimeStampForJob()}</Td>
      <MarkerOfAJob job={actualJob} />
    </Tr>
  );
};

export default LocationShifts;

const StyledCard = styled(Card)`
  padding: 16px;
`;

const NoMarkerContainer = styled(Row)`
  height: 100%;
`;

const Image = styled.img`
  object-fit: contain;
`;
