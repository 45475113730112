/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import { Image } from '@chakra-ui/image';
import { Box, Center, Flex, Text } from '@chakra-ui/layout';
import { useToast, Button } from '@chakra-ui/react';
import { withTheme } from '@material-ui/core/styles';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import logo from '../../../assets/svgs/stratosfy_logo.svg';
import { AuthContext } from '../../../contexts/auth_context';
import { LocalizationContext } from '../../../contexts/localization_context';
import { auth, loginWithCredential } from '../../../controllers/snowm_firebase';
import { ValidateEmail } from '../../../helpers/snowm_validators';
import { CustomButton } from '../../../reusableComponent/CustomButton';
import color from '../../../utils/color';
import { Email, LoginMethod, LoginMethodType } from './Email';
import { Password } from './Password';
import { PhoneNumber } from './PhoneNumber';

export interface NewUserCheck {
  newUser: boolean | null;
}

export enum Screen {
  LogInMethod = 'LOGINMETHOD',
  ScreenEmail = 'EMAIL',
  VerifyCode = 'VERIFYCODE',
  UpdatePasswrod = 'UPDATEPASSWORD',
  ScreenPassword = 'PASSWORD',
  Phone = 'PHONE',
}

export type ScreenSelection =
  | Screen.LogInMethod
  | Screen.ScreenEmail
  | Screen.VerifyCode
  | Screen.UpdatePasswrod
  | Screen.ScreenPassword
  | Screen.Phone;

const Login = (props: any) => {
  const {
    ScreenEmail: EmailScreen,
    LogInMethod: LogInMethodScreen,
    Phone: PhoneScreen,
  } = Screen;

  const { Email: EmailLogin, PhoneNumber: PhoneNumberLogin } = LoginMethod;

  const toast = useToast();
  // TODO: change type of login props
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isEmailVerified, setisEmailVerified] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [screen, setScreen] = useState<ScreenSelection>(LogInMethodScreen);

  const { authenticated, setAuthenticated, setCompanyKey } =
    useContext(AuthContext);
  const { strings } = useContext(LocalizationContext);

  const { LOGIN } = strings.auth;

  const [form, setForm] = useState({
    email: {
      value: '',
      error: false,
    },
    password: {
      value: '',
      error: false,
    },
    error: '',
  });

  /*
   ****************************************************************************
   * @brief  (attemptLogin)      tries to login with the current credentials in the state
   * @param{type : object } (e)
   *
   * @returns undefined
   ****************************************************************************
   */
  const attemptLogin = async (e: any) => {
    // TODO: change type
    if (ValidateEmail(form.email.value) && form.password.value !== '') {
      setLoading(true);
      try {
        const credential = auth.EmailAuthProvider.credential(
          form.email.value,
          form.password.value
        );
        const res = await loginWithCredential(credential);

        if (res.pathname === '/home') {
          setCompanyKey(res.keyOfCompany);
          setAuthenticated({ userClaims: res.data, canLogIn: true });
          history.push(res.pathname);
          return;
        }
        history.push(res.pathname);
      } catch (err) {
        toast({
          status: 'error',
          description: err.message ?? 'Cannot login',
        });

        setLoading(false);
      }
    }
    setForm({ ...form, error: 'Please check errors' });
  };

  /*
   ****************************************************************************
   * @brief  (handleInput)     called each time an input value is changed in
   * the form, makes appropriate changes in state when a value in input field
   * is changed.
   * @param{type : event } (event)
   *
   * @returns undefined
   ****************************************************************************
   */
  const handleInputChange = (event: any) => {
    let error = false;
    if (event.target.name === 'email') {
      error = !ValidateEmail(event.target.value);
    }
    setForm({
      ...form,
      [event.target.name]: { value: event.target.value, error },
    });
  };

  const goBackToEmailField = () => {
    setisEmailVerified(false);
  };

  const goToPhoneLogin = () => {
    handleLogin(PhoneNumberLogin);
  };

  if (authenticated?.userClaims) {
    history.push('/choose-company');
  }

  const handleLogin = (type: LoginMethodType) => {
    if (type === 'email') {
      setScreen(EmailScreen);
    } else {
      setScreen(PhoneScreen);
    }
  };

  const renderLoginScreen = () => {
    switch (screen) {
      case LogInMethodScreen:
        return (
          <Flex justify="center" align="center">
            <Button
              height="56px"
              width="56px"
              colorScheme="primary"
              background=" linear-gradient(95deg, #65CAF0, #00AEEF)"
              onClick={() => handleLogin(EmailLogin)}
              margin="0.5rem"
            >
              <Box color="white">
                <EmailIcon />
              </Box>
            </Button>
            <Text padding="20px">or</Text>
            <Button
              height="56px"
              width="56px"
              background=" linear-gradient(95deg, #65CAF0, #00AEEF)"
              onClick={() => handleLogin(PhoneNumberLogin)}
              colorScheme="primary"
              margin="0.5rem"
            >
              <Box color="white">
                <PhoneIcon />
              </Box>
            </Button>
          </Flex>
        );

      case EmailScreen:
        if (isEmailVerified) {
          return (
            <>
              <Password
                login={attemptLogin}
                showPassword={showPassword}
                setShowPassword={setShowPassword}
                goToPhoneLogin={goToPhoneLogin}
                handlePasswordChange={handleInputChange}
                loading={loading}
              />
              {renderTroubleshoot()}
            </>
          );
        }

        return (
          <>
            <Email
              setIsEmailVerified={setisEmailVerified}
              handleEmailChange={handleInputChange}
              handleLogin={handleLogin}
            />
            {renderTroubleshoot()}
          </>
        );

      case PhoneScreen:
        return (
          <>
            <PhoneNumber handleLogin={handleLogin} />
            {renderTroubleshoot()}
          </>
        );

      default:
        return null;
    }
  };

  const renderTroubleshoot = () => {
    return (
      <>
        <span
          style={{
            height: '1px',
            marginTop: '80px',
            marginBottom: '1rem',
            borderTop: '1px solid #8A91A6',
            width: '100%',
          }}
        />

        <span>
          <label
            style={{
              color: '#8A91A6',
              fontSize: '1rem',
              fontWeight: 400,
              lineHeight: '1.5',
            }}
          >
            Having trouble logging in?
          </label>
          <a href="/" style={{ color: '#006DB8' }}>
            {' '}
            Click here
          </a>
        </span>
      </>
    );
  };

  const renderHeadingForLogin = () => {
    if (screen === Screen.LogInMethod) {
      return ` This is a secure system and you will need to provide your login
          details to access the site.`;
    }
    return `This is a secure system and you need to be registered to access the site.`;
  };

  return (
    <Flex
      alignItems="space-between"
      justifyContent="center"
      bg="white"
      height="100vh"
    >
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="flex-start"
        marginTop="100px"
        height="auto"
      >
        <Flex direction="column" alignItems="center" justifyContent="center">
          <Image
            src={logo}
            alt="stratosfy"
            mb="-20px"
            height={150}
            width={150}
            cursor="pointer"
          />

          <Text
            fontSize="12px"
            font="normal normal normal 12px/13px Montserrat"
            letterSpacing="3.82px"
            color="#162350"
            textTransform="capitalize"
          >
            Turn Smart Into Genius
          </Text>
          <br />

          <Text
            fontSize="28px"
            fontWeight="bold"
            color="primary.400"
            marginBottom="0.5rem"
          >
            {LOGIN}
          </Text>
          <Box width="92%">
            <Text textAlign="center" fontSize="12px" color="text.700">
              {renderHeadingForLogin()}
            </Text>
          </Box>
        </Flex>
        <br />
        <>{renderLoginScreen()}</>
      </Flex>
    </Flex>
  );
};

export default withTheme(Login);

const Blank = styled.div`
  height: 5px;
`;

const LoginForm = styled.div`
  flex: 1;
  width: 290px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${(props) => props.theme.palette.primary.main};
`;

export const StyledForm = styled.form`
  gap: 4px;
  height: 50%;
  display: flex;
  padding: 15px 0px;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const RowCenterChild = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ForgotPwdButton = styled.button`
  border: none;
  cursor: pointer;
  background-color: ${color.white};
`;
