import {
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  InputLeftAddon,
  InputRightAddon,
  Text,
  Textarea,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';

export type CountryCode = `+${string}`;

interface SInputProps {
  name: string;
  type: InputTypes;
  error?: FieldError;
  maxLength?: number;
  placeholder: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  errorMessage?: string;
  defaultValue?: string;
  children?: any;
  countryCode?: CountryCode;
  register?: any;
  value: string | number | undefined | any;
  onClickIcon?: () => void;
  showPassword?: boolean;
  showPasswordIcon?: boolean;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  rightIcon?: any;
  onKeyDown?: (e: any) => any;
}

// TODO: Change it to the proper color name
const inputColor = {
  error: '#FF0000',
  focused: '#006DB8',
  unfocused: '#ECEEF5',
  disabled: '#8A91A6',
};

export const CustomInput = (props: SInputProps) => {
  const {
    type,
    name,
    error,
    value,
    onChange,
    register,
    maxLength,
    isRequired,
    isDisabled,
    placeholder,
    defaultValue,
    errorMessage,
    countryCode,
    rightIcon,
    onKeyDown,

    onClickIcon,
    showPassword,
    showPasswordIcon,
    children,
  } = props;

  const [color, setColor] = useState('');
  const [showLabel, setShowLabel] = useState(false);

  const valueExist = value && value !== 'NaN';

  useEffect(() => {
    if (error) {
      setColor(inputColor.error);
    } else if ((showLabel || valueExist) && !isDisabled) {
      setColor(inputColor.focused);
    } else if (isDisabled) {
      setColor(inputColor.disabled);
    } else {
      setColor(inputColor.unfocused);
    }
  }, [error, showLabel, value]);

  const handleKeyDown = (event) => {
    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  function content() {
    switch (type) {
      case 'textarea':
        return (
          <Textarea
            type={type}
            name={name}
            value={value}
            variant="unstyled"
            onChange={onChange}
            register={register}
            isDisabled={isDisabled}
            isRequired={isRequired}
            placeholder={placeholder}
            defaultValue={defaultValue}
            onFocus={() => {
              setShowLabel(true);
            }}
            onBlur={() => setShowLabel(false)}
          />
        );
      case 'children':
        return <>{children}</>;

      default:
        return (
          <Flex>
            {countryCode && (
              <InputLeftAddon pointerEvents="none" top="10px" left="2">
                <Text>{countryCode}</Text>
              </InputLeftAddon>
            )}
            <Input
              type={type}
              name={name}
              value={value}
              error={error}
              variant="unstyled"
              onChange={onChange}
              register={register}
              maxLength={maxLength}
              isDisabled={isDisabled}
              isRequired={isRequired}
              placeholder={placeholder}
              defaultValue={defaultValue}
              errorMessage={errorMessage}
              marginLeft={countryCode && '4'}
              onFocus={() => {
                setShowLabel(true);
              }}
              onBlur={() => setShowLabel(false)}
              onKeyDown={handleKeyDown}
            />

            {rightIcon && <InputRightAddon>{rightIcon}</InputRightAddon>}
          </Flex>
        );
    }
  }

  const fieldSetPadding = type !== 'children' ? '8px 10px' : '0 0 0 8px';
  const fieldSetBorder = valueExist || showLabel ? '2px' : '1px';
  return (
    <FormControl isInvalid={!!error}>
      <fieldset
        style={{
          borderRadius: '4px',
          padding: fieldSetPadding,
          border: `${fieldSetBorder} solid ${color}`,
        }}
      >
        {(showLabel || valueExist) && (
          <legend
            style={{
              color,
              top: -10,
              left: 10,
              margin: 0,
              fontWeight: 600,
              fontSize: '13px',
              padding: '0 4px',
              background: 'white',
              position: 'absolute',
            }}
          >
            {placeholder}
          </legend>
        )}
        {content()}
      </fieldset>
      {error && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
};

type InputTypes =
  | 'text'
  | 'textarea'
  | 'date'
  | 'number'
  | 'children'
  | 'password'
  | 'email';
