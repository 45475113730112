import { TriangleDownIcon, TriangleUpIcon, ViewIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Collapse,
  Flex,
  Icon,
  Tbody,
  Td,
  Text,
  Tr,
  Wrap,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import LocationZeroStateImage from '../../../assets/images/zeroStateLocation.png';
import { ReactComponent as AddIcon } from '../../../assets/svgs/AddIcon.svg';
import { ReactComponent as EditBlueIcon } from '../../../assets/svgs/Editblue.svg';
import { ReactComponent as GreyAddIcon } from '../../../assets/svgs/GreyAddIcon.svg';
import { ServicePointsContext } from '../../../contexts/service_points';
import { getRelativeDate } from '../../../helpers/date';
import { sortByEntity } from '../../../helpers/misc';
import ActionsDrawer from '../../../reusableComponent/ActionsDrawer';
import EmptyComponent from '../../../reusableComponent/EmptyComponent';
import { MainTable, MainTableRow } from '../../../reusableComponent/MainTable';
import Loader from '../../Generics/snowm_loader';
import { microLocationBoxInDeleteDialog } from '../microLocations/MarkersTable';

const headers = [
  { name: 'Name', sort: 'asc' },
  { name: 'Address', sort: 'asc' },
  { name: 'Micro-Locations', sort: 'asc' },
];

export const PropertiesTable = ({
  body,
  searchText,
  properties,
  setProperties,
  DeletePress,
  onEditLocation,
  openActionDrawer,
  selectedLocation,
  onDeleteIconPress,
  setOpenActionDrawer,
  setSelectedLocation,
  openPropertiesDetail,
  ViewPress: viewPress,
  selectedMicroLocations,
  hanldeAddMicroLocation,
  setSelectedServicePoint,
  setSelectedMicroLocations,
  handleMultipleDeleteButton,
  setOpenAddMiroLocationDialog,
  openMicroLocationActionDrawer,
  onEditMicroLocationFromLocation,
  deleteMicroLocationFromLocation,
  setOpenMicroLocationActionDrawer,
  selectedLocationForMicroLocations,
}) => {
  const [locationId, setLocationId] = useState('');
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [selectedMicroLocation, setSelectedMicroLocation] = useState();
  const [selectedLocationCount, setSelectedLocationCount] = useState(0);
  const [locationHeaders, setLocationHeaders] = useState(headers);

  const selectedLocationData = Object.values(selectedLocation);

  useEffect(() => {
    setSelectedLocationCount(selectedLocationData.length);
  }, [selectedLocation]);

  const selectedLocationToEdit = selectedLocationData[0];

  function selectAllLocation(e) {
    setOpenActionDrawer(true);
    setSelectedMicroLocations({});
    if (e.target.checked) {
      const checkedLocation = properties?.reduce((locations, location) => {
        if (location.id) {
          locations[location.id] = location;
        }
        return locations;
      }, {});
      setSelectedLocation({ ...checkedLocation });
    } else {
      setSelectedLocation({});
    }
  }
  const selectLocation = (location) => {
    setSelectedMicroLocations({});
    setOpenActionDrawer(true);

    const { id } = location;
    if (selectedLocation[id]) {
      const { [id]: value, ...remainingLocation } = selectedLocation;
      setSelectedLocation({ ...remainingLocation });
    } else {
      setSelectedLocation({
        ...selectedLocation,
        [id]: { ...location },
      });
    }
  };

  const handleSorting = (header, headerIndex) => {
    const { sort, name: fieldName } = header;
    const newSort = sort === 'desc' ? 'asc' : 'desc';
    const copiedLocations = [...properties];

    switch (fieldName) {
      case 'Name':
        copiedLocations?.sort((a, b) => sortByEntity(a.name, b.name, newSort));
        break;

      case 'Address':
        copiedLocations?.sort((a, b) =>
          sortByEntity(a.address, b.address, newSort)
        );
        break;

      case 'Micro-Locations':
        copiedLocations?.sort((a, b) => {
          return sortByEntity(a.markers ?? 0, b.markers ?? 0, newSort);
        });

        break;
      default:
        break;
    }

    const updatedHeaders = [...locationHeaders];
    updatedHeaders.splice(headerIndex, 1, {
      name: fieldName,
      sort: newSort,
    });
    setLocationHeaders(updatedHeaders);
    setProperties(copiedLocations);
  };

  if (!properties) {
    return <Loader />;
  }

  if (!properties.length) {
    return (
      <EmptyComponent
        header="Location"
        title="Before you configure your equipment and associate sensors you need to create location."
        subTitle="Click + add Location button at top right corner to create your first location."
        zeroStateImage={LocationZeroStateImage}
        isSearch={!!searchText}
      />
    );
  }
  function showButtons() {
    return (
      <Wrap>
        <Button
          size="sm"
          colorScheme="blue"
          border="2px solid #006DB8"
          leftIcon={<AddIcon />}
          variant="outline"
          onClick={() => {
            hanldeAddMicroLocation(selectedLocationToEdit);
          }}
        >
          Add Micro-Location
        </Button>

        <Button
          size="sm"
          colorScheme="blue"
          border="2px solid #006DB8"
          leftIcon={<EditBlueIcon />}
          variant="outline"
          onClick={() => {
            onEditLocation(selectedLocationToEdit);
          }}
        >
          Edit
        </Button>
      </Wrap>
    );
  }
  return (
    <Box mb="xxxl" overflow="hidden">
      <MainTable
        headers={locationHeaders}
        checked={selectedLocationCount === properties?.length}
        onSelectedEntities={(e) => selectAllLocation(e)}
        handleSorting={handleSorting}
      >
        <Tbody bg="white" boxShadow="0px 12px 24px #ECEEF5" borderRadius="16px">
          {properties?.map((property) => (
            <STableRow
              body={body}
              key={property.id}
              property={property}
              onViewPress={viewPress}
              locationId={locationId}
              onDeletePress={DeletePress}
              setLocationId={setLocationId}
              onEditLocation={onEditLocation}
              selectedEntities={selectedEntities}
              selectedLocation={selectedLocation}
              openActionDrawer={openActionDrawer}
              handlePropertyDelete={onDeleteIconPress}
              setSelectedEntities={setSelectedEntities}
              handlePropertyView={openPropertiesDetail}
              setSelectedLocation={setSelectedLocation}
              setOpenActionDrawer={setOpenActionDrawer}
              selectedMicroLocation={selectedMicroLocation}
              selectLocation={() => selectLocation(property)}
              selectedLocationToEdit={selectedLocationToEdit}
              selectedMicroLocations={selectedMicroLocations}
              hanldeAddMicroLocation={hanldeAddMicroLocation}
              setSelectedServicePoint={setSelectedServicePoint}
              setSelectedMicroLocation={setSelectedMicroLocation}
              setSelectedMicroLocations={setSelectedMicroLocations}
              handleMultipleDeleteButton={handleMultipleDeleteButton}
              setOpenAddMiroLocationDialog={setOpenAddMiroLocationDialog}
              openMicroLocationActionDrawer={openMicroLocationActionDrawer}
              onEditMicroLocationFromLocation={onEditMicroLocationFromLocation}
              deleteMicroLocationFromLocation={deleteMicroLocationFromLocation}
              setOpenMicroLocationActionDrawer={
                setOpenMicroLocationActionDrawer
              }
              selectedLocationForMicroLocations={
                selectedLocationForMicroLocations
              }
            />
          ))}
          <ActionsDrawer
            openActionDrawer={openActionDrawer}
            selectedEntities={Object.values(selectedLocation)}
            setSelectedEntities={setSelectedLocation}
            showButtons={showButtons}
            showDeleteButton
            handleDeleteEntities={onDeleteIconPress}
            entity="Location"
            body={body}
            deleteSubtitle="Remember, this action cannot be undone. It also removed data related to microLocation"
          />
        </Tbody>
      </MainTable>
    </Box>
  );
};
const STableRow = (props) => {
  const [collapseOpen, setCollapseOpen] = useState(false);

  const {
    body,
    property,
    locationId,
    onViewPress,
    onDeletePress,
    setLocationId,
    onEditLocation,
    selectLocation,
    selectedEntities,
    selectedLocation,
    openActionDrawer,
    handlePropertyView,
    setSelectedEntities,
    setSelectedLocation,
    setOpenActionDrawer,
    handlePropertyDelete,
    selectedMicroLocation,
    selectedLocationToEdit,
    selectedMicroLocations,
    hanldeAddMicroLocation,
    setSelectedServicePoint,
    setSelectedMicroLocation,
    setSelectedMicroLocations,
    handleMultipleDeleteButton,
    setOpenAddMiroLocationDialog,
    openMicroLocationActionDrawer,
    onEditMicroLocationFromLocation,
    deleteMicroLocationFromLocation,
    setOpenMicroLocationActionDrawer,
    selectedLocationForMicroLocations,
  } = props;

  useEffect(() => {
    if (!collapseOpen) setSelectedMicroLocations({});
  }, [collapseOpen]);

  const isThisTheSameLocationThatsSelected = () => {
    return selectedLocationForMicroLocations?.id === property.id;
  };

  const doesThisLocatioHaveMicroLocatios = () => {
    return (property.markers ?? 0) >= 1;
  };

  const doOpenCollapse = () => {
    return (
      isThisTheSameLocationThatsSelected() && doesThisLocatioHaveMicroLocatios()
    );
  };

  useEffect(() => {
    setCollapseOpen(doOpenCollapse());
  }, [selectedLocationForMicroLocations]);

  function handleCollapseOpen(location) {
    setSelectedMicroLocation(location);
    setCollapseOpen(true);

    if (location.id === selectedMicroLocation?.id) {
      setSelectedMicroLocation({});
      setCollapseOpen(false);
    }
  }

  const collapseOpenCloseIcon = () => {
    if (collapseOpen && property.id === selectedMicroLocation.id) {
      return TriangleUpIcon;
    }
    return TriangleDownIcon;
  };

  const showProperty = () => {
    if (!property.markers || property.markers < 1) {
      return <GreyAddIcon />;
    }
    return (
      <Text
        textAlign="center"
        color="#323A45"
        opacity="1"
        fontWeight="bold"
        fontSize="md"
      >
        {property.markers}
      </Text>
    );
  };

  return (
    <>
      <MainTableRow>
        <Td>
          <Flex gridGap="md">
            <Checkbox
              isChecked={!!selectedLocation[property.id ?? '']}
              onChange={selectLocation}
              borderColor="primary.500"
              bg="white"
            />

            <Flex
              onClick={() => handlePropertyView(property)}
              cursor="pointer"
              gridGap="sm"
              alignItems="center"
            >
              <Avatar name={property.name} src={property.imageUrl} />
              <Text>{property.name}</Text>
            </Flex>
          </Flex>
        </Td>

        <Td>{property.address}</Td>
        {/* <Td>
          <Tooltip label="View">
            <IconButton onClick={() => handlePropertyView(property)}>
              <ViewIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Td> */}
        <Td>
          <Flex
            gridGap="20px"
            cursor="pointer"
            alignItems="center"
            justifyContent="flex-end"
            onClick={() =>
              !property.markers || property.markers < 1
                ? hanldeAddMicroLocation(property)
                : handleCollapseOpen(property)
            }
          >
            <Box
              p="xxsm"
              backgroundColor="#F1F1F1"
              borderRadius="4px"
              opacity="1"
            >
              {showProperty()}
            </Box>
            Micro-Locations
            <Icon as={collapseOpenCloseIcon()} />
          </Flex>
        </Td>
      </MainTableRow>

      <td style={{ overflow: 'hidden' }} colSpan={100}>
        <Collapse in={collapseOpen && property.id === selectedMicroLocation.id}>
          <Box bg="background.500">
            <CollapseData
              location={property}
              openMicroLocationActionDrawer={openMicroLocationActionDrawer}
              setOpenMicroLocationActionDrawer={
                setOpenMicroLocationActionDrawer
              }
              setSelectedMicroLocations={setSelectedMicroLocations}
              selectedMicroLocations={selectedMicroLocations}
              onEditMicroLocationFromLocation={onEditMicroLocationFromLocation}
              onDeletePress={onDeletePress}
              onViewPress={onViewPress}
              selectedEntities={Object.values(selectedLocation)}
              setSelectedEntities={setSelectedLocation}
              setOpenAddMiroLocationDialog={setOpenAddMiroLocationDialog}
              setSelectedServicePoint={setSelectedServicePoint}
              hanldeAddMicroLocation={hanldeAddMicroLocation}
              deleteMicroLocationFromLocation={deleteMicroLocationFromLocation}
            />
          </Box>
        </Collapse>
      </td>
    </>
  );
};
const CollapseData = ({
  location,
  openMicroLocationActionDrawer,
  setOpenMicroLocationActionDrawer,
  onEditMicroLocationFromLocation,
  onDeletePress,
  onViewPress,
  setSelectedEntities,
  selectedEntities,
  setOpenAddMiroLocationDialog,
  selectedMicroLocations,
  hanldeAddMicroLocation,
  setSelectedMicroLocations,
  setSelectedServicePoint,
  deleteMicroLocationFromLocation,
}) => {
  const { markers, fetchServicePoints } = useContext(ServicePointsContext);

  const [loading, setLoading] = useState(true);
  const [microLocationData, setMicroLocationData] = useState([]);
  const [microLocationsCount, setMicroLocationsCount] = useState(0);

  const selectedMicroLocation = Object.values(selectedMicroLocations);
  const microLocationsToEditDelete = selectedMicroLocation?.[0];

  useEffect(() => {
    if (!markers) {
      setLoading(true);
      fetchServicePoints();
    }
  }, []);

  useEffect(() => {
    if (markers) {
      const microLocationsOfALocation = Object.values(markers).filter(
        (marker) => marker.propertyKey === location.id
      );
      setMicroLocationData(microLocationsOfALocation);
      setLoading(false);
    }
  }, [markers]);

  useEffect(() => {
    setMicroLocationsCount(selectedMicroLocation.length);
  }, [selectedMicroLocations]);

  function getDate(date) {
    return date ? getRelativeDate(date) : 'Never';
  }

  const collapseHeader = [
    {
      name: 'Name',
      sort: 'desc',
    },
  ];

  const selectAllMicroLocations = (e) => {
    setOpenMicroLocationActionDrawer(true);
    setSelectedEntities({});
    if (e.target.checked) {
      const checkedShiftTemplate = microLocationData?.reduce(
        (microLocations, microLocation) => {
          if (microLocation.key) {
            microLocations[microLocation.key] = microLocation;
          }
          return microLocations;
        },
        {}
      );
      setSelectedMicroLocations({ ...checkedShiftTemplate });
    } else {
      setSelectedMicroLocations({});
    }
  };

  const onSelectedMicroLocations = (selectedShiftTemplate) => {
    setOpenMicroLocationActionDrawer(true);
    setSelectedEntities({});

    const { key } = selectedShiftTemplate;
    if (key) {
      if (selectedMicroLocations[key]) {
        const { [key]: value, ...remainingTask } = selectedMicroLocations;
        setSelectedMicroLocations({ ...remainingTask });
      } else {
        setSelectedMicroLocations({
          ...selectedMicroLocations,
          [key]: { ...selectedShiftTemplate },
        });
      }
    }
  };

  const showButton = () => (
    <Wrap>
      <Button
        colorScheme="blue"
        variant="outline"
        border="2px solid #006DB8"
        size="sm"
        onClick={() => onViewPress(microLocationsToEditDelete)}
        leftIcon={<ViewIcon />}
      >
        View
      </Button>

      <Button
        colorScheme="blue"
        variant="outline"
        border="2px solid #006DB8"
        size="sm"
        onClick={() =>
          onEditMicroLocationFromLocation(microLocationsToEditDelete)
        }
        leftIcon={<EditBlueIcon />}
      >
        Edit
      </Button>
    </Wrap>
  );

  const handleAddMicroLocationButton = (selectedLocation) => {
    setSelectedMicroLocations({});
    hanldeAddMicroLocation(selectedLocation);
  };

  if (loading) {
    return <Text>loading....</Text>;
  }

  return (
    <>
      {microLocationData?.length ? (
        <MainTable
          onSelectedEntities={(e) => selectAllMicroLocations(e)}
          checked={microLocationData?.length === microLocationsCount}
          headers={collapseHeader}
        >
          <Tbody
            bg="white"
            borderRadius="16px"
            boxShadow="0px 12px 24px #ECEEF5"
          >
            {microLocationData.map((microlocationn) => (
              <Tr
                key={microlocationn.key}
                height="51px"
                backgroundColor="#F1F1F1"
                justifyContent="space-between"
              >
                <Td>
                  <Flex gridGap="md">
                    <Checkbox
                      borderColor="#006DB8"
                      isChecked={
                        !!selectedMicroLocations[microlocationn.key ?? '']
                      }
                      onChange={() => onSelectedMicroLocations(microlocationn)}
                    />
                    <Button
                      flexGrow="1"
                      padding="xsm"
                      variant="ghost"
                      cursor="pointer"
                      _hover={{ background: 'none' }}
                      onClick={() => onViewPress(microlocationn)}
                    >
                      <span style={{ textAlign: 'left', width: '100%' }}>
                        <Text>{microlocationn.name}</Text>
                        {microlocationn?.deviceDetail?.deviceDetail
                          ?.battery && (
                          <Text fontWeight="bold">
                            Battery:{' '}
                            {
                              microlocationn?.deviceDetail?.deviceDetail
                                ?.battery
                            }
                            %
                          </Text>
                        )}

                        {!microlocationn?.beaconId && (
                          <Text
                            bgColor="red"
                            padding="xxsm"
                            color="highlight.200"
                            maxWidth="max-content"
                          >
                            NO DEVICE
                          </Text>
                        )}
                      </span>
                    </Button>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </MainTable>
      ) : (
        <Tr width="100%" display="flex" justifyContent="center">
          <Text>No micro-locations found</Text>
        </Tr>
      )}
      <Box width="100%" height="51px" backgroundColor="white">
        <Button
          size="sm"
          isDisabled={false}
          leftIcon={<AddIcon />}
          alignItems="center"
          paddingTop="23px"
          paddingLeft="15px"
          justifyContent="start"
          color="#006DB8"
          style={{ background: 'transparent' }}
          isLoading={false}
          onClick={() => handleAddMicroLocationButton(location)}
        >
          Add Micro-Location
        </Button>
      </Box>
      {/* MicroLocation's Action Drawer  */}
      <ActionsDrawer
        setSelectedEntities={setSelectedMicroLocations}
        openActionDrawer={openMicroLocationActionDrawer}
        selectedEntities={Object.values(selectedMicroLocations)}
        showButtons={showButton}
        showDeleteButton
        handleDeleteEntities={deleteMicroLocationFromLocation}
        entity="Micro Location"
        body={microLocationBoxInDeleteDialog}
        deleteSubtitle="Remember, this action cannot be undone."
      />
    </>
  );
};
