import { ViewIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Tbody,
  Td,
  Text,
  Wrap,
} from '@chakra-ui/react';
import { Fragment, useContext, useEffect, useState } from 'react';
import { ReactComponent as EditIcon } from '../../../assets/svgs/Editblue.svg';
import { LocationContext } from '../../../contexts/locationContext';
import { ServiceTypesContext } from '../../../contexts/service_types';
import { getImageBackgroundColor } from '../../../controllers/snowm_firebase';
import { sortByEntity } from '../../../helpers/misc';
import ActionsDrawer from '../../../reusableComponent/ActionsDrawer';
import EmptyComponent from '../../../reusableComponent/EmptyComponent';
import { MainTable, MainTableRow } from '../../../reusableComponent/MainTable';
import color from '../../../utils/color';
import { Progress } from '../../Generics/snowm_loader';

const MarkersTable = ({
  markers,
  searchText,
  onEditIconPress,
  onDeletePress,
  onViewPress,
  openActionDrawer,
  setSelectedMicroLocations,
  selectedMicroLocations,
  setFilteredMarkers,
  setMicroLocationHeaders,
  microLocationHeaders,
}) => {
  const { locations } = useContext(LocationContext);

  const [microLocationsCount, setMicroLocationsCount] = useState(0);

  const selectedMicroLocation = Object.values(selectedMicroLocations);
  const microLocationsToEditDelete = selectedMicroLocation?.[0];

  useEffect(() => {
    setMicroLocationsCount(selectedMicroLocation.length);
  }, [selectedMicroLocations]);

  const selectAllMicroLocations = (e) => {
    if (e.target.checked) {
      const checkedShiftTemplate = markers?.reduce(
        (microLocations, microLocation) => {
          if (microLocation.key) {
            microLocations[microLocation.key] = microLocation;
          }
          return microLocations;
        },
        {}
      );
      setSelectedMicroLocations({ ...checkedShiftTemplate });
    } else {
      setSelectedMicroLocations({});
    }
  };
  const onSelectedMicroLocations = (selectedShiftTemplate) => {
    const { key } = selectedShiftTemplate;
    if (key) {
      if (selectedMicroLocations[key]) {
        const { [key]: value, ...remainingTask } = selectedMicroLocations;
        setSelectedMicroLocations({ ...remainingTask });
      } else {
        setSelectedMicroLocations({
          ...selectedMicroLocations,
          [key]: { ...selectedShiftTemplate },
        });
      }
    }
  };

  const handleSorting = (header, headerIndex) => {
    const { sort, name: fieldName } = header;
    const newSort = sort === 'desc' ? 'asc' : 'desc';

    const copiedMicroLocations = [...markers];

    switch (fieldName) {
      case 'Name':
        copiedMicroLocations?.sort((firstMicroLocation, secondeMircoLocation) =>
          sortByEntity(
            firstMicroLocation.name,
            secondeMircoLocation.name,
            newSort
          )
        );
        break;

      case 'Location':
        copiedMicroLocations?.sort(
          (firstMicroLocation, secondeMircoLocation) => {
            const firstLocationName =
              locations[firstMicroLocation.propertyKey]?.name ?? '';
            const secondLocationName =
              locations[secondeMircoLocation.propertyKey]?.name ?? '';

            return sortByEntity(firstLocationName, secondLocationName, newSort);
          }
        );

        break;

      case 'Location Address':
        copiedMicroLocations?.sort((a, b) => {
          const firstLocationAddress = locations[a.propertyKey]?.address ?? '';
          const secondLocationAddress = locations[b.propertyKey]?.address ?? '';

          return sortByEntity(
            firstLocationAddress,
            secondLocationAddress,
            newSort
          );
        });
        break;

      default:
        break;
    }

    const updatedHeaders = [...microLocationHeaders];
    updatedHeaders.splice(headerIndex, 1, {
      name: fieldName,
      sort: newSort,
    });
    setMicroLocationHeaders(updatedHeaders);
    setFilteredMarkers(copiedMicroLocations);
  };

  const showButton = () => (
    <Wrap>
      <Button
        colorScheme="blue"
        variant="outline"
        border="2px solid #006DB8"
        size="sm"
        onClick={() => onViewPress(microLocationsToEditDelete)}
        leftIcon={<ViewIcon />}
      >
        View
      </Button>

      <Button
        colorScheme="blue"
        variant="outline"
        border="2px solid #006DB8"
        size="sm"
        onClick={() => onEditIconPress(microLocationsToEditDelete)}
        leftIcon={<EditIcon />}
      >
        Edit
      </Button>
    </Wrap>
  );

  return (
    <>
      <Box>
        <MainTable
          headers={microLocationHeaders}
          checked={markers?.length === microLocationsCount}
          onSelectedEntities={(e) => selectAllMicroLocations(e)}
          handleSorting={handleSorting}
        >
          <Tbody
            bg="white"
            boxShadow="0px 12px 24px #ECEEF5"
            borderRadius="16px"
          >
            {markers.map((marker) => (
              <MicroLocationBody
                key={marker.key}
                marker={marker}
                onEditIconPress={onEditIconPress}
                onDeletePress={onDeletePress}
                onViewPress={onViewPress}
                onSelectedMicroLocations={onSelectedMicroLocations}
                selectedMicroLocations={selectedMicroLocations}
                openActionDrawer={openActionDrawer}
              />
            ))}
          </Tbody>
        </MainTable>
      </Box>

      <ActionsDrawer
        setSelectedEntities={setSelectedMicroLocations}
        selectedEntities={Object.values(selectedMicroLocations).map((e) => ({
          key: e.key,
          name: e.name,
        }))}
        entity="Micro Location"
        openActionDrawer={openActionDrawer}
        showButtons={showButton}
        showDeleteButton
        handleDeleteEntities={onDeletePress}
        body={microLocationBoxInDeleteDialog}
        deleteSubtitle="Remember, this action cannot be undone."
      />
    </>
  );
};

export default MarkersTable;

const MicroLocationBody = ({
  marker,
  onViewPress,
  onDeletePress,
  onEditIconPress,
  selectedMicroLocations,
  onSelectedMicroLocations,
}) => {
  const { locations, getAllPropertiesOfACompany } = useContext(LocationContext);

  const [property, setProperty] = useState();

  useEffect(() => {
    if (marker?.propertyKey && locations) {
      setProperty(locations?.[marker?.propertyKey] ?? {});
    }
  }, [marker, locations]);

  useEffect(() => {
    if (!locations) getAllPropertiesOfACompany();
  }, []);

  const renderProperty = () => {
    if (!property) {
      return <Progress size={15} />;
    }

    if (!property?.name) {
      return '-';
    }

    const { address } = property ?? {};

    return <Text>{address ?? marker?.address ?? '-'}</Text>;
  };

  return (
    <MainTableRow>
      <Td>
        <Flex gridGap="md">
          <Checkbox
            bg="white"
            borderColor="primary.500"
            onChange={() => onSelectedMicroLocations(marker)}
            isChecked={!!selectedMicroLocations[marker.key ?? '']}
          />

          <Button
            gridGap="sm"
            display="flex"
            variant="ghost"
            paddingLeft="0"
            margin="10px 0"
            alignItems="center"
            onClick={() => onViewPress(marker)}
            _hover={{
              backgroundColor: 'none',
            }}
          >
            <Avatar
              backgroundColor={getImageBackgroundColor(marker?.name)}
              name={marker.name}
              src={marker.imageUrl}
            />
            <Text>{marker.name}</Text>
            {!marker?.beaconId && <Text bgColor={color.red}>NO DEVICE</Text>}
          </Button>
        </Flex>
      </Td>
      <Td>
        <Text>{property?.name ?? '-'}</Text>
      </Td>
      <Td textAlign="end">{renderProperty()}</Td>
    </MainTableRow>
  );
};

export const microLocationBoxInDeleteDialog = (microLocations) => {
  return (
    <Box
      overflowY="auto"
      margin="24px 0px"
      borderRadius="4px"
      border="0.5px solid #AEB0B5"
      padding="16px 8px 8px 16.5px"
      background="#F1F1F1 0% 0% no-repeat padding-box"
    >
      {microLocations?.map((microLocation, i) => {
        return (
          <EachMicroLocation
            microLocation={microLocation}
            microLocations={microLocations}
            key={microLocation?.key}
            index={i}
          />
        );
      })}
    </Box>
  );
};

const EachMicroLocation = ({ microLocation, microLocations, index }) => {
  const { locations, getAllPropertiesOfACompany } = useContext(LocationContext);

  const [location, setLocation] = useState({});

  const getLocationName = async (locationKey) => {
    try {
      const locationDetails = locations?.[locationKey];
      setLocation(locationDetails);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!locations) getAllPropertiesOfACompany();
  }, []);

  useEffect(() => {
    getLocationName(microLocation?.propertyKey);
  }, [microLocation, locations]);

  return (
    <Fragment key={microLocation?.id}>
      <Flex gridGap="xxsm" justifyItems="center">
        <Avatar
          width="34px"
          height="34px"
          name={microLocation?.name}
          src={microLocation?.imageUrl}
          borderRadius="4px"
        />
        <Flex direction="column" justifyItems="center">
          <Text>{microLocation?.name}</Text>
          <Text fontSize="sm" color="grey.200">
            {location?.name}
          </Text>
        </Flex>
      </Flex>
      {index < microLocations.length - 1 && <Divider margin="8px auto" />}
    </Fragment>
  );
};
