import { Fragment, useContext, useEffect, useState, useMemo } from 'react';
import { Redirect } from 'react-router';
import { Button } from '@chakra-ui/button';
import { FaPlusCircle } from 'react-icons/fa';
import {
  DeleteIcon,
  EditIcon,
  SearchIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from '@chakra-ui/icons';
import { Box, Divider, Flex, Text } from '@chakra-ui/layout';
import {
  Checkbox,
  Collapse,
  Icon,
  Stack,
  Tbody,
  Td,
  useToast,
  Wrap,
} from '@chakra-ui/react';

import CollapseData from './CollapseData';
import { TaskNameBox } from './TaskNameBox';
import DeleteDialog from './DeleteTaskDialog';
import SearchBar from '../../Common/Search_Bar';
import Loader from '../../Generics/snowm_loader';
import { getLowerCasedWord, sortByEntity } from '../../../helpers/misc';
import { SideDrawer } from '../../../reusableComponent/SideDrawer';
import EmptyTaskImage from '../../../assets/svgs/EmptyTaskImage.svg';
import ActionsDrawer from '../../../reusableComponent/ActionsDrawer';
import { CustomInput } from '../../../reusableComponent/CustomInput';
import { ServiceTypesContext } from '../../../contexts/service_types';
import { MainTable, MainTableRow } from '../../../reusableComponent/MainTable';
import { CustomSelect } from '../../../reusableComponent/shiftSchedule/custom_select';
import {
  addOrEditActivity,
  deleteActivityDatabase,
  getActivitiesOfACompany,
  deleteActivitiesOfACategory,
} from '../../../controllers/snowm_firebase';
import EmptyComponent from '../../../reusableComponent/EmptyComponent';
import { Toast } from '../../Common/Toast';
import { LocalizationContext } from '../../../contexts/localization_context';
import { makeFirstLetterUpperCase } from '../../../helpers/string';

const Activities = () => {
  const defaultActivity = {
    name: null,
    type: null,
    category: null,
    completed: null,
    mandatory: false,
    tasks: [{ name: null, mandatory: false }],
  };

  const headers = useMemo(
    () => [
      { name: 'Name', sort: 'asc' },
      { name: 'Type', sort: 'asc' },
      { name: 'Tasks', sort: 'asc' },
    ],
    []
  );

  const [taskKey, setTaskKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [taskCount, setTaskCount] = useState(0);
  const [activities, setActivities] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [allActivities, setAllActivities] = useState();
  const [categoryName, setCategoryName] = useState('');
  const [selectedTask, setSelectedTask] = useState([]);
  const [addTaskList, setAddTaskList] = useState(false);
  const [openTaskDialog, setOpenTaskDialog] = useState(false);
  const [categoryExpanded, setCategoryExpanded] = useState({});
  const [selectedTaskCount, setSelectedTaskCount] = useState(0);
  const [selectedTaskLists, setSelectedTaskLists] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openActionDrawer, setOpenActionDrawer] = useState(false);
  const [activityDetails, setActivityDetails] = useState(defaultActivity);
  const [tasksHeader, setTasksHeader] = useState(headers);

  const [openTaskListActionDrawer, setOpenTaskListActionDrawer] =
    useState(false);

  const servicesContext = useContext(ServiceTypesContext);
  const subscribedServices = servicesContext?.subscribedServices;

  const taskOptions = [
    {
      label: 'Work',
      value: 'work',
    },
    { label: 'Inspection', value: 'inspection' },
  ];

  const getSelectedTaskListType = () => {
    const selectedType = taskOptions.find(
      (option) => option.value === activityDetails.type
    );

    return selectedType ?? null;
  };

  function sortByCategory(first, second) {
    const categoryNameA = getLowerCasedWord(first.category);
    const categoryNameB = getLowerCasedWord(second.category);
    if (categoryNameA < categoryNameB) return -1;
    if (categoryNameA > categoryNameB) return 1;
    return 0;
  }

  function sortByActivityName(a, b) {
    const upperA = a.name?.toUpperCase();
    const upperB = b.name?.toUpperCase();
    if (upperA > upperB) return 1;
    if (upperA < upperB) return -1;
    return 0;
  }

  function _updateActivities(data, fromDatabase) {
    if (fromDatabase) {
      setAllActivities(data);
    }

    data.sort(sortByCategory);

    const newData = data?.reduce((acc, cur) => {
      acc[cur.category] = acc[cur.category] || [];
      acc[cur.category].push(cur);
      return acc;
    }, {});

    Object.keys(newData).forEach((eachData) => {
      newData[eachData].sort(sortByActivityName);
    });

    setActivities(newData);
  }

  useEffect(() => {
    let isSubscribe = true;

    if (isSubscribe) {
      getActivitiesOfACompany(_updateActivities);
    }

    return () => {
      isSubscribe = false;
    };
  }, []);

  useEffect(() => {
    setSelectedTaskCount(selectedTask.length);
  }, [selectedTask]);

  useEffect(() => {
    if (isSearch && activities) {
      const categories = Object.keys(activities);
      const selectedCategories = categories.reduce((acc, curr) => {
        return { ...acc, [curr]: true };
      }, {});
      setCategoryExpanded(selectedCategories);
    } else {
      setCategoryExpanded({});
    }
  }, [isSearch, activities]);

  function handleActivityAddDialog() {
    setOpenActionDrawer(false);
    setActivityDetails(defaultActivity);
    setOpenDialog(true);
  }
  function handleAddTask(task) {
    const typeOfActivity = allActivities?.find(
      (activity) => activity.category === task
    );
    setOpenTaskDialog(true);
    setAddTaskList(true);
    setActivityDetails({
      ...defaultActivity,
      category: task,
      type: typeOfActivity.type,
    });
  }
  function closeDialog() {
    setOpenActionDrawer(true);
    setOpenTaskDialog(false);
    setOpenTaskListActionDrawer(true);
    setOpenDeleteDialog(false);
    setOpenDialog(false);
    setLoading(false);
  }

  async function handleTaskSubmit(e) {
    e.preventDefault();
    setLoading(true);
    await addOrEditActivity({ ...activityDetails });
    renderToast();
    setLoading(false);
    setOpenTaskDialog(false);
    setSelectedTaskLists({});
  }
  const toast = useToast();
  function renderToast() {
    return toast({
      duration: 3000,
      isClosable: true,
      render: function CustomFile() {
        return (
          <Toast
            title="Success"
            status="success"
            description={renderTaskName()}
          />
        );
      },
    });
  }

  function renderTaskName() {
    // TODO: style this according to the UI
    // make this dynamic
    return (
      <span>
        Task
        <span style={{ fontWeight: 'bold', fontSize: 18 }}>
          {' '}
          {activityDetails?.name}{' '}
        </span>
        {`${activityDetails?.id ? 'updated' : 'created'}`} successfully
      </span>
    );
  }

  async function handleAddButton(event) {
    event.preventDefault();
    setSelectedTask([]);
    setAddTaskList(false);
    setLoading(true);
    const promises = [];
    try {
      activityDetails.tasks.forEach((ac) => {
        const { tasks, name: category, ...bahek } = activityDetails;
        const activity = {
          ...ac,
          ...bahek,
          category,
        };
        promises.push(addOrEditActivity({ ...activity }));
      });
      await Promise.all(promises);

      renderTaskListToast();
      setLoading(false);
      closeDialog();
    } catch (error) {
      console.error('@@error', error);
      setLoading(false);
    }
  }
  function renderTaskListToast() {
    return toast({
      duration: 3000,
      isClosable: true,
      render: function CustomFile() {
        return (
          <Toast
            title="Success"
            status="success"
            description={renderTaskListName()}
          />
        );
      },
    });
  }

  function renderTaskListName() {
    return (
      <span>
        Task-List
        <span style={{ fontWeight: 'bold', fontSize: 18 }}>
          {' '}
          {activityDetails?.name}{' '}
        </span>
        {`${isEdit ? 'updated' : 'created'}`} successfully
      </span>
    );
  }

  function handleCategory(event) {
    event.persist();

    setActivityDetails((prevData) => {
      return {
        ...prevData,
        category: event.target?.value ?? '',
      };
    });
  }

  function handleName(event) {
    event.persist();

    setActivityDetails((prevData) => {
      return { ...prevData, name: event.target.value };
    });
  }
  function handleTypeSelect(event) {
    setActivityDetails((prevData) => {
      return { ...prevData, type: event.value };
    });
  }

  function handleMandatoryCheckBox(event) {
    event.persist();
    setActivityDetails((prevData) => {
      return {
        ...prevData,
        mandatory: event.target?.checked,
      };
    });
  }

  function openEditModal(activity) {
    setActivityDetails({ ...activity });
    setOpenDialog(true);
  }

  function openDeleteModal(activity) {
    setOpenDeleteDialog(true);
    setOpenTaskListActionDrawer(false);
    // setActivityDetails(activity);
  }
  function handleAddActivity(e, activity = {}) {
    e.stopPropagation();
    setActivityDetails({ ...activityDetails, category: activity });
    setOpenDialog(true);
  }
  async function handleDeletion() {
    try {
      if (selectedTask.length) {
        await Promise.all(
          selectedTask.map((task) => deleteActivitiesOfACategory(task))
        );
      } else if (Object.values(selectedTaskLists).length) {
        await Promise.all(
          Object.values(selectedTaskLists).map((task) =>
            deleteActivityDatabase(task.id)
          )
        );
      }
      setOpenDeleteDialog(false);
    } catch (error) {
      console.error('@@deleteError', error);
    }
    setCategoryName('');
    setOpenActionDrawer(false);
    setSelectedTask([]);
    setSelectedTaskLists({});
  }
  async function handleMultipleDeletion(taskName) {
    try {
      await deleteActivitiesOfACategory(taskName);
      setSelectedTask([]);
    } catch (error) {
      console.error('@@deleteError', error);
    }
    setSelectedTask([]);
  }
  async function handleMultipleTaskDeletion(id) {
    try {
      await deleteActivityDatabase(id);
      setSelectedTaskLists({});
    } catch (error) {
      console.error('@@deleteError', error);
    }
    setSelectedTaskLists({});
  }

  function closeAlertBox() {
    setOpenActionDrawer(true);
    setOpenDeleteDialog(false);
    setCategoryName('');
  }

  function getTaskListObjFromActivities(tsk) {
    const taskObj = {};
    const tasks = [];
    tsk.forEach((task, i) => {
      if (i === 0) {
        taskObj.type = task.type;
        taskObj.name = task.category;
      }
      tasks.push({ ...task });
    });
    return { ...taskObj, tasks };
  }

  function handleActivityEdit(e, category) {
    setOpenActionDrawer(false);
    e.stopPropagation();
    setCategoryName(category);
    const obj = getTaskListObjFromActivities(activities[category]);
    setActivityDetails({ ...obj });
    setOpenDialog(true);
  }

  function handleCategoryEdit(e, category) {
    setOpenTaskDialog(true);
    setOpenActionDrawer(false);
    e.stopPropagation();
  }
  function handleTaskNameEdit(e, category) {
    setOpenActionDrawer(false);
    e.stopPropagation();
    setCategoryName(category);
    setActivityDetails(category);
    setOpenTaskDialog(true);
    setOpenTaskListActionDrawer(false);
  }

  function closeEditDialog() {
    setOpenActionDrawer(true);
    setOpenTaskDialog(false);
    setOpenTaskListActionDrawer(true);
    setOpenDialog(false);
    setCategoryName('');
    setActivityDetails(defaultActivity);
  }

  function handleCategoryDelete(category) {
    setOpenActionDrawer(false);
    setCategoryName(category);
    setOpenDeleteDialog(true);
  }

  function handleCategoryClick(category) {
    const categoryValue = categoryExpanded[category];
    setCategoryExpanded({ ...categoryExpanded, [category]: !categoryValue });
  }

  if (!activities || !subscribedServices) {
    return <Loader />;
  }

  if (!subscribedServices?.activities && !subscribedServices.activitiesOnJob) {
    return <Redirect to="/admin" />;
  }

  const handleSearchInput = (e) => {
    const searchWord = e.target.value;
    setIsSearch(true);

    const res = allActivities?.reduce((s, curr) => {
      const categoriesName = curr.category;

      const activitiesName = curr.name;

      const nameOfCategory = getLowerCasedWord(categoriesName);
      const activityName = getLowerCasedWord(activitiesName);
      const searchString = getLowerCasedWord(searchWord);

      if (
        activityName.includes(searchString) ||
        nameOfCategory.includes(searchString)
      ) {
        return [...s, curr];
      }
      return s;
    }, []);

    _updateActivities(res ?? []);
  };

  const handleChange = (sortedCategory) => {
    const sortedArrayOfCategory = sortedCategory.reduce((acc, category) => {
      return { ...acc, [category]: activities[category] };
    }, {});
    setActivities(sortedArrayOfCategory);
  };
  const handleAllTasks = (e, cat) => {
    if (e.target.checked) {
      setTaskCount(taskCount + 1);
    } else {
      setTaskCount(taskCount - 1);
    }
  };

  const selectedTaskToEdit = selectedTask[0];
  const selectAllTasks = (e) => {
    setSelectedTaskLists({});

    if (e.target.checked) {
      setOpenActionDrawer(true);
      // const checkedTask = Object.values(activities)?.reduce((tasks, task) => {
      //   if (task) {
      //     tasks[task] = task;
      //   }
      //   return tasks;
      // }, []);
      setSelectedTask(Object.keys(activities));
    } else {
      setSelectedTask([]);
    }
  };

  const selectTask = (task) => {
    setSelectedTaskLists({});
    setOpenActionDrawer(true);
    if (selectedTask.includes(task)) {
      const remainingTask = selectedTask.filter((i) => i !== task);
      setSelectedTask(remainingTask);
    } else {
      setSelectedTask([...selectedTask, task]);
    }
  };

  const handleSorting = (header, headerIndex) => {
    const { sort, name: fieldName } = header;
    const newSort = sort === 'desc' ? 'asc' : 'desc';

    const copiedCategory = [...Object.keys(activities)];

    switch (fieldName) {
      case 'Name':
        copiedCategory?.sort((a, b) => sortByEntity(a, b, newSort));
        break;

      case 'Type':
        copiedCategory?.sort((a, b) => {
          const firtTaskType = activities[a][0].type ?? '';
          const secondTaskType = activities[b][0].type ?? '';
          return sortByEntity(firtTaskType, secondTaskType, newSort);
        });
        break;
      case 'Tasks':
        copiedCategory?.sort((a, b) => {
          const firstTaskCount = activities[a].length ?? 0;
          const secondTaskCount = activities[b].length ?? 0;

          return sortByEntity(firstTaskCount, secondTaskCount, newSort);
        });

        break;

      default:
        break;
    }

    const sortedCategory = copiedCategory.reduce((a, c) => {
      a[c] = activities[c];

      return a;
    }, {});

    const updatedHeaders = [...tasksHeader];
    updatedHeaders.splice(headerIndex, 1, {
      name: fieldName,
      sort: newSort,
    });
    setTasksHeader(updatedHeaders);
    setActivities(sortedCategory);
  };

  const showTaskBox = () => {
    const originalTask = [...activityDetails.tasks];
    originalTask.push({ name: '' });
    setActivityDetails({ ...activityDetails, tasks: originalTask });
  };

  const defaultActivityType = {
    label: activityDetails.type,
    value: activityDetails.type,
  };
  const isEdit = !!activityDetails.tasks?.[0]?.id;

  return (
    <Flex
      bg="background.500"
      overflow="auto"
      height="100%"
      p="12"
      direction="column"
      gridGap="sm"
    >
      <Flex direction="column" gridGap="llg">
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontWeight={600} fontSize={36}>
            Tasks
          </Text>
          <Button
            leftIcon={<FaPlusCircle />}
            width="157px"
            height="40px"
            colorScheme="blue"
            onClick={handleActivityAddDialog}
            paddingLeft="8px"
          >
            <Text color="white">Add Task List</Text>
          </Button>
        </Flex>
        <SearchBar
          title="Task-list"
          LeftIcon={<SearchIcon />}
          onChange={handleSearchInput}
          setSelectedEntities={setSelectedTask}
          selectedEntities={selectedTask}
        />
      </Flex>
      {Object.keys(activities).length ? (
        <Box>
          <MainTable
            headers={tasksHeader}
            checked={selectedTaskCount === Object.values(activities).length}
            onSelectedEntities={(e) => selectAllTasks(e)}
            handleSorting={handleSorting}
          >
            <Tbody
              bg="white"
              borderRadius="16px"
              boxShadow="0px 12px 24px #ECEEF5"
            >
              {Object.keys(activities)?.map((category) => (
                <STableRow
                  key={category}
                  category={category}
                  addTaskList={addTaskList}
                  checked={selectedTaskCount}
                  allActivities={allActivities}
                  handleAddTask={handleAddTask}
                  selectedEntities={selectedTask}
                  handleAllTasks={handleAllTasks}
                  openDeleteModal={openDeleteModal}
                  typeOfTask={activities[category]}
                  openActionDrawer={openActionDrawer}
                  setSelectedEntities={setSelectedTask}
                  selectedTaskLists={selectedTaskLists}
                  handleCategoryEdit={handleActivityEdit}
                  handleTaskNameEdit={handleTaskNameEdit}
                  selectedTaskToEdit={selectedTaskToEdit}
                  selectTask={() => selectTask(category)}
                  setOpenActionDrawer={setOpenActionDrawer}
                  handleCategoryDelete={handleCategoryDelete}
                  setSelectedTaskLists={setSelectedTaskLists}
                  handleMultipleDeletion={handleMultipleDeletion}
                  openTaskListActionDrawer={openTaskListActionDrawer}
                  handleMultipleTaskDeletion={handleMultipleTaskDeletion}
                  setOpenTaskListActionDrawer={setOpenTaskListActionDrawer}
                  setTaskKey={setTaskKey}
                  taskKey={taskKey}
                  activityDetails={activityDetails}
                  activities={activities}
                />
              ))}
            </Tbody>
          </MainTable>
        </Box>
      ) : (
        <EmptyComponent
          header="Tasks"
          title="Manage your Task-Lists and task here"
          subTitle="Click + add Task-List button at top right corner to create your first Task-List."
          zeroStateImage={EmptyTaskImage}
          isSearch={isSearch}
        />
      )}

      <SideDrawer
        loading={loading}
        open={openDialog}
        negativeAction={closeDialog}
        title={isEdit ? 'Edit Task-List' : 'Add Task-List'}
      >
        <form onSubmit={handleAddButton}>
          <Stack gridGap="16px">
            <CustomInput
              type="children"
              name="type"
              placeholder="Select Task-List Type"
              value={activityDetails.type}
              isRequired
              // register={{
              //   ...register('type', {
              //     validate: validateType,
              //   }),
              // }}
              // error={errors?.type}
              // errorMessage={errors?.type?.message}
            >
              <CustomSelect
                required
                options={taskOptions}
                onChange={handleTypeSelect}
                value={getSelectedTaskListType()}
                defaultValue={defaultActivityType}
                placeholder="Select Task-List Type"
              />
            </CustomInput>
            <CustomInput
              isRequired
              onChange={handleName}
              value={activityDetails.name}
              placeholder="Task-List name"
            />

            {(activityDetails.tasks ?? []).map((task, i) => {
              return (
                <TaskNameBox
                  key={task._id}
                  task={task}
                  taskList={activityDetails}
                  setTaskList={setActivityDetails}
                  index={i}
                />
              );
            })}
            <Button
              leftIcon={<FaPlusCircle />}
              width="157px"
              height="40px"
              variant="link"
              colorScheme="blue"
              onClick={showTaskBox}
              paddingLeft="8px"
            >
              <Text color="primary.400" fontWeight="medium" fontSize="16px">
                Add more tasks
              </Text>
            </Button>

            <Flex justify="flex-end" gridGap="md">
              <Button
                variant="ghost"
                color="primary.500"
                isDisabled={loading}
                onClick={closeDialog}
              >
                Cancel
              </Button>

              <Button
                type="submit"
                colorScheme="blue"
                borderRadius="5px"
                isLoading={loading}
                background="#006DB8 0% 0%"
                boxShadow=" 0px 3px 6px #006DB85C"
              >
                {isEdit ? 'Update' : 'Add'}
              </Button>
            </Flex>
          </Stack>
        </form>
      </SideDrawer>
      {openTaskDialog && (
        <SideDrawer
          title={activityDetails.id ? 'Edit Task' : 'Add Task'}
          negativeAction={closeEditDialog}
          open={openTaskDialog}
        >
          <form onSubmit={handleTaskSubmit}>
            <Stack gridGap="md">
              <CategoryTextField
                details={activityDetails}
                handleCategory={handleCategory}
              />
              <CustomInput
                value={activityDetails.name}
                onChange={handleName}
                placeholder="Task name"
              />

              <Flex justify="flex-end" gridGap="md">
                <Button
                  variant="ghost"
                  color="primary.500"
                  isLoading={loading}
                  onClick={closeDialog}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  colorScheme="blue"
                  background="#006DB8 0% 0%"
                  boxShadow=" 0px 3px 6px #006DB85C"
                  borderRadius="5px"
                  isLoading={loading}
                >
                  {activityDetails.id ? 'Update' : 'Add Task'}
                </Button>
              </Flex>
            </Stack>
          </form>
        </SideDrawer>
      )}
      <DeleteDialog
        open={openDeleteDialog}
        handleCancel={closeDialog}
        listsComponent={
          <SelectedTasksComponent
            activityDetails={activityDetails}
            selectedTaskLists={selectedTaskLists}
            activities={activities}
            selectedTask={selectedTask}
          />
        }
        selectedEnitities={
          selectedTask?.length ? selectedTask : selectedTaskLists
        }
        title="Deleting Task"
        subtitle="Are you sure you want to delete?"
        handleDelete={handleDeletion}
        handleNegativeAction={closeAlertBox}
        entity={selectedTask?.length ? 'Task-Lists' : 'Tasks'}
        showDeleteButton={false}
      />
    </Flex>
  );
};

export const SelectedTasksComponent = ({
  activityDetails,
  selectedTaskLists = {},
  activities,
  selectedTask,
}) => {
  const category =
    Object.values(selectedTaskLists)[0]?.category ??
    activityDetails[0]?.category ??
    activityDetails?.name;
  const taskList = activities[category];
  return (
    <Box
      overflowY="auto"
      margin="24px 0px"
      borderRadius="4px"
      border="0.5px solid #AEB0B5"
      padding="16px 8px 8px 16.5px"
      background="#F1F1F1 0% 0% no-repeat padding-box"
    >
      {selectedTask?.length ? (
        selectedTask.map((task, i) => {
          return (
            <Fragment key={task.id}>
              <Text>{task}</Text>
              {i < selectedTask.length - 1 && <Divider margin="8px auto" />}
            </Fragment>
          );
        })
      ) : (
        <>
          <Text fontWeight={600} fontSize="14px">
            {category}
          </Text>
          <Text fontSize="10px" color=" black.100" margin="8px auto">
            {taskList?.length} Tasks ({Object.keys(selectedTaskLists)?.length}{' '}
            selected)
          </Text>
          {Object.values(selectedTaskLists).map((task) => {
            return (
              <>
                <Divider marginBottom="8px" />
                <Text>{task.name}</Text>
              </>
            );
          })}
        </>
      )}
    </Box>
  );
};
const CategoryTextField = ({ details, handleCategory }) => {
  const {
    strings: {
      taskList: { TASK_LIST },
    },
  } = useContext(LocalizationContext);

  return (
    <CustomInput
      value={details?.category}
      onChange={handleCategory}
      placeholder={`${makeFirstLetterUpperCase(details?.type)} ${TASK_LIST}`}
      isDisabled
      isRequired
    />
  );
};

const STableRow = (props) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState();

  const {
    handleAllTasks,
    allActivities,
    category,
    handleCategoryEdit,
    handleTaskNameEdit,
    handleCategoryDelete,
    setSelectedEntities,
    selectedEntities,
    openActionDrawer,
    setOpenActionDrawer,
    checked,
    selectTask,
    selectedTaskToEdit,
    // This takes just cateogry name and deletes the task list
    handleMultipleDeletion,
    // this takes just task key and deletes the task
    handleMultipleTaskDeletion,
    activityDetails,
    activities,
    typeOfTask,
    setOpenTaskListActionDrawer,
    openTaskListActionDrawer,
    setSelectedTaskLists,
    selectedTaskLists,
    openDeleteModal,
    handleAddTask,
    taskKey,
    setTaskKey,
  } = props;

  const renderTaskListToDelete = () => (
    <SelectedTasksComponent
      activityDetails={activityDetails}
      selectedTaskLists={selectedTaskLists}
      activities={activities}
      selectedTask={selectedEntities}
    />
  );

  function handleCollapseOpen(catagory) {
    setTaskKey(catagory);
    setSelectedTasks(catagory);
    setCollapseOpen(true);

    if (taskKey === catagory) {
      setTaskKey('');
      setCollapseOpen(false);
    }
  }

  function showButtons() {
    return (
      <Wrap>
        <Button
          size="sm"
          colorScheme="blue"
          border="2px solid #006DB8"
          leftIcon={<EditIcon />}
          variant="outline"
          onClick={(e) => {
            handleCategoryEdit(e, selectedTaskToEdit);
          }}
        >
          Edit
        </Button>
      </Wrap>
    );
  }

  function showDelete() {
    return (
      <Button
        size="sm"
        colorScheme="red"
        border="2px solid #006DB8"
        variant="outline"
        leftIcon={<DeleteIcon />}
        onClick={(e) => {
          handleCategoryDelete(selectedTaskToEdit);
        }}
      >
        Delete
      </Button>
    );
  }
  const {
    strings: {
      taskList: { TASK_LISTS, TASK_LIST },
    },
  } = useContext(LocalizationContext);

  return (
    <>
      <MainTableRow>
        <Td>
          <Flex gridGap="md">
            <Checkbox
              isChecked={selectedEntities?.includes(category)}
              onChange={selectTask}
              borderColor="primary.500"
              bg="white"
            />
            <Text> {category} </Text>{' '}
          </Flex>
        </Td>

        <Td>
          <Text> {typeOfTask[0].type ?? '-'} </Text>
        </Td>
        <Td>
          <Flex
            gridGap="20px"
            cursor="pointer"
            alignItems="center"
            justifyContent="flex-end"
            onClick={() => handleCollapseOpen(category)}
          >
            <Text
              fontSize="md"
              textAlign="center"
              padding="6px 12px"
              borderRadius="4px"
              color="highlight.300"
              fontWeight="semibold"
              backgroundColor="background.600"
            >
              {typeOfTask?.length ?? 0}
            </Text>
            Tasks
            <Icon
              as={
                collapseOpen && taskKey === category
                  ? TriangleUpIcon
                  : TriangleDownIcon
              }
            />
          </Flex>
        </Td>
      </MainTableRow>
      <ActionsDrawer
        openActionDrawer={openActionDrawer}
        selectedEntities={selectedEntities.map((e) => ({
          key: e,
          name: e,
        }))}
        showButtons={showButtons}
        setSelectedEntities={setSelectedEntities}
        showDeleteButton
        body={() => renderTaskListToDelete()}
        handleDeleteEntities={(task) => handleMultipleDeletion(task.key)}
        entity={selectedEntities.length < 2 ? TASK_LIST : TASK_LISTS}
      />
      {collapseOpen && taskKey === category ? (
        <td style={{ overflow: 'hidden' }} colSpan={100}>
          <Collapse in={collapseOpen} unmountOnExit animateOpacity>
            <Box bg="background.500">
              <CollapseData
                tasks={allActivities}
                selectedEntities={selectedEntities}
                setSelectedEntities={setSelectedEntities}
                task={selectedTasks}
                setOpenTaskListActionDrawer={setOpenTaskListActionDrawer}
                openTaskListActionDrawer={openTaskListActionDrawer}
                setSelectedTaskLists={setSelectedTaskLists}
                selectedTaskLists={selectedTaskLists}
                handleCategoryDelete={handleCategoryDelete}
                handleCategoryEdit={handleTaskNameEdit}
                openDeleteModal={openDeleteModal}
                handleAddTask={handleAddTask}
                handleMultipleTaskDeletion={handleMultipleTaskDeletion}
                renderTaskListToDelete={renderTaskListToDelete}
              />
            </Box>
          </Collapse>
        </td>
      ) : null}
    </>
  );
};

export default Activities;
