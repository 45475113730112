/*
 ********************************************************************************
 *
 *  SNOWM INCORPORATED. ALL RIGHTS RESERVED 2018-2019
 *
 *  File name: alert_box.jsx
 *
 *  Description: Alert Box Component
 *
 *  Author: Roshan Gautam (roshan@brainants.com)
 *
 *  Date created: 22-july-2019
 *
 *
 *********************************************************************************
 */

/*
 import statements
 */
import React, { useContext } from 'react';

import styled from 'styled-components';
import { Close } from '@material-ui/icons';
import { Drawer, DrawerContent, DrawerOverlay } from '@chakra-ui/modal';
import {
  Divider,
  IconButton,
  DialogTitle,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';

import { LowerCaseButton } from './styled';
import PrimayButton from './primary_button';
import { Row } from '../../styles/snowm_styled';
import { LocalizationContext } from '../../contexts/localization_context';

const FormModal = ({
  open,
  edit,
  title,
  width,
  scroll,
  loading,
  children,
  disabled,
  maxWidth = '400px',
  organizationalCrew,
  handleNegativeAction,
  handlePositiveAction,
  renderActions = true,
}) => {
  const { strings } = useContext(LocalizationContext);
  const {
    action: { ADD, CANCEL },
  } = strings;

  return (
    <Drawer
      isOpen={open}
      fullWidth
      maxWidth={maxWidth}
      overflow="auto"
      width={width}
      placement="right"
    >
      <DrawerOverlay />
      <DrawerContent>
        <StyledForm scroll={scroll} onSubmit={handlePositiveAction}>
          <StyledDialogTitle>
            <Row justify="space-between">
              {title}
              <IconButton onClick={handleNegativeAction}>
                <Close fontSize="small" />
              </IconButton>
            </Row>
            <Divider />
          </StyledDialogTitle>

          {children}

          {renderActions && (
            <StyledDialogActions>
              <LowerCaseButton
                style={{ fontSize: 16, fontWeight: '700', marginRight: 10 }}
                onClick={handleNegativeAction}
                color="primary"
                disabled={loading}
              >
                {CANCEL}
              </LowerCaseButton>

              {!organizationalCrew && (
                <PrimayButton
                  type="submit"
                  loading={loading}
                  disabled={disabled}
                >
                  {loading && (
                    <CircularProgress
                      size={20}
                      color="inherit"
                      style={{ marginRight: 8 }}
                    />
                  )}
                  {edit ? 'Save' : ADD}
                </PrimayButton>
              )}
            </StyledDialogActions>
          )}
        </StyledForm>
      </DrawerContent>
    </Drawer>
  );
};

const StyledDialogTitle = styled(DialogTitle)`
  && {
    padding-left: 0px;
    padding-right: 8px;
    h2 {
      font-weight: 700;
      font-size: x-large;
    }
  }
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    padding: 16px 0;
  }
`;

const StyledForm = styled.form`
  position: relative;
  padding: 1rem 2rem 0 1.5rem;
  display: flex;
  flex-direction: column;
  overflow-y: ${(props) => props.scroll && 'scroll'};
`;

export default FormModal;
