import * as React from 'react';

import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  Th,
  Tr,
  Box,
  Flex,
  Icon,
  Text,
  Table,
  Thead,
  Checkbox,
} from '@chakra-ui/react';

import '../App.css';

export const MainTableRow = (props) => {
  const { children } = props;
  return <Tr height="50px">{children}</Tr>;
};

export interface EachHeader {
  name: string;
  sort?: string;
  checkBox?: boolean;
}

interface MainTableProps {
  checked?: boolean;
  headers: EachHeader[];
  children: React.ReactNode;
  handleSorting?: (header: EachHeader, index: number) => void;
  onSelectedEntities?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const MainTable = ({
  headers,
  checked,
  children,
  handleSorting,
  onSelectedEntities,
}: MainTableProps) => {
  function renderCheckBox() {
    if (checked !== undefined) {
      return (
        <Checkbox
          bg="white"
          isChecked={checked}
          borderColor="primary.500"
          onChange={onSelectedEntities}
        />
      );
    }
    return null;
  }

  const renderSortingIcon = (sort?: string) => {
    if (!sort) {
      return null;
    }
    if (sort === 'desc') {
      return <Icon fill=" #17244F " as={TriangleDownIcon} />;
    }
    return <Icon as={TriangleUpIcon} />;
  };

  function renderHeader() {
    return (
      <Tr top="0" zIndex="10" cursor="pointer" height="50px">
        {headers.map((header, index) => {
          const { name, checkBox, sort } = header;
          return (
            <Th
              key={name}
              onClick={() =>
                handleSorting && sort ? handleSorting(header, index) : null
              }
            >
              <Flex
                gridGap="md"
                alignItems="center"
                justifyContent={
                  index === headers.length - 1 && headers.length !== 1
                    ? 'flex-end'
                    : 'flex-start'
                }
              >
                {index === 0 && !checkBox && renderCheckBox()}
                <Text
                  mr="1"
                  fontSize="md"
                  textTransform="none"
                  fontWeight="semibold"
                >
                  {name}
                </Text>
                {renderSortingIcon(sort)}
              </Flex>
            </Th>
          );
        })}
      </Tr>
    );
  }

  return (
    <Box
      height="100%"
      overflow="auto"
      borderRadius="md"
      boxShadow=" 0px 12px 24px #ECEEF5"
    >
      <Table size="sm" overflowX="scroll">
        <Thead>{renderHeader()}</Thead>
        {children}
      </Table>
    </Box>
  );
};
