import { DeleteIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
  Wrap,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import PlaylistCheckIcon from 'mdi-react/PlaylistCheckIcon';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Span from '../../Components/Common/Span';
import Loader, { Progress } from '../../Components/Generics/snowm_loader';
import { Schedule } from '../../Components/Private/schedule/shift';
import { LocationContext } from '../../contexts/locationContext';
import { ServicePointsContext } from '../../contexts/service_points';
import { ServicePContext } from '../../contexts/service_provider';
import { getShiftById } from '../../controllers/snowm_firebase';
import { getColorForStatus, possibleJobStatuses } from '../../enums/jobStatus';
import { getDateForJob, getStartTimeStampOfTheDay } from '../../helpers/date';
import { formatTime } from '../../helpers/misc';
import ActionsDrawer from '../ActionsDrawer';
import { MainTable, MainTableRow } from '../MainTable';
import { ShiftTemplate } from '../../Components/Private/shiftTemplates/shiftTemplate';

export const jobsHeader = [
  {
    name: 'Shifts',
    sort: 'asc',
    checkBox: true,
    view: false,
  },
  {
    name: 'Janitors',
    sort: 'asc',
    view: false,
  },
  {
    name: 'Location',
    sort: 'asc',
    view: false,
  },

  {
    name: 'Micro-location',
    sort: 'asc',
    view: false,
  },
];

export const flteredJobsHeaders = (isPeriodic) => {
  return jobsHeader.filter((header) => {
    if (header.view) {
      if (header.view === !isPeriodic) return true;
      return false;
    }
    return true;
  });
};

type SchedulesByKey = Record<string, Schedule>;

interface JobsTableInterface {
  jobs: Schedule[];
  isPeriodic: boolean;
  openActionDrawer: boolean;
  selectedShifts: SchedulesByKey;
  onEditClick: (schedule: Schedule) => void;
  onCheckClick: (schedule: Schedule) => void;
  onDeleteClick: (schedule: Schedule) => void;
  onDetailsClick: (schedule: Schedule) => void;
  handleMultipleDelete: (schedule: Schedule) => void;
  setOpenActionDrawer: (openActionDrawer: boolean) => void;
  setSelectedShifts: (schedulesByKey: SchedulesByKey) => void;
}

const JobsTable = ({
  jobs,
  isPeriodic,
  onEditClick,
  onCheckClick,
  onDeleteClick,
  onDetailsClick,
  selectedShifts,
  openActionDrawer,
  setSelectedShifts,
  setOpenActionDrawer,
  handleMultipleDelete,
}: JobsTableInterface) => {
  const [selectedShiftsCount, setSelectedShiftCount] = useState(0);

  const selectedShiftsData = Object.values(selectedShifts);
  useEffect(() => {
    setSelectedShiftCount(selectedShiftsData.length);
  }, [selectedShifts]);

  const actionButtons = () => {
    const job = selectedShiftsData[0];

    const checkIfCompleteJobIsAllowed = () => {
      const { status: jobStatus } = job ?? {};
      return (
        jobStatus === possibleJobStatuses.STARTED ||
        jobStatus === possibleJobStatuses.NOT_COMPLETED
      );
    };

    const jobStatus = job?.status;

    return (
      <Wrap>
        <Button
          size="sm"
          colorScheme="blue"
          border="2px solid #006DB8"
          variant="outline"
          onClick={() => onDetailsClick(job)}
          leftIcon={<ViewIcon />}
        >
          View
        </Button>

        {isPeriodic || jobStatus === 'assigned' ? (
          <Button
            onClick={() => onEditClick(job)}
            size="sm"
            colorScheme="blue"
            border="2px solid #006DB8"
            variant="outline"
            leftIcon={<EditIcon />}
          >
            Edit
          </Button>
        ) : null}
        {checkIfCompleteJobIsAllowed() && (
          <Tooltip label="Complete Job">
            <PlaylistCheckIcon
              fontSize="small"
              onClick={() => onCheckClick(job)}
            />
          </Tooltip>
        )}
        <Button
          size="sm"
          colorScheme="red"
          border="2px solid #006DB8"
          variant="outline"
          leftIcon={<DeleteIcon />}
          onClick={() => {
            onDeleteClick(job);
          }}
        >
          Delete
        </Button>
      </Wrap>
    );
  };

  const selectAllShifts = (e) => {
    if (e.target.checked) {
      const checkedShift = jobs?.reduce((shifts, shift) => {
        if (shift.key) {
          shifts[shift.key] = shift;
        }
        return shifts;
      }, {});
      setSelectedShifts({ ...checkedShift });
    } else {
      setSelectedShifts({});
    }
  };

  const renderJobsByDate = () => {
    const dates = Object.keys(jobs);

    return dates.map((date, i) => {
      const jobsOfAParticularDate = jobs[date];

      const isToday = () =>
        getStartTimeStampOfTheDay(date) === getStartTimeStampOfTheDay();

      return (
        <Tbody
          bg="white"
          key={date}
          borderRadius="16px"
          boxShadow="0px 12px 24px #ECEEF5"
        >
          <Tr>
            <Td padding="md">
              <Flex>
                <Text
                  color={isToday() ? 'blue.600' : 'black'}
                  fontWeight="600"
                  fontSize="18px"
                >
                  {date}
                </Text>
                {isToday() ? (
                  <Text color="blue.600" fontSize="16px">
                    (Today)
                  </Text>
                ) : null}
              </Flex>
            </Td>
            <Td />
            <Td />
            <Td />
          </Tr>
          {jobsOfAParticularDate?.map((job, index) => {
            return (
              <CrewsBody
                job={job}
                date={date}
                key={job.key}
                isPeriodic={isPeriodic}
                onDetailsClick={onDetailsClick}
                selectedShifts={selectedShifts}
                setSelectedShifts={setSelectedShifts}
              />
            );
          })}
        </Tbody>
      );
    });
  };

  if (!jobs) {
    return <h1>loading...</h1>;
  }
  return (
    <Box mb="50px">
      <MainTable
        headers={flteredJobsHeaders(isPeriodic)}
        checked={selectedShiftsCount === jobs?.length}
        onSelectedEntities={(e) => selectAllShifts(e)}
      >
        {renderJobsByDate()}
      </MainTable>

      <ActionsDrawer
        setSelectedEntities={setSelectedShifts}
        selectedEntities={Object.values(selectedShifts)}
        openActionDrawer={openActionDrawer}
        showButtons={() => actionButtons()}
        entity="Shift"
        showDeleteButton={Object.values(selectedShifts).length > 1}
        handleDeleteEntities={(shift) => handleMultipleDelete(shift)}
        deleteSubtitle="Remember, this action cannot be undone."
      />
    </Box>
  );
};

const CrewsBody = ({
  date,
  job,
  isPeriodic,
  onDetailsClick,
  selectedShifts,
  setSelectedShifts,
}) => {
  const getJob = () => {
    if (isPeriodic) {
      return job?.job;
    }
    return job;
  };

  const [shift, setShift] = useState<ShiftTemplate | null>(null);
  const [actualJob, setActualJob] = useState<Schedule>(getJob());

  useEffect(() => {
    const updatedJob = getJob();
    setActualJob(updatedJob);
  }, [job, isPeriodic]);
  useEffect(() => {
    let isCancel = false;

    if ((actualJob?.shift || actualJob?.shiftKey) && !isPeriodic) {
      if (actualJob?.shift) {
        setShift(actualJob.shift);
      } else {
        getShiftById(actualJob?.shiftKey).then((res: any) => {
          if (!isCancel) {
            setShift(res);
          }
        });
      }
    }
    return () => {
      isCancel = true;
    };
  }, [actualJob]);

  const onSelectShift = (selectedShift) => {
    const { key } = selectedShift;
    if (selectedShifts[key]) {
      const { [key]: value, ...remainingShift } = selectedShifts;
      setSelectedShifts({ ...remainingShift });
    } else {
      setSelectedShifts({ ...selectedShifts, [key]: { ...selectedShift } });
    }
  };
  function isJobOutdated() {
    if (!job.shift?.startTime) return false;
    if (job.createdDate < dayjs().valueOf()) return true;
    return false;
  }

  return (
    <MainTableRow>
      <Td>
        <Flex gridGap="md">
          {isJobOutdated() ? null : (
            <Checkbox
              onChange={() => onSelectShift(job)}
              isChecked={!!selectedShifts[job.key ?? ' ']}
              borderColor="primary.500"
              bg="white"
            />
          )}
          <Box
            flexGrow="1"
            padding="9px"
            cursor="pointer"
            onClick={() => onDetailsClick(job, date)}
          >
            <Text color="black">
              {job.shift
                ? `${formatTime(job.shift?.startTime)} to ${formatTime(
                    job.shift?.endTime
                  )}`
                : '-'}
            </Text>
          </Box>
        </Flex>
      </Td>
      <Td>
        <CrewsOfJob job={actualJob} />
      </Td>
      <Td>
        <LocationOfAJob job={actualJob} />
      </Td>
      <Td>
        <MarkerOfAJob job={actualJob} />
      </Td>
    </MainTableRow>
  );
};

export const CrewsOfJob = ({ job }) => {
  const crewContext = useContext(ServicePContext);

  const { getCrewByUid } = crewContext ?? {};

  const [crewsName, setCrewsName] = useState<string | null>(null);

  const getCrews = async (crewIds) => {
    const crewPromise = crewIds?.map((uid) => {
      const response = getCrewByUid(uid);
      return response;
    });

    const result = await Promise.all(crewPromise ?? []);

    return result?.map((prov) => prov.name).join() || 'Deleted';
  };

  useEffect(() => {
    if (job?.providerUids) {
      getCrews(job.providerUids).then((res) => {
        setCrewsName(res);
      });
    }
  }, [job]);

  if (!crewContext) {
    return <Loader />;
  }

  const renderCrewsName = () => {
    if (!crewsName) {
      return <Progress size={20} />;
    }
    return crewsName;
  };

  return (
    <Text color="grey.300" fontWeight="bold">
      {renderCrewsName()}
    </Text>
  );
};

export const MarkerOfAJob = ({ job }) => {
  const markerContext = useContext(ServicePointsContext);

  const [markers, setMarkers] = useState(null);

  const { getMarkerByKey } = markerContext;
  const getMarkers = async () => {
    const promise = job?.allMarkers?.map((markerKey) => {
      return getMarkerByKey(markerKey);
    });

    const res = await Promise.all(promise ?? []);

    const validMarkers = res?.reduce((acc, marker) => {
      if (marker.name) {
        return [...acc, marker.name];
      }
      return acc;
    }, []);

    const markersSeparatedByComma = validMarkers?.join() ?? '-';

    setMarkers(markersSeparatedByComma || '-');
  };

  useEffect(() => {
    getMarkers();
  }, [job]);

  const renderMarkersName = () => {
    if (!markers) {
      return <Progress size={20} />;
    }
    return markers;
  };

  return (
    <Flex justifyContent="flex-end">
      <Text>{renderMarkersName()}</Text>
    </Flex>
  );
};

export const LocationOfAJob = ({ job }) => {
  const { locations, getAllPropertiesOfACompany } = useContext(LocationContext);

  const [locationsOfAJob, setLocationsOfAJob] = useState(null);

  const getLocations = async () => {
    const locationsInJob = job?.propertyKeys?.map((key) => {
      return locations?.[key] ?? null;
    });

    const validMarkers = locationsInJob?.reduce((acc, loc) => {
      if (loc?.name) {
        return [...acc, loc.name];
      }
      return acc;
    }, []);

    const markersSeparatedByComma = validMarkers?.join() ?? '-';

    setLocationsOfAJob(markersSeparatedByComma || '-');
  };

  useEffect(() => {
    getLocations();
  }, [locations]);

  useEffect(() => {
    if (!locations) getAllPropertiesOfACompany();
  }, []);

  useEffect(() => {
    getLocations();
  }, [job]);

  const renderLocationsName = () => {
    if (!locationsOfAJob) {
      return <Progress size={20} />;
    }
    return locationsOfAJob;
  };

  return (
    <Flex justifyContent="flex-end">
      <Text>{renderLocationsName()}</Text>
    </Flex>
  );
};

export default JobsTable;
