import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import Map from '../../Components/Private/Maps/map_polyline';
import MarkerLogs from './markerLogs/MakerLogs';
import {
  getServicePointById,
  getMarkerLogs,
  getRouteByKey,
} from '../../controllers/snowm_firebase';

const OutdoorJobRouteDetail = ({ match }) => {
  const [routeInfo, setRouteInfo] = useState({});
  const [markers, setMarkers] = useState([]);
  const [logs, setLogs] = useState([]);
  const { jobKey, routeKey } = match.params;

  useEffect(() => {
    getRouteByKey(routeKey).then((route) => setRouteInfo(route));
  }, [jobKey, routeKey]);

  useEffect(() => {
    if (routeInfo.servicePointsKeys) {
      const promise = routeInfo.servicePointsKeys.map((markerKey) => {
        return getServicePointById(markerKey);
      });

      Promise.all(promise).then((markersInfo) => {
        setMarkers(markersInfo);
      });
    }
  }, [routeInfo]);

  useEffect(() => {
    const _updateMarkerLogs = (response) => {
      setLogs(response);
    };

    getMarkerLogs(jobKey, routeKey, false, _updateMarkerLogs);
  }, []);

  return (
    <>
      <div style={{ height: 350 }}>
        <Map
          polyline={routeInfo ? routeInfo.polyline : ''}
          points={markers}
          zoom={16}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography style={{ textAlign: 'center' }}>Marker Logs</Typography>
        {logs.length > 0 ? (
          logs.map((log) => {
            if (log.endDate) {
              return <MarkerLogs key={log.key} log={log} />;
            }
            return (
              <Typography style={{ textAlign: 'center' }} key={log.endDate}>
                No logs available for this route
              </Typography>
            );
          })
        ) : (
          <Typography style={{ textAlign: 'center' }}>No logs</Typography>
        )}
      </div>
    </>
  );
};

export default OutdoorJobRouteDetail;
