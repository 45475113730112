import { Route } from 'react-router';

import ShiftTemplates from './ShiftTemplates';

const ShiftTemplatesRoutes = () => {
  return (
    <Route exact path="/admin/shift-templates" component={ShiftTemplates} />
  );
};

export default ShiftTemplatesRoutes;
