import React, { useState, useEffect } from 'react';

import styled from 'styled-components';

import InfoWindow from './InfoWindow';
import { defaultAvatar } from '../../../helpers/misc';
import markerImg from '../../../assets/images/redMarker.png';
import greenMarker from '../../../assets/images/greenMarker.png';
import {
  getProviderByUid,
  getRealTimeProvider,
} from '../../../controllers/snowm_firebase';

const height = 50;
const width = 50;

const MapMarker = ({ onTap, assigned, point, edit, show, dashboard }) => {
  const [image, setImage] = useState();
  const [showInfo, setShowInfo] = useState(false);
  const [status, setStatus] = useState();

  const _updateProvider = (provider) => {
    const currentStatus = provider?.working ? 'Working' : 'Not working';
    setStatus(currentStatus);
  };

  useEffect(() => {
    let isCancel = false;
    if (point?.uid && dashboard) {
      getProviderByUid(point?.uid).then((res) => {
        if (!isCancel) setImage(res?.imageUrl);
      });
      getRealTimeProvider(point?.uid, _updateProvider);
    } else if (assigned === true) {
      setImage(greenMarker);
    } else {
      setImage(markerImg);
    }

    return () => {
      isCancel = true;
    };
  }, [assigned, point]);

  useEffect(() => {
    if (show) setShowInfo(true);
    else setShowInfo(false);
  }, [show]);

  return (
    <>
      {showInfo && point && (
        <InfoWindow
          point={point}
          onClose={() => {
            setShowInfo(false);
          }}
        />
      )}
      <div
        role="button"
        tabIndex={0}
        onClick={() => {
          if (onTap) {
            onTap();
          }
          if (!edit) {
            setShowInfo(true);
          }
        }}
        style={{ position: 'relative' }}
        onKeyDown={() => null}
      >
        <StyledImage
          src={image}
          height={height}
          width={width}
          onError={(e) => {
            e.currentTarget.src = defaultAvatar;
          }}
        />
      </div>
    </>
  );
};

const StyledImage = styled.img`
  position: absolute;
  left: ${(props) => props.left ?? -(width / 2)}px;
  top: ${(props) => props.top ?? -height}px;
  border-radius: 50%;
  object-fit: cover;
`;

export default MapMarker;
