import React from 'react';

import {
  Divider,
  Drawer,
  Box,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Heading,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

interface SDrawerProps {
  open: boolean;
  title: string;
  positiveLabel?: string;
  negativeLabel?: string;
  negativeAction: () => void;
  children?: React.ReactNode;
  showActionButtons?: boolean;
  action?: any;
  positiveAction?: () => void;
  handleBackClick?: () => void;
  showBackButton?: boolean;
  actionLoading?: boolean;
}

export const SideDrawer = (props: SDrawerProps) => {
  const {
    open,
    title,
    positiveLabel,
    negativeLabel,
    positiveAction,
    negativeAction,
    showActionButtons = false,
    action,
    handleBackClick,
    showBackButton = false,
    actionLoading = false,
    children,
  } = props;

  return (
    <Drawer size="md" isOpen={open} placement="right" onClose={negativeAction}>
      <DrawerOverlay>
        <DrawerContent overflowY="scroll">
          <Box mt="60px" ml="60px" mr="60px" mb="10px">
            <DrawerHeader p="0">
              <Flex direction="column">
                <Flex
                  display="flex"
                  justify="space-between"
                  alignItems="center"
                >
                  {showBackButton ? (
                    <ArrowBackIcon
                      color="#17244F"
                      width="24px"
                      height="24px"
                      marginRight="16px"
                      onClick={handleBackClick}
                      cursor="pointer"
                    />
                  ) : null}
                  <Heading
                    m="0"
                    fontSize="lg"
                    color=" #162350"
                    letterSpacing={0}
                    fontWeight="bold"
                  >
                    {title}
                  </Heading>
                  {action}
                  <DrawerCloseButton
                    position="relative"
                    color="text.500"
                    top="0"
                    right="0"
                    bg="white"
                  />
                </Flex>
                <Box
                  maxWidth="481px"
                  height="0px"
                  border="1px solid #323A45"
                  opacity="0.4"
                  marginBottom="32px"
                  mt="16px"
                />
              </Flex>
            </DrawerHeader>

            <DrawerBody p="0" overflow="unset">
              <Box marginTop="8px">{children}</Box>
            </DrawerBody>
          </Box>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};
